import axios from '../../axios';
import queryString from 'query-string';
import _ from 'lodash';

import actionCreators from './actionCreators';

export const getExcelForm = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/generic-forms/${id}/`);
    dispatch(actionCreators.excelFormLoaded(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addExcelForm = (data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/generic-forms/`, data);
    dispatch(actionCreators.excelFormUpdated(response.data));
    dispatch(actionCreators.excelFormsAdd(response.data));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editExcelForm = (id, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/generic-forms/${id}/`, data);
    dispatch(actionCreators.excelFormUpdated(response.data));
    dispatch(actionCreators.excelFormsUpdated(response.data));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const getExcelForms = (page, parameters) => async (dispatch) => {
  try {
    const params = parameters ? { ...parameters, form_type: 'forms' } : { form_type: 'forms' };
    const response = await axios.get(`/api/v1/generic-forms/`, {
      params: { ...params, page: page },
      paramsSerializer: function (params) {
        return queryString.stringify(params, {
          arrayFormat: 'comma',
          skipNull: true,
          skipEmptyString: true,
        });
      },
    });

    dispatch(actionCreators.excelFormsLoaded(response.data, parameters?.countries));

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getExcelFormsRelative = (parameters, countryId) => async (dispatch) => {
  try {
    const params = parameters ? { ...parameters, form_type: 'forms' } : { form_type: 'forms' };
    const response = await axios.get(`/api/v1/generic-forms/`, {
      params: { ...params, countries: countryId },
      paramsSerializer: function (params) {
        return queryString.stringify(params, {
          arrayFormat: 'comma',
          skipNull: true,
          skipEmptyString: true,
        });
      },
    });

    dispatch(actionCreators.excelFormsLoadRelative(response.data, parameters, countryId));

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const editExcelDocument = (empId, gfId, formId, data, isDraft) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .put(
        isDraft
          ? `/api/v1/employees/${empId}/gf/employee-edit/${gfId}/form/${formId}/`
          : `/api/v1/employees/${empId}/gf/${gfId}/form/${formId}/`,
        data,
      )
      .then((response) => {
        dispatch(actionCreators.excelDocumentUpdate(response.data, gfId, formId, empId));
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addExcelDocument = (empId, gfId, data, isDraft) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .post(
        isDraft ? `/api/v1/employees/${empId}/gf/employee-edit/${gfId}/` : `/api/v1/employees/${empId}/gf/${gfId}/`,

        data,
      )
      .then((response) => {
        dispatch(actionCreators.excelDocumentAdd(response.data, gfId, empId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addRelativeForm = (relativeId, gfId, data, relatedReqId, isDraft) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .post(
        isDraft
          ? `/api/v1/employees/relative/${relativeId}/gf/employee-edit/${gfId}/`
          : `/api/v1/employees/relative/${relativeId}/gf/${gfId}/`,
        data,
      )
      .then((response) => {
        dispatch(actionCreators.relativeFormAdd(response.data, gfId, relatedReqId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editRelativeForm = (employeeId, gfId, formId, data, relatedReqId, isDraft) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .put(
        isDraft
          ? `/api/v1/employees/${employeeId}/gf/employee-edit/${gfId}/form/${formId}/`
          : `/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/`,
        data,
      )
      .then((response) => {
        dispatch(actionCreators.relativeFormUpdate(response.data, gfId, formId, relatedReqId));
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
