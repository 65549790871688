import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import { Formik, Form, FieldArray, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import classNames from 'classnames';

import { getFormElement } from '../../Shared/Form/FormElements';
import { arhiveDocumentForm, deleteDocumentForm } from '../../../store/actions/uploadFile';
import SlideOutIcons from '../../../components/Shared/SlideOutIcons/SlideOutIcons';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import { setIsCurrentDocument, useFormSchema, useInitialValues, useValidationSchema } from './hooks';
import AttachmentBlock from '../../Shared/Form/AttachFiles/AttachmentBlock';
import CurrentAttachmentBlock from './CurrentAttachmentBlock/CurrentAttachmentBlock';
import DocumentAccordion from '../DocumentAccordion/DocumentAccordion';
import './employeeDocument.scss';

const FormObserver = ({ relativeRequestId, documentSlug }) => {
  const { initialValues, values, dirty } = useFormikContext();

  useEffect(() => {
    if (!dirty) {
      if (relativeRequestId) {
        const relativesDocs = JSON.parse(sessionStorage.getItem('RelativeDocs'));
        const relativeDocsByRelativeId = relativesDocs?.[relativeRequestId] || {};
        if (documentSlug in relativeDocsByRelativeId) {
          delete relativeDocsByRelativeId[documentSlug];
        }
        sessionStorage.setItem(
          'RelativeDocs',
          JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('RelativeDocs')),
            [relativeRequestId]: relativeDocsByRelativeId,
          }),
        );
      } else {
        const employeeDocsValues = JSON.parse(sessionStorage.getItem('EmployeeDocs')) || {};
        if (documentSlug in employeeDocsValues) {
          delete employeeDocsValues[documentSlug];
        }
        sessionStorage.setItem(
          'EmployeeDocs',
          JSON.stringify({
            ...employeeDocsValues,
          }),
        );
      }
      return;
    }

    if (JSON.stringify(initialValues) !== JSON.stringify(values)) {
      if (relativeRequestId) {
        const relativesDocs = JSON.parse(sessionStorage.getItem('RelativeDocs'));
        const relativeDocsByRelativeId = relativesDocs?.[relativeRequestId] || {};

        const updatedRelativeDocs = {
          ...relativeDocsByRelativeId,
          ...values,
        };

        sessionStorage.setItem(
          'RelativeDocs',
          JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('RelativeDocs')),
            [relativeRequestId]: updatedRelativeDocs,
          }),
        );
      } else {
        sessionStorage.setItem(
          'EmployeeDocs',
          JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('EmployeeDocs')),
            ...values,
          }),
        );
      }
    }
  }, [values]);

  return null;
};

const DocumentForm = ({
  document,
  relatedId,
  relatedReqId,
  onSubmit,
  petId,
  petReqId,
  isCase,
  isCaseDisabled,
  employeeId,
  withCurrentField,
  withArchiveForm,
  withEditFormInConstructor,
  documentType,
  allowCustomReminders,
  submitBtnTitle,
  isLegalizationRequestDocument,
  isDocumentsSubmit,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const targetRef = useRef();
  const [titleLength, setTitleLength] = useState({});

  const setTitleWidth = () => {
    if (targetRef.current) {
      setTitleLength({
        width: targetRef.current.offsetWidth,
      });
    }
  };

  const [formSchemaWithoutDisFilds, formSchemaWithoutEmptDisFieldsArray] = useFormSchema(
    document,
    isCase,
    withCurrentField,
    document.slug,
  );

  const [initialValues, initialValuesToPush] = useInitialValues(document, formSchemaWithoutDisFilds, withCurrentField);
  const validationSchema = useValidationSchema(document);

  const handleDeleteForm = (documentId, formId) => () => {
    return dispatch(
      deleteDocumentForm(
        employeeId,
        documentId,
        formId,
        documentType.forWhom,
        relatedId,
        relatedReqId,
        petId,
        petReqId,
        isCase,
        documentType.type,
      ),
    );
  };

  const handleArchiveForm = (documentId, formId) => () => {
    dispatch(
      arhiveDocumentForm({ isArchived: true }, employeeId, documentId, formId, documentType.forWhom, relatedReqId),
    );
  };

  useLayoutEffect(() => {
    setTitleWidth();
  }, []);

  return (
    <div className="document">
      <DocumentAccordion
        documentName={document.name}
        documentId={document.slug}
        targetRef={targetRef}
        type={documentType.forWhom}
      >
        <Formik
          enableReinitialize
          initialValues={_.cloneDeep(initialValues)}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => {
            return (
              <Form>
                {isDocumentsSubmit && <FormObserver relativeRequestId={relatedReqId} documentSlug={document.slug} />}
                <FieldArray
                  name={document.slug}
                  render={({ remove, push, insert }) => (
                    <>
                      <div>
                        {formikProps.values[document.slug].length > 0 &&
                          formikProps.values[document.slug].map((form, formIndex) => (
                            <div className="document-container" key={formIndex}>
                              <div className="document-container__form">
                                <div className="document-form">
                                  {(!isCase || (isCase && !isCaseDisabled)) && (
                                    <SlideOutIcons
                                      key={formIndex}
                                      form={form}
                                      formikProps={formikProps}
                                      document={document}
                                      formIndex={formIndex}
                                      titleLength={titleLength}
                                      remove={remove}
                                      deleteForm={handleDeleteForm(document.id, form.id)}
                                      archiveForm={handleArchiveForm(document.id, form.id)}
                                      isCase={isCase}
                                      withArchiveForm={withArchiveForm}
                                      withEditFormInConstructor={withEditFormInConstructor}
                                    />
                                  )}
                                  {!isCase && document.description && document.description !== '' && (
                                    <div className="description" key={`description.${formIndex}`}>
                                      {parse(document.description)}
                                    </div>
                                  )}
                                  <div>
                                    {(formikProps.initialValues[document.slug].length - (formIndex + 1) >= 0
                                      ? formSchemaWithoutEmptDisFieldsArray[formIndex]
                                        ? formSchemaWithoutEmptDisFieldsArray[formIndex]
                                        : []
                                      : formSchemaWithoutDisFilds
                                    ).map((field) => (
                                      <div key={field.fieldName}>
                                        {getFormElement(
                                          field.element,
                                          {
                                            nameFieldArray: `${document.slug}`,
                                            formIndex,
                                            validationSchema,
                                            ...field,
                                          },
                                          formikProps,
                                          isCaseDisabled,
                                          allowCustomReminders && document.isWithCustomReminders,
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                {document.isWithAttachments && (
                                  <AttachmentBlock
                                    formikProps={formikProps}
                                    name={`${document.slug}.${formIndex}.files`}
                                    formIndex={formIndex}
                                    forceDisabled={
                                      isCaseDisabled || (form.id && !isCase && !document.forms[formIndex]?.isDraft)
                                    }
                                    isLegalizationRequestDocument={isLegalizationRequestDocument}
                                  />
                                )}

                                {document.isWithAttachments && (
                                  <CurrentAttachmentBlock
                                    form={form}
                                    document={document}
                                    documentType={documentType}
                                    employeeId={employeeId}
                                    relatedId={relatedId}
                                    relatedReqId={relatedReqId}
                                    petId={petId}
                                    petReqId={petReqId}
                                    isCase={isCase}
                                    formIndex={formIndex}
                                    isCaseDisabled={isCaseDisabled}
                                    isLegalizationRequestDocument={isLegalizationRequestDocument}
                                  />
                                )}
                                {document.forms[formIndex] &&
                                  !formikProps.isSubmitting &&
                                  location.pathname.includes('documents') &&
                                  document.isWithAttachments &&
                                  document.forms[formIndex].formId &&
                                  document.forms[formIndex].attachments.length < 1 &&
                                  form.files.length < 1 &&
                                  document.forms[formIndex].isDraft && (
                                    <div style={{ color: 'red', fontSize: '14px' }}>
                                      Please attach documents to the filled form
                                    </div>
                                  )}
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className="button-wrapper">
                        <div>
                          {!!onSubmit && (
                            <SubmitButton
                              title={submitBtnTitle ? submitBtnTitle : 'Submit'}
                              formikProps={formikProps}
                            />
                          )}
                          {document.isCanBePlural && (
                            <button
                              type="button"
                              className={classNames('button btn-form main-btn', isCaseDisabled ? 'btn-disabled' : '')}
                              onClick={() => {
                                push(initialValuesToPush);
                                setIsCurrentDocument(document.slug)(true, null, formikProps);
                              }}
                              disabled={isCaseDisabled}
                            >
                              Add document
                            </button>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </DocumentAccordion>
    </div>
  );
};

export default DocumentForm;
