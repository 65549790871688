import { Switch, Route } from 'react-router-dom';
import FrequentlyAskedQuestion from '../../Relocation/FAQ/FrequentlyAskedQuestions';
import Relocation from '../../Relocation/MainPage/Relocation';
import AskQuestion from '../../Relocation/AskQuestion/AskQuestion';
import RelocationPackage from '../../Relocation/RelocationPackage/RelocationPackage';
import NotFound from '../../NotFound/NotFound';
import RelatedPage from '../../Relocation/RelocationPackage/RelatedPage/RelatedPage';

const RelocationRoutes = () => {
  return (
    <Switch>
      <Route exact path="/relocation" component={Relocation} />
      <Route exact path="/relocation/about/:nameCountry" component={RelocationPackage} />
      <Route path="/relocation/about/:nameCountry/:relatedPageId" component={RelatedPage} />
      <Route path="/relocation/faq" component={FrequentlyAskedQuestion} />
      <Route path="/relocation/ask-question" component={AskQuestion} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default RelocationRoutes;
