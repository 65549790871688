import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ModalConfirm from '../../Common/ModalConfirm/ModalConfirm';
import { deleteEmployeeProfile } from '../../../store/actions/employeeProfile';
import './deleteEmployeeProfile.scss';

const DeleteEmployeeProfile = ({ employeeId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);

  const deleteProfile = () => {
    dispatch(deleteEmployeeProfile(employeeId)).then((response) => {
      if (response?.status === 204) {
        history.push(`/legalization-documents`);
      }
    });
    setConfirmModalVisible(false);
  };

  return (
    <div className="delete-profile__container">
      <span className="delete-profile_btn" onClick={() => setConfirmModalVisible(true)}>
        Delete profile
      </span>

      {isConfirmModalVisible && (
        <ModalConfirm
          isModalVisible={isConfirmModalVisible}
          setModalVisible={setConfirmModalVisible}
          confirmationQuestion={
            'The data about this Candidate will be deleted permanently. Are you sure you want to delete this Candidate profile?'
          }
          handleClick={() => {
            deleteProfile();
          }}
        />
      )}
    </div>
  );
};

export default DeleteEmployeeProfile;
