import actionCreators from './actionCreators';
import axios from '../../axios';
import queryString from 'query-string';

export const getRelatedProfile = (id, rel_req_id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/relocation-requests/${id}/relative-requests/${rel_req_id}/`);
    dispatch(actionCreators.relatedInfoLoaded(response.data, rel_req_id));

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getRelativeRelocationHistory = (requestId, relativeReqId, page) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/${requestId}/relative-requests/${relativeReqId}/history/`, {
        params: { page: page },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.relativeRequestHistoryLoaded(response.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.relativeRequestHistoryError());
      });
  };
};
