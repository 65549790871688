import { types } from '../actions/types';

const initialState = {
  employeeHistory: [],
  next: null,
  previous: null,
  count: 0,
};

const employeeHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EMPLOYEE_HISTORY_LOADED:
      return {
        ...state,
        employeeHistory: action.payload.employeeHistory,
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
      };
    case types.EMPLOYEE_HISTORY_ERROR:
      return {
        ...state,
        employeeHistory: action.payload.employeeHistory,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    default:
      return {
        ...state,
      };
  }
};

export default employeeHistoryReducer;
