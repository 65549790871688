import React, { useEffect, useState, createContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { getRelatedProfile } from '../../store/actions/requestRelatedProfiles';
import { getRequestDetails } from '../../store/actions/requestDetails';
import SpinnerContainer from '../Common/SpinnerContainer/SpinnerContainer';
import QuickNavUp from '../Shared/QuickButtonsBlock/QuickNavUp/QuickNavUp';
import SiderMenu from './Relatives/SiderMenu/SiderMenu';
import ContentRoutes from './Relatives/ContentRoutes/ContentRoutes';
import { getIsCaseDisabled } from '../../helpers';
import { defaultDateFormat } from '../../constants';

import './requestRelatedDetails.scss';

export const IsRelativeCaseDisabledContext = createContext();

const RequestRelatedDetails = ({ match }) => {
  const dispatch = useDispatch();

  const requestId = match.params.id;
  const relativeReqId = match.params.rel_req_id;

  const request = useSelector((state) => state.requestDetails[match.params.id]?.requestDetails);
  const relatedInfo = useSelector((state) => state.requestRelatedProfiles[relativeReqId]?.relatedInfo);

  const [isLoading, setLoading] = useState(!request?.id || !relatedInfo?.id);

  const isRelativeCaseDisabled =
    request &&
    getIsCaseDisabled({
      currentEmploymentInfo: request?.employee?.currentEmploymentInfo,
      isActive: request.employee.isActive,
      status: request.status,
    });

  useEffect(() => {
    if (request?.id !== +requestId) {
      dispatch(getRequestDetails(requestId));
    }
    if (request?.id && relatedInfo?.id) {
      setLoading(false);
    }
  }, [requestId, dispatch, relativeReqId, request?.id, relatedInfo?.id, request?.employee.uuid]);

  useEffect(() => {
    if (request?.id) {
      dispatch(getRelatedProfile(request?.id, relativeReqId));
    }
  }, [request?.id, relativeReqId]);

  return (
    <IsRelativeCaseDisabledContext.Provider value={isRelativeCaseDisabled}>
      <>
        {!isLoading && request && relatedInfo && (
          <section className=" RelativeRequestDetails">
            <div className="request-top">
              <h3>{relatedInfo?.relative.generalInfo?.fullName}</h3>
              <div className="updated-at-by">
                Updated: {dayjs(new Date(relatedInfo.updatedAt)).format(defaultDateFormat)} by {relatedInfo.updatedBy}
              </div>
            </div>
            <div className="relative-request-details__container">
              <SiderMenu relatedInfo={relatedInfo} />
              <ContentRoutes
                requestId={requestId}
                relatedInfo={relatedInfo}
                request={request}
                relativeReqId={relativeReqId}
                isRelativeCaseDisabled={isRelativeCaseDisabled}
              />
            </div>

            <QuickNavUp />
          </section>
        )}
        {!isLoading && !request && !relatedInfo && <h1 className="no-data">Request not found!</h1>}
        {isLoading && <SpinnerContainer />}
      </>
    </IsRelativeCaseDisabledContext.Provider>
  );
};

export default RequestRelatedDetails;
