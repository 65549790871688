import Modal from '../../Shared/Modal/Modal';
import Note from './NoteTab/Note';
import ExitIcon from '../../../assets/img/exit.svg';
import CreateNewReminder from './CreateNewReminder/CreateNewReminder';
import RemindersTab from './RemindersTab/ReminerTable';

import './NotesModal.scss';

const NotesModal = ({
  requestId,
  employeeId,
  requestNote,
  currentTab,
  setCurrentTab,
  isModalVisible,
  setModalVisible,
}) => {
  const requestTabs = [
    {
      id: 0,
      label: 'Create/edit note',
      component: (
        <Note
          currentTab={currentTab}
          requestId={requestId}
          requestNote={requestNote}
          setModalVisible={setModalVisible}
        />
      ),
    },
    {
      id: 1,
      label: 'New reminder',
      component: (
        <CreateNewReminder
          currentTab={currentTab}
          setModalVisible={setModalVisible}
          employeeId={employeeId}
          requestId={requestId}
        />
      ),
    },
    { id: 2, label: 'Reminders', component: <RemindersTab currentTab={currentTab} employeeId={employeeId} /> },
  ];

  const profileTabs = [
    {
      id: 0,
      label: 'New reminder',
      component: <CreateNewReminder setModalVisible={setModalVisible} employeeId={employeeId} requestId={requestId} />,
    },
    { id: 1, label: 'Reminders', component: <RemindersTab employeeId={employeeId} /> },
  ];

  const isRequestModal = requestNote !== undefined;
  const tabs = isRequestModal ? requestTabs : profileTabs;

  return (
    <div className="right-section">
      <div className="add-notes">
        {!requestNote && isRequestModal && (
          <button className="add-notes__button button btn-form main-btn btn-back" onClick={() => setModalVisible(true)}>
            Add notes
          </button>
        )}
        {(!isRequestModal || (isRequestModal && requestNote)) && (
          <button
            className="add-notes__button button btn-form main-btn btn-back"
            onClick={() => {
              setCurrentTab(isRequestModal && requestNote ? 1 : 0);
              setModalVisible(true);
            }}
          >
            Add reminders
          </button>
        )}
        {isModalVisible && (
          <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l">
            <div className="add-item-to-legalization-table__content">
              <div className="add-item-to-legalization-table__title">
                <h4 className="panel-top-row add-notes__underlined">Notes</h4>
                <img src={ExitIcon} alt="close" onClick={() => setModalVisible(false)} />
              </div>
              <div className="add-notes__buttons">
                {tabs.map((item) => {
                  return (
                    <button
                      key={item.id}
                      className={`tab-button ${item.id === currentTab ? 'tab-active' : 'tab-disabled'}`}
                      onClick={() => {
                        setCurrentTab(item.id);
                      }}
                    >
                      {item.label}
                    </button>
                  );
                })}
                <div className="add-notes__tab-underline" />
              </div>
              <div className="form">{tabs[currentTab].component}</div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default NotesModal;
