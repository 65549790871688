import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { getFormElement } from '../../../../../Shared/Form/FormElements';
import { editRelativeEmploymentInfo } from '../../../../../../store/actions/requestDetails';
import { SubmitButton } from '../../../../../Shared/Form/Buttons/Buttons';
import './relativeEmploymentInfo.scss';

const validationSchema = Yup.object().shape({
  company: Yup.string().required('This field is required'),
  position: Yup.string().required('This field is required'),
});

const formSchema = [
  {
    fieldName: 'company',
    type: 'TextInput',
    label: 'Company name',
    required: true,
  },
  {
    fieldName: 'position',
    type: 'TextInput',
    label: 'Position',
    required: true,
  },
];

const RelativeEmploymentInfo = ({ employeeUuid, relativeId, relReqId, employmentInfo }) => {
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    dispatch(editRelativeEmploymentInfo(values, employeeUuid, relativeId, relReqId));
    setSubmitting(false);
  };
  return (
    <div className="relative-employment-info">
      <Formik
        enableReinitialize
        initialValues={{ company: employmentInfo?.company, position: employmentInfo?.position }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form>
            {formSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field)}</div>
            ))}
            <SubmitButton title="Save" formikProps={formikProps} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default RelativeEmploymentInfo;
