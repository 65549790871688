import { types } from '../actions/types';

const initialState = {
  legalizationDocuments: [],
  trackingDocuments: [],
  next: null,
  previous: null,
  count: 0,
  countByGroups: { all: 0, candidates: 0, profiles: 0 },
  generationExcelEmployeeIds: {},
  allCases: false,
  excludeIds: [],
};

const legalizationDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LEGALIZATION_DOCUMENTS_LOAD:
      return {
        ...state,
        legalizationDocuments: action.payload.legalizationDocuments,
        next: action.payload.next,
        previous: action.payload.previous,
        countByGroups: action.payload.countByGroups,
        count: action.payload.count,
        page: action.payload.page,
      };
    case types.LEGALIZATION_DOCUMENTS_ALL_CASES_UPDATE:
      return {
        ...state,
        allCases: !state.allCases,
        generationExcelEmployeeIds: {},
        excludeIds: [],
      };
    case types.LEGALIZATION_DOCUMENTS_EXCEL_CHECKBOXES_ADD:
      return {
        ...state,
        ...(state.allCases &&
          !state.excludeIds.includes(action.payload.employeeUuid) && {
            excludeIds: [...state.excludeIds, action.payload.employeeUuid],
          }),
        ...(state.allCases &&
          state.excludeIds.includes(action.payload.employeeUuid) && {
            excludeIds: state.excludeIds.filter((item) => item !== action.payload.employeeUuid),
          }),
        ...(!state.allCases && {
          generationExcelEmployeeIds: {
            ...state.generationExcelEmployeeIds,
            ...action.payload.generationExcelCheckbox,
          },
        }),
      };
    case types.LEGALIZATION_DOCUMENTS_EXCEL_CHECKBOXES_REMOVE:
      return {
        ...state,
        generationExcelEmployeeIds: {},
        allCases: false,
        excludeIds: [],
      };
    case types.LEGALIZATION_DOCUMENTS_ADD_EVENT:
      return {
        ...state,
        legalizationDocuments: state.legalizationDocuments.map((item) => {
          if (item.uuid === action.payload.employeeId && action.payload.event) {
            item.events = [action.payload.event, ...item.events];
            return item;
          }
          return item;
        }),
      };
    case types.TRACKING_DOCUMENTS_LOAD:
      return {
        ...state,
        trackingDocuments: action.payload.trackingDocuments,
      };
    case types.TRACKING_DOCUMENT_ADD: {
      return {
        ...state,
        trackingDocuments: state.trackingDocuments.map((item) => {
          if (+item.id === +action.payload.gfId) {
            item.forms = [...item.forms, action.payload.document];
            item.forms.forEach((form, index) => {
              if (form.formId === null) {
                item.forms.splice(index, 1);
              }
            });
          }
          return item;
        }),
      };
    }
    case types.TRACKING_DOCUMENT_ATTACHMENT_ADD: {
      return {
        ...state,
        trackingDocuments: state.trackingDocuments.map((item) => {
          if (+item.id === +action.payload.gfId) {
            item.forms.forEach((form, index) => {
              if (form.formId === action.payload.formId) {
                form.attachments = [...form.attachments, action.payload.attachments];
              }
            });
          }
          return item;
        }),
      };
    }
    case types.TRACKING_DOCUMENT_ATTACHMENT_DELETE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        trackingDocuments: state.trackingDocuments.map((item) => {
          if (+item.id === +action.payload.gfId) {
            item.forms.forEach((form, index) => {
              if (form.formId === action.payload.formId) {
                form.attachments.forEach((attachment, index) => {
                  if (+attachment.id === +action.payload.attachmentId) {
                    form.attachments.splice(index, 1);
                  }
                });
              }
            });
          }
          return item;
        }),
      };
    }
    case types.LEGALIZATION_DOCUMENTS_UPDATE:
      return {
        ...state,
        legalizationDocuments: [...state.legalizationDocuments, ...action.payload.legalizationDocuments],
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
      };
    case types.LEGALIZATION_DOCUMENTS_CITIZENSHIP_UPDATE:
      return {
        ...state,
        legalizationDocuments: state.legalizationDocuments.reduce((accamulator, currentValue) => {
          if (currentValue.uuid === action.payload.uuid) {
            currentValue = {
              ...currentValue,
              generalInfo: { ...currentValue.generalInfo, citizenship: action.payload.citizenship },
            };
          }
          accamulator.push(currentValue);
          return accamulator;
        }, []),
      };
    case types.LEGALIZATION_DOCUMENTS_REASON_UPDATE:
      return {
        ...state,
        legalizationDocuments: state.legalizationDocuments.reduce((accamulator, currentValue) => {
          if (currentValue.uuid === action.payload.uuid) {
            currentValue = {
              ...currentValue,
              ...action.payload.reason,
            };
          }
          accamulator.push(currentValue);
          return accamulator;
        }, []),
      };
    case types.LEGALIZATION_DOCUMENTS_ERROR:
      return {
        ...state,
        legalizationDocuments: [],
        page: 1,
      };
    default:
      return {
        ...state,
      };
  }
};

export default legalizationDocumentsReducer;
