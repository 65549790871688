import classNames from 'classnames';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { useSelector } from 'react-redux';

import { defaultDateFormat } from '../../../../constants';
import { ReactComponent as AdditionalInfoIcon } from '../../../../assets/img/icons/additional-info.svg';

import './generalDocument.scss';

const GeneralDocument = ({ document, dateNow, thirtyDays, threeMonths }) => {
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const expirationDate = new Date(document?.expirationDate);
  const expirationInMonth = expirationDate - dateNow < thirtyDays;

  const entryDateDocumentWithExpirationInThreeMonths =
    document?.docType?.toLowerCase() === 'entry date' && expirationDate - dateNow < threeMonths;

  return (
    <>
      {document && (
        <div className="general-document">
          {document?.expirationDate ? (
            <>
              <span className="doc-type">{document?.docName || 'not provided'}</span>
              <span
                className={classNames(
                  'exp-date',
                  { expirationInMonth: expirationInMonth },
                  { entryDateDocumentWithExpirationInThreeMonths: entryDateDocumentWithExpirationInThreeMonths },
                )}
              >
                {document?.expirationDate
                  ? `exp. ${dayjs(document.expirationDate).format(displayDateFormat)}`
                  : document?.expirationDateExists
                  ? 'not provided'
                  : ''}
              </span>
            </>
          ) : (
            <div className="doc-without-exp-date">
              <span className="doc-type">{document?.docName || 'not provided'}</span>
              <div className="document-value">
                <Tooltip placement={'top'} title={document?.value || 'not provided'}>
                  <AdditionalInfoIcon
                    data-tip={document?.value || 'not provided'}
                    className={'ant-table-additional-info'}
                    data-for="additional-info-column"
                  />
                </Tooltip>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default GeneralDocument;
