import { types } from '../actions/types';

const initialState = {
  employeeComments: {},
};

const employeeCommentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EMPLOYEE_COMMENTS_LOAD:
      const targetId = action.payload?.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          count: action.payload?.count,
          comments: action.payload?.comments,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default employeeCommentsReducer;
