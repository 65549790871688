import { useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Formik, Form } from 'formik';

import Modal from '../../../../Shared/Modal/Modal';
import SelectDocuments from './SelectDocuments/SelectDocuments';
import SetDeadLine from './SetDeadline/SetDeadline';
import ExitImg from '../../../../../assets/img/exit.svg';
import { validationSchema, initialValues } from './formSchema';
import { requestEmployeeFeedback } from '../../../../../store/actions/feedbackForm';

import './requestFeedbackModal.scss';

const RequestFeedbackModal = ({ isModalVisible, setModalVisible, requestId, documents }) => {
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);

  const formSchema = documents.map((item) => {
    return { fieldName: +item.id, type: 'CheckboxReverse', label: item.name };
  });

  const onSubmit = (values, { setSubmitting }) => {
    const docsValues = Object.keys(values).reduce((object, key) => {
      if (key !== 'date' && key !== 'displayVacIsRequired' && key !== 'displayVacIsRequiredForRelatives') {
        object[key] = values[key];
      }
      return object;
    }, {});

    const documentsSentList = _.keys(_.pickBy(docsValues));

    dispatch(requestEmployeeFeedback(requestId, { forms_ids: documentsSentList, deadline: values.date })).finally(
      () => {
        setSubmitting(false);
        setModalVisible(true);
      },
    );
  };

  return (
    <div className="select-documents request-feedback-modal">
      <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l">
        <div className="select-documents__wrapper">
          <div className="select-documents-send__title">
            <h3 className="underlined panel-top-row">
              {step === 1 ? 'Select feedback forms to send' : 'Set deadline'}
            </h3>
            <img src={ExitImg} alt="close" onClick={() => setModalVisible(false)} />
          </div>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formikProps) => {
              return (
                <>
                  <Form>
                    {step === 1 && (
                      <SelectDocuments
                        setModalVisible={setModalVisible}
                        formikProps={formikProps}
                        setStep={setStep}
                        formSchema={formSchema}
                      />
                    )}
                    {step === 2 && (
                      <SetDeadLine setStep={setStep} formikProps={formikProps} selectedValues={formikProps.values} />
                    )}
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default RequestFeedbackModal;
