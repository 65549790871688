import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  question: Yup.string()
    .trim()
    .required('You cannot submit an empty form. Please add your question.')
    .test(
      'max-question-length',
      'Maximum question size is 6000 characters',
      (question) => !question || question.length <= 6000,
    ),
});
