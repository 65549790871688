import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CustomTable from '../Shared/Table/CustomTable';
import { editReminderStatus, getReminders } from '../../store/actions/reminders';
import QuickNavUp from '../Shared/QuickButtonsBlock/QuickNavUp/QuickNavUp';
import ContentWrapper from '../Shared/Table/ContentWrapperMobileView/ContentWrapperMobileView';

import TitleWithSort from '../Shared/Table/TitleWithSort/TitleWithSort';
import FilterDropdownIcon from '../Shared/Table/FilterIcon/FilterIcon';

import ExcelTitle from '../RelocatedEmployees/generationAllExcel/ExcelTitle';
import { TableCheckbox } from '../Shared/Table/Checkbox/Checkbox';
import axios from '../../axios';
import queryString from 'query-string';
import actionCreators from '../../store/actions/actionCreators';
import FeedbackColumn from '../FeedbackDashboard/FeedbackColumn/FeedbackColumn';
import classNames from 'classnames';
import { defaultDateFormat } from '../../constants';
import clearDocumentsDashboardReducer from '../../store/reducers/clearDocumentsDashboard';
import dayjs from 'dayjs';

export const getClearDocumentDashboard = (page, filterParams, params) => {
  return (dispatch) => {
    return axios
      .get('/api/v1/user-documents/clear-dashboard/', {
        params: { page: page, ...params, ...filterParams },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.clearDocumentsDashboardLoad(response.data));

        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const editDocumentsWillBeDeleted = (id, data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .put(`/api/v1/user-documents/clear-dashboard/${id}/`, data)
      .then((response) => {
        dispatch(actionCreators.clearDocumentsDashboardUpdate(response.data, response.data?.id));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

const ClearDocumentsDashborad = () => {
  const dispatch = useDispatch();

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;
  const { count, clearDocumentsDashboard } = useSelector((state) => state.clearDocumentsDashboardReducer);

  const changeIsWillBeDeleted = (id, value) => {
    dispatch(editDocumentsWillBeDeleted(id, { isWillBeDeleted: value }));
  };

  const data = clearDocumentsDashboard.map((item, index) => {
    return {
      key: index,
      number: `${index + 1}`,
      employeeName: (
        <ContentWrapper dataLabel={'Employee name'}>
          <Link className={'employee-name'} to={`/profile/${item.objectId}/`}>
            {item.objectName}
          </Link>
        </ContentWrapper>
      ),
      documentName: <ContentWrapper dataLabel={'Author'}>{item.formName}</ContentWrapper>,
      dateOfDeletion: (
        <ContentWrapper dataLabel={'Reminder date'}>
          {item.willBeDeletedAt ? dayjs(item.willBeDeletedAt).format(displayDateFormat) : 'Not provided'}
        </ContentWrapper>
      ),
      isWillBeDelete: (
        <ContentWrapper dataLabel={'Excel'}>
          <TableCheckbox
            value={item.isWillBeDeleted}
            onChange={() => changeIsWillBeDeleted(item.id, !item.isWillBeDeleted)}
          />
        </ContentWrapper>
      ),
    };
  });

  const columns = [
    {
      key: 'number',
      title: '№',
      label: '№',
      dataIndex: 'number',
      fixed: 'left',
      width: '2%',
    },
    {
      title: 'Employee name',
      label: 'Employee name',
      key: 'employeeName',
      dataIndex: 'employeeName',
      fixed: 'left',
      width: '15%',
    },
    {
      title: 'Document name',
      label: 'Document name',
      key: 'documentName',
      dataIndex: 'documentName',
      width: '15%',
      filteredValue: null,
    },
    {
      title: 'Date of deletion',
      label: 'Date of deletion',
      key: 'dateOfDeletion',
      dataIndex: 'dateOfDeletion',
      width: '15%',
      filteredValue: null,
    },
    {
      title: 'Is will be delete?',
      key: 'isWillBeDelete',
      dataIndex: 'isWillBeDelete',
      width: '5%',
    },
  ];

  return (
    <section className="reminders">
      <div className="reminders-dashboard__header">
        <h3 className="underlined panel-top-row">Documents that will be deleted</h3>
      </div>
      <>
        <CustomTable
          tableKey={'ClearDocumentsDashboard'}
          data={data}
          columns={columns}
          count={count}
          sortMapping={[]}
          filterMapping={[]}
          getTableDataCallback={getClearDocumentDashboard}
          mobileView={true}
          pageSize={50}
        />
      </>
      <QuickNavUp />
    </section>
  );
};

export default ClearDocumentsDashborad;
