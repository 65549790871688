import { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { exportExcel } from '../../../../../store/actions/requestDetails';
import SpinnerImg from '../../../../../assets/img/SpinnerWhite.svg';
import { useGetParams } from '../../useGetParams';
import '../caseTable.scss';

const GenerateExcel = ({ generationExcelCheckboxes, allCases, exludeIds }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [isLoading, setLoading] = useState(false);

  const generationExcelRequestsIds = Object.keys(generationExcelCheckboxes).filter(
    (key) => generationExcelCheckboxes[key],
  );

  const paramsFromLocation = location.search;

  const paramsFromLocalStorage = JSON.parse(localStorage.getItem(`casesFilters`));

  const fillteringParams = useGetParams({
    paramsFromLocalStorage: paramsFromLocalStorage,
    paramsFromLocation: paramsFromLocation,
  });

  const params = queryString.parse(fillteringParams);

  const generateExcel = () => {
    setLoading(true);
    const relocationRequestsIds = {
      objectUuids: generationExcelRequestsIds,
      excludeIds: exludeIds,
      allObjects: allCases,
    };

    dispatch(exportExcel(relocationRequestsIds, params)).then((response) => setLoading(false));
  };

  const generateExcelBtnDisabled = generationExcelRequestsIds.length === 0 && !allCases;
  return (
    <div className="generate-excel__container">
      <button
        className={classNames('button btn-form main-btn btn-back generate-excel')}
        onClick={generateExcel}
        disabled={generateExcelBtnDisabled}
      >
        {isLoading ? <img src={SpinnerImg} alt="SpinnerContainer" /> : 'Generate Excel file'}
      </button>
    </div>
  );
};

export default GenerateExcel;
