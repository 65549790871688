import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import ImageGride from '../ImageGride/ImageGride';
import actionCreators from '../../../../../store/actions/actionCreators';

import { ReactComponent as UploadPhotoIcon } from '../../../../../assets/img/icons/UploadPhoto.svg';

const Dropzone = ({ formikProps, name, forceDisabled }) => {
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [isDrag, setIsDrag] = useState(false);

  useEffect(() => {
    setFiles([]);
  }, [formikProps.dirty]);

  const maxSize = 52428800;
  const accept = {
    'image/png': ['.png'],
    'image/jpeg': ['.jpeg', '.jpg'],
    'application/pdf': ['.pdf'],
    'application/msword': ['.doc'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  };
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (!forceDisabled) {
        formikProps.setFieldValue(name, [...files, ...acceptedFiles]);
        setFiles((prev) => [...prev, ...acceptedFiles]);
        setIsDrag(false);
      }
    },
    [files],
  );

  const onDropRejected = () => {
    dispatch(
      actionCreators.toastUpdate({
        type: 'error',
        message:
          'Please attach files that are less than 50 MB and in one of the following formats: DOC, DOCX, JPG, JPEG, PNG, PDF, XLS, XLSX.',
      }),
    );
  };

  const onDragEnter = () => {
    setIsDrag(true);
  };

  const onDragLeave = () => {
    setIsDrag(false);
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles, open } = useDropzone({
    accept,
    onDrop,
    onDropRejected,
    onDragEnter,
    onDragLeave,
    maxSize,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <div className="form-input-width dropzone-container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input id="file" name="files" {...getInputProps()} />

        {files.length > 0 ? (
          <ImageGride files={files} setFiles={setFiles} open={open} formikProps={formikProps} name={name} />
        ) : (
          <div
            onClick={forceDisabled ? null : open}
            className={classNames(
              forceDisabled && 'click-zone__disabled',
              isDrag && !forceDisabled ? 'drag-click-zone' : 'click-zone',
            )}
          >
            <p className="dropzone-text">Select or drop files here</p>
            <p className={classNames('dropzone-text', 'available-files', isDrag && 'drag-available-files')}>
              (DOC, DOCX, JPG, JPEG, PNG, PDF, XLS, XLSX)
            </p>
            <UploadPhotoIcon className={isDrag ? 'drag-dropzone-image' : 'dropzone-image'} />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
