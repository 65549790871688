import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import { getFormElement } from '../../../Shared/Form/FormElements';
import { editRelocationRequest } from '../../../../store/actions/request';
import { formSchema as formSchemaWithoutOptions } from './requestSchema';
import { SubmitButton } from '../../../Shared/Form/Buttons/Buttons';

import './requestDetailsForm.scss';

const withWhom = ['withSpouse', 'withChildren', 'withPets'];

const RequestForm = ({ isCaseDisabled, consultationLocationOptions, ...request }) => {
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    const valuesInfo = { ...values };
    withWhom.map((i) => (valuesInfo.withWhom.includes(i) ? (valuesInfo[i] = true) : (valuesInfo[i] = false)));
    if (!valuesInfo.period) {
      delete valuesInfo.period;
    }
    if (!valuesInfo.consultationCountry) {
      delete valuesInfo.consultationCountry;
    }
    dispatch(editRelocationRequest(request.id, valuesInfo)).finally(() => setSubmitting(false));
  };

  const formSchema = formSchemaWithoutOptions.map((item) => {
    if (item.fieldName === 'consultationCountry') {
      item.options =
        consultationLocationOptions.length > 0
          ? consultationLocationOptions
              .map((item) => ({ id: item.name, name: item.name }))
              .concat([{ id: 'Other', name: 'Other' }])
          : [];
    }
    return { ...item };
  });

  return (
    <div className="request-details-form">
      {request && (
        <Formik enableReinitialize initialValues={request} onSubmit={onSubmit}>
          {(formikProps) => (
            <Form>
              {formSchema.map((field) => (
                <div key={field.fieldName}>
                  {field.fieldName !== 'customCity' && (
                    <div className={field.fieldName}>
                      {getFormElement(field.type, field, formikProps, isCaseDisabled)}
                    </div>
                  )}
                  {field.fieldName === 'customCity' &&
                    request.cityName?.toUpperCase().includes('OTHER') &&
                    request.customCity &&
                    getFormElement(field.type, field, formikProps, isCaseDisabled)}
                </div>
              ))}
              <SubmitButton title="Submit" formikProps={formikProps} />
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default RequestForm;
