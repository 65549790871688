import { useState, useCallback } from 'react';

const usePersistentState = (key, defaultValue = null) => {
  const [state, setState] = useState(() => {
    const storedState = localStorage.getItem(key);

    return storedState ? JSON.parse(storedState) : defaultValue;
  });

  const handleStateChange = useCallback(
    (newState) => {
      setState(newState);
      localStorage.setItem(key, newState);
    },
    [key, setState],
  );

  return [state, handleStateChange];
};

export default usePersistentState;
