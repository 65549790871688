import React, { useContext, useEffect, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { sendVACdetails, upsertVacAppointments } from '../../../store/actions/requestDetails';
import {
  getAddressInfoOptions,
  getValuesToUpdate,
  parseVacAppointment,
  setAllValuesToNull,
  setIsCurrentVacAppointment,
} from '../../../helpers';
import { getVacAppointmentAddresses } from '../../../store/actions/requestVacAppointment';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import VacField from './VacField/VacField';
import {
  CheckboxesSchema,
  formSchema,
  vacAppointmentInitialValues,
  validationSchema,
  visaAppealSchema,
  visaNotesSchema,
  visaStatusSchema,
} from './schemaProperties';

import { IsCaseDisabledContext } from '../RequestDetails';

import './vacAppointment.scss';

const VacAppointment = ({ requestId, vacInfo: vacAppointment }) => {
  const dispatch = useDispatch();

  const addressesInfo = useSelector((state) => state.vacAppointmentReducer.addressesInfo);

  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const currentVAC = vacAppointment.find((item) => item.isCurrent === true);
  const isEmployeeNotifiedForCurrentVAC = currentVAC ? currentVAC.isEmployeeNotifiedNew?.value : true;
  const vacInfo = parseVacAppointment(vacAppointment);
  const initialValues = {
    vacInfo: vacAppointment.length ? vacInfo : [vacAppointmentInitialValues],
  };

  vacInfo.forEach((item) => {
    if (!item.appeal) {
      item.appeal = {
        submissionDate: null,
        decisionDate: null,
        decision: null,
      };
    }
    item.time = {
      startTime: item?.mainTime || null,
      endTime: item?.possibleTime || null,
    };
  });

  const [addressInfoOptions, setAddressInfoOptions] = useState(getAddressInfoOptions(addressesInfo));

  const cityOptions = [
    ...new Map(addressesInfo.map((item) => [item.city?.id, { key: item.city?.id, value: item.city?.name }])).values(),
  ];

  useEffect(() => {
    setAddressInfoOptions(getAddressInfoOptions(addressesInfo));
  }, [addressesInfo]);

  useEffect(() => {
    if (!addressesInfo?.length) {
      dispatch(getVacAppointmentAddresses());
    }
  }, [dispatch, addressesInfo?.length]);

  const onChangeSelectedAddress = (addressInfoId, formikProps, formIndex) => {
    const selectedCity = addressesInfo.find((item) => item.id === addressInfoId).city?.name;

    formikProps.setFieldValue(`vacInfo.${formIndex}.addressInfo.city.name`, selectedCity);
  };

  formSchema.forEach((item) => {
    if (item.fieldName === 'addressInfo.id') {
      item.options = addressInfoOptions;
      item.updateselectvalueroot = onChangeSelectedAddress;
    }
    if (item.fieldName === 'addressInfo.city.name') {
      item.options = cityOptions;
    }
    if (item.fieldName === 'isCurrent') {
      item.updateselectvalueroot = setIsCurrentVacAppointment;
    }
  });

  const handleSendVACDetails = () => {
    dispatch(sendVACdetails(currentVAC.id, requestId));
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    values.vacInfo.map((vacInfo, formIndex) => {
      vacInfo.addressInfoId = vacInfo.addressInfo?.id;

      if (!vacInfo.id || !vacInfo.appeal?.submissionDate) {
        delete vacInfo.appeal;
      }

      vacInfo.mainTime = vacInfo.time.startTime;
      vacInfo.possibleTime = vacInfo.time.endTime;

      // delete vacInfo.time;
    });

    const valuesToUpdate = getValuesToUpdate(values.vacInfo, vacAppointment, Object.keys(vacAppointmentInitialValues));

    const vacAppointmentIsNotCurrent = getValuesToUpdate(values.vacInfo, vacAppointment, ['isCurrent']).filter(
      (item) => item.isCurrent === false,
    );

    const valuesToAdd = values?.vacInfo.filter(({ id }) => isNaN(id));

    const valuesToUpdateWithNullValues = setAllValuesToNull(valuesToUpdate);
    const vacAppointmentIsNotCurrentWithNullValues = setAllValuesToNull(vacAppointmentIsNotCurrent);
    const valuesToAddWithNullValues = setAllValuesToNull(valuesToAdd);

    if (
      valuesToUpdateWithNullValues.length ||
      valuesToAddWithNullValues.length ||
      vacAppointmentIsNotCurrentWithNullValues.length
    ) {
      dispatch(
        upsertVacAppointments(
          valuesToUpdateWithNullValues,
          valuesToAddWithNullValues,
          vacAppointmentIsNotCurrentWithNullValues,
          requestId,
        ),
      ).finally(() => {
        setSubmitting(false);
      });
    }
  };

  return (
    <div className="VacAppointment">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps) => {
          return (
            <Form>
              <FieldArray
                name="vacInfo"
                render={({ remove, push }) => (
                  <>
                    <VacField
                      values={formikProps.values}
                      formSchema={formSchema}
                      ChekboxesSchema={CheckboxesSchema}
                      visaStatusSchema={visaStatusSchema}
                      visaAppealSchema={visaAppealSchema}
                      visaNotesSchema={visaNotesSchema}
                      formikProps={formikProps}
                      requestId={requestId}
                      remove={remove}
                    />
                    <div className="button-wrapper">
                      <SubmitButton title="Submit" formikProps={formikProps} />
                      <button
                        type="button"
                        className="button btn-form main-btn"
                        onClick={() => {
                          push(vacAppointmentInitialValues);
                          setIsCurrentVacAppointment(true, null, formikProps);
                        }}
                      >
                        Add VAC
                      </button>
                      {vacInfo.length > 0 && (
                        <button
                          type="button"
                          className={classNames(
                            'button btn-form main-btn btn-back',
                            isCaseDisabled || isEmployeeNotifiedForCurrentVAC ? 'btn-disabled' : '',
                          )}
                          onClick={() => handleSendVACDetails()}
                          disabled={isCaseDisabled || isEmployeeNotifiedForCurrentVAC}
                        >
                          Send VAC details to Employee
                        </button>
                      )}
                    </div>
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default VacAppointment;
