import React, { useState } from 'react';
import classNames from 'classnames';

import NewRelativeForm from './RelativeForms/NewRelativeForm';
import ExistingRelativeForm from './RelativeForms/ExistingRelativeForm';

const RelatedModal = ({ request, requestId, isCaseDisabled }) => {
  const [actionToggle, setActionToggle] = useState(true);

  const [relativeError, setRelativeError] = useState(false);

  const [show, setShow] = useState(false);

  const petsNotInRequest = request.petsNotInRequest.length;
  const relativesNotInRequest = request.relativesNotInRequest.length;

  return (
    <>
      <div className="button-wrapper">
        <button
          className={classNames('button btn-form main-btn', isCaseDisabled ? 'btn-disabled' : '')}
          onClick={() => {
            setShow(true);
          }}
          disabled={isCaseDisabled}
        >
          Add related profiles
        </button>
      </div>
      <div className={`add-relative__wrapper ${show ? 'show' : ''}`}>
        <div className="add-relative">
          <div className="toggle-button__wrapper">
            <button
              className={`toggle-button toggle-button-left ${actionToggle ? 'tab-active' : 'tab-disabled'}`}
              onClick={() => {
                setActionToggle(true);
                setRelativeError(false);
              }}
            >
              Add new relative
            </button>
            <button
              className={`toggle-button toggle-button-right ${
                !petsNotInRequest && !relativesNotInRequest && 'disabledTab'
              } ${actionToggle ? 'tab-disabled' : 'tab-active'}`}
              onClick={() => (petsNotInRequest || relativesNotInRequest) && setActionToggle(false)}
            >
              Select existing relative
            </button>
          </div>
          <div className={`relative-wrapper ${relativeError ? 'error' : ''}`}>
            {actionToggle ? (
              <NewRelativeForm
                request={request}
                requestId={requestId}
                setShow={setShow}
                setRelativeError={setRelativeError}
                setActionToggle={setActionToggle}
              />
            ) : !petsNotInRequest && !relativesNotInRequest ? (
              <NewRelativeForm
                request={request}
                requestId={requestId}
                setShow={setShow}
                setRelativeError={setRelativeError}
              />
            ) : (
              <ExistingRelativeForm
                request={request}
                setActionToggle={setActionToggle}
                setShow={setShow}
                setRelativeError={setRelativeError}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default RelatedModal;
