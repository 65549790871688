import { title, radioGroupOptions, filters, getSeriesData, customOptions } from './statisticsProperties';
import ChartBlock from '../../Common/ChartBlock/ChartBlock';
import { useGetStatisticsFilters } from '../../Common/hooks/useGetStatisticsFilters';

const ResidencePermitStatistics = () => {
  const [statisctics, radioValue, onChangeRadioGroup, dateRangeValue, onChangeDateRange] = useGetStatisticsFilters({
    filters,
  });

  const series = getSeriesData(statisctics.data);

  return (
    <ChartBlock
      title={title}
      isLoading={{ isLoading: statisctics.isLoading, status: statisctics.statusRequest }}
      radioGroupOptions={radioGroupOptions}
      radioValue={radioValue}
      onChangeRadioGroup={onChangeRadioGroup}
      dateRangeValue={dateRangeValue}
      onChangeDateRange={onChangeDateRange}
      series={series}
      customOptions={customOptions}
    />
  );
};

export default ResidencePermitStatistics;
