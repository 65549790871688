import { getFormElement } from '../../../../../../Shared/Form/FormElements';
import useWindowDimensions from '../../../../../../Shared/Hooks/useWindowDimensions';

import './selectDocumentsForm.scss';

const SelectDocumentsForm = ({ formikProps, onNext, formSchema, formSchemaForForms, setModalVisible }) => {
  const { width } = useWindowDimensions();

  return (
    <div className={'employee-select-documents'}>
      <>
        <div className="employee-select-documents__forms-container">
          <h4 className="select-documents__title">Select documents</h4>
          {formSchema?.length > 0 ? (
            <div
              className="form__container"
              style={{
                gridTemplateRows: `repeat(${Math.ceil(
                  width <= 1600 ? formSchema.length / 2 : formSchema.length / 3,
                )}, auto)`,
              }}
            >
              {formSchema.map((field) => (
                <div key={field.fieldName} className={field.fieldName}>
                  {getFormElement(field.type, field, formikProps)}
                </div>
              ))}
            </div>
          ) : (
            <p className="no-available-forms">
              No documents have been added to this profile. Please select documents to display
            </p>
          )}
        </div>

        <div className="employee-select-documents__forms-container">
          <h4 className="select-documents__title">Select forms</h4>
          {formSchemaForForms?.length > 0 ? (
            <div
              className="form__container"
              style={{
                gridTemplateRows: `repeat(${Math.ceil(
                  width <= 1600 ? formSchemaForForms.length / 2 : formSchemaForForms.length / 3,
                )}, auto)`,
              }}
            >
              {formSchemaForForms.map((field) => (
                <div key={field.fieldName} className={field.fieldName}>
                  {getFormElement(field.type, field, formikProps)}
                </div>
              ))}
            </div>
          ) : (
            <p className="no-available-forms">No forms available for Employee</p>
          )}
        </div>
      </>
      <div className="employee-select-documents__button-container">
        <button type="button" className="button btn-form main-btn" onClick={() => setModalVisible(false)}>
          Cancel
        </button>
        <button type="button" className="button btn-form main-btn btn-back" onClick={onNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectDocumentsForm;
