import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QuickButtonsBlock from '../Shared/QuickButtonsBlock/QuickButtonsBlock';
import './legalizationRequest.scss';

import { FieldArray, Form, Formik } from 'formik';
import { getFormElement } from '../Shared/Form/FormElements';
import { SubmitButton } from '../Shared/Form/Buttons/Buttons';

import axios from '../../axios';
import SpinnerContainer from '../Common/SpinnerContainer/SpinnerContainer';
import { sendFeedback } from '../../store/actions/employeeFeedback';

import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import Modal from '../Shared/Modal/Modal';
import BackArrow from '../Relocation/BackArrow/BackArrow';
import { getTypeSchema } from '../../helpers';
import * as Yup from 'yup';
import { useGetValidationSchema } from './LegalizationRequestForm/hooks';

const StatusModal = ({ isModalVisible, setIsModalVisible }) => {
  const history = useHistory();

  const handleCloseModal = () => {
    setIsModalVisible((prev) => ({ ...prev, isVisible: false }));
    if (isModalVisible.isSuccess) {
      history.push(`/`);
    }
  };

  const message = isModalVisible.isSuccess
    ? 'Thank you! Your legalization request has been sent to the Global Mobility Team. We will reach out to you, should we have any questions.'
    : isModalVisible.error
    ? parse(isModalVisible.error)
    : 'Sorry, something went wrong during sending feedback. Try to send feedback again';

  const title = isModalVisible.isSuccess ? 'Thank you' : 'Error';

  return (
    <div className="send-feedback__status">
      <Modal isVisible={isModalVisible.isVisible} setIsVisible={handleCloseModal} size="l">
        <div className="modal-message">
          <h4 className="underlined panel-top-row">{title}</h4>
          <div className={'message'}>{message}</div>
          <button type="button" className="button btn-form main-btn btn-back ok-btn" onClick={handleCloseModal}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
};

const LegalizationRequest = ({ match }) => {
  const dispatch = useDispatch();

  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleForWhomChange = (value) => {
    setSelectedOption(value);
  };

  const handleCountryIdChange = (value) => {
    setSelectedCountryId(value);
  };

  const getLegalizationRequestForms = async (params) => {
    try {
      const response = await axios.post('/api/v1/legalization-request/forms/', params);
      // dispatch(actionCreators.feedbackFormLoaded(response.data));
      setLegalizationRequestForms(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getLegalizationRequestInitialData = async () => {
    try {
      const response = await axios.get('/api/v1/legalization-request/forms/');
      // dispatch(actionCreators.feedbackFormLoaded(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const getLegalizationRequestAvailableCountries = async () => {
    try {
      const response = await axios.get('/api/v1/legalization-request/forms/countries/');
      // dispatch(actionCreators.feedbackFormLoaded(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [isInitialDataLoading, setIsInitialDataLoading] = useState(true);
  const [isAvailableCountriesLoading, setIsAvailableCountriesLoading] = useState(true);
  const [isFormsLoading, setFormsLoading] = useState(false);

  const [legalizationRequestForms, setLegalizationRequestForms] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [validationSchema, setValidationSchema] = useState(Yup.object().shape({}));

  useEffect(() => {
    if (selectedCountryId && selectedOption) {
      const queryParams = {
        country_id: selectedCountryId,
        for_whom: selectedOption,
      };

      setFormsLoading(true);
      getLegalizationRequestForms(queryParams).then((r) => {
        setLegalizationRequestForms(r.forms);
        setInitialValues({ countryId: selectedCountryId, forWhom: selectedOption, ...useGetInitialValues(r.forms) });
        setValidationSchema(useGetValidationSchema(r.forms));
        setFormsLoading(false);
      });
    }
  }, [selectedCountryId, selectedOption]);

  const createLegalizationRequest = async (values) => {
    try {
      const response = await axios.post('/api/v1/legalization-request/', values);
      // dispatch(actionCreators.feedbackFormLoaded(response.data));
      // setFeedbackForms(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [countriesInfo, setCountriesInfo] = useState([]);

  useEffect(() => {
    if (!countriesInfo?.length) {
      getLegalizationRequestAvailableCountries()
        .then((r) => setCountriesInfo(r))
        .finally(() => setIsAvailableCountriesLoading(false));
    }
  }, [countriesInfo]);

  const countryOptions =
    countriesInfo?.map((country) => ({
      value: country.id,
      text: country.name,
    })) || [];

  useEffect(() => {
    getLegalizationRequestInitialData()
      .then((r) => {
        setSelectedCountryId(r?.currentEmploymentInfo?.company?.country?.id);
        // array of objects to be queried

        // console.log();

        setInitialValues(
          countryOptions.find((obj) => obj.value === r?.currentEmploymentInfo?.company?.country?.id)
            ? { countryId: r?.currentEmploymentInfo?.company?.country?.id }
            : {},
        );
      })
      .finally(() => {
        setIsInitialDataLoading(false);
      });
  }, [countriesInfo]);

  // console.log(selectedCountryId);

  const [isModalVisible, setModalVisible] = useState({ isVisible: false, isSuccess: '' });

  const onSubmit = (values, { setSubmitting }) => {
    const valuesInfoObj = values.legalizationRequestForms.reduce((accumulator, currentItem) => {
      const { generic_form_id, form_id, ...formValues } = currentItem;
      accumulator[currentItem.generic_form_id] = [{ form_id: form_id, values: formValues }];
      return accumulator;
    }, {});

    createLegalizationRequest({
      countryId: selectedCountryId,
      forWhom: selectedOption,
      deadline: values?.deadline || null,
      comments: values?.comments || null,
      legalizationRequestFormData: { ...valuesInfoObj },
    })
      .then(() => {
        setModalVisible({ isVisible: true, isSuccess: true });
      })
      .catch((error) => {
        setModalVisible({ isVisible: true, isSuccess: false, message: error.response?.data });
      })
      .finally(() => setSubmitting(false));

    // createLegalizationRequest(values).then((r) => console.log(r));
  };

  const useGetInitialValues = (legalizationRequestForms) => {
    return {
      legalizationRequestForms: legalizationRequestForms.map((item) => {
        const form = item.forms[0];
        form.fields.forEach((field) => {
          if (
            field.disabled &&
            (form.values[field.fieldName] === '' ||
              form.values[field.fieldName] === null ||
              form.values[field.fieldName] === 0 ||
              form.values[field.fieldName]?.length === 0)
          ) {
            delete form.values[field.fieldName];
          }
        });
        return {
          ...form.values,
          generic_form_id: item.id,
          form_id: form.formId,
        };
      }),
    };
  };

  const useGetValidationSchema = (legalizationRequestForms) => {
    const formsValidation = legalizationRequestForms.reduce((acc, currentForm) => {
      const validation = {};
      currentForm.forms[0].fields
        .filter((item) => !item.disabled)
        .forEach((item) => {
          if (item.required) {
            const element = item.element === 'CustomElement' ? item.key : item.element;
            validation[item.fieldName] = getTypeSchema(element);
          }
        });
      return { ...acc, [currentForm.id]: validation };
    }, {});

    const validationSchema = Yup.object().shape({
      legalizationRequestForms: Yup.array().of(
        Yup.lazy((item) => {
          const { generic_form_id } = item;

          return Yup.object({
            ...formsValidation[generic_form_id],
          });
        }),
      ),
    });

    return validationSchema;
  };

  const formSchema = [
    {
      fieldName: 'countryId',
      type: 'Dropdown',
      label: 'Country',
      required: true,
      updateselectvalueroot: handleCountryIdChange,
      options: countryOptions,
    },
    {
      fieldName: 'forWhom',
      type: 'Dropdown',
      label: 'Legalization documents are required for',
      required: true,
      updateselectvalueroot: handleForWhomChange,
      options: [
        {
          value: 'employee',
          text: 'Me',
        },
        {
          value: 'employee_and_family',
          text: 'Me and my family',
        },
        {
          value: 'family',
          text: 'Only family members',
        },
      ],
    },
  ];

  const formSchema2 = [
    {
      fieldName: 'deadline',
      type: 'DatePicker',
      label: 'Deadline: Date of submission of documents (if known).',
    },
    {
      fieldName: 'comments',
      type: 'TextArea',
      label: 'Comments: please provide your comments for the Global Mobility Team, if necessary.',
    },
  ];

  return (
    <section className="legalization-request">
      <div className="questionnaire-answer">
        <h3 className="questionnaire-title underlined">Legalization request</h3>
        {!isInitialDataLoading && !isAvailableCountriesLoading && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {(formikProps) => (
              <>
                <Form>
                  <FieldArray
                    name={'legalizationRequestForms'}
                    render={({ remove, push, insert }) => (
                      <>
                        <div className="question-block">
                          {formSchema.map((field) => (
                            <div key={field.fieldName} className="question-answer">
                              {getFormElement(field.type, field, formikProps)}
                              <hr className={'answer__divider'}></hr>
                            </div>
                          ))}
                          {!isFormsLoading && selectedCountryId && selectedOption && (
                            <>
                              {legalizationRequestForms.length > 0 ? (
                                <>
                                  {legalizationRequestForms.map((form, formIndex) => (
                                    <>
                                      {form.forms[0].fields.map((field, fieldIndex) => (
                                        <div key={field.fieldName} className="question-answer">
                                          {getFormElement(
                                            field.element,
                                            {
                                              nameFieldArray: 'legalizationRequestForms',
                                              formIndex,
                                              ...field,
                                            },
                                            formikProps,
                                          )}
                                          <hr className={'answer__divider'}></hr>
                                        </div>
                                      ))}
                                    </>
                                  ))}
                                  {formSchema2.map((field, formIndex) => (
                                    <div key={field.fieldName} className="question-answer">
                                      {getFormElement(field.type, field, formikProps)}
                                      <hr className={'answer__divider'}></hr>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="question-block">
                                  <div className="question-answer">No available forms for selected options</div>
                                </div>
                              )}
                            </>
                          )}
                          {isFormsLoading && <SpinnerContainer />}
                        </div>
                      </>
                    )}
                  />
                  {legalizationRequestForms.length > 0 && (
                    <div className="submit-btn__container">
                      <SubmitButton title="Send" formikProps={formikProps} />
                    </div>
                  )}
                </Form>
              </>
            )}
          </Formik>
        )}
        {(isInitialDataLoading || isAvailableCountriesLoading) && <SpinnerContainer />}
      </div>
      <QuickButtonsBlock />
      {isModalVisible.isVisible && <StatusModal setIsModalVisible={setModalVisible} isModalVisible={isModalVisible} />}
      {/*)}*/}
      {/*{legalizationRequestForms.length === 0 && <p>No available feedback forms</p>}*/}
      {/*{isLoading && <SpinnerContainer />}*/}
    </section>
  );
};
export default LegalizationRequest;
