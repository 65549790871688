import React from 'react';
import { useLocation } from 'react-router-dom';
import { Menu as SubMenu } from 'antd';
import { Tabs } from 'antd';

import useWindowDimensions from '../../Shared/Hooks/useWindowDimensions';
import './menu.scss';

const Menu = ({ sidebarNavigation }) => {
  const { width } = useWindowDimensions();

  const sidebarNavFlat = sidebarNavigation.reduce(
    (result, current) => result.concat(current.children && current.children.length > 0 ? current.children : current),
    [],
  );

  const location = useLocation();

  const pathName = location.pathname.substring(location.pathname.lastIndexOf('/'));

  const currentMenuItem = sidebarNavFlat.find(({ url }) => url === pathName);

  const defaultSelectedKeys = currentMenuItem ? [currentMenuItem.key] : ['generalInfo'];

  const openedSubmenu = sidebarNavigation.find(({ children }) => children?.some(({ url }) => url === pathName));
  const defaultOpenKeys = openedSubmenu ? [openedSubmenu.key] : [];

  return (
    <div className="sider-menu">
      {width > 767 ? (
        <SubMenu
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={defaultOpenKeys}
          style={{
            width: 230,
          }}
          items={sidebarNavigation}
        />
      ) : (
        <Tabs
          // centered
          defaultActiveKey={currentMenuItem?.key || 'generalInfo'}
          items={sidebarNavFlat.map((item) => {
            return {
              label: item.label,
              key: item.key,
            };
          })}
        />
      )}
    </div>
  );
};

export default Menu;
