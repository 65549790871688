import { getFormElement } from '../../../../../../Shared/Form/FormElements';
import useWindowDimensions from '../../../../../../Shared/Hooks/useWindowDimensions';

import './selectDocumentsForm.scss';

const SelectDocumentsForm = ({
  onNext,
  onBack,
  forWhom,
  formSchema,
  formSchemaForForms,
  formVacIsRequiredSchema,
  formikProps,
}) => {
  const { width } = useWindowDimensions();

  return (
    <div className={'employee-select-documents'}>
      {formSchema?.length === 0 && formSchemaForForms?.length === 0 ? (
        <p className={'no-available-forms'}>
          No documents and forms available for this country for {forWhom.charAt(0).toUpperCase() + forWhom.slice(1)}
        </p>
      ) : (
        <>
          <div className="employee-select-documents__forms-container">
            <h4 className="select-documents__title">Select documents</h4>
            {formSchema?.length > 0 ? (
              <div
                className="form__container"
                style={{
                  gridTemplateRows: `repeat(${Math.ceil(
                    width <= 1600 ? formSchema.length / 2 : formSchema.length / 3,
                  )}, auto)`,
                }}
              >
                {formSchema.map((field) => (
                  <div key={field.fieldName} className={field.fieldName}>
                    {getFormElement(field.type, field, formikProps)}
                  </div>
                ))}
              </div>
            ) : (
              <p className="no-available-forms">
                No documents available for this country for {forWhom.charAt(0).toUpperCase() + forWhom.slice(1)}
              </p>
            )}
          </div>

          <div className="employee-select-documents__forms-container">
            <h4 className="select-documents__title">Select forms</h4>
            {formSchemaForForms?.length > 0 ? (
              <div
                className="form__container"
                style={{
                  gridTemplateRows: `repeat(${Math.ceil(
                    width <= 1600 ? formSchemaForForms.length / 2 : formSchemaForForms.length / 3,
                  )}, auto)`,
                }}
              >
                {formSchemaForForms.map((field) => (
                  <div key={field.fieldName} className={field.fieldName}>
                    {getFormElement(field.type, field, formikProps)}
                  </div>
                ))}
              </div>
            ) : (
              <p className="no-available-forms">
                No forms available for this country for {forWhom.charAt(0).toUpperCase() + forWhom.slice(1)}
              </p>
            )}
          </div>
          {(formSchema?.length > 0 || formSchemaForForms?.length > 0) &&
            formVacIsRequiredSchema.map((field) => (
              <div key={field.fieldName} className={field.fieldName}>
                {getFormElement(field.type, field, formikProps)}
              </div>
            ))}
        </>
      )}
      <div className="employee-select-documents__button-container">
        <button type="button" className="button btn-form main-btn" onClick={onBack}>
          Back
        </button>
        <button type="button" className="button btn-form main-btn btn-back" onClick={onNext}>
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectDocumentsForm;
