import './searchName.scss';

const SearchName = ({ searchName, setSearchName, tableKey }) => {
  const handleChangeSearchName = (e) => {
    const searchValue = e.target.value || undefined;

    sessionStorage.setItem(
      tableKey,
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem(tableKey)),
        search: searchValue,
      }),
    );
    setSearchName(searchValue);
  };

  return (
    <div className="search-form__container">
      <span className="search-form">
        <input
          type="text"
          autoComplete="off"
          value={searchName}
          onChange={handleChangeSearchName}
          name="search"
          placeholder="Search employee"
          className="search-form__input"
        />
      </span>
    </div>
  );
};

export default SearchName;
