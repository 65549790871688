import { Link } from 'react-router-dom';

import './footer.scss';

const currentYear = new Date().getFullYear();

const Footer = () => {
  return (
    <div className="footer ">
      <div className="footer-top">
        <div className="footer-top__navigation">
          <Link to="/relocation">
            <div className="footer-top__navigation-item">Main page</div>
          </Link>
          <Link to="/relocation/ask-question">
            <div className="footer-top__navigation-item">Ask a question</div>
          </Link>
          <Link to="/relocation/faq">
            <div className="footer-top__navigation-item">FAQ</div>
          </Link>
          {/*<Link to="/feedback">*/}
          {/*  <div className="footer-top__navigation-item contact-us-link">Contact us</div>*/}
          {/*</Link>*/}
        </div>
        <div className="footer-top__contact-us">
          {/*<Link to="/feedback">*/}
          {/*  <button className="contact-us-btn">Contact us</button>*/}
          {/*</Link>*/}
        </div>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom__email">
          <a href="mailto:Global-Mobility@ventionteams.com">Global-Mobility@ventionteams.com</a>
        </div>
        <p className="footer-bottom__policy">© 2002 - {currentYear}. :Vention All Rights Reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
