import { Field, ErrorMessage } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import classNames from 'classnames';
import './textarea.scss';

const AutoSizeTextArea = ({ field, form, ...props }) => {
  return <TextareaAutosize {...field} {...props} maxRows={25} />;
};

const Textarea = (props) => {
  const {
    name,
    label,
    placeholder,
    formIndex,
    nameFieldArray,
    formikProps,
    canHaveDisplayHorizontal,
    updateselectvalueroot,
    isRequired,
    ...rest
  } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  const handleChange = (e) => {
    formikProps.setFieldValue(fieldName, e.target.value);

    if (updateselectvalueroot) {
      updateselectvalueroot(e.target.value, formikProps);
    }
  };

  return (
    <div className="form-group textarea-group column">
      <div className="label-message">
        {label && (
          <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
            {label}
          </label>
        )}
        <ErrorMessage
          name={fieldName}
          render={(msg) => (
            <span className="error-textarea" style={{ color: 'red' }}>
              {msg}
            </span>
          )}
        />
      </div>
      <div className="form-error-container text-area-form-width">
        <Field
          className={classNames('form-control', { areaDisabled: props.disabled })}
          // as="textarea"
          component={AutoSizeTextArea}
          name={fieldName}
          id={fieldName}
          placeholder={placeholder || ''}
          {...(updateselectvalueroot ? { onBlur: handleChange } : {})}
          {...rest}
        />
      </div>
    </div>
  );
};

export default Textarea;
