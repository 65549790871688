import axios from '../../axios';
import actionCreators from './actionCreators';

export const addNewEvent = (instanceId, eventType, data) => {
  return (dispatch) => {
    dispatch(actionCreators.setEventsViewed(instanceId, eventType, false));
    switch (eventType) {
      case 'request':
        dispatch(
          actionCreators.requestCaseUpdate({
            requestCaseList: {
              events: data,
            },
            caseId: instanceId,
          }),
        );
        dispatch(actionCreators.addRequestEvent(data, instanceId));
        return null;
      case 'candidateProfile':
        dispatch(actionCreators.legalizationDocumentsAddEvent(data, instanceId));
        dispatch(actionCreators.addProfileEvent(data, instanceId));
        return null;
      case 'employeeProfile':
        dispatch(actionCreators.legalizationDocumentsAddEvent(data, instanceId));
        dispatch(actionCreators.addProfileEvent(data, instanceId));
        return null;
      default:
        return null;
    }
  };
};

export const updateViewedEvents = (instanceId, eventType, data) => {
  return (dispatch) => {
    return axios
      .post('/api/v1/events/', data)
      .then((response) => {
        dispatch(actionCreators.setEventsViewed(instanceId, eventType, true));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};
