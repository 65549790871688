import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getFormElement } from '../../../../Shared/Form/FormElements';
import { changeRelatives } from '../../../../../store/actions/requestDetails';
import { moveWithFormSchema, moveWithRelativesSchema } from '../requestSchema';
import { SubmitButton, ResetButton } from '../../../../Shared/Form/Buttons/Buttons';

const newRelative = {
  relatives: '',
  pets: '',
  existence_in_list: true,
};

const ExistingRelValidationSchema = Yup.object().shape({
  relatives: Yup.string().required('This field is required'),
});

const ExistingPetsValidationSchema = Yup.object().shape({
  pets: Yup.string().required('This field is required'),
});

const ExistingRelativeForm = ({ request, setShow, setRelativeError, setActionToggle }) => {
  const dispatch = useDispatch();
  const [petSelected, updatePetSelected] = useState(false);
  const [relativeSelected, updateRelativeSelected] = useState(false);
  const [roleSelected, setPetSelected] = useState();

  const onChildSelectChange = (val) => {
    setRelativeError(false);
    updatePetSelected(val === 'pet');
    updateRelativeSelected(val === 'relative');
    setPetSelected(val);
  };

  const onRelativeSelectChange = (val) => {
    setRelativeError(false);
    setPetSelected(val);
  };

  const onPetSelectChange = (val) => {
    setRelativeError(false);
    setPetSelected(val);
  };

  const typeSwitch = (field, formikProps) => {
    switch (field.fieldName) {
      case 'choose':
        return getFormElement(field.type, field, formikProps);
      case 'relatives':
        if (relativeSelected) {
          return getFormElement(field.type, field, formikProps);
        }
        return '';
      case 'pets':
        if (petSelected) {
          return getFormElement(field.type, field, formikProps);
        }
        return '';
      default:
        return getFormElement(field.type, field, formikProps);
    }
  };

  const onSubmitRelative = (values, { setSubmitting, resetForm, setStatus }) => {
    if (values.relatives) {
      if (
        request.relativeRequests.filter((item) => {
          return item.relative.relationType === 'spouse';
        }).length
      ) {
        if (
          relativesNotInRequest.filter((rel) => {
            return rel.fullName.relationType === 'spouse' && values.relatives === rel.fullName.id;
          }).length
        ) {
          updatePetSelected(false);
          setRelativeError(true);
          setSubmitting(false);
          return;
        }
      }
    }
    dispatch(
      changeRelatives(request.id, {
        relationType: values.choose,
        relativeId: values.relatives ? values.relatives : values.pets,
        existence_in_list: true,
      }),
      () => resetForm(),
    );
    setShow(false);
    setSubmitting(false);
    setActionToggle(true);
    setPetSelected(false);
    updatePetSelected(false);
    updateRelativeSelected(false);
    setRelativeError(false);
  };

  const relativesNotInRequest = request.relativesNotInRequest.map((relativeRequest) => ({
    fullName: {
      fullName: relativeRequest.generalInfo.fullName,
      id: relativeRequest.id,
      relationType: relativeRequest.relationType,
    },
  }));

  const petsNotInRequest = request.petsNotInRequest.map((petRequest) => ({
    fullName: { fullName: petRequest.name, id: petRequest.id },
  }));

  moveWithFormSchema.forEach((item) => {
    if (item.fieldName === 'choose') {
      item.updateselectvalueroot = onChildSelectChange;
    }

    if (item.fieldName === 'relatives') {
      item.updateselectvalueroot = onRelativeSelectChange;
      item.options = relativesNotInRequest.map((rel) => ({
        text: `${rel.fullName.fullName} (${
          rel.fullName.relationType[0].toUpperCase() + rel.fullName.relationType.slice(1)
        })`,
        value: rel.fullName.id,
      }));
    }

    if (item.fieldName === 'pets') {
      item.updateselectvalueroot = onPetSelectChange;
      item.options = petsNotInRequest.map((rel) => ({ text: rel.fullName.fullName, value: rel.fullName.id }));
    }
  });

  // moveWithPetsSchema.forEach((item) => {
  //   if (item.fieldName === 'choose') {
  //     item.updateselectvalueroot = onChildSelectChange;
  //   }
  //
  //   if (item.fieldName === 'pets') {
  //     item.updateselectvalueroot = onPetSelectChange;
  //     item.options = petsNotInRequest.map((rel) => ({ text: rel.fullName.fullName, value: rel.fullName.id }));
  //   }
  // });

  moveWithRelativesSchema.forEach((item) => {
    if (item.fieldName === 'choose') {
      item.updateselectvalueroot = onChildSelectChange;
    }

    if (item.fieldName === 'relatives') {
      item.updateselectvalueroot = onRelativeSelectChange;
      item.options = relativesNotInRequest.map((rel) => ({ text: rel.fullName.fullName, value: rel.fullName.id }));
    }
  });

  return (
    <Formik
      onSubmit={onSubmitRelative}
      initialValues={newRelative}
      validationSchema={
        roleSelected === 'relative'
          ? ExistingRelValidationSchema
          : roleSelected === 'pet'
          ? ExistingPetsValidationSchema
          : ''
      }
    >
      {(formikProps) => (
        <Form>
          {!request.relativesNotInRequest.length
            ? moveWithRelativesSchema.map((field) => <div key={field.fieldName}>{typeSwitch(field, formikProps)}</div>)
            : moveWithFormSchema.map((field) => <div key={field.fieldName}>{typeSwitch(field, formikProps)}</div>)}

          <div className="sub-cancel__butt relative-buttons button-wrapper">
            <SubmitButton title="Submit" formikProps={formikProps} />
            <ResetButton
              onClick={() => {
                setShow(false);
                setActionToggle(true);
                updateRelativeSelected(false);
                updatePetSelected(false);
                setPetSelected(false);
                setRelativeError(false);
              }}
              title="Cancel"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ExistingRelativeForm;
