import { Field, ErrorMessage } from 'formik';
import classNames from 'classnames';

import './textField.scss';

const TextField = (props) => {
  const {
    name,
    label,
    placeholder,
    formIndex,
    nameFieldArray,
    formikProps,
    canHaveDisplayHorizontal,
    isRequired,
    ...rest
  } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  return (
    <div className="form-group form-group__column">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}
      <div
        className={classNames(
          'text-field__wrapper',
          name === 'additionalDocuments' ? 'col-md-7' : name === 'address' ? 'col-md-5' : 'form-input-width',
        )}
      >
        <Field
          className={classNames('form-control', 'form-width')}
          type="text"
          name={fieldName}
          id={fieldName}
          placeholder={placeholder || ''}
          {...rest}
        />
      </div>
      <ErrorMessage
        name={fieldName}
        render={(msg) => (
          <span className="error" style={{ color: '#FF0000' }}>
            {msg}
          </span>
        )}
      />
    </div>
  );
};

export default TextField;
