import { types } from '../actions/types';

const initialState = {
  requestRelocatedEmployees: [],
  generationExcelRequestIds: {},
  next: null,
  previous: null,
  page: 1,
  count: 0,
  allCases: false,
  exludeIds: [],
};

const requestRelocatedEmployeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RELOCATED_EMPLOYEES_LOADED:
      return {
        ...state,
        requestRelocatedEmployees: action.payload.requestRelocatedEmployees,
        // generationExcelRequestIds: {},
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
        page: action.payload.page,
      };
    case types.RELOCATED_EMPLOYEES_ALL_CASES_UPDATE:
      return {
        ...state,
        allCases: !state.allCases,
        generationExcelRequestIds: {},
        exludeIds: [],
      };
    case types.RELOCATED_EMPLOYEES_ERROR:
      return {
        ...state,
        requestRelocatedEmployees: action.payload.requestRelocatedEmployees,
        page: 1,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.RELOCATED_EMPLOYEES_UPDATED:
      return {
        ...state,
        requestRelocatedEmployees: [...state.requestRelocatedEmployees, ...action.payload.requestRelocatedEmployees],
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.RELOCATED_EMPLOYEES_EXCEL_CHECKBOXES_ADD:
      return {
        ...state,
        ...(state.allCases &&
          !state.exludeIds.includes(action.payload.employeeUuid) && {
            exludeIds: [...state.exludeIds, action.payload.employeeUuid],
          }),
        ...(state.allCases &&
          state.exludeIds.includes(action.payload.employeeUuid) && {
            exludeIds: state.exludeIds.filter((item) => item !== action.payload.employeeUuid),
          }),
        ...(!state.allCases && {
          generationExcelRequestIds: { ...state.generationExcelRequestIds, ...action.payload.generationExcelCheckbox },
        }),
      };
    case types.RELOCATED_EMPLOYEES_EXCEL_CHECKBOXES_REMOVE:
      return {
        ...state,
        generationExcelRequestIds: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export default requestRelocatedEmployeesReducer;
