import { types } from '../actions/types';

const initialState = {
  citiesForRelocation: [],
  cities: [],
  workPlaceCities: [],
};

const citiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CITIES_FOR_RELOCATION_LOAD:
      return {
        ...state,
        citiesForRelocation: action.payload.citiesForRelocation,
      };
    case types.CITIES_LOAD:
      return {
        ...state,
        cities: action.payload.cities,
      };
    case types.WORK_PLACE_CITIES_LOAD:
      return {
        ...state,
        workPlaceCities: action.payload.cities,
      };
    case types.CITIES_UPDATE:
      return {
        ...state,
        cities: [...state.cities, action.payload.city],
      };

    default:
      return {
        ...state,
      };
  }
};

export default citiesReducer;
