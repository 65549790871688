import { FieldArray, useField, Field, ErrorMessage } from 'formik';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import TextareaAutosize from 'react-textarea-autosize';
import { SubmitButton, CancelButton } from '../Buttons/Buttons';
import { defaultDateFormat } from '../../../../constants';

import notesImg from '../../../../assets/img/note.svg';

import './noteField.scss';

const AutoSizeTextArea = ({ field, form, ...props }) => {
  return <TextareaAutosize {...field} {...props} maxRows={25} />;
};

const NoteField = (props) => {
  const { name, label, placeholder, formikProps, formIndex, nameFieldArray, disabled } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const [field] = useField(fieldName);

  const initialNotes = nameFieldArray
    ? formikProps.initialValues[nameFieldArray].map((item) => item.notes)
    : formikProps.initialValues.notes;
  const newValuesNotes = nameFieldArray
    ? formikProps.values[nameFieldArray].map((item) => item.notes)
    : formikProps.values.notes;

  const user = useSelector((state) => state.auth.user);

  return (
    <div className="form-group textarea-group column">
      {field.value?.length > 0 && (
        <label className="control-label" htmlFor={`${fieldName}`}>
          {label}
        </label>
      )}
      <FieldArray name={fieldName}>
        {({ insert, remove, push }) => (
          <>
            <>
              {field.value?.length > 0 &&
                field.value.map((value, index) => {
                  const createdAt = value?.createdAt?.slice(0, 16);
                  const updatedAt = value?.updatedAt?.slice(0, 16);
                  const createdDate = new Date(createdAt);
                  const updatedDate = new Date(updatedAt);
                  const labelNote =
                    createdAt === updatedAt
                      ? `${dayjs(createdDate).format(defaultDateFormat)} ${value?.author} wrote:`
                      : `${dayjs(updatedDate).format(defaultDateFormat)} ${value?.author} (edit) wrote:`;
                  return (
                    <div className="note-container" key={index}>
                      <div className="note" key={index}>
                        {value?.author && <p> {labelNote}</p>}
                        <div className="note-cancel__container">
                          <Field
                            className="form-control note-field-textarea"
                            component={AutoSizeTextArea}
                            name={`${fieldName}.${index}.text`}
                            id={`${fieldName}.${index}.text`}
                            placeholder={placeholder || ''}
                            disabled={disabled || (!(+user?.internalId === value?.authorId) && !isNaN(value?.id))}
                          />
                          {!value?.id && field.value.length > 0 && <CancelButton remove={remove} formIndex={index} />}
                        </div>
                        <ErrorMessage
                          name={`${fieldName}.${index}.text`}
                          render={(msg, index) => (
                            <span className="error" style={{ color: 'red' }} key={index}>
                              {msg}
                            </span>
                          )}
                        />
                      </div>
                    </div>
                  );
                })}
            </>

            <div className="notes">
              {!_.isEqual(initialNotes, newValuesNotes) && (
                <>
                  {field.value.length > 0 && (
                    <div className="notes-buttons">
                      <SubmitButton title="Save notes" formikProps={formikProps} />
                    </div>
                  )}
                </>
              )}

              <div></div>
              {(formIndex >= 0 &&
                (formikProps.values.vacInfo?.[formIndex]?.id ||
                  formikProps.values.transportationDetailsInfo?.[formIndex]?.id ||
                  formikProps.values.accommodationDetailsInfo?.[formIndex]?.id)) ||
              formikProps.values.notes ? (
                <>
                  {!disabled && (
                    <>
                      <Tooltip placement={'top'} title={'Add notes'}>
                        <button type="button" className="tooltip-button add-notes" data-tip="Add notes">
                          <img className="notes-img" src={notesImg} alt="notes" onClick={() => push({ text: '' })} />
                        </button>
                      </Tooltip>
                    </>
                  )}
                </>
              ) : null}
            </div>
          </>
        )}
      </FieldArray>
    </div>
  );
};

export default NoteField;
