import classNames from 'classnames';
import { Field, ErrorMessage, useField } from 'formik';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import { ReactComponent as CalendarIcon } from '../../../../assets/img/icons/calendar.svg';
import { useSelector } from 'react-redux';
import { defaultDateFormat } from '../../../../constants';

const DatepickerWithDisabledCheckbox = (props) => {
  const {
    name,
    label,
    updateselectvalueroot,
    oncalendarcloseupdate,
    formikProps,
    formIndex,
    nameFieldArray,
    disabled,
    isRequired,
    ...rest
  } = props;
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const fieldNameDate =
    formIndex >= 0 ? `${nameFieldArray}.${formIndex}.'employeeRelocationDate'` : 'employeeRelocationDate';

  const [field, , { setValue }] = useField(fieldNameDate);
  const initDate = field.value ? dayjs(field.value, defaultDateFormat) : null;

  const handleChange = (date) => {
    const value = date ? dayjs(date).format(defaultDateFormat) : null;
    formikProps.setFieldValue(fieldNameDate, value);
    if (updateselectvalueroot) {
      updateselectvalueroot(value, formIndex, formikProps);
    }
  };

  const onCalendarClose = (open) => {
    if (!open && oncalendarcloseupdate) {
      oncalendarcloseupdate();
    }
  };

  return (
    <div className="form-group">
      <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
        {label}
      </label>
      <div className="custom-control custom-checkbox option-inline">
        <Field name={fieldName} className="custom-control-input" type="checkbox" id={fieldName} disabled={true} />
        <label className="custom-checkbox__label" htmlFor={fieldName}>
          <span></span>
        </label>
      </div>
      <DatePicker
        defaultValue={initDate}
        format={displayDateFormat}
        onChange={(date) => (date ? changeValue(date) : setValue(null))}
        className={'form-control ant-datepicker-wrapper'}
        placeholder={disabled ? '' : displayDateFormat}
        name="employeeRelocationDate"
        id="employeeRelocationDate"
        disabled={disabled}
        onOpenChange={(open) => onCalendarClose(open)}
        {...(updateselectvalueroot ? { onChange: handleChange } : {})}
        suffixIcon={<CalendarIcon />}
      />

      <ErrorMessage
        name={fieldName}
        className="field-error"
        render={(msg) => <div style={{ color: 'red' }}>{msg}</div>}
      />
    </div>
  );
};

export default DatepickerWithDisabledCheckbox;
