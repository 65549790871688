export const getFormsWithoutAttachments = (documents) => {
  return documents
    .filter((item) => item.isWithAttachments)
    .reduce((accumulator, currentItem) => {
      if (currentItem.isCanBePlural) {
        const formsWithoutAttachments = currentItem.forms.filter(
          (form) => form.isDraft && form.attachments?.length === 0 && currentItem.forms[0]?.formId !== null,
        );
        formsWithoutAttachments?.length > 0 &&
          accumulator.push({
            ...currentItem,
            forms: formsWithoutAttachments,
          });
      }
      if (
        !currentItem.isCanBePlural &&
        currentItem.forms[0]?.isDraft &&
        currentItem.forms[0]?.formId !== null &&
        currentItem.forms[0]?.attachments?.length === 0
      ) {
        accumulator.push(currentItem);
      }
      return accumulator;
    }, []);
};

export const getUnfilledForms = (documents) => {
  return documents.reduce((accumulator, currentItem) => {
    if (currentItem.forms[0]?.formId === null) {
      accumulator.push(currentItem);
    }
    return accumulator;
  }, []);
};

const useDocumentsValidation = (employeeDocuments, employeeForms, docsInfoRelatives, withWhom) => {
  const withWhomMapping = {
    child: 'withChildren',
    spouse: 'withSpouse',
  };

  return () => {
    const passportDocument = employeeDocuments.find((item) => item.slug.includes('passport'));
    let employeeFormsWithPassport;
    if (passportDocument) {
      employeeFormsWithPassport = employeeForms.concat([passportDocument]);
    } else {
      employeeFormsWithPassport = employeeForms;
    }
    const employeeDocumentsWithoutAttachments = getFormsWithoutAttachments(employeeDocuments);
    const employeeUnfilledForms = getUnfilledForms(employeeFormsWithPassport);

    const relativesDocumentsWithoutAttachments = {};
    const relativesUnfilledForms = {};
    // const relativesUnfilledEmploymentInfo = {};
    for (let relativeId in docsInfoRelatives) {
      const relationType = docsInfoRelatives[relativeId].relatedInfo?.relative?.relationType;
      if (withWhom && !withWhom[withWhomMapping[relationType]]) {
        continue;
      }

      const relativeFormsWithoutAttachments = getFormsWithoutAttachments(
        docsInfoRelatives[relativeId].relatedInfo.documents,
      );
      if (relativeFormsWithoutAttachments.length > 0) {
        relativesDocumentsWithoutAttachments[relativeId] = relativeFormsWithoutAttachments;
      }

      const relativePassportDocument = docsInfoRelatives[relativeId].relatedInfo.documents.find((item) =>
        item.slug.includes('passport'),
      );

      let relativeFormsWithPassport;
      if (relativePassportDocument) {
        // relativeFormsWithPassport = docsInfoRelatives[relativeId].relatedInfo.forms.concat([relativePassportDocument]); //remove validation for forms
        relativeFormsWithPassport = [relativePassportDocument];
      } else {
        // relativeFormsWithPassport = docsInfoRelatives[relativeId].relatedInfo.forms; //remove validation for forms
        relativeFormsWithPassport = [];
      }
      const relativeUnfilledForms = getUnfilledForms(relativeFormsWithPassport);
      if (relativeUnfilledForms.length > 0) {
        relativesUnfilledForms[relativeId] = relativeUnfilledForms;
      }

      // if (!docsInfoRelatives[relativeId].relatedInfo.relative.employmentInfo) {
      //   relativesUnfilledEmploymentInfo[relativeId] =
      //     docsInfoRelatives[relativeId].relatedInfo.relative.relationType === 'spouse'
      //       ? 'Employment information'
      //       : 'Education/Occupation information';
      // }
    }

    return [
      employeeDocumentsWithoutAttachments,
      employeeUnfilledForms,
      relativesDocumentsWithoutAttachments,
      relativesUnfilledForms,
      // relativesUnfilledEmploymentInfo,
    ];
  };
};

export default useDocumentsValidation;
