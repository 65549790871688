import Select from 'react-select';
import { ErrorMessage } from 'formik';
import { customStyles } from '../../customStyles';
import classNames from 'classnames';
import '../dropdown.scss';

const getValueFromFormikProps = (fieldName, initialValues) => {
  return fieldName.split('.').reduce((previousValue, currentValue) => {
    if (previousValue) return previousValue[currentValue];
    return null;
  }, initialValues);
};

const SelectField = (props) => {
  const {
    name,
    label,
    options,
    updateselectvalueroot,
    formikProps,
    formIndex,
    nameFieldArray,
    disabled,
    isclearable,
    isRequired,
  } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  const onChange = (e) => {
    formikProps.setFieldValue(fieldName, e.target.value);

    if (updateselectvalueroot) {
      updateselectvalueroot(e.target.value, formikProps, formIndex);
    }
  };

  const getOptionValue = (option) =>
    option?.value || option?.text || option?.[name] || option?.uuid || option?.internalId || option?.id || option;

  const getOptionLabel = (option) => option.text || option?.value || option.title || option.name;

  const optionValues = options.map((option) => ({
    label: getOptionLabel(option),
    value: getOptionValue(option),
    disabled: option.disabled,
  }));

  const defaultValue = optionValues.find((option) => {
    if (formikProps?.values[nameFieldArray]?.length > 1) {
      const formikOption = getOptionValue(
        formikProps?.values[nameFieldArray].map((value) => getValueFromFormikProps(name, value)),
      );
      return option.value === formikOption[formIndex];
    }

    const formikOption = getOptionValue(
      formikProps?.values[name] ||
        getValueFromFormikProps(name, formikProps?.values[nameFieldArray]?.[0]) ||
        getValueFromFormikProps(name, formikProps?.initialValues[nameFieldArray]?.[0]),
    );
    return option.value === formikOption;
  });

  return (
    <div className="form-group form-group__column">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}
      <Select
        isClearable={isclearable}
        value={defaultValue ? defaultValue : ''}
        options={optionValues}
        styles={customStyles}
        isDisabled={disabled}
        onChange={(option) => onChange({ target: { value: option?.value, options: optionValues } })}
        className="responsible-select-top"
        placeholder="-select-"
        isOptionDisabled={(option) => option.disabled}
        name={fieldName}
        id={fieldName}
      />
      <ErrorMessage
        name={fieldName}
        render={(msg) => (
          <span className="error" style={{ color: 'red' }}>
            {msg}
          </span>
        )}
      />
    </div>
  );
};

export default SelectField;
