import classNames from 'classnames';

import SettingsIcon from '../../../../assets/img/settings-icon.svg';

const MainSettingsIcon = ({ toggle, setToggle }) => {
  return (
    <button
      className={classNames('shareButton main', { open: toggle })}
      onMouseEnter={() => {
        !toggle ? setToggle(1) : setToggle(0);
      }}
      type="button"
    >
      <img className="share" src={SettingsIcon} alt="settings" />
      <svg className="close" viewBox="0 0 24 24">
        <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
      </svg>
    </button>
  );
};

export default MainSettingsIcon;
