import { Formik, Form } from 'formik';

import { getFormElement } from '../../../../../Shared/Form/FormElements';
import { SubmitButton } from '../../../../../Shared/Form/Buttons/Buttons';
import { useValidationSchema } from './useValidationSchema';
import AttachmentBlock from '../../../../../Shared/Form/AttachFiles/AttachmentBlock';
import SpinnerImg from '../../../../../../assets/img/SpinnerWhite.svg';

import '../../DocumentModal/documentModal.scss';

const DocumentForm = ({ setModalVisible, document, onSubmit, isSubmitting }) => {
  const formSchema = document.forms[0].fields;

  let formSchemaWithoutDisFilds = [];
  formSchema.forEach((item) => {
    if (!item?.disabled) formSchemaWithoutDisFilds.push(item);
  });

  const validationSchema = useValidationSchema(document);

  const initialValues = { ...document.forms[0].values, ...(document.isWithAttachments && { files: [] }) };

  for (var key in initialValues) {
    initialValues[key] = '';
  }

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(formikProps) => {
        return (
          <Form>
            {formSchemaWithoutDisFilds.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.element, field, formikProps)}</div>
            ))}
            {document.isWithAttachments && <AttachmentBlock formikProps={formikProps} name={`files`} />}

            <div className="attach-documents__button-container">
              <button type="button" className="button btn-form main-btn" onClick={() => setModalVisible(false)}>
                Cancel
              </button>

              {isSubmitting ? (
                <button type="button" className="button btn-form main-btn btn-back spinner-btn">
                  <img src={SpinnerImg} alt="SpinnerContainer" />
                </button>
              ) : (
                <SubmitButton title="Confirm" formikProps={formikProps} />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DocumentForm;
