import { useContext } from 'react';
import classNames from 'classnames';

import { IsCaseDisabledContext } from '../../../RequestDetails';
import Deadline from './Deadline/Deadline';
import DocsProvided from './Deadline/DocsProvided/DocsProvided';
import SendFurtherEmail from './Deadline/SendFurtherEmail/SendFurtherEmail';

const RequestDocumentsFormButton = ({
  isDocsEditable,
  requestedDocuments,
  documentsStatus,
  setModalVisible,
  requestId,
  employeeId,
}) => {
  const isCaseDisabled = useContext(IsCaseDisabledContext);

  return (
    <div className="request-documents">
      {requestedDocuments?.docsRequestedAt && isDocsEditable && (
        <Deadline
          requestedDocuments={requestedDocuments}
          isCaseDisabled={isCaseDisabled}
          requestId={requestId}
          employeeId={employeeId}
        />
      )}

      <button
        disabled={isCaseDisabled}
        className={classNames('button btn-form main-btn btn-back button-notification', {
          'btn-disabled': isCaseDisabled,
        })}
        onClick={() => setModalVisible(true)}
      >
        Request Employee documents
      </button>
      {requestId && (
        <DocsProvided requestId={requestId} documentsStatus={documentsStatus} isCaseDisabled={isCaseDisabled} />
      )}
      {requestedDocuments?.docsRequestedAt && isDocsEditable && (
        <SendFurtherEmail
          requestedDocuments={requestedDocuments}
          isCaseDisabled={isCaseDisabled}
          requestId={requestId}
          employeeId={employeeId}
        />
      )}
    </div>
  );
};

export default RequestDocumentsFormButton;
