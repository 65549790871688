import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { getGenericFormsForEmployeeRelative } from '../../../../../store/actions/genericForm';
import SelectDocumentsForm from './SelectDocumentsForm/SelectDocumentsForm';

import './selectDocumentsToDisplay.scss';

const SelectDocumentsToDisplayModal = ({
  isModalVisible,
  setModalVisible,
  objectId,
  editDocumentsToDisplayFunction,
  documentsToDisplay,
  country,
}) => {
  const dispatch = useDispatch();

  const countryId = country?.id || 0;
  const genericForms = useSelector((state) => state.genericForms.genericFormsForEmployee[countryId]) || [];

  useEffect(() => {
    if (!genericForms?.length) {
      dispatch(getGenericFormsForEmployeeRelative({ is_for_employee: true, countries: country?.id }, countryId));
    }
  }, [dispatch, genericForms?.length]);

  const formSchema = genericForms.map((item) => {
    return { fieldName: +item.id, type: 'CheckboxReverse', label: item.name };
  });

  const initialValues = formSchema.reduce((tally, currentValue) => {
    const initialValue = !!documentsToDisplay.documents.find((item) => item === currentValue.fieldName);
    if (initialValue) {
      tally[currentValue.fieldName] = initialValue;
    }
    return tally;
  }, {});

  const onSubmit = (values, { setSubmitting }) => {
    const documentsToDisplayNew = _.keys(_.pickBy(values));
    dispatch(
      editDocumentsToDisplayFunction(objectId, {
        documentsToDisplay: documentsToDisplayNew.concat(documentsToDisplay.legalization),
      }),
    ).finally(() => {
      setSubmitting(false);
      setModalVisible(false);
    });
  };

  return (
    <SelectDocumentsForm
      isModalVisible={isModalVisible}
      setModalVisible={setModalVisible}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formSchema={formSchema}
      title="Select documents to display"
    />
  );
};

export default SelectDocumentsToDisplayModal;
