import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { getFormElement } from '../../../../Shared/Form/FormElements';
import LinkedRequests from './LinkedRequests/LinkedRequests';
import { editRelatedRequest, editRelatedPetRequest } from '../../../../../store/actions/requestDetails';
import { editLinkedRequests } from '../../../../../store/actions/linkedRequest';
import { formSchema, checkboxSchema } from '../requestSchema';
import { SubmitButton, ResetButton } from '../../../../Shared/Form/Buttons/Buttons';

const related = {
  relationType: '',
  firstName: '',
  lastName: '',
  petType: '',
  name: '',
  weight: '',
};

const RelatedValidationSchema = Yup.object().shape({
  relationType: Yup.string().required('This field is required'),
  firstName: Yup.string()
    .required('This field is required')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'First name must contain only letters'),
  lastName: Yup.string()
    .required('This field is required')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'Last name must contain only letters'),
});

const PetValidationSchema = Yup.object().shape({
  petType: Yup.string().required('This field is required'),
  name: Yup.string()
    .required('This field is required')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'Name must contain only letters'),
  weight: Yup.string()
    .required('This field is required')
    .matches(/^[0-9]+$/, 'Weight must contain only digits'),
});

const NewRelativeForm = ({ request, requestId, setShow, setRelativeError, setActionToggle }) => {
  const dispatch = useDispatch();
  const [petSelected, updatePetSelected] = useState(false);
  const [roleSelected, setPetSelected] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [isRequestChoosed, setRequestChoosed] = useState(false);
  const [linkedId, setLinkedId] = useState({ case_id: null });

  const onChildSelectChange = (val) => {
    setRelativeError(false);
    updatePetSelected(val === 'pet');
    setPetSelected(val);
  };

  const elementSwitch = (field, formikProps) => {
    switch (field.fieldName) {
      case 'relationType':
        if (isClicked) {
          return '';
        }
        return getFormElement(field.type, field, formikProps);
      case 'firstName':
        if (petSelected && !isClicked) {
          return '';
        }
        return getFormElement(field.type, field, formikProps);
      case 'name':
        if (petSelected && !isClicked) {
          return getFormElement(field.type, field, formikProps);
        }
        return '';
      case 'petType':
        if (petSelected && !isClicked) {
          return getFormElement(field.type, field, formikProps);
        }
        return '';
      case 'weight':
        if (petSelected && !isClicked) {
          return getFormElement(field.type, field, formikProps);
        }
        return '';
      case 'lastName':
        if (petSelected && !isClicked) {
          return '';
        }
        return getFormElement(field.type, field, formikProps);
      default:
        return getFormElement(field.type, field, formikProps);
    }
  };

  const successSubmit = (values, setSubmitting, resetForm) => {
    dispatch(
      isRequestChoosed
        ? editLinkedRequests(request.id, linkedId)
        : editRelatedRequest(request.employee.uuid, request.id, {
            ...values,
            relocationRequestId: requestId,
          }),
    ).then(() => resetForm());
    setSubmitting(false);
    setShow(false);
    updatePetSelected(false);
    setRelativeError(false);
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    if (values.relationType === 'child') {
      successSubmit(values, setSubmitting, resetForm);
    }

    if (values.relationType === 'pet') {
      dispatch(
        editRelatedPetRequest(request.employee.uuid, request.id, {
          name: values.name || '',
          petType: values.petType,
          weight: values.weight || null,
          documentDetails: '',
          notes: '',
          relocationRequestId: requestId,
        }),
      ).then(() => resetForm());
      setSubmitting(false);
      setRelativeError(false);
      updatePetSelected(false);
      setShow(false);
    }

    if (values.relationType === 'spouse') {
      if (request.relativeRequests.length > 0) {
        if (request.relativeRequests.find((x) => x.relative.relationType === 'spouse')) {
          updatePetSelected(false);
          setRelativeError(true);
          setSubmitting(false);
        } else {
          successSubmit(values, setSubmitting, resetForm);
        }
      } else {
        successSubmit(values, setSubmitting, resetForm);
      }
    }
    setSubmitting(false);
  };

  formSchema.forEach((item) => {
    if (item.fieldName === 'relationType') {
      item.updateselectvalueroot = onChildSelectChange;
    }
  });

  formSchema.map((item) => {
    if (isClicked) {
      item.disabled = true;
    } else {
      item.disabled = false;
    }
  });

  return (
    <Formik
      initialValues={related}
      onSubmit={onSubmit}
      validationSchema={
        roleSelected === 'spouse' || roleSelected === 'child'
          ? RelatedValidationSchema
          : roleSelected === 'pet'
          ? PetValidationSchema
          : RelatedValidationSchema
      }
    >
      {(formikProps) => (
        <>
          <Form>
            {checkboxSchema.map((field) => (
              <div
                key={field.fieldName}
                onChange={() => {
                  setIsClicked(!isClicked);
                  setRequestChoosed(false);
                  if (!isClicked && !isRequestChoosed) {
                    setPetSelected('employee');
                    formikProps.setFieldValue(`relationType`, 'child');
                    formikProps.setFieldValue(`firstName`, '');
                    formikProps.setFieldValue(`lastName`, '');
                  } else {
                    formikProps.setFieldValue(`relationType`, '');
                  }
                }}
              >
                {elementSwitch(field, formikProps)}
              </div>
            ))}
            {isClicked && (
              <LinkedRequests
                requestId={request.id}
                cityId={request.city.id}
                setRequestChoosed={setRequestChoosed}
                setLinkedId={setLinkedId}
                linkedRequests={request.linkedRequests}
                formikProps={formikProps}
              />
            )}
            {formSchema.map((field) => (
              <div key={field.fieldName}>{elementSwitch(field, formikProps)}</div>
            ))}
            <div className="sub-cancel__butt relative-buttons button-wrapper">
              <SubmitButton title="Submit" formikProps={formikProps} />
              <ResetButton
                onClick={() => {
                  setShow(false);
                  updatePetSelected(false);
                  setRelativeError(false);
                  setIsClicked(false);
                  setRequestChoosed(false);
                  formikProps.setFieldValue(`relationType`, '');
                }}
                title="Cancel"
              />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default NewRelativeForm;
