import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { getFormElement } from '../../../Shared/Form/FormElements';
import { SubmitButton } from '../../../Shared/Form/Buttons/Buttons';
import React, { useEffect } from 'react';
import { createReminder } from '../../../../store/actions/reminders';
import { getFormSchemaWithRequiredField } from '../../../../helpers';

export const formSchemaWithoutRequiredField = [
  {
    fieldName: 'text',
    type: 'TextArea',
    placeholder: 'Reminder text',
  },
  {
    fieldName: 'targetDate',
    type: 'DatePicker',
    label: 'Set up a reminder date',
  },
];

export const validationSchema = Yup.object().shape({
  text: Yup.string().required('This field is required').max(1000, 'This field must not be more than 1000 characters'),
  targetDate: Yup.date().required('This field is required').nullable(),
});

const CreateNewReminder = ({ currentTab, setModalVisible, employeeId, requestId }) => {
  const dispatch = useDispatch();
  const onSubmit = (values, { setSubmitting, resetForm }) => {
    dispatch(createReminder(employeeId, requestId, values)).then((response) => {
      resetForm();
      setSubmitting(false);
    });
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, [currentTab]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField);

  return (
    <div className="set-up-reminder">
      <div>
        <Formik
          enableReinitialize
          initialValues={{ text: '', targetDate: '' }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
              {formSchema.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
              ))}
              <div className="add-item-to-legalization-table__button-container">
                <button type="button" className="button btn-form main-btn" onClick={() => setModalVisible(false)}>
                  Cancel
                </button>
                <SubmitButton title="Confirm" formikProps={formikProps} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateNewReminder;
