import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { setAllValuesToNull, getValuesToUpdate } from '../../../../../helpers';
import DocumentForm from '../../../DocumentForm/DocumentForm';
import { IsCaseDisabledContext } from '../../../../RequestDetails/RequestDetails';
import { upsertDocuments } from '../../../../../store/actions/legalizationStepsForm';
import './legalizationForm.scss';

const EmployeeForm = (props) => {
  const { document, employeeId } = props;

  const dispatch = useDispatch();
  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const onSubmit = (values, { setSubmitting, setFieldValue, resetForm, setStatus }) => {
    const valuesInfo = { ...values };

    valuesInfo[document.slug].forEach((item) => {
      if (item.id === null) delete item.id;
    });

    const allFormFields = document.forms[0].fields.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.fieldName);
      accumulator.push(currentValue.fieldName + 'DateExtraFields');
      return accumulator;
    }, []);

    const valuesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
      })),
      allFormFields,
    );

    const valuesWithFilesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
        files: [],
      })),
      ['files'],
    );

    const valuesToAdd = values[document.slug].filter(({ id }) => id === null || id === undefined);

    const isCurrentDocumentsToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        id: item.formId,
        isCurrent: item.isCurrent,
      })),
      ['isCurrent'],
    );

    const valuesToUpdateWithNullValues = setAllValuesToNull(valuesToUpdate);
    const valuesToAddWithNullValues = setAllValuesToNull(valuesToAdd);

    const files = valuesInfo[document.slug].some((item) => item?.files?.length);

    if (valuesToUpdate.length || valuesToAdd.length || isCurrentDocumentsToUpdate.length || files) {
      dispatch(
        upsertDocuments(
          valuesToUpdateWithNullValues,
          valuesToAddWithNullValues,
          employeeId,
          document.id,
          valuesWithFilesToUpdate,
          isCurrentDocumentsToUpdate,
        ),
      ).then(() => {
        if (document.isWithAttachments) {
          values[document.slug].forEach((item, index) => setFieldValue(`${document.slug}.${index}.files`, []));
        }
        setSubmitting(false);
      });
    }
  };

  return (
    <DocumentForm
      document={document}
      employeeId={employeeId}
      onSubmit={onSubmit}
      isCase={true}
      isCaseDisabled={isCaseDisabled}
      withCurrentField={true}
      withEditFormInConstructor={{ pathName: 'legalization-steps' }}
      documentType={{ forWhom: 'employee', type: 'legalizationSteps' }}
    />
  );
};

export default EmployeeForm;
