import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';

import { getFormElement } from '../../../Shared/Form/FormElements';
import { editRelocationRequest } from '../../../../store/actions/request';
import './vacTdBlock.scss';

const VacTdAdBlock = (props) => {
  const dispatch = useDispatch();
  const { requestId, isVacRequired, isTransportationRequired, isAccommodationRequired, isCaseDisabled } = props;

  const onVacRequired = (value) => {
    dispatch(editRelocationRequest(requestId, { isVacRequired: value }));
  };

  const formIsVacRequired = [
    {
      fieldName: 'isVacRequired',
      type: 'Checkbox',
      label: 'VAC is required',
      updateselectvalueroot: onVacRequired,
    },
  ];

  const onTransportationRequired = (value) => {
    dispatch(editRelocationRequest(requestId, { isTransportationRequired: value }));
  };

  const formIsTransportationRequired = [
    {
      fieldName: 'isTransportationRequired',
      type: 'Checkbox',
      label: 'Transportation is required',
      updateselectvalueroot: onTransportationRequired,
    },
  ];
  const onAccommodationRequired = (value) => {
    dispatch(editRelocationRequest(requestId, { isAccommodationRequired: value }));
  };

  const formIsAccommodationRequired = [
    {
      fieldName: 'isAccommodationRequired',
      type: 'Checkbox',
      label: 'Accommodation is required',
      updateselectvalueroot: onAccommodationRequired,
    },
  ];

  return (
    <div>
      <div className="final-checkbox-wrapper__block">
        <Formik
          enableReinitialize
          initialValues={{
            isVacRequired: isVacRequired,
          }}
        >
          {(formikProps) => (
            <Form>
              {formIsVacRequired.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
              ))}
            </Form>
          )}
        </Formik>
        <Formik
          enableReinitialize
          initialValues={{
            isTransportationRequired: isTransportationRequired,
          }}
        >
          {(formikProps) => (
            <Form>
              {formIsTransportationRequired.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
              ))}
            </Form>
          )}
        </Formik>
        <Formik
          enableReinitialize
          initialValues={{
            isAccommodationRequired: isAccommodationRequired,
          }}
        >
          {(formikProps) => (
            <Form>
              {formIsAccommodationRequired.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
              ))}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default VacTdAdBlock;
