import { types } from '../actions/types';

const initialState = {
  request: {},
  candidateProfile: {},
  employeeProfile: {},
};

const eventsInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EVENTS_SET_VIEWED:
      const targetId = action.payload.instanceId;
      const eventType = action.payload.eventType;
      return {
        ...state,
        [eventType]: {
          ...state[eventType],
          [targetId]: {
            isViewed: action.payload.isViewed,
          },
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default eventsInfoReducer;
