import DocumentForm from './DocumentForm/DocumentForm';

const Documents = ({ documents, requestId, relatedReqId, employeeId, relativeId }) => {
  return (
    <div className="related-documents">
      {documents.length > 0 &&
        documents.map((document) => (
          <DocumentForm
            key={document.id}
            requestId={requestId}
            relatedReqId={relatedReqId}
            document={document}
            isCase={true}
            employeeId={employeeId}
            relativeId={relativeId}
          />
        ))}

      {!documents.length && <p>No available documents</p>}
    </div>
  );
};

export default Documents;
