import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEmployeeDocuments } from '../../store/actions/requestDetails';
import WithWhomBlock from './WithWhomBlock/WithWhomBlock';
import GuideLine from './GuideLine/GuideLine';
import Documents from './Documents/Documents';
import QuickButtonsBlock from '../Shared/QuickButtonsBlock/QuickButtonsBlock';
import SpinnerContainer from '../Common/SpinnerContainer/SpinnerContainer';

import './documentsPage.scss';

const DocumentsPage = ({ match }) => {
  const dispatch = useDispatch();

  const docsInfo = useSelector((state) => state.requestDocumentsDetails.documents);

  const requestId = match.params.id;
  const employeeId = docsInfo && docsInfo.employee.uuid;

  const docsInfoEmployee = useSelector((state) => state.employeeProfileReducer[employeeId]?.employeeProfile);
  const docsInfoRelatives = useSelector((state) => state.requestRelatedProfiles);

  const [isLoading, setIsLoading] = useState(true);
  const [withWhom, setWithWhom] = useState({});

  useEffect(() => {
    dispatch(getEmployeeDocuments(requestId))
      .then((response) => {
        setWithWhom({
          withSpouse: response?.withSpouse,
          withChildren: response?.withChildren,
          isVacRequired: response?.isVacRequired,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [dispatch, requestId]);

  return (
    <>
      {!isLoading && docsInfo && docsInfoEmployee && (
        <section className="employee-documents__container">
          <GuideLine userType={'employee'} />
          <div className="content__container">
            <WithWhomBlock
              requestId={requestId}
              withWhom={withWhom}
              setWithWhom={setWithWhom}
              relativeRequests={docsInfoRelatives}
            />
            <Documents
              withWhom={withWhom}
              setWithWhom={setWithWhom}
              requestId={requestId}
              docsInfo={docsInfo}
              docsInfoEmployee={docsInfoEmployee}
              docsInfoRelatives={docsInfoRelatives}
              displayVacIsRequired={docsInfo.displayVacIsRequired}
            />
          </div>
          <QuickButtonsBlock />
        </section>
      )}
      {isLoading && <SpinnerContainer />}
    </>
  );
};

export default DocumentsPage;
