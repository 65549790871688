import { useDispatch } from 'react-redux';

import DisplayAttachment from './DisplayAttachment/DisplayAttachment';
import { deleteAccommodationAttachment } from '../../../../store/actions/uploadFile';
import { getAccommodationAttachmentLink } from '../../../../store/actions/downloadFile';
import './currentAttachmentBlock.scss';

const CurrentAttachmentBlock = ({ formId, accommodationDetailsAllInfo, requestId, isCaseDisabled }) => {
  const dispatch = useDispatch();

  const handleDeleteAttachment = (adId) => (attachId) => {
    dispatch(deleteAccommodationAttachment(attachId, adId, requestId));
  };

  return (
    <>
      <div className="current-attachments__container form-group">
        <label className="control-label">Uploaded files:</label>
        <div className="current-attachments-block">
          {accommodationDetailsAllInfo.map((item, index) =>
            item.attachments.map((attach, subindex) => {
              if (item.id === formId) {
                return (
                  <DisplayAttachment
                    key={attach.id}
                    attachmentId={attach.id}
                    attach={attach}
                    deleteAttachment={handleDeleteAttachment(formId)}
                  />
                );
              } else return null;
            }),
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentAttachmentBlock;
