import OtherSpellingSmgWodLinks from '../OtherSpellingSmgWodLinks/OtherSpellingSmgWodLinks';
import './smgWodLinks.scss';

const SmgWodLinks = ({ internalId, otherNameSpelling, index }) => {
  return (
    <div className="logo-wrap">
      {internalId && (
        <OtherSpellingSmgWodLinks internalId={internalId} otherNameSpelling={otherNameSpelling} index={index} />
      )}
    </div>
  );
};

export default SmgWodLinks;
