import CustomTable from '../../Shared/Table/CustomTable';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import parse from 'html-react-parser';

import { columns } from './tableProperties';
import { getEmployeeComments } from '../../../store/actions/employeeComments';
import ContentWrapper from '../../Shared/Table/ContentWrapperMobileView/ContentWrapperMobileView';
import { defaultDateFormat } from '../../../constants';

import './employeeComments.scss';

const EmployeeComments = ({ employeeId }) => {
  const employeeComments = useSelector((state) => state.employeeCommentsReducer[employeeId]);
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const count = employeeComments?.count || 0;
  const comments = employeeComments?.comments || [];

  const data = comments.map((item, index) => {
    const commentFullDate = dayjs(item.createdAt);
    return {
      key: index,
      date: <ContentWrapper dataLabel={'Date'}>{commentFullDate.format(displayDateFormat)}</ContentWrapper>,
      time: <ContentWrapper dataLabel={'Time'}>{commentFullDate.format('hh:mm A (Z [UTC])')}</ContentWrapper>,
      comment: <ContentWrapper dataLabel={'Comment'}>{parse(item.text)}</ContentWrapper>,
    };
  });

  const getEmployeeCommentsCallback = (employeeId) => (page) => {
    return getEmployeeComments(employeeId, page);
  };

  return (
    <div className={'employee-comments'}>
      <CustomTable
        tableKey={'EmployeeComments'}
        data={data}
        columns={columns}
        count={count}
        getTableDataCallback={getEmployeeCommentsCallback(employeeId)}
        mobileView={true}
        pageSize={20}
      />
    </div>
  );
};

export default EmployeeComments;
