import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Form, Formik } from 'formik';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getFormElement } from '../../../Shared/Form/FormElements';
import { CellType } from '../../../Shared/Constants/Constants';
import { customStyles } from '../CaseItem/caseItemCustomStyles';
import { editResponsible } from '../../../../store/actions/requestDetails';
import TableComponent from '../../../Common/Table/TableComponent';
import SettingsIcon from '../../../../assets/img/settings-icon.svg';
import Status from '../../../RequestDetails/Status/Status';
import Events from '../../../Events/Events';
import actionCreators from '../../../../store/actions/actionCreators';
import GenerateExcel from './GenerateExcel/GenerateExcel';
import OtherSpellingSmgWodLinks from '../../../Common/OtherSpellingSmgWodLinks/OtherSpellingSmgWodLinks';
import ExcelTitle from './GenerateAllExcel/ExcelTitle';
import { getDismissalLabel, getIsCaseDisabled, getIsDismissedCase } from '../../../../helpers';
import { defaultDateFormat } from '../../../../constants';

import './caseTable.scss';
import BulkVisaApplicationUpdate from './BulkVisaApplicationUpdate/BulkVisaApplicationUpdate';

const stringSplit = (string) => {
  let rightString = string
    .split(/(?=[A-Z])/)
    .map((word, index) => {
      if (index === 0) {
        return word[0].toUpperCase() + word.substring(1);
      } else {
        return word[0].toLowerCase() + word.substring(1);
      }
    })
    .join(' ');
  return rightString;
};

const CaseTable = ({ cases, responsibleSelectOptions, toggle, setToggle, providedRef, filtersType }) => {
  const dispatch = useDispatch();

  const generationExcelCheckboxes = useSelector((state) => state.requestCaseList.generationExcelRequestIds);
  const { allCases, exludeIds } = useSelector((state) => state.requestCaseList);

  const wrapperRef = useRef();
  const settingsRef = useRef();

  const paramsFromLocalStorage = JSON.parse(localStorage.getItem(`${filtersType}`));

  const handleClickOutside = useCallback(
    (e) => {
      if (
        wrapperRef &&
        !wrapperRef.current.contains(e.target) &&
        providedRef &&
        !providedRef.current.contains(e.target) &&
        settingsRef &&
        !settingsRef.current.contains(e.target)
      ) {
        setToggle(!toggle);
      }
    },
    [toggle, providedRef, settingsRef],
  );

  useEffect(() => {
    if (toggle) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, toggle]);

  const onChangeGenerateExcel = (rowIndex, value, cell, colIndex) => {
    const employeeUuid = cell.employeeUuid;
    dispatch(
      actionCreators.generationExcelCheckboxesAdd(
        {
          [employeeUuid]: generationExcelCheckboxes[employeeUuid] ? !generationExcelCheckboxes[employeeUuid] : true,
        },
        employeeUuid,
      ),
    );
  };

  const [columnsView, setColumnsView] = useState(
    paramsFromLocalStorage?.columns
      ? paramsFromLocalStorage.columns
      : {
          fullName: true,
          country: true,
          city: true,
          gmAssigned: true,
          plannedRelocationDate: true,
          status: true,
          created: true,
          documents: true,
          vacIsRequired: true,
          generateExcel: filtersType === 'columnsFilter',
          notes: true,
        },
  );

  const columns = [
    {
      key: 'fullName',
      title: 'Full name',
      columnName: 'Full name',
      visibility: columnsView.fullName,
    },
    {
      key: 'country',
      title: 'Country',
      columnName: 'Country',
      visibility: columnsView.country,
    },
    {
      key: 'city',
      title: 'City',
      columnName: 'City',
      visibility: columnsView.city,
    },
    {
      key: 'gmAssigned',
      title: 'GMG assigned',
      columnName: 'GMG assigned',
      type: CellType.Dropdown,
      customStyles: customStyles,
      visibility: columnsView.gmAssigned,
      getOptions: (cell) => cell.options,
      getDefaultValue: (cell) => cell.defaultValue.value,
      getDefaultLabel: (cell) => cell.defaultValue.label,
      onChangeFunc: (cell) => cell.onChangeFunc(),
    },
    {
      key: 'plannedRelocationDate',
      title: 'Planned relocation date',
      columnName: 'Planned relocation date',
      visibility: columnsView.plannedRelocationDate,
    },
    {
      key: 'status',
      title: 'Status',
      columnName: 'Status',
      visibility: columnsView.status,
    },
    {
      key: 'created',
      title: 'Created',
      columnName: 'Created',
      visibility: columnsView.created,
    },
    {
      key: 'documents',
      title: 'Documents',
      columnName: 'Documents',
      visibility: columnsView.documents,
    },
    {
      key: 'vacIsRequired',
      title: 'VAC is required',
      columnName: 'VAC is required',
      visibility: columnsView.vacIsRequired,
    },
    {
      key: 'generateExcel',
      title: <ExcelTitle />,
      columnName: 'Excel',
      type: CellType.Checkbox,
      onChange: onChangeGenerateExcel,
      visibility: columnsView.generateExcel,
    },
    {
      key: 'notes',
      title: 'Notes',
      columnName: 'Notes',
      visibility: columnsView.notes,
    },
  ];

  const filteredColumns = columns.filter((col) => col.visibility === true);

  const onResponsibleChanged = (id, option, type) => {
    dispatch(editResponsible(id, { gmtMemberId: option, gmtMemberType: type }));
  };

  const formColumns = columns
    .filter((column) => column.key !== 'fullName')
    .map((col) => {
      return {
        fieldName: col.key,
        type: 'CheckboxReverse',
        formikRef: col.key,
        label: col.columnName,
        visibility: col.visibility,
        updateselectvalueroot: (value) => setColumnsView((object) => ({ ...object, [col.key]: value })),
        getFieldName: (field) => field,
      };
    });

  useEffect(() => {
    localStorage.setItem(
      `${filtersType}`,
      JSON.stringify({
        columns: columnsView,
      }),
    );
  }, [columnsView]);

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const dataRow = cases.map((row) => {
    const isCaseDisabled = getIsCaseDisabled({
      currentEmploymentInfo: row.employee?.currentEmploymentInfo,
      isActive: row.employee?.isActive,
      status: row.status,
      isCreatedByEmployee: row.isCreatedByEmployee,
    });

    const isDismissedCase = getIsDismissedCase({
      currentEmploymentInfo: row.employee?.currentEmploymentInfo,
      isActive: row.employee?.isActive,
    });

    const employeeUuid = row.uuid;

    return {
      fullName: {
        cell: (
          <>
            {row.isCreatedByEmployee ? (
              <Tooltip
                placement="topLeft"
                title={
                  <div className="case-item__reason-tooltip">
                    Case was created automatically <br />
                    (Added as a related profile for {row.createdBy || '-/-'} that is working at iTechArt/Vention).
                    <br />
                    You&apos;ll be able to edit this case, once relocation of Employee is approved in WoD.
                  </div>
                }
                arrowPointAtCenter={true}
                className="disabled-fullname"
              >
                {row.employee.generalInfo.fullName}{' '}
                {!row.employee?.isActive && getDismissalLabel(!row.employee?.currentEmploymentInfo)}
              </Tooltip>
            ) : (
              <Link to={`./request-details/${row.id}`.substr(1)}>
                {row.employee.generalInfo.fullName}{' '}
                {!row.employee?.isActive && getDismissalLabel(!row.employee?.currentEmploymentInfo)}
              </Link>
            )}

            <div className="logo-wrap">
              <OtherSpellingSmgWodLinks
                internalId={row.employee.internalId}
                otherNameSpelling={row.employee.generalInfo.otherNameSpelling}
                index={row.id}
              />
              {row?.events.length > 0 && <Events instanceId={row.id} events={row.events} eventsType="request" />}
            </div>
          </>
        ),
      },
      country: row.city.country ? row.city.country.name : 'Not provided',
      city: row.city.name,
      gmAssigned: {
        defaultValue: {
          value: row.caseGmtMember?.uuid ? row.caseGmtMember.uuid : 0,
          label: row.caseGmtMember?.generalInfo.fullName ? row.caseGmtMember.generalInfo.fullName : 'Assign...',
        },
        options: responsibleSelectOptions,
        customStyles: customStyles,
        onChangeFunc: (option) => onResponsibleChanged(row.id, option.value, 'case_gmt_member'),
        disabled: isCaseDisabled,
      },
      plannedRelocationDate: row.plannedDate ? dayjs(row.plannedDate).format(displayDateFormat) : 'Not provided',
      status: (
        <Status
          requestId={row.id}
          status={row.status}
          isCaseDashboardDisabled={isCaseDisabled}
          isCaseDashboardDismissed={isDismissedCase}
          caseType={row.caseType}
        />
      ),
      created: row.createdAt && dayjs(row.createdAt).format(displayDateFormat),
      vacIsRequired: row.isVacRequired ? 'Yes' : 'No',
      documents: stringSplit(row.documentsState),
      notes: row.statusNote ? row.statusNote : '',
      generateExcel: {
        cell: allCases
          ? exludeIds.includes(employeeUuid)
            ? false
            : true
          : generationExcelCheckboxes[employeeUuid]
          ? generationExcelCheckboxes[employeeUuid]
          : false,

        employeeUuid: employeeUuid,
      },
    };
  });

  return (
    <div className="tableView-wrap">
      <div className="settingsIcon-mobile" ref={settingsRef}>
        <Tooltip placement={'top'} title={'Settings'}>
          <div className="tooltip-button">
            <button
              className={classNames('shareButton main', { open: toggle })}
              onClick={() => {
                setToggle(!toggle);
              }}
              type="button"
            >
              <img className="share" src={SettingsIcon} alt="settings" />
            </button>
          </div>
        </Tooltip>
      </div>
      {toggle && (
        <div className="columns-section__wrap">
          <div className="columns-section" ref={wrapperRef}>
            {formColumns.map((col) => {
              return (
                <Formik enableReinitialize initialValues={columnsView} key={col.fieldName}>
                  {(formikProps) => {
                    return (
                      <Form>
                        <div key={col.fieldName}>{getFormElement(col.type, col, formikProps)}</div>
                      </Form>
                    );
                  }}
                </Formik>
              );
            })}
          </div>
        </div>
      )}
      {columnsView.generateExcel && (
        <GenerateExcel
          generationExcelCheckboxes={generationExcelCheckboxes}
          allCases={allCases}
          exludeIds={exludeIds}
        />
      )}
      {columnsView.generateExcel && (
        <BulkVisaApplicationUpdate
          generationExcelCheckboxes={generationExcelCheckboxes}
          allCases={allCases}
          exludeIds={exludeIds}
        />
      )}
      <TableComponent rows={dataRow} columns={filteredColumns} />
    </div>
  );
};

export default CaseTable;
