import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-zA-Zа-яА-Я])/, 'First name must contain at least one letter'),
  lastName: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-zA-Zа-яА-Я])/, 'Last name must contain at least one letter'),
  birthDate: Yup.date().required('This field is required'),
});

export const getFormSchema = ({ citizenshipsOptions, nationalitiesOptions }) => {
  return [
    {
      fieldName: 'relationType',
      type: 'TextInput',
      label: 'Role',
      disabled: true,
    },
    {
      fieldName: 'firstName',
      type: 'TextareaAsInput',
      label: 'First name as in passport',
    },
    {
      fieldName: 'lastName',
      type: 'TextareaAsInput',
      label: 'Last name as in passport',
    },
    {
      fieldName: 'fullNameRu',
      type: 'TextInput',
      label: 'Full name (Ru)',
      placeholder: 'Фамилия Имя Отчество',
    },
    {
      fieldName: 'otherNameSpelling',
      type: 'TextInput',
      label: 'Other name spelling',
    },
    {
      fieldName: 'maidenName',
      type: 'TextInput',
      label: "Mother's maiden name",
    },
    {
      fieldName: 'birthDate',
      type: 'DatePicker',
      label: 'Birth date',
    },
    {
      fieldName: 'citizenshipId',
      type: 'Dropdown',
      label: 'Citizenship',
      options: citizenshipsOptions,
    },
    {
      fieldName: 'nationalityId',
      type: 'Dropdown',
      label: 'Nationality',
      options: nationalitiesOptions,
    },
  ];
};
