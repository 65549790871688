import { Field, ErrorMessage } from 'formik';
import classNames from 'classnames';

import './checkboxGroup.scss';

const CheckboxGroup = (props) => {
  const {
    name,
    label,
    options,
    formIndex,
    nameFieldArray,
    canHaveDisplayHorizontal,
    disabled,
    updateselectvalueroot,
    formikProps,
    isRequired,
  } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  const handleChange = (e) => {
    const values = [...formikProps.values[fieldName]] || [];
    const index = values.indexOf(e.target.value);
    if (index === -1) {
      values.push(e.target.value);
    } else {
      values.splice(index, 1);
    }
    formikProps.setFieldValue(e.target.name, values);
    if (updateselectvalueroot) {
      updateselectvalueroot(values, formIndex, formikProps);
    }
  };

  return (
    <div className="form-group radio-horizontal">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={name}>
          {label}
        </label>
      )}
      <div className={classNames(canHaveDisplayHorizontal ? 'horizontal' : 'checkboxes-vertical')}>
        {options.map((opt) => {
          const checkboxKey = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${opt.key}` : `${opt.key}`;
          return (
            <div key={checkboxKey} className="custom-control custom-checkbox option-inline">
              <Field
                className="custom-control-input"
                type="checkbox"
                id={checkboxKey}
                name={fieldName}
                value={opt.value}
                disabled={disabled}
                {...(updateselectvalueroot ? { onChange: (e) => handleChange(e, checkboxKey) } : {})}
              />
              <label className="custom-checkbox__label" htmlFor={checkboxKey}>
                <span></span>
                {opt.text}
              </label>
            </div>
          );
        })}
      </div>
      <ErrorMessage
        name={fieldName}
        render={(msg) => (
          <span className="error" style={{ color: 'red' }}>
            {msg}
          </span>
        )}
      />
    </div>
  );
};

export default CheckboxGroup;
