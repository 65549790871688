import EmployeeDocuments from '../Content/Employee/EmployeeDocuments/EmployeeDocuments';
import EmployeeForms from '../Content/Employee/EmployeeForms/EmployeeForms';
import PersonalInfo from '../Content/Employee/PersonalInfo/PersonalInfo';
import ExistingRelatives from '../Content/ExistingRelatives/ExistingRelatives';
import LinkedEmployeeTab from '../Content/LinkedEmployeeTab/LinkedEmployeeTab';
import NewRelativeTab from '../Content/NewRelativeTab/NewRelativeTab';
import RelativeDocuments from '../Content/Relative/RelativeDocuments/RelativeDocuments';
import RelativeEducationInfo from '../Content/Relative/RelativeEducationInfo/RelativeEducationInfo';
import RelativeEmploymentInfo from '../Content/Relative/RelativeEmploymentInfo/RelativeEmploymentInfo';
import RelativeForms from '../Content/Relative/RelativeForms/RelativeForms';
import RelativeVisaBlock from '../Content/Relative/RelativeVisaBlock/RelativeVisaBlock';
import Comments from '../Content/Employee/Comments/Comments';

//employee tab, new relative tab

export const getEmployeeTab = ({ docsInfo, docsInfoEmployee }) => {
  const employeeUuid = docsInfoEmployee.uuid;
  const generalInfo = docsInfoEmployee.generalInfo;

  const documentRequest = docsInfo ? docsInfo?.documentRequest : docsInfoEmployee?.documentRequest;

  return {
    id: docsInfoEmployee.internalId,
    tabTitle: docsInfoEmployee.generalInfo.fullName,
    type: 'employee',
    content: (type) => {
      switch (type) {
        case 'personalInfo':
          return <PersonalInfo employeeId={employeeUuid} generalInfo={generalInfo} />;
        case 'documents':
          return <EmployeeDocuments docsInfoEmployee={docsInfoEmployee} employeeId={employeeUuid} />;
        case 'forms':
          return <EmployeeForms docsInfoEmployee={docsInfoEmployee} employeeId={employeeUuid} />;
        case 'comments':
          return (
            <Comments
              employeeId={employeeUuid}
              documentRequest={documentRequest}
              pageType={docsInfo ? 'case' : 'profile'}
            />
          );
        default:
          return <PersonalInfo employeeId={employeeUuid} generalInfo={generalInfo} />;
      }
    },
  };
};

export const getNewRelativeTab = ({
  requestId,
  docsInfoEmployee,
  setCurrentTab,
  setWithWhom,
  currentTab,
  withWhom,
  setSelectedTab,
}) => {
  const employeeUuid = docsInfoEmployee.uuid;

  return {
    id: docsInfoEmployee.internalId + 1,
    className: 'add-new-relative-tab',
    tabTitle: '+ Add new relative',
    type: 'newRelative',
    content: (type) => {
      switch (type) {
        case 'personalInfo':
          return (
            <NewRelativeTab
              requestId={requestId}
              employeeId={employeeUuid}
              withWhom={withWhom}
              setWithWhom={setWithWhom}
              currentTab={currentTab}
              setCurrentTab={setCurrentTab}
              setSelectedTab={setSelectedTab}
            />
          );
        default:
          return null;
      }
    },
  };
};

export const getExistingRelativeTab = ({ requestId, docsInfo, setWithWhom, withWhom }) => {
  return {
    id: docsInfo.employee.internalId + 2,
    className: 'add-new-existing-tab',
    tabTitle: '+ Add existing relative',
    type: 'existingRelatives',
    content: (type) => {
      switch (type) {
        case 'existingRelatives':
          return (
            <ExistingRelatives
              requestId={requestId}
              existingRelatives={docsInfo.relativesNotInRequest}
              withWhom={withWhom}
              setWithWhom={setWithWhom}
            />
          );
        default:
          return null;
      }
    },
  };
};

//linked employee tab

export const getLinkedTab = ({ item, docsInfo }) => {
  const employeeUuid = docsInfo.employee.uuid;
  const generalInfo = docsInfo.employee.generalInfo;

  return {
    id: item.employee.internalId,
    tabTitle: item.employee.generalInfo.fullName,
    type: 'linkedEmployee',

    content: (type) => {
      switch (type) {
        case 'personalInfo':
          return <LinkedEmployeeTab />;
        default:
          return <LinkedEmployeeTab />;
      }
    },
  };
};

//relative tab

export const getRelativeTab = (
  item,
  requestId,
  docsInfo,
  docsInfoRelatives,
  setCurrentTab,
  currentTab,
  index,
  setSelectedTab,
) => {
  const employeeUuid = docsInfo.employee.uuid;
  const documents = docsInfoRelatives[item?.id]?.relatedInfo?.documents;
  const relativeForms = docsInfoRelatives[item?.id]?.relatedInfo?.forms;

  return {
    requestId: `relative_${item?.id}`,
    id: `relative_${item?.relative.id}`,
    tabTitle: item?.relative?.generalInfo?.fullName,
    relationType: item?.relative?.relationType,
    type: item?.relative?.relationType,
    content: (type) => {
      switch (type) {
        case 'personalInfo':
          return (
            <>
              <NewRelativeTab
                requestId={requestId}
                relatedInfo={item?.relative}
                employeeId={employeeUuid}
                documentsDetails={docsInfo}
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
                setSelectedTab={setSelectedTab}
              />
            </>
          );

        case 'employmentInfo':
          return (
            <>
              {item?.relative?.relationType === 'spouse' && (
                <RelativeEmploymentInfo
                  key={`relativeEmploymentInfo.${index}`}
                  employeeUuid={employeeUuid}
                  relativeId={item.relative.id}
                  relReqId={item.id}
                  employmentInfo={docsInfoRelatives[item?.id]?.relatedInfo?.relative?.employmentInfo}
                />
              )}
            </>
          );
        case 'educationInfo':
          return (
            <>
              {item?.relative?.relationType === 'child' && (
                <RelativeEducationInfo
                  key={`relativeEducationInfo.${index}`}
                  employeeUuid={employeeUuid}
                  relativeId={item.relative.id}
                  relReqId={item.id}
                  employmentInfo={docsInfoRelatives[item?.id]?.relatedInfo?.relative?.employmentInfo}
                />
              )}
            </>
          );
        case 'documents':
          return (
            <>
              <RelativeVisaBlock
                isVacRequired={item?.isVacRequired}
                relativeRequestId={item?.id}
                displayVacIsRequiredForRelatives={docsInfo.displayVacIsRequiredForRelatives}
                requestId={requestId}
              />
              <RelativeDocuments
                requestId={requestId}
                relativeInfo={item}
                documents={documents}
                employeeId={employeeUuid}
              />
            </>
          );
        case 'forms':
          return (
            <>
              {(item?.relative?.relationType === 'spouse' || item?.relative?.relationType === 'child') && (
                <RelativeForms
                  documents={relativeForms}
                  requestId={requestId}
                  relatedReqId={item.id}
                  relativeId={item.relative.id}
                  employeeId={employeeUuid}
                />
              )}
            </>
          );
        default:
          return null;
      }
    },
  };
};
