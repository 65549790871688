import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { getValuesToUpdate } from '../../../../../../../helpers';
import { upsertRelatedDocuments } from '../../../../../../../store/actions/requestDetails';
import DocumentForm from '../../../../../../Common/DocumentForm/DocumentForm';

const RelativeDocument = ({ requestId, relatedId, relatedReqId, document, employeeId }) => {
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting, setFieldValue, resetForm, setStatus }) => {
    const valuesInfo = { ...values };

    valuesInfo[document.slug].forEach((item) => {
      if (item.id === null) delete item.id;
    });

    const allFormFields = document.forms[0].fields.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.fieldName);
      accumulator.push(currentValue.fieldName + 'DateExtraFields');
      return accumulator;
    }, []);

    const valuesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
      })),
      allFormFields,
    );

    const valuesWithFilesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
        files: [],
      })),
      ['files'],
    );

    const valuesToAdd = values[document.slug].filter(({ id }) => id === null || id === undefined);

    const files = valuesInfo[document.slug].some((item) => item?.files?.length);

    if (valuesToUpdate.length || valuesToAdd.length || files) {
      dispatch(
        upsertRelatedDocuments(
          valuesToUpdate,
          valuesToAdd,
          requestId,
          relatedId,
          relatedReqId,
          document.id,
          'related',
          true,
          employeeId,
          valuesWithFilesToUpdate,
        ),
      ).finally(() => {
        const relativesDocs = JSON.parse(sessionStorage.getItem('RelativeDocs'));
        const relativeDocsByRelativeId = relativesDocs?.[relatedReqId] || {};
        if (document.slug in relativeDocsByRelativeId) {
          delete relativeDocsByRelativeId[document.slug];
        }
        sessionStorage.setItem(
          'RelativeDocs',
          JSON.stringify({
            ...JSON.parse(sessionStorage.getItem('RelativeDocs')),
            [relatedReqId]: relativeDocsByRelativeId,
          }),
        );

        if (document.isWithAttachments) {
          values[document.slug].forEach((item, index) => setFieldValue(`${document.slug}.${index}.files`, []));
        }
        setSubmitting(false);
      });
    }
  };

  return (
    <div>
      <DocumentForm
        document={document}
        requestId={requestId}
        relatedId={relatedId}
        relatedReqId={relatedReqId}
        onSubmit={onSubmit}
        isCase={false}
        employeeId={employeeId}
        documentType={{ forWhom: 'related', type: 'documents' }}
        submitBtnTitle={'Save'}
        isDocumentsSubmit
      />
    </div>
  );
};

export default RelativeDocument;
