import './notaBene.scss';

const NotaBene = () => {
  return (
    <p className="send-docs__nota-bene">
      <span className="bold">NB:</span> Global Mobility will be able to see all attached documents after you click on
      the <span className="bold">Send docs</span> button. <br />
      <span className="bold">Don’t forget to click this button once you attach all files.</span>
    </p>
  );
};

export default NotaBene;
