import { types } from '../actions/types';

const initialState = {
  feedbacks: [],
  next: null,
  previous: null,
  count: 0,
};

const feedbackTableReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FEEDBACK_TABLE_LOAD:
      return {
        ...state,
        feedbacks: action.payload.feedbacks,
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
        page: action.payload.page,
      };
    default:
      return {
        ...state,
      };
  }
};

export default feedbackTableReducer;
