import AttachmentLink from '../../AttachmentLink/AttachmentLink';

const AttachmentBlock = (props) => {
  const { form, document, employeeId } = props;
  return (
    <>
      <div className="attachments-block">
        {document.forms.map((item, index) =>
          item.attachments.map((attach, subindex) => {
            if (item.formId === form.id) {
              return (
                <AttachmentLink
                  key={attach.id}
                  attachmentId={attach.id}
                  gfId={document.id}
                  formId={item.formId}
                  employeeId={employeeId}
                  attach={attach}
                />
              );
            } else return null;
          }),
        )}
      </div>
    </>
  );
};

export default AttachmentBlock;
