import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BackArrowImg from '../../../assets/img/buttons/backArrow.svg';
import './backArrow.scss';
import { ArrowLeftOutlined } from '@ant-design/icons';

const BackArrow = ({ type }) => {
  const history = useHistory();
  return (
    <>
      {type !== 'faq' && history?.length > 1 ? (
        <div className="back-to-main" onClick={() => history.goBack()}>
          <ArrowLeftOutlined /> {/*<img src={BackArrowImg} alt="arrow" />*/}
          <p className="text">Back</p>
        </div>
      ) : (
        <Link to="/relocation">
          <div className="back-to-main">
            <img src={BackArrowImg} alt="arrow" />
            <p className="text">Back</p>
          </div>
        </Link>
      )}
    </>
  );
};

export default BackArrow;
