import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  key: Yup.string().required('This field is required'),
});

export const formSchemaWithoutRequiredField = [
  {
    fieldName: 'key',
    type: 'CustomSelect',
    label: 'Select step to update',
    options: [
      { text: 'Employee docs are gathered', value: 'is_docs_gathered_new' },
      { text: 'Docs for related profile(s) are gathered', value: 'is_related_docs_gathered_new' },
      { text: 'Docs are sent to the investment agency', value: 'docs_are_sent_to_agency_new' },
      { text: 'VAC appointment is scheduled', value: 'is_scheduled_new' },
      { text: 'VAC appointment is attended', value: 'is_attended_new' },
      { text: 'Passport is collected', value: 'is_passport_collected_new' },
    ],
  },
  {
    fieldName: 'data.date',
    type: 'DatePicker',
    label: 'Date',
  },
];

export const initialValues = {
  key: '',
  data: {
    value: true,
    date: null,
  },
};
