import { useState, useEffect, useMemo } from 'react';

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const windowHeight = useMemo(() => window.innerHeight, []);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };

    window.addEventListener('scroll', updatePosition);

    // updatePosition();
    return () => window.removeEventListener('scroll', updatePosition);
  }, []);

  return [scrollPosition, windowHeight];
};

export default useScrollPosition;
