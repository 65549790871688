import Dropzone from './Dropzone/Dropzone';

import './attachmentBlock.scss';

const AttachmentBlock = ({
  formikProps,
  forceDisabled,
  nameFieldArray,
  name,
  formIndex,
  isLegalizationRequestDocument,
}) => {
  const fieldName = nameFieldArray && formIndex >= 0 ? `${nameFieldArray}.${formIndex}.files` : name;

  return !isLegalizationRequestDocument ? (
    <div className="attach-photos-dropzone__container">
      <div className="attach-photos-dropzone form-group">
        <label htmlFor={fieldName} className="control-label">
          Attachments
        </label>
        <Dropzone formikProps={formikProps} name={fieldName} forceDisabled={forceDisabled} />
      </div>
    </div>
  ) : null;
};

export default AttachmentBlock;
