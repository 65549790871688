import { useEffect } from 'react';

import CheckboxGroup from './CheckboxGroup/CheckboxGroup';
import DateRangeFilterView from '../DateRangeFilter/DateRangeFilterView/DateRangeFIlterView';

const CheckboxGroupDateRangeFilter = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  close,
  visible,
  options,
}) => {
  useEffect(() => {
    if (!visible) {
      confirm();
    }
  }, [visible]);

  const dateRange = selectedKeys[0]?.dateRange || [];

  const onChangeDateRange = (values) => {
    setSelectedKeys([
      {
        ...selectedKeys[0],
        dateRange: values
          ? [
              {
                after: values[0].format('MM/DD/YYYY'),
                before: values[1].format('MM/DD/YYYY'),
              },
            ]
          : [],
      },
    ]);
  };

  return (
    <div className="multiple-dropdown-date-range-filter">
      <CheckboxGroup options={options} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} />
      <DateRangeFilterView
        value={dateRange[0]}
        onChange={onChangeDateRange}
        disabled={!selectedKeys[0]}
        clearFilters={clearFilters}
        confirm={confirm}
      />
    </div>
  );
};

export default CheckboxGroupDateRangeFilter;
