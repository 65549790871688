export const formSchema = [
  {
    fieldName: 'country',
    type: 'TextInput',
    label: 'Country',
    disabled: true,
  },
  {
    fieldName: 'city',
    type: 'TextInput',
    label: 'City',
    disabled: true,
  },
  {
    fieldName: 'targetUnit',
    type: 'TextInput',
    label: 'Target unit',
    disabled: true,
  },
  {
    fieldName: 'targetManager',
    type: 'TextInput',
    label: 'Target manager',
    disabled: true,
  },
  {
    fieldName: 'customCity',
    type: 'TextInput',
    label: 'Custom city to choise',
    disabled: true,
  },
  {
    fieldName: 'createdAt',
    type: 'DatePicker',
    label: 'Created',
    disabled: true,
  },
  {
    fieldName: 'withWhom',
    type: 'Checkboxes',
    label: 'With whom',
    inline: true,
    options: [
      { text: 'Spouse', key: 'withSpouse', value: 'withSpouse' },
      { text: 'Children', key: 'withChildren', value: 'withChildren' },
      { text: 'Pets', key: 'withPets', value: 'withPets' },
    ],
  },
  {
    fieldName: 'employeeComment',
    type: 'TextareaAsInput',
    label: 'Employee comment',
    disabled: true,
    placeholder: 'No comments',
  },
  {
    fieldName: 'consultationCountry',
    type: 'Dropdown',
    label: 'Consulate location',
    options: [],
  },
];
