import { upsertForeignPoliceAppointment } from '../../components/RequestDetails/ForeignPoliceAppointment/ForeignPoliceAppointment';

export const types = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  AUTHENTICATION_SUCCESS: 'AUTHENTICATION_SUCCESS',
  AUTHORIZATION_SUCCESS: 'AUTHORIZATION_SUCCESS',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOG_OUT: 'LOG_OUT',

  USER_PHOTO_LOADED: 'USER_PHOTO_LOADED',

  DATE_FORMAT_LOADED: 'DATE_FORMAT_LOADED',
  DATE_FORMAT_UPDATED: 'DATE_FORMAT_UPDATED',

  REQUEST_LIST_LOADED: 'REQUEST_LIST_LOADED',
  REQUEST_LIST_ERROR: 'REQUEST_LIST_ERROR',
  REQUEST_LIST_UPDATED: 'REQUEST_LIST_UPDATED',
  REQUEST_LIST_UPDATE: 'REQUEST_LIST_UPDATE',

  REQUEST_RELOCATE_UPDATED: 'REQUEST_RELOCATE_UPDATED',
  OTHER_DETAILS_LOADED: 'OTHER_DETAILS_LOADED',
  OTHER_DETAILS_UPDATED: 'OTHER_DETAILS_UPDATED',

  REQUEST_DETAILS_LOADED: 'REQUEST_DETAILS_LOADED',
  REQUEST_DETAILS_UPDATED: 'REQUEST_DETAILS_UPDATED',
  REQUEST_DETAILS_ERROR: 'REQUEST_DETAILS_ERROR',

  GENERAL_INFO_UPDATED: 'GENERAL_INFO_UPDATED',
  CONTACT_INFO_UPDATED: 'CONTACT_INFO_UPDATED',

  GENERIC_FORM_LOADED: 'GENERIC_FORM_LOADED',
  GENERIC_FORM_UPDATED: 'GENERIC_FORM_UPDATED',
  GENERIC_FORM_ERROR: 'GENERIC_FORM_ERROR',

  GENERIC_FORMS_LOADED: 'GENERIC_FORMS_LOADED',
  GENERIC_FORMS_EMPLOYEE_RELATIVE_LOAD: 'GENERIC_FORMS_EMPLOYEE_RELATIVE_LOAD',
  GENERIC_FORMS_ADD: 'GENERIC_FORMS_ADD',
  GENERIC_FORMS_UPDATED: 'GENERIC_FORMS_UPDATED',
  GENERIC_FORMS_ERROR: 'GENERIC_FORMS_ERROR',

  REQUEST_NATIONALITIES_LOADED: 'REQUEST_NATIONALITIES_LOADED',
  REQUEST_NATIONALITIES_ERROR: 'REQUEST_NATIONALITIES_ERROR',

  SEARCH_EMPLOYEES_LOADED: 'SEARCH_EMPLOYEES_LOADED',
  SEARCH_EMPLOYEES_UPDATED: 'SEARCH_EMPLOYEES_UPDATED',
  SEARCH_EMPLOYEES_ERROR: 'SEARCH_EMPLOYEES_ERROR',

  VAC_APPOINTMENT_ADD: 'VAC_APPOINTMENT_ADD',
  VAC_APPOINTMENT_UPDATE: 'VAC_APPOINTMENT_UPDATE',
  VAC_APPOINTMENT_ADDRESSES_LOADED: 'VAC_APPOINTMENT_ADDRESSES_LOADED',
  CURRENT_VAC_NOTIFIED_UPDATE: 'CURRENT_VAC_NOTIFIED_UPDATE',

  REQUEST_RELATED_INFO_LOADED: 'REQUEST_RELATED_INFO_LOADED',

  TRANSPORTATION_DETAILS_ADD: 'TRANSPORTATION_DETAILS_ADD',
  TRANSPORTATION_DETAILS_UPDATE: 'TRANSPORTATION_DETAILS_UPDATE',

  RELATED_VAC_APPOINTMENT_ADD: 'RELATED_VAC_APPOINTMENT_ADD',
  RELATED_VAC_APPOINTMENT_UPDATE: 'RELATED_VAC_APPOINTMENT_UPDATE',
  RELATED_CURRENT_VAC_NOTIFIED_UPDATE: 'RELATED_CURRENT_VAC_NOTIFIED_UPDATE',

  RELATED_FOREIGN_POLICE_ADD: 'RELATED_FOREIGN_POLICE_ADD',
  RELATED_FOREIGN_POLICE_UPDATE: 'RELATED_FOREIGN_POLICE_UPDATE',
  RELATED_CURRENT_FOREIGN_POLICE_NOTIFIED_UPDATE: 'RELATED_CURRENT_FOREIGN_POLICE_NOTIFIED_UPDATE',

  RELATED_TRANSPORTATION_DETAILS_ADD: 'RELATED_TRANSPORTATION_DETAILS_ADD',
  RELATED_TRANSPORTATION_DETAILS_UPDATE: 'RELATED_TRANSPORTATION_DETAILS_UPDATE',

  ACCOMMODATION_DETAILS_UPDATE: 'ACCOMMODATION_DETAILS_UPDATE',
  ACCOMMODATION_DETAILS_ADD: 'ACCOMMODATION_DETAILS_ADD',

  REQUEST_PETS_INFO_LOADED: 'REQUEST_PETS_INFO_LOADED',
  PETS_TRANSPORTATION_DETAILS_UPDATE: 'PETS_TRANSPORTATION_DETAILS_UPDATE',
  PETS_TRANSPORTATION_DETAILS_ADD: 'PETS_TRANSPORTATION_DETAILS_ADD',

  COUNTRIES_FOR_RELOCATION_LOAD: 'COUNTRIES_FOR_RELOCATION_LOAD',
  COUNTRIES_WITH_COMPANIES_LOAD: 'COUNTRIES_WITH_COMPANIES_LOAD',
  CITIES_FOR_RELOCATION_LOAD: 'CITIES_FOR_RELOCATION_LOAD',

  RESPONSIBLE_GMG_LOAD: 'RESPONSIBLE_GMG_LOAD',

  REQUEST_CASE_LOADED: 'REQUEST_CASE_LOADED',
  REQUEST_CASE_ERROR: 'REQUEST_CASE_ERROR',
  REQUEST_CASE_UPDATED: 'REQUEST_CASE_UPDATED',
  REQUEST_CASE_UPDATE: 'REQUEST_CASE_UPDATE',

  RELATED_REQUESTS_ADD: 'RELATED_REQUESTS_ADD',
  RELATED_PET_REQUEST_ADD: 'RELATED_PET_REQUEST_ADD',

  GENERIC_FORMS_UPDATE: 'GENERIC_FORMS_UPDATE',

  GENERIC_RELOCATION_FORM_LOADED: 'GENERIC_RELOCATION_FORM_LOADED',
  GENERIC_RELOCATION_FORM_UPDATED: 'GENERIC_RELOCATION_FORM_UPDATED',
  GENERIC_RELOCATION_FORM_ERROR: 'GENERIC_RELOCATION_FORM_ERROR',

  GENERIC_RELOCATION_FORMS_LOADED: 'GENERIC_RELOCATION_FORMS_LOADED',
  GENERIC_RELOCATION_FORMS_UPDATE: 'GENERIC_RELOCATION_FORMS_UPDATE',
  GENERIC_RELOCATION_FORMS_UPDATED: 'GENERIC_RELOCATION_FORMS_UPDATED',
  GENERIC_RELOCATION_FORMS_ADD: 'GENERIC_RELOCATION_FORMS_ADD',
  GENERIC_RELOCATION_FORMS_ERROR: 'GENERIC_RELOCATION_FORMS_ERROR',

  RELOCATION_DOCUMENT_ADD: 'RELOCATION_DOCUMENT_ADD',
  RELOCATION_DOCUMENT_UPDATE: 'RELOCATION_DOCUMENT_UPDATE',

  DOCUMENT_ADD: 'DOCUMENT_ADD',
  DOCUMENT_UPDATE: 'DOCUMENT_UPDATE',
  RELATED_DOCUMENT_UPDATE: 'RELATED_DOCUMENT_UPDATE',
  RELATED_DOCUMENT_ADD: 'RELATED_DOCUMENT_ADD',
  PETS_DOCUMENT_UPDATE: 'PETS_DOCUMENT_UPDATE',
  PETS_DOCUMENT_ADD: 'PETS_DOCUMENT_ADD',

  DOCUMENT_ATTACHMENT_ADD: 'DOCUMENT_ATTACHMENT_ADD',
  RELATED_DOCUMENT_ATTACHMENT_ADD: 'RELATED_DOCUMENT_ATTACHMENT_ADD',
  PET_DOCUMENT_ATTACHMENT_ADD: 'PET_DOCUMENT_ATTACHMENT_ADD',

  TICKET_ATTACHMENT_ADD: 'TICKET_ATTACHMENT_ADD',
  RELATED_TICKET_ATTACHMENT_ADD: 'RELATED_TICKET_ATTACHMENT_ADD',
  PET_TICKET_ATTACHMENT_ADD: 'PET_TICKET_ATTACHMENT_ADD',

  ACCOMMODATION_ATTACHMENT_ADD: 'ACCOMMODATION_ATTACHMENT_ADD',

  TOAST_UPDATE: 'TOAST_UPDATE',
  TOAST_RESET: 'TOAST_RESET',

  REQUEST_CITIZENSHIPS_LOADED: 'REQUEST_CITIZENSHIPS_LOADED',

  RELATED_GENERAL_INFO_UPDATE: 'RELATED_GENERAL_INFO_UPDATE',
  RELATED_CONTACT_INFO_UPDATE: 'RELATED_CONTACT_INFO_UPDATE',
  RELATED_EMPLOYMENT_INFO_UPDATE: 'RELATED_EMPLOYMENT_INFO_UPDATE',

  PETS_GENERAL_INFO_UPDATE: 'PETS_GENERAL_INFO_UPDATE',

  HR_NOTES_LOAD: 'HR_NOTES_LOAD',
  HR_NOTES_UPDATE: 'HR_NOTES_UPDATE',

  GENERAL_PET_PROFILE_NOTES_ADD: 'GENERAL_PET_PROFILE_NOTES_ADD',
  GENERAL_PET_PROFILE_NOTES_UPDATE: 'GENERAL_PET_PROFILE_NOTES_UPDATE',

  REQUEST_HISTORY_LOADED: 'REQUEST_HISTORY_LOADED',
  REQUEST_HISTORY_ERROR: 'REQUEST_HISTORY_ERROR',

  RELATIVE_REQUEST_HISTORY_LOADED: 'RELATIVE_REQUEST_HISTORY_LOADED',
  RELATIVE_REQUEST_HISTORY_ERROR: 'RELATIVE_REQUEST_HISTORY_ERROR',

  PET_REQUEST_HISTORY_LOADED: 'PET_REQUEST_HISTORY_LOADED',
  PET_REQUEST_HISTORY_ERROR: 'PET_REQUEST_HISTORY_ERROR',

  COMPANIES_LOAD: 'COMPANIES_LOAD',
  UNITS_LOAD: 'UNITS_LOAD',

  EMPLOYMENT_INFO_ADD: 'EMPLOYMENT_INFO_ADD',
  EMPLOYMENT_INFO_UPDATE: 'EMPLOYMENT_INFO_UPDATE',

  DOCUMENT_ATTACHMENT_DELETE: 'DOCUMENT_ATTACHMENT_DELETE',
  RELATED_DOCUMENT_ATTACHMENT_DELETE: 'RELATED_DOCUMENT_ATTACHMENT_DELETE',
  PET_DOCUMENT_ATTACHMENT_DELETE: 'PET_DOCUMENT_ATTACHMENT_DELETE',
  TICKET_ATTACHMENT_DELETE: 'TICKET_ATTACHMENT_DELETE',
  RELATED_TICKET_ATTACHMENT_DELETE: 'RELATED_TICKET_ATTACHMENT_DELETE',
  PET_TICKET_ATTACHMENT_DELETE: 'PET_TICKET_ATTACHMENT_DELETE',
  ACCOMMODATION_ATTACHMENT_DELETE: 'ACCOMMODATION_ATTACHMENT_DELETE',

  TICKET_FORM_DELETE: 'TICKET_FORM_DELETE',
  RELATED_TICKET_FORM_DELETE: 'RELATED_TICKET_FORM_DELETE',
  PET_TICKET_FORM_DELETE: 'PET_TICKET_FORM_DELETE',
  ACCOMMODATION_FORM_DELETE: 'ACCOMMODATION_FORM_DELETE',
  VAC_APPOINTMENT_FORM_DELETE: 'VAC_APPOINTMENT_FORM_DELETE',
  RELATED_VAC_APPOINTMENT_FORM_DELETE: 'RELATED_VAC_APPOINTMENT_FORM_DELETE',
  RELATED_FOREIGN_POLICE_DELETE: 'RELATED_FOREIGN_POLICE_DELETE',
  DOCUMENT_FORM_DELETE: 'DOCUMENT_FORM_DELETE',
  RELATED_DOCUMENT_FORM_DELETE: 'RELATED_DOCUMENT_FORM_DELETE',
  PET_DOCUMENT_FORM_DELETE: 'PET_DOCUMENT_FORM_DELETE',

  FAQ_INFO_LOAD: 'FAQ_INFO_LOAD',
  FAQ_INFO_UPDATED: 'FAQ_INFO_UPDATED',

  RELOCATED_EMPLOYEES_LOADED: 'RELOCATED_EMPLOYEES_LOADED',
  RELOCATED_EMPLOYEES_ERROR: 'RELOCATED_EMPLOYEES_ERROR',
  RELOCATED_EMPLOYEES_UPDATED: 'RELOCATED_EMPLOYEES_UPDATED',

  STATISTICS_LOAD: 'STATISTICS_LOAD',

  DOCUMENT_FORM_ARCHIVE: 'DOCUMENT_FORM_ARCHIVE',
  DOCUMENT_FORM_UNARCHIVE: 'DOCUMENT_FORM_UNARCHIVE',
  RELATIVE_DOCUMENT_FORM_ARCHIVE: 'RELATIVE_DOCUMENT_FORM_ARCHIVE',
  RELATIVE_DOCUMENT_FORM_UNARCHIVE: 'RELATIVE_DOCUMENT_FORM_UNARCHIVE',

  PLANNED_RELOCATION_NOTES_ADD: 'PLANNED_RELOCATION_NOTES_ADD',
  PLANNED_RELOCATION_NOTES_UPDATE: 'PLANNED_RELOCATION_NOTES_UPDATE',

  CHANGED_RELATIVES: 'CHANGED_RELATIVES',

  RELOCATION_COSTS_LOAD: 'RELOCATION_COSTS_LOAD',
  RELOCATION_COSTS_UPDATE: 'RELOCATION_COSTS_UPDATE',
  RELOCATION_COSTS_STRUCT_UPDATE: 'RELOCATION_COSTS_STRUCT_UPDATE',
  RELOCATION_COSTS_STRUCT_ITEM_DELETE: 'RELOCATION_COSTS_STRUCT_ITEM_DELETE',
  RELOCATION_COSTS_STRUCT_NAME_EDIT: 'RELOCATION_COSTS_STRUCT_NAME_EDIT',

  CHANGED_VAC_RELATIVES: 'CHANGED_VAC_RELATIVES',
  CHANGED_FOREIGN_POLICE_RELATIVES: 'CHANGED_FOREIGN_POLICE_RELATIVES',
  CHANGED_TD_RELATIVES: 'CHANGED_TD_RELATIVES',

  EXCEL_FORM_LOADED: 'EXCEL_FORM_LOADED',
  EXCEL_FORMS_LOAD_RELATIVE: 'EXCEL_FORMS_LOAD_RELATIVE',
  EXCEL_FORM_UPDATED: 'EXCEL_FORM_UPDATED',
  EXCEL_FORMS_ADD: 'EXCEL_FORMS_ADD',
  EXCEL_FORMS_UPDATE: 'EXCEL_FORMS_UPDATE',
  EXCEL_FORMS_LOADED: 'EXCEL_FORMS_LOADED',
  EXCEL_FORMS_UPDATED: 'EXCEL_FORMS_UPDATED',
  EXCEL_DOCUMENT_UPDATE: 'EXCEL_DOCUMENT_UPDATE',
  EXCEL_DOCUMENT_ADD: 'EXCEL_DOCUMENT_ADD',
  EXCEL_FORM_ATTACHMENT_ADD: 'EXCEL_FORM_ATTACHMENT_ADD',
  EXCEL_FORM_ATTACHMENT_DELETE: 'EXCEL_FORM_ATTACHMENT_DELETE',
  RELATIVE_FORM_ADD: 'RELATIVE_FORM_ADD',
  RELATIVE_FORM_UPDATE: 'RELATIVE_FORM_UPDATE',
  RELATIVE_FORM_ATTACHMENT_ADD: 'RELATIVE_FORM_ATTACHMENT_ADD',
  RELAIVE_FORM_ATTACHMENT_DELETE: 'RELAIVE_FORM_ATTACHMENT_DELETE',

  COUNTRIES_WITH_FAQ_LOAD: 'COUNTRIES_WITH_FAQ_LOAD',

  APPROVERS_INFO_LOAD: 'APPROVERS_INFO_LOAD',
  SEARCH_EMPLOYEES_FOR_CREATE_CASE_LOADED: 'SEARCH_EMPLOYEES_FOR_CREATE_CASE_LOADED',

  SEARCH_REQUEST_FOR_RELATED_PROFILES: 'SEARCH_REQUEST_FOR_RELATED_PROFILES',
  LINKED_REQUESTS_UPDATED: 'LINKED_REQUESTS_UPDATED',

  COUNTRIES_LIST_LOAD: 'COUNTRIES_LIST_LOAD',
  CITIES_UPDATE: 'CITIES_UPDATE',
  CITIES_LOAD: 'CITIES_LOAD',

  REQUEST_DOCUMENTS_LOADED: 'REQUEST_DOCUMENTS_LOADED',
  UPDATE_RELATIVES_DETAILS: 'UPDATE_RELATIVES_DETAILS',
  DELETE_RELATIVE_PROFILE: 'DELETE_RELATIVE_PROFILE',
  DELETE_PET_PROFILE: 'DELETE_PET_PROFILE',
  RELATED_DOC_GENERAL_INFO_UPDATE: 'RELATED_DOC_GENERAL_INFO_UPDATE',
  PETS_DOC_GENERAL_INFO_UPDATE: 'PETS_DOC_GENERAL_INFO_UPDATE',
  RELATED_DOC_REQUESTS_ADD: 'RELATED_DOC_REQUESTS_ADD',
  RELATED_PET_DOC_REQUEST_ADD: 'RELATED_PET_DOC_REQUEST_ADD',
  CHANGED_VAC_DOC_RELATIVES: 'CHANGED_VAC_DOC_RELATIVES',
  DELETE_PET_REQUEST: 'DELETE_PET_REQUEST',
  DELETE_RELATIVE_REQUEST: 'DELETE_RELATIVE_REQUEST',

  GENERAL_DOCS_INFO_UPDATED: 'GENERAL_DOCS_INFO_UPDATED',

  EXISTING_RELATIVES_ADD: 'EXISTING_RELATIVES_ADD',
  EXISTING_RELATED_DOC_ADD: 'EXISTING_RELATED_DOC_ADD',

  EMPLOYEE_PROFILE_ADD_EVENT: 'EMPLOYEE_PROFILE_ADD_EVENT',
  REQUEST_DETAILS_ADD_EVENT: 'REQUEST_DETAILS_ADD_EVENT',
  EVENTS_SET_VIEWED: 'EVENTS_SET_VIEWED',
  EMPLOYEE_PROFILE_UPDATE: 'EMPLOYEE_PROFILE_UPDATE',
  EMPLOYEE_REMINDERS_LOAD: 'EMPLOYEE_REMINDERS_LOAD',

  RELOCATION_ROUTES_LOAD: 'RELOCATION_ROUTES_LOAD',
  RELOCATION_ROUTES_UPDATE: 'RELOCATION_ROUTES_UPDATE',

  COMPENSATION_CALCULATOR_LOAD: 'COMPENSATION_CALCULATOR_LOAD',
  COMPENSATION_CALCULATOR_UPDATE: 'COMPENSATION_CALCULATOR_UPDATE',

  LEGALIZATION_DOCUMENTS_LOAD: 'LEGALIZATION_DOCUMENTS_LOAD',
  LEGALIZATION_DOCUMENTS_UPDATE: 'LEGALIZATION_DOCUMENTS_UPDATE',
  LEGALIZATION_DOCUMENTS_CITIZENSHIP_UPDATE: 'LEGALIZATION_DOCUMENTS_CITIZENSHIP_UPDATE',
  LEGALIZATION_DOCUMENTS_REASON_UPDATE: 'LEGALIZATION_DOCUMENTS_REASON_UPDATE',
  LEGALIZATION_DOCUMENTS_ADD_EVENT: 'LEGALIZATION_DOCUMENTS_ADD_EVENT',
  LEGALIZATION_DOCUMENTS_ERROR: 'LEGALIZATION_DOCUMENTS_ERROR',
  TRACKING_DOCUMENTS_LOAD: 'TRACKING_DOCUMENTS_LOAD',
  TRACKING_DOCUMENT_ADD: 'TRACKING_DOCUMENT_ADD',
  TRACKING_DOCUMENT_ATTACHMENT_ADD: 'TRACKING_DOCUMENT_ATTACHMENT_ADD',
  TRACKING_DOCUMENT_ATTACHMENT_DELETE: 'TRACKING_DOCUMENT_ATTACHMENT_DELETE',

  EMPLOYEE_PROFILE_LOADED: 'EMPLOYEE_PROFILE_LOADED',
  EMPLOYEE_PROFILE_UPDATED: 'EMPLOYEE_PROFILE_UPDATED',

  EMPLOYEE_HISTORY_LOADED: 'EMPLOYEE_HISTORY_LOADED',
  EMPLOYEE_HISTORY_ERROR: 'EMPLOYEE_HISTORY_ERROR',

  GENERATION_EXCEL_CHECKBOXES_ADD: 'GENERATION_EXCEL_CHECKBOXES_ADD',
  GENERATION_EXCEL_CHECKBOXES_REMOVE: 'GENERATION_EXCEL_CHECKBOXES_REMOVE',

  RELATED_CASES_UPDATE: 'RELATED_CASES_UPDATE',

  REMINDERS_LOAD: 'REMINDERS_LOAD',
  REMINDERS_UPDATE: 'REMINDERS_UPDATE',
  REMINDERS_AUTHOR_LOAD: 'REMINDERS_AUTHOR_LOAD',
  REMINDER_UPDATE: 'REMINDER_UPDATE',

  CLEAR_DOCUMENTS_DASHBOARD_LOAD: 'CLEAR_DOCUMENTS_DASHBOARD_LOAD',
  CLEAR_DOCUMENTS_DASHBOARD_UPDATE: 'CLEAR_DOCUMENTS_DASHBOARD_UPDATE',

  PROFILE_DOCUMENTS_LOAD: 'PROFILE_DOCUMENTS_LOAD',

  RELOCATED_EMPLOYEES_EXCEL_CHECKBOXES_ADD: 'RELOCATED_EMPLOYEES_EXCEL_CHECKBOXES_ADD',
  RELOCATED_EMPLOYEES_EXCEL_CHECKBOXES_REMOVE: 'RELOCATED_EMPLOYEES_EXCEL_CHECKBOXES_REMOVE',
  RELOCATED_EMPLOYEES_ALL_CASES_UPDATE: 'RELOCATED_EMPLOYEES_ALL_CASES_UPDATE',

  LEGALIZATION_DOCUMENTS_EXCEL_CHECKBOXES_ADD: 'LEGALIZATION_DOCUMENTS_EXCEL_CHECKBOXES_ADD',
  LEGALIZATION_DOCUMENTS_EXCEL_CHECKBOXES_REMOVE: 'LEGALIZATION_DOCUMENTS_EXCEL_CHECKBOXES_REMOVE',
  LEGALIZATION_DOCUMENTS_ALL_CASES_UPDATE: 'LEGALIZATION_DOCUMENTS_ALL_CASES_UPDATE',

  EMPLOYEE_COMMENTS_LOAD: 'EMPLOYEE_COMMENTS_LOAD',

  REQUEST_DOCS_COMMENT_UPDATE: 'REQUEST_DOCS_COMMENT_UPDATE',
  PROFILE_DOCS_COMMENT_UPDATE: 'PROFILE_DOCS_COMMENT_UPDATE',
  CASE_LIST_ALL_CASES_UPDATE: 'CASE_LIST_ALL_CASES_UPDATE',

  WORK_PLACE_CITIES_LOAD: 'WORK_PLACE_CITIES_LOAD',

  LEGALIZATION_FORM_LOADED: 'LEGALIZATION_FORM_LOADED',
  LEGALIZATION_FORM_UPDATED: 'LEGALIZATION_FORM_UPDATED',
  LEGALIZATION_FORMS_ADD: 'LEGALIZATION_FORMS_ADD',
  LEGALIZATION_FORMS_UPDATED: 'LEGALIZATION_FORMS_UPDATED',
  LEGALIZATION_FORMS_LOADED: 'LEGALIZATION_FORMS_LOADED',
  LEGALIZATION_DOCUMENT_ADD: 'LEGALIZATION_DOCUMENT_ADD',
  LEGALIZATION_DOCUMENT_UPDATE: 'LEGALIZATION_DOCUMENT_UPDATE',
  LEGALIZATION_DOCUMENT_ATTACHMENT_ADD: 'LEGALIZATION_DOCUMENT_ATTACHMENT_ADD',
  LEGALIZATION_DOCUMENT_ATTACHMENT_DELETE: 'LEGALIZATION_DOCUMENT_ATTACHMENT_DELETE',
  LEGALIZATION_DOCUMENT_DELETE: 'LEGALIZATION_DOCUMENT_DELETE',
  LEGALIZATION_FORMS_EMPLOYEE_LOAD: 'LEGALIZATION_FORMS_EMPLOYEE_LOAD',

  EMPLOYEES_WITH_EMAILS_LOAD: 'EMPLOYEES_WITH_EMAILS_LOAD',
  EMAIL_TEMPLATES_LOAD: 'EMAIL_TEMPLATES_LOAD',
  EMAIL_TEMPLATE_LOAD: 'EMAIL_TEMPLATE_LOAD',
  CONTACT_EMPLOYEE_TEMPLATE_LOAD: 'CONTACT_EMPLOYEE_TEMPLATE_LOAD',

  FEEDBACK_FORM_UPDATED: 'FEEDBACK_FORM_UPDATED',
  FEEDBACK_FORMS_ADD: 'FEEDBACK_FORMS_ADD',
  FEEDBACK_FORMS_UPDATED: 'FEEDBACK_FORMS_UPDATED',
  FEEDBACK_FORM_LOADED: 'FEEDBACK_FORM_LOADED',
  FEEDBACK_FORMS_LOADED: 'FEEDBACK_FORMS_LOADED',
  FEEDBACK_FORMS_EMPLOYEE_LOAD: 'FEEDBACK_FORMS_EMPLOYEE_LOAD',
  REQUEST_DETAILS_FEEDBACK_FORMS_LOAD: 'REQUEST_DETAILS_FEEDBACK_FORMS_LOAD',
  REQUEST_DETAILS_EMPLOYEE_FEEDBACK_UPFATE: 'REQUEST_DETAILS_EMPLOYEE_FEEDBACK_UPFATE',
  FEEDBACK_TABLE_LOAD: 'FEEDBACK_TABLE_LOAD',

  LEGALIZATION_REQUEST_LIST_LOAD: 'LEGALIZATION_REQUEST_LIST_LOAD',
  LEGALIZATION_REQUEST_COUNTRIES_LOAD: 'LEGALIZATION_REQUEST_COUNTRIES_LOAD',
  LEGALIZATION_REQUEST_UPDATED: 'LEGALIZATION_REQUEST_UPDATED',
  LEGALIZATION_REQUEST_LOADED: 'LEGALIZATION_REQUEST_LOADED',

  CASE_CONTROLLER_LOAD: 'CASE_CONTROLLER_LOAD',
  CASE_CONTROLLER_UPDATE: 'CASE_CONTROLLER_UPDATE',

  FOREIGN_POLICE_APPOINTMENT_DELETE: 'FOREIGN_POLICE_APPOINTMENT_DELETE',
  FOREIGN_POLICE_APPOINTMENT_ADD: 'FOREIGN_POLICE_APPOINTMENT_ADD',
  FOREIGN_POLICE_APPOINTMENT_UPDATE: 'FOREIGN_POLICE_APPOINTMENT_UPDATE',
  FOREIGN_POLICE_ADDRESSES_LOADED: 'FOREIGN_POLICE_ADDRESSES_LOADED',
  FOREIGN_POLICE_IS_NOTIFIED: 'FOREIGN_POLICE_IS_NOTIFIED',
};
