import * as Yup from 'yup';
import getTypeSchema from './DocumentFormValidation';

export const setIsCurrentDocument = (documentSlug) => (value, formIndex, formikProps) => {
  if (value) {
    if (formIndex >= 0) {
      formikProps.values[documentSlug].forEach((item, index) => {
        if (index !== formIndex) {
          formikProps.setFieldValue(`${documentSlug}.${index}.isCurrent`, false);
        }
      });
    } else {
      formikProps.values[documentSlug].forEach((item, index) => {
        if (index !== formikProps.values.vacInfo.length - 1) {
          formikProps.setFieldValue(`${documentSlug}.${index}.isCurrent`, false);
        }
      });
    }
  }
};

export const useFormSchema = (document, isCase, withCurrentField, documentSlug) => {
  const formSchema = document.forms[0].fields;

  const isCurrentField = {
    element: 'Checkbox',
    fieldName: 'isCurrent',
    label: 'Document is current',
    updateselectvalueroot: setIsCurrentDocument(documentSlug),
  };

  let formSchemaWithoutDisFilds = [];
  formSchema.forEach((item) => {
    if (!item?.disabled) formSchemaWithoutDisFilds.push(item);
  });

  formSchemaWithoutDisFilds = withCurrentField
    ? [isCurrentField].concat(formSchemaWithoutDisFilds)
    : formSchemaWithoutDisFilds;

  const formSchemaWithoutEmptDisFieldsArray = !isCase
    ? document?.forms.map((form) => {
        return form.fields
          .filter(
            (item) =>
              (item.disabled &&
                form.values[item.fieldName] !== '' &&
                form.values[item.fieldName]?.length !== 0 &&
                typeof form.values[item.fieldName] !== 'undefined' &&
                form.values[item.fieldName] !== null) ||
              !item.disabled,
          )
          .map((field) => {
            return {
              ...field,
              disabled: form.isDraft === undefined || isCase ? form.isDraft : !form.isDraft,
            };
          });
      })
    : document?.forms.map((schema) =>
        !withCurrentField
          ? schema?.fields.filter(
              (item) =>
                (item.disabled &&
                  schema.values[item.fieldName] !== '' &&
                  schema.values[item.fieldName]?.length !== 0 &&
                  typeof schema.values[item.fieldName] !== 'undefined' &&
                  schema.values[item.fieldName] !== null) ||
                !item.disabled,
            )
          : [isCurrentField].concat(
              schema?.fields.filter(
                (item) =>
                  (item.disabled &&
                    schema.values[item.fieldName] !== '' &&
                    schema.values[item.fieldName]?.length !== 0 &&
                    typeof schema.values[item.fieldName] !== 'undefined' &&
                    schema.values[item.fieldName] !== null) ||
                  !item.disabled,
              ),
            ),
      );
  return [formSchemaWithoutDisFilds, formSchemaWithoutEmptDisFieldsArray];
};

export const getInitialValueByElement = (element) => {
  switch (element) {
    case 'Checkboxes':
      return [];
    default:
      return '';
  }
};

export const useInitialValues = (document, formSchemaWithoutDisFilds, withCurrentField) => {
  const initialValues = {
    [document.slug]: document.forms
      .sort((a, b) => b.isCurrent - a.isCurrent)
      .map((item) => {
        item.fields.forEach((field) => {
          if (
            field.disabled &&
            (item.values[field.fieldName] === '' ||
              item.values[field.fieldName] === null ||
              item.values[field.fieldName] === 0 ||
              item.values[field.fieldName]?.length === 0)
          ) {
            delete item.values[field.fieldName];
          }
        });
        return {
          ...item.values,
          id: item.formId,
          ...(withCurrentField && { isCurrent: item.isCurrent }),
          ...(document.isWithAttachments && { files: [] }),
        };
      }),
  };

  const initialValuesToPush = {};
  formSchemaWithoutDisFilds.forEach((item) => {
    if (item.fieldName === 'isCurrent') {
      initialValuesToPush[item.fieldName] = true;
    } else {
      initialValuesToPush[item.fieldName] = getInitialValueByElement(item.element);
    }
  });

  if (document.isWithAttachments) {
    initialValuesToPush.files = [];
  }

  return [initialValues, initialValuesToPush];
};

export const useValidationSchema = (document) => {
  const formSchema = document.forms[0].fields;
  let validation = {};

  formSchema.forEach((item) => {
    if (item.required) {
      const element = item.element === 'CustomElement' ? item.key : item.element;
      validation[item.fieldName] = getTypeSchema(element);
    }
  });
  const validationSchema = Yup.object().shape({
    [document.slug]: Yup.array().of(
      Yup.object().shape({
        ...validation,
      }),
    ),
  });
  return validationSchema;
};
