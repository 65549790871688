import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';

import { getFormElement } from '../../Shared/Form/FormElements';
import { editContactInfo, addAddressInfo, editAddressInfo } from '../../../store/actions/requestDetails';
import { getFormSchemaWithRequiredField, getValuesToUpdate } from '../../../helpers';
import actionCreators from '../../../store/actions/actionCreators';
import { formAddressSchema, candidateFormSchema, employeeFormSchema } from './formSchema';
import ContactInfoValidationSchema from '../Validation/ContactInfoValidation';
import { IsCandidateContext } from '../../EmployeeProfile/EmployeeProfile';

import './contactInfo.scss';

const addressFormFields = ['id', 'address', 'startDate', 'endDate', 'isCurrent'];

const ContactInfo = ({ isCaseDisabled, ...contactInfo }) => {
  const dispatch = useDispatch();

  const isCandidate = useContext(IsCandidateContext);
  const formSchemaWithoutRequiredField = isCandidate ? candidateFormSchema : employeeFormSchema;

  const formSchema = getFormSchemaWithRequiredField(ContactInfoValidationSchema, formSchemaWithoutRequiredField);

  const sortedAddressInfo = contactInfo.addressInfo
    .map((item) => item)
    .sort((a, b) => (a.isCurrent === b.isCurrent ? 0 : a.isCurrent ? -1 : 1));

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    const addressToUpdate = contactInfo.addressInfo.length
      ? getValuesToUpdate(values.addressInfo, contactInfo.addressInfo, addressFormFields)
      : [];

    const addressToAdd = values.addressInfo.filter(({ id }) => isNaN(id));

    const addressPromises = addressToUpdate.concat(addressToAdd).map((address) => {
      if (address.id) {
        return editAddressInfo(contactInfo.employeeId, address.id, address);
      } else {
        return addAddressInfo(contactInfo.employeeId, address);
      }
    });

    Promise.all(addressPromises)
      .then(() => dispatch(editContactInfo(contactInfo.employeeId, contactInfo.requestId, values)))
      .catch((error) => dispatch(actionCreators.toastUpdate({ type: 'error' })))
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="ContactInfo">
      <Formik
        enableReinitialize
        initialValues={{ ...contactInfo, addressInfo: sortedAddressInfo }}
        onSubmit={onSubmit}
        validationSchema={ContactInfoValidationSchema}
      >
        {(formikProps) => (
          <Form>
            {formSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
            ))}
            {formAddressSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
            ))}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactInfo;
