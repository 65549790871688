import { useDispatch } from 'react-redux';

import Checkbox from '../../FormElements/Checkbox/Checkbox';
import { editRelocationRequest } from '../../../../../store/actions/request';
import Dropdown from '../../FormElements/Dropdown/Dropdown';
import { dropdownOptions } from '../formProperties';
import { formSchema } from './formSchema';

import './visaDetails.scss';

const VisaDetails = ({ requestId, isVacRequired, vacAppointment }) => {
  const dispatch = useDispatch();

  const vacInfo = vacAppointment.length > 0 ? vacAppointment.find((item) => item.isCurrent) || {} : {};

  const onChangeVisaIsRequired = (value) => {
    dispatch(editRelocationRequest(requestId, { isVacRequired: value }));
  };

  return (
    <>
      <Dropdown
        defaultValue={dropdownOptions.find((item) => item.value === isVacRequired)}
        label="VAC is required"
        options={dropdownOptions}
        onChange={onChangeVisaIsRequired}
      />
      <div className="visa-details__checkbox-group">
        {isVacRequired &&
          formSchema.map((item) => {
            let isChecked = vacInfo[item.fieldName]?.value;

            if (item.fieldName === 'isEmployeeReminded') {
              isChecked = vacInfo[item.fieldName];
            }

            if (item.fieldName === 'status') {
              isChecked = vacInfo[item.fieldName] === 'Gathered';
            }

            return (
              <Checkbox
                key={item.fieldName}
                tabKey={item.fieldName}
                isChecked={isChecked}
                label={item.label}
                isDisabled={true}
              />
            );
          })}
      </div>
    </>
  );
};

export default VisaDetails;
