import { useState } from 'react';
import { Collapse } from 'antd';
import { useEffect } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';

import { LeftOutlined } from '@ant-design/icons';

import Spinner from '../../../Common/SpinnerContainer/Spinner/Spinner';
import { getFAQ } from '../../../../store/actions/relocation';

import './accordion.scss';

const { Panel } = Collapse;

const FAQAccordion = ({ countryId, width }) => {
  const { hash } = useLocation();
  const dispatch = useDispatch();

  const faq = useSelector((state) => state.faqInfo);
  const next = useSelector((state) => state.faqInfo[countryId]?.faqInfo.next);

  const [isLoading, setLoading] = useState(true);

  const faqInfo = faq?.[countryId];

  const activeAccordionItemKey = hash.substring(hash.indexOf(`_`) + 1);

  useEffect(() => {
    if (faqInfo?.countryId !== countryId) {
      dispatch(getFAQ({ countries: countryId }))
        .then(() => {
          const elementId = hash.substring(hash.indexOf(`#`) + 1);
          const element = document.getElementById(elementId);
          element?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        })
        .then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [countryId, dispatch, faqInfo?.countryId]);

  const getNext = () => {
    if (next) {
      dispatch(getFAQ({ countries: countryId }, next));
    }
  };

  function callback(key) {
    (key || key === 0) && history.pushState(null, null, `#question_${key}`);
  }

  return (
    <>
      {isLoading ? (
        <div className="faq-accordion__spinner">
          <Spinner />
        </div>
      ) : (
        <div className={classNames({ accordion_sm: width <= 576 }, 'accordion')}>
          {faqInfo && (
            <InfiniteScroll
              dataLength={faqInfo?.faqInfo.results.length}
              next={getNext}
              hasMore={!!next}
              scrollThreshold={0.9}
              loader={<p>Loading...</p>}
            >
              <Collapse
                className="collapse-custom"
                accordion
                onChange={callback}
                defaultActiveKey={activeAccordionItemKey}
                bordered={false}
                expandIconPosition="end"
                expandIcon={({ isActive }) => <LeftOutlined rotate={isActive ? -90 : 0} />}
              >
                {faqInfo.faqInfo.results?.map((item, index) => (
                  <Panel
                    className="collapse-custom-panel"
                    id={`question_${item.id}`}
                    header={
                      <div className="collapse-custom-question" id={`question_${item.id}`}>
                        {item.question}
                      </div>
                    }
                    key={item.id}
                    showArrow={true}
                  >
                    {parse(item.answer)}
                  </Panel>
                ))}
              </Collapse>
            </InfiniteScroll>
          )}
        </div>
      )}
    </>
  );
};

export default FAQAccordion;
