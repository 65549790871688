import { types } from '../actions/types';
import _ from 'lodash';

const initialState = {};

const relocationRoutesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RELOCATION_ROUTES_LOAD:
      return action.payload.relocationRoutes;
    case types.RELOCATION_ROUTES_UPDATE:
      return {
        ...state,
        countries: state.countries.map((country) => {
          if (+country.id === +action.payload.countryId) {
            return { ...country, steps: action.payload.relocationStepsData };
          } else return country;
        }),
      };

    default:
      return {
        ...state,
      };
  }
};

export default relocationRoutesReducer;
