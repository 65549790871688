import './checkbox.scss';

const Checkbox = ({ col, rowIndex, checked, isDisabled, cell, colIndex }) => {
  return (
    <button
      className="custom-control custom-checkbox table-component__custom-checkbox"
      onClick={() => col.onChange(rowIndex, undefined, cell, colIndex)}
      disabled={col?.disabled || isDisabled}
    >
      <input
        className="custom-control-input"
        type="checkbox"
        checked={checked}
        disabled={col?.disabled || isDisabled}
        onChange={() => col.onChange(rowIndex, undefined, cell, colIndex)}
      />
      <label className="custom-checkbox__label">
        <span></span>
      </label>
    </button>
  );
};

export default Checkbox;
