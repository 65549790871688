import './remindersCell.scss';

const RemindersCell = ({ remindersList }) => {
  return (
    <>
      {remindersList.length > 0 && (
        <div className="reminders-column">
          {remindersList.map((reminder, index) => {
            return (
              <div className="reminders-column__item" key={index}>
                <span className="reminders-column__author">{reminder.authorName}</span>: {reminder.text}.
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default RemindersCell;
