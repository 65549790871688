import classNames from 'classnames';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import './AppLayout.scss';

const AppLayout = (props) => {
  const { secondaryLayout, isApproverStaff, isPortalStaff, isHrManager } = props;

  return (
    <>
      <Header isApproverStaff={isApproverStaff} isPortalStaff={isPortalStaff} isHrManager={isHrManager} />
      <main
        className={classNames(
          'app-wrapper-main',
          'max-width',
          !secondaryLayout ? 'max-width' : 'app-wrapper-secondary',
        )}
      >
        {props.children}
      </main>
      {secondaryLayout && <Footer />}
    </>
  );
};

export default AppLayout;
