import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { relocatedEmployeesExportExcel } from '../../../store/actions/relocatedEmployees';
import SpinnerImg from '../../../assets/img/SpinnerWhite.svg';
import { getParams } from '../tableProperties/getParams';

import './generationExcel.scss';

const GenerationExcel = ({ generationExcelRequestIds, allCases, exludeIds }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const generationExcelRequestsIds = Object.keys(generationExcelRequestIds).filter(
    (key) => generationExcelRequestIds[key],
  );
  const generationExcel = () => {
    setLoading(true);
    const data = {
      objectUuids: generationExcelRequestsIds,
      allObjects: allCases,
      excludeIds: exludeIds,
    };
    const [ordering, filtering, searchParams] = getParams({
      tableKey: 'relocatedEmplFiters',
    });

    dispatch(relocatedEmployeesExportExcel(data, filtering, { ordering: ordering, search: searchParams })).then(
      (response) => setLoading(false),
    );
  };
  return (
    <button
      className="relocated-employees__generation-excel-btn button btn-form main-btn btn-back"
      onClick={generationExcel}
      disabled={generationExcelRequestsIds.length === 0 && !allCases}
    >
      {isLoading ? <img src={SpinnerImg} alt="SpinnerContainer" /> : <>Generate Excel</>}
    </button>
  );
};

export default GenerationExcel;
