import Select from 'react-select';
import { ErrorMessage } from 'formik';
import { useMemo } from 'react';
import { customStyles } from '../../customStyles';
import classNames from 'classnames';
import '../dropdown.scss';

const CustomSelect = (props) => {
  const {
    name,
    label,
    isRequired,
    options,
    updateselectvalueroot,
    formikProps,
    formIndex,
    nameFieldArray,
    canHaveDisplayHorizontal,
    disabled,
    ismulti,
    ...rest
  } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  const onChange = (e) => {
    const options = e.target.options;

    ismulti
      ? e.target.values.length && e.target.values.find((option) => option.value === 'all')
        ? formikProps.setFieldValue(fieldName, e.target.options.slice(1))
        : formikProps.setFieldValue(fieldName, e.target.values)
      : formikProps.setFieldValue(fieldName, e.target.value);

    if (updateselectvalueroot) {
      if (name === 'cityId') {
        const selectedCity = options.find((item) => item.value === e.target.value);
        updateselectvalueroot(selectedCity.label);
      } else {
        updateselectvalueroot(ismulti ? e.target.values : e.target.value);
      }
    }
  };

  const getOptionValue = (option) =>
    option?.value || option?.text || option?.[name] || option?.internalId || option?.id || option;

  const getOptionLabel = (option) => option.text || option?.value || option.title || option.name;

  const optionValues = useMemo(
    () =>
      options.map((option) => ({
        label: getOptionLabel(option),
        value: getOptionValue(option),
      })),
    [options],
  );

  const defaultValue = ismulti
    ? formikProps?.values[name]
    : optionValues.find((option) => {
        if (formikProps?.values[nameFieldArray]?.length > 1) {
          const formikOption = getOptionValue(formikProps?.values[nameFieldArray].map((value) => value[name]));
          return option.value === formikOption[formIndex];
        }
        const formikOption = getOptionValue(
          formikProps?.values[name] ||
            formikProps?.values[nameFieldArray]?.[0] ||
            formikProps?.initialValues[nameFieldArray]?.[0],
        );
        return option.value === formikOption;
      });

  return (
    <div className="form-group form-group__column">
      <div className="form-error-container-relocation">
        <div className="custom-select__container">
          {label && (
            <label className={classNames('control-label', { required: isRequired })} htmlFor={name}>
              {label}
            </label>
          )}
          <Select
            isDisabled={disabled}
            value={defaultValue}
            options={optionValues}
            styles={customStyles}
            onChange={(e) => onChange({ target: { value: e?.value, options: optionValues, values: e } })}
            className="responsible-select-top"
            isMulti={ismulti || false}
            isClearable={true}
            {...rest}
            placeholder={'-select-'}
          />
        </div>
        <div className="error-container">
          <div className="label-error"></div>
          <ErrorMessage
            name={name}
            render={(msg) => (
              <div className="error" style={{ color: 'red' }}>
                {msg}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomSelect;
