import { types } from '../actions/types';

const initialState = {};

const requestDocumentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_DOCUMENTS_LOADED: {
      return {
        ...state,
        documents: action.payload.documents,
      };
    }
    case types.REQUEST_DOCS_COMMENT_UPDATE: {
      return {
        ...state,
        documents: {
          ...state.documents,
          documentRequest: {
            ...state.documents.documentRequest,
            comment: action.payload.employeeComment,
          },
        },
      };
    }
    case types.DELETE_RELATIVE_PROFILE: {
      const requestRelId = +action.payload.requestRelId;
      let tempRel = {};

      return {
        ...state,
        documents: {
          ...state.documents,
          relativeRequests: state.documents.relativeRequests.filter((item) => {
            if (item?.id === requestRelId) {
              tempRel = {
                id: item.relative.id,
                generalInfo: item.relative.generalInfo,
                relationType: item.relative.relationType,
              };
            }
            return item?.id !== requestRelId;
          }),
          relativesNotInRequest: [...state.documents.relativesNotInRequest, tempRel],
        },
      };
    }
    case types.DELETE_PET_PROFILE: {
      const requestPetId = +action.payload.requestPetId;
      let tempPet = {};

      return {
        ...state,
        documents: {
          ...state.documents,
          petRequests: state.documents.petRequests.filter((item) => {
            if (item?.id === requestPetId) {
              tempPet = {
                id: item.pet.id,
                name: item.pet.name,
                petType: item.pet.petType,
                weight: item.pet.weight,
                documentDetails: item.petDocuments,
              };
            }
            return item?.id !== requestPetId;
          }),
          petsNotInRequest: [...state.documents.petsNotInRequest, tempPet],
        },
      };
    }
    case types.PETS_DOC_GENERAL_INFO_UPDATE: {
      const petReqId = action.payload.petReqId;
      return {
        ...state,
        documents: {
          ...state.documents,
          petRequests: state.documents.petRequests.map((item) => {
            if (item?.pet?.id === petReqId) {
              item.pet = action.payload?.petsInfo;
            }
            return item;
          }),
        },
      };
    }
    case types.RELATED_DOC_GENERAL_INFO_UPDATE: {
      const relativeReqId = action.payload.relativeReqId;
      return {
        ...state,
        documents: {
          ...state.documents,
          relativeRequests: state.documents.relativeRequests.map((item) => {
            if (item?.relative?.id === relativeReqId) {
              item.relative.generalInfo = action.payload?.generalInfo;
            }
            return item;
          }),
        },
      };
    }
    case types.RELATED_PET_DOC_REQUEST_ADD: {
      return {
        ...state,
        documents: {
          ...state.documents,
          petRequests: [...state.documents.petRequests, action?.payload?.petRequest],
        },
      };
    }
    case types.RELATED_DOC_REQUESTS_ADD: {
      return {
        ...state,
        documents: {
          ...state.documents,
          relativeRequests: [...state.documents.relativeRequests, action?.payload?.relativeRequest],
        },
      };
    }
    case types.CHANGED_VAC_DOC_RELATIVES: {
      return {
        ...state,
        documents: {
          ...state.documents,
          relativeRequests: state.documents.relativeRequests.map((rel) => {
            if (rel.id === action.payload.relativeRequestId) {
              rel.isVacRequired = action.payload.existenceInList;
            }
            return rel;
          }),
        },
      };
    }
    case types.EXISTING_RELATIVES_ADD: {
      const relativesIds = action.payload.existingRelatives.relative_list
        .map((item) => {
          if (item.relationType === 'relative') {
            return item.relative_id;
          }
        })
        .filter((item) => item !== undefined);

      const petsIds = action.payload.existingRelatives.relative_list
        .map((item) => {
          if (item.relationType === 'pet') {
            return item.relative_id;
          }
        })
        .filter((item) => item !== undefined);

      const relativeRequests = action.payload.relativeRequests;
      const petRequests = action.payload.petRequests;

      return {
        ...state,
        documents: {
          ...state.documents,
          relativesNotInRequest: state.documents.relativesNotInRequest.filter((item) => {
            return relativesIds.includes(item.id) === false;
          }),
          petsNotInRequest: state.documents.petsNotInRequest.filter((item) => {
            return petsIds.includes(item.id) === false;
          }),
          relativeRequests: relativeRequests,
          petRequests: petRequests,
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default requestDocumentsReducer;
