import * as Yup from 'yup';
import dayjs from 'dayjs';

export const vacAppointmentInitialValues = {
  isCurrent: true,
  date: '',
  time: { startTime: null, endDate: null },
  city: '',
  addressInfo: {
    city: {
      name: '',
    },
    id: '',
  },
  isTheSameForRelated: false,
  isEmployeeNotifiedNew: { date: null, value: false },
  isDocsGatheredNew: { date: null, value: false },
  docsAreSentToAgencyNew: { date: null, value: false },
  isScheduledNew: { date: null, value: false },
  isRelatedDocsGatheredNew: { date: null, value: false },
  isAttendedNew: { date: null, value: false },
  isPassportCollectedNew: { date: null, value: false },
  notes: [],
};

export const chekboxesSchema = [
  {
    fieldName: 'isScheduledNew',
    type: 'CheckboxWithDatepicker',
    label: 'VAC appointment is scheduled',
  },
  {
    fieldName: 'isDocsGatheredNew',
    type: 'CheckboxWithDatepicker',
    label: '',
  },
  {
    fieldName: 'isEmployeeNotifiedNew',
    type: 'CheckboxWithDatepicker',
    label: 'Employee is notified',
    disabled: true,
  },
  {
    fieldName: 'isAttendedNew',
    type: 'CheckboxWithDatepicker',
    label: 'VAC appointment is attended',
  },
  {
    fieldName: 'isPassportCollectedNew',
    type: 'CheckboxWithDatepicker',
    label: 'Passport is collected',
  },
];

export const formSchema = [
  {
    fieldName: 'date',
    type: 'DatePicker',
    label: 'Date',
  },
  {
    fieldName: 'time',
    type: 'TimeRange',
    label: 'Time',
  },
  {
    fieldName: 'addressInfo.city.name',
    type: 'InputText',
    label: 'City',
    disabled: true,
  },
  {
    fieldName: 'addressInfo.id',
    type: 'Dropdown',
    label: 'Address',
  },
  {
    fieldName: 'address',
    type: 'InputText',
    label: 'Old Address',
    disabled: true,
  },
  {
    fieldName: 'isCurrent',
    type: 'Checkbox',
    label: 'VAC appointment is current',
  },
  {
    fieldName: 'isDuplicated',
    type: 'Checkbox',
    label: 'Is the VAC appointment duplicated?',
    disabled: true,
  },
];

export const visaNotesSchema = [
  {
    fieldName: 'notes',
    type: 'NoteField',
    label: 'Notes',
  },
];

export const validationSchema = Yup.object().shape({
  vacInfo: Yup.array().of(
    Yup.object().shape({
      time: Yup.object()
        .test('is-greater', 'End time should be greater than Start time', function (value) {
          const { startTime, endTime } = this.parent.time;
          const format = 'HH:mm';

          return endTime && startTime ? dayjs(endTime, format).isAfter(dayjs(startTime, format)) : true;
        })
        .test('start-time', 'Enter Start time', function (value) {
          const { startTime, endTime } = this.parent.time;

          return !(endTime && !startTime);
        }),
      notes: Yup.array().of(
        Yup.object().shape({
          text: Yup.string()
            .min(2, 'Notes must be at least 2 characters')
            .test('len', 'Notes must be at least 2 characters', (val) => (val ? val.toString().length : 0 > 0)),
        }),
      ),
    }),
  ),
});
