import OtherSpellingSmgWodLinks from '../../Common/OtherSpellingSmgWodLinks/OtherSpellingSmgWodLinks';
import Events from '../../Events/Events';
import ContactManager from '../ContactManager/ContactManager';
import { getDismissalLabel } from '../../../helpers';

import './mainInfoTop.scss';

export const getCaseTypeTitle = (caseType, isEmployeeRelocated) => {
  if (isEmployeeRelocated) {
    return 'Relocated employees';
  } else {
    switch (caseType.toLowerCase()) {
      case 'standard':
        return 'Standard case';
      case 'visa only':
        return 'Visa only';
    }
  }
};

const MainInfoTop = ({ request, genInfo, employee }) => {
  const caseType = getCaseTypeTitle(request.caseType, request.isEmployeeRelocated);
  return (
    <div className="request-top">
      <div className="EmployeeName__wrapper">
        <h3 className="EmployeeName underlined">
          {genInfo.fullName}
          {!employee?.isActive && getDismissalLabel(!employee.currentEmploymentInfo)}
        </h3>
        <div className="logo-wrapper">
          <OtherSpellingSmgWodLinks
            internalId={employee.internalId}
            otherNameSpelling={employee.generalInfo.otherNameSpelling}
            index={employee.internalId}
          />
          {request.events && request.events.length > 0 && (
            <Events instanceId={request.id} events={request.events} eventsType="request" />
          )}
        </div>
      </div>
      <div className="request-top__right">
        <h3 className="location">
          {request.isEmployeeRelocated ? 'Location: ' : 'Destination: '}
          {request.city?.country?.name
            ? request.city?.country?.name
            : request.city?.name
            ? request.city?.name
            : 'Not provided'}
        </h3>
        <div className="case-type">Type of case: {caseType}</div>
      </div>
      {request && (
        <ContactManager
          requestId={request.id}
          isManagerNotifiedAboutGatheredVac={request.isManagerNotifiedAboutGatheredVac}
          managerNotifiedAt={request.managerNotifiedAt}
          employeeNotifiedAboutFinishedRelocationAt={request.employeeNotifiedAboutFinishedRelocationAt}
          status={request.status}
        />
      )}
    </div>
  );
};

export default MainInfoTop;
