import Modal from '../../../Shared/DarkModal/DarkModal';
import Spinner from '../../../../assets/img/icons/Spinner.svg';
import './askQuestionModal.scss';

const AskQuestionModal = (props) => {
  const { isModalVisible, setModalVisible, formSubmitState } = props;

  return (
    <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="xs">
      {formSubmitState === 'pending' && (
        <div className="response-container-modal pending">
          <p className="content-modal">Your request is pending,</p>
          <p className="content-modal">please wait...</p>
          <div className="spinner">
            <img src={Spinner} alt="spinner" />
          </div>
        </div>
      )}

      {formSubmitState === 'success' && (
        <div className="response-container-modal">
          <p className="content-modal">Global Mobility Group will contact you as soon as possible</p>
        </div>
      )}
      {formSubmitState === 'error' && (
        <div className="response-container-modal">
          <p className="content-modal">Something went wrong, try again.</p>
        </div>
      )}
    </Modal>
  );
};

export default AskQuestionModal;
