import { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';

import Modal from '../../../../Shared/Modal/Modal';
import ExitImg from '../../../../../assets/img/exit.svg';
import { customStyles } from '../../../../Case/CaseList/CaseItem/caseItemCustomStyles';
import {
  getTrackingDocuments,
  addDocument,
  getLegalizationDocuments,
} from '../../../../../store/actions/legalizationDocuments';
import DocumentForm from './DocumentForm/DocumentForm';
import { removeEmpty } from '../../../../../helpers';
import { getParams } from '../../../tableProperties/getParams';

import './documentModal.scss';

const DocumentModal = ({ setModalVisible, isModalVisible, employeeId, requestId, documentTypeOptions, page }) => {
  const dispatch = useDispatch();

  const trackingDocuments = useSelector((state) => state.legalizationDocuments.trackingDocuments);

  const documentTypeOptionsExisting = documentTypeOptions.filter((option) =>
    trackingDocuments.find((item) => item.slug.includes(option.value)),
  );

  const [selectedTypeDocument, setSelectedTypeDocument] = useState();
  const [isSubmitting, setFormSubmitting] = useState(false);

  useEffect(() => {
    dispatch(getTrackingDocuments(employeeId));
  }, []);

  const document = useMemo(() => {
    return trackingDocuments.find((item) => item.slug.includes(selectedTypeDocument?.value));
  }, [selectedTypeDocument?.value]);

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    const valuesWithoutEmpty = removeEmpty(values);

    setFormSubmitting(true);

    dispatch(addDocument(employeeId, document.id, valuesWithoutEmpty, requestId)).then((response) => {
      setFormSubmitting(false);
      setSubmitting(false);
      const [ordering, filtering, searchParams, toggleParams] = getParams({
        tableKey: 'PolandDashboard',
      });
      dispatch(
        getLegalizationDocuments(page, filtering, {
          ordering: ordering,
          search: searchParams,
          toggle: toggleParams,
        }),
      );
      setModalVisible(false);
    });
  };

  return (
    <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l">
      <div className="attach-document__content">
        <div className="attach-document__title">
          <h4 className="underlined panel-top-row">Attach document</h4>
          <img src={ExitImg} alt="close" onClick={() => setModalVisible(false)} />
        </div>
        <div className="attach-document__type-document">
          <p className="attach-document__label">Select document</p>
          <Select
            className="select__type-document"
            value={selectedTypeDocument}
            options={documentTypeOptionsExisting}
            styles={customStyles}
            placeholder="-select-"
            name="typeDocument"
            onChange={(e) => {
              setSelectedTypeDocument(e);
            }}
          />
        </div>

        {document && (
          <div className="legalization-document">
            <DocumentForm
              setModalVisible={setModalVisible}
              document={document}
              onSubmit={onSubmit}
              isSubmitting={isSubmitting}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DocumentModal;
