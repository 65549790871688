import classNames from 'classnames';

import { Field, ErrorMessage } from 'formik';
import './checkbox.scss';

const Checkbox = (props) => {
  const { name, label, updateselectvalueroot, formikProps, formIndex, nameFieldArray, disabled, isRequired } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const handleChange = (e) => {
    const value = e.target.checked ? true : false;
    formikProps.setFieldValue(fieldName, value);
    if (updateselectvalueroot) {
      updateselectvalueroot(value, formIndex, formikProps);
    }
  };
  return (
    <div className="form-group radio-line sigle-checkbox">
      <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
        {label}
      </label>
      <div className="custom-control custom-checkbox option-inline">
        <Field
          name={fieldName}
          className="custom-control-input"
          type="checkbox"
          id={fieldName}
          disabled={disabled}
          {...(updateselectvalueroot ? { onChange: handleChange } : {})}
        />
        <label className="custom-checkbox__label" htmlFor={fieldName}>
          <span></span>
        </label>
      </div>
      <ErrorMessage
        name={fieldName}
        className="field-error"
        render={(msg) => <div style={{ color: 'red' }}>{msg}</div>}
      />
    </div>
  );
};

export default Checkbox;
