import { sidebarNavigation } from './sidebarNavigation';
import Menu from '../../../Common/Menu/Menu';
import './menu.scss';

const SiderMenu = ({ relatedInfo }) => {
  const relationType = relatedInfo?.relative.relationType;

  const sidebarNavigationOptions = sidebarNavigation.reduce((accumulator, currentItem) => {
    if (
      (currentItem.key === 'contactInfo' || currentItem.key === 'employmentInformation') &&
      relationType !== 'spouse'
    ) {
      return accumulator;
    }
    if (currentItem.key === 'educationInformation' && relationType !== 'child') {
      return accumulator;
    }

    if (currentItem.key === 'archivedDocuments' && relatedInfo?.archivedDocuments?.length === 0) {
      return accumulator;
    }
    if (currentItem.key === 'vacAppointment' && !relatedInfo?.isVacRequired) {
      return accumulator;
    }
    if (currentItem.key === 'foreignPoliceAppointment' && !relatedInfo?.isForeignPoliceRequired) {
      return accumulator;
    }
    if (currentItem.key === 'transportationDetails' && !relatedInfo?.isTdRequired) {
      return accumulator;
    }
    accumulator.push(currentItem);
    return accumulator;
  }, []);

  return (
    <div className="request-details__menu">
      <Menu sidebarNavigation={sidebarNavigationOptions} />
    </div>
  );
};

export default SiderMenu;
