import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCompensation } from '../../../../store/actions/request';
import CompensationCalculationForm from './CompensationCalculationForm/CompensationCalculationForm';
import Modal from '../../../Shared/Modal/Modal';

const CompensationCalculationModal = ({ isModalVisible, setModalVisible, requestId, linkedRequests }) => {
  const dispatch = useDispatch();

  const compensationInfo = useSelector((state) => state.compensationCalculator[requestId]);

  useEffect(() => {
    dispatch(getCompensation(requestId));
  }, []);

  return (
    <>
      {compensationInfo && (
        <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l">
          <div className="calculate-relocation-compensation">
            <h3 className="underlined panel-top-row">Calculate relocation compensation</h3>
            <CompensationCalculationForm
              compensationInfo={compensationInfo}
              requestId={requestId}
              setModalVisible={setModalVisible}
              linkedRequests={linkedRequests}
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default CompensationCalculationModal;
