import { useState } from 'react';
import classNames from 'classnames';
import { ErrorMessage } from 'formik';
import { Editor } from '@tinymce/tinymce-react';

import Spinner from '../../../Common/SpinnerContainer/Spinner/Spinner';
import { toolbarOptions } from './toolbarOptions';

import './tinyMCE.scss';

const TinyMCEField = (props) => {
  const { name, label, formIndex, nameFieldArray, formikProps, isRequired } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const defaultValue = formikProps?.values[name];

  const handleChange = (newValue) => {
    formikProps.setFieldValue(fieldName, newValue);
  };

  const [isLoading, setLoading] = useState(true);

  return (
    <div className="form-group form-group__column html-field">
      <div className="control-label">
        {label && (
          <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
            {label}
          </label>
        )}
      </div>
      <div className={'html-field__wrapper'}>
        {isLoading && <Spinner />}
        <div className={'html-editor'}>
          <Editor
            value={defaultValue}
            init={{
              height: 150,
              min_height: 150,
              // width: 500,
              selector: 'textarea', // change this value according to your HTML
              menubar: false,
              statusbar: false,
              content_css: '/skins/content/GoGlobal-style/',
              skin_url: '/skins/ui/GoGlobal-style/',
              plugins: ['link', 'lists', 'autoresize'],
              ...toolbarOptions,
            }}
            onInit={() => {
              setLoading(false);
            }}
            onEditorChange={handleChange}
          />
          <ErrorMessage name={fieldName} render={(msg) => <span className={'error-msg'}>{msg}</span>} />
        </div>
      </div>
    </div>
  );
};

export default TinyMCEField;
