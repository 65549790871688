import { Input } from 'antd';
import CheckboxMenu from './CheckboxMenu/CheckboxMenu';

const ColumnsFilter = ({ tableKey, columns, visibleColumns, setVisibleColumns, selectedCountry }) => {
  const onCheckboxChange = (selection) => {
    const previousSessionStorageValue = JSON.parse(sessionStorage.getItem(tableKey));

    sessionStorage.setItem(
      tableKey,
      JSON.stringify({
        ...previousSessionStorageValue,
        columnsFilter: {
          ...previousSessionStorageValue?.columnsFilter,
          [selectedCountry]: selection,
        },
      }),
    );

    setVisibleColumns([...selection]);
  };

  const resetVisibleColumns = () => {
    const allColumns = columns.map((item) => item.key);

    const previousSessionStorageValue = JSON.parse(sessionStorage.getItem(tableKey));
    sessionStorage.setItem(
      tableKey,
      JSON.stringify({
        ...previousSessionStorageValue,
        columnsFilter: {
          ...previousSessionStorageValue?.columnsFilter,
          [selectedCountry]: allColumns,
        },
      }),
    );

    setVisibleColumns(allColumns);
  };

  const options = columns.map((item) => ({ label: item.label, value: item.key }));

  return (
    <div className="columns-filter">
      <Input.Group compact>
        <CheckboxMenu
          resetVisibleColumns={resetVisibleColumns}
          options={options}
          visibleColumns={visibleColumns}
          onChange={onCheckboxChange}
        />
      </Input.Group>
    </div>
  );
};

export default ColumnsFilter;
