import { sidebarNavigation } from './sidebarNavigation';
import Menu from '../../Common/Menu/Menu';
import './menu.scss';

const SiderMenu = ({ employee, isCandidate, isHrManager }) => {
  const sidebarNavigationOptions = sidebarNavigation.reduce((accumulator, currentItem) => {
    if (currentItem.key === 'employeeDocuments') {
      currentItem.label = isCandidate ? 'Candidate documents' : 'Employee documents';
    }
    if (currentItem.key === 'archivedDocuments' && employee?.archivedDocuments?.length === 0) {
      return accumulator;
    }
    if (currentItem.key === 'employeeCases' && (isHrManager || isCandidate)) {
      return accumulator;
    }
    if (currentItem.key === 'employeeDocuments' && isCandidate) {
      const navigation = { ...currentItem };

      navigation.children = currentItem.children.filter((item) => item.key !== 'requestDocuments');
      accumulator.push({ ...navigation });
      return accumulator;
    }

    accumulator.push({ ...currentItem });
    return accumulator;
  }, []);

  return (
    <div className="request-details__menu">
      <Menu sidebarNavigation={sidebarNavigationOptions} />
    </div>
  );
};

export default SiderMenu;
