import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getLegalizationDocuments } from '../../store/actions/legalizationDocuments';
import AddToLegalizationTableModal from './AddToLegalizationTableModal/AddToLegalizationTableModal';
import CustomTable from '../Shared/Table/CustomTable';
import {
  filterMapping,
  dateRangeFilterMapping,
  sortMapping,
  getColumnsKeys,
  multipleFilterMapping,
} from './tableProperties/tableProperties';
import { useGetColumnsRows } from './tableProperties/useGetColumnsRows';
import CandidateEmployeeToggleFilter from './CandidateEmployeeToggleFilter/CandidateEmployeeToggleFilter';

import ClearFilters from '../Shared/Table/ClearFilters/ClearFilters';
import ColumnsFilter from '../Shared/Table/ColumnsFilter/ColumnsFilter';
import QuickNavUp from '../Shared/QuickButtonsBlock/QuickNavUp/QuickNavUp';
import SearchName from '../Shared/Table/SearchName/SearchName';
import CountryFilter from './CountryFilter/CountryFilter';
import GenerationExcel from './GenerationExcel/GenerationExcel';

import './legalizationDocuments.scss';

const SendBulkEmail = ({ generationExcelEmployeesIds, allCases }) => {
  const generationExcelEmployeeIds = Object.keys(generationExcelEmployeesIds).filter(
    (key) => generationExcelEmployeesIds[key],
  );

  const templateId = 36;

  return (
    <Link
      to={{
        pathname: `/contact-employees/${templateId}`,
      }}
    >
      <button
        className="legalization__generation-excel-btn button btn-form main-btn btn-back"
        disabled={generationExcelEmployeeIds.length === 0 && !allCases}
      >
        <>Send Email</>
      </button>
    </Link>
  );
};

const LegalizationDocuments = () => {
  const paramsFromLocalStorage = JSON.parse(sessionStorage.getItem(`PolandDashboard`));

  const { legalizationDocuments, countByGroups, count, page, generationExcelEmployeeIds, allCases, excludeIds } =
    useSelector((state) => state.legalizationDocuments);

  const [selectedCountry, setSelectedCountry] = useState(paramsFromLocalStorage?.country || { value: 0, label: 'All' });

  const columnsKeys = useMemo(() => {
    return getColumnsKeys(selectedCountry);
  }, [selectedCountry]);

  const [searchName, setSearchName] = useState(paramsFromLocalStorage?.search || '');
  const [candidateEmployeeToggleParam, setCandidateEmployeeToggleParam] = useState(
    paramsFromLocalStorage?.toggle || null,
  );
  const [isClearFilterParams, setClearFilterParams] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState(columnsKeys);

  useEffect(() => {
    const selectedCountryId = selectedCountry?.value;
    const columnsFilterFromStorage = paramsFromLocalStorage?.columnsFilter;

    if (columnsFilterFromStorage && columnsFilterFromStorage.hasOwnProperty(selectedCountryId)) {
      setVisibleColumns(columnsFilterFromStorage[selectedCountryId] || columnsKeys);
      return;
    }
    setVisibleColumns(columnsKeys);
  }, [columnsKeys, selectedCountry]);

  const clearAllInnerFilters = () => {
    setClearFilterParams(false);
    setSearchName('');
    setSelectedCountry({ value: 0, label: 'All' });
    setCandidateEmployeeToggleParam(null);
  };

  const [columns, rows] = useGetColumnsRows({
    legalizationDocuments,
    selectedCountry: selectedCountry.label,
    page,
    generationExcelEmployeeIds,
    allCases,
    excludeIds,
  });

  const filteredColumns = columns.filter(
    (column) => (columnsKeys.includes(column.key) && visibleColumns.includes(column.key)) || column.key === 'fullName',
  );

  return (
    <section className="legalization-documents">
      <div className="legalization-documents__header">
        <h3 className="underlined panel-top-row">Documents</h3>
        <AddToLegalizationTableModal page={page} countryId={selectedCountry.value} />
      </div>

      {page && (
        <div className="additional-filters__container">
          <div className="additional-filters__container-first-group">
            <CandidateEmployeeToggleFilter
              candidateEmployeeToggleParam={candidateEmployeeToggleParam}
              setCandidateEmployeeToggleParam={setCandidateEmployeeToggleParam}
              countByGroups={countByGroups}
            />

            <CountryFilter
              tableKey={'PolandDashboard'}
              setSelectedCountry={setSelectedCountry}
              selectedCountry={selectedCountry}
              paramsFromLocalStorage={paramsFromLocalStorage}
            />

            <ColumnsFilter
              tableKey={'PolandDashboard'}
              columns={columns.filter((column) => columnsKeys.includes(column.key) && column.key !== 'fullName')}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              selectedCountry={selectedCountry?.value}
            />
            <GenerationExcel />
            <SendBulkEmail
              generationExcelEmployeesIds={generationExcelEmployeeIds}
              allCases={allCases}
              excludeIds={excludeIds}
            />
            <ClearFilters setClearFilterParams={setClearFilterParams} />
          </div>
          <SearchName tableKey={'PolandDashboard'} searchName={searchName} setSearchName={setSearchName} />
        </div>
      )}

      <CustomTable
        tableKey={'PolandDashboard'}
        data={rows}
        columns={filteredColumns}
        count={count}
        sortMapping={sortMapping}
        filterMapping={filterMapping || []}
        dateRangeFilterMapping={dateRangeFilterMapping || []}
        multipleFilterMapping={multipleFilterMapping || {}}
        searchParams={searchName}
        toggleParams={candidateEmployeeToggleParam}
        additionalFiltering={selectedCountry.value ? { country: selectedCountry.label } : {}}
        getTableDataCallback={getLegalizationDocuments}
        paramsFromLocalStorage={paramsFromLocalStorage}
        isClearFilterParams={isClearFilterParams}
        clearAllInnerFilters={clearAllInnerFilters}
        mobileView={true}
        pageSize={50}
      />

      <QuickNavUp />
    </section>
  );
};

export default LegalizationDocuments;
