export const columns = [
  {
    key: 'date',
    title: 'Date',
    label: 'Date',
    dataIndex: 'date',
    width: '10%',
  },
  {
    key: 'time',
    title: 'Time',
    label: 'Time',
    dataIndex: 'time',
    width: '20%',
  },
  {
    key: 'employee',
    title: 'Employee',
    label: 'Employee',
    dataIndex: 'employee',
    width: '14%',
  },
  {
    key: 'changes',
    title: 'Changes made',
    label: 'Changes made',
    dataIndex: 'changes',
    width: '56%',
  },
];
