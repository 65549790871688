import Events from '../../Events/Events';
import OtherSpellingSmgWodLinks from '../../Common/OtherSpellingSmgWodLinks/OtherSpellingSmgWodLinks';
import { getDismissalLabel } from '../../../helpers';

const EmployeeNameEvents = ({ genInfo, employee, isCandidate, id }) => {
  return (
    <div className="request-top">
      <div className="EmployeeName__wrapper">
        <h3 className="EmployeeName underlined">
          {genInfo.fullName}
          {!employee.isActive && employee.internalId && getDismissalLabel(!employee?.currentEmploymentInfo)}
          {employee.isActive && !employee.internalId && ' (Candidate)'}
        </h3>
        <div className="logo-wrapper">
          {!isCandidate && employee?.internalId && (
            <OtherSpellingSmgWodLinks
              internalId={employee.internalId}
              otherNameSpelling={employee.generalInfo.otherNameSpelling}
              index={id}
            />
          )}
          {employee.events?.length > 0 && (
            <Events
              instanceId={id}
              events={employee.events}
              eventsType={isCandidate ? 'candidateProfile' : 'employeeProfile'}
            />
          )}
        </div>
      </div>
      <div className="case-type">Type of case: Profile</div>
    </div>
  );
};

export default EmployeeNameEvents;
