import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getEmployeeFeedbackForms } from '../../store/actions/employeeFeedback';
import QuickButtonsBlock from '../Shared/QuickButtonsBlock/QuickButtonsBlock';
import SpinnerContainer from '../Common/SpinnerContainer/SpinnerContainer';
import EmployeeFeedbackForm from './EmployeeFeedbackForm/EmployeeFeedBackForm';
import './employeeFeedback.scss';

const EmployeeFeedback = ({ match }) => {
  const dispatch = useDispatch();

  const requestId = match.params.id;

  const [isLoading, setLoading] = useState(true);
  const [feedbackForms, setFeedbackForms] = useState([]);

  useEffect(() => {
    getEmployeeFeedbackForms(requestId)
      .then((forms) => {
        setFeedbackForms(forms);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [dispatch, requestId]);

  return (
    <section className="employee-feedback">
      {!isLoading && feedbackForms.length > 0 && (
        <>
          <div className="questionnaire-answer">
            <EmployeeFeedbackForm feedbackForms={feedbackForms} requestId={requestId} />
          </div>
          <QuickButtonsBlock />
        </>
      )}

      {!isLoading && feedbackForms.length === 0 && <p>No available feedback forms</p>}
      {isLoading && <SpinnerContainer />}
    </section>
  );
};

export default EmployeeFeedback;
