import { useState } from 'react';
import { ErrorMessage, useField } from 'formik';
import classNames from 'classnames';
import { Tooltip, DatePicker } from 'antd';
import dayjs from 'dayjs';

import CustomReminder from '../CustomReminder/CustomReminder';
import { defaultDateFormat, selectedDateFormat } from '../../../../constants';

import { ReactComponent as CalendarNotifyIcon } from '../../../../assets/img/daily-schedule.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/img/icons/calendar.svg';
import { ReactComponent as AdditionalInfoIcon } from '../../../../assets/img/icons/additional-info.svg';

import './dateField.scss';
import { useSelector } from 'react-redux';

const DateField = (props) => {
  const {
    name,
    label,
    updateselectvalueroot,
    oncalendarcloseupdate,
    placeholder,
    notify,
    notify_days,
    formIndex,
    nameFieldArray,
    formikProps,
    disabled,
    isRequired,
    allowCustomReminders,
  } = props;
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const [field, , { setValue }] = useField(fieldName);

  const customReminderFieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.customReminders` : 'customReminders';
  const [, , { setValue: setCustomReminderValue }] = useField(customReminderFieldName);

  const initDate = field.value ? dayjs(field.value, defaultDateFormat) : null;

  const [isViewCustomReminder, setIsViewCustomReminder] = useState(false);

  const handleChange = (date) => {
    const value = date ? dayjs(date).format(defaultDateFormat) : null;
    formikProps.setFieldValue(fieldName, value);
    if (updateselectvalueroot) {
      updateselectvalueroot(value, formIndex, formikProps);
    }
  };

  const handleCustomReminderButtonClick = (fieldName) => {
    setIsViewCustomReminder(!isViewCustomReminder);
    const values = formikProps.values[nameFieldArray][formIndex]?.customReminders;
    setCustomReminderValue({ ...values, [fieldName]: null });
  };

  const changeValue = (date) => {
    setValue(dayjs(date).format(defaultDateFormat));
  };

  const onCalendarClose = (open) => {
    if (!open && oncalendarcloseupdate) {
      oncalendarcloseupdate();
    }
  };

  return (
    <div
      className={classNames(
        'form-group date-field',
        !disabled && !notify && 'date-field-active',
        notify && 'date-field-notify',
      )}
    >
      {label && notify && (
        <div className={'control-label'} style={{ display: 'flex' }}>
          <label className={isRequired ? 'required' : undefined} htmlFor={fieldName} style={{ marginBottom: 0 }}>
            {label}
          </label>
          {notify && allowCustomReminders && (
            <Tooltip placement={'top'} title={`Standard notification: ${notify_days} days before the set date`}>
              <AdditionalInfoIcon className={'additional-info'} data-for="additional-info-column" />
            </Tooltip>
          )}
        </div>
      )}
      {label && !notify && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}
      {notify ? (
        <div className={'date-picker-wrapper'}>
          <div className={'date-picker'}>
            <DatePicker
              value={initDate}
              format={displayDateFormat}
              onChange={(date) => (date ? changeValue(date) : setValue(null))}
              className={'form-control ant-datepicker-wrapper'}
              placeholder={disabled ? '' : displayDateFormat}
              name={fieldName}
              id={fieldName}
              disabled={disabled}
              onOpenChange={(open) => onCalendarClose(open)}
              {...(updateselectvalueroot ? { onChange: handleChange } : {})}
              suffixIcon={disabled ? null : notify ? <CalendarNotifyIcon /> : <CalendarIcon />}
            />
            {notify && allowCustomReminders && (
              <Tooltip
                placement={'top'}
                title={isViewCustomReminder ? 'Delete custom reminder' : 'Add custom reminder'}
              >
                <div className={'custom-reminder-button'} onClick={() => handleCustomReminderButtonClick(name)}>
                  {isViewCustomReminder ? '-' : '+'}
                </div>
              </Tooltip>
            )}
          </div>
          {notify && allowCustomReminders && (
            <CustomReminder
              name={name}
              customReminderFieldName={customReminderFieldName}
              setCustomReminderValue={setCustomReminderValue}
              setIsViewCustomReminder={setIsViewCustomReminder}
              isViewCustomReminder={isViewCustomReminder}
            />
          )}
        </div>
      ) : (
        <DatePicker
          value={initDate}
          format={displayDateFormat}
          onChange={(date) => (date ? changeValue(date) : setValue(null))}
          className={'form-control ant-datepicker-wrapper'}
          placeholder={disabled ? '' : displayDateFormat}
          name={fieldName}
          id={fieldName}
          disabled={disabled}
          onOpenChange={(open) => onCalendarClose(open)}
          {...(updateselectvalueroot ? { onChange: handleChange } : {})}
          suffixIcon={disabled ? null : notify ? <CalendarNotifyIcon /> : <CalendarIcon />}
        />
      )}
      <ErrorMessage
        name={fieldName}
        render={(msg) => (
          <span className="error" style={{ color: 'red' }}>
            {msg}
          </span>
        )}
      />
    </div>
  );
};

export default DateField;
