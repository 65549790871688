import classNames from 'classnames';

import useScrollPosition from '../../Hooks/useScrollPosition';

import './quickNavUp.scss';

const QuickNavUp = () => {
  const [scrollPosition, windowHeight] = useScrollPosition();

  const isQuickNavDisplay = scrollPosition - windowHeight + 75 > 0;
  return (
    <button
      className={classNames('btn-quick-nav', isQuickNavDisplay ? 'btn-quick-nav-display' : 'btn-quick-nav-hidden')}
      onClick={() =>
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    />
  );
};

export default QuickNavUp;
