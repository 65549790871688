import React from 'react';
import TableComponent from '../../Common/Table/TableComponent';
import { CellType } from '../../Shared/Constants/Constants';
import { useSelector } from 'react-redux';
import { defaultDateFormat } from '../../../constants';
import dayjs from 'dayjs';

const columns = [
  {
    key: 'nameEn',
    title: 'Name (passport)',
    type: CellType.Link,
    getLink: (cell) => `${cell.id}`,
    getValue: (cell) => cell.nameEn,
  },
  {
    key: 'nameRu',
    title: 'Name (Russian)',
  },
  {
    key: 'relation',
    title: 'Relation',
  },
  {
    key: 'birth',
    title: 'Birth date',
  },
];

const RelatedProfiles = ({ id, rel_req_id, petInfo, relInfo, genInfo, linkedRequests, type }) => {
  const idToRemove = rel_req_id;

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  let filteredPets = petInfo;
  let filteredPeople = relInfo;
  if (type === 'pet') {
    filteredPets = petInfo.filter((item) => item.id !== Number(idToRemove));
  } else {
    filteredPeople = relInfo?.filter((item) => item.id !== Number(idToRemove));
  }

  const employeesRowData = linkedRequests?.map(({ employee, id }) => ({
    nameEn: {
      nameEn: employee?.generalInfo.fullName,
      id: `/request-details/${id}`,
      isLinked: true,
    },
    nameRu: employee.generalInfo?.fullNameRu,
    relation: 'employee',
    birth: employee?.generalInfo.birthDate && dayjs(employee?.generalInfo.birthDate).format(displayDateFormat),
  }));

  const generalRowData = [genInfo.employee.generalInfo].map(({ fullName, fullNameRu, birthDate }) => ({
    nameEn: {
      nameEn: fullName,
      id: `/request-details/${id}`,
    },
    nameRu: fullNameRu,
    relation: 'spouse',
    birth: birthDate && dayjs(birthDate).format(displayDateFormat),
  }));

  const relativeRowData = filteredPeople.map(({ id, relative }) => ({
    nameEn: {
      nameEn: relative?.generalInfo.fullName,
      id: `/request-details/${genInfo.id}/relative-requests/${id}`,
    },
    nameRu: relative?.generalInfo.fullNameRu,
    relation: relative?.relationType,
    birth: relative?.generalInfo.birthDate && dayjs(relative?.generalInfo.birthDate).format(displayDateFormat),
  }));

  const petRowData = filteredPets.map(({ id, pet }) => ({
    nameEn: {
      nameEn: pet?.name,
      id: `/request-details/${genInfo.id}/pet-requests/${id}`,
    },
    nameRu: '',
    relation: pet?.petType,
    birth: '',
  }));

  const rowsData = generalRowData.concat(relativeRowData, petRowData, employeesRowData);

  return (
    <div className="RelatedProfiles">
      <TableComponent columns={columns} rows={rowsData} />
    </div>
  );
};

export default RelatedProfiles;
