import EmployeeDocument from './EmployeeDocument/EmployeeDocument';

const EmployeeDocuments = ({ documents, employeeId }) => {
  return (
    <div>
      {documents &&
        documents.map((document) => {
          return <EmployeeDocument employeeId={employeeId} key={document.id} document={document} />;
        })}
      {!documents?.length && <p> No available documents</p>}
    </div>
  );
};

export default EmployeeDocuments;
