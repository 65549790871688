import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import dayjs from 'dayjs';

import { getFormElement } from '../../../../../Shared/Form/FormElements';
import { editRequestedDocuments } from '../../../../../../store/actions/request';

const Deadline = ({ requestedDocuments, requestId, isCaseDisabled, employeeId }) => {
  const dispatch = useDispatch();

  const [deadline, setDeadline] = useState(requestedDocuments.deadline);
  const [updateTimeout, setUpdateTimeout] = useState(setTimeout(0));
  const [previousDeadline, setPreviosDeadline] = useState(deadline);

  const onSetPlannedDate = (value) => {
    setDeadline(value ? value : null);
  };

  const onCalendarClose = () => {
    if (previousDeadline !== deadline) {
      setPreviosDeadline(deadline);
      if (updateTimeout) clearTimeout(updateTimeout);
      setUpdateTimeout(
        setTimeout(() => {
          dispatch(
            editRequestedDocuments(
              requestedDocuments.id,
              requestId,
              { deadline: deadline },
              { requestedDocuments: { ...requestedDocuments, deadline: deadline } },
              employeeId,
            ),
          );
        }, 1500),
      );
    }
  };

  const formSchema = [
    {
      fieldName: 'draftDocumentsNotifiedAt',
      type: 'TextareaAsInput',
      label: 'Email sent',
      disabled: true,
    },
    {
      fieldName: 'documentsDeadline',
      type: 'DatePicker',
      label: 'Deadline',
      oncalendarcloseupdate: onCalendarClose,
      updateselectvalueroot: onSetPlannedDate,
    },
  ];

  const initialValues = {
    draftDocumentsNotifiedAt: dayjs(requestedDocuments?.docsRequestedAt).format('L [AT] LT (Z UTC)'),
    documentsDeadline: requestedDocuments.deadline,
  };

  return (
    <Formik enableReinitialize initialValues={initialValues}>
      {(formikProps) => (
        <Form>
          {formSchema.map((field) => (
            <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
          ))}
        </Form>
      )}
    </Formik>
  );
};

export default Deadline;
