import { useState } from 'react';

import SelectDocumentsModal from './SelectDocumentsModal/SelectDocumentsModal.js';
import RequestDocumentsFormButton from './RequestDocumentsFormButton/RequestDocumentsFormButton';

const RequestEmployeeDocuments = ({
  requestId,
  isDocsEditable,
  requestedDocuments,
  documentsToDisplay,
  documentsStatus,
  country,
  employeeForms,
  isCaseDisabled,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <RequestDocumentsFormButton
        isDocsEditable={isDocsEditable}
        requestedDocuments={requestedDocuments}
        documentsStatus={documentsStatus}
        setModalVisible={setModalVisible}
        requestId={requestId}
        isCaseDisabled={isCaseDisabled}
      />
      {isModalVisible && (
        <SelectDocumentsModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          requestId={requestId}
          documentsToDisplay={documentsToDisplay}
          employeeForms={employeeForms}
          country={country}
        />
      )}
    </>
  );
};

export default RequestEmployeeDocuments;
