import { types } from '../actions/types';

const initialState = {
  requestList: [],
  statistics: null,
  next: null,
  previous: null,
};

const requestListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_LIST_LOADED:
      return {
        ...state,
        requestList: action.payload.requestList,
        statistics: action.payload.statistics,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.REQUEST_LIST_ERROR:
      return {
        ...state,
        requestList: action.payload.requestList,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.REQUEST_LIST_UPDATED:
      return {
        ...state,
        requestList: [...state.requestList, ...action.payload.requestList],
        statistics: action.payload.statistics,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.REQUEST_LIST_UPDATE:
      return {
        ...state,
        requestList: state.requestList.map((item) => {
          return item.id === action.payload.requestId ? { ...item, ...action.payload.requestList } : item;
        }),
      };
    case types.LOG_OUT:
      return {
        requestList: [],
        next: null,
        previous: null,
      };
    default:
      return {
        ...state,
      };
  }
};

export default requestListReducer;
