import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Table } from 'antd';

import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import Spinner from '../../Common/SpinnerContainer/Spinner/Spinner';
import { parseFiltering, parseOrdering, transformObjectToArray } from './helpers';

import './customTable.scss';

const CustomTable = ({
  tableKey,
  columns: baseColumns,
  data,
  count,
  sortMapping,
  dateRangeFilterMapping,
  multipleFilterMapping,
  filterMapping,
  searchParams,
  toggleParams,
  additionalFiltering,
  getTableDataCallback,
  paramsFromLocalStorage,
  isClearFilterParams,
  clearAllInnerFilters,
  pageSize,
  mobileView,
}) => {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState(
    paramsFromLocalStorage?.ordering ? transformObjectToArray(paramsFromLocalStorage?.ordering) : [],
  );
  const [filteredInfo, setFilteredInfo] = useState(paramsFromLocalStorage?.filtering || {});

  const columns = baseColumns.map((column) => {
    if (column.hasOwnProperty('sortOrder')) {
      const sort = sortedInfo?.find((item) => item.columnKey === column.key);
      column.sortOrder = sort && sort.order ? sort.order : null;
    }
    if (column.hasOwnProperty('filteredValue')) {
      column.filteredValue = filteredInfo[column.key] || null;
    }
    return column;
  });

  const [isLoading, setIsLoading] = useState({ isTableLoading: true, isDataLoading: true });

  useEffect(() => {
    setIsLoading((prev) => ({ ...prev, isDataLoading: true }));
    const filtering = parseFiltering({
      rowFiltering: filteredInfo,
      dateRangeFilterMapping,
      multipleFilterMapping,
      filterMapping,
    });

    const ordering = parseOrdering(sortedInfo, sortMapping);

    dispatch(
      getTableDataCallback(page, filtering, {
        ...additionalFiltering,
        ordering: ordering,
        search: searchParams,
        toggle: toggleParams,
      }),
    )
      .catch(() => setPage(1))
      .finally(() => {
        setIsLoading({ isTableLoading: false, isDataLoading: false });
      });
  }, [page, sortedInfo, filteredInfo, searchParams, toggleParams, JSON.stringify(additionalFiltering)]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [isLoading]);

  useEffect(() => {
    //clear all filters and sort
    if (isClearFilterParams) {
      sessionStorage.removeItem(tableKey);
      setSortedInfo([]);
      setFilteredInfo({});

      clearAllInnerFilters();
    }
  }, [isClearFilterParams]);

  const handleTableChange = (pagination, filters, sorter) => {
    sessionStorage.setItem(
      tableKey,
      JSON.stringify({
        ...paramsFromLocalStorage,
        ordering: sorter,
        filtering: filters,
      }),
    );
    setSortedInfo(sorter ? transformObjectToArray(sorter) : []);
    setFilteredInfo(filters);
  };

  return (
    <>
      {!isLoading.isTableLoading && (
        <Table
          className={classNames(mobileView ? 'mobile-view' : '')}
          columns={columns}
          dataSource={data}
          onChange={handleTableChange}
          pagination={{
            total: count,
            pageSize: pageSize,
            showSizeChanger: false,
            hideOnSinglePage: true,
            onChange: (page, pageSize) => {
              setPage(page);
            },
          }}
          loading={{ indicator: <Spinner />, size: 'large', spinning: isLoading.isDataLoading }}
        />
      )}
      {isLoading.isTableLoading && <SpinnerContainer />}
    </>
  );
};

export default CustomTable;
