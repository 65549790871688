import axios from '../../axios';
import actionCreators from './actionCreators';
import { uploadFileToS3 } from './uploadToS3';

export const getPresignedUrl = async (employeeId, gfId, formId) => {
  try {
    const response = await axios.get(
      `/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/get-upload-credentials/`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAccommodationPresignedUrl = async (adId) => {
  try {
    const response = await axios.get(
      `/api/v1/relocation-requests/accommodation-details/${adId}/get-upload-credentials/`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getTicketPresignedUrl = async (tdId) => {
  try {
    const response = await axios.get(
      `/api/v1/relocation-requests/transportation-details/${tdId}/get-upload-credentials/`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addAttachment = (
  empId,
  gfId,
  formId,
  data,
  type,
  relatedId,
  relatedReqId,
  petId,
  petReqId,
  excelFlag,
  isCase,
) => {
  return (dispatch) => {
    return axios
      .post(
        isCase
          ? `/api/v1/employees/${empId}/gf/${gfId}/form/${formId}/attachments/add-attachment/`
          : `/api/v1/employees/${empId}/gf/employee-edit/${gfId}/form/${formId}/attachments/add-attachment/`,
        data,
      )
      .then((response) => {
        if (type === 'employee' && !excelFlag) {
          dispatch(actionCreators.documentAttachmentAdd(response.data, gfId, formId, empId));
        }
        if (type === 'employee' && excelFlag) {
          dispatch(actionCreators.excelFormAttachmentAdd(response.data, gfId, formId, empId));
        }
        if (type === 'related' && !excelFlag) {
          dispatch(actionCreators.relatedDocumentAttachmentAdd(response.data, gfId, formId, relatedId, relatedReqId));
        }
        if (type === 'related' && excelFlag) {
          dispatch(actionCreators.relativeFormAttachmentAdd(response.data, gfId, formId, relatedReqId));
        }
        if (type === 'pet') {
          dispatch(actionCreators.petDocumentAttachmentAdd(response.data, gfId, formId, petId, petReqId));
        }
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const attachmentUpload = (
  files,
  employeeUuid,
  gfId,
  formId,
  type,
  relatedId,
  relatedReqId,
  petId,
  petReqId,
  excelFlag,
  isCase,
) => {
  return (dispatch) => {
    const handleUpload = async (employeeUuid, gfId, formId, file) => {
      const presignedUrl = await getPresignedUrl(employeeUuid, gfId, formId);
      const filename = '${filename}';

      try {
        await uploadFileToS3(presignedUrl, file);
        const data = {
          fileName: file.name,
          fileKey: presignedUrl.fields.key.replace(`${filename}`, file.name),
        };

        return dispatch(
          addAttachment(
            employeeUuid,
            gfId,
            formId,
            data,
            type,
            relatedId,
            relatedReqId,
            petId,
            petReqId,
            excelFlag,
            isCase,
          ),
        );
      } catch (error) {
        throw error;
      }
    };

    return files.map((file, i) => {
      return handleUpload(employeeUuid, gfId, formId, file);
    });
  };
};

export const transportationTicketsUpload = (files, tdId, type, requestId, relatedReqId, petReqId) => {
  return (dispatch) => {
    const handleUpload = async (tdId, file) => {
      const presignedUrl = await getTicketPresignedUrl(tdId);
      const filename = '${filename}';

      try {
        await uploadFileToS3(presignedUrl, file);
        const data = {
          fileName: file.name,
          fileKey: presignedUrl.fields.key.replace(`${filename}`, file.name),
        };
        dispatch(addTicketAttachment(tdId, data, type, requestId, relatedReqId, petReqId));
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    return files.map((file, i) => {
      return handleUpload(tdId, file);
    });
  };
};

export const accommodationAttachmentsUpload = (files, adId, requestId) => {
  return (dispatch) => {
    const handleUpload = async (adId, file) => {
      const presignedUrl = await getAccommodationPresignedUrl(adId);
      let toast = { type: 'success', errorMessage: '' };
      const filename = '${filename';
      try {
        await uploadFileToS3(presignedUrl, file);
        const data = {
          fileName: file.name,
          fileKey: presignedUrl.fields.key.replace(`${filename}}`, file.name),
        };
        dispatch(addAccommodationAttachment(adId, data, requestId));
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    return files.map((file, i) => {
      handleUpload(adId, file);
    });
  };
};

export const addTicketAttachment = (tdId, data, type, requestId, relatedReqId, petReqId) => {
  return (dispatch) => {
    axios
      .post(`/api/v1/relocation-requests/transportation-details/${tdId}/add-attachment/`, data)
      .then((response) => {
        if (type === 'employee') {
          dispatch(actionCreators.ticketAttachmentAdd(response.data, tdId, requestId));
        }
        if (type === 'related') {
          dispatch(actionCreators.relatedTicketAttachmentAdd(response.data, tdId, relatedReqId));
        }
        if (type === 'pet') {
          dispatch(actionCreators.petTicketAttachmentAdd(response.data, tdId, petReqId));
        }
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const addAccommodationAttachment = (adId, data, requestId) => {
  return (dispatch) => {
    axios
      .post(`/api/v1/relocation-requests/accommodation-details/${adId}/add-attachment/`, data)
      .then((response) => {
        dispatch(actionCreators.accommodationAttachmentAdd(response.data, adId, requestId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const deleteAttachment = (
  attachmentId,
  gfId,
  formId,
  type,
  employeeId,
  relatedId,
  relatedReqId,
  petId,
  petReqId,
  isCase,
  documentType,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .delete(
        isCase
          ? `/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/attachments/${attachmentId}/`
          : `/api/v1/employees/${employeeId}/gf/employee-edit/${gfId}/form/${formId}/attachments/${attachmentId}/`,
      )
      .then((response) => {
        if (documentType === 'forms') {
          if (type === 'employee') {
            dispatch(actionCreators.excelFormAttachmentDelete(attachmentId, gfId, formId, employeeId));
          } else if (type === 'related') {
            dispatch(actionCreators.relativeFormAttachmentDelete(attachmentId, gfId, formId, relatedReqId));
          }
        }
        if (documentType === 'documents') {
          if (type === 'employee') {
            dispatch(actionCreators.documentAttachmentDelete(attachmentId, gfId, formId, employeeId));
          } else if (type === 'related') {
            dispatch(
              actionCreators.relatedDocumentAttachmentDelete(attachmentId, gfId, formId, relatedId, relatedReqId),
            );
          } else if (type === 'pet') {
            dispatch(actionCreators.petDocumentAttachmentDelete(attachmentId, gfId, formId, petId, petReqId));
          }
        }
        if (documentType === 'legalizationSteps') {
          if (type === 'employee') {
            dispatch(actionCreators.legalizationDocumentAttachmentDelete(attachmentId, gfId, formId, employeeId));
          }
        }

        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const deleteTicketAttachment = (attachmentID, tdId, type, requestId, relatedReqId, petReqId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/relocation-requests/transportation-details/attachments/${attachmentID}/`)
      .then((response) => {
        if (type === 'employee') {
          dispatch(actionCreators.ticketAttachmentDelete(attachmentID, tdId, requestId));
        }
        if (type === 'related') {
          dispatch(actionCreators.relatedTicketAttachmentDelete(attachmentID, tdId, relatedReqId));
        }
        if (type === 'pet') {
          dispatch(actionCreators.petTicketAttachmentDelete(attachmentID, tdId, petReqId));
        }
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const deleteDocumentForm = (
  employeeId,
  gfId,
  formId,
  forWhom,
  relatedId,
  relatedReqId,
  petId,
  petReqId,
  isCase,
  documentType,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .delete(
        isCase
          ? `/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/delete/`
          : `/api/v1/employees/${employeeId}/gf/employee-edit/${gfId}/form/${formId}/delete/`,
      )
      .then((response) => {
        if (documentType === 'documents') {
          if (forWhom === 'employee') {
            dispatch(actionCreators.documentFormDelete(gfId, formId, employeeId));
          }
          if (forWhom === 'related') {
            dispatch(actionCreators.relatedDocumentFormDelete(gfId, formId, relatedId, relatedReqId));
          }
          if (forWhom === 'pet') {
            dispatch(actionCreators.petDocumentFormDelete(gfId, formId, petId, petReqId));
          }
        }
        if (documentType === 'legalizationSteps' && forWhom === 'employee') {
          dispatch(actionCreators.legalizationDocumentDelete(gfId, formId, employeeId));
        }
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const deleteTicketForm = (tdId, type, requestId, relatedReqId, petReqId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/relocation-requests/transportation-details/${tdId}/`)
      .then((response) => {
        if (type === 'employee') {
          dispatch(actionCreators.ticketFormDelete(tdId, requestId));
        }
        if (type === 'related') {
          dispatch(actionCreators.relatedTicketFormDelete(tdId, relatedReqId));
        }
        if (type === 'pet') {
          dispatch(actionCreators.petTicketFormDelete(tdId, petReqId));
        }
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const deleteAccommodationDetailsForm = (adId, requestId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/relocation-requests/accommodation-details/${adId}/`)
      .then((response) => {
        dispatch(actionCreators.accommodationFormDelete(adId, requestId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const deleteVacAppointmentForm = (vacId, type, requestId, relatedReqId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/relocation-requests/vac-appointments/${vacId}/`)
      .then((response) => {
        if (type === 'employee') {
          dispatch(actionCreators.vacAppointmentFormDelete(vacId, requestId));
        }
        if (type === 'related') {
          dispatch(actionCreators.relatedVacAppointmentFormDelete(vacId, relatedReqId));
        }
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const deleteAccommodationAttachment = (attachmentId, adId, requestId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/relocation-requests/accommodation-details/attachments/${attachmentId}/`)
      .then((response) => {
        dispatch(actionCreators.accommodationAttachmentDelete(attachmentId, adId, requestId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const arhiveDocumentForm = (data, employeeId, gfId, formId, type, relatedReqId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .put(`/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/archive/`, data)
      .then((response) => {
        if (type === 'employee') {
          dispatch(actionCreators.documentFormArchive(gfId, formId, response.data, employeeId));
        }
        if (type === 'related') {
          dispatch(actionCreators.relativeDocumentFormArchive(gfId, formId, response.data, relatedReqId));
        }
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const unArhiveDocumentForm = (data, employeeId, gfId, formId, type, relatedReqId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .put(`/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/archive/`, data)
      .then((response) => {
        if (type === 'employee') {
          dispatch(actionCreators.documentFormUnarchive(gfId, formId, response.data, employeeId));
        }
        if (type === 'related') {
          dispatch(actionCreators.relativeDocumentFormUnarchive(gfId, formId, response.data, relatedReqId));
        }
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
