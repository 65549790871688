import React, { useMemo } from 'react';
import classNames from 'classnames';
import './displayDocuments.scss';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { defaultDateFormat } from '../../../constants';

const DisplayDocumentsColumn = ({ documents, validationMessages }) => {
  const dateNow = new Date();
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;
  const thirtyDays = useMemo(() => 1000 * 60 * 60 * 24 * 30, []);

  return (
    <div className="display-documents-column__container">
      {documents.length > 0 && (
        <div className="display-documents">
          {documents.map((item, index) => {
            const expirationDate = new Date(item.expirationDate);
            const expirationInMonth = expirationDate - dateNow < thirtyDays;
            return (
              <React.Fragment key={index}>
                <span
                  className={classNames(
                    'exp-date',
                    { expirationInMonth: expirationInMonth },
                    {
                      expirationBottomBorder:
                        index !== documents.length - 1 || (index === documents.length - 1 && validationMessages),
                    },
                  )}
                >
                  {item.expirationDate
                    ? `exp. ${dayjs(item.expirationDate).format(displayDateFormat)}`
                    : item.expirationDateExists
                    ? 'not provided'
                    : ''}
                </span>
              </React.Fragment>
            );
          })}
        </div>
      )}
      <p className="validation-messages">{validationMessages}</p>
    </div>
  );
};

export default DisplayDocumentsColumn;
