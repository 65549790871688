import { useContext } from 'react';
import dayjs from 'dayjs';

import RequestForm from './RequestDetailsForm/RequestDetailsForm';
import { IsCaseDisabledContext } from '../RequestDetails';
import VacTdAdBlock from './VacTdAdBlock/VacTdAdBlock';
import { defaultDateFormat } from '../../../constants';

const RelocationRequestDetails = ({ request, requestId }) => {
  const isCaseDisabled = useContext(IsCaseDisabledContext);

  if (!request) {
    return <h1 className="no-data">Request not found!</h1>;
  }

  return (
    <div className="relocation-request-details">
      <RequestForm
        id={requestId}
        country={request.city?.country?.name ? request.city.country.name : 'Not provided'}
        city={request.city.name}
        createdAt={dayjs(request.createdAt).format(defaultDateFormat)}
        cityId={request.city.id}
        cityName={request.city.name}
        customCity={request.customCity}
        withWhom={[
          request.withSpouse && 'withSpouse',
          request.withChildren && 'withChildren',
          request.withPets && 'withPets',
        ]}
        employeeComment={request.employeeComment ? request.employeeComment : ''}
        consultationCountry={request.consultationCountry ? request.consultationCountry : ''}
        isCaseDisabled={isCaseDisabled}
        consultationLocationOptions={
          request.city.country.consultationLocations ? request.city.country.consultationLocations : []
        }
        targetUnit={request.targetUnit?.longName || 'Not provided'}
        targetManager={request.targetManager || 'Not provided'}
      />
      <div className="block">
        <VacTdAdBlock
          key={request.id}
          requestId={request.id}
          isVacRequired={request.isVacRequired}
          isTransportationRequired={request.isTransportationRequired}
          isAccommodationRequired={request.isAccommodationRequired}
          isCaseDisabled={isCaseDisabled}
        />
      </div>
    </div>
  );
};

export default RelocationRequestDetails;
