const formSchema = (citizenshipsOptions, nationalitiesOptions) => {
  return [
    {
      fieldName: 'relationType',
      type: 'Dropdown',
      label: 'Role',
      options: [
        { name: 'Spouse', id: 'spouse' },
        { name: 'Child', id: 'child' },
      ],
      required: true,
    },
    {
      fieldName: 'firstName',
      type: 'TextInput',
      label: 'First name as in passport',
      required: true,
    },
    {
      fieldName: 'lastName',
      type: 'TextInput',
      label: 'Last name as in passport',
      required: true,
    },
    {
      fieldName: 'maidenName',
      type: 'TextInput',
      label: "Mother's maiden name",
      required: true,
    },
    {
      fieldName: 'birthDate',
      type: 'DatePicker',
      label: 'Birth date',
      required: true,
    },
    {
      fieldName: 'citizenshipId',
      type: 'Dropdown',
      label: 'Citizenship',
      options: citizenshipsOptions,
      required: true,
    },
    {
      fieldName: 'nationalityId',
      type: 'Dropdown',
      label: 'Nationality',
      options: nationalitiesOptions,
      required: true,
    },
  ];
};

export default formSchema;

export const iTechArtEmployeeSchema = [
  {
    fieldName: 'isITechArtEmployee',
    type: 'Checkbox',
    label: 'Is iTechArt/Vention employee?',
  },
];
