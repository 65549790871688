import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { editRelocationRequest } from '../../../../store/actions/request';
import { useDispatch } from 'react-redux';
import { getFormElement } from '../../../Shared/Form/FormElements';

const TicketBookedBlock = (props) => {
  const { isTicketsBookedInfo, requestId } = props;

  const [isTicketsBooked, setIsTicketsBooked] = useState(isTicketsBookedInfo);

  const dispatch = useDispatch();

  const onTicketsBooked = (value) => {
    dispatch(editRelocationRequest(requestId, { isTicketsBooked: value }));
    setIsTicketsBooked(value);
  };

  const formIsTicketsBooked = [
    {
      fieldName: 'isTicketsBooked',
      type: 'Checkbox',
      label: 'Tickets booked',
      updateselectvalueroot: onTicketsBooked,
    },
  ];

  return (
    <div className="final-checkbox-wrapper">
      <Formik enableReinitialize initialValues={{ isTicketsBooked: isTicketsBooked }}>
        {(formikProps) => (
          <Form>
            {formIsTicketsBooked.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
            ))}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TicketBookedBlock;
