export const tabTableDropdownCustomStyles = {
  control: (base, state) => ({
    ...base,
    borderColor: '#ced4da',
    cursor: 'pointer',
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      borderColor: '#ec2227',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    fontSize: 13,
    backgroundColor: state.isSelected ? '#cccccb' : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? '#cccccb' : 'rgba(204, 204, 204, .3)',
    },
    cursor: 'pointer',
    border: 'none',
  }),
  placeholder: (placeholder) => ({
    ...placeholder,
    fontSize: 13,
    color: 'black',
    opacity: 0.5,
  }),
  valueContainer: (valueContainer) => ({
    ...valueContainer,
    padding: '0px 8px',
  }),
  singleValue: (valueContainer) => ({
    ...valueContainer,
    fontSize: 13,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 2 }),
  menu: (provided) => ({ ...provided, zIndex: 2 }),
};

const TitleWithSort = ({ sortColumns, sorterKey, title }) => {
  const sortedColumn = sortColumns?.find(({ column }) => column.key === sorterKey);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ fontSize: '13px', color: '#7a7a7a', fontWeight: '500', marginBottom: '2px' }}>{title} </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {sortedColumn?.order ? (
          sortedColumn.order === 'ascend' ? (
            <svg
              style={{ marginLeft: '8px', fill: '#eb3d26' }}
              width="9"
              height="9"
              viewBox="0 0 8 7"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 6.5L0.535899 0.499999L7.4641 0.5L4 6.5Z" />
            </svg>
          ) : (
            <svg
              style={{ marginLeft: '8px', transform: 'rotate(180deg)', fill: '#eb3d26' }}
              width="9"
              height="9"
              viewBox="0 0 8 7"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 6.5L0.535899 0.499999L7.4641 0.5L4 6.5Z" />
            </svg>
          )
        ) : (
          <svg
            style={{ marginLeft: '8px', fill: '#555555' }}
            width="9"
            height="9"
            viewBox="0 0 8 7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 6.5L0.535899 0.499999L7.4641 0.5L4 6.5Z" />
          </svg>
        )}
      </div>
    </div>
  );
};

export const columns = [
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'dateReminder'} title={'Date'} />,
    sorter: {
      multiple: 1,
    },
    key: 'dateReminder',
    dataIndex: 'dateReminder',
    width: '10%',
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'authorName'} title={'Author'} />,
    key: 'authorName',
    dataIndex: 'authorName',
    width: '20%',
    sorter: {
      multiple: 1,
    },
  },
  {
    title: 'Reminder text',
    dataIndex: 'reminderText',
    width: '35%',
    height: 20,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'statusReminder'} title={'Status'} />,
    key: 'statusReminder',
    dataIndex: 'statusReminder',
    width: '25%',
    sorter: {
      multiple: 1,
    },
  },
];

export const orderingMapping = {
  authorName: 'author_name',
  dateReminder: 'reminder_date',
  statusReminder: 'reminder_status',
  requestId: 'case_id',
  employeeId: 'employee_id',
};
