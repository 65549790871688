import { useEffect } from 'react';

import { getEmployeeTab } from '../../../EmployeeDocuments/Documents/DocumentsTabs/useGetTabs';
import '../../../EmployeeDocuments/Documents/DocumentsTabs/documentsTabs.scss';

const DocumentsTabs = ({ docsInfoEmployee, selectedMenuItem, currentTab, setCurrentTab }) => {
  useEffect(() => {
    if (!currentTab) setCurrentTab(String(docsInfoEmployee.internalId));
  }, []);

  const tabs = [];

  //employeeTab
  const employeeTab = getEmployeeTab({
    docsInfoEmployee,
  });

  tabs.push(employeeTab);

  return (
    <>
      <div className="documentTabs-wrapper">
        <div className="documentTabs">
          {tabs.map((tab, i) => (
            <DocumentsTab key={i} currentTab={currentTab} tab={tab} />
          ))}
        </div>
        <div className="content">
          {tabs.map((tab, i) => (
            <div key={i}>{String(currentTab) === `${tab.id}` && <>{tab.content(selectedMenuItem || '')}</>}</div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DocumentsTabs;

export const DocumentsTab = ({ currentTab, tab }) => {
  return (
    <>
      <div className="tab-item__container">
        <button
          className={
            String(currentTab) === `${tab.id}`
              ? `tab-item active ${tab?.className || ''}`
              : `tab-item ${tab?.className || ''}`
          }
          id={tab.id}
          disabled={String(currentTab) === `${tab.id}`}
        >
          {tab.tabTitle}
        </button>
      </div>
    </>
  );
};
