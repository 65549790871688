import { types } from '../actions/types';

const initialState = {
  legalizationRequest: {},
};

const legalizationRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LEGALIZATION_REQUEST_LOADED: {
      return {
        legalizationRequest: action.payload.legalizationRequest,
      };
    }
    case types.LEGALIZATION_REQUEST_UPDATED:
      return {
        ...state,
        legalizationRequest: { ...state.legalizationRequest, ...action.payload.legalizationRequest },
      };
    default:
      return {
        ...state,
      };
  }
};

export default legalizationRequestReducer;
