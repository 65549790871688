import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import { getFormElement } from '../../Shared/Form/FormElements';
import {
  editRelativeGeneralInformation,
  getNationalities,
  getCitizenships,
} from '../../../store/actions/requestDetails';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import { getFormSchema, validationSchema } from './schemaProperties';
import { getFormSchemaWithRequiredField } from '../../../helpers';

const GeneralInfo = ({ isCaseDisabled, ...relatedInfo }) => {
  const dispatch = useDispatch();

  const nationalities = useSelector((state) => state.nationalitiesList.nationalitiesList);
  const citizenships = useSelector((state) => state.nationalitiesList.citizenshipsList);

  const nationalitiesOptions = nationalities;
  const citizenshipsOptions = citizenships;

  const formSchemaWithoutRequiredField = getFormSchema({ citizenshipsOptions, nationalitiesOptions });

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField);

  useEffect(() => {
    if (!nationalities?.length) {
      dispatch(getNationalities());
    }
  }, [dispatch, nationalities?.length]);

  useEffect(() => {
    if (!citizenships?.length) {
      dispatch(getCitizenships());
    }
  }, [dispatch, citizenships?.length]);

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    dispatch(
      editRelativeGeneralInformation(relatedInfo.id, relatedInfo.rel_id, relatedInfo.rel_req_id, {
        ...values,
        relationType: values.relationType.toLowerCase(),
        firstName: values.firstName,
        lastName: values.lastName,
        firstNameRu: values.fullNameRu?.split(' ')[0],
        middleNameRu: values.fullNameRu?.split(' ')[1],
        lastNameRu: values.fullNameRu?.split(' ')[2],
      }),
    ).finally(() => setSubmitting(false));
  };

  return (
    <div className="GeneralInfo">
      <Formik enableReinitialize initialValues={relatedInfo} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps) => (
          <Form>
            {formSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
            ))}
            <SubmitButton title="Submit" formikProps={formikProps} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GeneralInfo;
