export const customStyles = {
  control: (base, state) => ({
    ...base,
    borderColor: state.isFocused ? '#FF0000' : '#ced4da',
    cursor: 'pointer',
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      borderColor: state.isFocused ? '#FF0000' : '#ced4da',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#cccccb' : 'white',
    color: state.isDisabled ? 'rgba(170, 170, 170)' : 'black',
    '&:hover': {
      backgroundColor: state.isSelected ? '#cccccb' : 'rgba(204, 204, 204, .3)',
    },
    cursor: 'pointer',
    border: 'none',
  }),
  placeholder: (placeholder) => ({
    ...placeholder,
    fontWeight: '300',
    color: 'black',
    opacity: '0.5',
  }),
  valueContainer: (valueContainer) => ({
    ...valueContainer,
    padding: '0px 8px',
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: 2,
  }),
};
