import React, { useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import DeleteFormIcon from '../../../../assets/img/icons/Сlose_square_line.svg';

import SpinnerImg from '../../../../assets/img/Spinner-1s-200px.svg';
import ModalConfirm from '../../../Common/ModalConfirm/ModalConfirm';

const DeleteForm = ({ deleteForm, toggle }) => {
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isDeletingForm, setDeletingForm] = useState(false);

  const handleDeleteForm = () => {
    setDeletingForm(true);
    deleteForm().then(() => {
      setDeletingForm(false);
    });
    setDeleteModalVisible(false);
  };

  return (
    <>
      <Tooltip placement={'top'} title={'Delete form'} mouseEnterDelay={0} mouseLeaveDelay={0} zIndex={10}>
        <button className={classNames('shareButton ico', { open: toggle }, { close: !toggle })} type="button">
          <div className="tooltip-button">
            {isDeletingForm ? (
              <img className="delete-form-spinner" src={SpinnerImg} alt="SpinnerContainer" />
            ) : (
              <img
                className="delete-icon"
                src={DeleteFormIcon}
                alt="delete"
                onClick={() => setDeleteModalVisible(true)}
              />
            )}
          </div>
        </button>
      </Tooltip>
      {isDeleteModalVisible && (
        <ModalConfirm
          isModalVisible={isDeleteModalVisible}
          setModalVisible={setDeleteModalVisible}
          confirmationQuestion="Are you sure you want to delete this form?"
          handleClick={handleDeleteForm}
        />
      )}
    </>
  );
};

export default DeleteForm;
