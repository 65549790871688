import { types } from '../actions/types';

const initialState = {
  companies: [],
  units: [],
};

const employmentInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPANIES_LOAD:
      return {
        ...state,
        companies: action.payload.companies,
      };
    case types.UNITS_LOAD:
      return {
        ...state,
        units: action.payload.units,
      };
    default:
      return {
        ...state,
      };
  }
};

export default employmentInfoReducer;
