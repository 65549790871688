import { ErrorMessage, FieldArray, Field, useField } from 'formik';
import classNames from 'classnames';

import { SubmitButton } from '../Buttons/Buttons';
import { CancelButton } from '../Buttons/Buttons';
import DateField from '../DateField/DateField';
import { getFormElement } from '../FormElements';
import './addressField.scss';

const AddressField = (props) => {
  const { name, label, formikProps, disabled } = props;
  const [field] = useField(props);

  const setIsCurrentAddress = (e, formIndex) => {
    const value = e.target.checked;
    formikProps.setFieldValue(`${name}.${formIndex}.isCurrent`, value);

    if (value) {
      if (formIndex >= 0) {
        formikProps.values.addressInfo.forEach((item, index) => {
          if (index !== formIndex) {
            formikProps.setFieldValue(`${name}.${index}.isCurrent`, false);
          }
        });
      }
    }
  };

  const formSchema = [
    {
      fieldName: 'address',
      type: 'TextareaAsInput',
      label: 'Registration address',
      disabled: disabled,
      required: true,
    },
  ];

  const handleClick = (push) => {
    push({ address: '', startDate: null, endDate: null, isCurrent: true });

    formikProps.values.addressInfo.forEach((item, index) => {
      if (index !== formikProps.values.addressInfo.length) {
        formikProps.setFieldValue(`${name}.${index}.isCurrent`, false);
      }
    });
  };

  return (
    <FieldArray name={name}>
      {({ insert, remove, push }) => (
        <div className="address-info">
          <div>
            {field.value.map((addr, index) => (
              <div key={index} className="address-info__container">
                <div className="contact-info-container">
                  {!addr?.id && field.value.length > 0 && <CancelButton remove={remove} formIndex={index} />}
                  <div className="form-group addressField">
                    <label className="control-label" htmlFor={`${name}.${index}.isCurrent`}>
                      Is current:
                    </label>
                    <div className="custom-control custom-checkbox option-inline">
                      <Field
                        name={`${name}.${index}.isCurrent`}
                        className="custom-control-input"
                        type="checkbox"
                        id={`addr.${index}.isCurrent`}
                        onChange={(e) => setIsCurrentAddress(e, index)}
                        disabled={disabled}
                      />
                      <label className="custom-checkbox__label" htmlFor={`addr.${index}.isCurrent`}>
                        <span></span>
                      </label>
                    </div>
                    <ErrorMessage
                      name={`${name}.${index}.isCurrent`}
                      className="field-error"
                      render={(msg) => <div style={{ color: 'red' }}>{msg}</div>}
                    />
                  </div>

                  {formSchema.map((field) => (
                    <div key={index}>
                      {getFormElement(
                        field.type,
                        {
                          nameFieldArray: name,
                          formIndex: index,
                          ...field,
                        },
                        formikProps,
                      )}
                    </div>
                  ))}
                  <div key={index}>
                    <DateField name={`${name}.${index}.startDate`} label={'Start date'} disabled={disabled} />
                    <DateField name={`${name}.${index}.endDate`} label={'End date'} disabled={disabled} />
                  </div>
                </div>
              </div>
            ))}
          </div>

          <SubmitButton title="Submit" formikProps={formikProps} />
          <button
            type="button"
            className={classNames('button btn-form main-btn', disabled ? 'btn-disabled' : '')}
            onClick={() => {
              handleClick(push);
            }}
            disabled={disabled}
          >
            Add address
          </button>
        </div>
      )}
    </FieldArray>
  );
};

export default AddressField;
