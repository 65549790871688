import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import Modal from '../../../../../Shared/Modal/Modal';
import { getFormElement } from '../../../../../Shared/Form/FormElements';
import { SubmitButton } from '../../../../../Shared/Form/Buttons/Buttons';
import { defaultDateFormat } from '../../../../../../constants';
import { editDateFormat } from '../../../../../../store/actions/core';

import { getFormSchemaWithRequiredField } from '../../../../../../helpers';

import ExitIcon from '../../../../../../assets/img/exit.svg';

import './settingsModal.scss';
import * as Yup from 'yup';

const formSchemaWithoutRequiredField = [
  {
    fieldName: 'dateFormat',
    type: 'Dropdown',
    label: 'Date format',
    options: [
      { text: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
      { text: 'DD-MM-YYYY', value: 'DD-MM-YYYY' },
    ],
  },
];

export const validationSchema = Yup.object().shape({
  dateFormat: Yup.string().required('This field is required'),
});

const SettingsModal = ({ isModalVisible, setModalVisible }) => {
  const dispatch = useDispatch();

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField);

  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = 'hidden';
    }
  });

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(editDateFormat(values))
      // .then(() => setIsAddNewCity(false))
      .finally(() => setSubmitting(false));
  };

  const initialValues = {
    dateFormat: displayDateFormat,
  };

  return (
    <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="s">
      <div className={'settings__modal'}>
        <div className="settings__content">
          <div className="settings__title">
            <h4 className="panel-top-row underlined">Settings</h4>
            <img src={ExitIcon} alt="close" onClick={() => setModalVisible(false)} />
          </div>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <>
                <Form>
                  <div className="settings__form">
                    {formSchema.map((field) => (
                      <div key={field.fieldName} className={field.fieldName}>
                        {getFormElement(field.type, field, formikProps)}
                      </div>
                    ))}
                  </div>

                  <div className="settings__button-container">
                    <button type="button" className="button btn-form main-btn" onClick={() => setModalVisible(false)}>
                      Cancel
                    </button>
                    <SubmitButton title="Save" formikProps={formikProps} />
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};
export default SettingsModal;
