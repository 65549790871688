import NotaBene from '../../../SendInfo/NotaBene/NotaBene';
import EmployeeDocument from './EmployeeDocument/EmployeeDocument';

const EmployeeDocuments = ({ docsInfoEmployee, employeeId }) => {
  return (
    <div>
      <NotaBene />
      {docsInfoEmployee &&
        docsInfoEmployee.documents.map((document) => {
          return <EmployeeDocument employeeId={employeeId} key={document.id} document={document} />;
        })}
      {!docsInfoEmployee.documents?.length && <p> No available documents</p>}
    </div>
  );
};

export default EmployeeDocuments;
