import React, { useContext, useEffect, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  addRelatedVacAppointment,
  addRelatedVacAppointments,
  addVacAppointment,
  addVacAppointmentNotes,
  addVacAppointments,
  editNotes,
  editVacAppointment,
  editVacAppointments,
  sendVACdetails,
  upsertVacAppointments,
} from '../../../store/actions/requestDetails';
import {
  getAddressInfoOptions,
  getValuesToUpdate,
  parseVacAppointment,
  setAllValuesToNull,
  setIsCurrentVacAppointment,
} from '../../../helpers';
import { getVacAppointmentAddresses } from '../../../store/actions/requestVacAppointment';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import VacField from './VacField/VacField';
import {
  CheckboxesSchema,
  formSchema,
  vacAppointmentInitialValues,
  validationSchema,
  visaAppealSchema,
  visaNotesSchema,
  visaStatusSchema,
} from './schemaProperties';

import './foreignPoliceAppointment.scss';
import ForeignPoliceField from './VacField/VacField';
import actionCreators from '../../../store/actions/actionCreators';
import axios from '../../../axios';

export const addRelatedForeignPolice = async (requestId, relatedReqId, vacInfo) => {
  try {
    const response = await axios.post(
      `/api/v1/relocation-requests/${requestId}/relative-requests/${relatedReqId}/foreign-police/`,
      vacInfo,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addForeignPoliceAppointment = (vacAppointments, requestId, relatedReqId) => {
  return (dispatch) => {
    const vacAppointmentsPromises = vacAppointments.map((vacInfo) => {
      return addRelatedForeignPolice(requestId, relatedReqId, {
        ...vacInfo,
      }).catch((error) => {
        throw error;
      });
    });
    return Promise.all(vacAppointmentsPromises)
      .then((responses) => {
        responses.forEach((vacInfo) => {
          dispatch(actionCreators.relatedForeignPoliceAdd(vacInfo, relatedReqId));
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const editForeignPolice = async (id, info) => {
  try {
    const response = await axios.put(`/api/v1/relocation-requests/foreign-police-appointment/${id}/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editForeignPoliceAppointment = (vacAppointments, relatedReqId, relatedFlag) => {
  return (dispatch) => {
    let isDisplaySendVacDetails;
    const vacAppointmentsPromise = vacAppointments.map((vacInfo) => {
      return editForeignPolice(vacInfo.id, {
        ...vacInfo,
      })
        .then(async (response) => {
          isDisplaySendVacDetails = response.isDisplaySendVacDetails;
          return { ...response };
        })
        .catch((error) => {
          throw error;
        });
    });
    return Promise.all(vacAppointmentsPromise)
      .then((responses) => {
        responses.forEach((vacInfo) => {
          dispatch(actionCreators.relatedForeignPoliceUpdate(vacInfo, relatedReqId, isDisplaySendVacDetails));
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const upsertRelatedForeignPoliceAppointment = (
  valuesToUpdate,
  valuesToAdd,
  vacAppointmentIsNotCurrent,
  requestId,
  relatedReqId,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    let promises = [];
    let vacAppointmentIsNotCurrentPromises, vacAppointmentsToUpdatePromises, vacAppointmentsToAddPromises;
    const relatedFlag = true;

    if (valuesToUpdate.length) {
      vacAppointmentIsNotCurrentPromises = dispatch(
        editForeignPoliceAppointment(vacAppointmentIsNotCurrent, relatedReqId, relatedFlag),
      )
        .then(async () => {
          vacAppointmentsToUpdatePromises = await dispatch(
            editForeignPoliceAppointment(valuesToUpdate, relatedReqId, relatedFlag),
          );
        })
        .then(async () => {
          if (valuesToAdd.length) {
            vacAppointmentsToAddPromises = await dispatch(
              addForeignPoliceAppointment(valuesToAdd, requestId, relatedReqId),
            );
          }
        });
    } else {
      if (valuesToAdd.length) {
        vacAppointmentsToAddPromises = dispatch(addForeignPoliceAppointment(valuesToAdd, requestId, relatedReqId));
      }
    }

    if (vacAppointmentIsNotCurrentPromises) {
      promises.push(vacAppointmentIsNotCurrentPromises);
    }
    if (vacAppointmentsToUpdatePromises) {
      promises.push(vacAppointmentsToUpdatePromises);
    }
    if (vacAppointmentsToAddPromises) {
      promises.push(vacAppointmentsToAddPromises);
    }

    return Promise.all(promises)
      .then((responses) => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const sendRelatedForeignPoliceDetails = (vacId, relatedId) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(
      `/api/v1/relocation-requests/foreign-police-appointment/${vacId}/notification/relative/`,
    );
    dispatch(actionCreators.currentRelatedForeignPoliceIsNotifiedUpdate(relatedId));
    return response.data;
  } catch (error) {
    // let errorMessage = '';
    // if (error.response?.data) {
    //   errorMessage = parse(error.response?.data);
    // }
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
    throw error;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const getForeignPoliceAddresses = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/relocation-requests/foreign-police-appointment/addresses/?limit=999');
    dispatch(actionCreators.foreignPoliceAddressesLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const ForeignPoliceAppointment = (props) => {
  const { requestId, vacInfo: vacAppointment, relatedReqId, relationType, isCaseDisabled } = props;
  const dispatch = useDispatch();

  const addressesInfo = useSelector((state) => state.vacAppointmentReducer.foreignPoliceAddressesInfo);

  // const isCaseDisabled = useContext(IsCaseDisabledContext);

  const currentVAC = vacAppointment.find((item) => item.isCurrent === true);
  const isEmployeeNotifiedForCurrentVAC = currentVAC ? currentVAC.isEmployeeNotified?.value : true;

  const vacInfo = parseVacAppointment(vacAppointment);
  const initialValues = {
    vacInfo: vacAppointment.length ? vacInfo : [vacAppointmentInitialValues],
  };

  vacInfo.forEach((item) => {
    if (!item.appeal) {
      item.appeal = {
        submissionDate: null,
        decisionDate: null,
        decision: null,
      };
    }
    item.time = {
      startTime: item?.mainTime || null,
      endTime: item?.possibleTime || null,
    };
  });

  const [addressInfoOptions, setAddressInfoOptions] = useState(getAddressInfoOptions(addressesInfo));

  const cityOptions = [
    ...new Map(addressesInfo.map((item) => [item.city?.id, { key: item.city?.id, value: item.city?.name }])).values(),
  ];

  useEffect(() => {
    setAddressInfoOptions(getAddressInfoOptions(addressesInfo));
  }, [addressesInfo]);

  useEffect(() => {
    if (!addressesInfo?.length) {
      dispatch(getForeignPoliceAddresses());
    }
  }, [dispatch, addressesInfo?.length]);

  const onChangeSelectedAddress = (addressInfoId, formikProps, formIndex) => {
    const selectedCity = addressesInfo.find((item) => item.id === addressInfoId).city?.name;

    formikProps.setFieldValue(`vacInfo.${formIndex}.addressInfo.city.name`, selectedCity);
  };

  formSchema.forEach((item) => {
    if (item.fieldName === 'addressInfo.id') {
      item.options = addressInfoOptions;
      item.updateselectvalueroot = onChangeSelectedAddress;
    }
    if (item.fieldName === 'addressInfo.city.name') {
      item.options = cityOptions;
    }
    if (item.fieldName === 'isCurrent') {
      item.updateselectvalueroot = setIsCurrentVacAppointment;
    }
  });

  const handleSendVACDetails = () => {
    dispatch(sendRelatedForeignPoliceDetails(currentVAC.id, relatedReqId));
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    values.vacInfo.map((vacInfo, formIndex) => {
      vacInfo.addressInfoId = vacInfo.addressInfo?.id;

      if (!vacInfo.id || !vacInfo.appeal?.submissionDate) {
        delete vacInfo.appeal;
      }

      vacInfo.mainTime = vacInfo.time.startTime;
      vacInfo.possibleTime = vacInfo.time.endTime;

      // delete vacInfo.time;
    });

    const valuesToUpdate = getValuesToUpdate(values.vacInfo, vacAppointment, Object.keys(vacAppointmentInitialValues));

    const vacAppointmentIsNotCurrent = getValuesToUpdate(values.vacInfo, vacAppointment, ['isCurrent']).filter(
      (item) => item.isCurrent === false,
    );

    const valuesToAdd = values?.vacInfo.filter(({ id }) => isNaN(id));

    const valuesToUpdateWithNullValues = setAllValuesToNull(valuesToUpdate);
    const vacAppointmentIsNotCurrentWithNullValues = setAllValuesToNull(vacAppointmentIsNotCurrent);
    const valuesToAddWithNullValues = setAllValuesToNull(valuesToAdd);

    if (
      valuesToUpdateWithNullValues.length ||
      valuesToAddWithNullValues.length ||
      vacAppointmentIsNotCurrentWithNullValues.length
    ) {
      dispatch(
        upsertRelatedForeignPoliceAppointment(
          valuesToUpdateWithNullValues,
          valuesToAddWithNullValues,
          vacAppointmentIsNotCurrentWithNullValues,
          requestId,
          relatedReqId,
        ),
      ).finally(() => {
        setSubmitting(false);
      });
    }
  };

  return (
    <div className="VacAppointment">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps) => {
          return (
            <Form>
              <FieldArray
                name="vacInfo"
                render={({ remove, push }) => (
                  <>
                    <ForeignPoliceField
                      values={formikProps.values}
                      formSchema={formSchema}
                      checkboxesSchema={CheckboxesSchema}
                      formikProps={formikProps}
                      requestId={requestId}
                      relatedReqId={relatedReqId}
                      remove={remove}
                    />
                    <div className="button-wrapper">
                      <SubmitButton title="Submit" formikProps={formikProps} />
                      <button
                        type="button"
                        className="button btn-form main-btn"
                        onClick={() => {
                          push(vacAppointmentInitialValues);
                          setIsCurrentVacAppointment(true, null, formikProps);
                        }}
                      >
                        Add new
                      </button>
                      {vacInfo.length > 0 && (
                        <button
                          type="button"
                          className={classNames(
                            'button btn-form main-btn btn-back',
                            isCaseDisabled || isEmployeeNotifiedForCurrentVAC ? 'btn-disabled' : '',
                          )}
                          onClick={() => handleSendVACDetails()}
                          disabled={isCaseDisabled || isEmployeeNotifiedForCurrentVAC}
                        >
                          Send details to Employee
                        </button>
                      )}
                    </div>
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ForeignPoliceAppointment;
