export const formSchema = [
  {
    fieldName: 'transportType',
    type: 'Dropdown',
    label: 'Select type',
    options: [
      { text: 'Bus', value: 'bus' },
      { text: 'Train', value: 'train' },
      { text: 'Plane (direct)', value: 'plane-direct' },
      { text: 'Plane (transit)', value: 'plane-transit' },
    ],
    required: true,
  },
  {
    fieldName: 'ticketAmount',
    type: 'Dropdown',
    label: 'Number of flights',
    placeholder: 2,
    options: [
      { text: '2', value: 2 },
      { text: '3', value: 3 },
      { text: '4', value: 4 },
      { text: '5', value: 5 },
      { text: '6', value: 6 },
      { text: '7', value: 7 },
      { text: '8', value: 8 },
      { text: '9', value: 9 },
      { text: '10', value: 10 },
    ],
  },
  {
    fieldName: 'tickets',
    type: 'TicketField',
    label: 'Tickets',
  },
  {
    fieldName: 'currency',
    type: 'Currency',
    label: 'Ticket cost',
    options: [
      { text: '\u0024', value: 'usd' },
      { text: '\u20ac', value: 'eur' },
      { text: '\u007a\u0142', value: 'pln' },
      { text: '\u20b4', value: 'uah' },
    ],
  },
  {
    fieldName: 'files',
    type: 'AttachFiles',
    label: 'Attachments',
  },
  {
    fieldName: 'notes',
    type: 'NoteField',
    label: 'Notes',
  },
];
