import * as Yup from 'yup';
import { getIn } from 'formik';
import parse from 'html-react-parser';

export const getValuesToUpdate = (currentValues, initialValues, formFields) => {
  return currentValues
    .filter(({ id }) => !(id === null || id === undefined))
    .filter((currentValue) => {
      const initialValue = initialValues.find(({ id }) => id === currentValue.id);
      return formFields.some((field) => currentValue[field] !== initialValue[field]);
    });
};

export const setValuesToNull = (values, keys) => {
  const valuesInfo = [...values];
  return valuesInfo.map((item) => {
    keys.forEach((key) => {
      if (item.hasOwnProperty(key)) {
        if (item[key] === '' || !item[key]) {
          item[key] = null;
        }
      }
    });
    return item;
  });
};

export const setAllValuesToNull = (array) => {
  return array.map((item) => {
    Object.keys(item).forEach((key) => {
      if (item[key] === '') {
        item[key] = null;
      }
    });
    return item;
  });
};

export const removeEmpty = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, v]) => v != null && v !== '')
      .map(([k, v]) => [k, v]),
  );
};

export const getFormsIdFromFilledDocuments = (documents) => {
  return documents
    .map((item) => item.forms.filter((form) => form.formId !== null && form.isDraft))
    .reduce((previousValue, currentValue) => {
      currentValue.forEach((form) => previousValue.push(form.formId));
      return previousValue;
    }, []);
};

export const getCurrentRelativeUrl = () => window.location.href.substring(window.location.origin.length);

export function targetIds(state, action) {
  const keys = Object.keys(state);
  let targetId = '';
  keys.forEach((key) => {
    if (key == action.payload.requestId) {
      targetId = key;
    }
  });
  return targetId;
}

export const getTypeSchema = (element) => {
  switch (element) {
    case 'Checkboxes':
      return Yup.array().min(1, 'This field is required').required('This field is required');
    case 'RadioButtons':
      return Yup.mixed()
        .when('isArray', {
          is: Array.isArray,
          then: Yup.array().of(Yup.string()),
          otherwise: Yup.string(),
        })
        .required('This field is required')
        .nullable();
    case 'DatePicker':
      return Yup.date().required('This field is required').nullable();
    case 'TextArea':
      return Yup.string().required('This field is required').nullable();
    case 'Dropdown':
      return Yup.string().required('This field is required').nullable();
    case 'NumberInput':
      return Yup.number().typeError('Please enter only numbers (0-9)').required('This field is required').nullable();
    case 'TextInput':
      return Yup.string().required('This field is required').nullable();
    case 'CheckboxesWithDates':
      return Yup.array().min(1, 'This field is required').required('This field is required');
    default:
      return null;
  }
};

export const removeDuplicates = (arr) => {
  const result = [];
  const duplicatesIndices = [];

  arr.forEach((current, index) => {
    if (duplicatesIndices.includes(index)) return;

    result.push(current);

    for (let comparisonIndex = index + 1; comparisonIndex < arr.length; comparisonIndex++) {
      const comparison = arr[comparisonIndex];
      const currentKeys = Object.keys(current);
      const comparisonKeys = Object.keys(comparison);

      if (currentKeys.length !== comparisonKeys.length) continue;

      const currentKeysString = currentKeys.sort().join('').toLowerCase();
      const comparisonKeysString = comparisonKeys.sort().join('').toLowerCase();
      if (currentKeysString !== comparisonKeysString) continue;

      let valuesEqual = true;
      for (let i = 0; i < currentKeys.length; i++) {
        const key = currentKeys[i];
        if (current[key] !== comparison[key]) {
          valuesEqual = false;
          break;
        }
      }
      if (valuesEqual) duplicatesIndices.push(comparisonIndex);
    }
  });
  return result;
};

export const getEventIdsForCase = (events) => {
  return events.reduce((previousValue, currentValue) => {
    previousValue.push(currentValue.uuid);
    return previousValue;
  }, []);
};

export const sortParam = (localStorage) => {
  let path = '';
  if (localStorage?.[1] === 1 || localStorage?.[1] === -1) {
    path = 'ordering=' + localStorage?.[0];
  }
  return path;
};

export const sortHelper = (headRow, colName) => {
  let sortName = headRow.find((col) => col.colName === colName)?.sortName;
  const toggleState = headRow.find((col) => col.colName === colName)?.toggleState;

  toggleState === -1 && !sortName.includes('-')
    ? (sortName = `-${sortName}`)
    : toggleState === -1 && sortName.includes('-')
    ? (sortName = `-${sortName
        .split('')
        .filter((e) => e !== '-')
        .join('')}`)
    : toggleState === 1
    ? (sortName = sortName
        .split('')
        .filter((e) => e !== '-')
        .join(''))
    : '';
  return [sortName, toggleState];
};

export const setLocalStorageSortName = (key) => {
  return `${key
    .split(/\s+/)
    .map((word, index) => (index > 0 ? word[0].toUpperCase() + word.substring(1) : word))
    .join('')}Sort`;
};

export const setLocalStorageFilterName = (key) => {
  return key
    .split(/\s+/)
    .map((word, index) => (index > 0 ? word[0].toUpperCase() + word.substring(1) : word))
    .join('');
};

export const getIsCaseDisabled = (info) => {
  return (
    (!info?.isActive && !info.currentEmploymentInfo) ||
    info?.status.toLowerCase() === 'canceled' ||
    info?.isCreatedByEmployee
  );
};

export const getIsDismissedCase = (info) => {
  return !info?.isActive && !info.currentEmploymentInfo;
};

export const getDismissalLabel = (isEmployeeHasCurrentEmploymentInfo) => {
  return isEmployeeHasCurrentEmploymentInfo ? ' (Dismissed)' : ' (On Dismissal)';
};

export const parseVacAppointment = (vacInfo) => {
  return vacInfo
    .map((item) => ({ ...item }))
    .sort((a, b) => a.id - b.id)
    .sort((a, b) => (a.isCurrent === b.isCurrent ? 0 : a.isCurrent ? -1 : 1));
};

export const getAddressInfoOptions = (addressesInfo) => {
  return addressesInfo?.map((addressInfo) => {
    return { text: addressInfo.address, key: addressInfo.id, value: addressInfo.id, city: addressInfo.city?.id };
  });
};

export const setIsCurrentVacAppointment = (value, formIndex, formikProps) => {
  if (value) {
    if (formIndex >= 0) {
      formikProps.values.vacInfo.forEach((item, index) => {
        if (index !== formIndex) {
          formikProps.setFieldValue(`vacInfo.${index}.isCurrent`, false);
        }
      });
    } else {
      formikProps.values.vacInfo.forEach((item, index) => {
        if (index !== formikProps.values.vacInfo.length - 1) {
          formikProps.setFieldValue(`vacInfo.${index}.isCurrent`, false);
        }
      });
    }
  }
};

export const checkIsRequiredField = (validationSchema, name) => {
  return !!getIn(validationSchema.describe().fields, name)?.tests?.find((testName) => testName.name === 'required');
};

export const getFormSchemaWithRequiredField = (validationSchema, formSchema) => {
  formSchema.forEach((item) => {
    item.required = checkIsRequiredField(validationSchema, item.fieldName);
  });
  return formSchema;
};

export function waitForElement(elementId) {
  return new Promise((resolve) => {
    if (document.getElementById(elementId)) {
      return resolve(document.getElementById(elementId));
    }
    const observer = new MutationObserver((mutations) => {
      if (document.getElementById(elementId)) {
        resolve(document.getElementById(elementId));
        observer.disconnect();
      }
    });
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export const htmlRegex = new RegExp(/<\/?[a-z][\s\S]*>/i);

export const getParsedMessage = (message) => {
  return htmlRegex.test(message) ? parse(message) : message;
};

export const sumValues = (obj) => Object.values(obj).reduce((a, b) => a + b, 0);
