import ArchivedDocument from './ArchivedDocument/ArchivedDocument';

const ArchivedDocuments = ({ archivedDocuments, isCaseDisabled, employeeId, relatedReqId, type }) => {
  return (
    <div className="relative-archived-documents">
      {archivedDocuments.map((archivedDocument) => {
        return (
          <ArchivedDocument
            key={archivedDocument.id}
            employeeId={employeeId}
            document={archivedDocument}
            relatedReqId={relatedReqId}
            isCaseDisabled={isCaseDisabled}
            type={type}
          />
        );
      })}
    </div>
  );
};

export default ArchivedDocuments;
