import { useDispatch } from 'react-redux';

import { setAllValuesToNull, getValuesToUpdate } from '../../../../../../../helpers';
import { addExcelDocument, editExcelDocument } from '../../../../../../../store/actions/excelForm';
import { attachmentUpload } from '../../../../../../../store/actions/uploadFile';
import DocumentForm from '../../../../../../Common/DocumentForm/DocumentForm';

const EmployeeForm = (props) => {
  const { employeeId, document } = props;

  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting, setFieldValue, resetForm, setStatus }) => {
    const valuesInfo = { ...values };

    valuesInfo[document.slug].forEach((item) => {
      if (item.id === null) delete item.id;
    });

    const allFormFields = document.forms[0].fields.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.fieldName);
      accumulator.push(currentValue.fieldName + 'DateExtraFields');
      return accumulator;
    }, []);

    const valuesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
      })),
      allFormFields,
    );

    const valuesWithFilesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
        files: [],
      })),
      ['files'],
    );

    const valuesToAdd = values[document.slug].filter(({ id }) => id === null || id === undefined);

    const valuesToUpdateWithNullValues = setAllValuesToNull(valuesToUpdate);
    const valuesToAddWithNullValues = setAllValuesToNull(valuesToAdd);

    const files = valuesInfo[document.slug].some((item) => item?.files?.length);

    if (valuesToUpdateWithNullValues?.length) {
      valuesToUpdateWithNullValues.forEach((item) => {
        dispatch(editExcelDocument(employeeId, document.id, document.forms[0].formId, item, true));
      });
    }
    if (files) {
      valuesWithFilesToUpdate.map((item) => {
        return (
          item?.files &&
          dispatch(
            attachmentUpload(item.files, employeeId, document.id, item.id, 'employee', null, null, null, null, true),
          )
        );
      });
    }
    if (valuesToAddWithNullValues?.length) {
      valuesToAddWithNullValues.forEach((item) => {
        dispatch(addExcelDocument(employeeId, document.id, item, true)).then((response) => {
          item?.files &&
            dispatch(
              attachmentUpload(
                item.files,
                employeeId,
                document.id,
                response.formId,
                'employee',
                null,
                null,
                null,
                null,
                true,
              ),
            );
        });
      });
    }

    if (document.isWithAttachments) {
      values[document.slug].forEach((item, index) => setFieldValue(`${document.slug}.${index}.files`, []));
    }

    setSubmitting(false);
  };

  return (
    <DocumentForm
      document={document}
      employeeId={employeeId}
      onSubmit={onSubmit}
      education={[]}
      isCase={false}
      documentType={{ forWhom: 'employee', type: 'forms' }}
      allowCustomReminders={false}
      submitBtnTitle={'Save'}
    />
  );
};

export default EmployeeForm;
