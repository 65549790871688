import { useState } from 'react';
import { Tooltip } from 'antd';

import Document from '../../../../../assets/img/document.svg';
import Close from '../../../../../assets/img/icons/exit.svg';
import ModalConfirm from '../../../ModalConfirm/ModalConfirm';
import { getAttachmentLink } from '../../../../../store/actions/downloadFile';
import SpinnerImg from '../../../../../assets/img/Spinner-1s-200px.svg';

import './displayAttachment.scss';

const DisplayAttachment = ({
  attachmentId,
  attach,
  deleteAttachment,
  employeeId,
  gfId,
  formId,
  isCase,
  isDraft,
  isCaseDisabled,
}) => {
  const [isDeleteAttachmentModalVisible, setDeleteAttachmentModalVisible] = useState(false);
  const [isDeletingAttachment, setDeletingAttachment] = useState(false);

  const downloadFile = async (attachmentId) => {
    const response = await getAttachmentLink(attachmentId, employeeId, gfId, formId, isCase);
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = response.url;
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const openModal = () => {
    setDeleteAttachmentModalVisible(true);
  };

  const deleteFile = () => {
    setDeletingAttachment(true);
    deleteAttachment(attachmentId).then(() => setDeletingAttachment(false));
    setDeleteAttachmentModalVisible(false);
  };

  return (
    <>
      <div className="display-attachment" onClick={() => downloadFile(attachmentId)}>
        <div className="attachment-container">
          <img src={Document} alt={attach.fileName} className="icon" />
          <span className="attachment-name">{attach.fileName}</span>
          {!isCaseDisabled && deleteAttachment && ((isDraft && !isCase) || isCase) && (
            <Tooltip placement={'top'} title={'Delete attachment'}>
              <div className="delete-attachment">
                {isDeletingAttachment ? (
                  <img className="delete-attachment-spinner" src={SpinnerImg} alt="SpinnerContainer" />
                ) : (
                  <img src={Close} alt="Close" className="close-attachment" onClick={() => openModal()} />
                )}
              </div>
            </Tooltip>
          )}
        </div>
        {isDeleteAttachmentModalVisible && (
          <ModalConfirm
            isModalVisible={isDeleteAttachmentModalVisible}
            setModalVisible={setDeleteAttachmentModalVisible}
            confirmationQuestion="Are you sure you want to delete this attachment?"
            handleClick={deleteFile}
          />
        )}
      </div>
    </>
  );
};

export default DisplayAttachment;
