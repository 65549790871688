import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getResponsibleGMG } from '../../../store/actions/requestDetails';
import axios from '../../../axios';
import { Form, Formik } from 'formik';
import { getFormElement } from '../../Shared/Form/FormElements';
import { getValuesByElement } from '../../FeedbackDashboard/FeedbackColumn/FeedbackColumn';
import classNames from 'classnames';
import { Timeline, Tooltip } from 'antd';
import { CheckCircleFilled, ClockCircleFilled, Loading3QuartersOutlined, UserOutlined } from '@ant-design/icons';
import actionCreators from '../../../store/actions/actionCreators';
import dayjs from 'dayjs';
import { defaultDateFormat } from '../../../constants';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';

import './legalizationRequestOverview.scss';
import { Link } from 'react-router-dom';

export const columns = [
  {
    key: 'date',
    title: 'Date',
    label: 'Date',
    dataIndex: 'date',
    width: '10%',
  },
  {
    key: 'time',
    title: 'Time',
    label: 'Time',
    dataIndex: 'time',
    width: '20%',
  },
  {
    key: 'employee',
    title: 'Employee',
    label: 'Employee',
    dataIndex: 'employee',
    width: '14%',
  },
  {
    key: 'changes',
    title: 'Changes made',
    label: 'Changes made',
    dataIndex: 'changes',
    width: '56%',
  },
];

const LegalizationRequestOverview = ({ employeeId, isPortalStaff, isHrManager }) => {
  const dispatch = useDispatch();
  const gmgResponsible = useSelector((state) => state.gmgResponsible.gmgResponsible);

  const legalizationRequest = useSelector((state) => state.legalizationRequestReducer.legalizationRequest);

  const { data: statusTimelineData, maxKey: statusTimelineMaxKey } = { ...legalizationRequest.statusTimeline };

  const [isLoading, setIsLoading] = useState(true);

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const getTimeline = (item, itemIndex, maxKey) => {
    const key = maxKey ? maxKey - 1 : 1;

    if (key + 1 === itemIndex) {
      if (item.set) {
        return {
          color: 'green',
          dot: <CheckCircleFilled height={'20px'} width={'20px'} />,
          children: (
            <div>
              <div className={'text'}>{item.title}</div>
              <div
                className={'date'}
                style={{
                  fontSize: '14px',
                  height: '20px',
                }}
              >
                {item.set && `Set: ${dayjs(new Date(item.set)).format(displayDateFormat)}`}
              </div>
            </div>
          ),
        };
      } else {
        return {
          color: '#eb3d26',
          dot: <Loading3QuartersOutlined />,
          children: (
            <div>
              <div className={'text'}>{item.title}</div>
              <div
                className={'date'}
                style={{
                  fontSize: '14px',
                  height: '20px',
                }}
              >
                Status in progress
              </div>
            </div>
          ),
        };
      }
    } else if (itemIndex > key) {
      return {
        color: '#ced4da',
        dot: <ClockCircleFilled />,
        children: (
          <div>
            <div className={'text'}>{item.title}</div>
            <div
              className={'date'}
              style={{
                fontSize: '14px',
                height: '20px',
              }}
            >
              {/*{item.set ? `Expected: ${item.set}` : 'Expected: '}*/}
            </div>
          </div>
        ),
      };
    } else {
      return {
        color: 'green',
        dot: <CheckCircleFilled height={'20px'} width={'20px'} />,
        children: (
          <div>
            <div className={'text'}>{item.title}</div>
            <div
              className={'date'}
              style={{
                fontSize: '14px',
                height: '20px',
              }}
            >
              {item.set && `Set: ${dayjs(new Date(item.set)).format(displayDateFormat)}`}
            </div>
          </div>
        ),
      };
    }
  };

  const getLegalizationRequest = async () => {
    try {
      const response = await axios.get(`/api/v1/legalization-request/${employeeId}/details/`);

      dispatch(actionCreators.legalizationRequestLoaded(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!gmgResponsible?.length && (isPortalStaff || isHrManager)) {
      dispatch(getResponsibleGMG());
    }
  }, [dispatch, gmgResponsible?.length]);

  useEffect(() => {
    getLegalizationRequest().finally(() => setIsLoading(false));
  }, []);

  const editableFormSchema = [
    {
      fieldName: 'responsibleId',
      type: 'Dropdown',
      label: 'Responsible',
      options: [],
    },
    {
      fieldName: 'status',
      type: 'Dropdown',
      label: 'Status',
      options: [
        { text: 'New', value: 'New' },
        { text: 'In progress', value: 'In progress' },
        { text: 'Waiting for passport translation', value: 'Waiting for passport translation' },
        {
          text: 'Verification of the notarized translation of the passport',
          value: 'Verification of the notarized translation of the passport',
        },
        { text: 'Documents preparation', value: 'Documents preparation' },
        {
          text: 'An electronic version was sent to the employee',
          value: 'An electronic version was sent to the employee',
        },
        { text: 'Documents are ready to pick up', value: 'Documents are ready to pick up' },
        { text: 'Documents were sent by post', value: 'Documents were sent by post' },
        { text: 'Documents received by the employee', value: 'Documents received by the employee' },
      ],
    },
  ];

  const nonEditableFormSchema = [
    {
      fieldName: 'country',
      type: 'TextInput',
      label: 'Country',
      disabled: true,
    },
    {
      fieldName: 'type',
      type: 'TextInput',
      label: 'Type',
      disabled: true,
    },
  ];

  const lastFormsSchema = [
    {
      fieldName: 'deadline',
      type: 'DatePicker',
      label: 'Deadline: Date of submission of documents (if known).',
    },
    {
      fieldName: 'comments',
      type: 'TextArea',
      label: 'Comments: please provide your comments for the Global Mobility Team, if necessary.',
    },
  ];

  const responsibleSelectOptions =
    gmgResponsible?.map((responsible) => {
      return { text: responsible.generalInfo.fullName, value: responsible.uuid };
    }) || [];

  const editLegalization = (data) => async (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    try {
      const response = await axios.put(`/api/v1/legalization-request/${employeeId}/details/`, data);
      dispatch(actionCreators.legalizationRequestLoaded(response.data));
      return response.data;
    } catch (error) {
      toast.type = 'error';
      toast.errorMessage = error.response?.data;
    } finally {
      dispatch(
        actionCreators.toastUpdate({
          type: toast.type,
          message: toast.errorMessage,
        }),
      );
    }
  };

  const editLegalizationResponsible = (legalizationRequestId, data) => async (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    try {
      const response = await axios.put(`/api/v1/legalization-request/${legalizationRequestId}/responsible/`, data);
      dispatch(actionCreators.legalizationRequestLoaded(response.data));
      return response.data;
    } catch (error) {
      toast.type = 'error';
      toast.errorMessage = error.response?.data;
    } finally {
      dispatch(
        actionCreators.toastUpdate({
          type: toast.type,
          message: toast.errorMessage,
        }),
      );
    }
  };

  const updateResponsible = (responsibleId) => {
    dispatch(editLegalizationResponsible(legalizationRequest.id, { responsibleId: responsibleId })).then((r) =>
      console.log(''),
    );
  };

  const updateStatus = (status) => {
    dispatch(editLegalization({ status: status })).then((r) => console.log(''));
  };

  editableFormSchema.forEach((item) => {
    if (item.fieldName === 'responsibleId') {
      item.options = responsibleSelectOptions;
      item.updateselectvalueroot = updateResponsible;
    } else if (item.fieldName === 'status') {
      item.updateselectvalueroot = updateStatus;
    }
  });

  const formsTypeMapping = {
    employee: 'Only employee',
    family: 'Only family',
    employee_and_family: 'With family',
  };

  const initialValues = {
    responsibleId: legalizationRequest.responsible?.uuid,
    status: legalizationRequest.status,
    type: formsTypeMapping[legalizationRequest.formsType],
    country: legalizationRequest.country?.name,
    deadline: legalizationRequest.deadline,
    comments: legalizationRequest.comments,
  };

  return (
    <div className={'legalization-request-details__wrapper'}>
      {!isLoading &&
        (legalizationRequest.status ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} className={'hover-test'}>
            <div className={'flex-space legalization-request-details'}>
              <h4>Legalization request details</h4>
              <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
                {(formikProps) => (
                  <>
                    <Form>
                      <div className="question-block">
                        <div className="form-group form-group__column">
                          {editableFormSchema.map((field) => (
                            <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                          ))}
                          {formikProps.values?.status === 'Documents were sent by post' && (
                            <div className={'contact-employee-sent-by-post'}>
                              <Tooltip placement={'top'} title={'Contact employee'}>
                                <Link
                                  to={{
                                    pathname: `/contact/${employeeId}/template/40`,
                                  }}
                                >
                                  <button
                                    type="button"
                                    className={classNames('button btn-form main-btn btn-back submit-btn')}
                                    style={{
                                      width: '200px',
                                      height: '40px',
                                      margin: 'unset',
                                      fontSize: '16px',
                                    }}
                                  >
                                    Contact employee
                                  </button>
                                </Link>
                              </Tooltip>
                            </div>
                          )}
                        </div>
                        <div className={'underl-test'}></div>
                        <div className="form-group form-group__column">
                          {nonEditableFormSchema.map((field) => (
                            <div key={field.fieldName} className="form-group form-group__column">
                              {field.label && (
                                <label className={classNames('control-label', { required: field.required })}>
                                  {field.label}
                                </label>
                              )}
                              <div className={classNames('text-field__wrapper', 'form-input-width')}>
                                <div>
                                  {/*{'Test'}*/}
                                  {getValuesByElement(
                                    field,
                                    initialValues[field.fieldName],
                                    initialValues[field.fieldName + 'DateExtraFields'],
                                  ) || 'Not provided'}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {legalizationRequest?.forms?.length > 0 &&
                          legalizationRequest?.forms?.map((form, formIndex) => {
                            const { fields, values, status } = form.forms[0];

                            return fields.map((field, fieldIndex) => {
                              return (
                                <div key={field.fieldName} className="form-group form-group__column">
                                  {field.label && (
                                    <label className={classNames('control-label', { required: field.required })}>
                                      {field.label}
                                    </label>
                                  )}
                                  <div className={classNames('text-field__wrapper', 'form-input-width')}>
                                    <div>
                                      {/*{'Test'}*/}
                                      {getValuesByElement(
                                        field,
                                        values[field.fieldName],
                                        values[field.fieldName + 'DateExtraFields'],
                                      ) || 'Not provided'}
                                    </div>
                                  </div>
                                </div>
                              );
                            });
                          })}
                        <div className="form-group form-group__column">
                          {lastFormsSchema.map((field) => (
                            <div key={field.fieldName} className="form-group form-group__column">
                              {field.label && (
                                <label className={classNames('control-label', { required: field.required })}>
                                  {field.label}
                                </label>
                              )}
                              <div className={classNames('text-field__wrapper', 'form-input-width')}>
                                <div>
                                  {/*{'Test'}*/}
                                  {getValuesByElement(
                                    field,
                                    initialValues[field.fieldName],
                                    initialValues[field.fieldName + 'DateExtraFields'],
                                  ) || 'Not provided'}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
            <div className={'flex-space status-timeline'}>
              <h4>Status timeline</h4>
              <Timeline
                items={
                  statusTimelineData?.length > 0
                    ? statusTimelineData.map((item, itemIndex) => {
                        return {
                          ...getTimeline(item, itemIndex, statusTimelineMaxKey),
                        };
                      })
                    : []
                }
              />
            </div>
          </div>
        ) : (
          <div>No legalization request</div>
        ))}
      {isLoading && <SpinnerContainer />}
    </div>
  );
};

export default LegalizationRequestOverview;
