import classNames from 'classnames';
import { Field, ErrorMessage, useField } from 'formik';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import { ReactComponent as CalendarIcon } from '../../../../assets/img/icons/calendar.svg';

import './CheckboxWithDatepicker.scss';
import { useSelector } from 'react-redux';
import { defaultDateFormat } from '../../../../constants';

const CheckboxWithDatepicker = (props) => {
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const { name, label, formIndex, nameFieldArray, disabled, isRequired } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const valueFieldName = `${fieldName}.value`;
  const dateFieldName = `${fieldName}.date`;

  const [field, , { setValue }] = useField(dateFieldName);

  return (
    <div className={'form-group checkbox-with-date-picker'}>
      <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
        {label}
      </label>
      <div className={'custom-control custom-checkbox option-inline'}>
        <Field
          name={valueFieldName}
          className={'custom-control-input'}
          type={'checkbox'}
          id={valueFieldName}
          disabled={disabled}
        />
        <label className={'custom-checkbox__label'} htmlFor={valueFieldName}>
          <span style={disabled && { cursor: 'not-allowed' }}></span>
        </label>
      </div>
      <DatePicker
        value={field.value && dayjs(field.value)}
        onChange={(date) => (date ? setValue(dayjs(date).format('MM/DD/YYYY')) : setValue(null))}
        className={'form-control date-picker'}
        placeholder={displayDateFormat}
        format={displayDateFormat}
        name={'dateFieldName'}
        id={'dateFieldName'}
        disabled={disabled}
        suffixIcon={<CalendarIcon />}
      />
      <ErrorMessage
        name={fieldName}
        className={'field-error'}
        render={(msg) => <div className={'error-msg'}>{msg}</div>}
      />
    </div>
  );
};

export default CheckboxWithDatepicker;
