import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TableDropdown } from '../../Table/Dropdown/Dropdown';
import { editReminderStatus } from '../../../../store/actions/reminders';
import { getEmployeeReminders } from '../../../../store/actions/employeeProfile';
import { columns, orderingMapping, tabTableDropdownCustomStyles } from './tableProperties';

import './reminderTable.scss';
import dayjs from 'dayjs';
import { defaultDateFormat } from '../../../../constants';

const RemindersTab = ({ currentTab, employeeId }) => {
  const dispatch = useDispatch();

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;
  const count = useSelector((state) => state.employeeProfileReducer[employeeId]?.employeeReminders?.count) || 0;
  const reminders =
    useSelector((state) => state.employeeProfileReducer[employeeId]?.employeeReminders?.reminders) || [];

  const [page, setPage] = useState(1);
  const [ordering, setOrdering] = useState([]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, [dispatch, currentTab, page, ordering]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  useEffect(() => {
    dispatch(
      getEmployeeReminders(employeeId, page, {
        ordering: ordering.join(','),
      }),
    );
  }, [dispatch, page, ordering]);

  const handleTableChange = (pagination, filters, sorter) => {
    const sortering = Array.isArray(sorter) ? sorter : [sorter];

    const test = sortering.map((item) => {
      if (item.order === 'ascend') {
        return `-${orderingMapping[item.columnKey]}`;
      }
      if (item.order === 'descend') {
        return `${orderingMapping[item.columnKey]}`;
      }
    });

    setOrdering(test);
  };

  const onStatusChange = (reminderId, value) => {
    dispatch(editReminderStatus(reminderId, { status: value })).then((response) => {
      dispatch(
        getEmployeeReminders(employeeId, page, {
          ordering: ordering.join(','),
        }),
      );
    });
  };

  const data = reminders.map((item, index) => {
    return {
      key: index,
      employeeName: (
        <Link style={{ fontSize: '13px', fontWeight: '600' }} to={`/profile/${item.employee.uuid}/`}>
          {item.employee?.generalInfo?.fullName}
        </Link>
      ),
      authorName: item.author?.generalInfo?.fullName,
      dateReminder: item.targetDate ? dayjs(item.targetDate).format(displayDateFormat) : 'Not provided',
      reminderText: <div className="reminder-text">{item.text}</div>,
      statusReminder: (
        <TableDropdown
          value={{ value: item.status, label: item.status }}
          options={[
            { value: 'Active', text: 'Active', label: 'Active' },
            { value: 'Completed', text: 'Completed', label: 'Completed' },
          ]}
          onChange={(option) => onStatusChange(item.uuid, option.value)}
          customStyles={tabTableDropdownCustomStyles}
          isClearable={false}
        />
      ),
    };
  });

  return (
    <div className={'reminder-tab'}>
      <Table
        columns={columns}
        reminder-tab
        dataSource={data}
        onChange={handleTableChange}
        size={'small'}
        pagination={{
          total: count,
          pageSize: 3,
          showSizeChanger: false,
          hideOnSinglePage: true,
          onChange: (page, pageSize) => {
            setPage(page);
          },
        }}
      />
    </div>
  );
};

export default RemindersTab;
