import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';

import EditButton from '../../../../assets/img/edit-button.svg';

const EditFormInConstructor = ({ toggle, withEditFormInConstructor, documentId }) => {
  return (
    <>
      <Tooltip placement={'top'} title={'Edit form'} mouseEnterDelay={0} mouseLeaveDelay={0}>
        <button className={classNames('shareButton ico', { open: toggle }, { close: !toggle })} type="button">
          <div className="tooltip-button">
            <Link
              to={{
                pathname: `/form-builder/${withEditFormInConstructor.pathName}/` + documentId,
                state: { id: documentId, tab: withEditFormInConstructor.pathName },
              }}
            >
              <img className="edit-icon" src={EditButton} alt="edit" />
            </Link>
          </div>
        </button>
      </Tooltip>
    </>
  );
};

export default EditFormInConstructor;
