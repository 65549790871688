import { useState } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { statisticsExportExcel } from '../../../store/actions/statistics';

import SpinnerImg from '../../../assets/img/SpinnerWhite.svg';
import './generateExcel.scss';

const GenerateExcel = () => {
  const dispatch = useDispatch();
  const { excelFilters } = useSelector((state) => state.statistics, shallowEqual);

  const [isLoading, setLoading] = useState(false);

  const downloadExcel = () => {
    setLoading(true);
    dispatch(statisticsExportExcel(excelFilters)).finally(() => setLoading(false));
  };

  return (
    <button className="statistics__generation-excel-btn button btn-form main-btn btn-back" onClick={downloadExcel}>
      {isLoading ? <img src={SpinnerImg} alt="SpinnerContainer" /> : <>Generate Excel</>}
    </button>
  );
};

export default GenerateExcel;
