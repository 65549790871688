import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getResponsibleGMG } from '../../../store/actions/requestDetails';
import CaseTable from '../../Case/CaseList/CaseTable/CaseTable';

const EmployeeCases = ({ cases }) => {
  const dispatch = useDispatch();
  const gmgResponsible = useSelector((state) => state.gmgResponsible.gmgResponsible);

  useEffect(() => {
    if (!gmgResponsible?.length) {
      dispatch(getResponsibleGMG());
    }
  }, [dispatch, gmgResponsible?.length]);

  const responsibleSelectOptions =
    gmgResponsible?.map((responsible) => {
      return { label: responsible.generalInfo.fullName, value: responsible.uuid };
    }) || [];

  return (
    <div className="employee-profile__cases">
      <CaseTable cases={cases} filtersType={'employeeProfile'} responsibleSelectOptions={responsibleSelectOptions} />
    </div>
  );
};

export default EmployeeCases;
