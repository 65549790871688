import { types } from '../actions/types';
import { targetIds } from '../../helpers';

const initialState = {};

const employeeProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EMPLOYEE_PROFILE_LOADED: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: action.payload?.employeeProfile,
        },
      };
    }
    case types.EMPLOYEE_PROFILE_UPDATE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: { ...state[targetId]?.employeeProfile, ...action.payload?.employeeProfile },
        },
      };
    }
    case types.REQUEST_DETAILS_LOADED: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId]?.employeeProfile,
            ...action.payload.employeeData,
            documents: action.payload.employeeDocuments,
            forms: action.payload.employeeForms,
            legalizationDocuments: action.payload.employeeLegalizationDocuments,
            archivedDocuments: action.payload.employeeArchivedDocuments,
            relatedCases: action.payload.relatedCases,
          },
        },
      };
    }
    case types.PROFILE_DOCUMENTS_LOAD: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId]?.employeeProfile,
            ...action.payload.employeeData,
            documents: action.payload.employeeDocuments,
            forms: action.payload.employeeForms,
            documentRequest: action.payload.documentRequest,
          },
        },
      };
    }
    case types.PROFILE_DOCS_COMMENT_UPDATE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId]?.employeeProfile,
            documentRequest: {
              ...state[targetId]?.employeeProfile?.documentRequest,
              comment: action.payload.employeeComment,
            },
          },
        },
      };
    }
    case types.GENERAL_DOCS_INFO_UPDATED: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId]?.employeeProfile,
            generalInfo: action.payload.generalInfo,
          },
        },
      };
    }
    case types.REQUEST_DETAILS_UPDATED: {
      const targetId = action.payload.employeeId;
      return state[targetId]
        ? {
            ...state,
            [targetId]: {
              ...state[targetId],
              employeeProfile: {
                ...state[targetId].employeeProfile,
                documents: !action.payload?.requestDetails?.employeeDocuments
                  ? state[targetId].employeeProfile.documents
                  : [...action.payload?.requestDetails?.employeeDocuments],
                legalizationDocuments: !action.payload?.requestDetails?.employeeLegalizationDocuments
                  ? state[targetId].employeeProfile.legalizationDocuments
                  : [...action.payload?.requestDetails?.employeeLegalizationDocuments],
              },
            },
          }
        : { ...state };
    }
    case types.RELATED_CASES_UPDATE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId]?.employeeProfile,
            relatedCases: action.payload.relatedCases,
          },
        },
      };
    }
    case types.EMPLOYMENT_INFO_ADD: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            employmentInfoHistory: [
              ...state[targetId].employeeProfile.employmentInfoHistory,
              action.payload.relocatedEmploymentInfo,
            ],
          },
        },
      };
    }
    case types.EMPLOYMENT_INFO_UPDATE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId]?.employeeProfile,
            employmentInfoHistory: state[targetId].employeeProfile.employmentInfoHistory.map((item) => {
              if (+item.id === action.payload.employmentInfo.id) {
                return action.payload.employmentInfo;
              } else {
                return item;
              }
            }),
          },
        },
      };
    }
    case types.GENERAL_INFO_UPDATED: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId]?.employeeProfile,
            generalInfo: {
              ...state[targetId].generalInfo,
              ...action.payload.generalInfo,
            },
          },
        },
      };
    }
    case types.CONTACT_INFO_UPDATED: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId]?.employeeProfile,
            contactInfo: {
              ...state[targetId].contactInfo,
              ...action.payload.contactInfo,
            },
          },
        },
      };
    }
    case types.DOCUMENT_ADD: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            documents: state[targetId].employeeProfile.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms = [...item.forms, action.payload.document];
                item.forms.forEach((form, index) => {
                  if (form.formId === null) {
                    item.forms.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.DOCUMENT_UPDATE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            documents: state[targetId].employeeProfile.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.document.formId) {
                    form.isCurrent = action.payload.document.isCurrent;
                    form.values = action.payload.document.values;
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.DOCUMENT_FORM_DELETE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            documents: state[targetId].employeeProfile.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                if (item.forms.length > 1) {
                  return {
                    ...item,
                    forms: item.forms.filter((form) => form.formId !== action.payload.formId),
                  };
                } else {
                  return {
                    ...item,
                    forms: item.forms.map((form, index) => {
                      if (form.formId === action.payload.formId) {
                        for (let key in item.forms[index].values) {
                          if (key === 'customReminders') {
                            item.forms[index].values[key] = {};
                          } else {
                            item.forms[index].values[key] = '';
                          }
                        }
                        return { ...form, formId: null, attachments: [] };
                      } else return form;
                    }),
                  };
                }
              } else return item;
            }),
          },
        },
      };
    }
    case types.DOCUMENT_FORM_ARCHIVE: {
      const targetId = action.payload.employeeId;
      const isFormExistGF = state[targetId].employeeProfile.archivedDocuments.find(
        (item) => +item.id === +action.payload.gfId,
      );
      let formToArchive = {};
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            documents: state[targetId].employeeProfile.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                formToArchive = { ...item };

                if (item.forms.length > 1) {
                  return {
                    ...item,
                    forms: item.forms.filter((form) => form.formId !== action.payload.formId),
                  };
                } else {
                  return {
                    ...item,
                    forms: item.forms.map((form, index) => {
                      if (form.formId === action.payload.formId) {
                        for (let key in item.forms[index].values) {
                          item.forms[index].values[key] = '';
                        }
                        return { ...form, formId: null, attachments: [] };
                      } else return form;
                    }),
                  };
                }
              } else return item;
            }),

            archivedDocuments: isFormExistGF
              ? state[targetId].employeeProfile.archivedDocuments.map((item) => {
                  if (+item.id === +action.payload.gfId) {
                    return {
                      ...item,
                      forms: [...item.forms, action.payload.form],
                    };
                  } else return item;
                })
              : [
                  ...state[targetId].employeeProfile.archivedDocuments,
                  { ...formToArchive, forms: [action.payload.form] },
                ],
          },
        },
      };
    }
    case types.DOCUMENT_FORM_UNARCHIVE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            archivedDocuments: state[targetId].employeeProfile.archivedDocuments.reduce((acc, item) => {
              if (+item.id === +action.payload.gfId) {
                if (item.forms.length > 1) {
                  acc.push({
                    ...item,
                    forms: item.forms.filter((form) => form.formId !== action.payload.formId),
                  });
                }
              } else {
                acc.push(item);
              }
              return acc;
            }, []),

            documents: state[targetId].employeeProfile.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms = [...item.forms, action.payload.form];
                item.forms.forEach((form, index) => {
                  if (form.formId === null) {
                    item.forms.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.DOCUMENT_ATTACHMENT_ADD: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            documents: state[targetId].employeeProfile.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments = [...form.attachments, action.payload.attachments];
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.DOCUMENT_ATTACHMENT_DELETE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            documents: state[targetId].employeeProfile.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments.forEach((attachment, index) => {
                      if (+attachment.id === +action.payload.attachmentId) {
                        form.attachments.splice(index, 1);
                      }
                    });
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.LEGALIZATION_DOCUMENT_ADD: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            legalizationDocuments: state[targetId].employeeProfile.legalizationDocuments.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms = [...item.forms, action.payload.legalizationDocument];
                item.forms.forEach((form, index) => {
                  if (form.formId === null) {
                    item.forms.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.LEGALIZATION_DOCUMENT_UPDATE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            legalizationDocuments: state[targetId].employeeProfile.legalizationDocuments.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.legalizationDocument.formId) {
                    form.values = action.payload.legalizationDocument.values;
                    form.isCurrent = action.payload.legalizationDocument.isCurrent;
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.LEGALIZATION_DOCUMENT_DELETE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            legalizationDocuments: state[targetId].employeeProfile.legalizationDocuments.map((item) => {
              if (+item.id === +action.payload.gfId) {
                if (item.forms.length > 1) {
                  return {
                    ...item,
                    forms: item.forms.filter((form) => form.formId !== action.payload.formId),
                  };
                } else {
                  return {
                    ...item,
                    forms: item.forms.map((form, index) => {
                      if (form.formId === action.payload.formId) {
                        for (let key in item.forms[index].values) {
                          item.forms[index].values[key] = '';
                        }
                        return { ...form, formId: null, attachments: [] };
                      } else return form;
                    }),
                  };
                }
              } else return item;
            }),
          },
        },
      };
    }
    case types.LEGALIZATION_DOCUMENT_ATTACHMENT_ADD: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            legalizationDocuments: state[targetId].employeeProfile.legalizationDocuments.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments = [...form.attachments, action.payload.attachments];
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.LEGALIZATION_DOCUMENT_ATTACHMENT_DELETE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            legalizationDocuments: state[targetId].employeeProfile.legalizationDocuments.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments.forEach((attachment, index) => {
                      if (+attachment.id === +action.payload.attachmentId) {
                        form.attachments.splice(index, 1);
                      }
                    });
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.EXCEL_DOCUMENT_ADD: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            forms: state[targetId].employeeProfile.forms.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms = [...item.forms, action.payload.excelDocument];
                item.forms.forEach((form, index) => {
                  if (form.formId === null) {
                    item.forms.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.EXCEL_DOCUMENT_UPDATE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            forms: state[targetId].employeeProfile.forms.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.excelDocument.formId) {
                    form.values = action.payload.excelDocument.values;
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.EXCEL_FORM_ATTACHMENT_ADD: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            forms: state[targetId].employeeProfile.forms.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments = [...form.attachments, action.payload.attachments];
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.EXCEL_FORM_ATTACHMENT_DELETE: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeProfile: {
            ...state[targetId].employeeProfile,
            forms: state[targetId].employeeProfile.forms.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments.forEach((attachment, index) => {
                      if (+attachment.id === +action.payload.attachmentId) {
                        form.attachments.splice(index, 1);
                      }
                    });
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.EMPLOYEE_REMINDERS_LOAD: {
      const targetId = action.payload.employeeId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          employeeReminders: action.payload.employeeReminders,
        },
      };
    }
    case types.EMPLOYEE_PROFILE_ADD_EVENT:
      const targetId = action.payload.employeeId;
      return state.hasOwnProperty(targetId)
        ? {
            ...state,
            [targetId]: {
              ...state[targetId],
              employeeProfile: {
                ...state[targetId]?.employeeProfile,
                events: state[targetId]?.employeeProfile.events
                  ? [action.payload.event, ...state[targetId].employeeProfile.events]
                  : [action.payload.event],
              },
            },
          }
        : { ...state };
    default:
      return {
        ...state,
      };
  }
};

export default employeeProfileReducer;
