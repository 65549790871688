import { ReactComponent as ClearFiltersIcon } from '../../../../assets/img/icons/clear-filters.svg';

import './clearFilters.scss';

const ClearFilters = ({ setClearFilterParams }) => {
  return (
    <div className="clear-filters__container">
      <div className="clear-filters" onClick={() => setClearFilterParams(true)}>
        clear all
        <ClearFiltersIcon className="clear-filters-icon" />
      </div>
    </div>
  );
};

export default ClearFilters;
