import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import { getEmployeeProfile } from '../../../store/actions/employeeProfile';
import QuickNavUp from '../../Shared/QuickButtonsBlock/QuickNavUp/QuickNavUp';

import '../employeeProfile.scss';
import { getDismissalLabel } from '../../../helpers';
import { Tooltip } from 'antd';
import { ReactComponent as WODIcon } from '../../../assets/img/wodLogo.svg';
import { ReactComponent as SMGIcon } from '../../../assets/img/smgLogo.svg';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import AllLegalizationRequests from '../../LegalizationProfile/LegalizationRequestsHistory/LegalizationRequestsHistory';
import Menu from '../../Common/Menu/Menu';
import DisabledLegalizationRequestOverview from '../../LegalizationProfile/LegalizationRequestOverview/DisabledLegalizationRequestOverview/DisabledLegalizationRequestOverview';
import { getEmployeeDocumentsToUploadForms } from '../../../store/actions/employeeFeedback';
import EmployeeDocument from '../../EmployeeDocuments/Documents/Content/Employee/EmployeeDocuments/EmployeeDocument/EmployeeDocument';
import EmployeeDocuments from '../../LegalizationProfile/LegalizationRequestOverview/DisabledLegalizationRequestOverview/EmployeeDocuments/EmployeeDocuments';

const OtherSpellingSmgWodLinks = ({ internalId, otherNameSpelling }) => {
  return (
    <div className={'links-icons__wrapper'}>
      <a
        className="wodLogo"
        target="_blank"
        rel="noreferrer"
        href={'https://employees-wod.ventionteams.com/employees/' + internalId}
      >
        <Tooltip placement="topLeft" title={'Employee Development'} arrowPointAtCenter={true}>
          <WODIcon className={'links-icon wod-icon'} />
        </Tooltip>
      </a>
      <a
        className="smgLogo"
        target="_blank"
        rel="noreferrer"
        href={'https://smg.ventionteams.com/administrative/employees/' + internalId}
      >
        <Tooltip placement="topLeft" title={'Solution Management Gate'} arrowPointAtCenter={true}>
          <SMGIcon className={'links-icon smg-icon'} />
        </Tooltip>
      </a>
    </div>
  );
};

const DocumentsToSave = ({ employeeId }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [documentsToUpload, setDocumentsToUpload] = useState([]);

  useEffect(() => {
    getEmployeeDocumentsToUploadForms(employeeId)
      .then((forms) => {
        setDocumentsToUpload(forms);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [dispatch, employeeId]);

  return (
    <div>
      {!isLoading && documentsToUpload.length > 0 && (
        <>
          <div className="questionnaire-answer">
            <EmployeeDocuments employeeId={employeeId} documents={documentsToUpload} />
          </div>
        </>
      )}

      {!isLoading && documentsToUpload.length === 0 && <p>No available feedback forms</p>}
      {isLoading && <SpinnerContainer />}
    </div>
  );
};

const EmployeeNameEvents = ({ genInfo, employee, isCandidate, id }) => {
  return (
    <div className="request-top">
      <div className="EmployeeName__wrapper">
        <h3 className="EmployeeName underlined">
          {genInfo.fullName}
          {!employee.isActive && employee.internalId && getDismissalLabel(!employee?.currentEmploymentInfo)}
          {employee.isActive && !employee.internalId && ' (Candidate)'}
        </h3>
        <div className="logo-wrapper">
          {!isCandidate && employee?.internalId && (
            <OtherSpellingSmgWodLinks
              internalId={employee.internalId}
              otherNameSpelling={employee.generalInfo.otherNameSpelling}
              index={id}
            />
          )}
        </div>
      </div>
    </div>
  );
};

const ContentRoutes = ({ employee, isHrManager, isPortalStaff }) => {
  const genInfo = employee?.generalInfo;

  return (
    <div className="employee-profile__content">
      <Switch>
        <Route
          exact
          path="/profile/:id/legalization-request"
          component={() => (
            <DisabledLegalizationRequestOverview
              employeeId={employee?.uuid}
              isHrManager={isHrManager}
              isPortalStaff={isPortalStaff}
            />
          )}
        />
        <Route
          exact
          path="/profile/:id/documents-to-save/"
          component={() => <DocumentsToSave employeeId={employee?.uuid} />}
        />
        <Route
          exact
          path="/profile/:id/legalization-requests"
          component={() => <AllLegalizationRequests employeeId={employee?.uuid} />}
        />

        <Redirect exact from="/profile/:id/" to="/profile/:id/legalization-request" />
      </Switch>
    </div>
  );
};

const sidebarNavigation = [
  {
    label: 'Legalization',
    key: 'legalization',
    children: [
      {
        label: (
          <NavLink to={'./legalization-request'} className="menu-item">
            Request
          </NavLink>
        ),
        key: 'legalizationRequest',
        url: '/legalization-request',
      },
      {
        label: (
          <NavLink to={'./documents-to-save'} className="menu-item">
            Documents to save
          </NavLink>
        ),
        key: 'documentsToSave',
        url: '/documents-to-save',
      },
      {
        label: (
          <NavLink to={'./legalization-requests'} className="menu-item">
            All requests
          </NavLink>
        ),
        key: 'legalizationRequests',
        url: '/legalization-requests',
      },
    ],
  },
];

const SiderMenu = ({ employee, isCandidate, isHrManager }) => {
  return (
    <div className="request-details__menu">
      <Menu sidebarNavigation={sidebarNavigation} />
    </div>
  );
};

const RegularEmployeeProfile = ({ match, isHrManager, isPortalStaff }) => {
  const dispatch = useDispatch();

  const employeeUuid = match.params.id;
  const employee = useSelector((state) => state.employeeProfileReducer[employeeUuid]?.employeeProfile);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getEmployeeProfile(employeeUuid)).then(() => setLoading(false));
  }, [dispatch, employeeUuid]);

  const isProfileDisable = !employee?.isActive && !employee?.currentEmploymentInfo;
  const isCandidate = !employee?.apiUuid && !employee?.domainName && !employee?.internalId;

  return (
    <>
      {!isLoading && employee && (
        <section className="RequestDetails employee-profile">
          {employee?.uuid === employeeUuid && (
            <div className="RequestDetails__wrapper">
              <EmployeeNameEvents
                genInfo={employee?.generalInfo}
                employee={employee}
                isCandidate={isCandidate}
                id={employeeUuid}
              />

              <div className="employee-profile__container">
                <SiderMenu employee={employee} isCandidate={isCandidate} isHrManager={isHrManager} />
                <ContentRoutes
                  employee={employee}
                  isHrManager={isHrManager}
                  isProfileDisable={isProfileDisable}
                  isCandidate={isCandidate}
                />
              </div>
            </div>
          )}
          <QuickNavUp />
        </section>
      )}
      {!isLoading && !employee && <h1 className="no-data">Employee not found!</h1>}
      {isLoading && <SpinnerContainer />}
    </>
  );
};

export default RegularEmployeeProfile;
