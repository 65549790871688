import { useContext, useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { editRelocationRequest } from '../../../store/actions/request';
import classNames from 'classnames';

import { getFormElement } from '../../Shared/Form/FormElements';
import { IsCaseDisabledContext } from '../RequestDetails';
import CompensationCalculationModal from './CompensationCalculationModal/CompensationCalculationModal';
import './compensationRequest.scss';

const CompensationRequested = ({ requestId, compensationRequestedInfo, linkedRequests, paidInAdvance }) => {
  const dispatch = useDispatch();

  const [isModalVisible, setModalVisible] = useState(false);
  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const onCompensationRequested = (value, formikProps, currency) => {
    dispatch(
      editRelocationRequest(requestId, {
        currency: currency,
        compensationAmount: formikProps.values.salaryAfterRelocation,
      }),
    );
  };
  const formIsCompensationRequested = [
    {
      fieldName: 'currency',
      type: 'Currency',
      label: 'Compensation',
      options: [
        { text: '\u0024', value: 'usd' },
        { text: '\u20ac', value: 'eur' },
      ],
      disabled: true,
      updateselectvalueroot: onCompensationRequested,
    },
  ];

  return (
    <div className="compensation-form__container">
      <div className="compensation-form-container final-checkbox-wrapper">
        <Formik
          enableReinitialize
          initialValues={{
            currency: compensationRequestedInfo.currency ? compensationRequestedInfo.currency : 'usd',
            salaryAfterRelocation: compensationRequestedInfo.compensationAmount
              ? paidInAdvance
                ? compensationRequestedInfo.compensationAmount + ' (Paid in advance)'
                : compensationRequestedInfo.compensationAmount
              : '',
          }}
        >
          {(formikProps) => (
            <Form>
              {formIsCompensationRequested.map((field) => (
                <div key={field.fieldName}>
                  {getFormElement(
                    field.type,
                    field,
                    formikProps,
                    compensationRequestedInfo.isCompensationDisabled || isCaseDisabled,
                  )}
                </div>
              ))}
            </Form>
          )}
        </Formik>
      </div>
      {compensationRequestedInfo.isCompensationDisabled && (
        <p className="compensation-notify">
          Compensation is calculated in the case of{' '}
          {linkedRequests.find((relative) => !relative.isCompensationDisabled)?.employee?.generalInfo?.fullName}
        </p>
      )}
      <button
        type="button"
        className={classNames(
          'button btn-form main-btn btn-back',
          compensationRequestedInfo.isCompensationDisabled || isCaseDisabled ? 'btn-disabled' : '',
        )}
        onClick={() => setModalVisible(true)}
        disabled={compensationRequestedInfo.isCompensationDisabled || isCaseDisabled}
      >
        Calculate compensation
      </button>
      {isModalVisible && (
        <CompensationCalculationModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          requestId={requestId}
          linkedRequests={linkedRequests}
        />
      )}
    </div>
  );
};

export default CompensationRequested;
