import { types } from '../actions/types';

export const initialState = {
  genericForm: {
    name: '',
    description: '',
    forms: {},
    fieldset: [],
    isCanBePlural: false,
    isCanBeArchived: false,
    isWithAttachments: false,
    countries: [],
  },
};

const genericFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GENERIC_FORM_LOADED:
      return {
        genericForm: action.payload.genericForm,
      };
    case types.GENERIC_FORM_UPDATED:
      return {
        ...state,
        genericForm: { ...action.payload.genericForm },
      };
    case types.GENERIC_FORM_ERROR:
      return {
        genericForm: action.payload.genericForm,
      };
    default:
      return {
        ...state,
      };
  }
};

export default genericFormReducer;
