import { Switch, Route, Redirect } from 'react-router-dom';

import GeneralInfo from '../../GeneralInfo/GeneralInfo';
import ContactInfo from '../../ContactInfo/ContactInfo';
import EmploymentInfo from '../../EmploymentInfo/EmploymentInfo';
import EducationInfo from '../../EducationInfo/EducationInfo';
import RelatedProfiles from '../../RelatedProfiles/RelatedProfiles';
import Documents from '../../RelativeDocumentsTabs/RelatedDocuments/Documents';
import RelativeForms from '../../RelativeDocumentsTabs/RelativeForms/RelativeForms';
import ArchivedDocuments from '../../ArchivedDocuments/ArchivedDocuments';
import VacAppointment from '../../VacAppointment/VacAppointment';
import TransportationRelatedDetails from '../../TransportationRelatedDetails/TransportationRelatedDetails';
import RelativeHistory from '../../History/RelativeHistory/RelativeHistory';
import ForeignPoliceAppointment from '../../ForeignPoliceAppointment/ForeignPoliceAppointment';

const ContentRoutes = ({ relatedInfo, request, requestId, relativeReqId, isRelativeCaseDisabled }) => {
  const employeeUuid = request.employee.uuid;
  const relativeId = relatedInfo.relative.id;

  const relInfo = request.relativeRequests;
  const petInfo = request.petRequests;

  return (
    <div className="relative-request-details__content">
      <Switch>
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/general-information"
          component={() => (
            <>
              <GeneralInfo
                id={employeeUuid}
                rel_id={relativeId}
                rel_req_id={relativeReqId}
                relationType={
                  relatedInfo?.relative.relationType
                    ? relatedInfo?.relative.relationType[0].toUpperCase() + relatedInfo?.relative.relationType.slice(1)
                    : ''
                }
                fullNameRu={
                  relatedInfo?.relative.generalInfo.fullNameRu.trim()
                    ? relatedInfo?.relative.generalInfo.fullNameRu
                    : ''
                }
                otherNameSpelling={relatedInfo?.relative.generalInfo.otherNameSpelling || ''}
                birthDate={relatedInfo?.relative.generalInfo.birthDate || ''}
                maidenName={relatedInfo?.relative.generalInfo.maidenName || ''}
                nationalityId={relatedInfo?.relative.generalInfo?.nationality?.id || undefined}
                citizenshipId={relatedInfo?.relative.generalInfo?.citizenship?.id || undefined}
                isCaseDisabled={isRelativeCaseDisabled}
                firstName={relatedInfo?.relative.generalInfo.firstName || ''}
                lastName={relatedInfo?.relative.generalInfo.lastName || ''}
              />
            </>
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/contact-information"
          component={() => (
            <ContactInfo
              id={employeeUuid}
              rel_id={relativeId}
              rel_req_id={relativeReqId}
              phones={relatedInfo?.relative.contactInfo?.phones || ['']}
              address={relatedInfo?.relative.contactInfo?.address || ''}
              isCaseDisabled={isRelativeCaseDisabled}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/employment-information"
          component={() => (
            <EmploymentInfo
              id={employeeUuid}
              rel_id={relativeId}
              rel_req_id={relativeReqId}
              company={relatedInfo?.relative.employmentInfo?.company || ''}
              position={relatedInfo?.relative.employmentInfo?.position || ''}
              phoneNumbers={relatedInfo?.relative.employmentInfo?.phoneNumbers || ['']}
              address={relatedInfo?.relative.employmentInfo?.address || ''}
              isCaseDisabled={isRelativeCaseDisabled}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/education-information"
          component={() => (
            <EducationInfo
              id={employeeUuid}
              rel_id={relativeId}
              rel_req_id={relativeReqId}
              company={relatedInfo?.relative.employmentInfo?.company || ''}
              position={relatedInfo?.relative.employmentInfo?.position || ''}
              address={relatedInfo?.relative.employmentInfo?.address || ''}
              isCaseDisabled={isRelativeCaseDisabled}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/related-profiles"
          component={() => (
            <RelatedProfiles
              id={requestId}
              rel_req_id={relativeReqId}
              relInfo={relInfo}
              petInfo={petInfo}
              genInfo={request}
              linkedRequests={request.linkedRequests}
              type="related"
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/documents"
          component={() => (
            <Documents
              documents={relatedInfo.documents}
              requestId={requestId}
              relatedReqId={relativeReqId}
              employeeId={employeeUuid}
              relativeId={relativeId}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/forms"
          component={() => (
            <RelativeForms
              documents={relatedInfo.forms}
              requestId={requestId}
              relatedReqId={relativeReqId}
              employeeId={employeeUuid}
              relativeId={relativeId}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/archived-documents"
          component={() => (
            <ArchivedDocuments
              archivedDocuments={relatedInfo.archivedDocuments}
              isCaseDisabled={isRelativeCaseDisabled}
              employeeId={employeeUuid}
              relatedReqId={relativeReqId}
              type="related"
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/vac-appointment"
          component={() => (
            <VacAppointment
              requestId={requestId}
              relatedReqId={relativeReqId}
              vacAppointment={relatedInfo.vacAppointment}
              relationType={relatedInfo?.relative.relationType}
              isDisplaySendVacDetails={relatedInfo?.isDisplaySendVacDetails}
              isCaseDisabled={isRelativeCaseDisabled}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/foreign-police"
          component={() => (
            <ForeignPoliceAppointment
              requestId={requestId}
              relatedReqId={relativeReqId}
              relationType={relatedInfo?.relative.relationType}
              isDisplaySendVacDetails={relatedInfo?.isDisplaySendVacDetails}
              vacInfo={relatedInfo?.foreignPoliceAppointments || []}
              isCaseDisabled={isRelativeCaseDisabled}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/transportation-details"
          component={() => (
            <TransportationRelatedDetails
              requestId={requestId}
              relatedReqId={relativeReqId}
              transportationDetailsInfo={relatedInfo.transportationDetails}
              isCaseDisabled={isRelativeCaseDisabled}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relative-requests/:rel_req_id/history"
          component={() => <RelativeHistory requestId={requestId} relativeReqId={relativeReqId} />}
        />
        <Redirect
          exact
          from="/request-details/:id/relative-requests/:rel_req_id"
          to="/request-details/:id/relative-requests/:rel_req_id/general-information"
        />
      </Switch>
    </div>
  );
};

export default ContentRoutes;
