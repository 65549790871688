import { types } from '../actions/types';

const initialState = {
  legalizationRequestsData: [],
  legalizationRequestsColumns: [],
  next: null,
  previous: null,
  count: 0,
};

const legalizationRequestListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LEGALIZATION_REQUEST_LIST_LOAD:
      return {
        ...state,
        legalizationRequestsData: action.payload.legalizationRequestsData,
        legalizationRequestsColumns: action.payload.legalizationRequestsColumns,
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
        page: action.payload.page,
      };
    default:
      return {
        ...state,
      };
  }
};

export default legalizationRequestListReducer;
