import { getAppUserManager } from '../auth/userManager';

let source = null;

const createEventSource = async (groupName) => {
  const userManager = getAppUserManager();
  const token = await userManager.getUser().then((user) => {
    return user?.access_token;
  });

  return new EventSource(`/realtime-events/?group_name=${groupName}&token=${token}`);
};

const getEventSource = (groupName) => {
  if (!source) {
    source = createEventSource(groupName);
  }
  return source;
};

export default getEventSource;
