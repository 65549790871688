import { useState } from 'react';

import LegalizationForm from './LegalizationForm/LegalizationForm';
import SelectFormsToDisplay from './SelectFormsToDisplay/SelectFormsToDisplay';
import { editCaseDocumentsToDisplay } from '../../../../store/actions/request';
import { editEmployeeDocumentsToDisplay } from '../../../../store/actions/employeeProfile';
import './legalizationDocuments.scss';

const LegalizationDocuments = (props) => {
  const { documents, employeeId, requestId, documentsToDisplay } = props;

  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <div className="legalization-forms__container">
        <button className="select-documents button btn-form main-btn btn-back" onClick={() => setModalVisible(true)}>
          Select forms to display
        </button>
        {documents?.length > 0 &&
          documents.map((document, index) => {
            return <LegalizationForm key={index} employeeId={employeeId} document={document} />;
          })}
        {!documents?.length && <p className="no-documents">No available forms</p>}
      </div>
      {isModalVisible && (
        <SelectFormsToDisplay
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          objectId={requestId ? requestId : employeeId}
          editDocumentsToDisplayFunction={requestId ? editCaseDocumentsToDisplay : editEmployeeDocumentsToDisplay}
          documentsToDisplay={documentsToDisplay}
          // country={country}
        />
      )}
    </>
  );
};

export default LegalizationDocuments;
