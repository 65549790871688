import * as Yup from 'yup';
import { getTypeSchema } from '../../../helpers';

export const useGetInitialValues = (feedbackForms) => {
  return {
    feedback: feedbackForms.map((item) => {
      const form = item.forms[0];
      form.fields.forEach((field) => {
        if (
          field.disabled &&
          (form.values[field.fieldName] === '' ||
            form.values[field.fieldName] === null ||
            form.values[field.fieldName] === 0 ||
            form.values[field.fieldName]?.length === 0)
        ) {
          delete form.values[field.fieldName];
        }
      });
      return {
        ...form.values,
        generic_form_id: item.id,
        form_id: form.formId,
      };
    }),
  };
};

export const useGetValidationSchema = (feedbackForms) => {
  const formsValidation = feedbackForms.reduce((acc, currentForm) => {
    const validation = {};
    currentForm.forms[0].fields
      .filter((item) => !item.disabled)
      .forEach((item) => {
        if (item.required) {
          const element = item.element === 'CustomElement' ? item.key : item.element;
          validation[item.fieldName] = getTypeSchema(element);
        }
      });
    return { ...acc, [currentForm.id]: validation };
  }, {});

  const validationSchema = Yup.object().shape({
    feedback: Yup.array().of(
      Yup.lazy((item) => {
        const { generic_form_id } = item;

        return Yup.object({
          ...formsValidation[generic_form_id],
        });
      }),
    ),
  });

  return validationSchema;
};
