import { COLORS } from '../../../Shared/Constants/Constants';

export const title = 'How many people are going to move within the case';

export const radioGroupOptions = [
  {
    value: 'country',
    name: 'By country',
  },
  {
    value: 'city',
    name: 'By city',
  },
  {
    value: 'gm_assigned',
    name: 'GMG assigned',
  },
  {
    value: 'month',
    name: 'By month',
  },
];

export const filters = {
  radioInitialValue: 'country',
  analyticsType: 'avg_people_in_case',
};

export const options = {
  xaxis: {
    type: 'category',
    labels: {
      minHeight: 120,
      maxHeight: 120,
      rotate: -45,
      rotateAlways: true,
      hideOverlappingLabels: false,
      style: {
        fontSize: '14px',
        fontFamily: 'IBM Plex Sans',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
    },
    tickPlacement: 'on',
  },
  plotOptions: {
    bar: {
      distributed: true,
      dataLabels: {
        position: 'top',
        orientation: 'vertical',
        fontSize: '14px',
        fontFamily: 'IBM Plex Sans',
      },
      columnWidth: '100%',
      rangeBarOverlap: false,
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -40,
    offsetX: -4,
    textAnchor: 'middle',
    style: {
      padding: {
        top: 30,
      },
      fontSize: '14px',
      fontWeight: '600',
    },
  },
};
