import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEmloyeesForCreatingCase } from '../../../../store/actions/searchEmployees';

import './employeeSearch.scss';

const EmployeeSearch = (props) => {
  const { formikProps, isHiddenOverflow, filters } = props;

  const dispatch = useDispatch();
  const searchEmployeesList = useSelector((state) => state.searchEmployeesCreateCase.searchEmployeesList); // TODO: rewrite reducer

  const [searchHide, setSearchHide] = useState(true);
  const [inputValue, setInputValue] = useState('');

  const searchResult = (e, blur) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2 && !blur) {
      dispatch(
        getEmloyeesForCreatingCase({
          ...filters,
          search: searchValue,
        }),
      );
      setSearchHide(false);
    } else {
      setSearchHide(true);
    }
  };

  const handleClick = (uuid, fullName) => {
    formikProps.setFieldValue(`employeeId`, uuid);

    setInputValue(fullName);
    setSearchHide(true);
  };

  const onChange = (e) => {
    if (!inputValue || formikProps.getFieldProps('employeeId').value) {
      formikProps.setFieldValue(`employeeId`, '');
    }

    setInputValue(e.target.value);
    searchResult(e);
  };

  useEffect(() => {
    if (isHiddenOverflow) document.body.style.overflow = 'hidden';
  }, [inputValue, searchHide, formikProps]);

  useEffect(() => {
    return () => {
      if (isHiddenOverflow) document.body.style.overflow = 'unset';
    };
  });

  return (
    <div className="search-employees">
      <div className="search-form">
        <p className="search-form__label">Search for name</p>
        <span className={'search-form__container'}>
          <input
            type="text"
            autoComplete="off"
            value={inputValue}
            onChange={onChange}
            name="search"
            placeholder=""
            className="search-form__input"
          />
        </span>
      </div>
      {formikProps.errors['employeeId'] && searchHide && (
        <div className="error">{formikProps.errors['employeeId']}</div>
      )}
      <div className={`search-results__container${searchHide ? 'hide' : ''}`}>
        <p className="label-empty"></p>
        <div className="search-results__position">
          <div className={`search-results__wrapper ${searchHide ? 'hide' : ''}`}>
            <ul className="search-results__items">
              {searchEmployeesList.length > 0 ? (
                searchEmployeesList.map((searchItem) => {
                  return (
                    <li
                      className="search-results__item"
                      key={searchItem.internalId}
                      onClick={() => handleClick(searchItem.uuid, searchItem.generalInfo.fullName)}
                    >
                      <p>{`${searchItem.generalInfo.fullName} (${searchItem.internalId})`}</p>
                    </li>
                  );
                })
              ) : (
                <li className="search-results__item no-content">No data to display</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeSearch;
