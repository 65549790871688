import React, { useState, useRef, useLayoutEffect } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';

import { getFormElement } from '../../../Shared/Form/FormElements';
import SlideOutIcons from '../../../Shared/SlideOutIcons/SlideOutIcons';
import DocumentAccordion from '../../../Common/DocumentAccordion/DocumentAccordion';
import { SubmitButton } from '../../../Shared/Form/Buttons/Buttons';
import { editAdditionalFeedbackInformation, getFeedbackFormsInCase } from '../../../../store/actions/feedbackForm';
import { additionalFeedbackInformationSchema } from './formSchema';

import './feedbackForm.scss';

const FeedbackForm = ({ requestId, document }) => {
  const targetRef = useRef();
  const dispatch = useDispatch();

  const [titleLength, setTitleLength] = useState({});

  const setTitleWidth = () => {
    if (targetRef.current) {
      setTitleLength({
        width: targetRef.current.offsetWidth + 50,
      });
    }
  };

  const schema = document.forms[0];

  const formSchemaWithoutEmptDisFields = schema.fields.filter(
    (item) =>
      (item.disabled &&
        schema.values[item.fieldName] !== '' &&
        schema.values[item.fieldName]?.length !== 0 &&
        typeof schema.values[item.fieldName] !== 'undefined' &&
        schema.values[item.fieldName] !== null) ||
      !item.disabled,
  );

  const initialValues = {
    ...document.forms[0].values,
    comment: document.forms[0].comment,
    status: document.forms[0].status,
  };

  useLayoutEffect(() => {
    setTitleWidth();
  }, []);

  const isFormDisabled = true;

  const onSubmit = (values, { setSubmitting }) => {
    const valuesInfo = { comment: values.comment, status: values.status };

    dispatch(editAdditionalFeedbackInformation(requestId, document.forms[0].formId, valuesInfo)).then(() => {
      dispatch(getFeedbackFormsInCase(requestId));
    });
    setSubmitting(false);
  };

  return (
    <div className="feedback-form__container after-relocation-steps-form ">
      <DocumentAccordion documentName={document.name} documentId={document.slug} targetRef={targetRef} type="employee">
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
          {(formikProps) => (
            <Form className="feedback-form additional-steps-form">
              {formSchemaWithoutEmptDisFields.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.element, field, formikProps, isFormDisabled)}</div>
              ))}
              {document.forms[0].formId &&
                additionalFeedbackInformationSchema.map((field) => (
                  <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                ))}
              <SubmitButton title="Submit" formikProps={formikProps} />
              <SlideOutIcons
                document={document}
                titleLength={titleLength}
                additionalFlag={true}
                withEditFormInConstructor={{ pathName: 'feedback' }}
              />
            </Form>
          )}
        </Formik>
      </DocumentAccordion>
    </div>
  );
};

export default FeedbackForm;
