import dayjs from 'dayjs';

import { columns } from './tableProperties';
import CustomTable from '../../Shared/Table/CustomTable';
import ContentWrapper from '../../Shared/Table/ContentWrapperMobileView/ContentWrapperMobileView';
import { defaultDateFormat } from '../../../constants';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import './History.scss';
import { useSelector } from 'react-redux';

const History = (props) => {
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const { historyList, getTableDataCallback, count, className, name } = props;

  const historyDetails = [];

  historyList.forEach((history) => {
    if (history.actionType === 'update') {
      if (history.changes.previous === '') {
        historyDetails.push(`${history.objectName + ': added ' + history.changes.current}`);
      } else if (history.changes.current === '') {
        historyDetails.push(`${history.objectName + ': deleted ' + history.changes.previous}`);
      } else {
        historyDetails.push(
          `${history.objectName + ' updated from ' + history.changes.previous + ' to ' + history.changes.current}`,
        );
      }
    } else if (history.actionType === 'create') {
      historyDetails.push(`${history.objectName + ' created'}`);
    } else if (history.actionType === 'moved') {
      historyDetails.push(`${history.objectName}`);
    } else if (history.actionType === 'sent_mail') {
      historyDetails.push(`${history.objectName}`);
    } else if (history.actionType === 'delete') {
      historyDetails.push(`${history.objectName + ' deleted'}`);
    } else {
      historyDetails.push(`${history.objectName}`);
    }
  });

  const historyData = historyList.map((history) => [
    dayjs(new Date(history.actionTime)).format(defaultDateFormat),
    dayjs(new Date(history.actionTime)).format('LT (Z UTC)'),
    history.actor,
  ]);

  const dataArray = [historyData, historyDetails].reduce((a, b) => a.map((v, i) => [...v, b[i]]));

  const rows = dataArray.map((row, index) => ({
    key: index,
    date: <ContentWrapper dataLabel={'Date'}>{dayjs(row[0]).format(displayDateFormat)}</ContentWrapper>,
    time: <ContentWrapper dataLabel={'Time'}>{row[1]}</ContentWrapper>,
    employee: <ContentWrapper dataLabel={'Employee'}>{row[2]}</ContentWrapper>,
    changes: <ContentWrapper dataLabel={'Changes made'}>{row[3]}</ContentWrapper>,
  }));

  return (
    <div className={className}>
      <CustomTable
        tableKey={name}
        data={rows}
        columns={columns}
        count={count}
        getTableDataCallback={getTableDataCallback}
        mobileView={true}
        pageSize={20}
      />
    </div>
  );
};

export default History;
