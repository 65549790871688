import { Tooltip } from 'antd';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

import DeleteFormIcon from '../../../../assets/img/icons/Сlose_square_line.svg';
import AddAttachment from '../../../../assets/img/icons/Plus_round_line.svg';
import './buttons.scss';

export const SubmitButton = (props) => {
  const { title, formikProps, canHaveDisplayHorizontal, forceDisabled, isDocumentsValid, ...rest } = props;

  const { isSubmitting } = useFormikContext();

  const disabled = formikProps ? !formikProps.dirty : isSubmitting;

  return (
    <button
      type="submit"
      className={classNames(
        'button btn-form main-btn btn-back submit-btn',
        disabled || forceDisabled ? 'submit-btn-disabled' : '',
        isSubmitting && !forceDisabled ? 'isSubmitting' : '',
      )}
      {...rest}
      disabled={disabled || isSubmitting || forceDisabled}
    >
      {title}
    </button>
  );
};

export const ResetButton = (props) => {
  const { title, formikProps, canHaveDisplayHorizontal, ...rest } = props;

  return (
    <button type="reset" className="button btn-form main-btn" {...rest}>
      {title}
    </button>
  );
};

export const AddAttachmentButton = (props) => {
  const { handleClick } = props;
  return (
    <div className="add-attachment-button">
      <Tooltip placement={'top'} title={'Add scans'} mouseEnterDelay={0} mouseLeaveDelay={0}>
        <div className="tooltip-button">
          <img className="add-attachment" src={AddAttachment} alt="add attachment" onClick={handleClick} />
        </div>
      </Tooltip>
    </div>
  );
};

export const CancelButton = (props) => {
  const { remove, formIndex } = props;
  return (
    <div className="cancel-button">
      <Tooltip placement={'top'} title={'Cancel'} mouseEnterDelay={0} mouseLeaveDelay={0}>
        <div className="tooltip-button">
          <img className="delete-icon" src={DeleteFormIcon} alt="delete" onClick={() => remove(formIndex)} />
        </div>
      </Tooltip>
    </div>
  );
};
