import { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getRelocatedEmployees } from '../../store/actions/relocatedEmployees';
import QuickNavUp from '../Shared/QuickButtonsBlock/QuickNavUp/QuickNavUp';

import CustomTable from '../Shared/Table/CustomTable';
import { filterMapping, getColumnsKeys, sortMapping, dateRangeFilterMapping } from './tableProperties/tableProperties';
import { useGetColumnsRows } from './tableProperties/useGetColumnsRows';
import ColumnsFilter from '../Shared/Table/ColumnsFilter/ColumnsFilter';
import ClearFilters from '../Shared/Table/ClearFilters/ClearFilters';
import SearchName from '../Shared/Table/SearchName/SearchName';
import GenerationExcel from './generationExcel/GenerationExcel';

import './RelocatedEmployees.scss';

const RelocatedEmployees = () => {
  const paramsFromLocalStorage = JSON.parse(sessionStorage.getItem('relocatedEmplFiters'));
  const selectedCountry = paramsFromLocalStorage?.filtering?.country
    ? paramsFromLocalStorage?.filtering?.country[0]
    : '';

  const columnsKeys = useMemo(() => {
    return getColumnsKeys(selectedCountry);
  }, [selectedCountry]);

  const { requestRelocatedEmployees, count, page, generationExcelRequestIds, allCases, exludeIds } = useSelector(
    (state) => state.requestRelocatedEmployees,
  );

  const [visibleColumns, setVisibleColumns] = useState(columnsKeys); //paramsFromLocalStorage?.columnsFilter ??
  const [isClearFilterParams, setClearFilterParams] = useState(false);
  const [searchName, setSearchName] = useState(paramsFromLocalStorage?.search || '');

  useEffect(() => {
    setVisibleColumns(columnsKeys);
  }, [columnsKeys]);

  const clearAllInnerFilters = () => {
    setClearFilterParams(false);
    setSearchName('');
  };

  useEffect(() => {
    const columnsFilterFromStorage = paramsFromLocalStorage?.columnsFilter;

    if (columnsFilterFromStorage && columnsFilterFromStorage.hasOwnProperty(selectedCountry)) {
      setVisibleColumns(columnsFilterFromStorage[selectedCountry] || columnsKeys);
      return;
    }
    setVisibleColumns(columnsKeys);
  }, [columnsKeys, selectedCountry]);

  const [columns, extraColumns, rows] = useGetColumnsRows({
    relocatedEmployees: requestRelocatedEmployees?.data || [],
    page,
    selectedCountry,
    generationExcelRequestIds,
    allCases,
    exludeIds,
    extraColumns: requestRelocatedEmployees?.columns || [],
  });

  const filteredColumns = columns.filter(
    (column) => (columnsKeys.includes(column.key) && visibleColumns.includes(column.key)) || column.key === 'fullName',
  );

  return (
    <section className="relocated-employees">
      <div className="relocated-employees__header-container">
        <h3 className="underlined panel-top-row">Relocated employees</h3>
        <GenerationExcel
          generationExcelRequestIds={generationExcelRequestIds}
          allCases={allCases}
          exludeIds={exludeIds}
        />
      </div>
      {page && (
        <div className="relocated-employees__additional-filters">
          <div className="relocated-employees__additional-filters-left-group">
            <ColumnsFilter
              tableKey={'relocatedEmplFiters'}
              columns={columns.filter((column) => columnsKeys.includes(column.key) && column.key !== 'fullName')}
              visibleColumns={visibleColumns}
              setVisibleColumns={setVisibleColumns}
              selectedCountry={selectedCountry}
            />
            <ClearFilters setClearFilterParams={setClearFilterParams} />
          </div>
          <SearchName tableKey={'relocatedEmplFiters'} searchName={searchName} setSearchName={setSearchName} />
        </div>
      )}
      <CustomTable
        tableKey={'relocatedEmplFiters'}
        data={rows}
        columns={[...filteredColumns, ...extraColumns]}
        count={count}
        sortMapping={sortMapping}
        filterMapping={filterMapping || []}
        dateRangeFilterMapping={dateRangeFilterMapping || []}
        searchParams={searchName}
        getTableDataCallback={getRelocatedEmployees}
        paramsFromLocalStorage={paramsFromLocalStorage}
        isClearFilterParams={isClearFilterParams}
        clearAllInnerFilters={clearAllInnerFilters}
        mobileView={true}
        pageSize={50}
      />
      <QuickNavUp />
    </section>
  );
};

export default RelocatedEmployees;
