import { useEffect } from 'react';

import { getAppUserManager } from '../userManager';

const getOidcState = () => {
  const redirectPathParam = 'redirectPath=';
  const queryString = window.location.search;
  const redirectPathParamStartIndex = queryString.indexOf(redirectPathParam);
  if (redirectPathParamStartIndex < 0) {
    return '/';
  }
  const redirectPathParamEndIndex = queryString.indexOf('&', redirectPathParamStartIndex);
  const redirectPath = decodeURIComponent(
    queryString.slice(
      redirectPathParamStartIndex + redirectPathParam.length,
      redirectPathParamEndIndex >= 0 ? redirectPathParamEndIndex : undefined,
    ),
  );

  return redirectPath;
};

const SignInRedirect = () => {
  useEffect(() => {
    const userManager = getAppUserManager();
    userManager.signinRedirect({ state: getOidcState() });
  }, []);

  return <p>Signing in...</p>;
};

export default SignInRedirect;
