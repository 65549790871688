import React from 'react';
import classNames from 'classnames';

const FilterToggle = ({ toggleParam, setToggle, count }) => {
  const handleChangeToggleFilter = (newValue) => {
    sessionStorage.setItem(
      'RemindersDashboard',
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem('RemindersDashboard')),
        toggle: newValue,
      }),
    );

    setToggle(newValue);
  };

  return (
    <div className="reminders-toggle-filters">
      <button
        className={classNames('filter-tab', !toggleParam && 'filter-tab-active')}
        onClick={(e) => {
          handleChangeToggleFilter();
        }}
      >
        All {!toggleParam && `(${count ? count : 0})`}
      </button>

      <button
        className={classNames('filter-tab', toggleParam === 'cases' && 'filter-tab-active')}
        onClick={() => handleChangeToggleFilter('cases')}
      >
        Cases {toggleParam === 'cases' && `(${count ? count : 0})`}
      </button>
      <button
        className={classNames('filter-tab', toggleParam === 'profiles' && 'filter-tab-active')}
        onClick={() => handleChangeToggleFilter('profiles')}
      >
        Profiles {toggleParam === 'profiles' && `(${count ? count : 0})`}
      </button>
    </div>
  );
};

export default FilterToggle;
