import { ReactComponent as CalendarIcon } from '../../../../../assets/img/icons/calendar.svg';
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';

import './dateRangeFilterView.scss';
import { useSelector } from 'react-redux';
import { defaultDateFormat } from '../../../../../constants';

const { RangePicker } = DatePicker;

const DateRangeFilterView = ({ value, onChange, disabled, clearFilters, confirm }) => {
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  return (
    <>
      <div className="date-range-filter__range-picker">
        <RangePicker
          value={value && [dayjs(value?.after), dayjs(value?.before)]}
          onChange={(values) => onChange(values)}
          format={displayDateFormat}
          suffixIcon={<CalendarIcon />}
        />
      </div>
      <div className="date-range-filter__buttons-container">
        <Button
          disabled={disabled}
          type={'link'}
          size="small"
          onClick={() => {
            clearFilters && clearFilters();
          }}
        >
          Reset
        </Button>

        <Button
          type="primary"
          onClick={() => {
            confirm();
          }}
          size="small"
        >
          OK
        </Button>
      </div>
    </>
  );
};

export default DateRangeFilterView;
