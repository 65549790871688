import SelectDocumentsForm from './SelectDocumentsForm/SelectDocumentsForm';
import '../selectDocumentsModal.scss';

const EmployeeSelectDocuments = (props) => {
  const { documentsToDisplay, employeeForms, setModalVisible, setStep, formikProps } = props;

  const formSchema = documentsToDisplay.map((item) => {
    return { fieldName: +item.id, type: 'CheckboxReverse', label: item.name };
  });

  const formSchemaForForms = employeeForms.map((item) => {
    return { fieldName: +item.id, type: 'CheckboxReverse', label: item.name };
  });

  const onNext = () => {
    setStep(2);
  };

  return (
    <>
      <SelectDocumentsForm
        setModalVisible={setModalVisible}
        onNext={onNext}
        formSchema={formSchema}
        formSchemaForForms={formSchemaForForms}
        formikProps={formikProps}
      />
    </>
  );
};

export default EmployeeSelectDocuments;
