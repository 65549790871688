import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

import Modal from '../../../Shared/Modal/Modal';

import './feedbackStatusModal.scss';

const FeedbackStatusModal = ({ isModalVisible, setIsModalVisible }) => {
  const history = useHistory();

  const handleCloseModal = () => {
    setIsModalVisible((prev) => ({ ...prev, isVisible: false }));
    if (isModalVisible.isSuccess) {
      history.push(`/`);
    }
  };

  const message = isModalVisible.isSuccess
    ? 'Thank you! Your feedback has been sent to the Global Mobility Team. We will reach out to you, should we have any questions.'
    : isModalVisible.error
    ? parse(isModalVisible.error)
    : 'Sorry, something went wrong during sending feedback. Try to send feedback again';

  const title = isModalVisible.isSuccess ? 'Thank you' : 'Error';

  return (
    <div className="send-feedback__status">
      <Modal isVisible={isModalVisible.isVisible} setIsVisible={handleCloseModal} size="l">
        <div className="modal-message">
          <h4 className="underlined panel-top-row">{title}</h4>
          <div className={'message'}>{message}</div>
          <button type="button" className="button btn-form main-btn btn-back ok-btn" onClick={handleCloseModal}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default FeedbackStatusModal;
