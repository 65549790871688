import actionCreators from './actionCreators';
import axios from '../../axios';
import queryString from 'query-string';

export const getRelocatedEmployees = (page, filterParams, params) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/relocated-employees/`, {
        params: { page: page, ...params, ...filterParams },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.relocatedEmployeesLoaded(response.data, page));
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.relocatedEmployeesError());
        throw error;
      });
  };
};

export const relocatedEmployeesExportExcel = (data, filterParams, params) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .post('/api/v1/relocation-requests/relocated-employees/export', data, {
        params: { ...params, ...filterParams },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const fileName =
          response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '') || `${Date.now()}.xlsx`;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        dispatch(actionCreators.relocatedEmployeesExcelCheckboxesRemove());
        return 1;
      })
      .catch(async (error) => {
        toast.type = 'error';
        const responseData = await error.response?.data;
        const responseMessage = responseData === 'Internal server error' ? responseData : responseData.text();
        toast.errorMessage = await responseMessage;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
