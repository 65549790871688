import actionCreators from './actionCreators';
import axios from '../../axios';
import queryString from 'query-string';

export const getPetProfile = (id, rel_req_id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/relocation-requests/${id}/pet-requests/${rel_req_id}/`);
    dispatch(actionCreators.petsInfoLoaded(response.data, rel_req_id));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const editGeneralInfoPetsNotes = (noteId, info, petReqId) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/relocation-requests/notes/${noteId}/`, info);
    dispatch(actionCreators.genaralPetProfileNotesUpdate(response.data, petReqId));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const addGeneralPetProfileNotes = (req_id, pet_req_id, info) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/relocation-requests/${req_id}/pet-requests/${pet_req_id}/notes/`, info);
    dispatch(actionCreators.genaralPetProfileNotesAdd(response.data, pet_req_id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const getPetRelocationHistory = (requestId, petReqId, page) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/${requestId}/pet-requests/${petReqId}/history/`, {
        params: { page: page },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.petRequestHistoryLoaded(response.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.petRequestHistoryError());
      });
  };
};
