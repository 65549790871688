import React, { useState } from 'react';
import { Tooltip } from 'antd';

import Clip from '../../../../assets/img/icons/Vector.svg';
import DocumentModal from './DocumentModal/DocumentModal';
import './attachDocuments.scss';

const AttachDocuments = ({ employeeId, requestId, documentTypeOptions, page }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <Tooltip title={'Attach document'} mouseEnterDelay={0} mouseLeaveDelay={0} zIndex={10}>
        <img
          className="clip-img"
          style={{ height: 17 }}
          src={Clip}
          alt="attach documents"
          onClick={() => setModalVisible(true)}
        />
      </Tooltip>

      {isModalVisible && (
        <DocumentModal
          setModalVisible={setModalVisible}
          isModalVisible={isModalVisible}
          employeeId={employeeId}
          requestId={requestId}
          documentTypeOptions={documentTypeOptions}
          page={page}
        />
      )}
    </>
  );
};

export default AttachDocuments;
