export const formSchema = [
  {
    fieldName: 'relationType',
    type: 'CustomSelect',
    label: 'Role',
    options: [
      { text: 'Spouse', value: 'spouse' },
      { text: 'Child', value: 'child' },
    ],
    required: true,
  },
  {
    fieldName: 'firstName',
    type: 'InputText',
    label: 'First name',
    required: true,
  },
  {
    fieldName: 'lastName',
    type: 'InputText',
    label: 'Last name',
    required: true,
  },
];

export const moveWithFormSchema = [
  {
    fieldName: 'choose',
    type: 'CustomSelect',
    label: 'Choose type',
    options: [{ text: 'Relatives', value: 'relative' }],
    required: true,
  },
  {
    fieldName: 'relatives',
    type: 'CustomSelect',
    label: 'Relatives',
    required: true,
  },
];

export const moveWithRelativesSchema = [
  {
    fieldName: 'choose',
    type: 'CustomSelect',
    label: 'Choose type',
    options: [{ text: 'Relatives', value: 'relative' }],
    required: true,
  },
  {
    fieldName: 'relatives',
    type: 'CustomSelect',
    label: 'Relatives',
    required: true,
  },
];

export const checkboxSchema = [
  {
    fieldName: 'isITechArtEmployee',
    type: 'Checkbox',
    label: 'Is iTechArt/Vention employee?',
  },
];
