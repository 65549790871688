import { types } from '../actions/types';
import dayjs from 'dayjs';

const initialState = {};

const requestRelatedProfilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_RELATED_INFO_LOADED: {
      const relativeReqId = action.payload.relativeReqId;
      return {
        ...state,
        [relativeReqId]: {
          ...state[relativeReqId],
          relatedInfo: action.payload.relatedInfo,
        },
      };
    }
    case types.RELATED_VAC_APPOINTMENT_ADD: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            isDisplaySendVacDetails: true,
            vacAppointment: [...state[relatedReqId].relatedInfo.vacAppointment, action.payload.vacAppointment],
          },
        },
      };
    }
    case types.RELATED_FOREIGN_POLICE_ADD: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            isDisplaySendVacDetails: true,
            foreignPoliceAppointments: [
              ...state[relatedReqId].relatedInfo.foreignPoliceAppointments,
              action.payload.vacAppointment,
            ],
          },
        },
      };
    }
    case types.RELATED_VAC_APPOINTMENT_FORM_DELETE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            vacAppointment: state[relatedReqId].relatedInfo.vacAppointment.filter(
              (item) => item.id !== action.payload.vacId,
            ),
          },
        },
      };
    }
    case types.RELATED_FOREIGN_POLICE_DELETE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            foreignPoliceAppointments: state[relatedReqId].relatedInfo.foreignPoliceAppointments.filter(
              (item) => item.id !== action.payload.vacId,
            ),
          },
        },
      };
    }
    case types.RELATED_VAC_APPOINTMENT_UPDATE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            isDisplaySendVacDetails: action.payload.isDisplaySendVacDetails,
            vacAppointment: state[relatedReqId].relatedInfo.vacAppointment.map((vacInfo) => {
              if (vacInfo.id === action.payload.vacAppointment.id) {
                vacInfo = action.payload.vacAppointment;
              }
              return vacInfo;
            }),
          },
        },
      };
    }
    case types.RELATED_FOREIGN_POLICE_UPDATE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            isDisplaySendVacDetails: action.payload.isDisplaySendVacDetails,
            foreignPoliceAppointments: state[relatedReqId].relatedInfo.foreignPoliceAppointments.map((vacInfo) => {
              if (vacInfo.id === action.payload.vacAppointment.id) {
                vacInfo = action.payload.vacAppointment;
              }
              return vacInfo;
            }),
          },
        },
      };
    }

    case types.RELATED_CURRENT_VAC_NOTIFIED_UPDATE: {
      const relatedReqId = action.payload.relatedId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            vacAppointment: state[relatedReqId].relatedInfo.vacAppointment.map((vacInfo) => {
              if (vacInfo.isCurrent) {
                vacInfo = { ...vacInfo, isEmployeeNotifiedNew: { value: true, date: dayjs().format('MM/DD/YYYY') } };
              }
              return vacInfo;
            }),
          },
        },
      };
    }
    case types.RELATED_CURRENT_FOREIGN_POLICE_NOTIFIED_UPDATE: {
      const relatedReqId = action.payload.relatedId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            foreignPoliceAppointments: state[relatedReqId].relatedInfo.foreignPoliceAppointments.map((vacInfo) => {
              if (vacInfo.isCurrent) {
                vacInfo = { ...vacInfo, isEmployeeNotified: { value: true, date: dayjs().format('MM/DD/YYYY') } };
              }
              return vacInfo;
            }),
          },
        },
      };
    }
    case types.RELATED_TRANSPORTATION_DETAILS_ADD: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            transportationDetails: [
              ...state[relatedReqId].relatedInfo.transportationDetails,
              action.payload.transportationDetails,
            ],
          },
        },
      };
    }
    case types.RELATED_TICKET_FORM_DELETE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            transportationDetails: state[relatedReqId].relatedInfo.transportationDetails.filter(
              (item) => +item.id !== +action.payload.tdId,
            ),
          },
        },
      };
    }
    case types.RELATED_TRANSPORTATION_DETAILS_UPDATE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            transportationDetails: state[relatedReqId].relatedInfo.transportationDetails.map((item) => {
              if (item.id === action.payload.transportationDetails.id) {
                item = action.payload.transportationDetails;
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATIVE_FORM_ADD: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            forms: state[relatedReqId].relatedInfo.forms.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms = [...item.forms, action.payload.form];
                item.forms.forEach((form, index) => {
                  if (form.formId === null) {
                    item.forms.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATIVE_FORM_UPDATE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            forms: state[relatedReqId].relatedInfo.forms.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.form.formId) {
                    form.values = action.payload.form.values;
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATIVE_FORM_ATTACHMENT_ADD: {
      const relatedReqId = action.payload.relatedReqId;

      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId]?.relatedInfo,
            forms: state[relatedReqId]?.relatedInfo.forms.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments = [...form.attachments, action.payload.attachments];
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELAIVE_FORM_ATTACHMENT_DELETE: {
      const relatedReqId = action.payload.relatedReqId;

      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            forms: state[relatedReqId].relatedInfo.forms.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments.forEach((attachment, index) => {
                      if (+attachment.id === +action.payload.attachmentId) {
                        form.attachments.splice(index, 1);
                      }
                    });
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATED_DOCUMENT_UPDATE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            documents: state[relatedReqId].relatedInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.document.formId) {
                    form.isCurrent = action.payload.document.isCurrent;
                    form.values = action.payload.document.values;
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATED_DOCUMENT_ADD: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            documents: state[relatedReqId].relatedInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms = [...item.forms, action.payload.document];
                item.forms.forEach((form, index) => {
                  if (form.formId === null) {
                    item.forms.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATED_DOCUMENT_FORM_DELETE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            documents: state[relatedReqId].relatedInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                if (item.forms.length > 1) {
                  return {
                    ...item,
                    forms: item.forms.filter((form) => form.formId !== action.payload.formId),
                  };
                } else {
                  return {
                    ...item,
                    forms: item.forms.map((form, index) => {
                      if (form.formId === action.payload.formId) {
                        for (let key in item.forms[index].values) {
                          item.forms[index].values[key] = '';
                        }
                        return { ...form, formId: null, attachments: [] };
                      } else return form;
                    }),
                  };
                }
              } else return item;
            }),
          },
        },
      };
    }
    case types.RELATIVE_DOCUMENT_FORM_ARCHIVE: {
      const relatedReqId = action.payload.relatedReqId;
      const isFormExistGF = state[relatedReqId].relatedInfo.archivedDocuments.find(
        (item) => +item.id === +action.payload.gfId,
      );
      let formToArchive = {};
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            documents: state[relatedReqId].relatedInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                formToArchive = { ...item };

                if (item.forms.length > 1) {
                  return {
                    ...item,
                    forms: item.forms.filter((form) => form.formId !== action.payload.formId),
                  };
                } else {
                  return {
                    ...item,
                    forms: item.forms.map((form, index) => {
                      if (form.formId === action.payload.formId) {
                        for (let key in item.forms[index].values) {
                          item.forms[index].values[key] = '';
                        }
                        return { ...form, formId: null, attachments: [] };
                      } else return form;
                    }),
                  };
                }
              } else return item;
            }),

            archivedDocuments: isFormExistGF
              ? state[relatedReqId].relatedInfo.archivedDocuments.map((item) => {
                  if (+item.id === +action.payload.gfId) {
                    return {
                      ...item,
                      forms: [...item.forms, action.payload.form],
                    };
                  } else return item;
                })
              : [
                  ...state[relatedReqId].relatedInfo.archivedDocuments,
                  { ...formToArchive, forms: [action.payload.form] },
                ],
          },
        },
      };
    }
    case types.RELATIVE_DOCUMENT_FORM_UNARCHIVE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            archivedDocuments: state[relatedReqId].relatedInfo.archivedDocuments.reduce((acc, item) => {
              if (+item.id === +action.payload.gfId) {
                if (item.forms.length > 1) {
                  acc.push({
                    ...item,
                    forms: item.forms.filter((form) => form.formId !== action.payload.formId),
                  });
                }
              } else {
                acc.push(item);
              }
              return acc;
            }, []),

            documents: state[relatedReqId].relatedInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms = [...item.forms, action.payload.form];
                item.forms.forEach((form, index) => {
                  if (form.formId === null) {
                    item.forms.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATED_DOCUMENT_ATTACHMENT_ADD: {
      const relatedReqId = action.payload.relatedReqId;

      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId]?.relatedInfo,
            documents: state[relatedReqId]?.relatedInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments = [...form.attachments, action.payload.attachments];
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATED_DOCUMENT_ATTACHMENT_DELETE: {
      const relatedReqId = action.payload.relatedReqId;

      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            documents: state[relatedReqId].relatedInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments.forEach((attachment, index) => {
                      if (+attachment.id === +action.payload.attachmentId) {
                        form.attachments.splice(index, 1);
                      }
                    });
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATED_TICKET_ATTACHMENT_ADD: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            transportationDetails: state[relatedReqId].relatedInfo.transportationDetails.map((item) => {
              if (item.id === +action.payload.tdId) {
                item.attachments = [...item.attachments, action.payload.attachments];
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATED_TICKET_ATTACHMENT_DELETE: {
      const relatedReqId = action.payload.relatedReqId;
      return {
        ...state,
        [relatedReqId]: {
          ...state[relatedReqId],
          relatedInfo: {
            ...state[relatedReqId].relatedInfo,
            transportationDetails: state[relatedReqId].relatedInfo.transportationDetails.map((item) => {
              if (item.id === +action.payload.tdId) {
                item.attachments.forEach((attachment, index) => {
                  if (+attachment.id === +action.payload.attachmentId) {
                    item.attachments.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATED_GENERAL_INFO_UPDATE: {
      const relativeReqId = action.payload.relativeReqId;
      return {
        ...state,
        [relativeReqId]: {
          ...state[relativeReqId],
          relatedInfo: {
            ...state[relativeReqId].relatedInfo,
            relative: {
              ...state[relativeReqId].relatedInfo.relative,
              generalInfo: action.payload.generalInfo,
            },
          },
        },
      };
    }
    case types.RELATED_CONTACT_INFO_UPDATE: {
      const relativeReqId = action.payload.relativeReqId;
      return {
        ...state,
        [relativeReqId]: {
          ...state[relativeReqId],
          relatedInfo: {
            ...state[relativeReqId].relatedInfo,
            relative: {
              ...state[relativeReqId].relatedInfo.relative,
              contactInfo: action.payload.contactInfo,
            },
          },
        },
      };
    }
    case types.RELATED_EMPLOYMENT_INFO_UPDATE: {
      const relativeReqId = action.payload.relativeReqId;
      return {
        ...state,
        [relativeReqId]: {
          ...state[relativeReqId],
          relatedInfo: {
            ...state[relativeReqId].relatedInfo,
            relative: {
              ...state[relativeReqId].relatedInfo.relative,
              employmentInfo: action.payload.employmentInfo,
            },
          },
        },
      };
    }
    case types.RELATED_DOC_REQUESTS_ADD: {
      const relativeReqId = action.payload.relativeRequest.id;
      return {
        ...state,
        [relativeReqId]: {
          ...state[relativeReqId],
          relatedInfo: {
            documents: action.payload.relativeRequest.documents,
            forms: action.payload.relativeRequest.forms,
            relative: action.payload.relativeRequest.relative,
          },
        },
      };
    }
    case types.EXISTING_RELATED_DOC_ADD: {
      const relativesDoduments = action.payload.relativesDocs.reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value.id]: {
            relatedInfo: {
              documents: value.documents,
              forms: value.forms,
              relative: value.relative,
            },
          },
        };
      }, {});
      return {
        ...relativesDoduments,
      };
    }
    case types.DELETE_RELATIVE_PROFILE: {
      const relativeId = action.payload.requestRelId;
      delete state[relativeId];
      return {
        ...state,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default requestRelatedProfilesReducer;
