import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../../../axios';
import { Form, Formik } from 'formik';
import { getValuesByElement } from '../../../FeedbackDashboard/FeedbackColumn/FeedbackColumn';
import classNames from 'classnames';
import { Timeline } from 'antd';
import { CheckCircleFilled, ClockCircleFilled, Loading3QuartersOutlined } from '@ant-design/icons';
import actionCreators from '../../../../store/actions/actionCreators';
import SpinnerContainer from '../../../Common/SpinnerContainer/SpinnerContainer';
import { defaultDateFormat } from '../../../../constants';
import dayjs from 'dayjs';

import '../legalizationRequestOverview.scss';

export const columns = [
  {
    key: 'date',
    title: 'Date',
    label: 'Date',
    dataIndex: 'date',
    width: '10%',
  },
  {
    key: 'time',
    title: 'Time',
    label: 'Time',
    dataIndex: 'time',
    width: '20%',
  },
  {
    key: 'employee',
    title: 'Employee',
    label: 'Employee',
    dataIndex: 'employee',
    width: '14%',
  },
  {
    key: 'changes',
    title: 'Changes made',
    label: 'Changes made',
    dataIndex: 'changes',
    width: '56%',
  },
];

const DisabledLegalizationRequestOverview = ({ employeeId, isPortalStaff, isHrManager }) => {
  const dispatch = useDispatch();
  const legalizationRequest = useSelector((state) => state.legalizationRequestReducer.legalizationRequest);

  const { data: statusTimelineData, maxKey: statusTimelineMaxKey } = { ...legalizationRequest.statusTimeline };

  const [isLoading, setIsLoading] = useState(true);

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const getTimeline = (item, itemIndex, maxKey) => {
    const key = maxKey ? maxKey - 1 : 1;

    if (key + 1 === itemIndex) {
      if (item.set) {
        return {
          color: 'green',
          dot: <CheckCircleFilled height={'20px'} width={'20px'} />,
          children: (
            <div>
              <div className={'text'}>{item.title}</div>
              <div
                className={'date'}
                style={{
                  fontSize: '14px',
                  height: '20px',
                }}
              >
                {item.set && `Set: ${dayjs(new Date(item.set)).format(displayDateFormat)}`}
              </div>
            </div>
          ),
        };
      } else {
        return {
          color: '#eb3d26',
          dot: <Loading3QuartersOutlined />,
          children: (
            <div>
              <div className={'text'}>{item.title}</div>
              <div
                className={'date'}
                style={{
                  fontSize: '14px',
                  height: '20px',
                }}
              >
                Status in progress
              </div>
            </div>
          ),
        };
      }
    } else if (itemIndex > key) {
      return {
        color: '#ced4da',
        dot: <ClockCircleFilled />,
        children: (
          <div>
            <div className={'text'}>{item.title}</div>
            <div
              className={'date'}
              style={{
                fontSize: '14px',
                height: '20px',
              }}
            >
              {/*{item.set ? `Expected: ${item.set}` : 'Expected: '}*/}
            </div>
          </div>
        ),
      };
    } else {
      return {
        color: 'green',
        dot: <CheckCircleFilled height={'20px'} width={'20px'} />,
        children: (
          <div>
            <div className={'text'}>{item.title}</div>
            <div
              className={'date'}
              style={{
                fontSize: '14px',
                height: '20px',
              }}
            >
              {item.set && `Set: ${dayjs(new Date(item.set)).format(displayDateFormat)}`}
            </div>
          </div>
        ),
      };
    }
  };

  const getLegalizationRequest = async () => {
    try {
      const response = await axios.get(`/api/v1/legalization-request/${employeeId}/details/`);

      dispatch(actionCreators.legalizationRequestLoaded(response.data));
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getLegalizationRequest().finally(() => setIsLoading(false));
  }, []);

  const editableFormSchema = [
    // {
    //   fieldName: 'responsibleId',
    //   type: 'TextInput',
    //   label: 'Responsible',
    //   disabled: true,
    // },
    {
      fieldName: 'status',
      type: 'TextInput',
      label: 'Status',
      disabled: true,
    },
  ];

  const nonEditableFormSchema = [
    {
      fieldName: 'country',
      type: 'TextInput',
      label: 'Country',
      disabled: true,
    },
    {
      fieldName: 'type',
      type: 'TextInput',
      label: 'Type',
      disabled: true,
    },
  ];

  const formsTypeMapping = {
    employee: 'Only employee',
    family: 'Only family',
    employee_and_family: 'With family',
  };

  const initialValues = {
    responsibleId: legalizationRequest.responsible?.generalInfo?.fullName,
    status: legalizationRequest.status,
    type: formsTypeMapping[legalizationRequest.formsType],
    country: legalizationRequest.country?.name,
    deadline: legalizationRequest.deadline,
    comments: legalizationRequest.comments,
  };

  const lastFormsSchema = [
    {
      fieldName: 'deadline',
      type: 'DatePicker',
      label: 'Deadline: Date of submission of documents (if known).',
    },
    {
      fieldName: 'comments',
      type: 'TextArea',
      label: 'Comments: please provide your comments for the Global Mobility Team, if necessary.',
    },
  ];

  return (
    <div className={'legalization-request-details__wrapper'}>
      {!isLoading &&
        (legalizationRequest.status ? (
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }} className={'hover-test'}>
            <div className={'flex-space legalization-request-details'}>
              <h4>Legalization request details</h4>
              <Formik enableReinitialize initialValues={initialValues} onSubmit={() => {}}>
                {(formikProps) => (
                  <>
                    <Form>
                      <div className="question-block">
                        <div className="form-group form-group__column">
                          {editableFormSchema.map((field) => (
                            <div key={field.fieldName} className="form-group form-group__column">
                              {field.label && (
                                <label className={classNames('control-label', { required: field.required })}>
                                  {field.label}
                                </label>
                              )}
                              <div className={classNames('text-field__wrapper', 'form-input-width')}>
                                <div>
                                  {/*{'Test'}*/}
                                  {getValuesByElement(
                                    field,
                                    initialValues[field.fieldName],
                                    initialValues[field.fieldName + 'DateExtraFields'],
                                  ) || 'Not provided'}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className={'underl-test'}></div>
                        <div className="form-group form-group__column">
                          {nonEditableFormSchema.map((field) => (
                            <div key={field.fieldName} className="form-group form-group__column">
                              {field.label && (
                                <label className={classNames('control-label', { required: field.required })}>
                                  {field.label}
                                </label>
                              )}
                              <div className={classNames('text-field__wrapper', 'form-input-width')}>
                                <div>
                                  {/*{'Test'}*/}
                                  {getValuesByElement(
                                    field,
                                    initialValues[field.fieldName],
                                    initialValues[field.fieldName + 'DateExtraFields'],
                                  ) || 'Not provided'}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {legalizationRequest?.forms?.length > 0 &&
                          legalizationRequest?.forms?.map((form, formIndex) => {
                            const { fields, values, status } = form.forms[0];

                            return fields.map((field, fieldIndex) => {
                              return (
                                <div key={field.fieldName} className="form-group form-group__column">
                                  {field.label && (
                                    <label className={classNames('control-label', { required: field.required })}>
                                      {field.label}
                                    </label>
                                  )}
                                  <div className={classNames('text-field__wrapper', 'form-input-width')}>
                                    <div>
                                      {/*{'Test'}*/}
                                      {getValuesByElement(
                                        field,
                                        values[field.fieldName],
                                        values[field.fieldName + 'DateExtraFields'],
                                      ) || 'Not provided'}
                                    </div>
                                  </div>
                                </div>
                              );
                            });
                          })}
                        <div className="form-group form-group__column">
                          {lastFormsSchema.map((field) => (
                            <div key={field.fieldName} className="form-group form-group__column">
                              {field.label && (
                                <label className={classNames('control-label', { required: field.required })}>
                                  {field.label}
                                </label>
                              )}
                              <div className={classNames('text-field__wrapper', 'form-input-width')}>
                                <div>
                                  {/*{'Test'}*/}
                                  {getValuesByElement(
                                    field,
                                    initialValues[field.fieldName],
                                    initialValues[field.fieldName + 'DateExtraFields'],
                                  ) || 'Not provided'}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
            <div className={'flex-space status-timeline'}>
              <h4>Status timeline</h4>
              <Timeline
                items={
                  statusTimelineData?.length > 0
                    ? statusTimelineData.map((item, itemIndex) => {
                        return {
                          ...getTimeline(item, itemIndex, statusTimelineMaxKey),
                        };
                      })
                    : []
                }
              />
            </div>
          </div>
        ) : (
          <div>No legalization request</div>
        ))}
      {isLoading && <SpinnerContainer />}
    </div>
  );
};

export default DisabledLegalizationRequestOverview;
