import { Form, Formik } from 'formik';
import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { getValuesToUpdate } from '../../../helpers';
import { editRelocationRequest } from '../../../store/actions/request';
import { addPlannedRelocationNotes, editPlannedRelocationNotes } from '../../../store/actions/requestDetails';
import { getFormElement } from '../../Shared/Form/FormElements';
import { IsCaseDisabledContext } from '../RequestDetails';
import './plannedRelocation.scss';

const formSchemaNotes = [
  {
    fieldName: 'notes',
    type: 'NoteField',
    label: 'Notes',
  },
];

const validation = Yup.object().shape({
  notes: Yup.array().of(
    Yup.object().shape({
      text: Yup.string()
        .min(2, 'Notes must be at least 2 characters')
        .test('len', 'Notes must be at least 2 characters', (val) => (val ? val.toString().length : 0 > 0)),
    }),
  ),
});

const PlannedRelocation = ({ request }) => {
  const dispatch = useDispatch();

  const [plannedDate, setPlannedDate] = useState(request.plannedDate);
  const [updateTimeout, setUpdateTimeout] = useState(setTimeout(0));
  const [previousPlannedDate, setPreviousPlannedDate] = useState(plannedDate);

  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const initialValuesNotes = { notes: request.notes };

  const onSetPlannedDate = (value) => {
    setPlannedDate(value ? value : null);
  };

  const onCalendarClose = () => {
    if (previousPlannedDate !== plannedDate) {
      setPreviousPlannedDate(plannedDate);
      if (updateTimeout) clearTimeout(updateTimeout);
      setUpdateTimeout(
        setTimeout(() => {
          dispatch(editRelocationRequest(request.id, { plannedDate: plannedDate }));
        }, 1500),
      );
    }
  };

  const formPlannedDateSchema = [
    {
      fieldName: 'plannedDate',
      type: 'DatePicker',
      label: 'Planned relocation date',
      oncalendarcloseupdate: onCalendarClose,
      updateselectvalueroot: onSetPlannedDate,
      disabled: request.sendToWod,
    },
  ];

  const onSubmitNotes = (values, { setSubmitting, resetForm, setStatus }) => {
    const notesToUpdate = getValuesToUpdate(values.notes, request.notes, ['author', 'text']);
    const notesToAdd = values.notes.filter(({ id }) => isNaN(id));
    if (notesToUpdate.length) {
      notesToUpdate.forEach((note) => {
        dispatch(editPlannedRelocationNotes(note.id, { ...note }, request.id));
      });
    }

    if (notesToAdd.length) {
      notesToAdd.forEach((note) => {
        if (note) {
          dispatch(addPlannedRelocationNotes(request.id, { ...note }));
        }
      });
    }
    setSubmitting(false);
  };

  return (
    <div className="planned-relocation">
      <Formik enableReinitialize initialValues={{ plannedDate: plannedDate }}>
        {(formikProps) => (
          <Form className="date">
            {formPlannedDateSchema.map((field) => (
              <div key={field.fieldName} className="planned-relocation-date">
                {getFormElement(field.type, field, formikProps, isCaseDisabled)}
              </div>
            ))}
          </Form>
        )}
      </Formik>
      <Formik
        enableReinitialize
        initialValues={initialValuesNotes}
        onSubmit={onSubmitNotes}
        validationSchema={validation}
      >
        {(formikProps) => (
          <Form className="notes">
            {formSchemaNotes.map((field) => (
              <div key={field.fieldName} className="planned-relocation-notes">
                {getFormElement(field.type, field, formikProps, isCaseDisabled)}
              </div>
            ))}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PlannedRelocation;
