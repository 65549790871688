import { Switch, Route, Redirect } from 'react-router-dom';

import RelatedProfiles from '../../RelatedProfiles/RelatedProfiles';
import Documents from '../../RelativeDocumentsTabs/RelatedDocuments/Documents';
import GeneralPetInfo from '../../GeneralPetInfo/GeneralPetInfo';
import TransportationPetDetails from '../../TransportationPetDetails/TransportationPetDetails';
import PetHistory from '../../History/PetHistory/PetHistory';

const ContentRoutes = ({ relatedInfo, request, requestId, petReqId, isPetCaseDisabled }) => {
  const petId = relatedInfo?.pet.id;
  const employeeUuid = request.employee.uuid;

  const relInfo = request.relativeRequests;
  const petInfo = request.petRequests;

  return (
    <div className="pet-request-details__content">
      <Switch>
        <Route
          exact
          path="/request-details/:id/pet-requests/:rel_req_id/general-information"
          component={() => (
            <>
              <GeneralPetInfo
                id={employeeUuid}
                req_id={requestId}
                rel_id={petId}
                rel_req_id={petReqId}
                name={relatedInfo?.pet.name || ''}
                petType={relatedInfo?.pet.petType || '0'}
                weight={relatedInfo?.pet.weight || null}
                documentDetails={relatedInfo?.pet.documentDetails || ''}
                notes={relatedInfo?.notes || ''}
                isCaseDisabled={isPetCaseDisabled}
              />
            </>
          )}
        />

        <Route
          exact
          path="/request-details/:id/pet-requests/:rel_req_id/related-profiles"
          component={() => (
            <RelatedProfiles
              id={requestId}
              rel_req_id={petReqId}
              relInfo={relInfo}
              petInfo={petInfo}
              genInfo={request}
              linkedRequests={request.linkedRequests}
              type="pet"
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/pet-requests/:rel_req_id/documents"
          component={() => (
            <Documents
              documents={relatedInfo.documents}
              requestId={requestId}
              petReqId={petReqId}
              petId={petId}
              isCaseDisabled={isPetCaseDisabled}
              employeeId={employeeUuid}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/pet-requests/:rel_req_id/transportation-details"
          component={() => (
            <TransportationPetDetails
              requestId={requestId}
              relatedReqId={petReqId}
              transportationDetailsInfo={relatedInfo.transportationDetails}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/pet-requests/:rel_req_id/history"
          component={() => <PetHistory requestId={requestId} petReqId={petReqId} />}
        />
        <Redirect
          exact
          from="/request-details/:id/pet-requests/:rel_req_id"
          to="/request-details/:id/pet-requests/:rel_req_id/general-information"
        />
      </Switch>
    </div>
  );
};

export default ContentRoutes;
