import RelativeForm from './RelativeForm/RelativeForm';
import './relativeForms.scss';

const RelativeForms = (props) => {
  const { documents, requestId, relatedReqId, relativeId, employeeId } = props;

  if (!documents?.length) {
    return <p className="no-documents">No available forms</p>;
  }

  return (
    <div>
      {documents?.length > 0 &&
        documents.map((document, index) => {
          return (
            <RelativeForm
              key={index}
              requestId={requestId}
              document={document}
              relatedReqId={relatedReqId}
              relativeId={relativeId}
              employeeId={employeeId}
            />
          );
        })}
    </div>
  );
};

export default RelativeForms;
