import EmployeeForm from './EmployeeForm/EmployeeForm';

const EmployeeForms = ({ docsInfoEmployee, employeeId }) => {
  return (
    <div>
      {docsInfoEmployee &&
        docsInfoEmployee.forms.map((document) => {
          return <EmployeeForm key={document.id} employeeId={employeeId} document={document} />;
        })}
      {!docsInfoEmployee.forms?.length && <p className="no-documents"> No available forms</p>}
    </div>
  );
};

export default EmployeeForms;
