import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { getValuesToUpdate } from '../../../../../../../helpers';
import { upsertDocuments } from '../../../../../../../store/actions/requestDetails';
import DocumentForm from '../../../../../../Common/DocumentForm/DocumentForm';

const EmployeeDocument = ({ employeeId, document }) => {
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting, setFieldValue, resetForm, setStatus }) => {
    const valuesInfo = { ...values };

    valuesInfo[document.slug].forEach((item) => {
      if (item.id === null) delete item.id;
    });

    const allFormFields = document.forms[0].fields.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.fieldName);
      accumulator.push(currentValue.fieldName + 'DateExtraFields');
      return accumulator;
    }, []);

    const valuesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
      })),
      allFormFields,
    );

    const valuesWithFilesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
        files: [],
      })),
      ['files'],
    );

    const valuesToAdd = values[document.slug].filter(({ id }) => id === null || id === undefined);

    const files = valuesInfo[document.slug].some((item) => item?.files?.length);

    if (valuesToUpdate.length || valuesToAdd.length || files) {
      dispatch(
        upsertDocuments(
          valuesToUpdate,
          valuesToAdd,
          employeeId,
          document.id,
          'employee',
          true,
          valuesWithFilesToUpdate,
        ),
      ).finally(() => {
        const employeeDocsValues = JSON.parse(sessionStorage.getItem('EmployeeDocs')) || {};
        if (document.slug in employeeDocsValues) {
          delete employeeDocsValues[document.slug];
        }
        sessionStorage.setItem(
          'EmployeeDocs',
          JSON.stringify({
            ...employeeDocsValues,
          }),
        );

        if (document.isWithAttachments) {
          values[document.slug].forEach((item, index) => setFieldValue(`${document.slug}.${index}.files`, []));
        }
        setSubmitting(false);
      });
    }
  };

  return (
    <DocumentForm
      document={document}
      employeeId={employeeId}
      onSubmit={onSubmit}
      education={[]}
      isCase={false}
      documentType={{ forWhom: 'employee', type: 'documents' }}
      allowCustomReminders={false}
      submitBtnTitle={'Save'}
      isDocumentsSubmit
    />
  );
};

export default EmployeeDocument;
