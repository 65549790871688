import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { getLegalizationFormsForEmployee } from '../../../../../store/actions/legalizationStepsForm';
import SelectFormsModal from './SelectFormsModal/SelectFormsModal';

import './selectFormsToDisplay.scss';

const SelectFormsToDisplay = ({
  isModalVisible,
  setModalVisible,
  objectId,
  editDocumentsToDisplayFunction,
  documentsToDisplay,
  country,
}) => {
  const dispatch = useDispatch();

  const legalizationForms = useSelector((state) => state.legalizationForms.legalizationFormsForEmployee) || [];

  useEffect(() => {
    if (!legalizationForms?.length) {
      dispatch(getLegalizationFormsForEmployee({ is_for_employee: true }));
    }
  }, [dispatch, legalizationForms?.length]);

  const formSchema = legalizationForms.map((item) => {
    return { fieldName: +item.id, type: 'CheckboxReverse', label: item.name };
  });

  const initialValues = formSchema.reduce((accumulator, currentValue) => {
    const initialValue = !!documentsToDisplay.legalization.find((item) => item === currentValue.fieldName);
    if (initialValue) {
      accumulator[currentValue.fieldName] = initialValue;
    }
    return accumulator;
  }, {});

  const onSubmit = (values, { setSubmitting }) => {
    const documentsToDisplayNew = _.keys(_.pickBy(values));

    dispatch(
      editDocumentsToDisplayFunction(objectId, {
        documentsToDisplay: documentsToDisplayNew.concat(documentsToDisplay.documents),
      }),
    ).finally(() => {
      setSubmitting(false);
      setModalVisible(false);
    });
  };

  return (
    <SelectFormsModal
      isModalVisible={isModalVisible}
      setModalVisible={setModalVisible}
      initialValues={initialValues}
      onSubmit={onSubmit}
      formSchema={formSchema}
      title="Select forms to display"
    />
  );
};

export default SelectFormsToDisplay;
