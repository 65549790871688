import { useMemo } from 'react';
import { Tree } from 'antd';

import './multipleCheckboxTree.scss';

const MultipleCheckboxTree = ({ setSelectedKeys, selectedKeys, options }) => {
  const selectedItems = selectedKeys[0]?.multipleCheckboxTreeFilter
    ? Object.values(selectedKeys[0]?.multipleCheckboxTreeFilter).flat()
    : [];

  const availableOptions = useMemo(() => {
    return options
      .filter((item) => item.hasChildren)
      .reduce((obj, item) => {
        obj[item.key] = item.children.map((childrenItem) => childrenItem.key);
        return obj;
      }, {});
  }, []);

  const onCheck = (checkedKeys, info) => {
    setSelectedKeys(
      checkedKeys.length > 0
        ? [
            {
              ...selectedKeys[0],
              multipleCheckboxTreeFilter: options
                .filter((item) => item.hasChildren)
                .reduce((obj, { key }) => {
                  obj[key] = checkedKeys.filter((checkedKey) => availableOptions[key].includes(checkedKey));

                  return obj;
                }, {}),
            },
          ]
        : [],
    );
  };

  return (
    <div className="multiple-filter__checkbox-tree">
      <Tree checkable checkedKeys={selectedItems} onCheck={onCheck} treeData={options} selectable={false} />
    </div>
  );
};

export default MultipleCheckboxTree;
