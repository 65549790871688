import { types } from '../actions/types';

export const initialState = {
  excelForms: [],
  filterByCountry: '',
  excelFormsForRelative: {},
  excelFormsForEmployee: {},
  next: null,
  previous: null,
  count: 0,
};

const excelFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EXCEL_FORMS_LOADED:
      return {
        ...state,
        excelForms: action.payload.excelForms,
        filterByCountry: action.payload.filterByCountry || '',
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
      };
    case types.EXCEL_FORMS_LOAD_RELATIVE:
      return {
        ...state,
        ...(action.payload.parameters?.is_for_employee && {
          excelFormsForEmployee: {
            ...state.excelFormsForEmployee,
            [action.payload.countryId]: action.payload.excelForms,
          },
        }),
        ...(action.payload.parameters?.is_for_spouse && {
          excelFormsForRelative: {
            ...state.excelFormsForRelative,
            [action.payload.countryId]: {
              ...state.excelFormsForRelative[action.payload.countryId],
              spouse: action.payload.excelForms,
            },
          },
        }),
        ...(action.payload.parameters?.is_for_child && {
          excelFormsForRelative: {
            ...state.excelFormsForRelative,
            [action.payload.countryId]: {
              ...state.excelFormsForRelative[action.payload.countryId],
              child: action.payload.excelForms,
            },
          },
        }),
      };
    case types.EXCEL_FORMS_UPDATE:
      return {
        ...state,
        excelForms: [...state.excelForms, ...action.payload.excelForms],
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.EXCEL_FORMS_UPDATED:
      return {
        ...state,
        excelForms: state.excelForms.map((item) => {
          if (item.id === action.payload.excelForms.id) {
            item = action.payload.excelForms;
          }
          return item;
        }),
      };
    case types.EXCEL_FORMS_ADD:
      return {
        ...state,
        excelForms: [...state.excelForms, action.payload.excelForms],
      };
    default:
      return {
        ...state,
      };
  }
};

export default excelFormsReducer;
