import axios from 'axios';
import { getAppUserManager } from './components/auth/userManager';

const instance = axios.create();

instance.interceptors.request.use(async function (config) {
  const userManager = getAppUserManager();
  const token = await userManager.getUser().then((user) => {
    return user?.access_token;
  });

  config.headers.Authorization = token ? `Bearer ${token}` : '';
  config.headers.common['Accept'] = 'application/json';
  config.headers.post['Content-Type'] = 'application/json';
  return config;
});

instance.interceptors.response.use(
  (response) => {
    if (response.config.url === '/api/v1/auth/login/' && response.data.token) token = response.data.token;
    else if (response.config.url === '/api/v1/auth/logout/') token = '';
    return response;
  },
  (error) => {
    const internalServerErrorRegEx = /^((51[0-1])|(50[0-9]))$/;
    if (error.response.status === 401) {
      window.location.href = '/auth/signout-redirect';
    } else if (error.response.status === 403) {
      window.location.href = '/access-denied';
    } else if (internalServerErrorRegEx.test(error.response.status)) {
      error.response.data = 'Internal server error';
    }
    throw error;
  },
);

export default instance;
