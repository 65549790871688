import { UserManager, WebStorageStateStore } from 'oidc-client';

import { ENABLE_EXTERNAL_LOGIN_KEY } from '../../Shared/Hooks/useEnableExternalLogin';
import { AUTHORITY, CLIENT_ID } from './constants';

let userManager = null;

const createAppUserManager = () => {
  const storedEnableExternalLogin = localStorage.getItem(ENABLE_EXTERNAL_LOGIN_KEY);
  const shouldEnableExternalLogin = storedEnableExternalLogin ? JSON.parse(storedEnableExternalLogin) : false;

  return new UserManager({
    authority: AUTHORITY,
    client_id: CLIENT_ID,
    redirect_uri: `${window.location.origin}/auth/signin-callback`,
    acr_values: shouldEnableExternalLogin ? 'external_idp:enabled' : undefined,

    // if we choose to use popup window instead for logins
    popup_redirect_uri: `${window.location.origin}/auth/signin-callback-popup`,
    popupWindowFeatures: 'menubar=yes,location=yes,toolbar=yes,width=1200,height=800,left=100,top=100;resizable=yes',

    // these two will be done dynamically from the buttons clicked, but are
    // needed if you want to use the silent_renew
    response_type: 'code',
    scope: 'openid profile email wod_profile smg_profile itechart_goglobal_api',

    // this will toggle if profile endpoint is used
    loadUserInfo: true,

    // silent renew will get a new access_token via an iframe
    // just prior to the old access_token expiring (60 seconds prior)
    silent_redirect_uri: `${window.location.origin}/auth/signin-callback-silent`,
    automaticSilentRenew: true,

    monitorAnonymousSession: true,

    // this will allow all the OIDC protocol claims to be visible in the window. normally a client app
    // wouldn't care about them or want them taking up space
    filterProtocolClaims: false,

    userStore: new WebStorageStateStore({ store: localStorage }),
  });
};

const getAppUserManager = () => {
  if (!userManager) {
    userManager = createAppUserManager();
  }

  return userManager;
};

export default getAppUserManager;
