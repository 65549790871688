import { title, radioGroupOptions, filters, customCityOptions, customCountryOptions } from './statisticsProperties';
import ChartBlock from '../../Common/ChartBlock/ChartBlock';
import { sumValues } from '../../../../helpers';
import { useGetStatisticsFilters } from '../../Common/hooks/useGetStatisticsFilters';
import { getSeriesData } from '../../Common/ChartBlock/chartProperties';

const CasesStatistics = ({ analyticsSource }) => {
  const [statisctics, radioValue, onChangeRadioGroup, dateRangeValue, onChangeDateRange] = useGetStatisticsFilters({
    filters: { ...filters, analyticsSource: analyticsSource },
  });

  const series = [
    {
      name: 'count',
      data: getSeriesData(statisctics.data, radioValue),
    },
  ];

  const totalNumber = sumValues(statisctics.data) || 0;

  const customOptions =
    radioValue === 'city' ? customCityOptions : radioValue === 'country' ? customCountryOptions : null;

  return (
    <ChartBlock
      title={title}
      isLoading={{ isLoading: statisctics.isLoading, status: statisctics.statusRequest }}
      radioGroupOptions={radioGroupOptions}
      totalNumber={totalNumber}
      radioValue={radioValue}
      onChangeRadioGroup={onChangeRadioGroup}
      dateRangeValue={dateRangeValue}
      onChangeDateRange={onChangeDateRange}
      series={series}
      customOptions={customOptions}
    />
  );
};

export default CasesStatistics;
