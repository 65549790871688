import { types } from '../actions/types';

const initialState = {
  employeesWithEmails: [],
  emailTemplates: [],
  emailTemplate: {},
  contactEmployeeTemplate: {},
};

const emailTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EMPLOYEES_WITH_EMAILS_LOAD:
      return {
        ...state,
        employeesWithEmails: action.payload.employees || [],
      };
    case types.EMAIL_TEMPLATES_LOAD:
      return {
        ...state,
        emailTemplates: action.payload.emailTemplates || [],
      };
    case types.EMAIL_TEMPLATE_LOAD:
      return {
        ...state,
        emailTemplate: { ...state.emailTemplate, [action.payload.templateId]: action.payload.emailTemplate },
      };
    case types.CONTACT_EMPLOYEE_TEMPLATE_LOAD:
      return {
        ...state,
        contactEmployeeTemplate: {
          ...state.contactEmployeeTemplate,
          [action.payload.requestId]: action.payload.emailTemplate,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default emailTemplatesReducer;
