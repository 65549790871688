import { Switch, Route, Redirect } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import RelocatedEmployees from './RelocatedEmployees/RelocatedEmployees';
import RelocationCases from './RelocationCases/RelocationCases';
import VisaOnly from './VisaOnly/VisaOnly';
import GenerateExcel from './GenerateExcel/GenerateExcel';

import './statistics.scss';

const tabs = [
  {
    tabName: 'relocation-cases',
    title: 'Relocation cases',
  },
  {
    tabName: 'relocated-employees',
    title: 'Relocated employees',
  },
  {
    tabName: 'visa-only',
    title: 'Visa only',
  },
];
const Statistic = () => {
  return (
    <section className="statistic__section">
      <h3 className="underlined panel-top-row">Statistic board</h3>
      <div className="tabs-download-excel__container">
        <div className="tabs">
          {tabs.map(({ title, tabName }) => (
            <div key={tabName}>
              <NavLink to={`/statistics/${tabName}`} className={({ isActive }) => (isActive ? 'active' : '')}>
                <div className="tab-item">{title}</div>
              </NavLink>
            </div>
          ))}
        </div>
        <GenerateExcel />
      </div>

      <Switch>
        <Route path="/statistics/relocation-cases" component={() => <RelocationCases />} />
        <Route path={`/statistics/relocated-employees`} component={() => <RelocatedEmployees />} />
        <Route path={`/statistics/visa-only`} component={() => <VisaOnly />} />
        <Redirect from="/statistics" to="statistics/relocation-cases" />
        <Redirect from="/" to="statistics/relocation-cases" />
      </Switch>
    </section>
  );
};

export default Statistic;
