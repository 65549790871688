const standardStatusOptions = [
  { label: 'New', value: 'New' },
  { label: 'In progress', value: 'In Progress' },
  { label: 'On hold', value: 'On Hold' },
  { label: 'Completed', value: 'Completed' },
  { label: 'Canceled', value: 'Canceled' },
  {
    label: 'Ready for employment',
    value: 'Ready for employment',
  },
  {
    label: 'Country legalization',
    value: 'Country legalization',
  },
  {
    label: 'Relocation completed',
    value: 'Relocation completed',
  },
];

const visaOnlyStatusOptions = [
  { label: 'New', value: 'New' },
  { label: 'In progress', value: 'In Progress' },
  { label: 'On hold', value: 'On Hold' },
  { label: 'Canceled', value: 'Canceled' },
  { label: 'Completed', value: 'Completed' },
];

const dismissedStatusOptions = [{ label: 'Dismissed', value: 'Dismissed' }];

export const getStatusOptions = (caseType, isDismissedCase) => {
  if (caseType.toLowerCase() === 'visa only') {
    return visaOnlyStatusOptions;
  }
  return isDismissedCase ? dismissedStatusOptions : standardStatusOptions;
};
