import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getStatistics } from '../../../../store/actions/statistics';

export const useGetStatisticsFilters = ({ filters }) => {
  const dispatch = useDispatch();

  const [statisctics, setStatistics] = useState({ isLoading: true, statusRequest: 'loading', data: {} });
  // const [isLoading, setLoading] = useState({ isLoading: true, status: 'loading' });

  const [radioValue, setRadioValue] = useState(filters.radioInitialValue);
  const [dateRangeValue, setDateRangeValue] = useState({
    dateAfter: null,
    dateBefore: null,
  });

  useEffect(() => {
    dispatch(
      getStatistics({
        analytics_source: filters.analyticsSource,
        analytics_type: filters.analyticsType,
        aggregation_type: radioValue,
        created_at_after: dateRangeValue.dateAfter,
        created_at_before: dateRangeValue.dateBefore,
      }),
    )
      .then((data) => {
        setStatistics({ isLoading: false, status: '', data: data });
      })
      .catch(() => {
        setStatistics({ isLoading: false, status: 'failed', data: {} });
      });
  }, [radioValue, dateRangeValue.dateAfter, dateRangeValue.dateBefore]);

  const onChangeRadioGroup = (e) => {
    setRadioValue(e.target.value);
  };

  const onChangeDateRange = (values) => {
    setDateRangeValue(
      values
        ? {
            dateAfter: values[0].format('MM/DD/YYYY'),
            dateBefore: values[1].format('MM/DD/YYYY'),
          }
        : {
            dateAfter: null,
            dateBefore: null,
          },
    );
  };

  return [statisctics, radioValue, onChangeRadioGroup, dateRangeValue, onChangeDateRange];
};
