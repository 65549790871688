import React, { useState } from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

import { useGetParams } from '../../useGetParams';
import '../caseTable.scss';
import axios from '../../../../../axios';
import actionCreators from '../../../../../store/actions/actionCreators';
import Modal from '../../../../Shared/Modal/Modal';
import { Form, Formik } from 'formik';
import { getFormElement } from '../../../../Shared/Form/FormElements';
import { SubmitButton } from '../../../../Shared/Form/Buttons/Buttons';
import { getFormSchemaWithRequiredField } from '../../../../../helpers';
import { formSchemaWithoutRequiredField, initialValues, validationSchema } from './schemaProperties';

import ExitImg from '../../../../../assets/img/exit.svg';

import './bulkVisaApplicationUpdate.scss';

export const bulkVisaApplicationUpdate = (data, params) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put('/api/v1/relocation-requests/cases/bulk-update/', data, {
      params: { ...params },
    });
    return response.status;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

const BulkVisaApplicationUpdateModal = ({
  generationExcelRequestsIds,
  allCases,
  excludeIds,
  isModalVisible,
  setModalVisible,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField);

  const paramsFromLocation = location.search;
  const paramsFromLocalStorage = JSON.parse(localStorage.getItem(`casesFilters`));

  const filteringParams = useGetParams({
    paramsFromLocalStorage: paramsFromLocalStorage,
    paramsFromLocation: paramsFromLocation,
  });
  const params = queryString.parse(filteringParams);

  const onSubmit = (values, { setSubmitting }) => {
    const data = {
      objectUuids: generationExcelRequestsIds,
      excludeIds: excludeIds,
      allObjects: allCases,
      ...values,
    };
    dispatch(bulkVisaApplicationUpdate(data, params))
      .then((status) => {
        dispatch(actionCreators.generationExcelCheckboxesRemove());
        setModalVisible(false);
      })
      .finally((status) => setSubmitting(false));
  };

  return (
    <div className={'bulk-visa-application-update__modal'}>
      <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l">
        <div className="bulk-visa-application-update__content">
          <div className="bulk-visa-application-update__title">
            <h4 className="underlined panel-top-row">Visa application progress</h4>
            <img src={ExitImg} alt="close" onClick={() => setModalVisible(false)} />
          </div>

          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formikProps) => (
              <Form>
                {formSchema.map((field) => (
                  <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                ))}
                <div className="bulk-visa-application-update__buttons">
                  <div className="bulk-visa-application-update__button-container">
                    <button type="button" className="button btn-form main-btn" onClick={() => setModalVisible(false)}>
                      Cancel
                    </button>
                    <SubmitButton title="Confirm" formikProps={formikProps} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

const BulkVisaApplicationUpdate = ({ generationExcelCheckboxes, allCases, exludeIds }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const generationExcelRequestsIds = Object.keys(generationExcelCheckboxes).filter(
    (key) => generationExcelCheckboxes[key],
  );
  const visaApplicationUpdateBtnDisabled = generationExcelRequestsIds.length === 0 && !allCases;

  return (
    <div className="visa_bulk-update__container">
      <button
        disabled={visaApplicationUpdateBtnDisabled}
        className={classNames('button btn-form main-btn btn-back visa-application-bulk-update')}
        onClick={() => setModalVisible(true)}
      >
        Update Visa progress
      </button>
      {isModalVisible && (
        <BulkVisaApplicationUpdateModal
          generationExcelRequestsIds={generationExcelRequestsIds}
          allCases={allCases}
          excludeIds={exludeIds}
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
        />
      )}
    </div>
  );
};

export default BulkVisaApplicationUpdate;
