export const formSchema = [
  {
    fieldName: 'isDocsGatheredNew',
    label: 'Docs for VAC appointment are gathered',
  },
  {
    fieldName: 'isRelatedDocsGatheredNew',
    label: 'Docs for related profile(s) are gathered',
  },
  {
    fieldName: 'docsAreSentToAgencyNew',
    label: 'Docs are sent to the investment agency',
  },
  {
    fieldName: 'isScheduledNew',
    label: 'VAC appointment is scheduled',
  },
  {
    fieldName: 'isEmployeeReminded',
    label: 'VAC appointment reminder is sent',
  },
  {
    fieldName: 'isEmployeeNotifiedNew',
    label: 'Employee is notified',
  },
  {
    fieldName: 'isAttendedNew',
    label: 'VAC appointment is attended',
  },
  {
    fieldName: 'status',
    label: 'Visa gathered',
  },
];
