import parse from 'html-react-parser';

import { types } from '../actions/types';

const initialState = {
  message: null,
  type: null,
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TOAST_UPDATE:
      // if (action.payload.message) {
      //   action.payload.message = parse(action.payload.message);
      // }
      return {
        message: action.payload.message,
        type: action.payload.type,
      };
    case types.TOAST_RESET:
      if (action.payload.message) {
        action.payload.message = parse(action.payload.message);
      }
      return {
        message: action.payload.message,
        type: action.payload.type,
      };

    default:
      return {
        ...state,
      };
  }
};

export default toastReducer;
