import React, { useEffect, useState, Suspense } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { BrowserRouter as Router, Link, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router';
import { ConfigProvider, List } from 'antd';
import { ToastContainer, toast, Slide } from 'react-toastify';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

import PrivateRoute from './components/Routing/PrivateRoute';
const Case = React.lazy(() => import('./components/Case/Case'));
const VisaOnly = React.lazy(() => import('./components/VisaOnly/VisaOnly'));
const Constructor = React.lazy(() => import('./components/Constructor/Constructor'));
const ContactUs = React.lazy(() => import('./components/ContactUs/ContactUs'));
const RequestRelatedDetails = React.lazy(() => import('./components/RequestRelatedDetails/RequestRelatedDetails'));
const RelocationCosts = React.lazy(() => import('./components/RelocationCosts/RelocationCosts'));
const AccessDenied = React.lazy(() => import('./components/AccessDenied/AccessDenied'));
const RelocationRoutesPage = React.lazy(() => import('./components/RelocationRoutes/RelocationRoutesPage'));
const NotFound = React.lazy(() => import('./components/NotFound/NotFound'));
const Statistics = React.lazy(() => import('./components/Statistics/Statistics'));

import RoleManagement from './components/RoleManagement/RoleManagement';
import RootPage from './components/RootPage/RootPage';
import RelocationRoutes from './components/Routing/RelocationRoutes/RelocationRoutes';
import RequestDetails from './components/RequestDetails/RequestDetails';
import RequestPetDetails from './components/RequestRelatedDetails/RequestPetDetails';
import RelocatedEmployees from './components/RelocatedEmployees/RelocatedEmployees';
import DocumentsPage from './components/EmployeeDocuments/DocumentsPage';
import LegalizationDocuments from './components/LegalizationDocuments/LegalizationDocuments';
import Reminders from './components/Reminders/Reminders';
import EmployeeProfile from './components/EmployeeProfile/EmployeeProfile';
import ProfileDocuments from './components/EmployeeProfileDocuments/ProfileDocuments';
import SpinnerContainer from './components/Common/SpinnerContainer/SpinnerContainer';
import { useEnableExternalLogin } from './components/Shared/Hooks';
import { SignInRedirect, SignInRedirectCallback, SignOutRedirect } from './components/auth';
import EmailTemplates from './components/EmailTemplates/WriteEmail/EmailTemplates';
import ContactEmployee from './components/EmailTemplates/ContactEmployee/ContactEmployee';
import Toast from './components/Shared/Toast/Toast';
import EmployeeFeedback from './components/Feedback/EmployeeFeedback';
import FeedbackTable from './components/FeedbackDashboard/FeedbackTable';
import LegalizationRequest from './components/LegalizationRequest/LegalizationRequest';
import LegalizationProfile from './components/LegalizationProfile/LegalizationProfile';
import LegalizationRequestsDashboard from './components/LegalizationRequests/LegalizationRequests';

import DisabledLegalizationRequest from './components/LegalizationRequest/DisabledLegalizationRequest';
import ClearDocumentsDashborad from './components/ClearDocumentsDashboard/ClearDocumentsDashboard';
import SendBulkEmailRouter from './components/LegalizationDocuments/SendBulkEmail/SendBulkEmailWrapper';

import 'react-toastify/dist/ReactToastify.css';

import './assets/css/App.scss';

import { COLORS } from './constants';

import { getEmployeesEmails, getTemplate, sendEmail } from './store/actions/emailTemplates';
import { getFormSchemaWithRequiredField } from './helpers';
import {
  formSchemaWithoutRequiredField,
  validationSchema,
} from './components/EmailTemplates/ContactEmployee/schemaProperties';
import { Form, Formik } from 'formik';
import { getFormElement } from './components/Shared/Form/FormElements';
import { SubmitButton } from './components/Shared/Form/Buttons/Buttons';
import { getEmployeeProfile } from './store/actions/employeeProfile';
import Modal from './components/Shared/Modal/Modal';
import { getAppUserManager } from './components/auth/userManager';

const EmailStatusModal = ({ isModalVisible, setIsModalVisible, employeeId }) => {
  const history = useHistory();

  const handleCloseModal = () => {
    setIsModalVisible((prev) => ({ ...prev, isVisible: false, status: false }));
    if (isModalVisible.status) {
      history.push(`/profile/${employeeId}/legalization-request`);
    }
  };

  const message = isModalVisible.status
    ? 'Thank you! Your message has been sent.'
    : 'Sorry, something went wrong during sending email. Try to send email again';

  return (
    <div className="send-email__status">
      <Modal isVisible={isModalVisible.isVisible} setIsVisible={handleCloseModal} size="l">
        <div className="modal-message">
          <h4 className="underlined panel-top-row">Thank you</h4>
          <div className={'message'}>{message}</div>
          <button type="button" className="button btn-form main-btn btn-back ok-btn" onClick={handleCloseModal}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
};

const ContactEmployeeAboutPost = ({ match }) => {
  const dispatch = useDispatch();

  const templateId = match.params.templateId;
  const employeeId = match.params.employeeId;

  const { employeesWithEmails: employees } = useSelector((state) => state.emailTemplates);

  const [actor, setActor] = useState(null);

  useEffect(() => {
    if (!employees?.length) {
      dispatch(getEmployeesEmails());
    }
  }, []);

  const options = employees.map((item) => ({
    label: item.contactInfo.email,
    value: item.contactInfo.email,
    fullName: item.generalInfo.fullName,
    internalId: item.internalId,
  }));

  const [isModalVisible, setIsModalVisible] = useState({ isVisible: false, status: false });
  const [employee, setEmployee] = useState();
  const template = useSelector((state) => state.emailTemplates.emailTemplate[templateId]);

  useEffect(() => {
    if (templateId && !template) {
      dispatch(getTemplate(templateId));
    }

    dispatch(getEmployeeProfile(employeeId)).then((response) => setEmployee(response));
  }, []);

  const initialValues = {
    subject: template?.subject || '',
    message: template?.body || '',
    ccList: template?.ccList || [],
  };

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField).map((item) => {
    if (item.fieldName === 'emailList' || item.fieldName === 'ccList') {
      item.options = options;
    }
    return item;
  });

  const [userEmail, setUserEmail] = useState({});
  const userManager = getAppUserManager();
  userManager.getUser().then((user) => {
    setUserEmail(user?.profile?.email || '');
  });

  const onSubmit = (values, { setSubmitting, resetForm, setFieldValue }) => {
    sendEmail({ ...values, emailList: [employee.contactInfo?.email] })
      .then((response) => {
        setSubmitting(false);
        resetForm();
        setIsModalVisible((prev) => ({ isVisible: true, status: true }));
      })
      .catch((error) => {
        setSubmitting(false);
        setIsModalVisible((prev) => ({ isVisible: true, status: false, error: error.response.data }));
      });
  };

  return (
    <section className="contact-employee__section">
      {employee && template && (
        <div className="send-email__wrapper">
          <div className="send-email__header">
            <h3 className="underlined panel-top-row">Write an email to {employee.generalInfo.fullName}</h3>
          </div>
          <div className="send-email">
            <Formik
              enableReinitialize
              initialValues={{ ...initialValues, ccList: [userEmail && userEmail.toLowerCase()] }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formikProps) => (
                <Form>
                  {formSchema.map((field) => (
                    <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                  ))}
                  <div className="send-email__button-container">
                    <SubmitButton title="Send" formikProps={formikProps} />
                  </div>
                </Form>
              )}
            </Formik>
            {isModalVisible.isVisible && (
              <EmailStatusModal
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                employeeId={employeeId}
              />
            )}
          </div>
        </div>
      )}
      {(!employee || !template) && <SpinnerContainer />}
    </section>
  );
};

const App = () => {
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [enableExternalLogin, setEnableExternalLogin] = useEnableExternalLogin();

  return (
    <>
      <ConfigProvider
        theme={{
          token: COLORS,
        }}
      >
        <Suspense fallback={<SpinnerContainer />}>
          <Router>
            <Switch>
              <Route
                exact
                path="/login"
                render={(props) => {
                  history.push(`/auth/signin-redirect?redirectPath=`);
                }}
              />
              <Route path="/auth/signin-redirect" component={SignInRedirect} />
              <Route path="/auth/signin-callback" render={() => <SignInRedirectCallback onSignedIn={setUser} />} />
              <Route
                path="/auth/signout-redirect"
                render={() => <SignOutRedirect enableExternalLogin={enableExternalLogin} />}
              />
              <PrivateRoute
                exact
                path="/"
                component={RootPage}
                roles={['employee', 'approver', 'gmg', 'hr']}
                rootPage
              />
              <PrivateRoute
                exact
                path="/contact-us"
                secondaryLayout
                component={ContactUs}
                roles={['employee', 'approver', 'gmg', 'hr']}
              />
              <PrivateRoute
                path="/contact-employee/:requestId"
                component={ContactEmployee}
                roles={['approver', 'gmg', 'hr']}
              />
              <PrivateRoute
                path="/contact/:employeeId/template/:templateId"
                component={ContactEmployeeAboutPost}
                roles={['approver', 'gmg', 'hr']}
              />
              <PrivateRoute
                path="/contact-employees/:templateId"
                component={SendBulkEmailRouter}
                roles={['gmg', 'hr']}
              />
              <PrivateRoute path="/email-templates" component={EmailTemplates} roles={['approver', 'gmg', 'hr']} />
              <PrivateRoute path="/cases" component={Case} roles={['gmg']} />
              <PrivateRoute path="/visa-only" component={VisaOnly} roles={['gmg']} />
              <PrivateRoute path="/relocation-costs" component={RelocationCosts} roles={['gmg', 'approver']} />
              <PrivateRoute path="/relocation-routes" component={RelocationRoutesPage} roles={['gmg', 'approver']} />
              <PrivateRoute
                path="/relocation"
                component={RelocationRoutes}
                roles={['employee', 'approver', 'gmg', 'hr']}
                secondaryLayout
              />
              <PrivateRoute path="/relocated-employees" component={RelocatedEmployees} roles={['gmg', 'approver']} />
              <PrivateRoute
                exact
                path="/feedback-dashboard"
                component={FeedbackTable}
                roles={['employee', 'approver', 'gmg', 'hr']}
              />
              <PrivateRoute
                exact
                path="/feedback/:id"
                component={EmployeeFeedback}
                roles={['employee', 'approver', 'gmg', 'hr']}
              />
              <PrivateRoute
                exact
                path="/legalization-request"
                component={LegalizationRequest}
                roles={['employee', 'approver', 'gmg', 'hr']}
              />
              <PrivateRoute
                exact
                path="/legalization-request/:id"
                component={DisabledLegalizationRequest}
                roles={['employee', 'approver', 'gmg', 'hr']}
              />

              <PrivateRoute
                path="/documents/:id"
                component={DocumentsPage}
                roles={['employee', 'approver', 'gmg', 'hr']}
              />
              <PrivateRoute
                path="/profile/documents/:id"
                component={ProfileDocuments}
                roles={['employee', 'approver', 'gmg', 'hr']}
              />
              <PrivateRoute path="/statistics" component={Statistics} roles={['gmg']} />
              <PrivateRoute
                path="/request-details/:id/relative-requests/:rel_req_id"
                component={RequestRelatedDetails}
                roles={['gmg']}
              />
              <PrivateRoute
                path="/request-details/:id/pet-requests/:rel_req_id"
                component={RequestPetDetails}
                roles={['gmg']}
              />

              <PrivateRoute path="/request-details/:id" component={RequestDetails} roles={['gmg']} />
              <PrivateRoute path="/form-builder" component={Constructor} roles={['gmg']} />
              <PrivateRoute path="/legalization-documents" component={LegalizationDocuments} roles={['gmg', 'hr']} />
              <PrivateRoute path="/clear-documents" component={ClearDocumentsDashborad} roles={['gmg']} />
              <PrivateRoute path="/reminders" component={Reminders} roles={['gmg']} />
              <PrivateRoute
                path="/access-denied"
                component={AccessDenied}
                roles={['employee', 'approver', 'gmg', 'admin']}
              />
              <PrivateRoute path="/profile/:id" component={EmployeeProfile} roles={['employee', 'gmg', 'hr']} />
              <PrivateRoute
                path="/legalization-profile/:id"
                component={LegalizationProfile}
                roles={['employee', 'gmg', 'hr']}
              />
              <PrivateRoute
                path="/legalization-dashboard/"
                component={LegalizationRequestsDashboard}
                roles={['employee', 'gmg', 'hr']}
              />
              <Route render={() => <RoleManagement component={NotFound} roles={['employee', 'approver', 'gmg']} />} />
            </Switch>
          </Router>
        </Suspense>
        <Toast />
      </ConfigProvider>
    </>
  );
};

export default App;
