import { useState } from 'react';
import { Tooltip } from 'antd';

import Document from '../../../assets/img/document.svg';
import Close from '../../../assets/img/icons/Close.svg';
import ModalConfirm from '../ModalConfirm/ModalConfirm';
import { getAttachmentLink } from '../../../store/actions/downloadFile';

import './AttachmentLink.scss';

const AttachmentLink = ({
  attachmentId,
  attach,
  deleteAttachment,
  employeeId,
  gfId,
  formId,
  isCase,
  isDraft,
  isCaseDisabled,
}) => {
  const [isDeleteAttachmentModalVisible, setDeleteAttachmentModalVisible] = useState(false);

  const downloadFile = async (attachmentId) => {
    const response = await getAttachmentLink(attachmentId, employeeId, gfId, formId, isCase);
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = response.url;
    document.body.appendChild(link);
    link.click();
  };

  const openModal = () => {
    setDeleteAttachmentModalVisible(true);
  };

  const deleteFile = () => {
    deleteAttachment(attachmentId);
    setDeleteAttachmentModalVisible(false);
  };

  return (
    <>
      <div className="attachment">
        <div className="attachment-container">
          <img src={Document} alt={attach.fileName} className="icon" onClick={() => downloadFile(attachmentId)} />
          {!isCaseDisabled && deleteAttachment && ((isDraft && !isCase) || isCase) && (
            <Tooltip placement={'top'} title={'Delete attachment'}>
              <div className="delete-attachment">
                <img src={Close} alt="Close" className="close-attachment" onClick={() => openModal()} />
              </div>
            </Tooltip>
          )}
        </div>
        <span onClick={() => downloadFile(attachmentId)}>{attach.fileName}</span>
      </div>
      {isDeleteAttachmentModalVisible && (
        <ModalConfirm
          isModalVisible={isDeleteAttachmentModalVisible}
          setModalVisible={setDeleteAttachmentModalVisible}
          confirmationQuestion="Are you sure you want to delete this attachment?"
          handleClick={deleteFile}
        />
      )}
    </>
  );
};

export default AttachmentLink;
