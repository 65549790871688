import * as Yup from 'yup';

export const formSchema = [
  {
    fieldName: 'text',
    type: 'TinyMCEField',
    label: 'Comment',
  },
];

export const validationSchema = Yup.object().shape({
  text: Yup.string().required('This field is required'),
});
