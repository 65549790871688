import { getRelativeTab } from '../DocumentsTabs/useGetTabs';

const getMenuItems = (defaultItems, docsInfo) => {
  return defaultItems.reduce((accumulator, currentItem) => {
    if (currentItem.value === 'documents' && !docsInfo?.documents.length) {
      return accumulator;
    } else if (currentItem.value === 'forms' && !docsInfo?.forms.length) {
      return accumulator;
    }

    accumulator.push(currentItem);
    return accumulator;
  }, []);
};

export const getMenuList = (selectedTab, selectedTabIndex, docsInfo) => {
  switch (selectedTab) {
    case 'employee': {
      const requestedDocsForSelectedTab = {
        documents: docsInfo.employeeDocuments,
        forms: docsInfo.employeeForms,
      };
      const defaultItems = [
        {
          name: 'Personal info',
          value: 'personalInfo',
        },
        {
          name: 'Documents',
          value: 'documents',
        },
        {
          name: 'Forms',
          value: 'forms',
        },
        {
          name: 'Comments',
          value: 'comments',
        },
      ];

      return getMenuItems(defaultItems, requestedDocsForSelectedTab);
    }
    case 'employee-profile': {
      return [
        {
          name: 'Documents',
          value: 'documents',
        },
        {
          name: 'Comments',
          value: 'comments',
        },
      ];
    }
    case 'relative': {
      const requestedDocsForSelectedTab = docsInfo.relativeRequests.find(
        (item) => item.relative?.id === +selectedTabIndex,
      );
      const defaultItems = [
        {
          name: 'Personal info',
          value: 'personalInfo',
        },
        {
          name: 'Employment info',
          value: 'employmentInfo',
        },
        {
          name: 'Documents',
          value: 'documents',
        },
        {
          name: 'Forms',
          value: 'forms',
        },
      ];
      return requestedDocsForSelectedTab ? getMenuItems(defaultItems, requestedDocsForSelectedTab) : defaultItems;
    }
    case 'spouse': {
      const requestedDocsForSelectedTab = docsInfo.relativeRequests.find(
        (item) => item.relative?.id === +selectedTabIndex,
      );
      const defaultItems = [
        {
          name: 'Personal info',
          value: 'personalInfo',
        },
        {
          name: 'Employment info',
          value: 'employmentInfo',
        },
        {
          name: 'Documents',
          value: 'documents',
        },
        {
          name: 'Forms',
          value: 'forms',
        },
      ];
      return requestedDocsForSelectedTab ? getMenuItems(defaultItems, requestedDocsForSelectedTab) : defaultItems;
    }
    case 'child': {
      const requestedDocsForSelectedTab = docsInfo.relativeRequests.find(
        (item) => item.relative?.id === +selectedTabIndex,
      );
      const defaultItems = [
        {
          name: 'Personal info',
          value: 'personalInfo',
        },
        {
          name: 'Education info',
          value: 'educationInfo',
        },
        {
          name: 'Documents',
          value: 'documents',
        },
        {
          name: 'Forms',
          value: 'forms',
        },
      ];
      return requestedDocsForSelectedTab ? getMenuItems(defaultItems, requestedDocsForSelectedTab) : defaultItems;
    }
    case 'linkedEmployee':
      return [
        {
          name: 'Personal info',
          value: 'personalInfo',
        },
      ];
    case 'newRelative':
      return [
        {
          name: 'Personal info',
          value: 'personalInfo',
        },
      ];
    case 'existingRelatives':
      return [
        {
          name: 'Existing relatives',
          value: 'existingRelatives',
        },
      ];
    default:
      return null;
  }
};
