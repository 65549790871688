import { types } from './types';

const authenticateSuccess = (userInfo) => {
  return {
    type: types.AUTHENTICATION_SUCCESS,
    payload: {
      isAuthenticated: true,
    },
  };
};

const dateFormatLoaded = (data) => {
  return {
    type: types.DATE_FORMAT_LOADED,
    payload: {
      dateFormat: data.dateFormat,
    },
  };
};

const dateFormatUpdated = (data) => {
  return {
    type: types.DATE_FORMAT_UPDATED,
    payload: {
      dateFormat: data.dateFormat,
    },
  };
};

const authorizationSuccess = (data) => {
  return {
    type: types.AUTHORIZATION_SUCCESS,
    payload: {
      user: data,
      isAuthorizated: true,
    },
  };
};

const userPhotoLoaded = (link) => {
  return {
    type: types.USER_PHOTO_LOADED,
    payload: {
      userPhotoLink: link,
    },
  };
};

const requestListLoaded = (data) => {
  return {
    type: types.REQUEST_LIST_LOADED,
    payload: {
      next: data.next,
      requestList: data.results,
      statistics: data.statistics,
      previous: data.previous,
    },
  };
};

const requestListError = () => {
  return {
    type: types.REQUEST_LIST_ERROR,
    payload: {
      next: null,
      requestList: [],
      previous: null,
    },
  };
};

const requestListUpdated = (data) => {
  return {
    type: types.REQUEST_LIST_UPDATED,
    payload: {
      next: data.next,
      requestList: data.results,
      statistics: data.statistics,
      previous: data.previous,
    },
  };
};

const requestListUpdate = (data) => {
  return {
    type: types.REQUEST_LIST_UPDATE,
    payload: {
      requestList: data.requestList,
      requestId: data.requestId,
    },
  };
};

const requestDetailsLoaded = (data, id) => {
  return {
    type: types.REQUEST_DETAILS_LOADED,
    payload: {
      requestDetails: data,
      requestId: id,
      employeeId: data.employee.uuid,
      employeeData: data.employee,
      employeeDocuments: data.employeeDocuments,
      employeeArchivedDocuments: data.employeeArchivedDocuments,
      employeeForms: data.employeeForms,
      employeeLegalizationDocuments: data.employeeLegalizationDocuments,
      relatedCases: data.relatedCases,
    },
  };
};

const requestDetailsFeedbackFormsLoad = (data, requestId) => {
  return {
    type: types.REQUEST_DETAILS_FEEDBACK_FORMS_LOAD,
    payload: {
      requestId: requestId,
      employeeFeedback: data,
    },
  };
};

const caseControllerLoad = (data, requestId) => {
  return {
    type: types.CASE_CONTROLLER_LOAD,
    payload: {
      controller: data,
      requestId: requestId,
    },
  };
};

const requestDetailsEmployeeFeedbackUpdate = (data, requestId) => {
  return {
    type: types.REQUEST_DETAILS_EMPLOYEE_FEEDBACK_UPFATE,
    payload: {
      requestId: requestId,
      employeeFeedback: data,
    },
  };
};

const caseControllerUpdate = (data, requestId) => {
  return {
    type: types.CASE_CONTROLLER_UPDATE,
    payload: {
      tabsController: data,
      requestId: requestId,
    },
  };
};

const profileDocumentsLoad = (data, employeeId) => {
  return {
    type: types.PROFILE_DOCUMENTS_LOAD,
    payload: {
      employeeId: employeeId,
      employeeData: data.employee,
      employeeDocuments: data.employeeDocuments,
      employeeForms: data.employeeForms,
      documentRequest: data.documentRequest,
    },
  };
};

const compensationCalculatorLoad = (data, requestId) => {
  return {
    type: types.COMPENSATION_CALCULATOR_LOAD,
    payload: {
      compensationInfo: data,
      requestId: requestId,
    },
  };
};

const compensationCalculatorUpdate = (data, requestId) => {
  return {
    type: types.COMPENSATION_CALCULATOR_UPDATE,
    payload: {
      compensationInfo: data,
      requestId: requestId,
    },
  };
};

const requestDetailsUpdated = (data, requestId, employeeId) => {
  return {
    type: types.REQUEST_DETAILS_UPDATED,
    payload: {
      requestDetails: data,
      requestId: requestId,
      employeeId: employeeId,
    },
  };
};

const employeeProfileUpdate = (data, employeeId) => {
  return {
    type: types.EMPLOYEE_PROFILE_UPDATE,
    payload: {
      employeeProfile: data,
      employeeId: employeeId,
    },
  };
};

const requestDetailsError = () => {
  return {
    type: types.REQUEST_DETAILS_ERROR,
  };
};

const generalInfoUpdated = (data, requestId, employeeId) => {
  return {
    type: types.GENERAL_INFO_UPDATED,
    payload: {
      generalInfo: data,
      requestId: requestId,
      employeeId: employeeId,
    },
  };
};

const legalizationDocumentsCitizenshipUpdate = (data, uuid) => {
  return {
    type: types.LEGALIZATION_DOCUMENTS_CITIZENSHIP_UPDATE,
    payload: {
      citizenship: data.citizenship,
      uuid: uuid,
    },
  };
};

const legalizationDocumentsReasonUpdate = (data, uuid) => {
  return {
    type: types.LEGALIZATION_DOCUMENTS_REASON_UPDATE,
    payload: {
      reason: data,
      uuid: uuid,
    },
  };
};

const trackingDocumentsLoad = (employeeId, data) => {
  return {
    type: types.TRACKING_DOCUMENTS_LOAD,
    payload: {
      employeeId: employeeId,
      trackingDocuments: data,
    },
  };
};

const trackingDocumentAdd = (gfId, data) => {
  return {
    type: types.TRACKING_DOCUMENT_ADD,
    payload: {
      gfId: gfId,
      document: data,
    },
  };
};

const contactInfoUpdated = (data, requestId, employeeId) => {
  return {
    type: types.CONTACT_INFO_UPDATED,
    payload: {
      contactInfo: data,
      requestId: requestId,
      employeeId: employeeId,
    },
  };
};

const legalizationFormLoaded = (data) => {
  return {
    type: types.LEGALIZATION_FORM_LOADED,
    payload: {
      legalizationForm: data,
    },
  };
};

const legalizationFormUpdated = (data) => {
  return {
    type: types.LEGALIZATION_FORM_UPDATED,
    payload: {
      legalizationForm: data,
    },
  };
};

const legalizationFormsAdd = (data) => {
  return {
    type: types.LEGALIZATION_FORMS_ADD,
    payload: {
      legalizationForms: data,
    },
  };
};

const legalizationFormsUpdated = (data) => {
  return {
    type: types.LEGALIZATION_FORMS_UPDATED,
    payload: {
      legalizationForms: data,
    },
  };
};

const legalizationFormsLoaded = (data, filterByCountry) => {
  return {
    type: types.LEGALIZATION_FORMS_LOADED,
    payload: {
      legalizationForms: data.results,
      next: data.next,
      previous: data.previous,
      filterByCountry: filterByCountry,
      count: data.count,
    },
  };
};

const legalizationDocumentAdd = (data, gfId, empId) => {
  return {
    type: types.LEGALIZATION_DOCUMENT_ADD,
    payload: {
      legalizationDocument: data,
      gfId: gfId,
      employeeId: empId,
    },
  };
};

const legalizationDocumentUpdate = (data, gfId, formId, empId) => {
  return {
    type: types.LEGALIZATION_DOCUMENT_UPDATE,
    payload: {
      legalizationDocument: data,
      gfId: gfId,
      formId: formId,
      employeeId: empId,
    },
  };
};

const legalizationDocumentAttachmentAdd = (data, gfId, formId, empId) => {
  return {
    type: types.LEGALIZATION_DOCUMENT_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      gfId: gfId,
      formId: formId,
      employeeId: empId,
    },
  };
};

const legalizationDocumentAttachmentDelete = (attachmentId, gfId, formId, employeeId) => {
  return {
    type: types.LEGALIZATION_DOCUMENT_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      gfId: gfId,
      formId: formId,
      employeeId: employeeId,
    },
  };
};

const legalizationDocumentDelete = (gfId, formId, employeeId) => {
  return {
    type: types.LEGALIZATION_DOCUMENT_DELETE,
    payload: {
      gfId: gfId,
      formId: formId,
      employeeId: employeeId,
    },
  };
};

const legalizationFormsEmployeeLoad = (data, parameters, countryId) => {
  return {
    type: types.LEGALIZATION_FORMS_EMPLOYEE_LOAD,
    payload: {
      legalizationForms: data,
      parameters: parameters,
      countryId: countryId,
    },
  };
};

const feedbackTableLoad = (data, page) => {
  return {
    type: types.FEEDBACK_TABLE_LOAD,
    payload: {
      next: data.next,
      feedbacks: data.results,
      previous: data.previous,
      count: data.count,
      page: page,
    },
  };
};

const legalizationRequestListLoad = (data, page) => {
  return {
    type: types.LEGALIZATION_REQUEST_LIST_LOAD,
    payload: {
      next: data.next,
      legalizationRequestsColumns: data.results?.columns || [],
      legalizationRequestsData: data.results?.data || [],
      previous: data.previous,
      count: data.count,
      page: page,
    },
  };
};

const legalizationRequestLoaded = (data) => {
  return {
    type: types.LEGALIZATION_REQUEST_LOADED,
    payload: {
      legalizationRequest: data,
    },
  };
};

const legalizationRequestUpdated = (data) => {
  return {
    type: types.LEGALIZATION_REQUEST_UPDATED,
    payload: {
      legalizationRequest: data,
    },
  };
};

const legalizationRequestCountriesLoad = (data) => {
  return {
    type: types.LEGALIZATION_REQUEST_COUNTRIES_LOAD,
    payload: {
      legalizationRequestCountries: data,
    },
  };
};

const feedbackFormUpdated = (data) => {
  return {
    type: types.FEEDBACK_FORM_UPDATED,
    payload: {
      feedbackForm: data,
    },
  };
};

const feedbackFormsAdd = (data) => {
  return {
    type: types.FEEDBACK_FORMS_ADD,
    payload: {
      feedbackForms: data,
    },
  };
};

const feedbackFormsUpdated = (data) => {
  return {
    type: types.FEEDBACK_FORMS_UPDATED,
    payload: {
      feedbackForms: data,
    },
  };
};

const feedbackFormLoaded = (data) => {
  return {
    type: types.FEEDBACK_FORM_LOADED,
    payload: {
      feedbackForm: data,
    },
  };
};

const feedbackFormsLoaded = (data, filterByCountry) => {
  return {
    type: types.FEEDBACK_FORMS_LOADED,
    payload: {
      feedbackForms: data.results,
      next: data.next,
      previous: data.previous,
      filterByCountry: filterByCountry,
      count: data.count,
    },
  };
};

const feedbackFormsEmployeeLoad = (data, parameters, countryId) => {
  return {
    type: types.FEEDBACK_FORMS_EMPLOYEE_LOAD,
    payload: {
      feedbackForms: data,
      parameters: parameters,
      countryId: countryId,
    },
  };
};

const excelFormLoaded = (data) => {
  return {
    type: types.EXCEL_FORM_LOADED,
    payload: {
      excelForm: data,
    },
  };
};

const excelFormUpdated = (data) => {
  return {
    type: types.EXCEL_FORM_UPDATED,
    payload: {
      excelForm: data,
    },
  };
};

const excelFormsAdd = (data) => {
  return {
    type: types.EXCEL_FORMS_ADD,
    payload: {
      excelForms: data,
    },
  };
};

const excelFormsUpdate = (data) => {
  return {
    type: types.EXCEL_FORMS_UPDATE,
    payload: {
      excelForms: data.results,
      next: data.next,
      previous: data.previous,
    },
  };
};

const excelFormsLoaded = (data, filterByCountry) => {
  return {
    type: types.EXCEL_FORMS_LOADED,
    payload: {
      excelForms: data.results,
      next: data.next,
      previous: data.previous,
      filterByCountry: filterByCountry,
      count: data.count,
    },
  };
};

const excelFormsLoadRelative = (data, parameters, countryId) => {
  return {
    type: types.EXCEL_FORMS_LOAD_RELATIVE,
    payload: {
      excelForms: data,
      parameters: parameters,
      countryId: countryId,
    },
  };
};

const excelFormsUpdated = (data) => {
  return {
    type: types.EXCEL_FORMS_UPDATED,
    payload: {
      excelForms: data,
    },
  };
};

const excelDocumentUpdate = (data, gfId, formId, empId) => {
  return {
    type: types.EXCEL_DOCUMENT_UPDATE,
    payload: {
      excelDocument: data,
      gfId: gfId,
      formId: formId,
      employeeId: empId,
    },
  };
};

const excelDocumentAdd = (data, gfId, empId) => {
  return {
    type: types.EXCEL_DOCUMENT_ADD,
    payload: {
      excelDocument: data,
      gfId: gfId,
      employeeId: empId,
    },
  };
};

const relativeFormAdd = (data, gfId, relatedReqId) => {
  return {
    type: types.RELATIVE_FORM_ADD,
    payload: {
      form: data,
      gfId: gfId,
      relatedReqId: relatedReqId,
    },
  };
};
const relativeFormUpdate = (data, gfId, formId, relatedReqId) => {
  return {
    type: types.RELATIVE_FORM_UPDATE,
    payload: {
      form: data,
      gfId: gfId,
      formId: formId,
      relatedReqId: relatedReqId,
    },
  };
};

const relativeFormAttachmentAdd = (data, gfId, formId, relatedReqId) => {
  return {
    type: types.RELATIVE_FORM_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      gfId: gfId,
      formId: formId,
      relatedReqId: relatedReqId,
    },
  };
};

const relativeFormAttachmentDelete = (attachmentId, gfId, formId, relatedReqId) => {
  return {
    type: types.RELAIVE_FORM_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      gfId: gfId,
      formId: formId,
      relatedReqId: relatedReqId,
    },
  };
};

const genericRelocationFormLoaded = (data) => {
  return {
    type: types.GENERIC_RELOCATION_FORM_LOADED,
    payload: {
      genericRelocationForm: data,
    },
  };
};

const genericRelocationFormUpdated = (data) => {
  return {
    type: types.GENERIC_RELOCATION_FORM_UPDATED,
    payload: {
      genericRelocationForm: data,
    },
  };
};

const genericRelocationFormError = () => {
  return {
    type: types.GENERIC_RELOCATION_FORM_ERROR,
    payload: {
      genericRelocationForm: null,
    },
  };
};

const genericRelocationFormsLoaded = (data, filterByCountry) => {
  return {
    type: types.GENERIC_RELOCATION_FORMS_LOADED,
    payload: {
      genericRelocationForms: data.results,
      filterByCountry: filterByCountry,
      next: data.next,
      previous: data.previous,
      count: data.count,
    },
  };
};

const genericRelocationFormsUpdate = (data) => {
  return {
    type: types.GENERIC_RELOCATION_FORMS_UPDATE,
    payload: {
      genericRelocationForms: data.results,
      next: data.next,
      previous: data.previous,
    },
  };
};

const genericRelocationFormsUpdated = (data) => {
  return {
    type: types.GENERIC_RELOCATION_FORMS_UPDATED,
    payload: {
      genericRelocationForms: data,
    },
  };
};

const genericRelocationFormsAdd = (data) => {
  return {
    type: types.GENERIC_RELOCATION_FORMS_ADD,
    payload: {
      genericRelocationForms: data,
    },
  };
};

const genericRelocationFormsError = () => {
  return {
    type: types.GENERIC_RELOCATION_FORMS_ERROR,
    payload: {
      genericRelocationForms: [],
    },
  };
};

const genericFormLoaded = (data) => {
  return {
    type: types.GENERIC_FORM_LOADED,
    payload: {
      genericForm: data,
    },
  };
};

const genericFormUpdated = (data) => {
  return {
    type: types.GENERIC_FORM_UPDATED,
    payload: {
      genericForm: data,
    },
  };
};

const genericFormError = () => {
  return {
    type: types.GENERIC_FORM_ERROR,
    payload: {
      genericForm: null,
    },
  };
};

const genericFormsLoaded = (data, parameters, filterByCountry) => {
  return {
    type: types.GENERIC_FORMS_LOADED,
    payload: {
      genericForms: data.results,
      next: data.next,
      previous: data.previous,
      parameters: parameters,
      filterByCountry: filterByCountry,
      count: data.count,
    },
  };
};

const genericFormsEmployeeRelativeLoad = (data, parameters, countryId) => {
  return {
    type: types.GENERIC_FORMS_EMPLOYEE_RELATIVE_LOAD,
    payload: {
      genericForms: data,
      parameters: parameters,
      countryId: countryId,
    },
  };
};

const genericFormsUpdate = (data) => {
  return {
    type: types.GENERIC_FORMS_UPDATE,
    payload: {
      genericForms: data.results,
      next: data.next,
      previous: data.previous,
    },
  };
};

const genericFormsUpdated = (data) => {
  return {
    type: types.GENERIC_FORMS_UPDATED,
    payload: {
      genericForms: data,
    },
  };
};

const genericFormsAdd = (data) => {
  return {
    type: types.GENERIC_FORMS_ADD,
    payload: {
      genericForms: data,
    },
  };
};

const genericFormsError = () => {
  return {
    type: types.GENERIC_FORMS_ERROR,
    payload: {
      genericForms: [],
    },
  };
};

const otherDetailsLoaded = (data, requestId) => {
  return {
    type: types.OTHER_DETAILS_LOADED,
    payload: {
      otherDetails: data,
      requestId: requestId,
    },
  };
};

const relatedRequestsAdd = (data, requestId) => {
  return {
    type: types.RELATED_REQUESTS_ADD,
    payload: {
      relativeRequest: data,
      requestId: requestId,
    },
  };
};

const relatedPetRequestAdd = (data, requestId) => {
  return {
    type: types.RELATED_PET_REQUEST_ADD,
    payload: {
      petRequest: data,
      requestId: requestId,
    },
  };
};

const relatedInfoLoaded = (data, rel_req_id) => {
  return {
    type: types.REQUEST_RELATED_INFO_LOADED,
    payload: {
      relatedInfo: data,
      relativeReqId: rel_req_id,
    },
  };
};

const petsInfoLoaded = (data, rel_req_id) => {
  return {
    type: types.REQUEST_PETS_INFO_LOADED,
    payload: {
      petsInfo: data,
      petReqId: rel_req_id,
    },
  };
};

const petsTransportationDetailsUpdate = (data, relatedReqId) => {
  return {
    type: types.PETS_TRANSPORTATION_DETAILS_UPDATE,
    payload: {
      transportationDetails: data,
      petReqId: relatedReqId,
    },
  };
};

const petsTransportationDetailsAdd = (data, petReqId) => {
  return {
    type: types.PETS_TRANSPORTATION_DETAILS_ADD,
    payload: {
      transportationDetails: data,
      petReqId: petReqId,
    },
  };
};

const otherDetailsUpdated = (data, id) => {
  return {
    type: types.OTHER_DETAILS_UPDATED,
    payload: {
      otherDetails: data,
      requestId: id,
    },
  };
};

const requestNationalitiesLoaded = (data) => {
  return {
    type: types.REQUEST_NATIONALITIES_LOADED,
    payload: {
      next: data.next,
      nationalitiesList: data.results,
      previous: data.previous,
    },
  };
};

const requestCitizenShipsLoaded = (data) => {
  return {
    type: types.REQUEST_CITIZENSHIPS_LOADED,
    payload: {
      citizenshipsList: data.results,
    },
  };
};

const requestNationalitiesError = () => {
  return {
    type: types.REQUEST_NATIONALITIES_ERROR,
    payload: {
      next: null,
      nationalitiesList: null,
      previous: null,
    },
  };
};

const searchEmployeesLoaded = (data) => {
  return {
    type: types.SEARCH_EMPLOYEES_LOADED,
    payload: {
      next: data.next,
      searchEmployeesList: data.results,
      previous: data.previous,
    },
  };
};

const searchEmployeesForCreatingCaseLoaded = (data) => {
  return {
    type: types.SEARCH_EMPLOYEES_FOR_CREATE_CASE_LOADED,
    payload: {
      searchEmployeesList: data,
    },
  };
};

const searchEmployeesUpdated = (data) => {
  return {
    type: types.SEARCH_EMPLOYEES_UPDATED,
    payload: {
      next: data.next,
      searchEmployeesList: data.results,
      previous: data.previous,
    },
  };
};

const searchEmployeesError = () => {
  return {
    type: types.SEARCH_EMPLOYEES_ERROR,
    payload: {
      next: null,
      searchEmployeesList: null,
      previous: null,
    },
  };
};

const searchRequestForRelatedProfiles = (data) => {
  return {
    type: types.SEARCH_REQUEST_FOR_RELATED_PROFILES,
    payload: {
      searchRequestsList: data,
    },
  };
};

const linkedRequestsUpdated = (data, requestId) => {
  return {
    type: types.LINKED_REQUESTS_UPDATED,
    payload: {
      linkedRequests: data,
    },
  };
};

const relocationDocumentAdd = (data, gfId, reqId) => {
  return {
    type: types.RELOCATION_DOCUMENT_ADD,
    payload: {
      relocationDocument: data,
      gfId: gfId,
      requestId: reqId,
    },
  };
};

const relocationDocumentUpdate = (data, gfId, formId, reqId) => {
  return {
    type: types.RELOCATION_DOCUMENT_UPDATE,
    payload: {
      relocationDocument: data,
      gfId: gfId,
      formId: formId,
      requestId: reqId,
    },
  };
};

const documentAdd = (data, gfId, employeeId) => {
  return {
    type: types.DOCUMENT_ADD,
    payload: {
      document: data,
      gfId: gfId,
      employeeId: employeeId,
    },
  };
};
const excelFormAttachmentAdd = (data, gfId, formId, employeeId) => {
  return {
    type: types.EXCEL_FORM_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      gfId: gfId,
      formId: formId,
      employeeId: employeeId,
    },
  };
};

const documentAttachmentAdd = (data, gfId, formId, empId) => {
  return {
    type: types.DOCUMENT_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      gfId: gfId,
      formId: formId,
      employeeId: empId,
    },
  };
};

const trackingDocumentAttachmentAdd = (data, gfId, formId) => {
  return {
    type: types.TRACKING_DOCUMENT_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      gfId: gfId,
      formId: formId,
    },
  };
};

const ticketAttachmentAdd = (data, tdId, requestId) => {
  return {
    type: types.TICKET_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      tdId: tdId,
      requestId: requestId,
    },
  };
};

const relatedTicketAttachmentAdd = (data, tdId, relatedReqId) => {
  return {
    type: types.RELATED_TICKET_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      tdId: tdId,
      relatedReqId: relatedReqId,
    },
  };
};

const petTicketAttachmentAdd = (data, tdId, petReqId) => {
  return {
    type: types.PET_TICKET_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      tdId: tdId,
      petReqId: petReqId,
    },
  };
};

const accommodationAttachmentAdd = (data, adId, requestId) => {
  return {
    type: types.ACCOMMODATION_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      adId: adId,
      requestId: requestId,
    },
  };
};

const documentUpdate = (data, gfId, formId, employeeId) => {
  return {
    type: types.DOCUMENT_UPDATE,
    payload: {
      document: data,
      gfId: gfId,
      formId: formId,
      employeeId: employeeId,
    },
  };
};

const relatedDocumentAdd = (data, gfId, relatedReqId) => {
  return {
    type: types.RELATED_DOCUMENT_ADD,
    payload: {
      document: data,
      gfId: gfId,
      relatedReqId: relatedReqId,
    },
  };
};

const relatedDocumentUpdate = (data, gfId, formId, relatedId, relatedReqId) => {
  return {
    type: types.RELATED_DOCUMENT_UPDATE,
    payload: {
      document: data,
      gfId: gfId,
      formId: formId,
      relatedId: relatedId,
      relatedReqId: relatedReqId,
    },
  };
};

const relatedDocumentAttachmentAdd = (data, gfId, formId, relatedId, relatedReqId) => {
  return {
    type: types.RELATED_DOCUMENT_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      gfId: gfId,
      formId: formId,
      relatedId: relatedId,
      relatedReqId: relatedReqId,
    },
  };
};

const petsDocumentAdd = (data, gfId, petId, petReqId) => {
  return {
    type: types.PETS_DOCUMENT_ADD,
    payload: {
      document: data,
      gfId: gfId,
      petId: petId,
      petReqId: petReqId,
    },
  };
};

const petsDocumentUpdate = (data, gfId, formId, relatedId, relReqId) => {
  return {
    type: types.PETS_DOCUMENT_UPDATE,
    payload: {
      document: data,
      gfId: gfId,
      formId: formId,
      relatedId: relatedId,
      petReqId: relReqId,
    },
  };
};

const petDocumentAttachmentAdd = (data, gfId, formId, petId, petReqId) => {
  return {
    type: types.PET_DOCUMENT_ATTACHMENT_ADD,
    payload: {
      attachments: data,
      gfId: gfId,
      formId: formId,
      petId: petId,
      petReqId: petReqId,
    },
  };
};

const excelFormAttachmentDelete = (attachmentId, gfId, formId, employeeId) => {
  return {
    type: types.EXCEL_FORM_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      gfId: gfId,
      formId: formId,
      employeeId: employeeId,
    },
  };
};

const documentAttachmentDelete = (attachmentId, gfId, formId, employeeId) => {
  return {
    type: types.DOCUMENT_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      gfId: gfId,
      formId: formId,
      employeeId: employeeId,
    },
  };
};

const trackingDocumentAttachmentDelete = (attachmentId, gfId, formId) => {
  return {
    type: types.TRACKING_DOCUMENT_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      gfId: gfId,
      formId: formId,
    },
  };
};

const documentFormDelete = (gfId, formId, employeeId) => {
  return {
    type: types.DOCUMENT_FORM_DELETE,
    payload: {
      gfId: gfId,
      formId: formId,
      employeeId: employeeId,
    },
  };
};

const documentFormArchive = (gfId, formId, data, employeeId) => {
  return {
    type: types.DOCUMENT_FORM_ARCHIVE,
    payload: {
      gfId: gfId,
      formId: formId,
      form: data,
      employeeId: employeeId,
    },
  };
};

const relativeDocumentFormArchive = (gfId, formId, data, relatedReqId) => {
  return {
    type: types.RELATIVE_DOCUMENT_FORM_ARCHIVE,
    payload: {
      gfId: gfId,
      formId: formId,
      form: data,
      relatedReqId: relatedReqId,
    },
  };
};

const documentFormUnarchive = (gfId, formId, data, employeeId) => {
  return {
    type: types.DOCUMENT_FORM_UNARCHIVE,
    payload: {
      gfId: gfId,
      formId: formId,
      form: data,
      employeeId: employeeId,
    },
  };
};

const relativeDocumentFormUnarchive = (gfId, formId, data, relatedReqId) => {
  return {
    type: types.RELATIVE_DOCUMENT_FORM_UNARCHIVE,
    payload: {
      gfId: gfId,
      formId: formId,
      form: data,
      relatedReqId: relatedReqId,
    },
  };
};

const relatedDocumentFormDelete = (gfId, formId, relatedId, relatedReqId) => {
  return {
    type: types.RELATED_DOCUMENT_FORM_DELETE,
    payload: {
      gfId: gfId,
      formId: formId,
      relatedId: relatedId,
      relatedReqId: relatedReqId,
    },
  };
};

const petDocumentFormDelete = (gfId, formId, petId, petReqId) => {
  return {
    type: types.PET_DOCUMENT_FORM_DELETE,
    payload: {
      gfId: gfId,
      formId: formId,
      petId: petId,
      petReqId: petReqId,
    },
  };
};

const relatedDocumentAttachmentDelete = (attachmentId, gfId, formId, relatedId, relatedReqId) => {
  return {
    type: types.RELATED_DOCUMENT_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      gfId: gfId,
      formId: formId,
      relatedId: relatedId,
      relatedReqId: relatedReqId,
    },
  };
};

const petDocumentAttachmentDelete = (attachmentId, gfId, formId, petId, petReqId) => {
  return {
    type: types.PET_DOCUMENT_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      gfId: gfId,
      formId: formId,
      petId: petId,
      petReqId: petReqId,
    },
  };
};

const accommodationAttachmentDelete = (attachmentId, adId, requestId) => {
  return {
    type: types.ACCOMMODATION_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      adId: adId,
      requestId: requestId,
    },
  };
};

const ticketAttachmentDelete = (attachmentId, tdId, requestId) => {
  return {
    type: types.TICKET_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      tdId: tdId,
      requestId: requestId,
    },
  };
};

const ticketFormDelete = (tdId, requestId) => {
  return {
    type: types.TICKET_FORM_DELETE,
    payload: {
      tdId: tdId,
      requestId: requestId,
    },
  };
};

const relatedTicketFormDelete = (tdId, relatedReqId) => {
  return {
    type: types.RELATED_TICKET_FORM_DELETE,
    payload: {
      tdId: tdId,
      relatedReqId: relatedReqId,
    },
  };
};

const petTicketFormDelete = (tdId, petReqId) => {
  return {
    type: types.PET_TICKET_FORM_DELETE,
    payload: {
      tdId: tdId,
      petReqId: petReqId,
    },
  };
};

const accommodationFormDelete = (adId, requestId) => {
  return {
    type: types.ACCOMMODATION_FORM_DELETE,
    payload: {
      adId: adId,
      requestId: requestId,
    },
  };
};

const vacAppointmentFormDelete = (vacId, requestId) => {
  return {
    type: types.VAC_APPOINTMENT_FORM_DELETE,
    payload: {
      vacId: vacId,
      requestId: requestId,
    },
  };
};

const relatedVacAppointmentFormDelete = (vacId, relatedReqId) => {
  return {
    type: types.RELATED_VAC_APPOINTMENT_FORM_DELETE,
    payload: {
      vacId: vacId,
      relatedReqId: relatedReqId,
    },
  };
};

const relatedForeignPoliceFormDelete = (vacId, relatedReqId) => {
  return {
    type: types.RELATED_FOREIGN_POLICE_DELETE,
    payload: {
      vacId: vacId,
      relatedReqId: relatedReqId,
    },
  };
};

const relatedTicketAttachmentDelete = (attachmentId, tdId, relatedReqId) => {
  return {
    type: types.RELATED_TICKET_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      tdId: tdId,
      relatedReqId: relatedReqId,
    },
  };
};

const petTicketAttachmentDelete = (attachmentId, tdId, petReqId) => {
  return {
    type: types.PET_TICKET_ATTACHMENT_DELETE,
    payload: {
      attachmentId: attachmentId,
      tdId: tdId,
      petReqId: petReqId,
    },
  };
};

const vacAppointmentAdd = (data, requestId) => {
  return {
    type: types.VAC_APPOINTMENT_ADD,
    payload: {
      vacAppointment: data,
      requestId: requestId,
    },
  };
};

const relatedVacAppointmentAdd = (data, relatedReqId) => {
  return {
    type: types.RELATED_VAC_APPOINTMENT_ADD,
    payload: {
      vacAppointment: data,
      relatedReqId: relatedReqId,
    },
  };
};

const relatedForeignPoliceAdd = (data, relatedReqId) => {
  return {
    type: types.RELATED_FOREIGN_POLICE_ADD,
    payload: {
      vacAppointment: data,
      relatedReqId: relatedReqId,
    },
  };
};

const currentVacAppointmentIsNotifiedUpdate = (requestId) => {
  return {
    type: types.CURRENT_VAC_NOTIFIED_UPDATE,
    payload: {
      requestId: requestId,
    },
  };
};

const currentRelatedVacAppointmentIsNotifiedUpdate = (relatedId) => {
  return {
    type: types.RELATED_CURRENT_VAC_NOTIFIED_UPDATE,
    payload: {
      relatedId: relatedId,
    },
  };
};

const currentRelatedForeignPoliceIsNotifiedUpdate = (relatedId) => {
  return {
    type: types.RELATED_CURRENT_FOREIGN_POLICE_NOTIFIED_UPDATE,
    payload: {
      relatedId: relatedId,
    },
  };
};

const transportationDetailsAdd = (data, requestId) => {
  return {
    type: types.TRANSPORTATION_DETAILS_ADD,
    payload: {
      transportationDetails: data,
      requestId: requestId,
    },
  };
};

const relatedTransportationDetailsAdd = (data, relatedReqId) => {
  return {
    type: types.RELATED_TRANSPORTATION_DETAILS_ADD,
    payload: {
      transportationDetails: data,
      relatedReqId: relatedReqId,
    },
  };
};

const transportationDetailsUpdate = (data, requestId) => {
  return {
    type: types.TRANSPORTATION_DETAILS_UPDATE,
    payload: {
      transportationDetails: data,
      requestId: requestId,
    },
  };
};

const relatedTransportationDetailsUpdate = (data, relatedReqId) => {
  return {
    type: types.RELATED_TRANSPORTATION_DETAILS_UPDATE,
    payload: {
      transportationDetails: data,
      relatedReqId: relatedReqId,
    },
  };
};

const vacAppointmentUpdate = (data, relatedId) => {
  return {
    type: types.VAC_APPOINTMENT_UPDATE,
    payload: {
      vacAppointment: data,
      requestId: relatedId,
    },
  };
};

const relatedVacAppointmentUpdate = (data, relatedReqId, isDisplaySendVacDetails) => {
  return {
    type: types.RELATED_VAC_APPOINTMENT_UPDATE,
    payload: {
      vacAppointment: data,
      relatedReqId: relatedReqId,
      isDisplaySendVacDetails: isDisplaySendVacDetails,
    },
  };
};

const relatedForeignPoliceUpdate = (data, relatedReqId, isDisplaySendVacDetails) => {
  return {
    type: types.RELATED_FOREIGN_POLICE_UPDATE,
    payload: {
      vacAppointment: data,
      relatedReqId: relatedReqId,
      isDisplaySendVacDetails: isDisplaySendVacDetails,
    },
  };
};

const countriesForRelocationLoad = (data) => {
  return {
    type: types.COUNTRIES_FOR_RELOCATION_LOAD,
    payload: {
      countriesForRelocation: data,
    },
  };
};

const countriesWithFAQLoad = (data) => {
  return {
    type: types.COUNTRIES_WITH_FAQ_LOAD,
    payload: {
      countriesWithFAQ: data,
    },
  };
};

const citiesForRelocationLoad = (data) => {
  return {
    type: types.CITIES_FOR_RELOCATION_LOAD,
    payload: {
      citiesForRelocation: data,
    },
  };
};

const citiesLoad = (data) => {
  return {
    type: types.CITIES_LOAD,
    payload: {
      cities: data,
    },
  };
};

const citiesUpdate = (data) => {
  return {
    type: types.CITIES_UPDATE,
    payload: {
      city: data,
    },
  };
};

const countriesListLoad = (data) => {
  return {
    type: types.COUNTRIES_LIST_LOAD,
    payload: {
      countryList: data,
    },
  };
};

const countriesWithCompaniesLoad = (data) => {
  return {
    type: types.COUNTRIES_WITH_COMPANIES_LOAD,
    payload: {
      countriesWithCompanies: data,
    },
  };
};

const responsibleGMGLoad = (data) => {
  return {
    type: types.RESPONSIBLE_GMG_LOAD,
    payload: {
      gmgResponsible: data,
    },
  };
};

const accommodationDetailsUpdate = (data, requestId) => {
  return {
    type: types.ACCOMMODATION_DETAILS_UPDATE,
    payload: {
      accommodationDetails: data,
      requestId: requestId,
    },
  };
};

const accommodationDetailsAdd = (data, requestId) => {
  return {
    type: types.ACCOMMODATION_DETAILS_ADD,
    payload: {
      accommodationDetails: data,
      requestId: requestId,
    },
  };
};

const requestCaseLoaded = (data) => {
  return {
    type: types.REQUEST_CASE_LOADED,
    payload: {
      next: data.next,
      requestCaseList: data.results,
      statistics: data.statistics,
      previous: data.previous,
    },
  };
};

const requestCaseError = () => {
  return {
    type: types.REQUEST_CASE_ERROR,
    payload: {
      next: null,
      requestCaseList: [],
      previous: null,
    },
  };
};

const requestCaseUpdated = (data) => {
  return {
    type: types.REQUEST_CASE_UPDATED,
    payload: {
      next: data.next,
      requestCaseList: data.results,
      statistics: data.statistics,
      previous: data.previous,
    },
  };
};

const requestCaseUpdate = (data) => {
  return {
    type: types.REQUEST_CASE_UPDATE,
    payload: {
      requestCaseList: data.requestCaseList,
      caseId: data.caseId,
    },
  };
};

const toastUpdate = (data) => {
  return {
    type: types.TOAST_UPDATE,
    payload: {
      message: data.message,
      type: data.type,
    },
  };
};

const toastReset = () => {
  return {
    type: types.TOAST_RESET,
    payload: {
      message: null,
      type: null,
    },
  };
};

const relatedGeneralInfoUpdate = (data, rel_id, rel_req_id) => {
  return {
    type: types.RELATED_GENERAL_INFO_UPDATE,
    payload: {
      generalInfo: data,
      relativeReqId: rel_req_id,
    },
  };
};

const relatedContactInfoUpdate = (data, rel_id, rel_req_id) => {
  return {
    type: types.RELATED_CONTACT_INFO_UPDATE,
    payload: {
      contactInfo: data,
      relativeId: rel_id,
      relativeReqId: rel_req_id,
    },
  };
};

const relatedEmploymentInfoUpdate = (data, rel_id, rel_req_id) => {
  return {
    type: types.RELATED_EMPLOYMENT_INFO_UPDATE,
    payload: {
      employmentInfo: data,
      relativeId: rel_id,
      relativeReqId: rel_req_id,
    },
  };
};

const petsGeneralInfoUpdate = (data, rel_req_id) => {
  return {
    type: types.PETS_GENERAL_INFO_UPDATE,
    payload: {
      petsInfo: data,
      petReqId: rel_req_id,
    },
  };
};

const hrNotesLoad = (data) => {
  return {
    type: types.HR_NOTES_LOAD,
    payload: {
      hrNotes: data,
    },
  };
};

const hrNotesUpdate = (data) => {
  return {
    type: types.HR_NOTES_UPDATE,
    payload: {
      hrNotes: data,
    },
  };
};

const genaralPetProfileNotesAdd = (data, pet_req_id) => {
  return {
    type: types.GENERAL_PET_PROFILE_NOTES_ADD,
    payload: {
      note: data,
      petReqId: pet_req_id,
    },
  };
};

const plannedRelocationNotesAdd = (data, request_id) => {
  return {
    type: types.PLANNED_RELOCATION_NOTES_ADD,
    payload: {
      note: data,
      requestId: request_id,
    },
  };
};

const genaralPetProfileNotesUpdate = (data, petReqId) => {
  return {
    type: types.GENERAL_PET_PROFILE_NOTES_UPDATE,
    payload: {
      note: data,
      petReqId: petReqId,
    },
  };
};

const plannedRelocationNotesUpdate = (data, requestId) => {
  return {
    type: types.PLANNED_RELOCATION_NOTES_UPDATE,
    payload: {
      note: data,
      requestId: requestId,
    },
  };
};

const requestHistoryLoaded = (data) => {
  return {
    type: types.REQUEST_HISTORY_LOADED,
    payload: {
      next: data.next,
      requestHistory: data.results,
      previous: data.previous,
      count: data.count,
    },
  };
};

const requestHistoryError = () => {
  return {
    type: types.REQUEST_HISTORY_ERROR,
    payload: {
      next: null,
      requestHistory: [],
      previous: null,
    },
  };
};

const requestHistoryUpdated = (data) => {
  return {
    type: types.REQUEST_HISTORY_UPDATED,
    payload: {
      next: data.next,
      requestHistory: data.results,
      previous: data.previous,
    },
  };
};

const relativeRequestHistoryLoaded = (data) => {
  return {
    type: types.RELATIVE_REQUEST_HISTORY_LOADED,
    payload: {
      next: data.next,
      relativeRequestHistory: data.results,
      previous: data.previous,
      count: data.count,
    },
  };
};

const relativeRequestHistoryError = () => {
  return {
    type: types.RELATIVE_REQUEST_HISTORY_ERROR,
    payload: {
      next: null,
      relativeRequestHistory: [],
      previous: null,
    },
  };
};

const petRequestHistoryLoaded = (data) => {
  return {
    type: types.PET_REQUEST_HISTORY_LOADED,
    payload: {
      next: data.next,
      petRequestHistory: data.results,
      previous: data.previous,
      count: data.count,
    },
  };
};

const petRequestHistoryError = () => {
  return {
    type: types.PET_REQUEST_HISTORY_ERROR,
    payload: {
      next: null,
      petRequestHistory: [],
      previous: null,
    },
  };
};

const unitsLoad = (data) => {
  return {
    type: types.UNITS_LOAD,
    payload: {
      units: data,
    },
  };
};

const companiesLoad = (data) => {
  return {
    type: types.COMPANIES_LOAD,
    payload: {
      companies: data,
    },
  };
};

const relocatedEmploymentInfoAdd = (data, employeeId) => {
  return {
    type: types.EMPLOYMENT_INFO_ADD,
    payload: {
      relocatedEmploymentInfo: data,
      employeeId: employeeId,
    },
  };
};

const employmentInfoUpdate = (data, employeeId) => {
  return {
    type: types.EMPLOYMENT_INFO_UPDATE,
    payload: {
      employmentInfo: data,
      employeeId: employeeId,
    },
  };
};

const approversLoad = (data, requestId) => {
  return {
    type: types.APPROVERS_INFO_LOAD,
    payload: {
      approvers: data,
      requestId: requestId,
    },
  };
};

const faqInfoLoad = (data, countryId) => {
  return {
    type: types.FAQ_INFO_LOAD,
    payload: {
      faqInfo: data,
      countryId: countryId,
    },
  };
};

const faqInfoUpdated = (data, countryId) => {
  return {
    type: types.FAQ_INFO_UPDATED,
    payload: {
      next: data.next,
      faqInfo: data,
      countryId: countryId,
      previous: data.previous,
    },
  };
};

const legalizationDocumentsLoad = (data, page) => {
  return {
    type: types.LEGALIZATION_DOCUMENTS_LOAD,
    payload: {
      next: data.next,
      previous: data.previous,
      legalizationDocuments: data.results,
      count: data.count,
      countByGroups: data.countByGroups,
      page: page,
    },
  };
};

const legalizationDocumentsAddEvent = (event, employeeId) => {
  return {
    type: types.LEGALIZATION_DOCUMENTS_ADD_EVENT,
    payload: {
      event: event,
      employeeId: employeeId,
    },
  };
};

const legalizationDocumentsUpdate = (data) => {
  return {
    type: types.LEGALIZATION_DOCUMENTS_UPDATE,
    payload: {
      next: data.next,
      previous: data.previous,
      legalizationDocuments: data.results,
      count: data.count,
    },
  };
};

const relocatedEmployeesLoaded = (data, page) => {
  return {
    type: types.RELOCATED_EMPLOYEES_LOADED,
    payload: {
      next: data.next,
      requestRelocatedEmployees: data.results,
      count: data.count,
      page: page,
      previous: data.previous,
    },
  };
};

const relocatedEmployeesExcelCheckboxesAdd = (data, employeeUuid) => {
  return {
    type: types.RELOCATED_EMPLOYEES_EXCEL_CHECKBOXES_ADD,
    payload: {
      generationExcelCheckbox: data,
      employeeUuid: employeeUuid,
    },
  };
};

const relocatedEmployeesExcelCheckboxesRemove = () => {
  return {
    type: types.RELOCATED_EMPLOYEES_EXCEL_CHECKBOXES_REMOVE,
    payload: {
      generationExcelCheckbox: {},
    },
  };
};

const legalizationDocumentsExcelCheckboxesAdd = (data, employeeUuid) => {
  return {
    type: types.LEGALIZATION_DOCUMENTS_EXCEL_CHECKBOXES_ADD,
    payload: {
      generationExcelCheckbox: data,
      employeeUuid: employeeUuid,
    },
  };
};

const legalizationDocumentsExcelCheckboxesRemove = () => {
  return {
    type: types.LEGALIZATION_DOCUMENTS_EXCEL_CHECKBOXES_REMOVE,
    payload: {
      generationExcelCheckbox: {},
    },
  };
};

const relocatedEmployeesError = () => {
  return {
    type: types.RELOCATED_EMPLOYEES_ERROR,
    payload: {
      next: null,
      requestRelocatedEmployees: [],
      previous: null,
    },
  };
};

const legalizationDocumentsError = () => {
  return {
    type: types.LEGALIZATION_DOCUMENTS_ERROR,
    payload: {
      next: null,
      documents: [],
      previous: null,
    },
  };
};

const relocatedEmployeesUpdated = (data) => {
  return {
    type: types.RELOCATED_EMPLOYEES_UPDATED,
    payload: {
      next: data.next,
      requestRelocatedEmployees: data.results,
      previous: data.previous,
    },
  };
};

const relocatedEmployeesAllCasesUpdate = () => {
  return {
    type: types.RELOCATED_EMPLOYEES_ALL_CASES_UPDATE,
    payload: {},
  };
};

const legalizationDocumentsAllCasesUpdate = () => {
  return {
    type: types.LEGALIZATION_DOCUMENTS_ALL_CASES_UPDATE,
    payload: {},
  };
};

const caseListAllCasesUpdate = () => {
  return {
    type: types.CASE_LIST_ALL_CASES_UPDATE,
    payload: {},
  };
};

const statisticsLoad = (data, params) => {
  return {
    type: types.STATISTICS_LOAD,
    payload: {
      statistics: data,
      params: params,
    },
  };
};

const changingRelatives = (data, requestId) => {
  return {
    type: types.CHANGED_RELATIVES,
    payload: {
      relative: data,
      requestId: requestId,
    },
  };
};

const relocationRoutesLoad = (data) => {
  return {
    type: types.RELOCATION_ROUTES_LOAD,
    payload: {
      relocationRoutes: data,
    },
  };
};

const relocationRoutesUpdate = (data, countryId) => {
  return {
    type: types.RELOCATION_ROUTES_UPDATE,
    payload: {
      relocationStepsData: data,
      countryId: countryId,
    },
  };
};

const relocationCostsLoad = (data) => {
  return {
    type: types.RELOCATION_COSTS_LOAD,
    payload: {
      relocationCosts: data,
    },
  };
};

const relocationCostsUpdate = (data, countryId, currencyType) => {
  return {
    type: types.RELOCATION_COSTS_UPDATE,
    payload: {
      relocationCosts: data,
      countryId: countryId,
      currencyType: currencyType,
    },
  };
};

const relocationCostsStructUpdate = (data) => {
  return {
    type: types.RELOCATION_COSTS_STRUCT_UPDATE,
    payload: {
      structItem: data.structItem,
      existence: data.existence,
    },
  };
};

const relocationCostsStructItemDelete = (data) => {
  return {
    type: types.RELOCATION_COSTS_STRUCT_ITEM_DELETE,
    payload: {
      structItem: data.structItem,
      existence: data.existence,
    },
  };
};

const relocationCostsStructNameEdit = (data) => {
  return {
    type: types.RELOCATION_COSTS_STRUCT_NAME_EDIT,
    payload: {
      oldStructItem: data.oldStructItem,
      structItem: data.structItem,
      existence: data.existence,
    },
  };
};

const changingVacRequired = (data, id) => {
  return {
    type: types.CHANGED_VAC_RELATIVES,
    payload: {
      relationType: data.relationType,
      relativeRequestId: data.relativeRequestId,
      existence_in_list: data.existence_in_list,
      requestId: id,
    },
  };
};

const changingForeignPoliceRequired = (data, id) => {
  return {
    type: types.CHANGED_FOREIGN_POLICE_RELATIVES,
    payload: {
      relationType: data.relationType,
      relativeRequestId: data.relativeRequestId,
      existence_in_list: data.existence_in_list,
      requestId: id,
    },
  };
};

const changingTdRequired = (data, id) => {
  return {
    type: types.CHANGED_TD_RELATIVES,
    payload: {
      relationType: data.relationType,
      relativeRequestId: data.relativeRequestId,
      existence_in_list: data.existence_in_list,
      requestId: id,
    },
  };
};

const deleteRelativeProfile = (requestRelId, relId) => {
  return {
    type: types.DELETE_RELATIVE_PROFILE,
    payload: {
      relativeId: relId,
      requestRelId: requestRelId,
    },
  };
};

const deletePetProfile = (requestPetId, petId) => {
  return {
    type: types.DELETE_PET_PROFILE,
    payload: {
      petId: petId,
      requestPetId: requestPetId,
    },
  };
};

const deletePetRequest = (requestPetId, requestId) => {
  return {
    type: types.DELETE_PET_REQUEST,
    payload: {
      requestId: requestId,
      requestPetId: requestPetId,
    },
  };
};

const deleteRelativeRequest = (requestRelId, requestId) => {
  return {
    type: types.DELETE_RELATIVE_REQUEST,
    payload: {
      requestId: requestId,
      requestRelId: requestRelId,
    },
  };
};

const requestDocumentsLoaded = (data, id) => {
  return {
    type: types.REQUEST_DOCUMENTS_LOADED,
    payload: {
      documents: data,
      requestId: id,
    },
  };
};

const relatedDocGeneralInfoUpdate = (data, rel_id, rel_req_id) => {
  return {
    type: types.RELATED_DOC_GENERAL_INFO_UPDATE,
    payload: {
      generalInfo: data,
      relativeReqId: rel_req_id,
    },
  };
};

const petsDocGeneralInfoUpdate = (data, rel_req_id) => {
  return {
    type: types.PETS_DOC_GENERAL_INFO_UPDATE,
    payload: {
      petsInfo: data,
      petReqId: rel_req_id,
    },
  };
};

const relatedDocRequestsAdd = (data, requestId) => {
  return {
    type: types.RELATED_DOC_REQUESTS_ADD,
    payload: {
      relativeRequest: data,
      requestId: requestId,
    },
  };
};

const relatedPetDocRequestAdd = (data, requestId) => {
  return {
    type: types.RELATED_PET_DOC_REQUEST_ADD,
    payload: {
      petRequest: data,
      requestId: requestId,
    },
  };
};

const changingVacDocRequired = (data, id) => {
  return {
    type: types.CHANGED_VAC_DOC_RELATIVES,
    payload: {
      relationType: data.relationType,
      relativeRequestId: data.relativeRequestId,
      existenceInList: data.existence_in_list,
      requestId: id,
    },
  };
};

const generalDocumentsInfoUpdated = (data, employeeId) => {
  return {
    type: types.GENERAL_DOCS_INFO_UPDATED,
    payload: {
      generalInfo: data,
      employeeId: employeeId,
    },
  };
};

const requestExistRelativesAdd = (data, requestId, relativeRequests, petRequests) => {
  return {
    type: types.EXISTING_RELATIVES_ADD,
    payload: {
      requestId: requestId,
      existingRelatives: data,
      relativeRequests: relativeRequests,
      petRequests: petRequests,
    },
  };
};

const existingRelatedDocAdd = (relativesDocs, petsDocs, relatives) => {
  return {
    type: types.EXISTING_RELATED_DOC_ADD,
    payload: {
      relativesDocs: relativesDocs,
      petsDocs: petsDocs,
      relatives: relatives,
    },
  };
};

const addRequestEvent = (newEvent, requestId) => {
  return {
    type: types.REQUEST_DETAILS_ADD_EVENT,
    payload: {
      newEvent: newEvent,
      requestId: requestId,
    },
  };
};

const setEventsViewed = (instanceId, eventType, isViewed) => {
  return {
    type: types.EVENTS_SET_VIEWED,
    payload: {
      instanceId: instanceId,
      eventType: eventType,
      isViewed: isViewed,
    },
  };
};

const employeeProfileLoaded = (data, id) => {
  return {
    type: types.EMPLOYEE_PROFILE_LOADED,
    payload: {
      employeeProfile: data,
      employeeId: id,
    },
  };
};

const addProfileEvent = (event, employeeId) => {
  return {
    type: types.EMPLOYEE_PROFILE_ADD_EVENT,
    payload: {
      event: event,
      employeeId: employeeId,
    },
  };
};

const employeeHistoryLoaded = (data) => {
  return {
    type: types.EMPLOYEE_HISTORY_LOADED,
    payload: {
      next: data.next,
      employeeHistory: data.results,
      previous: data.previous,
      count: data.count,
    },
  };
};

const employeeHistoryError = () => {
  return {
    type: types.EMPLOYEE_HISTORY_ERROR,
    payload: {
      next: null,
      employeeHistory: [],
      previous: null,
    },
  };
};

const generationExcelCheckboxesAdd = (data, employeeUuid) => {
  return {
    type: types.GENERATION_EXCEL_CHECKBOXES_ADD,
    payload: {
      generationExcelCheckbox: data,
      employeeUuid: employeeUuid,
    },
  };
};

const generationExcelCheckboxesRemove = () => {
  return {
    type: types.GENERATION_EXCEL_CHECKBOXES_REMOVE,
    payload: {
      generationExcelCheckbox: {},
    },
  };
};

const relatedCasesUpdated = (data, employeeId) => {
  return {
    type: types.RELATED_CASES_UPDATE,
    payload: {
      relatedCases: data.relatedCases,
      employeeId: employeeId,
    },
  };
};

const vacAppointmentAddressesLoad = (data) => {
  return {
    type: types.VAC_APPOINTMENT_ADDRESSES_LOADED,
    payload: {
      addressesInfo: data,
    },
  };
};

const remindersLoad = (data) => {
  return {
    type: types.REMINDERS_LOAD,
    payload: {
      count: data.count,
      reminders: data.results,
    },
  };
};

const reminderUpdate = (data, reminderUuid) => {
  return {
    type: types.REMINDER_UPDATE,
    payload: {
      reminder: data,
      reminderUuid: reminderUuid,
    },
  };
};

const clearDocumentsDashboardLoad = (data) => {
  return {
    type: types.CLEAR_DOCUMENTS_DASHBOARD_LOAD,
    payload: {
      count: data.count,
      clearDocumentsDashboard: data.results,
    },
  };
};

const clearDocumentsDashboardUpdate = (data, id) => {
  return {
    type: types.CLEAR_DOCUMENTS_DASHBOARD_UPDATE,
    payload: {
      clearDocument: data,
      clearDocumentId: id,
    },
  };
};

// const clearDocumentsDashboardUpdate = (data, reminderUuid) => {
//   return {
//     type: types.CLEAR_DOCUMENTS_DASHBOARD_UPDATE,
//     payload: {
//       reminder: data,
//       reminderUuid: reminderUuid,
//     },
//   };
// };

const reminderAuthorsLoad = (data) => {
  return {
    type: types.REMINDERS_AUTHOR_LOAD,
    payload: {
      authorList: data,
    },
  };
};

const employeeRemindersLoad = (employeeId, data) => {
  return {
    type: types.EMPLOYEE_REMINDERS_LOAD,
    payload: {
      employeeId: employeeId,
      employeeReminders: {
        count: data.count,
        reminders: data.results,
      },
    },
  };
};

const employeeCommentsLoad = (employeeId, data, page) => {
  return {
    type: types.EMPLOYEE_COMMENTS_LOAD,
    payload: {
      employeeId: employeeId,
      comments: data.results,
      count: data.count,
      page: page,
    },
  };
};

const requestEmployeeCommentUpdate = (data) => {
  return {
    type: types.REQUEST_DOCS_COMMENT_UPDATE,
    payload: {
      employeeComment: data,
    },
  };
};

const profileEmployeeCommentUpdate = (employeeId, data) => {
  return {
    type: types.PROFILE_DOCS_COMMENT_UPDATE,
    payload: {
      employeeId: employeeId,
      employeeComment: data,
    },
  };
};

const workPlaceCitiesLoad = (cities) => {
  return {
    type: types.WORK_PLACE_CITIES_LOAD,
    payload: {
      cities: cities,
    },
  };
};

const employeesWithEmailsLoad = (data) => {
  return {
    type: types.EMPLOYEES_WITH_EMAILS_LOAD,
    payload: {
      employees: data,
    },
  };
};

const emailTemplatesLoad = (data) => {
  return {
    type: types.EMAIL_TEMPLATES_LOAD,
    payload: {
      emailTemplates: data,
    },
  };
};

const emailTemplateLoad = (data, templateId) => {
  return {
    type: types.EMAIL_TEMPLATE_LOAD,
    payload: {
      emailTemplate: data,
      templateId: templateId,
    },
  };
};

const contactEmployeeTemplateLoad = (data, requestId) => {
  return {
    type: types.CONTACT_EMPLOYEE_TEMPLATE_LOAD,
    payload: {
      emailTemplate: data,
      requestId: requestId,
    },
  };
};

const foreignPoliceAppointmentAdd = (data, requestId) => {
  return {
    type: types.FOREIGN_POLICE_APPOINTMENT_ADD,
    payload: {
      vacAppointment: data,
      requestId: requestId,
    },
  };
};

const foreignPoliceAppointmentUpdate = (data, relatedId) => {
  return {
    type: types.FOREIGN_POLICE_APPOINTMENT_UPDATE,
    payload: {
      vacAppointment: data,
      requestId: relatedId,
    },
  };
};

const foreignPoliceAppointmentDelete = (vacId, requestId) => {
  return {
    type: types.FOREIGN_POLICE_APPOINTMENT_DELETE,
    payload: {
      vacId: vacId,
      requestId: requestId,
    },
  };
};

const foreignPoliceAddressesLoad = (data) => {
  return {
    type: types.FOREIGN_POLICE_ADDRESSES_LOADED,
    payload: {
      addressesInfo: data,
    },
  };
};

const foreignPoliceIsNotified = (requestId) => {
  return {
    type: types.FOREIGN_POLICE_IS_NOTIFIED,
    payload: {
      requestId: requestId,
    },
  };
};

const logger = {
  setEventsViewed,
  authenticateSuccess,
  authorizationSuccess,
  requestListLoaded,
  requestListError,
  requestListUpdated,
  requestListUpdate,
  requestDetailsLoaded,
  requestDetailsUpdated,
  requestDetailsError,
  generalInfoUpdated,
  contactInfoUpdated,
  genericFormLoaded,
  genericFormUpdated,
  genericFormError,
  otherDetailsLoaded,
  otherDetailsUpdated,
  requestNationalitiesLoaded,
  requestNationalitiesError,
  searchEmployeesLoaded,
  searchEmployeesUpdated,
  searchEmployeesError,
  vacAppointmentAdd,
  vacAppointmentUpdate,
  relatedInfoLoaded,
  transportationDetailsAdd,
  transportationDetailsUpdate,
  relatedVacAppointmentAdd,
  relatedVacAppointmentUpdate,
  relatedTransportationDetailsAdd,
  relatedTransportationDetailsUpdate,
  accommodationDetailsUpdate,
  accommodationDetailsAdd,
  petsInfoLoaded,
  petsTransportationDetailsUpdate,
  petsTransportationDetailsAdd,
  countriesForRelocationLoad,
  requestCaseLoaded,
  requestCaseError,
  requestCaseUpdated,
  requestCaseUpdate,
  citiesForRelocationLoad,
  responsibleGMGLoad,
  relatedRequestsAdd,
  relatedPetRequestAdd,
  genericFormsLoaded,
  genericFormsUpdate,
  genericFormsAdd,
  genericFormsUpdated,
  genericFormsError,
  documentAdd,
  documentUpdate,
  relatedDocumentUpdate,
  relatedDocumentAdd,
  petsDocumentUpdate,
  petsDocumentAdd,
  documentAttachmentAdd,
  relatedDocumentAttachmentAdd,
  petDocumentAttachmentAdd,
  toastUpdate,
  toastReset,
  ticketAttachmentAdd,
  relatedTicketAttachmentAdd,
  petTicketAttachmentAdd,
  accommodationAttachmentAdd,
  requestCitizenShipsLoaded,
  relatedGeneralInfoUpdate,
  relatedContactInfoUpdate,
  relatedEmploymentInfoUpdate,
  petsGeneralInfoUpdate,
  hrNotesLoad,
  hrNotesUpdate,
  genaralPetProfileNotesAdd,
  genaralPetProfileNotesUpdate,
  requestHistoryLoaded,
  requestHistoryError,
  requestHistoryUpdated,
  relativeRequestHistoryLoaded,
  relativeRequestHistoryError,
  petRequestHistoryLoaded,
  petRequestHistoryError,
  companiesLoad,
  unitsLoad,
  relocatedEmploymentInfoAdd,
  employmentInfoUpdate,
  documentAttachmentDelete,
  relatedDocumentAttachmentDelete,
  petDocumentAttachmentDelete,
  ticketAttachmentDelete,
  relatedTicketAttachmentDelete,
  petTicketAttachmentDelete,
  accommodationAttachmentDelete,
  ticketFormDelete,
  relatedTicketFormDelete,
  petTicketFormDelete,
  accommodationFormDelete,
  vacAppointmentFormDelete,
  relatedVacAppointmentFormDelete,
  documentFormDelete,
  relatedDocumentFormDelete,
  petDocumentFormDelete,
  faqInfoLoad,
  faqInfoUpdated,
  relocatedEmployeesLoaded,
  relocatedEmployeesError,
  relocatedEmployeesUpdated,
  statisticsLoad,
  documentFormArchive,
  documentFormUnarchive,
  relativeDocumentFormArchive,
  relativeDocumentFormUnarchive,
  plannedRelocationNotesAdd,
  plannedRelocationNotesUpdate,
  changingRelatives,
  relocationCostsLoad,
  relocationCostsUpdate,
  relocationCostsStructUpdate,
  relocationCostsStructItemDelete,
  relocationCostsStructNameEdit,
  changingVacRequired,
  changingTdRequired,
  excelFormLoaded,
  excelFormUpdated,
  excelFormsAdd,
  excelFormsUpdate,
  excelFormsLoaded,
  excelFormsUpdated,
  excelDocumentUpdate,
  excelDocumentAdd,
  countriesWithFAQLoad,
  approversLoad,
  searchEmployeesForCreatingCaseLoaded,
  searchRequestForRelatedProfiles,
  linkedRequestsUpdated,
  countriesListLoad,
  citiesUpdate,
  citiesLoad,
  currentVacAppointmentIsNotifiedUpdate,
  currentRelatedVacAppointmentIsNotifiedUpdate,
  countriesWithCompaniesLoad,
  deleteRelativeProfile,
  deletePetProfile,
  requestDocumentsLoaded,
  relatedDocGeneralInfoUpdate,
  petsDocGeneralInfoUpdate,
  relatedDocRequestsAdd,
  relatedPetDocRequestAdd,
  changingVacDocRequired,
  genericRelocationFormLoaded,
  genericRelocationFormUpdated,
  genericRelocationFormError,
  genericRelocationFormsLoaded,
  genericRelocationFormsUpdate,
  genericRelocationFormsUpdated,
  genericRelocationFormsAdd,
  genericRelocationFormsError,
  relocationDocumentAdd,
  relocationDocumentUpdate,
  generalDocumentsInfoUpdated,
  requestExistRelativesAdd,
  existingRelatedDocAdd,
  relocationRoutesLoad,
  relocationRoutesUpdate,
  excelFormAttachmentAdd,
  excelFormAttachmentDelete,
  relativeFormAdd,
  relativeFormUpdate,
  relativeFormAttachmentAdd,
  relativeFormAttachmentDelete,
  deletePetRequest,
  deleteRelativeRequest,
  compensationCalculatorLoad,
  compensationCalculatorUpdate,

  addProfileEvent,
  addRequestEvent,

  legalizationDocumentsLoad,
  legalizationDocumentsUpdate,
  legalizationDocumentsCitizenshipUpdate,
  legalizationDocumentsReasonUpdate,
  legalizationDocumentsAddEvent,
  trackingDocumentsLoad,
  trackingDocumentAdd,
  trackingDocumentAttachmentAdd,
  trackingDocumentAttachmentDelete,

  employeeProfileLoaded,
  employeeRemindersLoad,
  employeeHistoryLoaded,
  employeeHistoryError,

  generationExcelCheckboxesAdd,
  generationExcelCheckboxesRemove,

  relatedCasesUpdated,

  vacAppointmentAddressesLoad,

  employeeProfileUpdate,
  profileDocumentsLoad,

  remindersLoad,
  reminderAuthorsLoad,
  reminderUpdate,

  relocatedEmployeesExcelCheckboxesAdd,
  relocatedEmployeesExcelCheckboxesRemove,
  legalizationDocumentsError,
  relocatedEmployeesAllCasesUpdate,

  employeeCommentsLoad,
  requestEmployeeCommentUpdate,
  profileEmployeeCommentUpdate,
  genericFormsEmployeeRelativeLoad,
  excelFormsLoadRelative,
  caseListAllCasesUpdate,
  workPlaceCitiesLoad,

  legalizationFormLoaded,
  legalizationFormUpdated,
  legalizationFormsAdd,
  legalizationFormsUpdated,
  legalizationFormsLoaded,
  legalizationDocumentAdd,
  legalizationDocumentUpdate,
  legalizationDocumentAttachmentAdd,
  legalizationDocumentAttachmentDelete,
  legalizationDocumentDelete,
  legalizationFormsEmployeeLoad,

  employeesWithEmailsLoad,
  emailTemplatesLoad,
  emailTemplateLoad,
  contactEmployeeTemplateLoad,

  feedbackFormUpdated,
  feedbackFormsAdd,
  feedbackFormsUpdated,
  feedbackFormLoaded,
  feedbackFormsLoaded,
  feedbackFormsEmployeeLoad,
  requestDetailsFeedbackFormsLoad,
  requestDetailsEmployeeFeedbackUpdate,
  feedbackTableLoad,

  legalizationRequestLoaded,
  legalizationRequestUpdated,
  legalizationRequestListLoad,
  legalizationRequestCountriesLoad,

  caseControllerLoad,
  caseControllerUpdate,
  userPhotoLoaded,
  dateFormatLoaded,
  dateFormatUpdated,

  foreignPoliceAppointmentAdd,
  foreignPoliceAppointmentUpdate,
  foreignPoliceAppointmentDelete,
  foreignPoliceAddressesLoad,
  foreignPoliceIsNotified,
  changingForeignPoliceRequired,

  relatedForeignPoliceAdd,
  relatedForeignPoliceUpdate,
  relatedForeignPoliceFormDelete,
  currentRelatedForeignPoliceIsNotifiedUpdate,

  legalizationDocumentsAllCasesUpdate,
  legalizationDocumentsExcelCheckboxesAdd,
  legalizationDocumentsExcelCheckboxesRemove,

  clearDocumentsDashboardLoad,
  clearDocumentsDashboardUpdate,
};

export default logger;
