import React, { useState } from 'react';
import classNames from 'classnames';

import DeleteForm from './DeleteForm/DeleteForm';
import CancelForm from './CancelForm/CancelForm';
import EditFormInConstructor from './EditFormInConstructor/EditFormInConctructor';
import ArchiveForm from './ArchiveForm/ArchiveForm';
import MainSettingsIcon from './MainSettingsIcon/MainSettingsIcon';
import './SlideOutIcons.scss';

const SlideOutIcons = ({
  form,
  formikProps,
  document,
  formIndex,
  titleLength,
  remove,
  deleteForm,
  archiveForm,
  additionalFlag,
  isCase,
  withArchiveForm,
  withEditFormInConstructor,
}) => {
  const [toggle, setToggle] = useState(0);

  return (
    <>
      {!additionalFlag ? (
        <div
          className={classNames('icons', { children: (document.forms.length > 1 || !form.id) && formIndex > 0 })}
          style={{ left: `${titleLength?.width + 32}px` }}
        >
          <MainSettingsIcon toggle={toggle} setToggle={setToggle} />

          {((form.id && document.isCanBePlural && isCase) ||
            (form.id && document.isCanBePlural && !isCase && document.forms[formIndex]?.isDraft)) && (
            <DeleteForm deleteForm={deleteForm} toggle={toggle} />
          )}

          {!form.id && formikProps?.values[document.slug]?.length > 1 && (
            <CancelForm toggle={toggle} formIndex={formIndex} remove={remove} />
          )}

          {form.id && withArchiveForm && <ArchiveForm toggle={toggle} archiveForm={archiveForm} />}

          {withEditFormInConstructor && (
            <EditFormInConstructor
              withEditFormInConstructor={withEditFormInConstructor}
              toggle={toggle}
              documentId={document.id}
            />
          )}
        </div>
      ) : (
        <div className={classNames('icons')} style={{ left: `${titleLength?.width - 20}px` }}>
          <MainSettingsIcon toggle={toggle} setToggle={setToggle} />
          <EditFormInConstructor
            withEditFormInConstructor={withEditFormInConstructor}
            toggle={toggle}
            documentId={document.id}
          />
        </div>
      )}
    </>
  );
};

export default SlideOutIcons;
