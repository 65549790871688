import { sidebarNavigation } from './sidebarNavigation';
import Menu from '../../Common/Menu/Menu';
import ContactManager from '../ContactManager/ContactManager';
import './menu.scss';
import { NavLink } from 'react-router-dom';

const exclusionTabs = ['vacAppointmentTab', 'transportationDetailsTab', 'accommodationDetailsTab'];

const SiderMenu = ({ employee, request }) => {
  const isStatusVisaOnly = request && request.caseType.toLowerCase() === 'visa only';

  const tabsController = request.tabsController;

  const sidebarNavigationOptions = sidebarNavigation.reduce((accumulator, currentItem) => {
    const tabControllerName = `${currentItem.key}Tab`;

    if (
      tabsController.hasOwnProperty(tabControllerName) &&
      !tabsController[tabControllerName] &&
      exclusionTabs.every((tab) => tab !== tabControllerName)
    ) {
      return accumulator;
    }

    if (currentItem.key === 'archivedDocuments' && employee.archivedDocuments?.length === 0) {
      return accumulator;
    }

    if (
      isStatusVisaOnly &&
      (currentItem.key === 'plannedRelocation' ||
        currentItem.key === 'relocationDetails' ||
        currentItem.key === 'additionalSteps' ||
        currentItem.key === 'compensation' ||
        currentItem.key === 'relocationDate')
    ) {
      return accumulator;
    }

    if (!(request.withChildren || request.withPets || request.withSpouse) && currentItem.key === 'relatedProfiles') {
      return accumulator;
    }

    // if (
    //   currentItem.key === 'appointments' &&
    //   ((!request?.isVacRequired && !isStatusVisaOnly) || !request?.isForeignPoliceRequired)
    // ) {
    //   if (!request?.isVacRequired && !isStatusVisaOnly)
    //   if (!request?.isVacRequired && !isStatusVisaOnly && currentItem.key === 'vacAppointment') {
    //     return accumulator;
    //   }
    // }
    // if (currentItem.key === 'appointments' && request?.isVacRequired && request?.isForeignPoliceRequired) {
    //   return accumulator;
    // }

    if (currentItem.key === 'appointments') {
      if (currentItem.key === 'appointments' && (request?.isVacRequired || request?.isForeignPoliceRequired)) {
        const currentItemCopy = _.cloneDeep(currentItem);
        currentItemCopy.children = currentItemCopy.children.filter((item) => {
          if (!request?.isForeignPoliceRequired && item.key === 'foreignPoliceAppointment') {
            return false;
          }
          if (!request?.isVacRequired && item.key === 'vacAppointment') {
            return false;
          }

          return true;
        });
        accumulator.push(currentItemCopy);
        return accumulator;
      }
      return accumulator;
    }

    // if (!request?.isVacRequired && !isStatusVisaOnly && currentItem.key === 'vacAppointment') {
    //   return accumulator;
    // }
    // if (!request?.isVacRequired && !isStatusVisaOnly && currentItem.key === 'vacAppointment') {
    //   return accumulator;
    // }
    // if (currentItem.key === 'transportationDetails') {
    //   console.log(
    //     !request?.isTransportationRequired && !isStatusVisaOnly && currentItem.key === 'transportationDetails',
    //   );
    //   console.log(!request?.isTransportationRequired, !isStatusVisaOnly);
    // }
    if (!request?.isTransportationRequired && currentItem.key === 'transportationDetails') {
      return accumulator;
    }
    if (!request?.isAccommodationRequired && currentItem.key === 'accommodationDetails') {
      return accumulator;
    }

    accumulator.push(currentItem);
    return accumulator;
  }, []);

  return (
    <div className="request-details__menu">
      <Menu sidebarNavigation={sidebarNavigationOptions} />
      {request && (
        <ContactManager
          requestId={request.id}
          isManagerNotifiedAboutGatheredVac={request.isManagerNotifiedAboutGatheredVac}
          managerNotifiedAt={request.managerNotifiedAt}
          employeeNotifiedAboutFinishedRelocationAt={request.employeeNotifiedAboutFinishedRelocationAt}
          status={request.status}
        />
      )}
    </div>
  );
};

export default SiderMenu;
