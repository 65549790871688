import { types } from '../actions/types';

const initialState = {
  error: null,
  loading: false,
  user: JSON.parse(localStorage.getItem('user')) || null,
  userPhotoLink: null,
  isAuthenticated: !!JSON.parse(localStorage.getItem('user')) || false,
  isAuthorizated: !!JSON.parse(localStorage.getItem('user')) || false,
  dateFormat: null,
  isDateFormatLoaded: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        loading: true,
        error: null,
      };
    case types.AUTHORIZATION_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        isAuthorizated: action.payload.isAuthorizated,
        loading: false,
        error: null,
      };
    case types.USER_PHOTO_LOADED:
      return {
        ...state,
        userPhotoLink: action.payload.userPhotoLink,
      };
    case types.DATE_FORMAT_LOADED:
      return {
        ...state,
        dateFormat: action.payload.dateFormat,
        isDateFormatLoaded: true,
      };
    case types.DATE_FORMAT_UPDATED:
      return {
        ...state,
        dateFormat: action.payload.dateFormat,
      };
    default:
      return {
        ...state,
      };
  }
};

export default authReducer;
