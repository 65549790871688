import { useState } from 'react';

import EmployeeDocument from './EmployeeDocument';
import EmployeeEducation from './EmployeeEducation';
import SelectDocumentsToDisplayModal from './SelectDocumentsToDisplayModal/SelectDocumentsToDisplayModal';
import { editCaseDocumentsToDisplay } from '../../../../store/actions/request';
import { editEmployeeDocumentsToDisplay } from '../../../../store/actions/employeeProfile';
import DocumentsStatus from './DocumentsStatus/DocumentsStatus';

import './employeeDocuments.scss';

const EmployeeDocuments = ({
  employeeDocuments,
  employeeId,
  requestId,
  country,
  documentsToDisplay,
  documentsStatus,
  isLegalizationRequestDocuments,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);

  const { status, message: documentsStatusMessage, verificationEndAt, verifiedForms } = documentsStatus || {};

  return (
    <>
      <div className="employee-documents__container">
        {!isLegalizationRequestDocuments && (
          <button className="select-documents button btn-form main-btn btn-back" onClick={() => setModalVisible(true)}>
            Select documents to display
          </button>
        )}
        {!isLegalizationRequestDocuments && status && (
          <DocumentsStatus
            employeeId={employeeId}
            documentsStatus={status}
            documentsStatusMessage={documentsStatusMessage}
            verificationEndAt={verificationEndAt}
            verifiedForms={verifiedForms}
          />
        )}
        {employeeDocuments.length <= 0 && <p className="no-available">No available documents</p>}
        {employeeDocuments.length > 0 &&
          employeeDocuments.map((document) => {
            return (
              <div key={document.id}>
                <EmployeeDocument employeeId={employeeId} document={document} />
              </div>
            );
          })}
      </div>
      {isModalVisible && (
        <SelectDocumentsToDisplayModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          objectId={requestId ? requestId : employeeId}
          editDocumentsToDisplayFunction={requestId ? editCaseDocumentsToDisplay : editEmployeeDocumentsToDisplay}
          documentsToDisplay={documentsToDisplay}
          country={country}
        />
      )}
    </>
  );
};

export default EmployeeDocuments;
