import axios from '../../axios';
import queryString from 'query-string';
import _ from 'lodash';
import { uploadFileToS3 } from './uploadToS3';

import actionCreators from './actionCreators';

export const getLegalizationForm = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/generic-forms/${id}/`);
    dispatch(actionCreators.legalizationFormLoaded(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addLegalizationForm = (data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/generic-forms/`, data);
    dispatch(actionCreators.legalizationFormUpdated(response.data));
    dispatch(actionCreators.legalizationFormsAdd(response.data));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editLegalizationForm = (id, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/generic-forms/${id}/`, data);
    dispatch(actionCreators.legalizationFormUpdated(response.data));
    dispatch(actionCreators.legalizationFormsUpdated(response.data));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const getLegalizationForms = (page, parameters) => async (dispatch) => {
  try {
    const params = parameters ? { ...parameters, form_type: 'legalization' } : { form_type: 'legalization' };
    const response = await axios.get(`/api/v1/generic-forms/`, {
      params: { ...params, page: page },
      paramsSerializer: function (params) {
        return queryString.stringify(params, {
          arrayFormat: 'comma',
          skipNull: true,
          skipEmptyString: true,
        });
      },
    });
    dispatch(actionCreators.legalizationFormsLoaded(response.data, parameters?.countries));

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getLegalizationFormsForEmployee = (parameters) => async (dispatch) => {
  try {
    const params = parameters ? { ...parameters, form_type: 'legalization' } : { form_type: 'legalization' };

    const response = await axios.get(`/api/v1/generic-forms/`, {
      params: { ...params },
      paramsSerializer: function (params) {
        return queryString.stringify(params, {
          arrayFormat: 'comma',
          skipNull: true,
          skipEmptyString: true,
        });
      },
    });

    dispatch(actionCreators.legalizationFormsEmployeeLoad(response.data, parameters));

    return response.data;
  } catch (error) {
    dispatch(actionCreators.genericFormsError());
    console.error(error);
  }
};

export const editLegalizationDocument = (empId, gfId, formId, data) => {
  return (dispatch) => {
    return axios
      .put(`/api/v1/employees/${empId}/gf/${gfId}/form/${formId}/`, data)
      .then((response) => {
        dispatch(actionCreators.legalizationDocumentUpdate(response.data, gfId, formId, empId));
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const addLegalizationDocument = (empId, gfId, data) => {
  return (dispatch) => {
    return axios
      .post(`/api/v1/employees/${empId}/gf/${gfId}/`, data)
      .then((response) => {
        dispatch(actionCreators.legalizationDocumentAdd(response.data, gfId, empId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const getPresignedUrl = async (employeeId, gfId, formId) => {
  try {
    const response = await axios.get(
      `/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/get-upload-credentials/`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addAttachment = (empId, gfId, formId, data) => {
  return (dispatch) => {
    return axios
      .post(`/api/v1/employees/${empId}/gf/${gfId}/form/${formId}/attachments/add-attachment/`, data)
      .then((response) => {
        dispatch(actionCreators.legalizationDocumentAttachmentAdd(response.data, gfId, formId, empId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const attachmentUpload = (files, employeeUuid, gfId, formId) => {
  return (dispatch) => {
    const handleUpload = async (employeeUuid, gfId, formId, file) => {
      const presignedUrl = await getPresignedUrl(employeeUuid, gfId, formId);
      const filename = '${filename}';

      try {
        await uploadFileToS3(presignedUrl, file);
        const data = {
          fileName: file.name,
          fileKey: presignedUrl.fields.key.replace(`${filename}`, file.name),
        };

        return dispatch(addAttachment(employeeUuid, gfId, formId, data));
      } catch (error) {
        throw error;
      }
    };

    return files.map((file, i) => {
      return handleUpload(employeeUuid, gfId, formId, file);
    });
  };
};

export const currentDocumentUpdate = (gfId, formId, data, employeeId) => {
  return (dispatch) => {
    return axios
      .put(`/api/v1/employees/${gfId}/form/${formId}/current/`, data)
      .then((response) => {
        dispatch(actionCreators.legalizationDocumentUpdate(response.data, gfId, formId, employeeId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const upsertDocuments = (
  documentsToUpdate,
  documentsToAdd,
  employeeId,
  gfId,
  valuesWithFilesToUpdate,
  isCurrentDocumentsToUpdate,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const documentsToAddPromises = documentsToAdd.map((item) => {
      return dispatch(addLegalizationDocument(employeeId, gfId, item)).then((response) => {
        const attachmentPromises =
          (item?.files && dispatch(attachmentUpload(item.files, employeeId, gfId, response.formId))) || [];
        const isCurrentPromises =
          item?.isCurrent &&
          dispatch(currentDocumentUpdate(gfId, response.formId, { isCurrent: item?.isCurrent }, employeeId));
        let promises = [];

        isCurrentPromises && promises.push(isCurrentPromises);
        promises = attachmentPromises ? promises.concat(attachmentPromises.flat()) : promises;

        return Promise.all(promises);
      });
    });

    const documentsToUpdatePromises = documentsToUpdate.map((item) => {
      return dispatch(editLegalizationDocument(employeeId, gfId, item.id, item));
    });

    const uploadAttachmentsPromises = valuesWithFilesToUpdate.map((item) => {
      return item?.files && dispatch(attachmentUpload(item.files, employeeId, gfId, item.id));
    });

    const isCurrentDocumentsToUpdatePromises =
      isCurrentDocumentsToUpdate &&
      isCurrentDocumentsToUpdate.map((item) => {
        return dispatch(currentDocumentUpdate(gfId, item.id, { isCurrent: item?.isCurrent }, employeeId));
      });

    const promises = documentsToAddPromises
      .concat(documentsToUpdatePromises)
      .concat(isCurrentDocumentsToUpdatePromises)
      .concat(uploadAttachmentsPromises.flat());

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
