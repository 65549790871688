import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getResponsibleGMG } from '../../store/actions/requestDetails';
import { columns } from './tableProperties';
import { getCountriesWithCompanies } from '../../store/actions/countriesForRelocation';

export const useGetColumns = () => {
  const dispatch = useDispatch();

  const responsibles = useSelector((state) => state.gmgResponsible.gmgResponsible || []);
  const countriesInfo = useSelector((state) => state.countries.countriesWithCompanies);

  useEffect(() => {
    if (!responsibles?.length) {
      dispatch(getResponsibleGMG());
    }
  }, [dispatch, responsibles?.length]);

  useEffect(() => {
    if (!countriesInfo?.length) {
      dispatch(
        getCountriesWithCompanies({
          countries_with_companies: true,
        }),
      );
    }
  }, [dispatch, countriesInfo?.length]);

  const responsibleSelectOptions = useMemo(
    () =>
      responsibles?.map((responsible) => {
        return {
          text: responsible.generalInfo.fullName,
          key: responsible.uuid,
          value: responsible.uuid,
          label: responsible.generalInfo.fullName,
        };
      }),
    [responsibles],
  );

  const responsibleSelectOptionsWithDefaultOptions = [{ value: 'Not provided', text: 'Not provided' }].concat(
    responsibleSelectOptions,
  );

  const countryOptions =
    countriesInfo?.map((country) => ({
      key: country.id,
      value: country.name,
      //   label: country.name,
      text: country.name,
    })) || [];

  columns.forEach((item) => {
    if (item.key === 'gmAssigned') {
      item.filters = responsibleSelectOptionsWithDefaultOptions;
    }
    if (item.key === 'destinationCountry') {
      item.filters = countryOptions;
    }
  });

  return columns;
};
