import FirstImg from '../../../assets/img/guidline/1.svg';
import SecondImg from '../../../assets/img/guidline/2.svg';
import ThirdImg from '../../../assets/img/guidline/3.svg';
import FoursImg from '../../../assets/img/guidline/4.svg';
import FifthImg from '../../../assets/img/guidline/5.svg';

export const getGuidelineInfo = (userType) => {
  return userType === 'profile'
    ? [
        {
          img: SecondImg,
          title: 'Fill in Documents (Personal Info, Documents and Forms <br> (if they were requested))',
        },
        {
          img: ThirdImg,
          title: 'Attach scans of documents <br> (if they were requested)',
        },

        {
          img: FifthImg,
          title:
            'Click on the <span className="bold-font">Send docs</span> button so Global Mobility can review your docs',
        },
      ]
    : [
        {
          img: FirstImg,
          title: 'Select checkbox(es) with whom are you planning to move',
        },
        {
          img: SecondImg,
          title: 'Fill in Documents (Personal Info, Documents and Forms <br> (if they were requested))',
        },
        {
          img: ThirdImg,
          title: 'Attach scans of documents <br> (if they were requested)',
        },
        {
          img: FoursImg,
          title: 'Add related Profiles and attach scans of requested documents',
        },
        {
          img: FifthImg,
          title: `Click on the <span className="bold-font">Send docs</span> button so Global Mobility can review your docs`,
        },
      ];
};
