import { types } from '../actions/types';

const initialState = {
  legalizationRequestCountries: [],
  countriesForRelocation: null,
  countriesWithCompanies: null,
  countriesWithFAQ: null,
  countriesList: [],
};

const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COUNTRIES_FOR_RELOCATION_LOAD:
      return {
        ...state,
        countriesForRelocation: action.payload.countriesForRelocation,
      };
    case types.COUNTRIES_WITH_FAQ_LOAD:
      return {
        ...state,
        countriesWithFAQ: action.payload.countriesWithFAQ,
      };
    case types.COUNTRIES_LIST_LOAD:
      return {
        ...state,
        countriesList: action.payload.countryList,
      };
    case types.COUNTRIES_WITH_COMPANIES_LOAD:
      return {
        ...state,
        countriesWithCompanies: action.payload.countriesWithCompanies,
      };
    case types.LEGALIZATION_REQUEST_COUNTRIES_LOAD:
      return {
        ...state,
        legalizationRequestCountries: action.payload.legalizationRequestCountries,
      };
    default:
      return {
        ...state,
      };
  }
};

export default countriesReducer;
