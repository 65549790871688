import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { getEmailTemplates, getEmployeesEmails } from '../../../store/actions/emailTemplates';
import ListTemplates from './ListTemplates/ListTemplates';
import SendEmailRouter from './SendEmail/SendEmailRouter';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import './emailTemplates.scss';

const EmailTemplates = () => {
  const dispatch = useDispatch();

  const { employeesWithEmails: employees, emailTemplates } = useSelector((state) => state.emailTemplates);

  const [isLoading, setLoading] = useState(!emailTemplates?.length);

  useEffect(() => {
    if (!emailTemplates?.length) {
      dispatch(getEmailTemplates()).then(() => setLoading(false));
    }
    if (!employees?.length) {
      dispatch(getEmployeesEmails());
    }
  }, []);

  const arrayEmails = emailTemplates
    .concat(emailTemplates)
    .concat(emailTemplates)
    .concat(emailTemplates)
    .concat(emailTemplates)
    .concat(emailTemplates)
    .concat(emailTemplates);

  return (
    <section className="email-templates__section">
      {/* {!isLoading && ( */}
      <div className="email-templates__wrapper">
        <Switch>
          <Route
            exact
            path="/email-templates"
            component={(props) => <SendEmailRouter {...props} employees={employees} />}
          />
          <Route
            exact
            path={`/email-templates/:id`}
            component={(props) => <SendEmailRouter {...props} employees={employees} />}
          />
        </Switch>
        {/* <ListTemplates templates={arrayEmails} isLoading={isLoading} /> */}
        <ListTemplates templates={emailTemplates} isLoading={isLoading} />
      </div>
      {/* )} */}
      {/* {isLoading && <SpinnerContainer />} */}
    </section>
  );
};

export default EmailTemplates;
