import { useDispatch } from 'react-redux';

import Modal from '../../../Shared/Modal/Modal';
import actionCreators from '../../../../store/actions/actionCreators';
import './warningModal.scss';

const WarningModal = ({ requestId, currentVacAppointment, isModalVisible, setModalVisible }) => {
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setModalVisible(false);
    dispatch(actionCreators.vacAppointmentUpdate({ ...currentVacAppointment, isSubmitting: false }, requestId));
  };

  return (
    <div className="vac-warning-modal">
      <Modal isVisible={isModalVisible} setIsVisible={handleCloseModal} size="l">
        <div className="modal-message">
          <h4 className="underlined panel-top-row">Your action is required</h4>
          <p>Visa appeal rejected, please cancel the Relocation case.</p>
          <button type="button" className="button btn-form main-btn btn-back ok-btn" onClick={handleCloseModal}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default WarningModal;
