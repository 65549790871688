import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';

import { getFormElement } from '../../Shared/Form/FormElements';
import { editRelocationRequest } from '../../../store/actions/request';

import './WithWhomBlock.scss';

const WithWhomBlock = ({ requestId, withWhom, setWithWhom, relativeRequests }) => {
  const dispatch = useDispatch();

  const relativesRelationType = Object.values(relativeRequests).map((item) => item.relatedInfo.relative.relationType);

  const onJustMe = (value) => {
    dispatch(
      editRelocationRequest(
        requestId,
        {
          with_spouse: !value,
          with_children: !value,
        },
        true,
      ),
    );

    setWithWhom((withWhom) => ({
      ...withWhom,
      ...{ withSpouse: !value, withChildren: !value },
    }));
  };

  const onWithSpouse = (value) => {
    dispatch(editRelocationRequest(requestId, { with_spouse: value }, true));

    setWithWhom((withWhom) => ({
      ...withWhom,
      ...{ withSpouse: value },
    }));
  };

  const onWithChildren = (value) => {
    dispatch(editRelocationRequest(requestId, { with_children: value }, true));

    setWithWhom((withWhom) => ({
      ...withWhom,
      ...{ withChildren: value },
    }));
  };

  const formJustMe = [
    {
      fieldName: 'withJustMe',
      type: 'Checkbox',
      label: 'Just me',
      updateselectvalueroot: onJustMe,
      disabled: relativesRelationType?.length > 0,
    },
  ];

  const formWithSpouse = [
    {
      fieldName: 'withSpouse',
      type: 'Checkbox',
      label: 'Spouse',
      updateselectvalueroot: onWithSpouse,
      disabled: relativesRelationType.some((item) => item.toLowerCase() === 'spouse'),
    },
  ];

  const formWithChildren = [
    {
      fieldName: 'withChildren',
      type: 'Checkbox',
      label: 'Children',
      updateselectvalueroot: onWithChildren,
      disabled: relativesRelationType.some((item) => item.toLowerCase() === 'child'),
    },
  ];

  return (
    <div className="accompanying__container">
      <h3 className="underlined">Accompanying</h3>
      <div className="checkbox-wrap">
        <p className="withWhom-lable">With whom are you planning to move?</p>
        <div className="final-checkbox-wrapper__block">
          <Formik
            enableReinitialize
            initialValues={{
              withJustMe: !withWhom.withSpouse && !withWhom.withChildren,
            }}
          >
            {(formikProps) => (
              <Form>
                {formJustMe.map((field) => (
                  <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                ))}
              </Form>
            )}
          </Formik>
          <Formik
            enableReinitialize
            initialValues={{
              withSpouse: withWhom.withSpouse,
            }}
          >
            {(formikProps) => (
              <Form>
                {formWithSpouse.map((field) => (
                  <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                ))}
              </Form>
            )}
          </Formik>
          <Formik
            enableReinitialize
            initialValues={{
              withChildren: withWhom.withChildren,
            }}
          >
            {(formikProps) => (
              <Form>
                {formWithChildren.map((field) => (
                  <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                ))}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default WithWhomBlock;
