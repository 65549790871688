import axios from '../../axios';
import actionCreators from '../actions/actionCreators';

export const getAttachmentLink = async (attachmentId, employeeId, gfId, formId, isCase) => {
  try {
    const response = await axios.get(
      isCase
        ? `/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/attachments/${attachmentId}/get-link/`
        : `/api/v1/employees/${employeeId}/gf/employee-edit/${gfId}/form/${formId}/attachments/${attachmentId}/get-link/`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const downloadArchiveAttachments = (employeeId, gfId, formId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .get(`/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/attachments/get-compressed/`, {
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const fileName =
          response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '') || `${Date.now()}.xlsx`;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        return 1;
      })
      .catch(async (error) => {
        toast.type = 'error';
        const responseData = await error.response?.data;
        const responseMessage = responseData === 'Internal server error' ? responseData : responseData.text();
        toast.errorMessage = await responseMessage;
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
        throw error;
      });
    // .finally(() => {
    //   dispatch(
    //     actionCreators.toastUpdate({
    //       type: toast.type,
    //       message: toast.errorMessage,
    //     }),
    //   );
    // });
  };
};

export const getTicketAttachmentLink = async (attachmentId) => {
  try {
    const response = await axios.get(
      `/api/v1/relocation-requests/transportation-details/attachments/${attachmentId}/get-link/`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getAccommodationAttachmentLink = async (attachmentId) => {
  try {
    const response = await axios.get(
      `/api/v1/relocation-requests/accommodation-details/attachments/${attachmentId}/get-link/`,
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
