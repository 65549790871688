export const CellType = {
  Link: 1,
  Checkbox: 2,
  InputNumber: 3,
  InputText: 4,
  Dropdown: 5,
  Mix: 7,
};

export const AvailableCurrencies = [
  { value: 'usd', label: '\u0024' },
  { value: 'eur', label: '\u20ac' },
  { value: 'pln', label: '\u007a\u0142' },
  { value: 'uah', label: '\u20b4' },
];

export const COLORS = [
  '#EB3D26',
  '#131314',
  '#404040',
  '#606060',
  '#808080',
  '#AFAFAF',
  '#D4D4D4',
  '#838485',
  '#d1dcdc',
  '#597373',
  '#97afaf',
  '#a82110',
  '#831a0c',
  '#C0C0C4',
  '#96969C',
  '#636369',
];

export const ToggleState = {
  UP: 1,
  NONE: 0,
  DOWN: -1,
};
