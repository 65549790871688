import Document from '../../../../../assets/img/document.svg';
import Close from '../../../../../assets/img/icons/exit.svg';
import NewAttach from '../../../../../assets/img/newAttach.svg';
import './imageGride.scss';

const ImageGride = ({ files, setFiles, open, formikProps, name }) => {
  const removeFile = (fileName) => () => {
    const newFiles = files.filter((file) => file.name !== fileName);
    setFiles(newFiles);
    formikProps.setFieldValue(name, newFiles);
  };

  return (
    <div className="image-gride">
      {files.map((attach, index) => (
        <div className="image-gride__display-attachment" key={attach.path + index}>
          <div className="attachment-container">
            <img src={Document} alt={attach.name} className="display-attachment__icon" />
            <span>{attach.name}</span>
            <div className="delete-attachment" data-tip="Delete attachment" onClick={removeFile(attach.name)}>
              <img src={Close} alt="Close" className="close-attachment" />
            </div>
          </div>
        </div>
      ))}
      <div className="display-attachment add-new-file__container" onClick={open}>
        <div className="attachment-container">
          <img src={NewAttach} alt="add file" className="add-new-file" />
        </div>
      </div>
    </div>
  );
};
export default ImageGride;
