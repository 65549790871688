import axios from '../../axios';
import actionCreators from './actionCreators';
import queryString from 'query-string';

export const createReminder = (employeeId, requestId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .post(`/api/v1/employees/${employeeId}/reminders/`, { requestId: requestId, ...data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editReminderStatus = (reminderId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .put(`/api/v1/reminders/${reminderId}/`, data)
      .then((response) => {
        dispatch(actionCreators.reminderUpdate(response.data, response.data?.uuid));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const getReminders = (page, filterParams, params) => {
  return (dispatch) => {
    return axios
      .get('/api/v1/reminders/', {
        params: { page: page, ...params, ...filterParams },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.remindersLoad(response.data));

        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const getReminderAuthors = () => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/reminders/authors/`)
      .then((response) => {
        dispatch(actionCreators.reminderAuthorsLoad(response.data));
        return response.data;
      })
      .catch((error) => {
        // toast.type = 'error';
        // toast.errorMessage = error.response?.data;

        console.error(error);
      });
  };
};
