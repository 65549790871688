export const formSchema = [
  {
    fieldName: 'company',
    type: 'InputText',
    label: 'Company name',
    disabled: true,
  },
  {
    fieldName: 'employmentDate',
    type: 'InputText',
    label: 'Planned employment date',
    disabled: true,
  },
];
