import CasesStatistics from './CasesStatistics/CasesStatistics';
import VACStatistics from './VACStatistics/VACStatistics';
import VisaGatheredStatistics from './VisaGatheredStatistics/VisaGatheredStatistics';
import AveragePeopleInCaseStatistics from './AveragePeopleInCaseStatistics/AveragePeopleInCaseStatistics';
import './relocationCases.scss';

const RelocationCases = () => {
  return (
    <div className="statistic__relocation-cases">
      <CasesStatistics analyticsSource={'sc'} />
      <VACStatistics analyticsSource={'sc'} />
      <VisaGatheredStatistics analyticsSource={'sc'} />
      <AveragePeopleInCaseStatistics analyticsSource={'sc'} />
    </div>
  );
};

export default RelocationCases;
