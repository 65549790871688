import AveragePeopleInCaseStatistics from '../RelocationCases/AveragePeopleInCaseStatistics/AveragePeopleInCaseStatistics';
import CasesStatistics from '../RelocationCases/CasesStatistics/CasesStatistics';
import VACStatistics from '../RelocationCases/VACStatistics/VACStatistics';
import VisaGatheredStatistics from '../RelocationCases/VisaGatheredStatistics/VisaGatheredStatistics';

import './visaOnly.scss';

const VisaOnly = () => {
  return (
    <div className="statistic__visa-only">
      <CasesStatistics analyticsSource={'voc'} />
      <VACStatistics analyticsSource={'voc'} />
      <VisaGatheredStatistics analyticsSource={'voc'} />
      <AveragePeopleInCaseStatistics analyticsSource={'voc'} />
    </div>
  );
};

export default VisaOnly;
