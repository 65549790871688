import { title, radioGroupOptions, filters } from './statisticsProperties';
import ChartBlock from '../../Common/ChartBlock/ChartBlock';
import { sumValues } from '../../../../helpers';
import { getSeriesData } from '../../Common/ChartBlock/chartProperties';
import { useGetStatisticsFilters } from '../../Common/hooks/useGetStatisticsFilters';

const VACStatistics = ({ analyticsSource }) => {
  const [statisctics, radioValue, onChangeRadioGroup, dateRangeValue, onChangeDateRange] = useGetStatisticsFilters({
    filters: { ...filters, analyticsSource: analyticsSource },
  });

  const series = [
    {
      name: 'count',
      data: getSeriesData(statisctics.data, radioValue),
    },
  ];

  const totalNumber = sumValues(statisctics.data) || 0;

  return (
    <ChartBlock
      series={series}
      title={title}
      isLoading={{ isLoading: statisctics.isLoading, status: statisctics.statusRequest }}
      radioGroupOptions={radioGroupOptions}
      totalNumber={totalNumber}
      radioValue={radioValue}
      onChangeRadioGroup={onChangeRadioGroup}
      dateRangeValue={dateRangeValue}
      onChangeDateRange={onChangeDateRange}
    />
  );
};

export default VACStatistics;
