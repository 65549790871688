import { useDispatch } from 'react-redux';
import { unArhiveDocumentForm } from '../../../../store/actions/uploadFile';
import ArchivedDocumentForm from '../../../Common/ArchivedDocumentForm/ArchivedDocumentForm';

const ArchivedDocument = ({ employeeId, document, relatedReqId, isCaseDisabled }) => {
  const dispatch = useDispatch();
  const formSchema = document.forms[0]?.fields;

  const handleUnarchiveForm = (employeeId, documentId, formId) => () => {
    dispatch(unArhiveDocumentForm({ isArchived: false }, employeeId, documentId, formId, 'related', relatedReqId));
  };

  if (!formSchema) {
    return <p>No available documents</p>;
  }

  return (
    <ArchivedDocumentForm
      document={document}
      employeeId={employeeId}
      unarchiveForm={handleUnarchiveForm}
      isCaseDisabled={isCaseDisabled}
    />
  );
};

export default ArchivedDocument;
