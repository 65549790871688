import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { ErrorMessage, Field, useField } from 'formik';
import classNames from 'classnames';

import { ReactComponent as CalendarIcon } from '../../../../assets/img/icons/calendar.svg';
import './checkboxGroupWithDates.scss';
import { useSelector } from 'react-redux';
import { defaultDateFormat } from '../../../../constants';

const CheckboxGroupWithDates = (props) => {
  const {
    name,
    label,
    options,
    placeholder,
    notify,
    formIndex,
    nameFieldArray,
    formikProps,
    canHaveDisplayHorizontal,
    disabled,
    isRequired,
    ...rest
  } = props;
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  const dateExtraFields =
    formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}DateExtraFields` : `${name}DateExtraFields`;
  const [extraField, , { setValue }] = useField(dateExtraFields);

  const handleDatePickerChange = (value, option) => {
    const obj = extraField.value ? { ...extraField.value } : {};
    if (value) {
      obj[option.key] = dayjs(value).format(defaultDateFormat);
    } else {
      if (obj.hasOwnProperty(option.key)) {
        delete obj[option.key];
      }
    }
    setValue(obj);
  };

  return (
    <div className="form-group radio-horizontal checkboxgroup__dates">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}
      <div className="checkboxes-vertical checkboxes-with-date-picker__wrapper">
        {options.map((option) => {
          const checkboxKey = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${option.key}` : `${option.key}`;
          const datePickerValue =
            extraField.value && extraField.value[option.key]
              ? dayjs(extraField.value[option.key], defaultDateFormat)
              : null;

          return (
            <div key={checkboxKey} className="checkbox-group-with-date-picker">
              <div className="custom-control custom-checkbox option-inline">
                <Field
                  className="custom-control-input"
                  type="checkbox"
                  id={checkboxKey}
                  name={fieldName}
                  value={option.value}
                  disabled={disabled}
                />
                <label className="custom-checkbox__label" htmlFor={checkboxKey}>
                  <span></span>
                  <p className="custom_checkbox__limited-text">{option.text}</p>
                </label>
              </div>
              <DatePicker
                defaultValue={datePickerValue}
                format={displayDateFormat}
                onChange={(date) => handleDatePickerChange(date, option)}
                className={'form-control ant-datepicker-wrapper'}
                placeholder={disabled ? '' : displayDateFormat}
                name={dateExtraFields}
                id={dateExtraFields}
                disabled={disabled}
                suffixIcon={<CalendarIcon />}
              />
            </div>
          );
        })}
      </div>
      <ErrorMessage
        name={fieldName}
        render={(msg) => (
          <span className="error" style={{ color: 'red' }}>
            {msg}
          </span>
        )}
      />
    </div>
  );
};

export default CheckboxGroupWithDates;
