import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import { SubmitButton } from '../../../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../../../Shared/Form/FormElements';
import { setExistingRelatives } from '../../../../../store/actions/requestDetails';
import { editRelocationRequest } from '../../../../../store/actions/request';

import './ExistingRelatives.scss';

const ExistingRelatives = ({ requestId, existingRelatives, withWhom, setWithWhom }) => {
  const dispatch = useDispatch();

  const formSchema = existingRelatives.map((item) => {
    return {
      fieldName: `relative_${item.id}`,
      type: 'CheckboxReverse',
      label: `${item.generalInfo.fullName} (${item.relationType})`,
    };
  });

  const initialValues = formSchema.reduce((accumulator, current) => {
    accumulator[current.fieldName] = false;
    return accumulator;
  }, {});

  const onSubmit = (values, { setSubmitting }) => {
    const valuesInfo = Object.keys(values).filter((key) => values[key]);

    const allExistingRelatives = [];
    const submitedRelativeTypes = [];

    valuesInfo.forEach((existRel) => {
      existingRelatives.forEach((relative) => {
        if (relative.id === +existRel.split('_')[1]) {
          submitedRelativeTypes.push(relative.relationType);
          allExistingRelatives.push({ relative_id: relative.id, relationType: 'relative' });
        }
      });
    });

    const uniqueRelativeTypes = [...new Set(submitedRelativeTypes)];

    if (!withWhom.withChildren && uniqueRelativeTypes.includes('child')) {
      dispatch(editRelocationRequest(requestId, { with_children: true }, true));
      setWithWhom((withWhom) => ({
        ...withWhom,
        ...{ withChildren: true },
      }));
    }

    if (!withWhom.withSpouse && uniqueRelativeTypes.includes('spouse')) {
      dispatch(editRelocationRequest(requestId, { with_spouse: true }, true));
      setWithWhom((withWhom) => ({
        ...withWhom,
        ...{ withSpouse: true },
      }));
    }

    dispatch(setExistingRelatives(requestId, { relative_list: allExistingRelatives })).finally(() => {
      setSubmitting(false);
    });
  };

  return (
    <div className="existing-relatives__wrapper">
      <Formik enableReinitialize onSubmit={onSubmit} initialValues={initialValues}>
        {(formikProps) => (
          <>
            <Form>
              <div className="relatives-form__container">
                {formSchema.map((field) => (
                  <div key={field.fieldName} className={field.fieldName}>
                    {getFormElement(field.type, field, formikProps)}
                  </div>
                ))}
              </div>
              {!formSchema?.length && <p>No existing related profiles to add</p>}

              <SubmitButton title="Submit" formikProps={formikProps} />
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

export default ExistingRelatives;
