import { Checkbox, Button } from 'antd';
import _ from 'lodash';

import './checkboxGroup.scss';

const CheckboxGroup = ({ options, selectedKeys, setSelectedKeys }) => {
  const selectedItems = selectedKeys[0]?.multipleFilter || [];

  const onChange = (value) => {
    if (value.length > 0) {
      setSelectedKeys([{ ...selectedKeys[0], multipleFilter: value }]);
    } else {
      const newSelectedKeys = { ...selectedKeys[0] };
      delete newSelectedKeys.multipleFilter;
      _.isEmpty(newSelectedKeys) ? setSelectedKeys([]) : setSelectedKeys([newSelectedKeys]);
    }
  };
  return (
    <div className="multiple-filter__checkbox-group">
      <Checkbox.Group onChange={onChange} value={selectedItems} className="multiple-filter__checkbox-menu">
        {options.map((option, i) => {
          return (
            <Checkbox key={option.value} value={option.value} style={{ margin: '0' }}>
              {option.label}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
      {/* <div className="multiple-filter__checkbox-group-buttons">
        <span className="reset-btn">Reset</span>
        <Button type="primary">OK</Button>
      </div> */}
    </div>
  );
};

export default CheckboxGroup;
