export const statusList = [
  {
    text: 'New',
    value: 'New',
  },
  {
    text: 'In progress',
    value: 'In Progress',
    statisticLabel: 'In Progress',
  },
  {
    text: 'On hold',
    value: 'On Hold',
  },
  {
    text: 'Completed',
    value: 'Completed',
  },
  {
    text: 'Canceled',
    value: 'Canceled',
  },
  {
    text: 'Ready for employment',
    value: 'Ready for employment',
  },
  {
    text: 'Country legalization',
    value: 'Country legalization',
  },
  {
    text: 'Relocation completed',
    value: 'Relocation completed',
  },
  {
    text: 'Visa only',
    value: 'Visa only',
  },
];

export const blueCardOptions = [
  { value: 'Assistance requested', text: 'Assistance requested' },
  { value: 'Application sent via Inpol', text: 'Application sent via Inpol' },
  { value: 'Application sent via post', text: 'Application sent via post' },
  { value: 'Planned decision date', text: 'Planned decision date' },
  { value: 'Stamp in passport obtained', text: 'Stamp in passport obtained' },
  { value: 'Decision issued', text: 'Decision Issued' },
  { value: 'Residence card obtained', text: 'Residence card obtained' },
];

export const workPermitOptions = [
  { value: 'o_wiadczenie_zezwolenie', text: 'Oświadczenie / zezwolenie' },
  { value: 'karta_polaka_student_visa', text: 'Karta Polaka / student visa' },
  { value: 'ukrainian_citizenship', text: 'Ukrainian citizenship' },
  { value: 'visa_pbh_humanitarian', text: 'Visa PBH / humanitarian' },
  { value: 'karta_pobytu', text: 'Karta pobytu' },
];

export const residencePermitTypeOptions = [
  { value: 'Blue Card', text: 'Blue Card' },
  { value: 'Temporary residence permit', text: 'Temporary residence permit' },
];

export const getCityOptions = (countriesInfo, country) => {
  return (
    countriesInfo
      ?.find((countryInfo) => countryInfo.name === country)
      ?.cities.map((city) => ({
        value: city.name,
        text: city.name,
      })) ?? []
  );
};
