import { useState } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { getFormElement } from '../../../Shared/Form/FormElements';
import { SubmitButton } from '../../../Shared/Form/Buttons/Buttons';
import { StatusModal } from '../StatusModal/StatusModal';
import { getFormSchemaWithRequiredField } from '../../../../helpers';
import { sendLegalizationDocumentsEmail } from '../../../../store/actions/emailTemplates';
import actionCreators from '../../../../store/actions/actionCreators';

import { validationSchema, formSchemaWithoutRequiredField } from './formSchema';

const SendBulkEmailForm = ({ employees, initialValues, id }) => {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState({ isVisible: false, status: false, isTemplateSelected: id });

  const onSubmit = (values, { setSubmitting }) => {
    sendLegalizationDocumentsEmail(values)
      .then((response) => {
        dispatch(actionCreators.legalizationDocumentsExcelCheckboxesRemove());
        setSubmitting(false);
        setIsModalVisible((prev) => ({ ...prev, isVisible: true, status: true }));
      })
      .catch(() => {
        setSubmitting(false);
        setIsModalVisible((prev) => ({ ...prev, isVisible: true, status: false }));
      });
  };

  const options = employees.map((item) => ({
    label: item.contactInfo.email,
    value: item.contactInfo.email,
    fullName: item.generalInfo.fullName,
    internalId: item.internalId,
  }));

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField).map((item) => {
    if (item.fieldName === 'emailList' || item.fieldName === 'ccList' || item.fieldName === 'bccList') {
      item.options = options;
    }
    return item;
  });

  return (
    <div className="send-email__wrapper" style={{ maxWidth: '900px' }}>
      <div className="send-email__header">
        <h3 className="underlined panel-top-row">Write an email</h3>
      </div>
      <div className="send-email">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {(formikProps) => (
            <Form>
              {formSchema.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
              ))}
              <div className="send-email__button-container">
                <SubmitButton title="Send" formikProps={formikProps} />
              </div>
            </Form>
          )}
        </Formik>
        {isModalVisible.isVisible && (
          <StatusModal setIsModalVisible={setIsModalVisible} isModalVisible={isModalVisible} />
        )}
      </div>
    </div>
  );
};

export default SendBulkEmailForm;
