import { sidebarNavigation } from './sidebarNavigation';
import Menu from '../../Common/Menu/Menu';
import './menu.scss';

const SiderMenu = ({ employee, isCandidate, isHrManager }) => {
  const sidebarNavigationOptions = sidebarNavigation.reduce((accumulator, currentItem) => {
    accumulator.push({ ...currentItem });
    return accumulator;
  }, []);

  return (
    <div className="request-details__menu">
      <Menu sidebarNavigation={sidebarNavigationOptions} />
    </div>
  );
};

export default SiderMenu;
