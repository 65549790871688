import { types } from '../actions/types';

const initialState = {
  citizenshipsList: [],
  nationalitiesList: [],
  next: null,
  previous: null,
};

const requestNationalitiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_NATIONALITIES_LOADED:
      return {
        ...state,
        nationalitiesList: action.payload.nationalitiesList,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.REQUEST_CITIZENSHIPS_LOADED:
      return {
        ...state,
        citizenshipsList: action.payload.citizenshipsList,
      };
    case types.REQUEST_NATIONALITIES_ERROR:
      return {
        ...state,
        nationalitiesList: [],
        next: action.payload.next,
        previous: action.payload.previous,
      };
    default:
      return {
        ...state,
      };
  }
};

export default requestNationalitiesReducer;
