import * as Yup from 'yup';

export const formSchema = [
  {
    fieldName: 'name',
    type: 'TextInput',
    label: 'Full name',
  },
  {
    fieldName: 'petType',
    type: 'CustomSelect',
    label: 'Pet type',
    options: [
      { text: 'Cat', value: 'cat' },
      { text: 'Dog', value: 'dog' },
      { text: 'Other...', value: 'other' },
    ],
  },
  {
    fieldName: 'weight',
    type: 'TextInput',
    label: 'Weight in kilos',
  },
  {
    fieldName: 'documentDetails',
    type: 'TextInput',
    label: 'Document details',
  },
];

export const formSchemaNotes = [
  {
    fieldName: 'notes',
    type: 'NoteField',
    label: 'Notes',
  },
];

export const validationSchema = Yup.object().shape({
  petType: Yup.string().required('Please enter a pet type'),
  weight: Yup.number().typeError('Please enter numbers (0-9)').nullable(),
  notes: Yup.array().of(
    Yup.object().shape({
      text: Yup.string()
        .min(2, 'Notes must be at least 2 characters')
        .test('len', 'Notes must be at least 2 characters', (val) => (val ? val.toString().length : 0 > 0)),
    }),
  ),
});
