export const formSchema = [
  {
    fieldName: 'date',
    type: 'DatePicker',
    label: 'Date',
  },
  {
    fieldName: 'time',
    type: 'TimeField',
    label: 'Time',
  },
  {
    fieldName: 'address',
    type: 'TextareaAsInput',
    label: 'Departure point',
  },
  {
    fieldName: 'travelFrom',
    type: 'TextInput',
    label: 'Travel from',
  },
  {
    fieldName: 'travelTo',
    type: 'TextInput',
    label: 'Travel to',
  },
];
