import axios from '../../axios';
import actionCreators from './actionCreators';

export const getDateFormat = () => {
  return (dispatch) => {
    axios
      .get('/api/v1/core/date-format/')
      .then((response) => {
        dispatch(actionCreators.dateFormatLoaded(response.data));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const editDateFormat = (data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .put('/api/v1/core/date-format/', data)
      .then((response) => {
        dispatch(actionCreators.dateFormatUpdated(response.data));
        return response.data;
      })
      .catch((error) => {
        toast.errorMessage = error.response?.data;
        toast.type = 'error';
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
