import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { SubmitButton } from '../../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../../Shared/Form/FormElements';
import { getCompanies, getUnits } from '../../../../store/actions/requestDetails';
import {
  addCandidateToPolishLegalizationDocuments,
  getLegalizationDocuments,
} from '../../../../store/actions/legalizationDocuments';
import { initialValues, validationSchema, formSchema as formSchemaWithoutRequiredField } from './schemaProperties';
import { getFormSchemaWithRequiredField } from '../../../../helpers';
import { getParams } from '../../tableProperties/getParams';

const AddCandidate = ({ setModalVisible, page, countryId }) => {
  const dispatch = useDispatch();

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField);

  const targetUnitOptions = useSelector((state) => state.employmentInfo.units).map((unit) => {
    return { text: unit.longName, key: unit.uuid, value: unit.uuid };
  });
  const companyOptions = useSelector((state) => state.employmentInfo.companies).map((company) => {
    return { text: company.title, key: company.id, value: company.id };
  });

  useEffect(() => {
    if (!targetUnitOptions?.length) {
      dispatch(getUnits());
    }
    if (!companyOptions.length) {
      dispatch(getCompanies());
    }
  }, [dispatch, targetUnitOptions?.length, companyOptions?.length]);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, [targetUnitOptions]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  formSchema.forEach((item) => {
    if (item.fieldName === 'targetUnitId') {
      item.options = targetUnitOptions;
    }
    if (item.fieldName === 'companyId') {
      item.options = companyOptions;
    }
  });

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(addCandidateToPolishLegalizationDocuments(values, countryId || null))
      .then((response) => {
        const [ordering, filtering, searchParams, toggleParams] = getParams({
          tableKey: 'PolandDashboard',
        });
        dispatch(
          getLegalizationDocuments(page, filtering, {
            ordering: ordering,
            search: searchParams,
            toggle: toggleParams,
          }),
        );
        setModalVisible(false);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {(formikProps) => (
        <Form>
          <div className="add-item-to-legalization-table__form">
            {formSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
            ))}
          </div>
          <div className="add-item-to-legalization-table__button-container">
            <button type="button" className="button btn-form main-btn" onClick={() => setModalVisible(false)}>
              Cancel
            </button>
            <SubmitButton title="Confirm" formikProps={formikProps} />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddCandidate;
