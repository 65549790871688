import { Formik, Form, Field } from 'formik';

const InputNumber = ({ cell, colIndex, rowIndex, col }) => {
  return (
    <Formik enableReinitialize initialValues={{ costs: cell?.cell }}>
      {(formikProps) => (
        <Form>
          <Field
            type="number"
            name="costs"
            id={`${col.title}.${rowIndex}.${colIndex}`}
            onBlur={(event) => col.onChange(rowIndex, event.target.value, cell, colIndex)}
            className="currency-cost"
            disabled={cell?.disabled}
          />
        </Form>
      )}
    </Formik>
  );
};

export default InputNumber;
