import { types } from '../actions/types';
import _ from 'lodash';

export const initialState = {
  genericForms: [],
  filterByCountry: '',
  genericFormsForEmployee: {},
  genericFormsForRelative: {},
  next: null,
  previous: null,
  count: 0,
};

const genericFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GENERIC_FORMS_LOADED:
      return {
        ...state,
        genericForms: action.payload.genericForms,
        filterByCountry: action.payload.filterByCountry || '',
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
      };
    case types.GENERIC_FORMS_EMPLOYEE_RELATIVE_LOAD:
      return {
        ...state,
        ...(action.payload.parameters?.is_for_employee && {
          genericFormsForEmployee: {
            ...state.genericFormsForEmployee,
            [action.payload.countryId]: action.payload.genericForms,
          },
        }),
        ...(action.payload.parameters?.is_for_spouse && {
          genericFormsForRelative: {
            ...state.genericFormsForRelative,
            [action.payload.countryId]: {
              ...state.genericFormsForRelative[action.payload.countryId],
              spouse: action.payload.genericForms,
            },
          },
        }),
        ...(action.payload.parameters?.is_for_child && {
          genericFormsForRelative: {
            ...state.genericFormsForRelative,
            [action.payload.countryId]: {
              ...state.genericFormsForRelative[action.payload.countryId],
              child: action.payload.genericForms,
            },
          },
        }),
      };
    case types.GENERIC_FORMS_UPDATE:
      return {
        ...state,
        genericFormsForEmployee: {},
        genericForms: [...state.genericForms, ...action.payload.genericForms],
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.GENERIC_FORMS_UPDATED:
      return {
        ...state,
        genericFormsForEmployee: {},
        genericForms: state.genericForms.map((item) => {
          if (item.id === action.payload.genericForms.id) {
            item = _.cloneDeep(action.payload.genericForms);
          }
          return item;
        }),
      };
    case types.GENERIC_FORMS_ADD:
      return {
        ...state,
        genericFormsForEmployee: {},
        genericForms: [...state.genericForms, action.payload.genericForms],
      };

    case types.GENERIC_FORMS_ERROR:
      return {
        ...state,
        genericForms: action.payload.genericForms,
      };
    default:
      return {
        ...state,
      };
  }
};

export default genericFormsReducer;
