import { useState } from 'react';
import { Checkbox, Popover, Button } from 'antd';

import './checkboxMenu.scss';

const CheckboxMenu = ({ visibleColumns, onChange, options, resetVisibleColumns }) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const handleOnChange = (selection) => {
    return onChange(selection);
  };

  return (
    <>
      <Popover
        content={
          <CheckboxRender
            hide={hide}
            options={options}
            handleOnChange={handleOnChange}
            selectedItems={visibleColumns}
            resetVisibleColumns={resetVisibleColumns}
          />
        }
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        placement="bottomLeft"
      >
        <div className="columns-filter__button">
          <div className="columns-filter__button-content">
            <div className="columns-filter__button-name">
              <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="10" height="10" rx="0.5" stroke="#666666" />
                <path d="M3.45831 0.807251L3.45831 10.1148" stroke="#666666" />
                <path d="M7.45838 0.81665L7.45838 10.2085" stroke="#666666" />
              </svg>
              Columns
            </div>
            <div>
              <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M4.49148 4.99831C4.34159 4.99864 4.19632 4.94059 4.0809 4.83424L0.231752 1.26755C0.100742 1.14646 0.0183548 0.972473 0.00271461 0.783847C-0.0129256 0.595221 0.0394626 0.407412 0.148354 0.261737C0.257246 0.116061 0.413721 0.0244508 0.583357 0.00705973C0.752993 -0.0103313 0.921894 0.047921 1.0529 0.169002L4.49148 3.36476L7.93298 0.159593C7.9986 0.100339 8.07411 0.0560893 8.15516 0.0293878C8.23621 0.00268628 8.3212 -0.00594073 8.40526 0.00400265C8.48932 0.013946 8.57078 0.0422633 8.64497 0.0873277C8.71915 0.132392 8.78459 0.193315 8.83753 0.266594C8.89628 0.339941 8.94078 0.425988 8.96823 0.519346C8.99568 0.612703 9.0055 0.711358 8.99707 0.809131C8.98864 0.906904 8.96214 1.00169 8.91923 1.08754C8.87633 1.1734 8.81793 1.24847 8.74772 1.30807L4.89564 4.87704C4.7769 4.96658 4.6346 5.00928 4.49148 4.99831Z"
                  fill="#555555"
                />
              </svg>
            </div>
          </div>
        </div>
      </Popover>
    </>
  );
};

export default CheckboxMenu;

export const CheckboxRender = ({ hide, options, handleOnChange, selectedItems, resetVisibleColumns }) => {
  return (
    <div className="columns-filter__checkbox-group">
      <Checkbox.Group onChange={handleOnChange} value={selectedItems} className="columns-filter__checkbox-menu">
        {options.map((option, i) => {
          return (
            <Checkbox key={option.value} value={option.value} style={{ margin: '0' }}>
              {option.label}
            </Checkbox>
          );
        })}
      </Checkbox.Group>
      <div className="columns-filter__checkbox-group-buttons">
        <span className="reset-btn" onClick={resetVisibleColumns}>
          Reset
        </span>
        <Button type="primary" onClick={hide}>
          OK
        </Button>
      </div>
    </div>
  );
};
