import axios from '../../axios';
import queryString from 'query-string';
import _ from 'lodash';

import actionCreators from './actionCreators';

export const getFeedbackForm = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/generic-forms/generic-constructor-form/${id}/`);
    dispatch(actionCreators.feedbackFormLoaded(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addFeedbackForm = (data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/generic-forms/generic-constructor-form/`, data);
    dispatch(actionCreators.feedbackFormUpdated(response.data));
    dispatch(actionCreators.feedbackFormsAdd(response.data));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editFeedbackForm = (id, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/generic-forms/generic-constructor-form/${id}/`, data);
    dispatch(actionCreators.feedbackFormUpdated(response.data));
    dispatch(actionCreators.feedbackFormsUpdated(response.data));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const getFeedbackForms = (page, parameters) => async (dispatch) => {
  try {
    const params = parameters ? { ...parameters, form_type: 'feedback' } : { form_type: 'feedback' };
    const response = await axios.get(`/api/v1/generic-forms/generic-constructor-form/`, {
      params: { ...params, page: page },
      paramsSerializer: function (params) {
        return queryString.stringify(params, {
          arrayFormat: 'comma',
          skipNull: true,
          skipEmptyString: true,
        });
      },
    });
    dispatch(actionCreators.feedbackFormsLoaded(response.data, parameters?.countries));

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getFeedbackFormsInCase = (requestId) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/${requestId}/constructor-forms/`)
      .then((response) => {
        dispatch(actionCreators.requestDetailsFeedbackFormsLoad(response.data, requestId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const requestEmployeeFeedback = (requestId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .post(`/api/v1/relocation-requests/${requestId}/feedback-notice/`, data)
      .then((response) => {
        const { deadline, id } = response.data;
        dispatch(
          actionCreators.requestDetailsEmployeeFeedbackUpdate(
            { deadline: deadline, requestedAt: new Date(), feedbackId: id },
            requestId,
          ),
        );
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editFeedbackDeadline = (requestId, feedbackId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .put(`/api/v1/relocation-requests/${requestId}/feedback/${feedbackId}/`, data)
      .then((response) => {
        dispatch(actionCreators.requestDetailsEmployeeFeedbackUpdate(data, requestId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editAdditionalFeedbackInformation = (requestId, formId, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/relocation-requests/${requestId}/feedback/form/${formId}`, data);
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};
