import * as Yup from 'yup';

export const formSchema = [
  {
    fieldName: 'fullName',
    type: 'TextInput',
    label: 'Employee name',
    disabled: true,
  },
  {
    fieldName: 'fullNameRu',
    type: 'TextareaAsInput',
    label: 'Full name (RU)',
    disabled: true,
  },
  {
    fieldName: 'passportFirstName',
    type: 'TextareaAsInput',
    label: 'First name as in passport',
  },
  {
    fieldName: 'passportLastName',
    type: 'TextareaAsInput',
    label: 'Last name as in passport',
  },
  {
    fieldName: 'otherNameSpelling',
    type: 'TextInput',
    label: 'Other name spelling',
  },
  {
    fieldName: 'maidenName',
    type: 'TextInput',
    label: "Mother's maiden name",
  },
  {
    fieldName: 'birthDate',
    type: 'DatePicker',
    label: 'Birth date',
    disabled: true,
  },
  {
    fieldName: 'residencyCityId',
    type: 'Dropdown',
    label: 'Residency city',
    isClearable: true,
  },
  {
    fieldName: 'citizenshipId',
    type: 'Dropdown',
    label: 'Citizenship',
  },
  {
    fieldName: 'nationalityId',
    type: 'Dropdown',
    label: 'Nationality',
  },
  {
    fieldName: 'unit',
    type: 'TextInput',
    label: 'Unit',
    disabled: true,
  },
  {
    fieldName: 'manager',
    type: 'TextInput',
    label: 'Manager',
    disabled: true,
  },
  {
    fieldName: 'position',
    type: 'TextInput',
    label: 'Position',
    disabled: true,
  },
];

export const validationSchema = Yup.object().shape({
  passportFirstName: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-zA-Zа-яА-Я])/, 'First name must contain at least one letter')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'First name must contain only letters'),
  passportLastName: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-zA-Zа-яА-Я])/, 'Last name must contain at least one letter')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'Last name must contain only letters'),
});
