import { useMemo } from 'react';
import classNames from 'classnames';

import GeneralDocument from './GeneralDocument/GeneralDocument';
import './displayGeneralDocuments.scss';

const DisplayGeneralDocuments = ({ documents, validationMessages }) => {
  const dateNow = new Date();
  const thirtyDays = useMemo(() => 1000 * 60 * 60 * 24 * 30, []);
  const threeMonths = useMemo(() => 3 * thirtyDays, []);

  return (
    <div className="general-documents-messages__container">
      <div
        className={classNames('general-documents__container', {
          expirationBottomBorder:
            documents && Object.values(documents).some((x) => x !== null && x !== '') && validationMessages.length > 0,
        })}
      >
        {Object.keys(documents).map((key) => (
          <GeneralDocument
            key={key}
            document={documents[key]}
            dateNow={dateNow}
            thirtyDays={thirtyDays}
            threeMonths={threeMonths}
          />
        ))}
      </div>
      <div>
        {Array.isArray(validationMessages) &&
          validationMessages.map((item, index) => (
            <p className="validation-message" key={index}>
              {item}
            </p>
          ))}
      </div>
    </div>
  );
};

export default DisplayGeneralDocuments;
