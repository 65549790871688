import { useEffect } from 'react';
import PropTypes from 'prop-types';

import { getAppUserManager } from '../userManager';

const SignOutRedirect = ({ enableExternalLogin }) => {
  useEffect(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('employeeDocumentAccordion');
    localStorage.removeItem('relatedDocumentAccordion');
    localStorage.removeItem('petDocumentAccordion');
    localStorage.removeItem('casesFilters');
    localStorage.removeItem('visa-onlyFilters');
    localStorage.removeItem('relocatedEmplFiters');
    localStorage.removeItem('columnsFilter');
    localStorage.removeItem('PolandDashboard');
    localStorage.removeItem('RemindersDashboard');

    const userManager = getAppUserManager();
    const args = {};
    if (enableExternalLogin) {
      args.extraQueryParams = {
        external_idp: 'enabled',
      };
    }

    userManager.signoutRedirect(args);
  }, []);

  return <p>Signing out...</p>;
};

SignOutRedirect.propTypes = {
  enableExternalLogin: PropTypes.bool.isRequired,
};

export default SignOutRedirect;
