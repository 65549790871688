import { useDispatch } from 'react-redux';

import DisplayAttachment from './DisplayAttachment/DisplayAttachment';
import { deleteTicketAttachment } from '../../../../store/actions/uploadFile';
import { getTicketAttachmentLink } from '../../../../store/actions/downloadFile';
import './currentAttachmentBlock.scss';

const CurrentAttachmentBlock = ({
  transportationDetailsInfoId,
  transportationDetailsAllInfo,
  type,
  requestId,
  relatedReqId,
  petReqId,
  isCaseDisabled,
}) => {
  const dispatch = useDispatch();

  const handleDeleteAttachment = (tdId) => (attachId) => {
    dispatch(deleteTicketAttachment(attachId, tdId, type, requestId, relatedReqId, petReqId));
  };

  return (
    <>
      <div className="current-attachments__container form-group">
        <label className="control-label">Uploaded files:</label>
        <div className="current-attachments-block">
          {transportationDetailsAllInfo.map((item, index) =>
            item.attachments.map((attach, subindex) => {
              if (item.id === transportationDetailsInfoId) {
                return (
                  <DisplayAttachment
                    key={attach.id}
                    getAttachmentLink={getTicketAttachmentLink}
                    attachmentId={attach.id}
                    attach={attach}
                    deleteAttachment={handleDeleteAttachment(transportationDetailsInfoId)}
                  />
                );
              } else return null;
            }),
          )}
        </div>
      </div>
    </>
  );
};

export default CurrentAttachmentBlock;
