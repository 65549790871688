import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getRelocationHistory } from '../../../store/actions/requestDetails';
import History from '../../Common/History/History';

const CaseHistory = ({ requestId }) => {
  const dispatch = useDispatch();

  const { requestHistory, count } = useSelector((state) => state.requestHistory);

  const getTableDataCallback = (requestId) => (page) => {
    return getRelocationHistory(requestId, page);
  };

  useEffect(() => {
    dispatch(getRelocationHistory(requestId));
  }, [requestId, dispatch]);

  return (
    <History
      historyList={requestHistory}
      count={count}
      getTableDataCallback={getTableDataCallback(requestId)}
      className="relocation-history-details"
      name="relocationHistoryDetails"
    />
  );
};

export default CaseHistory;
