import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import { getFormElement } from '../../../../Shared/Form/FormElements';
import { SubmitButton } from '../../../../Shared/Form/Buttons/Buttons';
import {
  getNationalities,
  getCitizenships,
  addRelativesWithGeneralInfo,
  editRelativeDocGeneralInformation,
  linkRequestWithTechArtEmployee,
} from '../../../../../store/actions/requestDetails';
import { editRelocationRequest } from '../../../../../store/actions/request';
import _ from 'lodash';
import {
  validationNewRelSchema,
  validationRelSchema,
  validationSchema,
  validationSchemaITechArtEmployee,
  initialValuesITechArtEmployee,
} from './validationSchema';
import EmployeeSearch from '../../../../Shared/Form/EmployeeSearch/EmployeeSearch';
import formSchema, { iTechArtEmployeeSchema } from './formSchema';
import './NewRelativeTab.scss';

const NewRelativeTab = ({
  requestId,
  relatedInfo,
  employeeId,
  documentsDetails,
  withWhom,
  setWithWhom,
  setCurrentTab,
  setSelectedTab,
}) => {
  const dispatch = useDispatch();

  const [roleSelected, setRoleSelected] = useState('');
  const [isITechArtEmployee, setITechArtEmployee] = useState(false);

  const initialValues = {
    birthDate: '',
    citizenshipId: null,
    firstName: '',
    lastName: '',
    maidenName: '',
    nationalityId: null,
    relationType: roleSelected,
  };

  const nationalities = useSelector((state) => state.nationalitiesList.nationalitiesList);
  const citizenships = useSelector((state) => state.nationalitiesList.citizenshipsList);

  const nationalitiesOptions = nationalities;
  const citizenshipsOptions = citizenships;

  const onChildSelectChange = (val) => {
    setRoleSelected(val);
    setITechArtEmployee(false);
  };

  const relatedSchema = formSchema(citizenshipsOptions, nationalitiesOptions);

  useEffect(() => {
    if (!nationalities?.length) {
      dispatch(getNationalities());
    }
  }, [dispatch, nationalities?.length]);

  useEffect(() => {
    if (!citizenships?.length) {
      dispatch(getCitizenships());
    }
  }, [dispatch, citizenships?.length]);

  const onSubmitITechArtEmployee = (values, { setSubmitting, resetForm, setStatus }) => {
    dispatch(linkRequestWithTechArtEmployee(values, requestId, employeeId));
    setSubmitting(false);
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    if (!relatedInfo) {
      if (!withWhom.withChildren && values.relationType === 'child') {
        dispatch(editRelocationRequest(requestId, { with_children: true }, true));
        setWithWhom((withWhom) => ({
          ...withWhom,
          ...{ withChildren: true },
        }));
      }

      if (!withWhom.withSpouse && values.relationType === 'spouse') {
        dispatch(editRelocationRequest(requestId, { with_spouse: true }, true));
        setWithWhom((withWhom) => ({
          ...withWhom,
          ...{ withSpouse: true },
        }));
      }

      dispatch(
        addRelativesWithGeneralInfo(employeeId, {
          relocation_request_id: requestId,
          relation_type: values.relationType,
          general_info: {
            firstName: values.firstName,
            lastName: values.lastName,
            middleNameRu: values.fullNameRu?.split(' ')[1],
            lastNameRu: values.fullNameRu?.split(' ')[2],
            nationality_id: values.nationalityId,
            citizenship_id: values.citizenshipId,
            birth_date: values.birthDate,
            maiden_name: values.maidenName,
          },
        }),
      )
        .then((response) => {
          setCurrentTab(`relative_${response?.relative?.id}`);
          setSelectedTab(`${values.relationType}_${response?.relative?.id}`);
        })
        .finally(() => setSubmitting(false));
    } else {
      dispatch(
        editRelativeDocGeneralInformation(employeeId, relatedInfo.id, relatedInfo.id, {
          firstName: values.firstName,
          lastName: values.lastName,
          middleNameRu: values.fullNameRu?.split(' ')[1],
          lastNameRu: values.fullNameRu?.split(' ')[2],
          nationalityId: values.nationalityId,
          citizenshipId: values.citizenshipId,
          birthDate: values.birthDate,
          maidenName: values.maidenName,
        }),
      ).finally(() => setSubmitting(false));
    }
  };

  let generalRelatedInfo = {};

  if (relatedInfo) {
    generalRelatedInfo = _.merge(
      documentsDetails[relatedInfo?.id] ? documentsDetails[relatedInfo?.id].generalInfo : relatedInfo.generalInfo,
      { relationType: relatedInfo.relationType },
      { nationalityId: relatedInfo.generalInfo.nationality?.id },
      { citizenshipId: relatedInfo.generalInfo.citizenship?.id },
    );
  }

  relatedSchema.forEach((item) => {
    if (item.fieldName === 'relationType') {
      item.updateselectvalueroot = onChildSelectChange;
    }
  });

  iTechArtEmployeeSchema.forEach((item) => {
    if (item.fieldName === 'isITechArtEmployee') {
      item.updateselectvalueroot = setITechArtEmployee;
      item.disabled = false;
    }
  });

  return (
    <div className="NewRelativeTab">
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={relatedInfo ? generalRelatedInfo : initialValues}
        validationSchema={
          (roleSelected === 'spouse' || roleSelected === 'child' || !roleSelected) && !relatedInfo
            ? validationNewRelSchema
            : relatedInfo
            ? validationRelSchema
            : validationSchema
        }
      >
        {(formikProps) => {
          return (
            <Form>
              {relatedSchema.map((field) => {
                if (isITechArtEmployee && roleSelected === 'spouse') {
                  if (field.fieldName === 'relationType') {
                    return (
                      <React.Fragment key={field.fieldName}>
                        <div key={field.fieldName + 1}>{getFormElement(field.type, field, formikProps)}</div>
                        {iTechArtEmployeeSchema.map((field) => (
                          <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                        ))}
                      </React.Fragment>
                    );
                  } else {
                    return null;
                  }
                }
                if (field.fieldName === 'firstName' && roleSelected === 'spouse') {
                  return (
                    <React.Fragment key={field.fieldName}>
                      {iTechArtEmployeeSchema.map((field) => (
                        <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                      ))}
                      <div key={field.fieldName + 1}>{getFormElement(field.type, field, formikProps)}</div>
                    </React.Fragment>
                  );
                } else return <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>;
              })}
              {!isITechArtEmployee && <SubmitButton title="Save" formikProps={formikProps} />}
            </Form>
          );
        }}
      </Formik>
      {isITechArtEmployee && (
        <Formik
          enableReinitialize
          onSubmit={onSubmitITechArtEmployee}
          initialValues={initialValuesITechArtEmployee}
          validationSchema={validationSchemaITechArtEmployee}
        >
          {(formikProps) => {
            return (
              <Form>
                <EmployeeSearch formikProps={formikProps} />
                <SubmitButton title="Save" formikProps={formikProps} />
              </Form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default NewRelativeTab;
