import { Field, ErrorMessage, useField } from 'formik';
import classNames from 'classnames';

import './dateFieldAsText.scss';
import dayjs from 'dayjs';
import { defaultDateFormat } from '../../../../constants';
import { useSelector } from 'react-redux';

const DateFieldAsText = (props) => {
  const {
    name,
    label,
    placeholder,
    formIndex,
    nameFieldArray,
    formikProps,
    canHaveDisplayHorizontal,
    isRequired,
    ...rest
  } = props;
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const [field, , { setValue }] = useField(fieldName);
  const value = field.value ? dayjs(field.value).format(displayDateFormat) : '';

  return (
    <div className="form-group form-group__column">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}
      <div
        className={classNames(
          'text-field__wrapper',
          name === 'additionalDocuments' ? 'col-md-7' : name === 'address' ? 'col-md-5' : 'form-input-width',
        )}
      >
        <Field
          className={classNames('form-control', 'form-width')}
          type="text"
          name={fieldName}
          id={fieldName}
          placeholder={placeholder || ''}
          value={value}
          {...rest}
        />
      </div>
      <ErrorMessage
        name={fieldName}
        render={(msg) => (
          <span className="error" style={{ color: '#FF0000' }}>
            {msg}
          </span>
        )}
      />
    </div>
  );
};

export default DateFieldAsText;
