import React, { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form, FieldArray } from 'formik';
import classNames from 'classnames';

import { getFormElement } from '../../Shared/Form/FormElements';
import { upsertAccommodationDetails } from '../../../store/actions/requestDetails';
import { getValuesToUpdate } from '../../../helpers';
import AccommodationDetailsField from './AccommodationDetailsField/AccommodationDetailsField';
import { editRelocationRequest } from '../../../store/actions/request';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import { IsCaseDisabledContext } from '../RequestDetails';
import './accommodationDetails.scss';
import { validationSchema } from './requestSchema';

const accommodationDetailsInitialValues = {
  checkInDate: null,
  // checkInTime: null,
  checkOutDate: null,
  // checkOutTime: null,
  address: null,
  requiredFor: [],
  notes: [],
  files: [],
};

const comparisonFields = [
  'checkInDate',
  'checkInTime',
  'checkOutDate',
  'checkOutTime',
  'address',
  'notes',
  'isRequiredForSpouse',
  'isRequiredForChildren',
  'isRequiredForPets',
];

const requiredFor = ['isRequiredForSpouse', 'isRequiredForChildren', 'isRequiredForPets'];

const AccommodationDetails = (props) => {
  const { requestId, accommodationDetailsAllInfo, isAccommodationBookedInfo } = props;
  const [isAccommodationBooked, setIsAccommodationBooked] = useState(isAccommodationBookedInfo);
  const dispatch = useDispatch();

  const onAccommodationBooked = (value) => {
    dispatch(editRelocationRequest(requestId, { isAccommodationBooked: value }));
    setIsAccommodationBooked(value);
  };

  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const formIsAccommodationBooked = [
    {
      fieldName: 'isAccommodationBooked',
      type: 'Checkbox',
      label: 'Accommodation booked',
      updateselectvalueroot: onAccommodationBooked,
    },
  ];

  const accommodationDetailsInformation = accommodationDetailsAllInfo
    .map((item) => {
      return {
        ...item,
        requiredFor: [
          item.isRequiredForSpouse && 'isRequiredForSpouse',
          item.isRequiredForChildren && 'isRequiredForChildren',
          item.isRequiredForPets && 'isRequiredForPets',
        ],
        files: [],
      };
    })
    .sort((a, b) => a.id - b.id);

  const initialValues = {
    accommodationDetailsInfo: accommodationDetailsAllInfo.length
      ? accommodationDetailsInformation
      : [accommodationDetailsInitialValues],
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    const valuesInfo = [...values?.accommodationDetailsInfo];

    requiredFor.forEach((i) =>
      valuesInfo.forEach((item) => {
        item.requiredFor.includes(i) ? (item[i] = true) : (item[i] = false);
      }),
    );

    const valuesToUpdate = getValuesToUpdate(valuesInfo, accommodationDetailsAllInfo, comparisonFields);

    const valuesToAdd = valuesInfo.filter(({ id }) => isNaN(id));

    const valuesWithFilesToUpdate = getValuesToUpdate(values.accommodationDetailsInfo, accommodationDetailsAllInfo, [
      'files',
    ]);

    const files = values.accommodationDetailsInfo.some((item) => item?.files?.length);

    try {
      if (valuesToUpdate.length || valuesToAdd.length || files) {
        dispatch(upsertAccommodationDetails(valuesToUpdate, valuesToAdd, requestId, valuesWithFilesToUpdate));
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="accommodation-details">
      {
        <>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formikProps) => {
              return (
                <Form>
                  <FieldArray
                    name="accommodationDetailsInfo"
                    render={({ remove, push }) => (
                      <>
                        <div>
                          {formikProps.values.accommodationDetailsInfo.length > 0 &&
                            formikProps.values.accommodationDetailsInfo.map((accommodationDetailsInfo, formIndex) => (
                              <AccommodationDetailsField
                                key={formIndex}
                                formikProps={formikProps}
                                formIndex={formIndex}
                                accommodationDetailsAllInfo={accommodationDetailsAllInfo}
                                accommodationDetailsInfo={accommodationDetailsInfo}
                                requestId={requestId}
                                remove={remove}
                                isCaseDisabled={isCaseDisabled}
                              />
                            ))}
                        </div>
                        <div className="button-wrapper">
                          <SubmitButton title="Submit" formikProps={formikProps} />
                          <button
                            type="button"
                            className={classNames('button btn-form main-btn', isCaseDisabled ? 'btn-disabled' : '')}
                            onClick={() => push(accommodationDetailsInitialValues)}
                            disabled={isCaseDisabled}
                          >
                            Add accommodation
                          </button>
                        </div>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          </Formik>
          {accommodationDetailsAllInfo.length > 0 && (
            <div className="final-checkbox-wrapper">
              <Formik
                enableReinitialize
                initialValues={{
                  isAccommodationBooked: isAccommodationBooked,
                }}
              >
                {(formikProps) => (
                  <Form>
                    {formIsAccommodationBooked.map((field) => (
                      <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
                    ))}
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </>
      }
    </div>
  );
};

export default AccommodationDetails;
