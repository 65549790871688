import React from 'react';
import { waitForElement } from '../../../../helpers';

const UnfilledFormsValidation = ({
  docsInfoEmployee,
  unfilledEmployeeForms,
  unfilledRelativesForms,
  docsInfoRelatives,
  setCurrentTab,
  setSelectedMenuItem,
  setSelectedTab,
  sendInfoModalClose,
}) => {
  return (
    <>
      {unfilledEmployeeForms?.length > 0 && (
        <div className="send-docs__validation-error">
          -Please fill in the{' '}
          {unfilledEmployeeForms.map((item, index, { length }) => (
            <span
              key={item.slug}
              className="send-docs__validation-error-link"
              onClick={() => {
                sendInfoModalClose(false);
                setCurrentTab(docsInfoEmployee?.internalId);
                setSelectedTab('employee');
                item.slug.includes('passport') ? setSelectedMenuItem('documents') : setSelectedMenuItem('forms');
                waitForElement(item.slug).then((element) =>
                  document.getElementById(item.forms[0].fields[0].id).focus(),
                );
              }}
            >
              {item.name}
              {length - 1 === index ? ' ' : ', '}
            </span>
          ))}
        </div>
      )}
      {unfilledRelativesForms &&
        Object.keys(unfilledRelativesForms).map((relativeId) => {
          const relative = docsInfoRelatives[relativeId]?.relatedInfo?.relative;

          const relationType = relative?.relationType;

          return (
            <div className="send-docs__validation-error" key={relativeId}>
              -Please fill in the{' '}
              {unfilledRelativesForms[relativeId].map((item, index, { length }) => (
                <span
                  className="send-docs__validation-error-link"
                  key={item.slug}
                  onClick={() => {
                    sendInfoModalClose(false);
                    setCurrentTab(`relative_${relative?.id}`);
                    setSelectedTab(`${relationType}_${relative?.id}`);
                    item.slug.includes('passport') ? setSelectedMenuItem('documents') : setSelectedMenuItem('forms');
                    waitForElement(item.slug).then((element) =>
                      document.getElementById(item.forms[0].fields[0].id).focus(),
                    );
                  }}
                >
                  {item.name}
                  {length - 1 === index ? ' ' : ', '}
                </span>
              ))}
              for your {relative?.relationType} <span>{relative?.generalInfo?.fullName}</span>
            </div>
          );
        })}
    </>
  );
};

export default UnfilledFormsValidation;
