import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { getRequestDetails } from '../../store/actions/requestDetails';
import { getPetProfile } from '../../store/actions/requestPetsProfiles';
import SpinnerContainer from '../Common/SpinnerContainer/SpinnerContainer';
import { getIsCaseDisabled } from '../../helpers';
import SiderMenu from './Pets/SiderMenu/SiderMenu';
import ContentRoutes from './Pets/ContentRoutes/ContentRoutes';
import { defaultDateFormat } from '../../constants';

import './requestRelatedDetails.scss';

const RequestPetDetails = ({ match }) => {
  const dispatch = useDispatch();
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;
  const requestId = match.params.id;
  const petReqId = match.params.rel_req_id;

  const request = useSelector((state) => state.requestDetails[requestId]?.requestDetails);

  const relatedInfo = useSelector((state) => state.requestPetsProfiles[petReqId]?.petsInfo);

  const isPetCaseDisabled =
    request &&
    getIsCaseDisabled({
      currentEmploymentInfo: request?.employee?.currentEmploymentInfo,
      isActive: request.employee.isActive,
      status: request.status,
    });

  const [isLoading, setLoading] = useState(!request);

  useEffect(() => {
    if (request?.id !== +requestId) {
      dispatch(getRequestDetails(requestId)).then(() => setLoading(false));
    }
  }, [requestId, dispatch, request?.id, request?.employee?.uuid]);

  useEffect(() => {
    if (request?.id) {
      dispatch(getPetProfile(request.id, petReqId));
    }
  }, [request?.id, petReqId]);

  return (
    <>
      {!isLoading && request && relatedInfo && (
        <section className="PetRequestDetails">
          <div className="request-top">
            <h3>{relatedInfo?.pet.name}</h3>
            <div className="updated-at-by">
              Updated: {dayjs(new Date(request.updatedAt)).format(displayDateFormat)} by {request.updatedBy}
            </div>
          </div>
          <div className="pet-request-details__container">
            <SiderMenu />
            <ContentRoutes
              requestId={requestId}
              relatedInfo={relatedInfo}
              request={request}
              petReqId={petReqId}
              isPetCaseDisabled={isPetCaseDisabled}
            />
          </div>
        </section>
      )}
      {!isLoading && !request && <h1 className="no-data">Request not found!</h1>}
      {isLoading && <SpinnerContainer />}
    </>
  );
};

export default RequestPetDetails;
