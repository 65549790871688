import actionCreators from './actionCreators';
import axios from '../../axios';
import queryString from 'query-string';
import { getPresignedUrl } from './uploadFile';
import { uploadFileToS3 } from './uploadToS3';

export const getLegalizationDocuments = (page, filterParams, params) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/employees/legalization-dashboard/`, {
        params: { page: page, ...params, ...filterParams },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.legalizationDocumentsLoad(response.data, page));

        return response.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.legalizationDocumentsError());
        throw error;
      });
  };
};

export const legalizationDocumnetsExportExcel = (data, filterParams, params) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .post('/api/v1/employees/legalization-dashboard/export', data, {
        params: { ...params, ...filterParams },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const fileName =
          response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '') || `${Date.now()}.xlsx`;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        // dispatch(actionCreators.relocatedEmployeesExcelCheckboxesRemove());
        return 1;
      })
      .catch(async (error) => {
        toast.type = 'error';
        const responseData = await error.response?.data;
        const responseMessage = responseData === 'Internal server error' ? responseData : responseData.text();
        toast.errorMessage = await responseMessage;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addEmployeeToPolishLegalizationDocuments = (data, countryId) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .post(`/api/v1/employees/legalization-dashboard/`, { ...data, countryId: countryId })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
        throw error;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addCandidateToPolishLegalizationDocuments = (data, countryId) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .post(`/api/v1/employees/candidate/`, { ...data, countryId: countryId })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
        throw error;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editCitizenship = (uuid, data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .put(`/api/v1/employees/${uuid}/general-info/`, data)
      .then((response) => {
        dispatch(actionCreators.legalizationDocumentsCitizenshipUpdate(response.data, uuid));
        return response.data;
      })
      .catch((error) => {
        dispatch(actionCreators.requestDetailsError());
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editLegalizationReason = (data, uuid) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .put(`/api/v1/employees/${uuid}/`, data)
      .then((response) => {
        dispatch(actionCreators.legalizationDocumentsReasonUpdate(data, uuid));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const getTrackingDocuments = (employeeId) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios.get(`/api/v1/employees/${employeeId}/pl-tracking-documents`).then((response) => {
      dispatch(actionCreators.trackingDocumentsLoad(employeeId, response.data));
      // return response.data;
    });
  };
};

export const attachmentUpload = (files, employeeUuid, gfId, formId, type) => {
  return (dispatch) => {
    const handleUpload = async (employeeUuid, gfId, formId, file) => {
      const presignedUrl = await getPresignedUrl(employeeUuid, gfId, formId);
      const filename = '${filename}';

      try {
        await uploadFileToS3(presignedUrl, file);
        const data = {
          fileName: file.name,
          fileKey: presignedUrl.fields.key.replace(`${filename}`, file.name),
        };
        return dispatch(addAttachment(employeeUuid, gfId, formId, data));
      } catch (error) {
        throw error;
      }
    };

    return files.map((file, i) => {
      return handleUpload(employeeUuid, gfId, formId, file);
    });
  };
};

export const addDocument = (employeeId, gfId, data, requestId) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .post(`/api/v1/employees/${employeeId}/gf/${gfId}/`, data)
      .then((response) => {
        dispatch(actionCreators.trackingDocumentAdd(gfId, response.data));
        const isCurrentPromise = currentDocumentUpdate(requestId, gfId, response.data.formId, { isCurrent: true });
        const attachmentPromises =
          data?.files && dispatch(attachmentUpload(data.files, employeeId, gfId, response.data.formId));
        let promises = [];

        isCurrentPromise && promises.push(isCurrentPromise);
        promises = attachmentPromises ? promises.concat(attachmentPromises.flat()) : promises;

        return Promise.all(promises);
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const currentDocumentUpdate = (reqId, gfId, formId, data) => {
  return axios
    .put(`/api/v1/employees/${gfId}/form/${formId}/current/`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const addAttachment = (empId, gfId, formId, data) => {
  return (dispatch) => {
    axios
      .post(`/api/v1/employees/${empId}/gf/${gfId}/form/${formId}/attachments/add-attachment/`, data)
      .then((response) => {
        dispatch(actionCreators.trackingDocumentAttachmentAdd(response.data, gfId, formId));

        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const deleteAttachment = (attachmentId, gfId, formId, employeeId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/attachments/${attachmentId}/`)
      .then((response) => {
        dispatch(actionCreators.trackingDocumentAttachmentDelete(attachmentId, gfId, formId));

        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const convertCandidateToEmployee = (data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .post(`api/v1/employees/legalization-dashboard/conversion`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const deleteEmployeeProfileFromLegalizationDocuments = (employeeId, dashboardId) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .delete(`/api/v1/employees/${employeeId}/legalization-dashboard/${dashboardId}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.errorMessage = error.response?.data;
        toast.type = 'error';
        return error.response;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const getWorkPlaceCities = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/employees/workplace-cities/');
    dispatch(actionCreators.workPlaceCitiesLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
