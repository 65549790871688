import { COLORS } from '../../../Shared/Constants/Constants';

const camelize = (str) => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
};

export const getOptions = ({ isLoading, customOptions, title }) => {
  const fileName = camelize(title) + 'Statistics';
  const options = {
    chart: {
      id: 'analytics',
      type: 'bar',
      width: '100%',
      height: '600',
      fontFamily: 'IBM Plex Sans',
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: true,
          zoomin: false, //
          zoomout: false, //
          pan: false,
          reset: true,
          customIcons: [],
        },
        export: {
          csv: {
            filename: fileName,
          },
          svg: {
            filename: fileName,
          },
          png: {
            filename: fileName,
          },
        },
        autoSelected: 'zoom',
      },
      zoom: {
        enabled: true,
        type: 'x',
        autoScaleYaxis: true,
        zoomedArea: {
          fill: {
            color: '#eb3d26',
            opacity: '0.1',
          },
          stroke: {
            color: '#eb3d26',
          },
        },
      },
    },
    xaxis: {
      type: 'category',
      labels: {
        minHeight: 120,
        maxHeight: 120,
        rotateAlways: false,
        // hideOverlappingLabels: false,
        style: {
          fontSize: '14px',
          fontFamily: 'IBM Plex Sans',
          fontWeight: 400,
          cssClass: 'apexcharts-xaxis-label',
        },
      },
      tickPlacement: 'on',
    },
    yaxis: {
      labels: {
        style: {
          cssClass: 'apexcharts-xaxis-label',
        },
      },
    },
    colors: COLORS,
    fill: {
      opacity: 1,
      type: 'solid',
    },
    plotOptions: {
      bar: {
        distributed: true,
        dataLabels: {
          orientation: 'horizontal',
          position: 'top',
          fontSize: '14px',
          fontFamily: 'IBM Plex Sans',
        },
        columnWidth: '100%',
        rangeBarOverlap: false,
      },
    },
    legend: {
      show: false,
    },
    grid: {
      padding: {
        top: 10,
      },
    },
    dataLabels: {
      enabled: true,
      offsetY: -30,
      offsetX: 0,
      textAnchor: 'middle',
      style: {
        padding: {
          top: 30,
        },
        fontSize: '14px',
        fontWeight: '600',
        colors: ['#000000'],
      },
    },
    tooltip: {
      style: {
        fontSize: '14px',
        fontFamily: 'IBM Plex Sans',
      },
      marker: {
        show: true,
        // show: false, // color of column in tooltip
      },
    },
    noData: {
      text: isLoading.isLoading ? 'Loading...' : isLoading.status === 'failed' ? 'Request failed' : 'No data',
      align: 'center',
      verticalAlign: 'middle',
      offsetX: 0,
      offsetY: -50,
      style: {
        fontSize: '14px',
        fontFamily: 'IBM Plex Sans',
      },
    },
  };
  return customOptions ? { ...options, ...customOptions } : options;
};

export const getSeriesData = (statisctics, radioValue) => {
  return Object.keys(statisctics).map((category) => ({
    x: radioValue === 'month' ? category.substring(0, 3) : category,
    y: statisctics[category],
  }));
};
