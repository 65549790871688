import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip } from 'antd';

import useScrollPosition from '../../Hooks/useScrollPosition';
import { ReactComponent as QuickContactUsButton } from '../../../../assets/img/buttons/quick_contact_us.svg';

import './quickNavUp.scss';
import parse from 'html-react-parser';

const QuickContactUs = () => {
  const [scrollPosition, windowHeight] = useScrollPosition();

  const isQuickNavDisplay = scrollPosition - windowHeight + 75 > 0;

  return (
    <>
      <Link to="/contact-us">
        <Tooltip
          title={parse('Have an issue filling the form?<br> Contact us for help.')}
          overlayClassName={'quick-contact-us__tooltip'}
          placement={'leftTop'}
        >
          <QuickContactUsButton
            className={classNames(
              'btn-quick-contact-us',
              isQuickNavDisplay ? 'btn-contact-us__moved-position' : 'btn-contact-us__default-position',
            )}
          />
        </Tooltip>
      </Link>
    </>
  );
};

export default QuickContactUs;
