import ToggleFilter from '../../Shared/Table/ToggleFilter/ToggleFilter';

const CandidateEmployeeToggleFilter = ({
  candidateEmployeeToggleParam,
  setCandidateEmployeeToggleParam,
  countByGroups,
}) => {
  const options = [
    {
      label: `All (${countByGroups?.all ? countByGroups.all : 0})`,
      name: 'All',
      key: 'all',
    },
    {
      label: `Candidate (${countByGroups?.candidates ? countByGroups.candidates : 0})`,
      name: 'Candidate',
      key: 'candidates',
    },
    {
      label: `Contactor (${countByGroups?.contractors ? countByGroups.contractors : 0})`,
      name: 'Contactor',
      key: 'contractors',
    },
    {
      label: `Employee (${countByGroups?.profiles ? countByGroups.profiles : 0})`,
      name: 'Employee',
      key: 'profiles',
    },
    {
      label: `Dismissed (${countByGroups?.dismissed ? countByGroups.dismissed : 0})`,
      name: 'Dismissed',
      key: 'dismissed',
    },
  ];

  return (
    <ToggleFilter
      candidateEmployeeToggleParam={candidateEmployeeToggleParam}
      countByGroups={countByGroups}
      items={options}
      tableKey={'PolandDashboard'}
      setCandidateEmployeeToggleParam={setCandidateEmployeeToggleParam}
    />
  );
};

export default CandidateEmployeeToggleFilter;
