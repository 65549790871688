import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import ModalConfirm from '../../Common/ModalConfirm/ModalConfirm';
import { deleteCase } from '../../../store/actions/requestDetails';
import './deleteRequest.scss';

const DeleteRequest = ({ requestId }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);

  const deleteProfile = () => {
    dispatch(deleteCase(requestId)).then((response) => {
      if (response?.status === 204) {
        history.push('/cases');
      }
    });
    setConfirmModalVisible(false);
  };

  return (
    <div className="delete-request__container">
      <span className="delete-request_btn" onClick={() => setConfirmModalVisible(true)}>
        Delete case
      </span>

      {isConfirmModalVisible && (
        <ModalConfirm
          isModalVisible={isConfirmModalVisible}
          setModalVisible={setConfirmModalVisible}
          confirmationQuestion={
            'The data collected in this case (VAC appointment, transportation/accommodation details) will be deleted permanently. Are you sure you want to delete this Case?'
          }
          handleClick={() => {
            deleteProfile();
          }}
        />
      )}
    </div>
  );
};

export default DeleteRequest;
