import axios from '../../axios';
import actionCreators from './actionCreators';
import queryString from 'query-string';

export const getEmployeeFeedbackForms = (requestId) => {
  return axios
    .get(`/api/v1/relocation-requests/employee-edit/${requestId}/feedback/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getEmployeeDocumentsToUploadForms = (employeeId) => {
  return axios
    .get(`/api/v1/legalization-request/employee/${employeeId}/documents-to-upload/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const sendFeedback = (requestId, data) => {
  return axios
    .post(`/api/v1/relocation-requests/employee-edit/${requestId}/feedback/`, data)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const getEmployeeFeedbacks = (page, filterParams, params) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/feedback-dashboard/`, {
        params: { page: page, ...params, ...filterParams },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.feedbackTableLoad(response.data, page));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};
