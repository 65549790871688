import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { getFormElement } from '../../Shared/Form/FormElements';
import { editRelativeContactInformation } from '../../../store/actions/requestDetails';
import ContactInfoValidationSchema from '../../Common/Validation/ContactInfoValidation';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';

const formSchema = [
  {
    fieldName: 'phones',
    type: 'PhoneField',
    label: 'Phone numbers',
  },
  {
    fieldName: 'address',
    type: 'TextareaAsInput',
    label: 'Registration address',
  },
];

const ContactInformation = ({ isCaseDisabled, ...contactInfo }) => {
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    dispatch(editRelativeContactInformation(contactInfo.id, contactInfo.rel_id, contactInfo.rel_req_id, values));
    setSubmitting(false);
  };

  return (
    <div className="ContactInfo">
      <Formik
        enableReinitialize
        initialValues={contactInfo}
        onSubmit={onSubmit}
        validationSchema={ContactInfoValidationSchema}
      >
        {(formikProps) => (
          <Form>
            {formSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, undefined, isCaseDisabled)}</div>
            ))}
            <SubmitButton title="Submit" formikProps={formikProps} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactInformation;
