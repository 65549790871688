import React, { useEffect } from 'react';
import ExitImg from '../../../assets/img/exit.svg';
import './Modal.scss';

export const Modal = (props) => {
  const { isVisible, setIsVisible, children, size } = props;
  let sizeModal = 'col-sm-10 col-md-9 col-lg-7 col-xl-4';

  if (size === 'xl') {
    sizeModal = 'col-sm-14 col-md-11 col-lg-10 col-xl-8';
  }
  if (size === 'l') {
    sizeModal = 'col-sm-11 col-md-9 col-lg-7 col-xl-5';
  }
  if (size === 'xs') {
    sizeModal = 'col-sm-8 col-md-6 col-lg-5 col-xl-3';
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, []);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  return (
    isVisible && (
      <div className="wrapper" onClick={() => setIsVisible(false)}>
        <div className={`modal-container ${sizeModal}`} onClick={(e) => e.stopPropagation()}>
          <div className="exit-container">
            <button className="exit-button" onClick={() => setIsVisible(false)}>
              <img src={ExitImg} alt="exit" />
            </button>
          </div>

          <div>{children}</div>
        </div>
      </div>
    )
  );
};

export default Modal;
