import * as Yup from 'yup';

export const formSchemaWithoutRequiredField = [
  {
    fieldName: 'emailList',
    type: 'SelectTags',
    label: 'To',
    options: [],
    filterOption: (inputValue, option) =>
      [option.internalId, option.value, option.fullName].join('').toLowerCase().includes(inputValue.toLowerCase()),
  },
  {
    fieldName: 'ccList',
    type: 'SelectTags',
    label: 'CC',
    options: [],
    disabled: true,
    filterOption: (inputValue, option) =>
      [option.internalId, option.value, option.fullName].join('').toLowerCase().includes(inputValue.toLowerCase()),
  },
  {
    fieldName: 'bccList',
    type: 'SelectTags',
    label: 'BCC',
    options: [],
    filterOption: (inputValue, option) =>
      [option.internalId, option.value, option.fullName].join('').toLowerCase().includes(inputValue.toLowerCase()),
  },
  {
    fieldName: 'subject',
    type: 'TextInput',
    label: 'Subject',
    placeholder: 'Write your subject here...',
  },
  {
    fieldName: 'message',
    type: 'TinyMCEField',
    label: 'Text',
    placeholder: 'Write your text here...',
  },
];

export const validationSchema = Yup.object().shape({
  subject: Yup.string().required('This field is required'),
  message: Yup.string().required('This field is required'),
});
