import { useState } from 'react';
import classNames from 'classnames';

import ArrowIcon from '../../../assets/img/icons/arrow.svg';
import './documentAccordion.scss';

const DocumentAccordion = (props) => {
  const { initialState, documentId, targetRef, documentName, type } = props;

  const checkboxState =
    type === 'employee'
      ? JSON.parse(localStorage.getItem('employeeDocumentAccordion')) || {}
      : type === 'related'
      ? JSON.parse(localStorage.getItem('relatedDocumentAccordion')) || {}
      : type === 'pet'
      ? JSON.parse(localStorage.getItem('petDocumentAccordion')) || {}
      : {};

  const [isActive, setIsActive] = useState(checkboxState[documentId] || initialState || false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  checkboxState[documentId] = isActive;

  localStorage.setItem(`${type}DocumentAccordion`, JSON.stringify(checkboxState));

  return (
    <>
      <div className="document-form__title-container" onClick={handleClick}>
        {targetRef ? (
          <h4 className="document-form__header" id={documentId} ref={targetRef}>
            {documentName}
          </h4>
        ) : (
          <h4 className="document-form__header" id={documentId}>
            {documentName}
          </h4>
        )}
        <img
          className={classNames('document-form__accordion', { accordionClose: !isActive, accordionOpen: isActive })}
          src={ArrowIcon}
          alt="arrow"
        />
      </div>
      {isActive && props.children}
    </>
  );
};

export default DocumentAccordion;
