import { Switch, Route, Redirect } from 'react-router-dom';

import LegalizationRequestOverview from '../LegalizationRequestOverview/LegalizationRequestOverview';
import AllLegalizationRequests from '../LegalizationRequestsHistory/LegalizationRequestsHistory';

const ContentRoutes = ({ employee, isHrManager, isProfileDisable, isCandidate }) => {
  return (
    <div className="employee-profile__content">
      {/*<AdditionalInfoRight employeeId={employee.uuid} gmtMember={employee.profileGmtMember} />*/}
      <Switch>
        <Route
          exact
          path="/legalization-profile/:id/legalization-request"
          component={() => <LegalizationRequestOverview employeeId={employee?.uuid} />}
        />
        <Route
          exact
          path="/legalization-profile/:id/legalization-requests"
          component={() => <AllLegalizationRequests employeeId={employee?.uuid} />}
        />

        <Redirect exact from="/legalization-profile/:id/" to="/legalization-profile/:id/legalization-request" />
      </Switch>
    </div>
  );
};

export default ContentRoutes;
