import { Tooltip } from 'antd';

import './otherSpellingSmgWodLinks.scss';

import { ReactComponent as WODIcon } from '../../../assets/img/wodLogo.svg';
import { ReactComponent as SMGIcon } from '../../../assets/img/smgLogo.svg';
import { ReactComponent as UserInfoIcon } from '../../../assets/img/icons/userInfo.svg';

const OtherSpellingSmgWodLinks = ({ internalId, otherNameSpelling }) => {
  return (
    <div className={'links-icons__wrapper'}>
      {otherNameSpelling && (
        <span className="user-info">
          <Tooltip placement="topLeft" title={otherNameSpelling} arrowPointAtCenter={true}>
            <UserInfoIcon />
          </Tooltip>
        </span>
      )}
      <a
        className="wodLogo"
        target="_blank"
        rel="noreferrer"
        href={'https://employees-wod.ventionteams.com/employees/' + internalId}
      >
        <Tooltip placement="topLeft" title={'Employee Development'} arrowPointAtCenter={true}>
          <WODIcon className={'links-icon wod-icon'} />
        </Tooltip>
      </a>
      <a
        className="smgLogo"
        target="_blank"
        rel="noreferrer"
        href={'https://smg.ventionteams.com/administrative/employees/' + internalId}
      >
        <Tooltip placement="topLeft" title={'Solution Management Gate'} arrowPointAtCenter={true}>
          <SMGIcon className={'links-icon smg-icon'} />
        </Tooltip>
      </a>
    </div>
  );
};

export default OtherSpellingSmgWodLinks;
