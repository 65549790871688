import AdditionalStepsForm from './AdditionalStepsForm/AdditionalStepsForm';

const AdditionalSteps = ({ documents, requestId }) => {
  return (
    <div className="additional-steps">
      <div className="ars-wrapper">
        {documents.length > 0 &&
          documents.map((document, index) => {
            return <AdditionalStepsForm key={index} requestId={requestId} document={document} />;
          })}
        {!documents.length && <p>No available documents</p>}
      </div>
    </div>
  );
};

export default AdditionalSteps;
