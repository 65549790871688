import { useState } from 'react';
import { useDispatch } from 'react-redux';

import SpinnerImg from '../../../assets/img/SpinnerWhite.svg';
import { getParams } from '../tableProperties/getParams';
import { legalizationDocumnetsExportExcel } from '../../../store/actions/legalizationDocuments';
import './generationExcel.scss';

const GenerationExcel = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const generationExcel = () => {
    setLoading(true);
    const data = {
      objectUuids: [],
      allObjects: true,
      excludeIds: [],
    };
    const [ordering, filtering, searchParams, toggleParams] = getParams({
      tableKey: 'PolandDashboard',
    });

    dispatch(
      legalizationDocumnetsExportExcel(data, filtering, {
        ordering: ordering,
        search: searchParams,
        toggle: toggleParams,
      }),
    ).then((response) => setLoading(false));
  };
  return (
    <button
      className="legalization__generation-excel-btn button btn-form main-btn btn-back"
      onClick={generationExcel}
      // disabled={generationExcelRequestsIds.length === 0 && !allCases}
    >
      {isLoading ? <img src={SpinnerImg} alt="SpinnerContainer" /> : <>Generate Excel</>}
    </button>
  );
};

export default GenerationExcel;
