import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import { getFormElement } from '../../Shared/Form/FormElements';
import { editRelativeEmploymentInformation } from '../../../store/actions/requestDetails';
import EmploymentInfoValidationSchema from '../../Common/Validation/RelatedEmploymentInfoValidation';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';

const formSchema = [
  {
    fieldName: 'company',
    type: 'TextInput',
    label: 'Company name',
    required: true,
  },
  {
    fieldName: 'position',
    type: 'TextInput',
    label: 'Position',
    required: true,
  },
  {
    fieldName: 'phoneNumbers',
    type: 'PhoneField',
    label: 'Phone numbers',
    required: true,
  },
  {
    fieldName: 'address',
    type: 'TextareaAsInput',
    label: 'Address',
  },
];

const EmploymentInfo = ({ isCaseDisabled, ...employmentInfo }) => {
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    dispatch(
      editRelativeEmploymentInformation(employmentInfo.id, employmentInfo.rel_id, employmentInfo.rel_req_id, values),
    );
    setSubmitting(false);
  };

  return (
    <div className="GeneralInfo">
      <Formik
        enableReinitialize
        initialValues={employmentInfo}
        onSubmit={onSubmit}
        validationSchema={EmploymentInfoValidationSchema}
      >
        {(formikProps) => (
          <Form>
            {formSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, undefined, isCaseDisabled)}</div>
            ))}
            <SubmitButton title="Submit" formikProps={formikProps} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EmploymentInfo;
