import { Link } from 'react-router-dom';

import { List } from 'antd';
import SpinnerContainer from '../../../Common/SpinnerContainer/SpinnerContainer';

import './listTemplates.scss';

const ListTemplates = ({ templates, isLoading }) => {
  return (
    <div className="list-templates__wrapper">
      <div className="list-templates__header">
        <h3 className="underlined panel-top-row">List of templates</h3>
      </div>
      <div id="scrollableDiv" className="list-templates">
        <List
          dataSource={templates}
          renderItem={(item) => (
            <List.Item key={item.id}>
              <div className="template-name">
                <Link
                  to={{
                    pathname: `/email-templates/` + item.id,
                  }}
                >
                  {item.name}
                </Link>
              </div>
            </List.Item>
          )}
          loading={{ indicator: <SpinnerContainer />, size: 'large', spinning: isLoading }}
        />
      </div>
    </div>
  );
};

export default ListTemplates;
