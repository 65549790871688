import { types } from '../actions/types';

const initialState = {};

const compensationCalculatorReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COMPENSATION_CALCULATOR_LOAD:
      return {
        ...state,
        [action.payload.requestId]: action.payload.compensationInfo,
      };
    case types.COMPENSATION_CALCULATOR_UPDATE:
      return {
        ...state,
        [action.payload.requestId]: { ...state[action.payload.requestId], ...action.payload.compensationInfo },
      };
    default:
      return {
        ...state,
      };
  }
};

export default compensationCalculatorReducer;
