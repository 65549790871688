import dayjs from 'dayjs';

import VisaDetails from './VisaDetails/VisaDetails';
import CheckboxWithLabel from '../FormElements/CheckboxWithLabel/CheckboxWithLabel';
import RelatedProfiles from './RelatedProfiles/RelatedProfiles';
import './caseProgress.scss';
import { useSelector } from 'react-redux';
import { defaultDateFormat } from '../../../../constants';

const CaseProgress = ({ requestId, caseProgress }) => {
  const { withWhom, isVacRequired, vacAppointment, managerNotifiedAt, compensationDate } = caseProgress;
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  return (
    <div className="case-progress">
      <h3>Case progress:</h3>
      <ul>
        <li>
          <RelatedProfiles withWhom={withWhom} requestId={requestId} />
        </li>
        <li>
          <VisaDetails requestId={requestId} isVacRequired={isVacRequired} vacAppointment={vacAppointment} />
        </li>
        <li>
          <CheckboxWithLabel
            tabKey="managerIsNotified"
            mainLabel={'Manager is notified'}
            secondaryLabel={
              managerNotifiedAt
                ? `Email sent ${dayjs(managerNotifiedAt).format(`${displayDateFormat} hh:mm A (Z [UTC])`)}`
                : ''
            }
            isDisabled={true}
            isChecked={managerNotifiedAt}
          />
        </li>
        <li>
          <CheckboxWithLabel
            tabKey="compensation"
            mainLabel={'Compensation is calculated'}
            secondaryLabel={
              compensationDate
                ? `Calculated ${dayjs(compensationDate).format(`${displayDateFormat} hh:mm A (Z [UTC])`)}`
                : ''
            }
            isDisabled={true}
            isChecked={compensationDate}
          />
        </li>
        {/* <li>
          <CheckboxWithLabel
            tabKey="feedback"
            mainLabel={'Employee feedback is gathered'}
            secondaryLabel={''}
            isDisabled={true}
            isChecked={false}
          />
        </li> */}
      </ul>
    </div>
  );
};

export default CaseProgress;
