import { types } from '../actions/types';

const initialState = {
  hrNotes: null,
};

const hrNotesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.HR_NOTES_LOAD:
      return {
        hrNotes: action.payload.hrNotes,
      };
    case types.HR_NOTES_UPDATE:
      return {
        ...state,
        hrNotes: { ...state.hrNotes, ...action.payload.hrNotes },
      };
    default:
      return {
        ...state,
      };
  }
};

export default hrNotesReducer;
