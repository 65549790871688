import classNames from 'classnames';
import { FieldArray, Field, ErrorMessage, useField } from 'formik';
import './phoneField.scss';

const PhoneField = (props) => {
  const { name, label, disabled, isRequired } = props;
  const [field] = useField(props);
  return (
    <div className="form-group phone-field">
      <label className={classNames('control-label', { required: isRequired })} htmlFor={name}>
        {label}
      </label>
      <FieldArray name={name}>
        {({ insert, remove, push }) => (
          <div className="field-array">
            {field.value.length > 0 &&
              field.value.map((phone, index) => (
                <div className="phone-block" key={index}>
                  <div className="d-flex phone-field__container">
                    <Field
                      name={`${name}.${index}`}
                      className="form-control"
                      placeholder="+XXX XX XXXXXXX"
                      type="text"
                      disabled={disabled}
                    />
                    <button
                      type="button"
                      className="btn btn-control"
                      onClick={() => insert(index + 1, '')}
                      title="Add phone number"
                      disabled={disabled}
                    >
                      +
                    </button>
                    {field.value.length !== 1 && (
                      <button
                        type="button"
                        className="btn btn-control"
                        onClick={() => remove(index)}
                        title="Remove phone number"
                        disabled={disabled}
                      >
                        -
                      </button>
                    )}
                  </div>
                  <ErrorMessage
                    name={`${name}.${index}`}
                    className="field-error"
                    render={(msg) => <div className="phone-field-error">{msg}</div>}
                  />
                </div>
              ))}
          </div>
        )}
      </FieldArray>
    </div>
  );
};

export default PhoneField;
