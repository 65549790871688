import classNames from 'classnames';
import { ErrorMessage, useField } from 'formik';
import { Select } from 'antd';

import { ReactComponent as CloseIcon } from '../../../../../assets/img/icons/closeTags.svg';
import './selectTags.scss';

const SelectTags = (props) => {
  const {
    name,
    label,
    formIndex,
    nameFieldArray,
    disabled,
    isRequired,
    options,
    formikProps,
    placeholder,
    filteroption,
  } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  const [field, , { setValue }] = useField(fieldName);

  const handleChange = (value) => {
    formikProps.setFieldValue(fieldName, value);
  };

  return (
    <div className="form-group form-group__column select-tags__wrapper">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}

      <Select
        className="form-input-width select-tags"
        mode="tags"
        value={field.value}
        onChange={handleChange}
        options={options}
        filterOption={filteroption}
        id={fieldName}
        placeholder={placeholder}
        name={fieldName}
        disabled={disabled}
        removeIcon={<CloseIcon />}
      />

      <ErrorMessage
        name={fieldName}
        render={(msg) => (
          <span className="error" style={{ color: 'red' }}>
            {msg}
          </span>
        )}
      />
    </div>
  );
};

export default SelectTags;
