import actionCreators from './actionCreators';
import axios from '../../axios';

export const getCaseController = (requestId) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/${requestId}/tabs/`)
      .then((response) => {
        dispatch(actionCreators.caseControllerLoad(response.data, requestId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const updateCaseController = (requestId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .put(`/api/v1/relocation-requests/${requestId}/tabs/`, data)
      .then((response) => {
        dispatch(actionCreators.caseControllerUpdate(response.data, requestId));
        return response.data;
      })
      .catch((error) => {
        toast.errorMessage = error.response?.data;
        toast.type = 'error';
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
