import { types } from '../actions/types';

export const initialState = {
  genericRelocationForms: [],
  filterByCountry: '',
  next: null,
  previous: null,
  count: 0,
};

const genericRelocationFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GENERIC_RELOCATION_FORMS_LOADED:
      return {
        genericRelocationForms: action.payload.genericRelocationForms,
        filterByCountry: action.payload.filterByCountry || '',
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
      };
    case types.GENERIC_RELOCATION_FORMS_UPDATE:
      return {
        ...state,
        genericRelocationForms: [...state.genericRelocationForms, ...action.payload.genericRelocationForms],
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.GENERIC_RELOCATION_FORMS_UPDATED:
      return {
        ...state,
        genericRelocationForms: state.genericRelocationForms.map((item) => {
          if (item.id === action.payload.genericRelocationForms.id) {
            item = action.payload.genericRelocationForms;
          }
          return item;
        }),
      };
    case types.GENERIC_RELOCATION_FORMS_ADD:
      return {
        ...state,
        genericRelocationForms: [...state.genericRelocationForms, action.payload.genericRelocationForms],
      };

    case types.GENERIC_RELOCATION_FORMS_ERROR:
      return {
        genericRelocationForms: action.payload.genericRelocationForms,
      };
    default:
      return {
        ...state,
      };
  }
};

export default genericRelocationFormsReducer;
