import React, { useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { getFormElement } from '../../Shared/Form/FormElements';
import TicketBookedBlock from './TicketBookedBlock/TicketBookedBlock';
import DeleteForm from '../DeleteForm/DeleteForm';
import { deleteTicketForm } from '../../../store/actions/uploadFile';
import { SubmitButton, CancelButton } from '../../Shared/Form/Buttons/Buttons';
import CurrentAttachmentBlock from './CurrentAttachmentBlock/CurrentAttachmentBlock';
import { transportationDetailsInitialValues, validationSchema } from './validationShema';
import './transportationDetails.scss';

const TransportationDetails = (props) => {
  const {
    transportationDetailsAllInfo,
    onSubmit,
    formSchema,
    isTicketsBookedInfo,
    requestId,
    transportationEmployeeDetails,
    relatedReqId,
    type,
    petReqId,
    isCaseDisabled,
  } = props;

  const dispatch = useDispatch();

  const [ticketAmount, setTicketAmount] = useState();
  const [transportType, setTransportType] = useState();

  const updateTicketAmount = (newTicketAmount, t, formIndex) => {
    setTicketAmount({ ...ticketAmount, [formIndex]: newTicketAmount });
  };

  const updateTransportType = (newTransportType, t, formIndex) => {
    setTransportType({ ...transportType, [formIndex]: newTransportType });
    setTicketAmount({
      ...ticketAmount,
      [formIndex]: newTransportType !== 'plane-transit' ? 1 : 2,
    });
  };

  formSchema.forEach((item) => {
    if (item.fieldName === 'ticketAmount') {
      item.updateselectvalueroot = updateTicketAmount;
    }
    if (item.fieldName === 'transportType') {
      item.updateselectvalueroot = updateTransportType;
    }
  });

  const initialValues = {
    transportationDetailsInfo: transportationDetailsAllInfo.length
      ? transportationDetailsAllInfo.map((item) => ({ ...item, files: [] }))
      : [transportationDetailsInitialValues],
  };

  const handleDeleteForm = (tdId) => () => {
    dispatch(deleteTicketForm(tdId, type, requestId, relatedReqId, petReqId));
  };

  return (
    <div className="TransportationDetails">
      {
        <>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(formikProps) => {
              return (
                <Form>
                  <FieldArray
                    name="transportationDetailsInfo"
                    render={({ remove, push }) => (
                      <>
                        <div>
                          {formikProps.values.transportationDetailsInfo.length > 0 &&
                            formikProps.values.transportationDetailsInfo.map((transportationDetailsInfo, formIndex) => (
                              <div className="transportation-details-container" key={formIndex}>
                                {!transportationDetailsInfo.id &&
                                  formikProps.values.transportationDetailsInfo.length > 1 && (
                                    <CancelButton remove={remove} formIndex={formIndex} />
                                  )}
                                {transportationDetailsInfo.id && !isCaseDisabled && (
                                  <div>
                                    <DeleteForm deleteForm={handleDeleteForm(transportationDetailsInfo.id)} />
                                  </div>
                                )}
                                {formSchema.map((field, index) => {
                                  if (field.fieldName === 'notes') {
                                    return (
                                      <React.Fragment key={index}>
                                        {transportationDetailsInfo?.attachments?.length > 0 && (
                                          <CurrentAttachmentBlock
                                            transportationDetailsInfoId={transportationDetailsInfo.id}
                                            transportationDetailsAllInfo={transportationDetailsAllInfo}
                                            type={type}
                                            requestId={requestId}
                                            relatedReqId={relatedReqId}
                                            petReqId={petReqId}
                                            isCaseDisabled={isCaseDisabled}
                                          />
                                        )}
                                        <div key={index}>
                                          {getFormElement(
                                            field.type,
                                            {
                                              nameFieldArray: 'transportationDetailsInfo',
                                              formIndex,
                                              ...field,
                                            },
                                            formikProps,
                                            isCaseDisabled,
                                          )}
                                        </div>
                                      </React.Fragment>
                                    );
                                  } else if (
                                    field.fieldName === 'ticketAmount' &&
                                    transportationDetailsInfo.transportType !== 'plane-transit'
                                  ) {
                                    return null;
                                  } else if (
                                    field.fieldName === 'ticketAmount' &&
                                    transportType &&
                                    transportType[formIndex] !== 'plane-transit'
                                  ) {
                                    return null;
                                  } else
                                    return (
                                      <div key={index}>
                                        {getFormElement(
                                          field.type,
                                          {
                                            nameFieldArray: 'transportationDetailsInfo',
                                            formIndex,
                                            ticketAmount:
                                              ticketAmount && ticketAmount[formIndex]
                                                ? ticketAmount[formIndex]
                                                : transportationDetailsInfo.transportType !== 'plane-transit'
                                                ? '1'
                                                : transportationDetailsInfo.ticketAmount > 2
                                                ? transportationDetailsInfo.transportType
                                                : '2',
                                            disabled:
                                              field.fieldName === 'transportType' &&
                                              formikProps.initialValues.transportationDetailsInfo[formIndex]
                                                ?.transportType === 'plane-transit' &&
                                              transportationDetailsInfo.id
                                                ? true
                                                : false,
                                            ...field,
                                          },
                                          formikProps,
                                          isCaseDisabled,
                                        )}
                                      </div>
                                    );
                                })}
                              </div>
                            ))}
                        </div>
                        <div className="button-wrapper">
                          <SubmitButton title="Submit" formikProps={formikProps} />
                          <button
                            type="button"
                            className={classNames('button btn-form main-btn', isCaseDisabled ? 'btn-disabled' : '')}
                            onClick={() => push(transportationDetailsInitialValues)}
                            disabled={isCaseDisabled}
                          >
                            Add tickets
                          </button>
                        </div>
                      </>
                    )}
                  />
                </Form>
              );
            }}
          </Formik>
          {transportationEmployeeDetails && transportationDetailsAllInfo.length > 0 && (
            <TicketBookedBlock isTicketsBookedInfo={isTicketsBookedInfo} requestId={requestId} />
          )}
        </>
      }
    </div>
  );
};

export default TransportationDetails;
