import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import History from '../../../Common/History/History';
import { getRelativeRelocationHistory } from '../../../../store/actions/requestRelatedProfiles';

const RelativeHistory = ({ requestId, relativeReqId }) => {
  const dispatch = useDispatch();

  const { relativeRequestHistory, count } = useSelector((state) => state.relativeRequestHistory);

  const getTableDataCallback = (requestId, relativeReqId) => (page) => {
    return getRelativeRelocationHistory(requestId, relativeReqId, page);
  };

  useEffect(() => {
    if (requestId && relativeReqId) {
      dispatch(getRelativeRelocationHistory(requestId, relativeReqId));
    }
  }, [requestId, dispatch, relativeReqId]);
  return (
    <History
      historyList={relativeRequestHistory}
      count={count}
      className="relocation-relative-history-details"
      name="RelativeHistory"
      getTableDataCallback={getTableDataCallback(requestId, relativeReqId)}
    />
  );
};

export default RelativeHistory;
