import { types } from '../actions/types';

const initialState = {
  searchEmployeesList: [],
};

const searchEmployeesCreateCaseReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_EMPLOYEES_FOR_CREATE_CASE_LOADED:
      return {
        ...state,
        searchEmployeesList: action.payload.searchEmployeesList,
      };
    default:
      return {
        ...state,
      };
  }
};

export default searchEmployeesCreateCaseReducer;
