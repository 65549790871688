import './notFound.scss';

const NotFound = () => {
  return (
    <section className="not-found">
      <h1> Not found</h1>
    </section>
  );
};

export default NotFound;
