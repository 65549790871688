import { useEffect } from 'react';

import { getCallbackUserManager } from '../userManager';

const SignOutRedirectCallback = () => {
  const trySignOutAsync = async () => {
    localStorage.removeItem('user');
    localStorage.removeItem('employeeDocumentAccordion');
    localStorage.removeItem('relatedDocumentAccordion');
    localStorage.removeItem('petDocumentAccordion');
    localStorage.removeItem('casesFilters');
    localStorage.removeItem('visa-onlyFilters');
    localStorage.removeItem('relocatedEmplFiters');
    localStorage.removeItem('columnsFilter');
    localStorage.removeItem('PolandDashboard');
    localStorage.removeItem('RemindersDashboard');

    const userManager = getCallbackUserManager();
    const user = await userManager.getUser();
    if (!user) {
      return;
    }

    // https://openid.net/specs/openid-connect-frontchannel-1_0.html#RPLogout
    const urlParams = new URLSearchParams(window.location.search);
    const iss = urlParams.get('iss');
    const sid = urlParams.get('sid');
    if (iss && userManager.settings.authority === iss && sid && user.profile.sid === sid) {
      await userManager.signoutRedirectCallback();
      await userManager.removeUser();
    }
  };

  useEffect(() => {
    trySignOutAsync();
  }, []);

  return null;
};

export default SignOutRedirectCallback;
