import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import classNames from 'classnames';

import useWindowDimensions from '../../../Shared/Hooks/useWindowDimensions';
import { getMenuList } from './getMenuList';
import './menuList.scss';

const MenuList = ({ selectedTab, setSelectedMenuItem, selectedMenuItem, docsInfo }) => {
  const { width } = useWindowDimensions();

  const selectedTabWithoutIndex = selectedTab.substr(0, selectedTab.indexOf('_')) || selectedTab;
  const extractedDigitFromSelectedTab = selectedTab.match(/\d+/g);
  const selectedTabIndex = extractedDigitFromSelectedTab ? extractedDigitFromSelectedTab[0] : selectedTab;

  const menuList = getMenuList(selectedTabWithoutIndex, selectedTabIndex, docsInfo);

  useEffect(() => {
    if (!selectedMenuItem) {
      setSelectedMenuItem(menuList[0].value);
    }
  }, [selectedTab]);

  return (
    <div className="menu-list">
      {width > 767 ? (
        <>
          {menuList.map((item, index) => (
            <div
              className={classNames('menu-list__item', selectedMenuItem === item.value && 'menu-list__item-active')}
              key={item.value}
              onClick={() => setSelectedMenuItem(item.value)}
            >
              {item.name}
            </div>
          ))}
        </>
      ) : (
        <Tabs
          activeKey={selectedMenuItem || 'personalInfo'}
          items={menuList.map((item) => {
            return {
              label: item.name,
              key: item.value,
            };
          })}
          onTabClick={(value) => setSelectedMenuItem(value)}
        />
      )}
    </div>
  );
};

export default MenuList;
