import { useContext } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { editResponsible } from '../../../store/actions/requestDetails';
import { editGMGResponsible } from '../../../store/actions/employeeProfile';
import { customStyles } from '../../Case/CaseList/CaseItem/caseItemCustomStyles';
import { IsCaseDisabledContext } from '../RequestDetails';
import './gmgResponsible.scss';

const GmgResponsible = (props) => {
  const { requestId, gmtMember, gmtType, gmgResponsible, employeeId, isClearable } = props;
  const dispatch = useDispatch();

  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const responsibleSelectOptions = gmgResponsible?.map((responsible) => {
    return { label: responsible.generalInfo.fullName, value: responsible.uuid };
  });

  const onResponsibleChanged = (option) => {
    if (gmtType === 'case_gmt_member') {
      dispatch(editResponsible(requestId, { gmtMemberId: option, gmtMemberType: gmtType }));
    }
    if (gmtType === 'profile_gmt_member') {
      dispatch(editGMGResponsible(employeeId, { gmtMemberId: option }));
    }
  };

  return (
    <div className={classNames('responsible-top')}>
      <strong className="additional-top__label">Responsible:</strong>
      <Select
        defaultValue={
          gmtMember?.uuid
            ? {
                value: gmtMember?.uuid ? gmtMember?.uuid : 0,
                label: gmtMember?.generalInfo.fullName ? gmtMember?.generalInfo.fullName : '',
              }
            : ''
        }
        placeholder="-select-"
        options={responsibleSelectOptions}
        styles={customStyles}
        onChange={(option) => onResponsibleChanged(option && option.value)}
        className={classNames('status-container__select')}
        isClearable={isClearable}
        isDisabled={isCaseDisabled}
      />
    </div>
  );
};

export default GmgResponsible;
