import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getResponsibleGMG } from '../../../store/actions/requestDetails';
import NotesModal from '../../Common/NotesModal/NotesModal';
import GmgResponsible from '../../RequestDetails/GmgResponsible/GmgResponsible';
import './additionalInfoRight.scss';

const AdditionalInfoRight = ({ employeeId, gmtMember }) => {
  const dispatch = useDispatch();

  const gmgResponsible = useSelector((state) => state.gmgResponsible.gmgResponsible);

  const [isModalVisible, setModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    if (!gmgResponsible?.length) {
      dispatch(getResponsibleGMG());
    }
  }, [dispatch]);

  return (
    <div className="additional-info-top ">
      <GmgResponsible
        employeeId={employeeId}
        gmtMember={gmtMember}
        gmtType={'profile_gmt_member'}
        gmgResponsible={gmgResponsible}
        isClearable={true}
      />
      <NotesModal
        employeeId={employeeId}
        isModalVisible={isModalVisible}
        setModalVisible={setModalVisible}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
      />
    </div>
  );
};

export default AdditionalInfoRight;
