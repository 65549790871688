import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';

import {
  changeDocumentsStatus,
  deleteEmployeeProfile,
  editGMGResponsible,
} from '../../../store/actions/employeeProfile';
import {
  deleteEmployeeProfileFromLegalizationDocuments,
  getLegalizationDocuments,
} from '../../../store/actions/legalizationDocuments';
import ModalConfirm from '../../Common/ModalConfirm/ModalConfirm';
import Events from '../../Events/Events';
import GarbageIcon from '../../../assets/img/icons/garbage.svg';
import { getParams } from '../tableProperties/getParams';
import MergeCandidate from './MergeCandidate/MergeCandidate';

import './actionsCell.scss';
import { tableDropdownCustomStyles } from '../../Shared/Table/CustomStyles/dropdownCustomStyles';
import { TableDropdown } from '../../Common/Table/Dropdown/Dropdown';

const ActionsCell = ({
  employeeId,
  isCandidate,
  documentsStatus,
  documentsStatusMessage,
  events,
  page,
  dashboardId,
  relocationCaseId,
  selectedCountry,
  disabled,
}) => {
  const dispatch = useDispatch();

  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [isDeleteProfileConfirmModalVisible, setDeleteProfileConfirmModalVisible] = useState(false);

  const onDeleteCandidateProfile = (employeeId) => {
    dispatch(deleteEmployeeProfile(employeeId)).then((response) => {
      const [ordering, filtering, searchParams, toggleParams] = getParams({
        tableKey: 'PolandDashboard',
      });
      dispatch(
        getLegalizationDocuments(page, filtering, {
          ordering: ordering,
          search: searchParams,
          toggle: toggleParams,
        }),
      );
    });

    setConfirmModalVisible(false);
  };

  const test =
    events?.length > 0 ||
    isCandidate ||
    (!isCandidate && dashboardId && !relocationCaseId && selectedCountry.toLowerCase() !== 'all');

  const onDeleteEmployeeProfile = () => {
    dispatch(deleteEmployeeProfileFromLegalizationDocuments(employeeId, dashboardId)).then((response) => {
      const [ordering, filtering, searchParams, toggleParams] = getParams({
        tableKey: 'PolandDashboard',
      });
      dispatch(
        getLegalizationDocuments(page, filtering, {
          ordering: ordering,
          search: searchParams,
          toggle: toggleParams,
        }),
      );
    });

    setDeleteProfileConfirmModalVisible(false);
  };

  const onDocumentsStatusChange = (uuid, value) => {
    dispatch(changeDocumentsStatus(uuid, { status: value })).then(() => {
      const [ordering, filtering, searchParams, toggleParams] = getParams({
        tableKey: 'PolandDashboard',
      });
      dispatch(
        getLegalizationDocuments(page, filtering, {
          ordering: ordering,
          search: searchParams,
          toggle: toggleParams,
        }),
      );
    });
  };

  return (
    <div className="action-required" style={{ flexFlow: 'column' }}>
      {test && (
        <div
          className={'test'}
          style={{
            paddingBottom: '8px',
            // gap: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <MergeCandidate candidateUuid={employeeId} isCandidate={isCandidate} page={page} />
          {events?.length > 0 && (
            <Events
              instanceId={employeeId}
              events={events}
              eventsType={isCandidate ? 'candidateProfile' : 'employeeProfile'}
              tooltipPlace={'topRight'}
            />
          )}
          {isCandidate && (
            <>
              <Tooltip placement={'topRight'} title={'Delete candidate profile'} arrowPointAtCenter={true} zIndex={10}>
                <img
                  className="delete-candidate-icon"
                  src={GarbageIcon}
                  alt="Delete candidate profile"
                  onClick={() => setConfirmModalVisible(true)}
                />
              </Tooltip>
            </>
          )}
          {!isCandidate && dashboardId && !relocationCaseId && selectedCountry.toLowerCase() !== 'all' && (
            <>
              <Tooltip
                placement={'topRight'}
                title={'Delete employee profile from the table'}
                arrowPointAtCenter={true}
                zIndex={10}
              >
                <img
                  className="delete-candidate-icon"
                  src={GarbageIcon}
                  alt="Delete employee profile"
                  onClick={() => setDeleteProfileConfirmModalVisible(true)}
                />
              </Tooltip>
            </>
          )}
        </div>
      )}
      {documentsStatus && (
        <div
          className={'test2'}
          style={{ borderTop: test ? '1px solid #ced4da' : 'unset', paddingTop: test ? '8px' : 'unset' }}
        >
          <TableDropdown
            value={{ value: documentsStatus || 0, label: documentsStatus || '' }}
            options={[
              { label: 'Documents verified', value: 'Documents verified' },
              { label: 'Attention needed', value: 'Attention needed' },
            ]}
            onChange={(option) => onDocumentsStatusChange(employeeId, option && option.value)}
            customStyles={tableDropdownCustomStyles}
            disabled={disabled}
          />
          {documentsStatusMessage && (
            <span className={'doc-type'}>
              <span style={{ fontSize: '14px', fontWeight: 500 }}>Reason:</span> {documentsStatusMessage}
            </span>
          )}
        </div>
      )}
      {isConfirmModalVisible && (
        <ModalConfirm
          isModalVisible={isConfirmModalVisible}
          setModalVisible={setConfirmModalVisible}
          confirmationQuestion={
            'The data about this Candidate will be delete permanently. Are you sure you want to delete this Candidate profile?'
          }
          handleClick={() => {
            onDeleteCandidateProfile(employeeId);
          }}
        />
      )}
      {isDeleteProfileConfirmModalVisible && (
        <ModalConfirm
          isModalVisible={isDeleteProfileConfirmModalVisible}
          setModalVisible={setDeleteProfileConfirmModalVisible}
          confirmationQuestion={
            'Employee Profile will be deleted from the table. Are you sure you want to delete this Employee profile from the table?'
          }
          handleClick={() => {
            onDeleteEmployeeProfile();
          }}
        />
      )}
    </div>
  );
};

export default ActionsCell;
