import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import SelectDocumentsForm from './SelectDocumentsForm/SelectDocumentsForm';
import { getGenericFormsForEmployeeRelative } from '../../../../../../store/actions/genericForm';
import { formVacIsRequiredSchema } from './schemes';
import { getExcelFormsRelative } from '../../../../../../store/actions/excelForm';
import Spinner from '../../../../../Common/SpinnerContainer/Spinner/Spinner';

import '../selectDocumentsModal.scss';

const RelativeSelectDocuments = (props) => {
  const { forWhom, nextStep, backStep, country, setModalVisible, setStep, formikProps } = props;

  const [isDocumentsLoading, setIsDocumentsLoading] = useState(true);
  const [isFormsLoading, setIsFormsLoading] = useState(true);

  const dispatch = useDispatch();

  const genericForms = useSelector((state) => state.genericForms.genericFormsForRelative[country.id]) || [];
  const relativeForms = useSelector((state) => state.excelForms.excelFormsForRelative[country.id]) || [];

  const filterMapping = {
    child: 'is_for_child',
    spouse: 'is_for_spouse',
  };

  useEffect(() => {
    dispatch(
      getGenericFormsForEmployeeRelative({ [filterMapping[forWhom]]: true, countries: country.id }, country.id),
    ).finally(() => {
      setIsDocumentsLoading(false);
    });
    dispatch(
      getExcelFormsRelative({ [filterMapping[forWhom]]: true, countries: country.id, form_type: 'forms' }, country.id),
    ).finally(() => {
      setIsFormsLoading(false);
    });
  }, [dispatch, genericForms?.length, relativeForms?.length]);

  const formSchema = genericForms[forWhom]
    ? genericForms[forWhom].map((item) => {
        return { fieldName: `${forWhom}_${+item.id}`, type: 'CheckboxReverse', label: item.name };
      })
    : [];

  const formSchemaForForms = relativeForms[forWhom]
    ? relativeForms[forWhom].map((item) => {
        return { fieldName: `${forWhom}_${+item.id}`, type: 'CheckboxReverse', label: item.name };
      })
    : [];

  const onNext = () => {
    setStep(nextStep ? nextStep : 0);
  };

  const onBack = () => {
    setStep(backStep ? backStep : 0);
  };

  return (
    <>
      <h4 className="employee__title">{forWhom.charAt(0).toUpperCase() + forWhom.slice(1)}</h4>
      {(isDocumentsLoading || isFormsLoading) && (
        <div className={'employee-select-forms__spinner'}>
          <Spinner />
        </div>
      )}
      {!isDocumentsLoading && !isFormsLoading && (
        <SelectDocumentsForm
          setModalVisible={setModalVisible}
          onNext={onNext}
          onBack={onBack}
          forWhom={forWhom}
          formSchema={formSchema}
          formSchemaForForms={formSchemaForForms}
          formVacIsRequiredSchema={formVacIsRequiredSchema}
          formikProps={formikProps}
        />
      )}
    </>
  );
};

export default RelativeSelectDocuments;
