export const title = 'Residence permit';

export const radioGroupOptions = [
  {
    value: 'country',
    name: 'By country',
  },
];

export const filters = {
  radioInitialValue: 'country',
  analyticsType: 'residence_permit_count',
  analyticsSource: 're',
};

export const COLORS = ['#EB3D26', '#131314', '#96969C', '#606060'];

export const customOptions = {
  xaxis: {
    type: 'category',
    labels: {
      minHeight: 96,
      maxHeight: 96,
      rotate: -45,
      rotateAlways: true,
      style: {
        fontSize: '14px',
        fontFamily: 'IBM Plex Sans',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
    },
    tickPlacement: 'between',
  },
  yaxis: {
    labels: {
      style: {
        cssClass: 'apexcharts-xaxis-label',
      },
    },
  },
  colors: COLORS,
  fill: {
    opacity: 1,
    type: 'solid',
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '80%',
      endingShape: 'rounded',

      dataLabels: {
        orientation: 'horizontal',
        position: 'top',
        fontSize: '14px',
        fontFamily: 'IBM Plex Sans',
      },
      // rangeBarOverlap: false,
    },
  },
  legend: {
    show: true,
  },
  grid: {
    padding: {
      top: 10,
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: -30,
    textAnchor: 'middle',
    style: {
      padding: {
        top: 30,
      },
      fontSize: '14px',
      fontWeight: '600',
      colors: ['#000000'],
    },
  },
  tooltip: {
    shared: true,
    intersect: false,
    style: {
      fontSize: '14px',
      fontFamily: 'IBM Plex Sans',
    },
    y: {
      formatter: function (val) {
        return val || 0;
      },
    },
    marker: {
      show: true,
    },
  },
};

const subCateggories = ['Permanent Residence Card', 'Temporary Residence Card', 'Blue Card', 'Not provided'];

export const getSeriesData = (statisctics) => {
  const series = subCateggories.map((subCategory) => {
    const data = [];
    for (const country in statisctics) {
      const countryStatistics = statisctics[country];
      data.push({ x: country, y: countryStatistics[subCategory] || null });
    }
    return {
      name: subCategory,
      data: data,
    };
  });
  return series;
};
