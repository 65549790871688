import React from 'react';

import Spinner from './Spinner/Spinner';

import './spinnerContainer.scss';

const SpinnerContainer = () => {
  return (
    <div className="spinner-container">
      <Spinner />
    </div>
  );
};

export default SpinnerContainer;
