import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { editRelocationRequest } from '../../../../store/actions/request';
import { getFormElement } from '../../../Shared/Form/FormElements';
import './employeeVacBlock.scss';

const EmployeeVacBlock = ({ isVacRequired, requestId, consultationCountry, setWithWhom, consulateLocations }) => {
  const dispatch = useDispatch();

  const onHelpNeed = (value) => {
    setWithWhom((prev) => ({ ...prev, isVacRequired: value }));
    dispatch(editRelocationRequest(requestId, { isVacRequired: value }, true));
  };

  const handleConsulateLocation = (value) => {
    dispatch(editRelocationRequest(requestId, { consultationCountry: value }, true));
  };

  const consulateLocationOptions = consulateLocations
    .map((item) => ({ id: item.name, name: item.name }))
    .concat([{ id: 'Other', name: 'Other' }]);

  const formSchema = [
    {
      fieldName: 'isHelpNeed',
      type: 'Checkbox',
      label: 'Do you need help with visa?',
      updateselectvalueroot: onHelpNeed,
    },
    {
      fieldName: 'consultationCountry',
      type: 'Dropdown',
      label: 'Consulate location',
      options: consulateLocationOptions,
      updateselectvalueroot: handleConsulateLocation,
    },
  ];

  const formSchemaFilter =
    isVacRequired && consulateLocations?.length > 0
      ? formSchema
      : formSchema.filter((item) => item.fieldName === 'isHelpNeed');

  return (
    <div className="employee-vac__container">
      <div className="help-visa__container">
        <Formik
          enableReinitialize
          initialValues={{
            isHelpNeed: isVacRequired,
            consultationCountry: consultationCountry,
          }}
        >
          {(formikProps) => (
            <Form>
              {formSchemaFilter.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
              ))}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EmployeeVacBlock;
