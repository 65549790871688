import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import classNames from 'classnames';

import './countryInfo1.scss';

const CountryInfo = ({ title, description, countryFlag, relocationPackage }) => {
  const countryWithoutGaps = title.replace(' ', '-');

  return (
    <div className="country-info__container">
      <div className="country-info__header">
        <div className="country-info__header-country-name">{title}</div>
        <img src={countryFlag} alt="flag" />
      </div>
      <p className="country-info__content">{parse(description)}</p>
      <div className="country-info__relocation-package">
        {/* <Link className="" to={{ pathname: 'relocation/about/' + countryWithoutGaps, state: { countryName: title } }}> */}
        <button
          disabled={!relocationPackage}
          // className={classNames('button btn-form main-btn btn-back', { disabled: !relocationPackage })}
          className={classNames('button btn-form main-btn btn-back')}
        >
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://wiki.itechart-group.com/display/REL/Relocation%3A+${title}`}
          >
            Relocation to {title}
          </a>
        </button>
        {/* </Link> */}
      </div>
    </div>
  );
};

export default CountryInfo;
