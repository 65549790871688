import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeHistory } from '../../../store/actions/employeeProfile';

import History from '../../Common/History/History';

const EmployeeProfileHistory = ({ employeeId }) => {
  const dispatch = useDispatch();
  const { employeeHistory, count } = useSelector((state) => state.employeeHistoryReducer);

  const getTableDataCallback = (employeeId) => (page) => {
    return getEmployeeHistory(employeeId, page);
  };

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeHistory(employeeId));
    }
  }, [employeeId, dispatch]);

  return (
    <History
      historyList={employeeHistory}
      count={count}
      getTableDataCallback={getTableDataCallback(employeeId)}
      className="profile-history"
      name="profileHistory"
    />
  );
};

export default EmployeeProfileHistory;
