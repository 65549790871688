import { useState, useRef, useCallback } from 'react';
import { Menu, Tooltip } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import { useHistory } from 'react-router';
import classNames from 'classnames';

import logo from '../../../../assets/img/icons/goGlobalLogo.svg';
import HeaderSearch from './HeaderSearch/HeaderSearch';
import LogOut from '../../../../assets/img/buttons/LogOut.svg';
import PopUp from './PopUp/PopUp';
import SettingsIcon from '../../../../assets/img/settings-icon.svg';
import SettingsModal from './Settings/SettingsModal/SettingsModal';

import './Header.scss';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { getAppUserManager } from '../../../auth/userManager';
import { useSelector } from 'react-redux';

import DefaultUserIcon from '../../../../assets/img/default-user-photo_300x300.png';

const Header = (props) => {
  const history = useHistory();

  const { isApproverStaff, isPortalStaff, isHrManager } = props;

  const [isPopUpShow, setisPopUpShow] = useState(false);

  const burgerMenuRef = useRef();

  const logout = useCallback(() => history.push('/auth/signout-redirect'), [history]);

  const closePopUp = () => {
    setisPopUpShow(!isPopUpShow);
  };

  const handleLogOut = (e) => {
    e.preventDefault();
    closePopUp();
    logout();
  };

  const [userPhoto, setUserPhoto] = useState(DefaultUserIcon);
  const userManager = getAppUserManager();
  userManager.getUser().then((user) => {
    setUserPhoto(user?.profile?.picture);
  });

  return (
    <header className="header">
      <div className="header-inner">
        <div className="left-menu">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>

          <div className="menu-wrapper">
            {isPortalStaff && (
              <NavLink to={'/cases'} className={({ isActive }) => (isActive ? 'desctop active' : 'desctop inactive')}>
                Cases
              </NavLink>
            )}
            {(isPortalStaff || isApproverStaff) && (
              <NavLink
                to={'/relocated-employees'}
                className={({ isActive }) => (isActive ? 'desctop active' : 'desctop inactive')}
              >
                Relocated employees
              </NavLink>
            )}
            {isPortalStaff && (
              <NavLink
                to={'/visa-only'}
                className={({ isActive }) => (isActive ? 'desctop active' : 'desctop inactive')}
              >
                Visa only
              </NavLink>
            )}
            {(isPortalStaff || isApproverStaff || isHrManager) && (
              <NavLink
                to={'/legalization-documents'}
                className={({ isActive }) => (isActive ? 'desctop active' : 'desctop inactive')}
              >
                Documents
              </NavLink>
            )}
            {/*{isPortalStaff && (*/}
            {/*  <NavLink*/}
            {/*    to={'/reminders'}*/}
            {/*    className={({ isActive }) => (isActive ? 'desctop active' : 'desctop inactive')}*/}
            {/*  >*/}
            {/*    Reminders*/}
            {/*  </NavLink>*/}
            {/*)}*/}
          </div>
        </div>

        <div className="right-menu">
          {(isPortalStaff || isApproverStaff) && (
            <div className="mobile-search">
              <HeaderSearch type="mobileSearch" />
            </div>
          )}
          {(isPortalStaff || isApproverStaff) && <HeaderSearch type="desktopSearch" />}
          {
            <div className="menu-wrapper">
              <Link to={'/relocation/FAQ'} className="desctop">
                FAQ
              </Link>
            </div>
          }
          {/*{(isPortalStaff || isApproverStaff) && (*/}
          {/*  <div>*/}
          {/*    <div className="settingsIcon-mobile" style={{ paddingLeft: '20px' }}>*/}
          {/*      <Tooltip placement={'bottom'} title={'Settings'}>*/}
          {/*        <div className="tooltip-button">*/}
          {/*          <img*/}
          {/*            style={{ width: '22px' }}*/}
          {/*            className="settings-modal-icon"*/}
          {/*            src={SettingsIcon}*/}
          {/*            alt="settings"*/}
          {/*            onClick={() => setModalVisible(true)}*/}
          {/*          />*/}
          {/*        </div>*/}
          {/*      </Tooltip>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*)}*/}
          {/*{isPortalStaff || isApproverStaff ? (*/}
          {/*  <div className="menu" ref={burgerMenuRef}>*/}
          {/*    <input*/}
          {/*      id="menuToggle"*/}
          {/*      type="checkbox"*/}
          {/*      className="visually-hidden"*/}
          {/*      checked={isPopUpShow}*/}
          {/*      onChange={closePopUp}*/}
          {/*    />*/}
          {/*    <div className={'avatar-root'}>*/}
          {/*      <img*/}
          {/*        alt="Dzmitry Bakunovich"*/}
          {/*        src="https://files-wod.itechart-group.com/api/smgphotos/49abcf3a-67df-11ec-827a-02e6fda07480"*/}
          {/*        className="MuiAvatar-img"*/}
          {/*      />*/}
          {/*    </div>*/}

          {/*    <label className={classNames('menu-btn', isPopUpShow ? 'rotate' : 'not-rotate')} htmlFor="menuToggle">*/}
          {/*      <div className="hamburger_menu">*/}
          {/*        <div></div>*/}
          {/*        <div></div>*/}
          {/*      </div>*/}
          {/*      <div className="close_menu">*/}
          {/*        <div></div>*/}
          {/*        <div></div>*/}
          {/*      </div>*/}
          {/*    </label>*/}
          {/*  </div>*/}
          {/*) : (*/}
          {/*  <Link to={'/login'} onClick={handleLogOut}>*/}
          {/*    <div className="logout">*/}
          {/*      <img src={LogOut} alt="logout" />*/}
          {/*    </div>*/}
          {/*  </Link>*/}
          {/*)}*/}
          <div className="new-menu" ref={burgerMenuRef}>
            <input
              id="menuToggle"
              type="checkbox"
              className="visually-hidden"
              checked={isPopUpShow}
              onChange={closePopUp}
            />
            <div className={'avatar-root'}>
              <img
                alt="Profile picture"
                src={userPhoto}
                className="MuiAvatar-img"
                onClick={closePopUp}
                onError={() => setUserPhoto(DefaultUserIcon)}
              />
            </div>

            <label className={classNames('menu-btn', isPopUpShow ? 'rotate' : 'not-rotate')} htmlFor="menuToggle">
              <div className="hamburger_menu">
                <div></div>
                <div></div>
              </div>
              <div className="close_menu">
                <div></div>
                <div></div>
              </div>
            </label>
          </div>
        </div>

        {/*<Menu*/}
        {/*  onClick={() => console.log(true)}*/}
        {/*  style={{ width: 256 }}*/}
        {/*  defaultSelectedKeys={['1']}*/}
        {/*  defaultOpenKeys={['sub1']}*/}
        {/*  mode="inline"*/}
        {/*  items={[]}*/}
        {/*/>*/}

        {/*{isPopUpShow && (*/}
        {/*  <Menu*/}
        {/*    onClick={() => console.log(true)}*/}
        {/*    style={{ width: 256 }}*/}
        {/*    defaultSelectedKeys={['1']}*/}
        {/*    defaultOpenKeys={['sub1']}*/}
        {/*    mode="inline"*/}
        {/*    items={items}*/}
        {/*  />*/}
        {/*)}*/}
        <PopUp
          isApproverStaff={isApproverStaff}
          isPortalStaff={isPortalStaff}
          isHrManager={isHrManager}
          closePopUp={closePopUp}
          burgerMenuRef={burgerMenuRef}
          isPopUpShow={isPopUpShow}
        />
      </div>
    </header>
  );
};
export default Header;
