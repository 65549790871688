const TitleWithSort = ({ sortColumns, sorterKey, title }) => {
  const sortedColumn = sortColumns?.find(({ column }) => column.key === sorterKey);

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ fontSize: '15px', color: '#7a7a7a', fontWeight: '500', marginBottom: '2px' }}>{title} </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {sortedColumn?.order ? (
          sortedColumn.order === 'ascend' ? (
            <svg
              style={{ marginLeft: '8px', fill: '#eb3d26' }}
              width="9"
              height="9"
              viewBox="0 0 8 7"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 6.5L0.535899 0.499999L7.4641 0.5L4 6.5Z" />
            </svg>
          ) : (
            <svg
              style={{ marginLeft: '8px', transform: 'rotate(180deg)', fill: '#eb3d26' }}
              width="9"
              height="9"
              viewBox="0 0 8 7"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 6.5L0.535899 0.499999L7.4641 0.5L4 6.5Z" />
            </svg>
          )
        ) : (
          <svg
            style={{ marginLeft: '8px', fill: '#555555' }}
            width="9"
            height="9"
            viewBox="0 0 8 7"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M4 6.5L0.535899 0.499999L7.4641 0.5L4 6.5Z" />
          </svg>
        )}
      </div>
    </div>
  );
};

export default TitleWithSort;
