import { types } from '../actions/types';

export const initialState = {
  legalizationForms: [],
  legalizationFormsForEmployee: [],
  filterByCountry: '',
  next: null,
  previous: null,
  count: 0,
};

const legalizationFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LEGALIZATION_FORMS_LOADED:
      return {
        ...state,
        legalizationForms: action.payload.legalizationForms,
        filterByCountry: action.payload.filterByCountry || '',
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
      };
    case types.LEGALIZATION_FORMS_UPDATE:
      return {
        ...state,
        legalizationForms: [...state.legalizationForms, ...action.payload.legalizationForms],
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.LEGALIZATION_FORMS_EMPLOYEE_LOAD:
      return {
        ...state,
        ...(action.payload.parameters?.is_for_employee && {
          legalizationFormsForEmployee: action.payload.legalizationForms,
        }),
      };
    case types.LEGALIZATION_FORMS_UPDATED:
      return {
        ...state,
        legalizationForms: state.legalizationForms.map((item) => {
          if (item.id === action.payload.legalizationForms.id) {
            item = action.payload.legalizationForms;
          }
          return item;
        }),
      };
    case types.LEGALIZATION_FORMS_ADD:
      return {
        ...state,
        legalizationForms: [...state.legalizationForms, action.payload.legalizationForms],
      };
    default:
      return {
        ...state,
      };
  }
};

export default legalizationFormsReducer;
