import { Formik, Form } from 'formik';
import { SubmitButton } from '../../../../../Shared/Form/Buttons/Buttons';
import Modal from '../../../../../Shared/Modal/Modal';
import { getFormElement } from '../../../../../Shared/Form/FormElements';
import useWindowDimensions from '../../../../../Shared/Hooks/useWindowDimensions';

const SelectFormsModal = ({ initialValues, onSubmit, formSchema, setModalVisible, isModalVisible, title }) => {
  const { width } = useWindowDimensions();

  return (
    <div className="select-forms-to-display">
      <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l">
        <div className="select-documents__wrapper">
          <h3 className="underlined panel-top-row">{title}</h3>
          <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
            {(formikProps) => {
              const { displayVacIsRequired, ...valuesWithoutDisplayVacIsRequired } = formikProps.values;
              return (
                <>
                  <Form>
                    <div
                      className="form__container"
                      style={{
                        gridTemplateRows: `repeat(${Math.ceil(
                          width <= 1600 ? formSchema.length / 2 : formSchema.length / 3,
                        )}, auto)`,
                      }}
                    >
                      {formSchema.map((field) => (
                        <div key={field.fieldName} className={field.fieldName}>
                          {getFormElement(field.type, field, formikProps)}
                        </div>
                      ))}
                    </div>

                    {!formSchema?.length && <p className="no-available-forms">No available forms</p>}
                    <div className="select-documents__button-container">
                      <button
                        type="button"
                        className="select-documents__cancel button btn-form main-btn"
                        onClick={() => setModalVisible(false)}
                      >
                        Cancel
                      </button>
                      <SubmitButton
                        title="Submit"
                        formikProps={formikProps}
                        forceDisabled={
                          !(
                            Object.values(valuesWithoutDisplayVacIsRequired).includes(true) &&
                            (!!formikProps.values.date || title.indexOf('send') < 0)
                          )
                        }
                      />
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
        </div>
      </Modal>
    </div>
  );
};

export default SelectFormsModal;
