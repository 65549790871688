import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCountriesWithImageUrlsForRelocation } from '../../../store/actions/countriesForRelocation';
import CountryInfo from './CountryInfo/CountryInfo';
import AskQuestionButton from '../Common/AskQuestionButton/AskQuestionButton';
import Spinner from '../../Common/SpinnerContainer/Spinner/Spinner';

import MainImg from '../../../assets/img/relocation/relocationMainPage.svg';

import './relocation1.scss';

import { Link } from 'react-router-dom';

const LegalizationRequestButton = () => {
  return (
    <Link to="/legalization-request">
      <button className="button btn-form main-btn btn-back" style={{ marginRight: '22px' }}>
        Legalization request
      </button>
    </Link>
  );
};

const Relocation = () => {
  const dispatch = useDispatch();

  const countriesInfo = useSelector((state) => state.countries.countriesForRelocation);

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (!countriesInfo?.length) {
      dispatch(getCountriesWithImageUrlsForRelocation()).then(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, [dispatch]);

  return (
    <section className="relocation-container">
      <div className="relocation-header">
        <div className="relocation-header__first-container">
          <h2 className="relocation-header__title">
            We are expanding
            <br />
            our borders with <br />
            new development centers <br />
          </h2>
          <p className="relocation-header__content">
            Please go to the{' '}
            <a
              className="relocation-header__content-links"
              target="_blank"
              rel="noreferrer"
              href="https://wiki.itechart-group.com/pages/viewpage.action?pageId=119115143"
            >
              Wiki page{' '}
            </a>{' '}
            to learn the latest information about relocation options we currently provide. Also, if you’re planning to
            relocate, please go to the{' '}
            <a
              className="relocation-header__content-links"
              target="_blank"
              rel="noreferrer"
              href="https://employees-wod.ventionteams.com/employees/me/locationInfo"
            >
              {' '}
              WOD{' '}
            </a>
            and submit this information in your profile (My Profile =&gt; More Options =&gt; Location Info)
          </p>
          <LegalizationRequestButton />
          <AskQuestionButton />
        </div>
        <div>
          <img className="relocation-header__img" src={MainImg} alt="pic" />
        </div>
      </div>
      {isLoading ? (
        <div className="relocation-container__spinner">
          <Spinner />
        </div>
      ) : (
        <div className="relocation-content">
          {countriesInfo &&
            countriesInfo.map((item, index) => (
              <CountryInfo
                key={item.name}
                title={item.name}
                description={item.description}
                countryFlag={item.countryFlag}
                relocationPackage={item.relocationPackage}
              />
            ))}
        </div>
      )}
    </section>
  );
};

export default Relocation;
