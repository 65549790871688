import React, { useEffect, useMemo, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import CustomTable from '../Shared/Table/CustomTable';
import { getResponsibleGMG } from '../../store/actions/requestDetails';
import { getValuesByElement } from '../FeedbackDashboard/FeedbackColumn/FeedbackColumn';
import ContentWrapper from '../Shared/Table/ContentWrapperMobileView/ContentWrapperMobileView';
import { TableDropdown } from '../Common/Table/Dropdown/Dropdown';
import { tableDropdownCustomStyles } from '../Shared/Table/CustomStyles/dropdownCustomStyles';
import axios from '../../axios';
import actionCreators from '../../store/actions/actionCreators';
import { Select } from 'antd';
import { getCountriesWithCompanies } from '../../store/actions/countriesForRelocation';
import FilterDropdownIcon from '../Shared/Table/FilterIcon/FilterIcon';
import CandidateEmployeeToggleFilter from '../LegalizationDocuments/CandidateEmployeeToggleFilter/CandidateEmployeeToggleFilter';
import ColumnsFilter from '../Shared/Table/ColumnsFilter/ColumnsFilter';
import GenerationExcel from '../LegalizationDocuments/GenerationExcel/GenerationExcel';
import ClearFilters from '../Shared/Table/ClearFilters/ClearFilters';
import SearchName from '../Shared/Table/SearchName/SearchName';
import queryString from 'query-string';
import { getDefaultColumns, lastColumns } from './tableProperties/tableProperties';
import { getDismissalLabel } from '../../helpers';
import { Link } from 'react-router-dom';
import { editAdditionalFeedbackInformation } from '../../store/actions/feedbackForm';
import { getEmployeeFeedbacks } from '../../store/actions/employeeFeedback';
import { parseFiltering, parseOrdering, transformObjectToArray } from '../Shared/Table/helpers';
import { filterMapping, sortMapping } from '../FeedbackDashboard/tableProperties';
import { getColumnsKeys } from '../LegalizationDocuments/tableProperties/tableProperties';

export const getLegalizationRequestCountries = (params) => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/legalization-request/forms/countries/', { params });
    dispatch(actionCreators.legalizationRequestCountriesLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getLegalizationRequests = (page, filterParams, params) => {
  return (dispatch) => {
    return axios
      .get('/api/v1/legalization-request/table/', {
        params: { page: page, ...params, ...filterParams },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.legalizationRequestListLoad(response.data, 1));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

const defaultOption = [{ value: 0, label: 'All' }];

const CustomSelect = ({ prefixIcon, children, ...rest }) => {
  return (
    <div style={{ position: 'relative' }}>
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <Select {...rest}>{children}</Select>
    </div>
  );
};

const editLegalization = (legalizationRequestId, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/legalization-request/${legalizationRequestId}/`, data);
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

const editLegalizationResponsible = (legalizationRequestId, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/legalization-request/${legalizationRequestId}/responsible/`, data);
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

const getParams = ({ tableKey, columns }) => {
  const paramsFromLocalStorage = JSON.parse(sessionStorage.getItem(tableKey));

  const getDefaultOrderingValue = () => {
    // const orderingParams =
    //   paramsFromLocalStorage && paramsFromLocalStorage?.ordering
    //     ? transformObjectToArray(paramsFromLocalStorage?.ordering)
    //     : [];
    //
    // console.log(paramsFromLocalStorage, paramsFromLocalStorage?.ordering);
    //
    // orderingParams.forEach((item) => {
    //   columns.find((columnItem) => columnItem.key === item.columnKey).defaultSortOrder = item.order;
    // });

    return [];
  };

  const getDefaultFilteringValue = () => {
    const filteringParams = paramsFromLocalStorage?.filtering || {};
    for (const key in filteringParams) {
      columns.find((item) => item.key === key).defaultFilteredValue = paramsFromLocalStorage.filtering[key];
    }

    return filteringParams;
  };

  const additionalFiltering = paramsFromLocalStorage?.additionalFiltering || {};

  const ordering = parseOrdering(getDefaultOrderingValue(), sortMapping);
  const mainFiltering = parseFiltering({
    rowFiltering: getDefaultFilteringValue(),
    dateRangeFilterMapping: null,
    multipleFilterMapping: null,
    filterMapping: filterMapping,
  });

  const filtering = { ...mainFiltering, ...additionalFiltering };

  const searchParams = paramsFromLocalStorage?.search || '';
  const toggleParams = paramsFromLocalStorage?.toggle || null;

  return [ordering, filtering, searchParams, toggleParams];
};

const CountryFilter = ({ tableKey, setSelectedCountry, selectedCountry, paramsFromLocalStorage }) => {
  const dispatch = useDispatch();
  const countriesInfo = useSelector((state) => state.countries.legalizationRequestCountries);

  useEffect(() => {
    if (!countriesInfo?.length) {
      dispatch(getLegalizationRequestCountries());
    }
  }, [dispatch, countriesInfo?.length]);

  const countryOptions =
    countriesInfo?.map((country) => ({
      value: country.id,
      label: country.name,
    })) || [];

  const handleChangeCountry = (value, option) => {
    setSelectedCountry(option);

    sessionStorage.setItem(
      tableKey,
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem(tableKey)),
        additionalFiltering: { ...paramsFromLocalStorage?.additionalFiltering, country: option.value },
        country: option,
      }),
    );
  };

  return (
    <CustomSelect
      suffixIcon={
        <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.49148 4.99831C4.34159 4.99864 4.19632 4.94059 4.0809 4.83424L0.231752 1.26755C0.100742 1.14646 0.0183548 0.972473 0.00271461 0.783847C-0.0129256 0.595221 0.0394626 0.407412 0.148354 0.261737C0.257246 0.116061 0.413721 0.0244508 0.583357 0.00705973C0.752993 -0.0103313 0.921894 0.047921 1.0529 0.169002L4.49148 3.36476L7.93298 0.159593C7.9986 0.100339 8.07411 0.0560893 8.15516 0.0293878C8.23621 0.00268628 8.3212 -0.00594073 8.40526 0.00400265C8.48932 0.013946 8.57078 0.0422633 8.64497 0.0873277C8.71915 0.132392 8.78459 0.193315 8.83753 0.266594C8.89628 0.339941 8.94078 0.425988 8.96823 0.519346C8.99568 0.612703 9.0055 0.711358 8.99707 0.809131C8.98864 0.906904 8.96214 1.00169 8.91923 1.08754C8.87633 1.1734 8.81793 1.24847 8.74772 1.30807L4.89564 4.87704C4.7769 4.96658 4.6346 5.00928 4.49148 4.99831Z"
            fill="#555555"
          />
        </svg>
      }
      prefixIcon={
        <svg width="9" height="12" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.58678 4.51986V0.943744C4.7122 -0.109671 6.006 2.00117 8.13915 0.943744V4.51986C6.00578 5.57729 4.71207 3.46636 2.58678 4.51986ZM3.85499 10.6334C3.85499 10.4776 3.79311 10.3281 3.6829 10.218C3.57269 10.1078 3.42328 10.0459 3.26746 10.0459H2.21039V1.05359C2.35494 0.970148 2.4551 0.827034 2.48416 0.662706C2.51314 0.498286 2.46788 0.329511 2.36057 0.201667C2.25334 0.0738134 2.09498 0 1.92801 0C1.76112 0 1.60276 0.0738134 1.49553 0.201667C1.38822 0.32952 1.34296 0.498291 1.37194 0.662706C1.40092 0.827038 1.50116 0.970153 1.64572 1.05359V10.0457L0.587612 10.0458C0.431717 10.0458 0.282303 10.1077 0.17209 10.2179C0.0619651 10.328 0 10.4775 0 10.6333V11H3.85498L3.85499 10.6334Z"
            fill="#666666"
          />
        </svg>
      }
      showSearch
      style={{
        width: 160,
        fontSize: '14px',
        cursor: 'pointer',
      }}
      dropdownStyle={{ fontSize: '14px', color: '#525252' }}
      className="country-filter"
      placeholder="Country"
      defaultValue={selectedCountry}
      value={selectedCountry}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      options={defaultOption.concat(countryOptions)}
      onChange={handleChangeCountry}
    />
  );
};

const LegalizationRequestsDashboard = ({ employeeId }) => {
  const dispatch = useDispatch();

  const paramsFromLocalStorage = JSON.parse(sessionStorage.getItem(`LegalizationRequestDashboard`));
  const [selectedCountry, setSelectedCountry] = useState(paramsFromLocalStorage?.country || { value: 0, label: 'All' });
  const { legalizationRequestsData, legalizationRequestsColumns, count, page } = useSelector(
    (state) => state.legalizationRequestListReducer,
  );

  const defaultResponsibleFilterOption = [{ text: 'Not assigned', value: 'Not provided' }];
  const gmgResponsible = useSelector((state) => state.gmgResponsible.gmgResponsible);

  const responsibleOptions =
    gmgResponsible?.map((responsible) => {
      return {
        text: responsible.generalInfo.fullName,
        label: responsible.generalInfo.fullName,
        value: responsible.uuid,
      };
    }) || [];

  const responsibleFiltersOption = defaultResponsibleFilterOption.concat(responsibleOptions);

  const onEditLegalizationRequest = (legalizationRequestId, data) => {
    dispatch(editLegalization(legalizationRequestId, data)).then(() => {
      const [ordering, filtering, searchParams, toggleParams] = getParams({
        tableKey: 'LegalizationRequestDashboard',
        columns: columns,
      });

      dispatch(
        getLegalizationRequests(page, filtering, {
          ordering: ordering,
          search: searchParams,
          toggle: toggleParams,
        }),
      );
    });
  };

  const onEditLegalizationRequestResponsible = (legalizationRequestId, value) => {
    dispatch(editLegalizationResponsible(legalizationRequestId, { responsibleId: value })).then(() => {
      const [ordering, filtering, searchParams, toggleParams] = getParams({
        tableKey: 'LegalizationRequestDashboard',
        columns: columns,
      });
      dispatch(
        getLegalizationRequests(page, filtering, {
          ordering: ordering,
          search: searchParams,
          toggle: toggleParams,
        }),
      );
    });
  };

  const columns = legalizationRequestsColumns
    ? [
        ...getDefaultColumns(selectedCountry),
        ...legalizationRequestsColumns.map((item) => {
          const fieldLabelTruncationIndex = item?.field?.label && item?.field?.label.indexOf(':');
          let columnLabel = fieldLabelTruncationIndex
            ? item.field.label.slice(0, fieldLabelTruncationIndex)
            : item?.field?.label;

          return {
            key: `${item?.field?.fieldName}_${item.formId}`,
            title: columnLabel,
            label: columnLabel,
            dataIndex: `${item?.field?.fieldName}_${item.formId}`,
            filters:
              item.field &&
              item.field.options &&
              item.field.options.length > 0 &&
              item.field.options.map((item2) => {
                return {
                  text: item2.text,
                  value: item2.value,
                };
              }),
            filteredValue: null,
            filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
          };
        }),
      ]
    : [];

  const formsTypeMapping = {
    employee: 'Only employee',
    family: 'Only family',
    employee_and_family: 'With family',
  };

  const data = legalizationRequestsData
    ? legalizationRequestsData.map((item) => {
        return Object.assign(
          {
            employee: (
              <Link to={`profile/${item?.employee?.uuid}/legalization-request`}>
                {item?.employee?.generalInfo?.fullName}
              </Link>
            ),
            status: (
              <ContentWrapper dataLabel={'Status'}>
                <TableDropdown
                  value={{
                    value: item.status || 0,
                    label: item.status || '',
                  }}
                  options={[
                    { label: 'New', value: 'New' },
                    { label: 'In progress', value: 'In progress' },
                    { label: 'Waiting for passport translation', value: 'Waiting for passport translation' },
                    {
                      label: 'Verification of the notarized translation of the passport',
                      value: 'Verification of the notarized translation of the passport',
                    },
                    { label: 'Documents preparation', value: 'Documents preparation' },
                    {
                      label: 'An electronic version was sent to the employee',
                      value: 'An electronic version was sent to the employee',
                    },
                    { label: 'Documents are ready to pick up', value: 'Documents are ready to pick up' },
                    { label: 'Documents were sent by post', value: 'Documents were sent by post' },
                    { label: 'Documents received by the employee', value: 'Documents received by the employee' },
                  ]}
                  onChange={(option) => onEditLegalizationRequest(item.id, option && { status: option.value })}
                  customStyles={tableDropdownCustomStyles}
                  // disabled={isRowDisabled}
                  // isClearable={true}
                />
              </ContentWrapper>
            ),
            responsible: (
              <ContentWrapper dataLabel={'Responsible GM'}>
                <TableDropdown
                  value={{
                    value: item.responsible?.uuid || 0,
                    label: item.responsible?.generalInfo?.fullName || '',
                  }}
                  options={responsibleOptions}
                  onChange={(option) => onEditLegalizationRequestResponsible(item.id, option && option.value)}
                  customStyles={tableDropdownCustomStyles}
                  // disabled={isRowDisabled}
                  // isClearable={true}
                />
              </ContentWrapper>
            ),
            viewDetails: (
              <div className={'view-details'}>
                <Link to={`/legalization-request/${item.id}`} style={{ textAlign: 'end' }}>
                  View details
                </Link>
              </div>
            ),
            country: <ContentWrapper dataLabel={'Country'}>{item.country?.name}</ContentWrapper>,
            type: <ContentWrapper dataLabel={'Type'}>{formsTypeMapping[item.formsType]}</ContentWrapper>,
            deadline: <ContentWrapper dataLabel={'Country'}>{item.deadline}</ContentWrapper>,
            comments: <ContentWrapper dataLabel={'Country'}>{item.comments}</ContentWrapper>,
          },
          ...item.forms.map((item2) => {
            const { fields, values } = item2.forms[0];
            return Object.assign(
              {},
              ...fields.map((field) => {
                return {
                  [`${field?.fieldName}_${item2.id}`]: getValuesByElement(
                    field,
                    values[field.fieldName],
                    values[field.fieldName + 'DateExtraFields'],
                  ),
                };
              }),
            );
          }),
        );
      })
    : [];

  const columnsKeys = columns.map((item) => item.key);
  const [visibleColumns, setVisibleColumns] = useState(columnsKeys || []);

  useEffect(() => {
    const selectedCountryId = selectedCountry?.value;
    const columnsFilterFromStorage = paramsFromLocalStorage?.columnsFilter;

    if (columnsFilterFromStorage && columnsFilterFromStorage.hasOwnProperty(selectedCountryId)) {
      setVisibleColumns(columnsFilterFromStorage[selectedCountryId] || columnsKeys);
      return;
    }
    setVisibleColumns(columns);
  }, [selectedCountry]);

  useEffect(() => {
    if (!gmgResponsible?.length) {
      dispatch(getResponsibleGMG());
    }
  }, [dispatch]);

  const [isClearFilterParams, setClearFilterParams] = useState(false);

  const clearAllInnerFilters = () => {
    setClearFilterParams(false);
    setSearchName('');
    setSelectedCountry({ value: 0, label: 'All' });
  };

  columns.forEach((item) => {
    if (item.key === 'responsible') {
      item.filters = responsibleFiltersOption;
    }
  });

  // const filteredColumns = columns.filter(
  //   (column) => (columnsKeys.includes(column.key) && visibleColumns.includes(column.key)) || column.key === 'fullName',
  // );

  // console.log(columnsKeys, filteredColumns, visibleColumns || []);

  const [searchName, setSearchName] = useState(paramsFromLocalStorage?.search || '');

  return (
    <section className="legalization-documents">
      <div className="legalization-documents__header">
        <h3 className="underlined panel-top-row">Legalization requests</h3>
      </div>

      <div className="additional-filters__container">
        <div className="additional-filters__container-first-group">
          <CountryFilter
            tableKey={'LegalizationRequestDashboard'}
            setSelectedCountry={setSelectedCountry}
            selectedCountry={selectedCountry}
            paramsFromLocalStorage={paramsFromLocalStorage}
          />

          {/*<ColumnsFilter*/}
          {/*  tableKey={'PolandDashboard'}*/}
          {/*  columns={columns.filter((column) => columnsKeys.includes(column.key) && column.key !== 'fullName')}*/}
          {/*  visibleColumns={visibleColumns}*/}
          {/*  setVisibleColumns={setVisibleColumns}*/}
          {/*  selectedCountry={selectedCountry?.value}*/}
          {/*/>*/}
          {/*<ColumnsFilter*/}
          {/*  tableKey={'LegalizationRequestDashboard'}*/}
          {/*  columns={columns}*/}
          {/*  visibleColumns={visibleColumns}*/}
          {/*  setVisibleColumns={setVisibleColumns}*/}
          {/*  selectedCountry={selectedCountry?.value}*/}
          {/*/>*/}
          <ClearFilters setClearFilterParams={setClearFilterParams} />
        </div>
        <SearchName tableKey={'LegalizationRequestDashboard'} searchName={searchName} setSearchName={setSearchName} />
      </div>

      <CustomTable
        tableKey={'LegalizationRequestDashboard'}
        data={data}
        columns={columns}
        count={count}
        additionalFiltering={selectedCountry.value ? { country: selectedCountry.value } : {}}
        sortMapping={[]}
        // dateRangeFilterMapping={[]}
        // multipleFilterMapping={{}}
        searchParams={searchName}
        filterMapping={[]}
        getTableDataCallback={getLegalizationRequests}
        paramsFromLocalStorage={paramsFromLocalStorage}
        clearAllInnerFilters={clearAllInnerFilters}
        isClearFilterParams={isClearFilterParams}
        mobileView={true}
        pageSize={20}
      />
    </section>
  );
};

export default LegalizationRequestsDashboard;
