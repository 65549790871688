import { types } from '../actions/types';

const initialState = {};

const requestPetsProfilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_PETS_INFO_LOADED: {
      const petReqId = action.payload.petReqId;
      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: action.payload.petsInfo,
        },
      };
    }
    case types.PETS_TRANSPORTATION_DETAILS_ADD: {
      const petReqId = action.payload.petReqId;
      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            transportationDetails: [
              ...state[petReqId].petsInfo.transportationDetails,
              action.payload.transportationDetails,
            ],
          },
        },
      };
    }
    case types.PET_TICKET_FORM_DELETE: {
      const petReqId = action.payload.petReqId;
      return {
        ...state,
        [petReqId]: {
          petsInfo: {
            ...state[petReqId].petsInfo,
            transportationDetails: state[petReqId].petsInfo.transportationDetails.filter(
              (item) => +item.id !== +action.payload.tdId,
            ),
          },
        },
      };
    }
    case types.PETS_TRANSPORTATION_DETAILS_UPDATE: {
      const petReqId = action.payload.petReqId;
      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            transportationDetails: state[petReqId].petsInfo.transportationDetails.map((item) => {
              if (item.id === action.payload.transportationDetails.id) {
                item = action.payload.transportationDetails;
              }
              return item;
            }),
          },
        },
      };
    }
    case types.PETS_DOCUMENT_UPDATE: {
      const petReqId = action.payload.petReqId;

      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            documents: state[petReqId].petsInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form) => {
                  if (form.formId === action.payload.document.formId) {
                    form.isCurrent = action.payload.document.isCurrent;
                    form.values = action.payload.document.values;
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.PETS_DOCUMENT_ADD: {
      const petReqId = action.payload.petReqId;

      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            documents: state[petReqId].petsInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms = [...item.forms, action.payload.document];
                item.forms.forEach((form, index) => {
                  if (form.formId === null) {
                    item.forms.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.PET_DOCUMENT_FORM_DELETE: {
      const petReqId = action.payload.petReqId;

      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            documents: state[petReqId].petsInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                if (item.forms.length > 1) {
                  return {
                    ...item,
                    forms: item.forms.filter((form) => form.formId !== action.payload.formId),
                  };
                } else {
                  return {
                    ...item,
                    forms: item.forms.map((form, index) => {
                      if (form.formId === action.payload.formId) {
                        for (let key in item.forms[index].values) {
                          item.forms[index].values[key] = '';
                        }
                        return { ...form, formId: null, attachments: [] };
                      } else return form;
                    }),
                  };
                }
              } else return item;
            }),
          },
        },
      };
    }
    case types.PET_DOCUMENT_ATTACHMENT_ADD: {
      const petReqId = action.payload.petReqId;

      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            documents: state[petReqId].petsInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments = [...form.attachments, action.payload.attachments];
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.PET_DOCUMENT_ATTACHMENT_DELETE: {
      const petReqId = action.payload.petReqId;

      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            documents: state[petReqId].petsInfo.documents.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.formId) {
                    form.attachments.forEach((attachment, index) => {
                      if (+attachment.id === +action.payload.attachmentId) {
                        form.attachments.splice(index, 1);
                      }
                    });
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.PET_TICKET_ATTACHMENT_ADD: {
      const petReqId = action.payload.petReqId;
      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            transportationDetails: state[petReqId].petsInfo.transportationDetails.map((item) => {
              if (item.id === +action.payload.tdId) {
                item.attachments = [...item.attachments, action.payload.attachments];
              }
              return item;
            }),
          },
        },
      };
    }
    case types.PET_TICKET_ATTACHMENT_DELETE: {
      const petReqId = action.payload.petReqId;
      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            transportationDetails: state[petReqId].petsInfo.transportationDetails.map((item) => {
              if (item.id === +action.payload.tdId) {
                item.attachments.forEach((attachment, index) => {
                  if (+attachment.id === +action.payload.attachmentId) {
                    item.attachments.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.PETS_GENERAL_INFO_UPDATE: {
      const petReqId = action.payload.petReqId;
      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            pet: action.payload.petsInfo,
          },
        },
      };
    }
    case types.GENERAL_PET_PROFILE_NOTES_ADD: {
      const petReqId = action.payload.petReqId;
      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            notes: [...state[petReqId].petsInfo.notes, action.payload.note],
          },
        },
      };
    }
    case types.GENERAL_PET_PROFILE_NOTES_UPDATE: {
      const petReqId = action.payload.petReqId;
      return {
        ...state,
        [petReqId]: {
          ...state[petReqId],
          petsInfo: {
            ...state[petReqId].petsInfo,
            notes: state[petReqId].petsInfo.notes.map((note) => {
              if (+note.id === +action.payload.note.id) {
                return action.payload.note;
              } else {
                return note;
              }
            }),
          },
        },
      };
    }
    case types.RELATED_PET_DOC_REQUEST_ADD: {
      const relativeReqId = action.payload.petRequest.id;
      return {
        ...state,
        [relativeReqId]: {
          ...state[relativeReqId],
          petsInfo: { documents: action.payload.petRequest.documents, pet: action.payload.petRequest.pet },
        },
      };
    }
    case types.EXISTING_RELATED_DOC_ADD: {
      const petsDoduments = action.payload.petsDocs.reduce((accumulator, value) => {
        return {
          ...accumulator,
          [value.id]: { petsInfo: { documents: value.documents, pet: value.pet, forms: value.forms } },
        };
      }, {});

      return {
        ...petsDoduments,
      };
    }
    case types.DELETE_PET_PROFILE: {
      const requestPetId = action.payload.requestPetId;
      delete state[requestPetId];
      return {
        ...state,
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default requestPetsProfilesReducer;
