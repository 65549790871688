import React, { useContext, useEffect, useState } from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  addVacAppointment,
  addVacAppointmentNotes,
  addVacAppointments,
  editNotes,
  editVacAppointment,
  editVacAppointments,
  sendVACdetails,
  upsertVacAppointments,
} from '../../../store/actions/requestDetails';
import {
  getAddressInfoOptions,
  getValuesToUpdate,
  parseVacAppointment,
  setAllValuesToNull,
  setIsCurrentVacAppointment,
} from '../../../helpers';
import { getVacAppointmentAddresses } from '../../../store/actions/requestVacAppointment';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import VacField from './VacField/VacField';
import {
  CheckboxesSchema,
  formSchema,
  vacAppointmentInitialValues,
  validationSchema,
  visaAppealSchema,
  visaNotesSchema,
  visaStatusSchema,
} from './schemaProperties';

import { IsCaseDisabledContext } from '../RequestDetails';

import './foreignPoliceAppointment.scss';
import ForeignPoliceField from './VacField/VacField';
import actionCreators from '../../../store/actions/actionCreators';
import axios from '../../../axios';

export const addForeignPoliceAppointmentx = async (id, info) => {
  try {
    const response = await axios.post(`/api/v1/relocation-requests/${id}/foreign-police-appointments/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addForeignPoliceAppointment = (vacAppointments, requestId) => {
  return (dispatch) => {
    const vacAppointmentsPromise = vacAppointments.map((vacInfo) => {
      return addForeignPoliceAppointmentx(requestId, {
        ...vacInfo,
      });
    });
    return Promise.all(vacAppointmentsPromise)
      .then((responses) => {
        responses.forEach((vacInfo) => {
          dispatch(actionCreators.foreignPoliceAppointmentAdd(vacInfo, requestId));
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const editForeignPoliceAppointmentx = async (id, info) => {
  try {
    const response = await axios.put(`/api/v1/relocation-requests/foreign-police-appointment/${id}/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editForeignPoliceAppointment = (vacAppointments, relatedReqId, relatedFlag) => {
  return (dispatch) => {
    const vacAppointmentsPromise = vacAppointments.map((vacInfo) => {
      return editForeignPoliceAppointmentx(vacInfo.id, {
        ...vacInfo,
      }).catch((error) => {
        throw error;
      });
    });
    return Promise.all(vacAppointmentsPromise)
      .then((responses) => {
        responses.forEach((vacInfo) => {
          dispatch(actionCreators.foreignPoliceAppointmentUpdate({ ...vacInfo, isSubmitting: true }, relatedReqId));
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const upsertForeignPoliceAppointment = (valuesToUpdate, valuesToAdd, vacAppointmentIsNotCurrent, requestId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    let promises = [];
    let vacAppointmentIsNotCurrentPromises, vacAppointmentsToUpdatePromises, vacAppointmentsToAddPromises;
    const relatedFlag = false;

    if (valuesToUpdate.length) {
      vacAppointmentIsNotCurrentPromises = dispatch(
        editForeignPoliceAppointment(vacAppointmentIsNotCurrent, requestId, relatedFlag),
      )
        .then(async () => {
          vacAppointmentsToUpdatePromises = await dispatch(
            editForeignPoliceAppointment(valuesToUpdate, requestId, relatedFlag),
          );
        })
        .then(async () => {
          if (valuesToAdd.length) {
            vacAppointmentsToAddPromises = await dispatch(addForeignPoliceAppointment(valuesToAdd, requestId));
          }
        });
    } else {
      if (valuesToAdd.length) {
        vacAppointmentsToAddPromises = dispatch(addForeignPoliceAppointment(valuesToAdd, requestId));
      }
    }

    if (vacAppointmentIsNotCurrentPromises) {
      promises.push(vacAppointmentIsNotCurrentPromises);
    }
    if (vacAppointmentsToUpdatePromises) {
      promises.push(vacAppointmentsToUpdatePromises);
    }
    if (vacAppointmentsToAddPromises) {
      promises.push(vacAppointmentsToAddPromises);
    }

    return Promise.all(promises)
      .then((responses) => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const sendForeignPoliceDetails = (vacId, requestId) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(
      `/api/v1/relocation-requests/foreign-police-appointment/${vacId}/notification/employee/`,
    );
    dispatch(actionCreators.foreignPoliceIsNotified(requestId));
    return response.data;
  } catch (error) {
    // let errorMessage = '';
    // if (error.response?.data) {
    //   errorMessage = parse(error.response?.data);
    // }
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
    throw error;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const getForeignPoliceAddresses = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/relocation-requests/foreign-police-appointment/addresses/?limit=999');
    dispatch(actionCreators.foreignPoliceAddressesLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const ForeignPoliceAppointment = ({ requestId, vacInfo: vacAppointment }) => {
  const dispatch = useDispatch();

  const addressesInfo = useSelector((state) => state.vacAppointmentReducer.foreignPoliceAddressesInfo);

  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const currentVAC = vacAppointment.find((item) => item.isCurrent === true);
  const isEmployeeNotifiedForCurrentVAC = currentVAC ? currentVAC.isEmployeeNotified?.value : true;

  const vacInfo = parseVacAppointment(vacAppointment);
  const initialValues = {
    vacInfo: vacAppointment.length ? vacInfo : [vacAppointmentInitialValues],
  };

  vacInfo.forEach((item) => {
    if (!item.appeal) {
      item.appeal = {
        submissionDate: null,
        decisionDate: null,
        decision: null,
      };
    }
    item.time = {
      startTime: item?.mainTime || null,
      endTime: item?.possibleTime || null,
    };
  });

  const [addressInfoOptions, setAddressInfoOptions] = useState(getAddressInfoOptions(addressesInfo));

  const cityOptions = [
    ...new Map(addressesInfo.map((item) => [item.city?.id, { key: item.city?.id, value: item.city?.name }])).values(),
  ];

  useEffect(() => {
    setAddressInfoOptions(getAddressInfoOptions(addressesInfo));
  }, [addressesInfo]);

  useEffect(() => {
    if (!addressesInfo?.length) {
      dispatch(getForeignPoliceAddresses());
    }
  }, [dispatch, addressesInfo?.length]);

  const onChangeSelectedAddress = (addressInfoId, formikProps, formIndex) => {
    const selectedCity = addressesInfo.find((item) => item.id === addressInfoId).city?.name;

    formikProps.setFieldValue(`vacInfo.${formIndex}.addressInfo.city.name`, selectedCity);
  };

  formSchema.forEach((item) => {
    if (item.fieldName === 'addressInfo.id') {
      item.options = addressInfoOptions;
      item.updateselectvalueroot = onChangeSelectedAddress;
    }
    if (item.fieldName === 'addressInfo.city.name') {
      item.options = cityOptions;
    }
    if (item.fieldName === 'isCurrent') {
      item.updateselectvalueroot = setIsCurrentVacAppointment;
    }
  });

  const handleSendVACDetails = () => {
    dispatch(sendForeignPoliceDetails(currentVAC.id, requestId));
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    values.vacInfo.map((vacInfo, formIndex) => {
      vacInfo.addressInfoId = vacInfo.addressInfo?.id;

      if (!vacInfo.id || !vacInfo.appeal?.submissionDate) {
        delete vacInfo.appeal;
      }

      vacInfo.mainTime = vacInfo.time.startTime;
      vacInfo.possibleTime = vacInfo.time.endTime;

      // delete vacInfo.time;
    });

    const valuesToUpdate = getValuesToUpdate(values.vacInfo, vacAppointment, Object.keys(vacAppointmentInitialValues));

    const vacAppointmentIsNotCurrent = getValuesToUpdate(values.vacInfo, vacAppointment, ['isCurrent']).filter(
      (item) => item.isCurrent === false,
    );

    const valuesToAdd = values?.vacInfo.filter(({ id }) => isNaN(id));

    const valuesToUpdateWithNullValues = setAllValuesToNull(valuesToUpdate);
    const vacAppointmentIsNotCurrentWithNullValues = setAllValuesToNull(vacAppointmentIsNotCurrent);
    const valuesToAddWithNullValues = setAllValuesToNull(valuesToAdd);

    if (
      valuesToUpdateWithNullValues.length ||
      valuesToAddWithNullValues.length ||
      vacAppointmentIsNotCurrentWithNullValues.length
    ) {
      dispatch(
        upsertForeignPoliceAppointment(
          valuesToUpdateWithNullValues,
          valuesToAddWithNullValues,
          vacAppointmentIsNotCurrentWithNullValues,
          requestId,
        ),
      ).finally(() => {
        setSubmitting(false);
      });
    }
  };

  return (
    <div className="VacAppointment">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps) => {
          return (
            <Form>
              <FieldArray
                name="vacInfo"
                render={({ remove, push }) => (
                  <>
                    <ForeignPoliceField
                      values={formikProps.values}
                      formSchema={formSchema}
                      checkboxesSchema={CheckboxesSchema}
                      formikProps={formikProps}
                      requestId={requestId}
                      remove={remove}
                    />
                    <div className="button-wrapper">
                      <SubmitButton title="Submit" formikProps={formikProps} />
                      <button
                        type="button"
                        className="button btn-form main-btn"
                        onClick={() => {
                          push(vacAppointmentInitialValues);
                          setIsCurrentVacAppointment(true, null, formikProps);
                        }}
                      >
                        Add new
                      </button>
                      {vacInfo.length > 0 && (
                        <button
                          type="button"
                          className={classNames(
                            'button btn-form main-btn btn-back',
                            isCaseDisabled || isEmployeeNotifiedForCurrentVAC ? 'btn-disabled' : '',
                          )}
                          onClick={() => handleSendVACDetails()}
                          disabled={isCaseDisabled || isEmployeeNotifiedForCurrentVAC}
                        >
                          Send details to Employee
                        </button>
                      )}
                    </div>
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ForeignPoliceAppointment;
