import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

import { getCountriesWithCompanies } from '../../../store/actions/countriesForRelocation';
import './countryFilter.scss';

const defaultOption = [{ value: 0, label: 'All' }];

const CustomSelect = ({ prefixIcon, children, ...rest }) => {
  return (
    <div style={{ position: 'relative' }}>
      {prefixIcon && <div className="prefix-icon-wrapper">{prefixIcon}</div>}
      <Select {...rest}>{children}</Select>
    </div>
  );
};

const CountryFilter = ({ tableKey, setSelectedCountry, selectedCountry, paramsFromLocalStorage }) => {
  const dispatch = useDispatch();
  const countriesInfo = useSelector((state) => state.countries.countriesWithCompanies);

  useEffect(() => {
    if (!countriesInfo?.length) {
      dispatch(
        getCountriesWithCompanies({
          countries_with_companies: true,
        }),
      );
    }
  }, [dispatch, countriesInfo?.length]);

  const countryOptions =
    countriesInfo?.map((country) => ({
      value: country.id,
      label: country.name,
    })) || [];

  const handleChangeCountry = (value, option) => {
    setSelectedCountry(option);

    sessionStorage.setItem(
      tableKey,
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem(tableKey)),
        additionalFiltering: { ...paramsFromLocalStorage?.additionalFiltering, country: option.label },
        country: option,
      }),
    );
  };

  return (
    <CustomSelect
      suffixIcon={
        <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.49148 4.99831C4.34159 4.99864 4.19632 4.94059 4.0809 4.83424L0.231752 1.26755C0.100742 1.14646 0.0183548 0.972473 0.00271461 0.783847C-0.0129256 0.595221 0.0394626 0.407412 0.148354 0.261737C0.257246 0.116061 0.413721 0.0244508 0.583357 0.00705973C0.752993 -0.0103313 0.921894 0.047921 1.0529 0.169002L4.49148 3.36476L7.93298 0.159593C7.9986 0.100339 8.07411 0.0560893 8.15516 0.0293878C8.23621 0.00268628 8.3212 -0.00594073 8.40526 0.00400265C8.48932 0.013946 8.57078 0.0422633 8.64497 0.0873277C8.71915 0.132392 8.78459 0.193315 8.83753 0.266594C8.89628 0.339941 8.94078 0.425988 8.96823 0.519346C8.99568 0.612703 9.0055 0.711358 8.99707 0.809131C8.98864 0.906904 8.96214 1.00169 8.91923 1.08754C8.87633 1.1734 8.81793 1.24847 8.74772 1.30807L4.89564 4.87704C4.7769 4.96658 4.6346 5.00928 4.49148 4.99831Z"
            fill="#555555"
          />
        </svg>
      }
      prefixIcon={
        <svg width="9" height="12" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.58678 4.51986V0.943744C4.7122 -0.109671 6.006 2.00117 8.13915 0.943744V4.51986C6.00578 5.57729 4.71207 3.46636 2.58678 4.51986ZM3.85499 10.6334C3.85499 10.4776 3.79311 10.3281 3.6829 10.218C3.57269 10.1078 3.42328 10.0459 3.26746 10.0459H2.21039V1.05359C2.35494 0.970148 2.4551 0.827034 2.48416 0.662706C2.51314 0.498286 2.46788 0.329511 2.36057 0.201667C2.25334 0.0738134 2.09498 0 1.92801 0C1.76112 0 1.60276 0.0738134 1.49553 0.201667C1.38822 0.32952 1.34296 0.498291 1.37194 0.662706C1.40092 0.827038 1.50116 0.970153 1.64572 1.05359V10.0457L0.587612 10.0458C0.431717 10.0458 0.282303 10.1077 0.17209 10.2179C0.0619651 10.328 0 10.4775 0 10.6333V11H3.85498L3.85499 10.6334Z"
            fill="#666666"
          />
        </svg>
      }
      showSearch
      style={{
        width: 160,
        fontSize: '14px',
        cursor: 'pointer',
      }}
      dropdownStyle={{ fontSize: '14px', color: '#525252' }}
      className="country-filter"
      placeholder="Country"
      defaultValue={selectedCountry}
      value={selectedCountry}
      optionFilterProp="children"
      filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
      options={defaultOption.concat(countryOptions)}
      onChange={handleChangeCountry}
    />
  );
};

export default CountryFilter;
