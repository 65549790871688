import './checkbox.scss';

export const TableCheckbox = ({ value, onChange, disabled }) => {
  return (
    <button
      className="custom-control custom-checkbox table-component__custom-checkbox"
      onClick={onChange}
      disabled={disabled}
    >
      <input className="custom-control-input" type="checkbox" checked={value} disabled={disabled} onChange={onChange} />
      <label className="custom-checkbox__label">
        <span></span>
      </label>
    </button>
  );
};
