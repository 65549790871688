import { useContext } from 'react';
import Select from 'react-select';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';

import { customStyles } from '../../Case/CaseList/CaseItem/caseItemCustomStyles';
import { editRelocationRequest } from '../../../store/actions/request';
import { IsCaseDisabledContext, IsDismissedCaseContext } from '../RequestDetails';
import { getStatusOptions } from './statusOptions';

import './status.scss';

const Status = ({ requestId, status, isCaseDashboardDisabled, isCaseDashboardDismissed, caseType }) => {
  const dispatch = useDispatch();
  const parsedStatus = (status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()).replace(/_/g, ' ');

  const isCaseDisabled = useContext(IsCaseDisabledContext);
  const isDismissedCase = useContext(IsDismissedCaseContext) || isCaseDashboardDismissed;

  const statusOptions = getStatusOptions(caseType, isDismissedCase);

  const onResponsibleChanged = (option) => {
    dispatch(editRelocationRequest(requestId, { status: option }));
  };

  return (
    <div className="status-container">
      <strong className="additional-top__label status-container__title">Status:</strong>
      <Select
        value={status ? { label: parsedStatus, value: parsedStatus } : ''}
        placeholder="-select-"
        options={statusOptions}
        styles={customStyles}
        onChange={(option) => onResponsibleChanged(option.value)}
        className={classNames(
          'status-container__select',
          (isCaseDashboardDisabled || isCaseDisabled) && !isDismissedCase ? 'status-container__disabled' : '',
        )}
        isDisabled={(isCaseDashboardDisabled || isCaseDisabled) && !isDismissedCase}
      />
    </div>
  );
};

export default Status;
