import React from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { customStyles } from '../../../../Case/CaseList/CaseItem/caseItemCustomStyles';
import CustomAccordion from '../../../CustomAccordion/CustomAccordion';
import { changeDocumentsStatus } from '../../../../../store/actions/employeeProfile';
import actionCreators from '../../../../../store/actions/actionCreators';

import './documentsStatus.scss';
import { Form, Formik } from 'formik';
import { getFormElement } from '../../../../Shared/Form/FormElements';
import { SubmitButton } from '../../../../Shared/Form/Buttons/Buttons';
import { validationSchema } from '../../../../Case/CaseList/AddNewCase/AddNewCity/AddNewCity';
import { editRelocationRequest } from '../../../../../store/actions/request';

const DocumentsStatus = ({ employeeId, documentsStatus, documentsStatusMessage, verificationEndAt, verifiedForms }) => {
  const dispatch = useDispatch();

  const onSubmit = (values) => {
    dispatch(changeDocumentsStatus(employeeId, values)).then((responseData) => {
      responseData && dispatch(actionCreators.employeeProfileUpdate({ documentsStatus: responseData }, employeeId));
    });
  };

  const formSchema = [
    {
      fieldName: 'status',
      type: 'Dropdown',
      label: 'Status',
      options: [
        { label: 'Documents verified', value: 'Documents verified' },
        { label: 'Attention needed', value: 'Attention needed' },
      ],
    },
    {
      fieldName: 'verificationEndAt',
      type: 'DatePicker',
      label: 'Attention will be needed after',
    },
  ];

  return (
    <CustomAccordion
      id={employeeId}
      titleName={'Documents status'}
      type={'documentsStatus'}
      isImportant={documentsStatus === 'Attention needed'}
    >
      <div style={{ marginBottom: '30px' }}>
        <div className={'form-group'}>
          <Formik
            enableReinitialize
            initialValues={{
              status: documentsStatus,
              verificationEndAt: verificationEndAt,
            }}
            onSubmit={onSubmit}
            // validationSchema={validationSchema}
          >
            {(formikProps) => (
              <Form>
                {formSchema.map((field) => (
                  <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                ))}
                {documentsStatusMessage && (
                  <div style={{ maxWidth: '500px' }}>
                    <div style={{ marginBottom: '1rem' }}>
                      <span style={{ fontWeight: 500 }}>Reason:</span> {documentsStatusMessage}
                    </div>
                    {verifiedForms && (
                      <div style={{ marginBottom: '1rem' }}>
                        <span style={{ fontWeight: 500 }}>Verified forms by GM:</span> {verifiedForms}
                      </div>
                    )}
                  </div>
                )}
                <div>
                  <SubmitButton title="Submit" formikProps={formikProps} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </CustomAccordion>
  );
};

export default DocumentsStatus;
