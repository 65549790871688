import { useMemo } from 'react';
import { Tree } from 'antd';

import './checkboxTree.scss';

const CheckboxTree = ({ options, selectedKeys, setSelectedKeys }) => {
  const selectedItems = selectedKeys[0]?.multipleFilter || [];

  const excludeParents = useMemo(() => {
    return options.filter((item) => item.hasChildren).map((item) => item.key);
  }, []);

  const onCheck = (checkedKeys, info) => {
    setSelectedKeys([
      { ...selectedKeys[0], multipleFilter: checkedKeys.filter((item) => !excludeParents.includes(item)) },
    ]);
  };

  return (
    <div className="multiple-filter__checkbox-tree">
      <Tree checkable checkedKeys={selectedItems} onCheck={onCheck} treeData={options} selectable={false} />
    </div>
  );
};

export default CheckboxTree;
