import { useState } from 'react';
import classNames from 'classnames';

import ArrowIcon from '../../../assets/img/icons/arrow.svg';

import './customAccordion.scss';

const CustomAccordion = (props) => {
  const { initialState, id, targetRef, titleName, type, isImportant } = props;

  const checkboxState = JSON.parse(localStorage.getItem(`${type}Accordion`)) || {};

  const [isActive, setIsActive] = useState(id ? checkboxState[id] : initialState || false);

  const handleClick = () => {
    setIsActive(!isActive);
  };

  checkboxState[id] = isActive;

  localStorage.setItem(`${type}Accordion`, JSON.stringify(checkboxState));

  return (
    <>
      <div className="custom-accordion__title-container" onClick={handleClick}>
        {targetRef ? (
          <h4 className="custom-accordion__header" id={id} ref={targetRef}>
            {titleName}{' '}
            {isImportant && (
              <div
                style={{
                  backgroundColor: '#EB3D26',
                  borderRadius: '50%',
                  width: '8px',
                  height: '8px',
                  marginLeft: '2px',
                }}
              ></div>
            )}
          </h4>
        ) : (
          <h4 className="custom-accordion__header" id={id}>
            {titleName}{' '}
            {isImportant && (
              <div
                style={{
                  backgroundColor: '#EB3D26',
                  borderRadius: '50%',
                  width: '7px',
                  height: '7px',
                  marginLeft: '2px',
                }}
              ></div>
            )}
          </h4>
        )}
        <img
          className={classNames('custom-accordion__icon', { accordionClose: !isActive, accordionOpen: isActive })}
          src={ArrowIcon}
          alt="arrow"
        />
      </div>
      {isActive && props.children}
    </>
  );
};

export default CustomAccordion;
