export const employeeFormSchema = [
  {
    fieldName: 'company',
    type: 'TextareaAsInput',
    label: 'Company name',
    disabled: true,
  },
  {
    fieldName: 'employmentDate',
    type: 'DatePicker',
    label: 'Employment date',
    disabled: true,
  },
  {
    fieldName: 'dismissDate',
    type: 'DatePicker',
    label: 'Dismissal date',
    disabled: true,
  },
];

export const candidateFormSchema = [
  {
    fieldName: 'company',
    type: 'TextareaAsInput',
    label: 'Company name',
    disabled: true,
  },
  {
    fieldName: 'employmentDate',
    type: 'DatePicker',
    label: 'Planned employment date',
  },
];

export const editFormSchema = [
  {
    fieldName: 'companyId',
    type: 'Dropdown',
    label: 'Company',
    required: true,
  },
  {
    fieldName: 'employmentDate',
    type: 'DatePicker',
    label: 'Employment date',
    required: true,
  },
];
