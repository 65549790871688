import { types } from '../actions/types';

const initialState = {
  gmgResponsible: null,
};

const gmgResponsibleReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RESPONSIBLE_GMG_LOAD:
      return {
        gmgResponsible: action.payload.gmgResponsible,
      };

    default:
      return {
        ...state,
      };
  }
};

export default gmgResponsibleReducer;
