import { useEffect, createContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';

import { getRequestDetails, getResponsibleGMG } from '../../store/actions/requestDetails';
import QuickNavUp from '../Shared/QuickButtonsBlock/QuickNavUp/QuickNavUp';
import MainInfoTop from './MainInfoTop/MainInfoTop';
import ContentRoutes from './ContentRoutes/ContentRoutes';
import SpinnerContainer from '../Common/SpinnerContainer/SpinnerContainer';
import SiderMenu from './SiderMenu/SiderMenu';
import { getIsCaseDisabled, getIsDismissedCase } from '../../helpers';
import { defaultDateFormat } from '../../constants';

import './RequestDetails.scss';

export const IsCaseDisabledContext = createContext(null);
export const IsDismissedCaseContext = createContext(null);

const RequestDetails = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const requestId = match.params.id;
  const request = useSelector((state) => state.requestDetails[requestId]?.requestDetails);

  const employeeId = request && request.employee.uuid;
  const employee = useSelector((state) => state.employeeProfileReducer[employeeId]?.employeeProfile);

  const gmgResponsible = useSelector((state) => state.gmgResponsible.gmgResponsible);

  const [isLoading, setLoading] = useState(!request || request?.id !== +requestId);

  const genInfo = employee?.generalInfo;

  useEffect(() => {
    dispatch(getRequestDetails(requestId)).then((response) => {
      if (!Number.isInteger(+requestId) && requestId) {
        history.push(`/request-details/${response?.id}`);
      }
      setLoading(false);
    });
  }, [dispatch, requestId]);

  useEffect(() => {
    if (!gmgResponsible?.length) {
      dispatch(getResponsibleGMG());
    }
  }, [dispatch]);

  useEffect(() => {
    if (!request || request?.id !== +requestId) {
      setLoading(true);
    }
  }, [requestId]);

  const isCaseDisabled =
    request &&
    employee &&
    getIsCaseDisabled({
      currentEmploymentInfo: employee?.currentEmploymentInfo,
      isActive: employee?.isActive,
      status: request.status,
      isCreatedByEmployee: request.isCreatedByEmployee,
    });

  const isDismissedCase = getIsDismissedCase({
    isActive: employee?.isActive,
    currentEmploymentInfo: employee?.currentEmploymentInfo,
  });

  return (
    <IsCaseDisabledContext.Provider value={isCaseDisabled}>
      <IsDismissedCaseContext.Provider value={isDismissedCase}>
        <section className="RequestDetails">
          {!isLoading && request && employee && (
            <>
              <MainInfoTop request={request} genInfo={genInfo} employee={employee} />

              <div className="request-details__container">
                <SiderMenu employee={employee} request={request} />
                <ContentRoutes
                  request={request}
                  employee={employee}
                  requestId={requestId}
                  isCaseDisabled={isCaseDisabled}
                  gmgResponsible={gmgResponsible}
                />
              </div>
              <div className="updated-at-by updated-at-by__absolute">
                {request.updatedBy
                  ? `Updated: ${dayjs(new Date(request.updatedAt)).format(displayDateFormat)} by ${request.updatedBy}`
                  : ''}
              </div>
            </>
          )}

          <QuickNavUp />
        </section>
        {!isLoading && !request && <h1 className="no-data">Request not found!</h1>}
        {isLoading && <SpinnerContainer />}
      </IsDismissedCaseContext.Provider>
    </IsCaseDisabledContext.Provider>
  );
};

export default RequestDetails;
