import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';

import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import { formSchemaWithoutRequiredField, validationSchema } from './schemaProperties';
import { getFormSchemaWithRequiredField } from '../../../helpers';
import EmailStatusModal from './EmailStatusModal/EmailStatusModal';
import { getFormElement } from '../../Shared/Form/FormElements';
import { getContactEmployeeTemplate, getEmployee, contactEmployee } from '../../../store/actions/emailTemplates';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import './contactEmployee.scss';

const ContactEmployee = ({ match }) => {
  const dispatch = useDispatch();

  const requestId = match.params.requestId;

  const [isModalVisible, setIsModalVisible] = useState({ isVisible: false, status: false });
  const [employee, setEmployee] = useState();

  const template = useSelector((state) => state.emailTemplates.contactEmployeeTemplate[requestId]);

  useEffect(() => {
    if (!template) {
      dispatch(getContactEmployeeTemplate(requestId));
    }
    getEmployee(requestId).then((data) => setEmployee(data.employee));
  }, []);

  const initialValues = {
    subject: template?.subject || '',
    message: template?.body || '',
  };

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField);

  const onSubmit = (values, { setSubmitting, resetForm, setFieldValue }) => {
    contactEmployee(values, requestId)
      .then((response) => {
        setSubmitting(false);
        resetForm();
        setIsModalVisible((prev) => ({ isVisible: true, status: true }));
      })
      .catch((error) => {
        setSubmitting(false);
        setIsModalVisible((prev) => ({ isVisible: true, status: false, error: error.response.data }));
      });
  };

  return (
    <section className="contact-employee__section">
      {employee && (
        <div className="send-email__wrapper">
          <div className="send-email__header">
            <h3 className="underlined panel-top-row">Write an email to {employee.generalInfo.fullName}</h3>
          </div>
          <div className="send-email">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formikProps) => (
                <Form>
                  {formSchema.map((field) => (
                    <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                  ))}
                  <div className="send-email__button-container">
                    <SubmitButton title="Send" formikProps={formikProps} />
                  </div>
                </Form>
              )}
            </Formik>
            {isModalVisible.isVisible && (
              <EmailStatusModal
                setIsModalVisible={setIsModalVisible}
                isModalVisible={isModalVisible}
                requestId={requestId}
              />
            )}
          </div>
        </div>
      )}
      {!employee && <SpinnerContainer />}
    </section>
  );
};

export default ContactEmployee;
