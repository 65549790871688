import React, { useState, useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../Shared/Form/FormElements';
import { getFormsIdFromFilledDocuments, getValuesToUpdate } from '../../../helpers';
import AttachmentValidation from './AttachmentValidation/AttachmentValidation';
import UnfilledFormsValidation from './UnfilledFormsValidation/UnfilledFormsValidation';
import useDocumentsValidation from '../../Shared/Hooks/useAttachmentValidation';
import actionCreators from '../../../store/actions/actionCreators';
// import UnfilledEmploymentInfo from './UnfilledEmploymentInfo/UnfilledEmploymentInfo';

import './sendDocs.scss';
import { getEmployeeDocuments, upsertDocuments, upsertRelatedDocuments } from '../../../store/actions/requestDetails';

const formSchema = [
  {
    fieldName: 'isSent',
    type: 'Checkbox',
    label: 'I give the permission for the Global Mobility team to work with the provided documents',
  },
];

const SendDocs = ({
  sendDocs,
  docsInfoEmployee,
  docsInfoRelatives,
  setCurrentTab,
  setSelectedMenuItem,
  setSelectedTab,
  sendInfoModalClose,
  setDocsSent,
  withWhom,
  requestId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isModalVisible, setModalVisible] = useState(false);

  // Employee validation
  const [employeeFormsWithoutAttachments, setEmployeeFormsWithoutAttachments] = useState([]);
  const [unfilledEmployeeForms, setUnfilledEmployeeForms] = useState([]);

  // Relatives validation
  const [relativesFormsWithoutAttachments, setRelativesFormsWithoutAttachments] = useState({});
  const [unfilledRelativesForms, setUnfilledRelativesForms] = useState({});
  // const [unfilledEmploymentInfo, setUnfilledEmploymentInfo] = useState({});

  const checkError = useDocumentsValidation(
    docsInfoEmployee.documents,
    docsInfoEmployee.forms,
    docsInfoRelatives,
    withWhom,
  );
  const isValidationErrorExist =
    employeeFormsWithoutAttachments?.length > 0 ||
    unfilledEmployeeForms?.length > 0 ||
    Object.keys(relativesFormsWithoutAttachments).length > 0 ||
    Object.keys(unfilledRelativesForms).length > 0;
  // Object.keys(unfilledEmploymentInfo).length > 0 ||

  const setValidationErrors = () => {
    const [
      employeeDocumentsWithoutAttachments,
      employeeUnfilledForms,
      relativesDocumentsWithoutAttachments,
      relativesUnfilledForms,
      // relativesUnfilledEmploymentInfo,
    ] = checkError();

    setEmployeeFormsWithoutAttachments(employeeDocumentsWithoutAttachments);
    setUnfilledEmployeeForms(employeeUnfilledForms);

    setRelativesFormsWithoutAttachments(relativesDocumentsWithoutAttachments);
    setUnfilledRelativesForms(relativesUnfilledForms);
    // setUnfilledEmploymentInfo(relativesUnfilledEmploymentInfo);

    return [
      employeeDocumentsWithoutAttachments,
      employeeUnfilledForms,
      relativesDocumentsWithoutAttachments,
      relativesUnfilledForms,
    ];
  };

  useEffect(() => {
    if (isValidationErrorExist) {
      setValidationErrors();
    }
  }, [docsInfoEmployee.documents, docsInfoEmployee.forms, docsInfoRelatives]);

  const submitDocs = (document, values, employeeId, requestId, relatedId, relatedReqId) => {
    return new Promise((resolve, reject) => {
      const valuesInfo = { ...values };

      valuesInfo[document.slug].forEach((item) => {
        if (item.id === null) delete item.id;
        if (item.files) delete item.files;
      });

      const allFormFields = document.forms[0].fields.reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.fieldName);
        accumulator.push(currentValue.fieldName + 'DateExtraFields');
        return accumulator;
      }, []);

      const valuesToUpdate = getValuesToUpdate(
        values[document.slug],
        document.forms.map((item) => ({
          ...item.values,
          id: item.formId,
        })),
        allFormFields,
      );

      const valuesWithFilesToUpdate = getValuesToUpdate(
        values[document.slug],
        document.forms.map((item) => ({
          ...item.values,
          id: item.formId,
          files: [],
        })),
        ['files'],
      );

      const valuesToAdd = values[document.slug].filter(({ id }) => id === null || id === undefined);

      if (valuesToUpdate.length || valuesToAdd.length) {
        if (relatedId || relatedReqId) {
          dispatch(
            upsertRelatedDocuments(
              valuesToUpdate,
              valuesToAdd,
              requestId,
              relatedId,
              relatedReqId,
              document.id,
              'related',
              true,
              employeeId,
              valuesWithFilesToUpdate,
            ),
          );
        } else {
          dispatch(
            upsertDocuments(
              valuesToUpdate,
              valuesToAdd,
              employeeId,
              document.id,
              'employee',
              true,
              valuesWithFilesToUpdate,
            ),
          );
        }
        resolve(true);
      }
    });
  };

  const onSubmit = (values, { setSubmitting }) => {
    const allValues = JSON.parse(sessionStorage.getItem('EmployeeDocs')) || null;
    const employeeId = docsInfoEmployee.uuid;
    let promises = [];

    allValues &&
      docsInfoEmployee.documents.map((document) => {
        allValues[document.slug] && promises.push(submitDocs(document, allValues, employeeId));
      });

    const allRelativeValues = JSON.parse(sessionStorage.getItem('RelativeDocs')) || {};
    Object.keys(docsInfoRelatives).map((relativeRequestId) => {
      const relativeInfo = docsInfoRelatives[relativeRequestId]?.relatedInfo;
      const relativeId = relativeInfo.relative?.id;
      const relativeValues = allRelativeValues[relativeRequestId] || null;

      relativeValues &&
        relativeInfo.documents?.map((document) => {
          relativeValues[document.slug] &&
            promises.push(submitDocs(document, relativeValues, employeeId, requestId, relativeId, relativeRequestId));
        });
    });

    Promise.all(promises)
      .then((results) => {
        const [
          employeeDocumentsWithoutAttachments,
          employeeUnfilledForms,
          relativesDocumentsWithoutAttachments,
          relativesUnfilledForms,
        ] = setValidationErrors();

        if (
          employeeDocumentsWithoutAttachments?.length === 0 &&
          employeeUnfilledForms?.length === 0 &&
          Object.keys(relativesDocumentsWithoutAttachments).length === 0 &&
          Object.keys(relativesUnfilledForms).length === 0
        ) {
          const employeeDocumentsIdFromSubmittedDocs = getFormsIdFromFilledDocuments(docsInfoEmployee.documents);
          const employeeFormsIdFromSubmittedDocs = getFormsIdFromFilledDocuments(docsInfoEmployee.forms);
          const relativesFormsIdFromSubmittedDocs = Object.values(docsInfoRelatives).reduce(
            (previousValue, currentValue) => {
              return previousValue.concat(getFormsIdFromFilledDocuments(currentValue.relatedInfo.forms));
            },
            [],
          );
          const relativesDocumentsIdFromSubmittedDocs = Object.values(docsInfoRelatives).reduce(
            (previousValue, currentValue) => {
              return previousValue.concat(getFormsIdFromFilledDocuments(currentValue.relatedInfo.documents));
            },
            [],
          );

          sendDocs({
            submittedDocsIds: employeeDocumentsIdFromSubmittedDocs
              .concat(relativesDocumentsIdFromSubmittedDocs)
              .concat(employeeFormsIdFromSubmittedDocs)
              .concat(relativesFormsIdFromSubmittedDocs),
          })
            .then(() => {
              sendInfoModalClose(false);
              setDocsSent(true);
            })
            .catch((error) => {
              dispatch(
                actionCreators.toastUpdate({
                  type: 'error',
                  message: 'Sorry, something went wrong there. Try again',
                }),
              );
            });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          actionCreators.toastUpdate({
            type: 'error',
            message: 'Sorry, something went wrong on updating documents.',
          }),
        );
      })
      .finally(() => {
        sessionStorage.clear();
        setSubmitting(false);
      });
  };

  return (
    <div className="send-docs__container">
      <div className="send-docs">
        <Formik
          enableReinitialize
          onSubmit={onSubmit}
          initialValues={{
            isSent: false,
          }}
        >
          {(formikProps) => (
            <Form>
              {formSchema.map((field) => (
                <div className="send-docs__form" key={field.fieldName}>
                  {getFormElement(field.type, field, formikProps)}
                </div>
              ))}
              {isValidationErrorExist && (
                <>
                  <AttachmentValidation
                    employeeFormsWithoutAttachments={employeeFormsWithoutAttachments}
                    relativesFormsWithoutAttachments={relativesFormsWithoutAttachments}
                    docsInfoRelatives={docsInfoRelatives}
                  />
                  <UnfilledFormsValidation
                    docsInfoEmployee={docsInfoEmployee}
                    unfilledEmployeeForms={unfilledEmployeeForms}
                    unfilledRelativesForms={unfilledRelativesForms}
                    docsInfoRelatives={docsInfoRelatives}
                    setCurrentTab={setCurrentTab}
                    setSelectedMenuItem={setSelectedMenuItem}
                    setSelectedTab={setSelectedTab}
                    sendInfoModalClose={sendInfoModalClose}
                  />
                  {/*<UnfilledEmploymentInfo*/}
                  {/*  docsInfoRelatives={docsInfoRelatives}*/}
                  {/*  unfilledEmploymentInfo={unfilledEmploymentInfo}*/}
                  {/*/>*/}
                </>
              )}
              <div className="send-docs__buttons">
                <button
                  type="button"
                  className="button btn-form main-btn send-docs__cancel"
                  onClick={() => sendInfoModalClose(false)}
                >
                  Cancel
                </button>
                <SubmitButton title="Confirm" formikProps={formikProps} forceDisabled={isValidationErrorExist} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SendDocs;
