import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { formSchema, formSchemaCalculationDate, getCalculatorDataChildren, getTotalCompensation } from './formSchema';
import { SubmitButton } from '../../../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../../../Shared/Form/FormElements';
import { editCompensation } from '../../../../../store/actions/request';
import PreviousCompensations from './PreviousCompensations/PreviousCompensations';
import CompensationFormula from './CompensationFormula/CompensationFormula';
import './calculateRelocationCompensation.scss';

const currencyType = {
  usd: '\u0024',
  eur: '\u20ac',
};

const CompensationCalculationForm = ({ compensationInfo, setModalVisible, requestId, linkedRequests }) => {
  const dispatch = useDispatch();

  const { calculatorData, previousCompensations } = compensationInfo;
  const compensationCurrency = currencyType[compensationInfo?.compensationCurrency];

  const previousCompensationOneThousand = useMemo(
    () =>
      previousCompensations.find(
        (item) =>
          (item?.compensationAmount === '1000.00' || item?.compensationAmount === '2000.00') &&
          !item?.relocationCaseId &&
          item?.compensationCurrency,
      ),
    [previousCompensations],
  );

  const compensationCalculation = compensationInfo.compensationCalculation || {
    employee: true,
    child: false,
    spouse: false,
    isSpouseCompanyEmployee: linkedRequests.length > 0,
    compensationAmount: 0,
    numberOfChild: 0,
    calculationDate: '',
    excludePreviousCompensation: false,
    description: '',
    paidInAdvance: false,
  };

  const calculatorDataChildren = useMemo(() => getCalculatorDataChildren(calculatorData), [calculatorData]);

  const [forWhom, setForWhom] = useState([
    (compensationCalculation?.compensationAmount
      ? compensationInfo?.compensationCalculation.spouse
      : compensationInfo?.spouseCount) && 'spouse',
    (compensationCalculation?.compensationAmount
      ? compensationInfo?.compensationCalculation.child
      : compensationInfo?.childCount) && 'child',
    compensationCalculation.compensationAmount ? compensationCalculation.employee && 'employee' : true && 'employee',
  ]);

  const [numberOfChild, setNumberOfChild] = useState(
    compensationCalculation?.compensationAmount ? compensationCalculation.numberOfChild : compensationInfo.childCount,
  );

  const [excludePreviousCompensation, setExcludePreviousCompensation] = useState(
    compensationCalculation.excludePreviousCompensation,
  );

  const [paidInAdvance, setPaidInAdvance] = useState(compensationCalculation.paidInAdvance);

  const compensationDependencies = useMemo(
    () => ({
      spouse: forWhom.some((item) => item === 'spouse'),
      employee: forWhom.some((item) => item === 'employee'),
      child: forWhom.some((item) => item === 'child'),
      numberOfChild: numberOfChild,
      excludePreviousCompensation: excludePreviousCompensation,
      previousCompensationOneThousand: previousCompensationOneThousand,
      paidInAdvance: paidInAdvance,
    }),
    [numberOfChild, forWhom, excludePreviousCompensation, paidInAdvance],
  );

  const [calculationCompensationAmount, setCompensationAmount] = useState(
    getTotalCompensation(compensationDependencies, calculatorData, calculatorDataChildren),
  );

  const onChangeForWhom = (value, formIndex, formikProps) => {
    setForWhom(value);
    const currentTotalCompensation = getTotalCompensation(
      {
        ...compensationDependencies,
        spouse: value.some((item) => item === 'spouse'),
        employee: value.some((item) => item === 'employee'),
        child: value.some((item) => item === 'child'),
      },
      calculatorData,
      calculatorDataChildren,
    );
    formikProps.setFieldValue(`compensationAmount`, currentTotalCompensation);
    setCompensationAmount(currentTotalCompensation);
  };

  const onChangeNumberOfChild = (value, formikProps, formIndex) => {
    setNumberOfChild(value);
    const currentTotalCompensation = getTotalCompensation(
      { ...compensationDependencies, numberOfChild: value },
      calculatorData,
      calculatorDataChildren,
    );
    formikProps.setFieldValue(`compensationAmount`, currentTotalCompensation);
    setCompensationAmount(currentTotalCompensation);
  };

  const onChangEexcludePreviousCompensation = (value, formIndex, formikProps) => {
    setExcludePreviousCompensation(value);
    const currentTotalCompensation = getTotalCompensation(
      { ...compensationDependencies, excludePreviousCompensation: value },
      calculatorData,
      calculatorDataChildren,
    );
    formikProps.setFieldValue(`compensationAmount`, currentTotalCompensation);
    setCompensationAmount(currentTotalCompensation);
  };

  const onChangePaidInAdvance = (value, formIndex, formikProps) => {
    setPaidInAdvance(value);
    const currentTotalCompensation = getTotalCompensation(
      { ...compensationDependencies, paidInAdvance: value },
      calculatorData,
      calculatorDataChildren,
    );
    formikProps.setFieldValue(`compensationAmount`, currentTotalCompensation);
    setCompensationAmount(currentTotalCompensation);
  };

  formSchema.forEach((item) => {
    if (item.fieldName === 'forWhom') {
      item.updateselectvalueroot = onChangeForWhom;
    }
    if (item.fieldName === 'numberOfChild') {
      item.updateselectvalueroot = onChangeNumberOfChild;
    }
    if (item.fieldName === 'excludePreviousCompensation') {
      item.updateselectvalueroot = onChangEexcludePreviousCompensation;
    }
    if (item.fieldName === 'paidInAdvance') {
      item.updateselectvalueroot = onChangePaidInAdvance;
    }
    if (item.fieldName === 'excludePreviousCompensation') {
      item.disabled = !previousCompensationOneThousand;
    }
  });

  const initialValue = useMemo(
    () =>
      compensationInfo && {
        compensationCurrency: compensationCurrency,
        isSpouseCompanyEmployee: compensationCalculation.isSpouseCompanyEmployee,
        excludePreviousCompensation: excludePreviousCompensation,
        numberOfChild: numberOfChild || '0',
        forWhom: forWhom,
        calculationDate: compensationCalculation.calculationDate,
        compensationAmount: compensationCalculation?.compensationAmount || calculationCompensationAmount || 0,
        description: compensationCalculation.description || '',
        paidInAdvance: paidInAdvance,
      },
    [compensationInfo],
  );

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    const valuesInfo = {
      isSpouseCompanyEmployee: values.isSpouseCompanyEmployee,
      excludePreviousCompensation: values.excludePreviousCompensation,
      compensationAmount: values.compensationAmount,
      description: values.description ? values.description : null,
      paidInAdvance: values.paidInAdvance,
    };

    if (values.forWhom.some((item) => item === 'child')) {
      valuesInfo.numberOfChild = values.numberOfChild;
    }

    ['employee', 'spouse', 'child'].forEach((i) =>
      values.forWhom.includes(i) ? (valuesInfo[i] = true) : (valuesInfo[i] = false),
    );

    dispatch(editCompensation(valuesInfo, requestId)).then(() => {
      setModalVisible(false);
    });
    setSubmitting(false);
  };

  const validationSchema = Yup.object().shape(
    {
      description: Yup.string().when(['compensationAmount'], {
        is: (compensationAmount) => {
          return +compensationAmount !== +calculationCompensationAmount;
        },
        then: Yup.string().required(`Please specify the reason why you have changed the calculated compensation`),
      }),
    },
    ['compensationAmount'],
  );

  return (
    <>
      <Formik enableReinitialize initialValues={initialValue} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps) => (
          <Form>
            <div className="form">
              {formSchema.map((field) => {
                if (field.fieldName === 'numberOfChild') {
                  return forWhom.some((item) => item === 'child') ? (
                    <div key={field.fieldName}>
                      <div className={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                    </div>
                  ) : null;
                } else if (field.fieldName === 'isSpouseCompanyEmployee') {
                  return (
                    <div key={field.fieldName} className="isSpouseCompanyEmployee__container">
                      <div className={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                      <p className="spouse-company-employees">
                        {linkedRequests.map((item, index) => (
                          <Link key={item.id} className="linked-request" to={'/request-details/' + item.id}>
                            {item.employee.generalInfo.fullName}
                            {index < linkedRequests.length - 1 ? ', ' : ''}
                          </Link>
                        ))}
                      </p>
                    </div>
                  );
                } else if (field.fieldName === 'previousCompensationAmount') {
                  return <PreviousCompensations key={field.fieldName} previousCompensations={previousCompensations} />;
                } else {
                  return (
                    <div key={field.fieldName}>
                      <div className={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                    </div>
                  );
                }
              })}
              <CompensationFormula
                compensationCurrency={compensationCurrency}
                forWhom={forWhom}
                calculatorData={calculatorData}
                numberOfChild={numberOfChild}
                calculatorDataChildren={calculatorDataChildren}
                previousCompensationOneThousand={previousCompensationOneThousand}
                excludePreviousCompensation={excludePreviousCompensation}
                paidInAdvance={paidInAdvance}
              />
              {formSchemaCalculationDate.map((field) => (
                <div key={field.fieldName}>
                  <div className={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
                </div>
              ))}
            </div>
            <div className="compensation__button-container">
              <SubmitButton title="Submit" />
              <button
                type="button"
                className="compensation_cancel button btn-form main-btn"
                onClick={() => setModalVisible(false)}
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CompensationCalculationForm;
