import actionCreators from './actionCreators';
import axios from '../../axios';
import queryString from 'query-string';

export const getEmployeeProfile = (id) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/employees/${id}/profile/`)
      .then((response) => {
        dispatch(actionCreators.employeeProfileLoaded(response.data, id));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const deleteEmployeeProfile = (id) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .delete(`/api/v1/employees/${id}/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error.response?.data) {
          errorMessage = error.response?.data;
        }
        toast.errorMessage = errorMessage;
        toast.type = 'error';
        return error.response;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const getEmployeeHistory = (employeeId, page) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/employees/${employeeId}/history/`, {
        params: { page: page },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.employeeHistoryLoaded(response.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.employeeHistoryError());
      });
  };
};

export const getEmployeeReminders = (employeeId, page, params) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/employees/${employeeId}/reminders/`, {
        params: { page: page, ...params },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.employeeRemindersLoad(employeeId, response.data));

        return response.data;
      })
      .catch((error) => {
        // toast.type = 'error';
        // toast.errorMessage = error.response?.data;

        console.error(error);
      });
  };
};

export const editEmployeeDocumentsToDisplay = (employeeId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .put(`/api/v1/employees/${employeeId}/documents-to-display/`, data)
      .then((response) => {
        dispatch(actionCreators.employeeProfileUpdate(response.data, employeeId));
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editResidencyCity = (employeeId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .put(`/api/v1/employees/${employeeId}/general-info/`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editGMGResponsible = (employeeId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .put(`/api/v1/employees/${employeeId}/gmg-responsible/`, data)
      .then((response) => {
        dispatch(actionCreators.employeeProfileUpdate(response.data, employeeId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const changeDocumentsStatus = (employeeId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .put(`/api/v1/employees/${employeeId}/documents-status/`, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
