import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import { getFormElement } from '../../Shared/Form/FormElements';
import { editGeneralInfo, getNationalities, getCitizenships } from '../../../store/actions/requestDetails';
import { getCities } from '../../../store/actions/countriesForRelocation';
import { formSchema, validationSchema } from './formSchema';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import { getFormSchemaWithRequiredField } from '../../../helpers';
import EmployeeEducation from '../EmployeeDocumentsTabs/EmployeeDocuments/EmployeeEducation';
import CustomAccordion from '../CustomAccordion/CustomAccordion';

import './generalInfo.scss';

const companiesLTPL = [4, 8, 12, 16];

const GeneralInfo = ({
  isCaseDisabled,
  companyId,
  isCreatedByEmployee,
  createdBy,
  employeeEducation,
  ...generalInfo
}) => {
  const dispatch = useDispatch();

  const nationalities = useSelector((state) => state.nationalitiesList.nationalitiesList);
  const citizenships = useSelector((state) => state.nationalitiesList.citizenshipsList);
  const cities = useSelector((state) => state.cities.cities);

  useEffect(() => {
    if (!cities?.length) {
      dispatch(getCities());
    }
  }, [cities?.length]);

  useEffect(() => {
    if (!nationalities?.length) {
      dispatch(getNationalities());
    }
  }, [dispatch, nationalities?.length]);

  useEffect(() => {
    if (!citizenships?.length) {
      dispatch(getCitizenships());
    }
  }, [dispatch, citizenships?.length]);

  nationalities &&
    formSchema.forEach((field) => {
      if (field.fieldName === 'nationalityId') {
        field.options = nationalities;
      }
      if (field.fieldName === 'citizenshipId') {
        field.options = citizenships;
      }
      if (field.fieldName === 'residencyCityId') {
        field.options = cities;
      }
    });

  // remove birth date for Poland and Lithuania
  const formSchemaFiltered =
    companiesLTPL.some((item) => item === companyId) || !generalInfo.birthDate
      ? formSchema.filter((item) => item.fieldName !== 'birthDate')
      : formSchema;

  const formSchemaWithRequiredField = getFormSchemaWithRequiredField(validationSchema, formSchemaFiltered);

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    if (!values.residencyCityId) {
      values.residencyCityId = null;
    }
    dispatch(editGeneralInfo(generalInfo.employeeId, generalInfo.requestId, values, false));
    setSubmitting(false);
  };

  return (
    <div className="GeneralInfo">
      {isCreatedByEmployee && (
        <div className="created-by-employee__title">
          Case was created automatically <br />
          (Added as a related profile for {createdBy || '-/-'} that is working at iTechArt/Vention).
          <br />
          You&apos;ll be able to edit this case, once relocation of Employee is approved in WoD.
        </div>
      )}

      <CustomAccordion id={generalInfo.employeeId} titleName={'General information'} type={'GeneralInfo'}>
        <Formik enableReinitialize initialValues={generalInfo} onSubmit={onSubmit} validationSchema={validationSchema}>
          {(formikProps) => (
            <Form style={{ marginBottom: '30px' }}>
              {formSchemaWithRequiredField.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
              ))}
              <SubmitButton title="Submit" formikProps={formikProps} />
            </Form>
          )}
        </Formik>
      </CustomAccordion>

      {employeeEducation.length > 0 &&
        employeeEducation.map((document) => {
          return <EmployeeEducation key={document.id} document={document} />;
        })}
    </div>
  );
};

export default GeneralInfo;
