import { parseFiltering, parseOrdering, transformObjectToArray } from '../../Shared/Table/helpers';
import { defaultColumns, filterMapping, sortMapping, dateRangeFilterMapping } from './tableProperties';

export const getParams = ({ tableKey }) => {
  const paramsFromLocalStorage = JSON.parse(sessionStorage.getItem(tableKey));

  const getDefaultOrderingValue = () => {
    const orderingParams =
      paramsFromLocalStorage && paramsFromLocalStorage?.ordering
        ? transformObjectToArray(paramsFromLocalStorage?.ordering)
        : [];

    orderingParams.forEach((item) => {
      defaultColumns.find((columnItem) => columnItem.key === item.columnKey).defaultSortOrder = item.order;
    });

    return orderingParams;
  };

  const getDefaultFilteringValue = () => {
    const filteringParams = paramsFromLocalStorage?.filtering || {};
    for (const key in filteringParams) {
      defaultColumns.find((item) => item.key === key).defaultFilteredValue = paramsFromLocalStorage.filtering[key];
    }

    return filteringParams;
  };

  const ordering = parseOrdering(getDefaultOrderingValue(), sortMapping);
  const filtering = parseFiltering({
    rowFiltering: getDefaultFilteringValue(),
    dateRangeFilterMapping,
    multipleFilterMapping: null,
    filterMapping,
  });

  const searchParams = paramsFromLocalStorage?.search || '';

  return [ordering, filtering, searchParams];
};
