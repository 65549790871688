import useWindowDimensions from '../../../../../Shared/Hooks/useWindowDimensions';
import { getFormElement } from '../../../../../Shared/Form/FormElements';

const SelectDocuments = ({ formikProps, setStep, formSchema, setModalVisible }) => {
  const { width } = useWindowDimensions();

  return (
    <>
      <div
        className="form__container"
        style={{
          gridTemplateRows: `repeat(${Math.ceil(width <= 1600 ? formSchema.length / 2 : formSchema.length / 3)}, auto)`,
        }}
      >
        {formSchema.map((field) => (
          <div key={field.fieldName} className={field.fieldName}>
            {getFormElement(field.type, field, formikProps)}
          </div>
        ))}
      </div>

      {!formSchema?.length && <p className="no-available-forms">No available forms</p>}
      <div className="select-documents__button-container">
        <button
          type="button"
          className="select-documents__cancel button btn-form main-btn"
          onClick={() => setModalVisible(false)}
        >
          Cancel
        </button>
        <button type="button" className="button btn-form main-btn btn-back" onClick={() => setStep((prev) => prev + 1)}>
          Next
        </button>
      </div>
    </>
  );
};

export default SelectDocuments;
