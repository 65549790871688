import * as Yup from 'yup';

const getTypeSchema = (element) => {
  switch (element) {
    case 'Checkboxes':
      return Yup.array().min(1, 'This field is required').required('This field is required');
    case 'RadioButtons':
      return Yup.mixed()
        .when('isArray', {
          is: Array.isArray,
          then: Yup.array().of(Yup.string()),
          otherwise: Yup.string(),
        })
        .required('This field is required')
        .nullable();
    case 'DatePicker':
      return Yup.date().required('This field is required').nullable();
    case 'TextArea':
      return Yup.string().required('This field is required').nullable();
    case 'Dropdown':
      return Yup.string().required('This field is required').nullable();
    case 'NumberInput':
      return Yup.number().typeError('Please enter numbers (0-9)').required('This field is required').nullable();
    case 'TextInput':
      return Yup.string().required('This field is required').nullable();
    case 'CheckboxesWithDates':
      return Yup.array().min(1, 'This field is required').required('This field is required');
    default:
      return null;
  }
};

export default getTypeSchema;
