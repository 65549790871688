import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { sortMapping, filterMapping } from './tableProperties';
import CustomTable from '../Shared/Table/CustomTable';
import ContentWrapper from '../Shared/Table/ContentWrapperMobileView/ContentWrapperMobileView';
import { getEmployeeFeedbacks } from '../../store/actions/employeeFeedback';
import FeedbackColumn from './FeedbackColumn/FeedbackColumn';
import { useGetColumns } from './useGetColumns';
import SearchName from '../Shared/Table/SearchName/SearchName';
import ClearFilters from '../Shared/Table/ClearFilters/ClearFilters';

import './feedbackTable.scss';

const commentFields = ['visa', 'relocation', 'legalization'];

const FeedbackTable = () => {
  const paramsFromLocalStorage = JSON.parse(sessionStorage.getItem(`FeedbackTable`));

  const { count, feedbacks, page } = useSelector((state) => state.feedbackTable);

  const [searchName, setSearchName] = useState('');
  const [isClearFilterParams, setClearFilterParams] = useState(false);

  const clearAllInnerFilters = () => {
    setClearFilterParams(false);
    setSearchName('');
  };

  const columns = useGetColumns();

  const rows = feedbacks.map((row, index) => ({
    key: index,
    employeeName: (
      <ContentWrapper dataLabel={'Employee name'}>
        <Link to={`/request-details/${row.id}/employee-feedback`}>{row.employee.generalInfo.fullName}</Link>
      </ContentWrapper>
    ),
    destinationCountry: <ContentWrapper dataLabel={'Destination country'}>{row.city.country.name}</ContentWrapper>,
    gmAssigned: (
      <ContentWrapper dataLabel={'GM assigned'}>
        {row.caseGmtMember?.generalInfo?.fullName || 'Not assigned'}
      </ContentWrapper>
    ),
    feedbackRelocation: (
      <ContentWrapper dataLabel={'Feedback'}>
        {row.feedbackRelocation && row.feedbackRelocation[0] && (
          <FeedbackColumn page={page} requestId={row.id} feedbackForms={row.feedbackRelocation[0]} />
        )}
      </ContentWrapper>
    ),
    feedbackLegalization: (
      <ContentWrapper dataLabel={'Feedback'}>
        {row.feedbackLegalization && row.feedbackLegalization[0] && (
          <FeedbackColumn page={page} requestId={row.id} feedbackForms={row.feedbackLegalization[0]} />
        )}
      </ContentWrapper>
    ),
    feedbackVisa: (
      <ContentWrapper dataLabel={'Feedback'}>
        {row.feedbackVisa && row.feedbackVisa[0] && (
          <FeedbackColumn page={page} requestId={row.id} feedbackForms={row.feedbackVisa[0]} />
        )}
      </ContentWrapper>
    ),
    comments: (
      <div className={'comments__container'}>
        {commentFields.map((commentField, commentIndex) => {
          const comments = row.comments[commentField];

          if (comments.length > 0) {
            return (
              <>
                <div
                  className={classNames(
                    commentIndex !== 0 &&
                      row.comments[commentFields[commentIndex - 1]].length > 0 &&
                      'comment__top-line',
                    'comment__title',
                  )}
                >
                  {commentField.charAt(0).toUpperCase() + commentField.slice(1)}
                </div>
                {comments.map((item, index) => {
                  return (
                    <div className={'comment__text'} key={index}>
                      {item}
                    </div>
                  );
                })}
              </>
            );
          }
        })}
      </div>
    ),
  }));

  return (
    <section className="feedback-table">
      <h3 className="underlined panel-top-row">Feedback</h3>
      <div className="additional-filters__container">
        <ClearFilters setClearFilterParams={setClearFilterParams} />
        <SearchName searchName={searchName} setSearchName={setSearchName} tableKey="feedback" />
      </div>
      <CustomTable
        tableKey={'FeedbackTable'}
        data={rows}
        columns={columns}
        count={count}
        getTableDataCallback={getEmployeeFeedbacks}
        searchParams={searchName}
        isClearFilterParams={isClearFilterParams}
        clearAllInnerFilters={clearAllInnerFilters}
        sortMapping={sortMapping}
        filterMapping={filterMapping || []}
        paramsFromLocalStorage={paramsFromLocalStorage}
        mobileView={true}
        pageSize={10}
      />
    </section>
  );
};

export default FeedbackTable;
