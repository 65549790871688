import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GuideLine from '../EmployeeDocuments/GuideLine/GuideLine';
import { getProfileDocuments } from '../../store/actions/requestDetails';
import Documents from './Documents/Documents';
import QuickButtonsBlock from '../Shared/QuickButtonsBlock/QuickButtonsBlock';
import SpinnerContainer from '../Common/SpinnerContainer/SpinnerContainer';

import '../EmployeeDocuments/documentsPage.scss';

const ProfileDocuments = ({ match }) => {
  const dispatch = useDispatch();

  const employeeId = match.params.id;

  const docsInfoEmployee = useSelector((state) => state.employeeProfileReducer[employeeId]?.employeeProfile);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    dispatch(getProfileDocuments(employeeId)).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch, employeeId]);

  return (
    <>
      {!isLoading && docsInfoEmployee && (
        <section className="employee-documents__container">
          <GuideLine userType="profile" />
          <div className="content__container">
            <Documents employeeId={employeeId} docsInfoEmployee={docsInfoEmployee} />
          </div>
          <QuickButtonsBlock />
        </section>
      )}
      {isLoading && <SpinnerContainer />}
    </>
  );
};

export default ProfileDocuments;
