import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';

import { editRelocationRequest } from '../../../../../../../store/actions/request';
import { getFormElement } from '../../../../../../Shared/Form/FormElements';
import './docsProvided.scss';

const DocsProvided = ({ requestId, documentsStatus, isCaseDisabled }) => {
  const dispatch = useDispatch();

  const onDocsProvided = (value) => {
    dispatch(editRelocationRequest(requestId, { documentsStatus: value }));
  };

  const checkboxFormSchema = [
    {
      fieldName: 'areDocsProvided',
      type: 'Checkbox',
      label: 'Employee docs are provided',
      updateselectvalueroot: onDocsProvided,
    },
  ];
  return (
    <div className="checkbox-wrapper docs-provided__container">
      <Formik
        enableReinitialize
        initialValues={{
          areDocsProvided: documentsStatus,
        }}
      >
        {(formikProps) => (
          <Form className="docs-provided">
            {checkboxFormSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
            ))}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DocsProvided;
