import actionCreators from './actionCreators';
import axios from '../../axios';

export const getLinkedRequest = (params) => {
  return (dispatch) => {
    axios
      .get(`/api/v1/relocation-requests/linked-search/`, { params })
      .then((response) => {
        dispatch(actionCreators.searchRequestForRelatedProfiles(response.data));
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const editLinkedRequests = (requestId, related) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .put(`/api/v1/relocation-requests/${requestId}/linked-requests/`, related)
      .then((response) => {
        dispatch(actionCreators.linkedRequestsUpdated(response.data, requestId));
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
