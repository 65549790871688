import React, { useState, useRef, useLayoutEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { editRelocationDocument, addRelocationDocument } from '../../../../store/actions/requestDetails';
import { getFormElement } from '../../../Shared/Form/FormElements';
import { getTypeSchema } from '../../../../helpers';
import { SubmitButton } from '../../../Shared/Form/Buttons/Buttons';
import SlideOutIcons from '../../../Shared/SlideOutIcons/SlideOutIcons';
import { IsCaseDisabledContext } from '../../RequestDetails';
import DocumentAccordion from '../../../Common/DocumentAccordion/DocumentAccordion';
import './additionalStepsForm.scss';

const AdditionalStepsForm = (props) => {
  const { requestId, document } = props;

  const dispatch = useDispatch();
  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const targetRef = useRef();
  const [titleLength, setTitleLength] = useState({});

  const setTitleWidth = () => {
    if (targetRef.current) {
      setTitleLength({
        width: targetRef.current.offsetWidth + 50,
      });
    }
  };

  const schema = document.forms[0];

  const formSchemaWithoutEmptDisFields = schema.fields.filter(
    (item) =>
      (item.disabled &&
        schema.values[item.fieldName] !== '' &&
        schema.values[item.fieldName]?.length !== 0 &&
        typeof schema.values[item.fieldName] !== 'undefined' &&
        schema.values[item.fieldName] !== null) ||
      !item.disabled,
  );

  let validation = {};
  formSchemaWithoutEmptDisFields.forEach((item) => {
    if (item.required) {
      validation[item.fieldName] = getTypeSchema(item.element);
    }
  });

  const validationSchema = Yup.object().shape({
    ...validation,
  });

  let initialValuesSchema;

  const initialValues = document.forms[0].formId
    ? document.forms[0].values
    : { ...document.forms[0].values, ...initialValuesSchema };

  const getValuesToUpdate = (currentValues, initialValues, formFields) => {
    return currentValues.filter((currentValue) => {
      const initialValue = initialValues.find(({ id }) => id === currentValue.id);
      return formFields.some((field) => {
        return currentValue[field] !== initialValue[field];
      });
    });
  };

  const onSubmit = (data, { setSubmitting, resetForm, setStatus }) => {
    const allFormFields = document.forms[0].fields.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.fieldName);
      accumulator.push(currentValue.fieldName + 'DateExtraFields');
      return accumulator;
    }, []);

    if (document.forms[0].formId) {
      const valuesToUpdate = getValuesToUpdate([data], [document.forms[0].values], allFormFields);

      valuesToUpdate.forEach((item) => {
        dispatch(editRelocationDocument(requestId, document.id, document.forms[0].formId, item));
      });
    } else {
      dispatch(addRelocationDocument(requestId, document.id, data));
    }

    setSubmitting(false);
  };

  useLayoutEffect(() => {
    setTitleWidth();
  }, []);

  return (
    <div className="after-relocation-steps-form">
      <DocumentAccordion documentName={document.name} documentId={document.slug} targetRef={targetRef} type="employee">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form className="ars-form additional-steps-form">
              {formSchemaWithoutEmptDisFields.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.element, field, formikProps, isCaseDisabled)}</div>
              ))}
              {!isCaseDisabled && (
                <SlideOutIcons
                  document={document}
                  titleLength={titleLength}
                  requestId={requestId}
                  additionalFlag={true}
                  withEditFormInConstructor={{ pathName: 'additional-steps' }}
                />

                // key={formIndex}
                //                       form={form}
                //                       formikProps={formikProps}
                //                       document={document}
                //                       formIndex={formIndex}
                //                       titleLength={titleLength}
                //                       remove={remove}
                //                       deleteForm={handleDeleteForm(document.id, form.id)}
                //                       archiveForm={handleArchiveForm(document.id, form.id)}
                //                       isCase={isCase}
                //                       withArchiveForm={withArchiveForm}
                //                       withEditFormInConstructor={withEditFormInConstructor}
              )}
              <div className="buttons-container">
                <SubmitButton title="Submit" formikProps={formikProps} />
                <Link
                  className="edit-btn"
                  to={{
                    pathname: '/form-builder/additional-steps/' + document.id,
                    state: { id: document.id, tab: 'additional-steps' },
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </DocumentAccordion>
    </div>
  );
};

export default AdditionalStepsForm;
