import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';

import DisplayAttachment from './DisplayAttachment/DisplayAttachment';
import { deleteAttachment } from '../../../../store/actions/uploadFile';
import { downloadArchiveAttachments } from '../../../../store/actions/downloadFile';
import Spinner from '../../SpinnerContainer/Spinner/Spinner';
import './currentAttachmentBlock.scss';

const CurrentAttachmentBlock = ({
  form,
  document,
  employeeId,
  relatedId,
  relatedReqId,
  petId,
  petReqId,
  documentType,
  isCase,
  isCaseDisabled,
  isLegalizationRequestDocument,
}) => {
  const dispatch = useDispatch();

  const [isLoadingAllfiles, setLoadingAllFiles] = useState(false);

  const handleDeleteAttachment = (formId) => (attachId) => {
    return dispatch(
      deleteAttachment(
        attachId,
        document.id,
        formId,
        documentType.forWhom,
        employeeId,
        relatedId,
        relatedReqId,
        petId,
        petReqId,
        isCase,
        documentType.type,
      ),
    );
  };

  const downloadAllAttachmentsToArchive = () => {
    setLoadingAllFiles(true);
    dispatch(downloadArchiveAttachments(employeeId, document.id, form.id)).finally(() => setLoadingAllFiles(false));
  };

  const currentAttachments = document.forms.find((item) => item.formId === form.id)?.attachments?.length;

  const isExistCurrentAttachments = !!currentAttachments && currentAttachments !== 0;

  return (
    <>
      {isExistCurrentAttachments && (
        <div className="current-attachments__container form-group">
          {!isLegalizationRequestDocument && <label className="control-label">Uploaded files:</label>}
          <div className="current-attachments-block">
            {document.forms.map((item, index) =>
              item.attachments.map((attach, subindex) => {
                if (item.formId === form.id) {
                  return (
                    <DisplayAttachment
                      key={attach.id}
                      attachmentId={attach.id}
                      gfId={document.id}
                      formId={item.formId}
                      employeeId={employeeId}
                      attach={attach}
                      deleteAttachment={handleDeleteAttachment(form.id)}
                      isDraft={item.isDraft}
                      isCase={isCase}
                      isCaseDisabled={isCaseDisabled}
                    />
                  );
                } else return null;
              }),
            )}
            {isCase && (
              <Tooltip placement={'top'} title={'Download all files'}>
                <div className="display-attachment download-all__wrapper" onClick={downloadAllAttachmentsToArchive}>
                  <div className="attachment-container download-all__container">
                    {isLoadingAllfiles ? (
                      <>
                        <Spinner />
                        <span className="loading-message">Please wait</span>{' '}
                      </>
                    ) : (
                      <DownloadOutlined />
                    )}
                  </div>
                </div>
              </Tooltip>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CurrentAttachmentBlock;
