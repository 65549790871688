import { types } from '../actions/types';
import _ from 'lodash';

export const initialState = {
  genericRelocationForm: {
    name: '',
    description: '',
    fieldset: [],
    countries: [],
  },
};

const genericRelocationFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GENERIC_RELOCATION_FORM_LOADED:
      return {
        genericRelocationForm: action.payload.genericRelocationForm,
      };
    case types.GENERIC_RELOCATION_FORM_UPDATED:
      return {
        ...state,
        genericRelocationForm: _.cloneDeep(action.payload.genericRelocationForm),
      };
    case types.GENERIC_RELOCATION_FORM_ERROR:
      return {
        genericRelocationForm: action.payload.genericRelocationForm,
      };
    default:
      return {
        ...state,
      };
  }
};

export default genericRelocationFormReducer;
