import React, { useEffect, useState } from 'react';

import Modal from '../../Shared/Modal/Modal';
import AddEmployee from './AddEmployee/AddEmployee';
import AddCandidate from './AddCandidate/AddCandidate';

import ExitIcon from '../../../assets/img/exit.svg';
import PlusIcon from '../../../assets/img/plus.svg';

import './addToLegalizationTableModal.scss';

const AddToLegalizationTableModal = ({ page, countryId }) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [isCandidateOpen, setIsCandidateOpen] = useState(false);

  return (
    <div className="right-section">
      <div className="add-item-to-legalization-table">
        <button
          className="add-item-to-legalization-table__button button btn-form main-btn btn-back"
          onClick={() => setModalVisible(true)}
        >
          <img src={PlusIcon} alt="plus" onClick={() => setModalVisible(false)} />
          Add an Employee/Candidate
        </button>
        {isModalVisible && (
          <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l">
            <div className="add-item-to-legalization-table__content">
              <div className="add-item-to-legalization-table__title">
                <h4 className="panel-top-row">Add employee/candidate</h4>
                <img src={ExitIcon} alt="close" onClick={() => setModalVisible(false)} />
              </div>
              <div className="add-item-to-legalization-table__buttons">
                <button
                  className={`tab-button tab-button-left ${isCandidateOpen ? 'tab-active' : 'tab-disabled'}`}
                  onClick={() => {
                    setIsCandidateOpen(true);
                  }}
                >
                  Add candidate
                </button>

                <button
                  className={`tab-button tab-button-right  ${!isCandidateOpen ? 'tab-active' : 'tab-disabled'}`}
                  onClick={() => setIsCandidateOpen(false)}
                >
                  Add iTechArt/Vention employee
                </button>
              </div>
              <div className="form">
                {isCandidateOpen ? (
                  <AddCandidate setModalVisible={setModalVisible} page={page} countryId={countryId} />
                ) : (
                  <AddEmployee setModalVisible={setModalVisible} page={page} countryId={countryId} />
                )}
              </div>
            </div>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default AddToLegalizationTableModal;
