import React, { useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import SmgWodLinks from '../../Common/SmgWodLinks/SmgWodLinks';
import {
  editGeneralInfo,
  getCitizenships,
  getCompanies,
  getResponsibleGMG,
  getUnits,
} from '../../../store/actions/requestDetails';
import DisplayDocuments from '../DisplayDocuments/DisplayDocuments';
import {
  editCitizenship,
  getLegalizationDocuments,
  getWorkPlaceCities,
} from '../../../store/actions/legalizationDocuments';
import DisplayAttachDocuments from '../DisplayAttachDocuments/DisplayAttachDocuments';
import {
  columns,
  getStayDocumentsOption,
  getWorkDocumentsOption,
  stayDocumentTypeOptions,
  workDocumentTypeOptions,
} from './tableProperties';
import { getDismissalLabel, getIsCaseDisabled } from '../../../helpers';
import DisplayDocumentsColumn from '../DisplayDocuments/DisplayDocumentsColumn';

import { TableDropdown } from '../../Common/Table/Dropdown/Dropdown';
import ActionsCell from '../ActionsCell/ActionRequiredCell';
import { tableDropdownCustomStyles } from '../../Shared/Table/CustomStyles/dropdownCustomStyles';
import { getParams } from './getParams';
import ContentWrapper from '../../Shared/Table/ContentWrapperMobileView/ContentWrapperMobileView';
import DisplayGeneralDocuments from '../DisplayGeneralDocuments/DisplayGeneralDocuments';
import { getWorkPlaceCitiesOptions } from './filterOptions';
import { editGMGResponsible, editResidencyCity } from '../../../store/actions/employeeProfile';
import RemindersCell from '../RemindersCell/RemindersCell';
import CheckboxTreeDateRangeFilter from '../../Shared/Table/MultipleFilter/CheckboxTreeDateRangeFilter';
import { defaultDateFormat } from '../../../constants';
import dayjs from 'dayjs';
import { TableCheckbox } from '../../Shared/Table/Checkbox/Checkbox';
import actionCreators from '../../../store/actions/actionCreators';
import { getCities } from '../../../store/actions/countriesForRelocation';

const defaultOption = [{ value: -999, text: 'Not provided' }];

export const useGetColumnsRows = ({
  legalizationDocuments,
  selectedCountry,
  page,
  generationExcelEmployeeIds,
  allCases,
  excludeIds,
}) => {
  const dispatch = useDispatch();

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;
  const responsibles = useSelector((state) => state.gmgResponsible.gmgResponsible || []);
  const citizenships = useSelector((state) => state.nationalitiesList.citizenshipsList);
  const companyOptions = useSelector((state) => state.employmentInfo.companies).map((company) => {
    return { text: company.title, key: company.id, value: company.id };
  });
  const unitOptions = useSelector((state) => state.employmentInfo.units).map((unit) => {
    return { text: unit.longName, key: unit.uuid, value: unit.uuid };
  });
  const workPlaceCities = useSelector((state) => state.cities.workPlaceCities);
  const cities = useSelector((state) => state.cities.cities);

  useEffect(() => {
    if (!cities?.length) {
      dispatch(getCities());
    }
  }, [cities?.length]);

  useEffect(() => {
    if (!unitOptions?.length) {
      dispatch(getUnits());
    }
  }, [dispatch, unitOptions?.length]);

  useEffect(() => {
    if (!companyOptions.length) {
      dispatch(getCompanies());
    }
  }, [dispatch, companyOptions?.length]);

  useEffect(() => {
    if (!citizenships?.length) {
      dispatch(getCitizenships());
    }
  }, [dispatch, citizenships?.length]);

  useEffect(() => {
    if (!workPlaceCities?.length) {
      dispatch(getWorkPlaceCities());
    }
  }, [dispatch, workPlaceCities?.length]);

  useEffect(() => {
    if (!responsibles?.length) {
      dispatch(getResponsibleGMG());
    }
  }, [dispatch, responsibles?.length]);

  const responsibleSelectOptions = useMemo(
    () =>
      responsibles?.map((responsible) => {
        return {
          text: responsible.generalInfo.fullName,
          key: responsible.uuid,
          value: responsible.uuid,
          label: responsible.generalInfo.fullName,
        };
      }),
    [responsibles],
  );

  const citizenshipsOptions = useMemo(
    () =>
      citizenships.map((option) => ({
        label: option.name,
        value: option.id.toString(),
        text: option.name,
      })),
    [citizenships],
  );

  const cityOptions = useMemo(
    () =>
      cities.map((option) => ({
        label: option.name,
        value: option.id.toString(),
        text: option.name,
      })),
    [citizenships],
  );

  const citizenOptionsWithDefaultOption = defaultOption.concat(citizenshipsOptions);
  const cityOptionsWithDefaultOption = defaultOption.concat(cityOptions);
  const unitOptionsWithDefaultOption = [{ value: 'Not provided', text: 'Not provided' }].concat(unitOptions);
  const responsibleSelectOptionsWithDefaultOption = [{ value: 'Not provided', text: 'Not provided' }].concat(
    responsibleSelectOptions,
  );
  const workPlaceCitiesOptions = getWorkPlaceCitiesOptions({ selectedCountry, workPlaceCities });

  columns.forEach((item) => {
    if (item.key === 'number') {
      item.render = (value, item, index) => (
        <ContentWrapper dataLabel={'№'}>{page === 1 ? index + 1 : (page - 1) * 50 + (index + 1)}</ContentWrapper>
      );
    }
    if (item.key === 'organization') {
      item.filters = companyOptions;
    }
    if (item.key === 'unit') {
      item.filters = unitOptionsWithDefaultOption;
    }
    if (item.key === 'citizenship') {
      item.filters = citizenOptionsWithDefaultOption;
    }
    if (item.key === 'city') {
      item.filters = workPlaceCitiesOptions;
    }
    if (item.key === 'residencyCity') {
      item.filters = cityOptionsWithDefaultOption;
    }
    if (item.key === 'gmgAssigned') {
      item.filters = responsibleSelectOptionsWithDefaultOption;
    }
    if (item.key === 'workDocuments') {
      item.filterDropdown = (props) => (
        <CheckboxTreeDateRangeFilter {...props} options={getWorkDocumentsOption(selectedCountry)} />
      );
    }
    if (item.key === 'stayDocuments') {
      item.filterDropdown = (props) => (
        <CheckboxTreeDateRangeFilter {...props} options={getStayDocumentsOption(selectedCountry)} />
      );
    }
  });

  const onCitizenshipChange = (uuid, values) => {
    dispatch(editCitizenship(uuid, { citizenshipId: values })).then(() => {
      const [ordering, filtering, searchParams, toggleParams] = getParams({
        tableKey: 'PolandDashboard',
      });
      dispatch(
        getLegalizationDocuments(page, filtering, {
          ordering: ordering,
          search: searchParams,
          toggle: toggleParams,
        }),
      );
    });
  };

  const onResidencyCityChange = (uuid, value) => {
    dispatch(editResidencyCity(uuid, { residencyCityId: value })).then(() => {
      const [ordering, filtering, searchParams, toggleParams] = getParams({
        tableKey: 'PolandDashboard',
      });
      dispatch(
        getLegalizationDocuments(page, filtering, {
          ordering: ordering,
          search: searchParams,
          toggle: toggleParams,
        }),
      );
    });
  };

  const onProfileResponsibleChange = (uuid, values) => {
    dispatch(editGMGResponsible(uuid, { gmtMemberId: values })).then(() => {
      const [ordering, filtering, searchParams, toggleParams] = getParams({
        tableKey: 'PolandDashboard',
      });
      dispatch(
        getLegalizationDocuments(page, filtering, {
          ordering: ordering,
          search: searchParams,
          toggle: toggleParams,
        }),
      );
    });
  };

  const getCheckboxValue = (employeeUuid) => {
    return allCases
      ? excludeIds.includes(employeeUuid)
        ? false
        : true
      : generationExcelEmployeeIds[employeeUuid]
      ? generationExcelEmployeeIds[employeeUuid]
      : false;
  };

  const onChangeGenerateExcel = (employeeUuid) => {
    dispatch(
      actionCreators.legalizationDocumentsExcelCheckboxesAdd(
        {
          [employeeUuid]: generationExcelEmployeeIds[employeeUuid] ? !generationExcelEmployeeIds[employeeUuid] : true,
        },
        employeeUuid,
      ),
    );
  };

  const rows = legalizationDocuments.map((row, index) => {
    const isRowDisabled = getIsCaseDisabled({
      currentEmploymentInfo: row.currentCompanyId,
      isActive: row.isActive,
      status: 'documents',
    });

    return {
      key: index,
      number: `${index + 1}`,
      fullName: (
        <ContentWrapper dataLabel={'Employee name'}>
          <div>
            <Link to={`./profile/${row.uuid}/employee-documents`.substr(1)} className="fullName">
              {row.generalInfo.fullName}
              {!row.isActive && row.internalId && getDismissalLabel(!row.currentCompanyId)}
              {row.isActive && !row.internalId && ' (Candidate)'}
            </Link>
            <SmgWodLinks
              internalId={row.internalId}
              otherNameSpelling={row.generalInfo.otherNameSpelling}
              index={index}
            />
          </div>
        </ContentWrapper>
      ),
      organization: (
        <ContentWrapper dataLabel={'Organization'}>{row?.currentCompanyTitle || 'Not provided'}</ContentWrapper>
      ),
      unit: <ContentWrapper dataLabel={'Unit'}>{row?.unit || 'Not provided'}</ContentWrapper>,
      employmentDate: (
        <ContentWrapper dataLabel={'Employment date'}>
          {row?.currentEmploymentDate ? dayjs(row?.currentEmploymentDate).format(displayDateFormat) : 'Not provided'}
        </ContentWrapper>
      ),
      city: <ContentWrapper dataLabel={'City'}>{row?.workplaceCityName || 'Not provided'}</ContentWrapper>,
      residencyCity: (
        <ContentWrapper dataLabel={'Residency city'}>
          <TableDropdown
            className={'residencyCity'}
            value={{
              value: row?.generalInfo?.residencyCity?.id || 0,
              label: row?.generalInfo?.residencyCity?.name || '',
            }}
            options={cityOptions}
            onChange={(option) => onResidencyCityChange(row.uuid, option && option.value)}
            customStyles={tableDropdownCustomStyles}
            disabled={isRowDisabled}
            isClearable={true}
          />
        </ContentWrapper>
      ),
      citizenship: (
        <ContentWrapper dataLabel={'Citizenship'}>
          <TableDropdown
            className={'citizenship'}
            value={{ value: row.generalInfo.citizenship?.id || 0, label: row.generalInfo.citizenship?.name || '' }}
            options={citizenshipsOptions}
            onChange={(option) => onCitizenshipChange(row.uuid, option.value)}
            customStyles={tableDropdownCustomStyles}
            disabled={isRowDisabled}
            isClearable={false}
          />
        </ContentWrapper>
      ),

      passport: (
        <ContentWrapper dataLabel={'Passport'}>
          <DisplayDocumentsColumn
            documents={
              row.trackingData?.passportData?.docType || row.trackingData?.passportData?.expirationDate
                ? [row.trackingData?.passportData]
                : []
            }
          />
        </ContentWrapper>
      ),
      stayDocuments: (
        <ContentWrapper dataLabel={'Stay (Documents)'}>
          <DisplayAttachDocuments
            employeeId={row.uuid}
            requestId={row.relocationCaseId}
            documents={row.trackingData?.documents?.stay || []}
            validationMessages={row.trackingData?.validationMessages?.stay || ''}
            validationType={'stay'}
            documentTypeOptions={stayDocumentTypeOptions}
            reason={row.stayOrWorkReason}
            page={page}
          />
        </ContentWrapper>
      ),
      workDocuments: (
        <ContentWrapper dataLabel={'Work (Documents)'}>
          <DisplayAttachDocuments
            employeeId={row.uuid}
            requestId={row.relocationCaseId}
            documents={row.trackingData?.documents?.work || []}
            documentTypeOptions={workDocumentTypeOptions}
            validationMessages={row.trackingData?.validationMessages?.work || ''}
            validationType={'work'}
            reason={row.stayOrWorkReason}
            page={page}
          />
        </ContentWrapper>
      ),
      residencePermit: (
        <ContentWrapper dataLabel={'Residence permit'}>
          <DisplayDocuments
            documents={row.trackingData?.residencePermit?.docType ? [row.trackingData?.residencePermit] : []}
            source={row.trackingData?.residencePermit?.docType ? row.trackingData?.residencePermit.source || 'ars' : ''}
          />
        </ContentWrapper>
      ),
      visa: (
        <ContentWrapper dataLabel={'Visa'}>
          <DisplayDocuments
            documents={row.trackingData?.documents?.visa || []}
            validationType={'visa'}
            validationMessages={row.trackingData?.validationMessages?.visa || ''}
            // reason={reason}
          />
        </ContentWrapper>
      ),
      documents: (
        <ContentWrapper dataLabel={'Documents'}>
          <DisplayGeneralDocuments
            documents={row.trackingData?.documents || {}}
            validationMessages={row.trackingData?.validationMessages || []}
          />
        </ContentWrapper>
      ),
      gmgAssigned: (
        <ContentWrapper dataLabel={'GM assigned'}>
          <TableDropdown
            value={{ value: row.profileGmtMember?.uuid || 0, label: row.profileGmtMember?.generalInfo?.fullName || '' }}
            options={responsibleSelectOptions}
            onChange={(option) => onProfileResponsibleChange(row.uuid, option && option.value)}
            customStyles={tableDropdownCustomStyles}
            disabled={isRowDisabled}
            isClearable={true}
          />
        </ContentWrapper>
      ),
      reminders: (
        <ContentWrapper dataLabel={'Reminders'}>
          <RemindersCell remindersList={row.activeReminders} />
        </ContentWrapper>
      ),
      actions: (
        <ContentWrapper dataLabel={'Actions'}>
          <ActionsCell
            selectedCountry={selectedCountry || ''}
            dashboardId={row.dashboardId}
            documentsStatus={row.documentsStatus?.status}
            documentsStatusMessage={row.documentsStatus?.message}
            relocationCaseId={row.relocationCaseId}
            employeeId={row.uuid}
            isCandidate={!row.internalId}
            events={row.events}
            disabled={isRowDisabled}
            page={page}
          />
        </ContentWrapper>
      ),
      excel: (
        <ContentWrapper dataLabel={'Excel'}>
          <TableCheckbox value={getCheckboxValue(row.uuid)} onChange={() => onChangeGenerateExcel(row.uuid)} />
        </ContentWrapper>
      ),
    };
  });

  return [columns, rows];
};
