import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getEmployeeHistory } from '../../../store/actions/employeeProfile';

import History from '../../Common/History/History';
import CustomTable from '../../Shared/Table/CustomTable';
import { getEmployeeFeedbacks } from '../../../store/actions/employeeFeedback';
import { Link } from 'react-router-dom';
import axios from '../../../axios';
import queryString from 'query-string';
import actionCreators from '../../../store/actions/actionCreators';
import dayjs from 'dayjs';
import { defaultDateFormat } from '../../../constants';

export const columns = [
  {
    key: 'createdAt',
    title: 'Created',
    label: 'Created',
    dataIndex: 'createdAt',
    width: '10%',
  },
  {
    key: 'country',
    title: 'Country',
    label: 'Country',
    dataIndex: 'country',
    width: '11%',
  },
  {
    key: 'status',
    title: 'Status',
    label: 'Status',
    dataIndex: 'status',
    width: '28%',
  },
];

const responsibleColumn = [
  {
    key: 'responsible',
    title: 'Responsible',
    label: 'Responsible',
    dataIndex: 'responsible',
    width: '26%',
  },
];

const viewDetailsColumn = [
  {
    key: 'viewDetails',
    title: '',
    label: '',
    dataIndex: 'viewDetails',
  },
];

const getEmployeeLegalizationRequestHistory = (employeeId, page) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/employees/${employeeId}/legalization-history/`, {
        params: { page: page },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.employeeHistoryLoaded(response.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.employeeHistoryError());
      });
  };
};

const LegalizationRequestsHistory = ({ employeeId, isPortalStaff }) => {
  const dispatch = useDispatch();
  const { employeeHistory, count } = useSelector((state) => state.employeeHistoryReducer);
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const getTableDataCallback = (employeeId) => (page) => {
    return getEmployeeLegalizationRequestHistory(employeeId, page);
  };

  useEffect(() => {
    if (employeeId) {
      dispatch(getEmployeeLegalizationRequestHistory(employeeId));
    }
  }, [employeeId, dispatch]);

  return (
    <div className={'legalization-request-history'}>
      <CustomTable
        tableKey={'employeeLegalizationRequestsHistory'}
        data={employeeHistory.map((item) => {
          return {
            createdAt: dayjs(item.createdAt).format(displayDateFormat),
            country: item.country?.name,
            status: item.status,
            responsible: item.responsible?.generalInfo?.fullName || 'Not provided',
            viewDetails: (
              <div className={'view-details'}>
                <Link to={`/legalization-request/${item.id}`} style={{ textAlign: 'end', fontSize: '14px' }}>
                  View details
                </Link>
              </div>
            ),
          };
        })}
        columns={
          isPortalStaff ? [...columns, ...responsibleColumn, ...viewDetailsColumn] : [...columns, ...viewDetailsColumn]
        }
        count={0}
        getTableDataCallback={getTableDataCallback(employeeId)}
        mobileView={true}
        pageSize={20}
      />
    </div>
  );
};

export default LegalizationRequestsHistory;
