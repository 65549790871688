import { Formik, Form } from 'formik';
import { getFormElement } from '../../Shared/Form/FormElements';

import DocumentAccordion from '../DocumentAccordion/DocumentAccordion';
import './educationDocument.scss';

const EducationForm = ({ document }) => {
  const formSchema = [
    {
      fieldName: 'institutionName',
      type: 'TextInput',
      label: 'Institution name',
      disabled: true,
    },
    {
      fieldName: 'majorName',
      type: 'TextareaAsInput',
      label: 'Major name',
      disabled: true,
    },
    {
      fieldName: 'facultyName',
      type: 'TextInput',
      label: 'Faculty name',
      disabled: true,
    },
    {
      fieldName: 'graduationYear',
      type: 'TextInput',
      label: 'Graduation year',
      disabled: true,
    },
  ];
  const initialValues = document;
  const documentName = document.isPrimary === true ? 'Primary education' : 'Secondary education';

  return (
    <div className="education_document">
      <DocumentAccordion documentName={documentName} documentId={documentName} type={'employee'}>
        <Formik enableReinitialize initialValues={initialValues}>
          {(formikProps) => {
            return (
              <Form>
                <>
                  <div className="education_document-container" key={document.id}>
                    <div className="education_document-container__form">
                      <div className="education_document-form">
                        <div>
                          {formSchema.map((field) => (
                            <div key={field.fieldName} className={field.fieldName}>
                              {getFormElement(
                                field.type,
                                {
                                  nameFieldArray: 'education',
                                  ...field,
                                },
                                formikProps,
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              </Form>
            );
          }}
        </Formik>
      </DocumentAccordion>
    </div>
  );
};

export default EducationForm;
