import { FieldArray, useField } from 'formik';

import { getFormElement } from '../FormElements';
import { formSchema } from './formSchema';
import './ticketField.scss';

const TicketField = (props) => {
  const { name, formikProps, formIndex, nameFieldArray, ticketamount, disabled } = props;

  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const [field] = useField(fieldName);

  if (ticketamount && field.value.length < ticketamount) {
    for (let i = 0; i < ticketamount - field.value.length; i++) {
      field.value.push({
        date: null,
        time: null,
        travelFrom: '',
        travelTo: '',
        address: null,
      });
    }
  }
  if (ticketamount && field.value.length > ticketamount) {
    for (let i = 0; i < field.value.length - ticketamount; i++) {
      field.value.pop();
    }
  }

  return (
    <div className="column tickets">
      <FieldArray name={fieldName}>
        {({ insert, remove, push }) => {
          return (
            <>
              {field.value.length > 0 &&
                field.value.map((value, formIndex) => (
                  <div className="tickets-container" key={formIndex}>
                    {field.value.length > 1 && (
                      <div>
                        <p className="title-flight"> Flight {formIndex + 1}</p>
                      </div>
                    )}
                    {formSchema.map((field, index) => (
                      <div key={index}>
                        {getFormElement(
                          field.type,
                          {
                            nameFieldArray: `${fieldName}`,
                            formIndex,
                            ...field,
                          },
                          formikProps,
                          disabled,
                        )}
                      </div>
                    ))}
                  </div>
                ))}
            </>
          );
        }}
      </FieldArray>
    </div>
  );
};

export default TicketField;
