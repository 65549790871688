import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';

import actionCreators from '../../../../../store/actions/actionCreators';
import './excelTitle.scss';

const ExcelTitle = () => {
  const dispatch = useDispatch();
  const { allCases } = useSelector((state) => state.requestCaseList);

  const handleClickAllCasesExcel = () => {
    dispatch(actionCreators.caseListAllCasesUpdate());
  };
  return (
    <span className="case-excel-title__container">
      Excel
      <div
        className={classNames('all-excel-btn', { allExcelBtnActive: allCases })}
        onClick={() => handleClickAllCasesExcel()}
      >
        All
      </div>
    </span>
  );
};

export default ExcelTitle;
