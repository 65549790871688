import Select from 'react-select';
import classNames from 'classnames';

const Dropdown = ({ col, cell }) => {
  const defaultValue = col.getDefaultValue(cell);
  const defaultLabel = col.getDefaultLabel(cell);

  return (
    <div className="dropdown-cell">
      <Select
        value={
          defaultValue
            ? {
                value: defaultValue,
                label: defaultLabel,
              }
            : ''
        }
        options={col.getOptions(cell)}
        styles={col.customStyles}
        onChange={(option) => cell.onChangeFunc(option)}
        className="responsible-select"
        placeholder="-select-"
        isDisabled={cell?.disabled}
      />
    </div>
  );
};

export default Dropdown;

export const TableDropdown = ({ value, options, customStyles, onChange, disabled, isClearable, className }) => {
  return (
    <div className={classNames('dropdown-cell', className)}>
      <Select
        value={value.value ? value : ''}
        options={options}
        styles={customStyles}
        onChange={onChange}
        className="responsible-select"
        placeholder="-select-"
        isDisabled={disabled}
        isClearable={isClearable}
      />
    </div>
  );
};
