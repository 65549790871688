import { useState } from 'react';

import DocumentsTabs from './DocumentsTabs/DocumentsTabs';
import MenuList from '../../EmployeeDocuments/Documents/MenuList/MenuList';
import SendInfo from '../../EmployeeDocuments/Documents/SendInfo/SendInfo';
import { sendProfileDocuments } from '../../../store/actions/requestDetails';
import '../../EmployeeDocuments/Documents//documents.scss';

const Documents = ({ docsInfoEmployee, employeeId }) => {
  const [selectedTabType, setSelectedTab] = useState('employee-profile');
  const [currentTab, setCurrentTab] = useState('');

  const [selectedMenuItem, setSelectedMenuItem] = useState();

  const sendDocs = (data) => {
    return sendProfileDocuments(employeeId, data);
  };

  return (
    <div className="request-employee-documents__container">
      <h3 className="underlined">Documents</h3>
      <div className="documents__wrapper">
        <div className="documets__menu">
          <MenuList
            selectedTab={selectedTabType}
            setSelectedMenuItem={setSelectedMenuItem}
            selectedMenuItem={selectedMenuItem}
          />
          <SendInfo
            sendDocs={sendDocs}
            docsInfoEmployee={docsInfoEmployee}
            docsInfoRelatives={[]}
            setCurrentTab={setCurrentTab}
            setSelectedMenuItem={setSelectedMenuItem}
            setSelectedTab={setSelectedTab}
          />
        </div>
        <div className="documets__content">
          <DocumentsTabs
            docsInfoEmployee={docsInfoEmployee}
            selectedMenuItem={selectedMenuItem}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </div>
      </div>
    </div>
  );
};

export default Documents;
