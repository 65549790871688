import React, { useState } from 'react';
import { Tooltip } from 'antd';
import { Formik, Form } from 'formik';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';

import ConvertIcon from '../../../../assets/img/icons/convert.svg';
import ExitIcon from '../../../../assets/img/exit.svg';
import Modal from '../../../Shared/Modal/Modal';
import EmployeeSearch from '../../../Shared/Form/EmployeeSearch/EmployeeSearch';
import { SubmitButton } from '../../../Shared/Form/Buttons/Buttons';
import { convertCandidateToEmployee, getLegalizationDocuments } from '../../../../store/actions/legalizationDocuments';
import { getParams } from '../../tableProperties/getParams';
import { defaultDateFormat } from '../../../../constants';

import './mergeCandidate.scss';

const MergeCandidate = ({ candidateUuid, isCandidate, page }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setModalVisible] = useState(false);

  const dateNow = new Date();

  const filters = {
    date_before: dayjs(dateNow).format(defaultDateFormat),
    date_after: dayjs(dateNow.setMonth(dateNow.getMonth() - 3)).format(defaultDateFormat),
  };

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(
      convertCandidateToEmployee({
        candidateUuid: candidateUuid,
        dashboard_type: 'poland_page',
        employee_uuid: values.employeeId,
      }),
    )
      .then(() => {
        const [ordering, filtering, searchParams, toggleParams] = getParams({
          tableKey: 'PolandDashboard',
        });
        dispatch(
          getLegalizationDocuments(page, filtering, {
            ordering: ordering,
            search: searchParams,
            toggle: toggleParams,
          }),
        );
        setModalVisible(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <div className="merge-candidate">
      {isCandidate && (
        <>
          <Tooltip
            placement={'topRight'}
            title={'Conversion of a Candidate to Employee'}
            arrowPointAtCenter={true}
            zIndex={10}
          >
            <img
              className="convertation-candidate"
              src={ConvertIcon}
              alt="convertation"
              onClick={() => setModalVisible(true)}
            />
          </Tooltip>
          {isModalVisible && (
            <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l">
              <div className="convertation__content">
                <div className="convertation-header">
                  <h4 className="convertation-title">Conversion of a Candidate to Employee</h4>
                  <img src={ExitIcon} alt="close" onClick={() => setModalVisible(false)} />
                </div>
                <Formik enableReinitialize initialValues={{ employeeId: '' }} onSubmit={onSubmit}>
                  {(formikProps) => (
                    <>
                      <EmployeeSearch formikProps={formikProps} isHiddenOverflow={true} filters={filters} />
                      <Form>
                        <div className="convertation__button-container">
                          <button
                            type="button"
                            className="button btn-form main-btn"
                            onClick={() => setModalVisible(false)}
                          >
                            Cancel
                          </button>
                          <SubmitButton title="Confirm" formikProps={formikProps} />
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
};

export default MergeCandidate;
