import { Field, ErrorMessage } from 'formik';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import QuestionIcon from '../../../../assets/img/icons/Question.svg';
import './radioGroup.scss';

const RadioGroup = (props) => {
  const {
    name,
    label,
    formIndex,
    nameFieldArray,
    options,
    canHaveDisplayHorizontal,
    updateselectvalueroot,
    formikProps,
    disabled,
    isRequired,
  } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  const handleChange = (e) => {
    const value = e.target.value;
    formikProps.setFieldValue(fieldName, value);
    if (updateselectvalueroot) {
      updateselectvalueroot(value, e.target.id);
    }
  };

  return (
    <div className="custom-radio radio-horizontal">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}

      <div className="radios-error-container">
        <div className={classNames(canHaveDisplayHorizontal ? 'radio-horizontal' : '', 'radio-group__container')}>
          {options.map((option, index) => {
            const key = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${option.key}` : option.key;
            return (
              <div className="relocation-group" role="group" aria-labelledby="my-radio-group" key={index}>
                <div className="relocation-group-radio">
                  <div className="relocation-type-input">
                    <Field
                      type="radio"
                      name={fieldName}
                      value={option.value}
                      className="custom-control-input"
                      id={key}
                      {...(updateselectvalueroot ? { onChange: handleChange } : {})}
                      disabled={disabled}
                    />
                    <label className="custom-radio__label" htmlFor={key}>
                      <span></span>
                    </label>
                  </div>

                  <label className="radio-option" htmlFor={key}>
                    {option.text}

                    {option.tooltip && (
                      <span>
                        <Tooltip placement={'top'} title={option.tooltip}>
                          <div className="tooltip-button">
                            <img src={QuestionIcon} alt="question" />
                          </div>
                        </Tooltip>
                      </span>
                    )}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
        <ErrorMessage
          name={fieldName}
          render={(msg) => (
            <span className="error" style={{ color: 'red' }}>
              {msg}
            </span>
          )}
        />
      </div>
    </div>
  );
};

export default RadioGroup;
