import { DatePicker } from 'antd';
import { Radio } from 'antd';
import dayjs from 'dayjs';

import { ReactComponent as CalendarIcon } from '../../../../assets/img/icons/calendar.svg';
import BarChart from './Chart/Chart';
import './chartBlock.scss';

const { RangePicker } = DatePicker;

const ChartBlock = ({
  series,
  title,
  isLoading,
  totalNumber,
  radioGroupOptions,
  radioValue,
  onChangeRadioGroup,
  dateRangeValue,
  onChangeDateRange,
  customOptions,
}) => {
  return (
    <div className="chart__container">
      <div className="statistic__filters">
        <h3 className="statistic__title">{title}</h3>
        <div className="statistic__range-picker-total">
          <RangePicker
            value={dateRangeValue.dateAfter && [dayjs(dateRangeValue.dateAfter), dayjs(dateRangeValue.dateBefore)]}
            onChange={onChangeDateRange}
            format={'MM/DD/YYYY'}
            suffixIcon={<CalendarIcon />}
          />
          {totalNumber >= 0 && !isLoading.isLoading && <span className="statistic__total">Total: {totalNumber}</span>}
        </div>
        <div className="statistic__radio-group-filters">
          <Radio.Group onChange={onChangeRadioGroup} value={radioValue}>
            {radioGroupOptions.map((option) => (
              <Radio key={option.value} value={option.value}>
                {option.name}
              </Radio>
            ))}
          </Radio.Group>
        </div>
      </div>
      <BarChart customOptions={customOptions} title={title} isLoading={isLoading} series={series} />
    </div>
  );
};

export default ChartBlock;
