import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { getFormElement } from '../../../Shared/Form/FormElements';
import { SubmitButton } from '../../../Shared/Form/Buttons/Buttons';
import Modal from '../../../Shared/Modal/Modal';
import { editRelocationRequest } from '../../../../store/actions/request';
import React, { useEffect } from 'react';
// import './statusNotes.scss';

export const formSchema = [
  {
    fieldName: 'statusNote',
    type: 'TextArea',
    placeholder: 'Note text',
    // label: 'Notes',
  },
];

export const validationSchema = Yup.object().shape({
  statusNote: Yup.string().max(5000, 'Notes must not be more than 5000 characters'),
});

const Note = ({ currentTab, setModalVisible, requestId, requestNote }) => {
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(editRelocationRequest(requestId, values));
    setSubmitting(false);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';
  }, [currentTab, requestNote]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  return (
    <div className="status-notes">
      <div>
        <Formik
          enableReinitialize
          initialValues={{ statusNote: requestNote }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
              {formSchema.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field)}</div>
              ))}
              <div className="add-item-to-legalization-table__button-container">
                <button type="button" className="button btn-form main-btn" onClick={() => setModalVisible(false)}>
                  Cancel
                </button>
                <SubmitButton title="Confirm" formikProps={formikProps} />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Note;
