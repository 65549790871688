import { useEffect } from 'react';
import { useField } from 'formik';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

import { defaultDateFormat } from '../../../../constants';

import './customReminder.scss';
import { useSelector } from 'react-redux';

const CustomReminder = ({
  name,
  customReminderFieldName,
  setCustomReminderValue,
  isViewCustomReminder,
  setIsViewCustomReminder,
}) => {
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const [field] = useField(customReminderFieldName);
  const initDate =
    field.value && field.value.hasOwnProperty(name) && field.value[name]
      ? dayjs(field.value[name], defaultDateFormat)
      : null;

  useEffect(() => {
    if (initDate) {
      setIsViewCustomReminder(true);
    }
  }, [JSON.stringify(field.value)]);

  const disabled = false;

  return (
    isViewCustomReminder && (
      <div className={'custom-reminder__wrapper'}>
        <label className={'custom-reminder__label'}>Custom reminder</label>
        <div className={'custom-reminder__date-picker'}>
          <DatePicker
            defaultValue={initDate}
            format={displayDateFormat}
            className={'form-control ant-datepicker-wrapper'}
            placeholder={disabled ? '' : displayDateFormat}
            disabled={disabled}
            suffixIcon={null}
            selected={initDate}
            onChange={(date) =>
              date
                ? setCustomReminderValue({ ...field.value, [name]: dayjs(date).format(defaultDateFormat) })
                : setCustomReminderValue({ ...field.value, [name]: null })
            }
            name={customReminderFieldName}
            id={customReminderFieldName}
            allowClear={false}
          />
        </div>
      </div>
    )
  );
};

export default CustomReminder;
