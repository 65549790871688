import { useDispatch } from 'react-redux';

import { deleteVacAppointmentForm } from '../../../../store/actions/uploadFile';
import DeleteForm from '../../../Common/DeleteForm/DeleteForm';
import { CancelButton } from '../../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../../Shared/Form/FormElements';
import CustomAccordion from '../../../Common/CustomAccordion/CustomAccordion';

import './VacField.scss';

const VacField = (props) => {
  const {
    values,
    formSchema,
    ChekboxesSchema,
    visaStatusSchema,
    visaAppealSchema,
    visaNotesSchema,
    formikProps,
    requestId,
    remove,
  } = props;
  const dispatch = useDispatch();

  const handleDeleteForm = (vacId) => () => {
    dispatch(deleteVacAppointmentForm(vacId, 'employee', requestId));
  };

  return (
    <div>
      {values.vacInfo.length > 0 &&
        values.vacInfo.map((vacInfo, formIndex) => {
          const formSchemaWithDisabledFields = formSchema.map((field) => {
            if (!vacInfo.isCurrent && field.fieldName !== 'isCurrent') {
              return { ...field, disabled: true };
            } else return field;
          });
          const chekboxesSchemaWithDisabledFields = ChekboxesSchema.map((field) => {
            if (!vacInfo.isCurrent) {
              return { ...field, disabled: true };
            } else {
              return field;
            }
          });
          const visaStatusSchemaWithDisabledFields = visaStatusSchema.map((field) => {
            if (!vacInfo.isCurrent) {
              return { ...field, disabled: true };
            } else {
              return field;
            }
          });
          const visaAppealSchemaWithDisabledFields = visaAppealSchema.map((field) => {
            if (!vacInfo.isCurrent) {
              return { ...field, disabled: true };
            } else {
              return field;
            }
          });
          const visaNotesSchemaWithDisabledFields = visaNotesSchema.map((field) => {
            if (!vacInfo.isCurrent) {
              return { ...field, disabled: true };
            } else {
              return field;
            }
          });
          return (
            <div className="vacAppointment-container" key={formIndex}>
              <div className="vacApointment-form">
                <div className={'vacAppointment-title__wrapper'}>
                  <div className={'vacAppointment-title__container'}>
                    <h4>VAC appointment details</h4>
                    {vacInfo.id && <div className="vacAppointment-id">#{vacInfo.id}</div>}
                  </div>{' '}
                  {!vacInfo.id && values.vacInfo.length > 1 && <CancelButton remove={remove} formIndex={formIndex} />}
                  {vacInfo.id && <DeleteForm deleteForm={handleDeleteForm(vacInfo.id)} />}
                </div>
                {formSchemaWithDisabledFields.map((field, index) => {
                  if (field.fieldName === 'address' && !vacInfo.address) {
                    return;
                  }
                  return (
                    <div key={index}>
                      {getFormElement(
                        field.type,
                        {
                          nameFieldArray: 'vacInfo',
                          formIndex,
                          ...field,
                        },
                        formikProps,
                      )}
                    </div>
                  );
                })}
                <CustomAccordion id={vacInfo.id} titleName={'Visa application package'} type={'visaApplicationPackage'}>
                  <>
                    {chekboxesSchemaWithDisabledFields.map((field, index) => (
                      <div key={index}>
                        {getFormElement(
                          field.type,
                          {
                            nameFieldArray: 'vacInfo',
                            formIndex,
                            ...field,
                          },
                          formikProps,
                        )}
                      </div>
                    ))}
                  </>
                </CustomAccordion>
              </div>

              {vacInfo.id && (
                <CustomAccordion id={vacInfo.id} titleName={'Visa status'} type={'visaStatus'}>
                  <>
                    {visaStatusSchemaWithDisabledFields.map((field, index) => {
                      return (
                        <div key={index}>
                          {getFormElement(
                            field.type,
                            {
                              nameFieldArray: 'vacInfo',
                              formIndex,
                              ...field,
                            },
                            formikProps,
                          )}
                        </div>
                      );
                    })}
                  </>
                </CustomAccordion>
              )}
              {vacInfo.id && (vacInfo.appeal?.submissionDate || vacInfo.status === 'Rejected') && (
                <CustomAccordion id={vacInfo.id} titleName={'Visa appeal'} type={'visaAppeal'}>
                  <div className={'visa-appeal'}>
                    {visaAppealSchemaWithDisabledFields.map((field, index) => (
                      <div key={index}>
                        {getFormElement(
                          field.type,
                          {
                            nameFieldArray: 'vacInfo',
                            formIndex,
                            ...field,
                          },
                          formikProps,
                        )}
                      </div>
                    ))}
                  </div>
                </CustomAccordion>
              )}
              {visaNotesSchemaWithDisabledFields.map((field, index) => (
                <div key={index}>
                  {getFormElement(
                    field.type,
                    {
                      nameFieldArray: 'vacInfo',
                      formIndex,
                      ...field,
                    },
                    formikProps,
                  )}
                </div>
              ))}
            </div>
          );
        })}
    </div>
  );
};

export default VacField;
