import { useState, useEffect } from 'react';

import ForeignPoliceAppointment from './ForeignPoliceAppointment';

const ForeignPoliceAppointmentWrapper = ({ requestId, vacInfo }) => {
  const [isWarningModalVisible, setWarningModalVisible] = useState(false);

  const currentVacAppointment = vacInfo.find((item) => item.isCurrent);

  useEffect(() => {
    if (currentVacAppointment?.appeal?.decision === 'Rejected' && currentVacAppointment?.isSubmitting) {
      setWarningModalVisible(true);
    }
  }, []);

  return (
    <>
      <ForeignPoliceAppointment
        requestId={requestId}
        vacInfo={vacInfo}
        setWarningModalVisible={setWarningModalVisible}
      />
    </>
  );
};

export default ForeignPoliceAppointmentWrapper;
