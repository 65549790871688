import { types } from '../actions/types';

const initialState = { filters: {}, excelFilters: [] };

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.STATISTICS_LOAD:
      const { analytics_source, analytics_type, ...filters } = action.payload.params;

      const excelFilters = {
        ...(state.filters[analytics_source] && {
          ...state.filters[analytics_source],
        }),
        [analytics_type]: action.payload.params,
      };
      return {
        filters: {
          [analytics_source]: excelFilters,
        },
        excelFilters: Object.values(excelFilters || {}),
      };

    default:
      return {
        ...state,
      };
  }
};

export default statisticsReducer;
