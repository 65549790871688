import { types } from '../actions/types';

const initialState = {
  searchRequestsList: [],
};

const searchRequestRelatedProfilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_REQUEST_FOR_RELATED_PROFILES:
      return {
        ...state,
        searchRequestsList: action.payload.searchRequestsList,
      };
    default:
      return {
        ...state,
      };
  }
};

export default searchRequestRelatedProfilesReducer;
