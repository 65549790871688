import { combineReducers } from 'redux';

import auth from './auth';
import requestList from './requestList';
import requestDetails from './requestDetails';
import requestRelatedProfiles from './requestRelatedProfiles';
import genericForm from './genericForm';
import genericForms from './genericForms';
import genericRelocationForm from './genericReloactionForm';
import genericRelocationForms from './genericReloactionForms';
import legalizationForm from './legalizationForm';
import legalizationForms from './legalizationForms';
import gmgResponsible from './gmgResponsible';
import nationalitiesList from './nationalitiesRequest';
import searchEmployeesList from './searchEmployees';
import requestPetsProfiles from './requestPetsProfiles';
import countries from './countries';
import requestCaseList from './requestCaseList';
import cities from './cities';
import toast from './Toast';
import hrNotes from './hrNotes';
import requestHistory from './requestHistory';
import relativeRequestHistory from './relativeRequestHistory';
import petRequestHistory from './petRequestHistory';
import employmentInfo from './employmentInfo';
import faqInfo from './faqInfo';
import requestRelocatedEmployees from './requestRelocatedEmployees';
import statistics from './statistics';
import relocationCosts from './relocationCosts';
import excelForm from './excelForm';
import excelForms from './excelForms';
import approvers from './approvers';
import searchEmployeesCreateCase from './searchEmployeesCreateCase';
import searchRequestRelatedProfiles from './searchRequestRelatedProfiles';
import requestDocumentsDetails from './requestDocumentsDetails';
import eventsInfoReducer from './events';
import relocationRoutes from './relocationRotes';
import compensationCalculator from './compensationCalculator';
import legalizationDocuments from './legalizationDocuments';
import employeeProfileReducer from './employeeProfile';
import employeeHistoryReducer from './employeeHistory';
import vacAppointmentReducer from './requestVacAppointment';
import remindersReducer from './reminders';
import employeeCommentsReducer from './employeeComments';
import emailTemplates from './emailTemplates';
import feedbackForm from './feedbackForm';
import feedbackForms from './feedbackForms';
import feedbackTable from './feedbackTable';
import legalizationRequestListReducer from './legalizationRequestList';
import legalizationRequestReducer from './legalizationRequest';
import clearDocumentsDashboardReducer from './clearDocumentsDashboard';

export default combineReducers({
  auth,
  requestList,
  requestDetails,
  requestRelatedProfiles,
  requestPetsProfiles,
  genericForm,
  genericForms,
  genericRelocationForm,
  genericRelocationForms,
  legalizationForm,
  legalizationForms,
  gmgResponsible,
  nationalitiesList,
  searchEmployeesList,
  countries,
  requestCaseList,
  cities,
  toast,
  hrNotes,
  requestHistory,
  relativeRequestHistory,
  petRequestHistory,
  employmentInfo,
  faqInfo,
  requestRelocatedEmployees,
  statistics,
  relocationCosts,
  excelForm,
  excelForms,
  approvers,
  searchEmployeesCreateCase,
  searchRequestRelatedProfiles,
  requestDocumentsDetails,
  eventsInfoReducer,
  relocationRoutes,
  compensationCalculator,
  vacAppointmentReducer,
  legalizationDocuments,
  employeeProfileReducer,
  employeeHistoryReducer,
  remindersReducer,
  employeeCommentsReducer,
  emailTemplates,
  feedbackForm,
  feedbackForms,
  feedbackTable,
  legalizationRequestReducer,
  legalizationRequestListReducer,
  clearDocumentsDashboardReducer,
});
