import * as Yup from 'yup';
import dayjs from 'dayjs';

export const isVisaGatheredFormSchema = [
  {
    fieldName: 'isVisaGathered',
    type: 'Checkbox',
    label: 'Visa gathered',
  },
];

export const formSchema = [
  {
    fieldName: 'date',
    type: 'DatePicker',
    label: 'Date',
  },
  {
    fieldName: 'time',
    type: 'TimeRange',
    label: 'Time',
  },
  {
    fieldName: 'addressInfo.city.name',
    type: 'InputText',
    label: 'City',
    disabled: true,
  },
  {
    fieldName: 'addressInfo.id',
    type: 'Dropdown',
    label: 'Address',
    isclearable: true,
  },
  {
    fieldName: 'address',
    type: 'InputText',
    label: 'Old Address',
    disabled: true,
  },
  {
    fieldName: 'isCurrent',
    type: 'Checkbox',
    label: 'Is current Migration office?',
  },
  {
    fieldName: 'isDuplicated',
    type: 'Checkbox',
    label: 'Is the Migration office duplicated?',
    disabled: true,
  },
];

export const visaStatusSchema = [
  {
    fieldName: 'status',
    type: 'Dropdown',
    label: 'Status',
    options: [
      { text: 'Visa gathered', value: 'Gathered' },
      { text: 'Visa rejected', value: 'Rejected' },
    ],
  },
  {
    fieldName: 'statusDate',
    type: 'DatePicker',
    label: 'Date',
  },
];

export const visaAppealSchema = [
  {
    fieldName: 'appeal.submissionDate',
    type: 'DatePicker',
    label: 'Submission date',
  },
  {
    fieldName: 'appeal.decision',
    type: 'Dropdown',
    label: 'Decision',
    options: [
      { text: 'Appeal rejected', value: 'Rejected' },
      { text: 'Appeal approved', value: 'Approved' },
    ],
  },
  {
    fieldName: 'appeal.decisionDate',
    type: 'DatePicker',
    label: 'Decision date',
  },
];

export const CheckboxesSchema = [
  {
    fieldName: 'isEmployeeNotified',
    type: 'CheckboxWithDatepicker',
    label: 'Employee is notified',
    disabled: true,
  },
  {
    fieldName: 'isEmployeeReminded',
    type: 'CheckboxWithDatepicker',
    label: 'Reminder is sent',
    disabled: true,
  },
];

export const visaNotesSchema = [
  {
    fieldName: 'notes',
    type: 'NoteField',
    label: 'Notes',
  },
];

export const validationSchema = Yup.object().shape({
  vacInfo: Yup.array().of(
    Yup.object().shape({
      status: Yup.string().nullable(),
      notes: Yup.array().of(
        Yup.object().shape({
          text: Yup.string()
            .min(2, 'Notes must be at least 2 characters')
            .test('len', 'Notes must be at least 2 characters', (val) => (val ? val.toString().length : 0 > 0)),
        }),
      ),
      time: Yup.object()
        .test('is-greater', 'End time should be greater than Start time', function (value) {
          const { startTime, endTime } = this.parent.time;
          const format = 'HH:mm';

          return endTime && startTime ? dayjs(endTime, format).isAfter(dayjs(startTime, format)) : true;
        })
        .test('start-time', 'Enter Start time', function (value) {
          const { startTime, endTime } = this.parent.time;

          return !(endTime && !startTime);
        }),
      appeal: Yup.object()
        .strict()
        .nullable()
        .when('status', {
          is: 'Rejected',
          then: Yup.object({
            submissionDate: Yup.date()
              .nullable()
              .test('emptySubmissionDate', 'Please provide Submission date', function (val) {
                const decision = this.resolve(Yup.ref('decision'));
                const decisionDate = this.resolve(Yup.ref('decisionDate'));

                return !((decision || decisionDate) && !val);
              }),
            decision: Yup.string()
              .nullable()
              .test('bothDecisionAndDecisionDate', 'Please select one of the options', function (val) {
                const decisionDate = this.resolve(Yup.ref('decisionDate'));
                const submissionDate = this.resolve(Yup.ref('submissionDate'));

                if (!submissionDate) {
                  return true;
                }

                if ((!val && !decisionDate) || (val && !decisionDate)) {
                  return true;
                }
                return decisionDate && val;
              }),
            decisionDate: Yup.string()
              .nullable()
              .test('bothDecisionAndDecisionDate1', 'Please provide Decision date', function (val) {
                const decision = this.resolve(Yup.ref('decision'));
                const submissionDate = this.resolve(Yup.ref('submissionDate'));

                if (!submissionDate) {
                  return true;
                }

                if ((!val && !decision) || (val && !decision)) {
                  return true;
                }
                return decision && val;
              }),
          }),
        }),
    }),
  ),
});

export const vacAppointmentInitialValues = {
  isCurrent: true,
  date: '',
  time: { startTime: null, endDate: null },
  city: '',
  status: '',
  statusDate: '',
  addressInfo: {
    city: {
      name: '',
    },
    id: '',
  },
  appeal: {
    submissionDate: null,
    decisionDate: null,
    decision: null,
  },
  isTheSameForRelated: false,
  isEmployeeNotifiedNew: { date: null, value: false },
  isDocsGatheredNew: { date: null, value: false },
  docsAreSentToAgencyNew: { date: null, value: false },
  isScheduledNew: { date: null, value: false },
  isRelatedDocsGatheredNew: { date: null, value: false },
  isAttendedNew: { date: null, value: false },
  isPassportCollectedNew: { date: null, value: false },
  notes: [],
};
