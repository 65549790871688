export const formSchema = [
  {
    fieldName: 'compensationCurrency',
    type: 'TextInput',
    label: 'Currency',
    disabled: true,
  },
  {
    fieldName: 'forWhom',
    type: 'Checkboxes',
    label: 'Select for whom',
    inline: true,
    options: [
      { text: 'Employee', key: 'employee', value: 'employee' },
      { text: 'Spouse', key: 'spouse', value: 'spouse' },
      { text: 'Child', key: 'child', value: 'child' },
    ],
  },
  {
    fieldName: 'numberOfChild',
    type: 'Dropdown',
    label: 'Number of Child(ren)',
    options: [
      { text: 1, key: 1 },
      { text: 2, key: 2 },
      { text: 3, key: 3 },
      { text: 4, key: 4 },
      { text: 5, key: 5 },
      { text: 6, key: 6 },
      { text: 7, key: 7 },
      { text: 8, key: 8 },
      { text: 9, key: 9 },
      { text: 10, key: 10 },
    ],
  },
  {
    fieldName: 'isSpouseCompanyEmployee',
    type: 'Checkbox',
    label: 'Is Spouse an iTechArt/Vention employee',
  },
  {
    fieldName: 'previousCompensationAmount',
    type: 'TextareaAsInput',
    label: 'Previous compensation amount',
    placeholder: 'No',
    disabled: true,
  },
  {
    fieldName: 'excludePreviousCompensation',
    type: 'Checkbox',
    label: 'Exclude previous compensation',
  },
  {
    fieldName: 'compensationAmount',
    type: 'TextInput',
    label: 'Compensation',
  },
  {
    fieldName: 'paidInAdvance',
    type: 'Checkbox',
    label: 'Paid in advance',
  },
  {
    fieldName: 'description',
    type: 'TextArea',
    label: 'Description',
  },
];

export const formSchemaCalculationDate = [
  {
    fieldName: 'calculationDate',
    type: 'DatePicker',
    label: 'Date calculated',
    disabled: true,
    placeholder: 'No',
  },
];

export const getTotalCompensation = (compensationCalculation, calculatorData, calculatorDataChildren) => {
  return compensationCalculation.paidInAdvance
    ? 0
    : +((compensationCalculation.employee && calculatorData?.Employee) || 0) +
        +((compensationCalculation.spouse && calculatorData?.Spouse) || 0) +
        +(compensationCalculation.child && +compensationCalculation.numberOfChild
          ? calculatorDataChildren[compensationCalculation.numberOfChild]
          : 0) -
        ((compensationCalculation.excludePreviousCompensation &&
          compensationCalculation.previousCompensationOneThousand?.compensationAmount) ||
          0);
};

export const getCalculatorDataChildren = (calculatorData) => {
  return {
    1: calculatorData ? calculatorData.Child : 0,
    2: calculatorData ? calculatorData['Two children'] : 0,
    3: calculatorData
      ? calculatorData['Three children']
        ? calculatorData['Three children']
        : calculatorData['Two children'] + calculatorData.Child
      : 0,
    4: calculatorData ? 2 * calculatorData['Two children'] : 0,
    5: calculatorData ? 2 * calculatorData['Two children'] + calculatorData.Child : 0,
    6: calculatorData ? 3 * calculatorData['Two children'] : 0,
    7: calculatorData ? 3 * calculatorData['Two children'] + calculatorData.Child : 0,
    8: calculatorData ? 4 * calculatorData['Two children'] : 0,
    9: calculatorData ? 4 * calculatorData['Two children'] + calculatorData.Child : 0,
    10: calculatorData ? 5 * calculatorData['Two children'] : 0,
  };
};
