import { types } from '../actions/types';

const initialState = {
  count: null,
  clearDocumentsDashboard: [],
};

const clearDocumentsDashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.CLEAR_DOCUMENTS_DASHBOARD_LOAD:
      return {
        ...state,
        count: action.payload.count,
        clearDocumentsDashboard: action.payload.clearDocumentsDashboard,
      };
    case types.CLEAR_DOCUMENTS_DASHBOARD_UPDATE:
      return {
        ...state,
        clearDocumentsDashboard: state.clearDocumentsDashboard.reduce((accumulator, currentItem) => {
          if (currentItem.id === action.payload.clearDocumentId) {
            accumulator.push(action.payload.clearDocument);
          } else {
            accumulator.push(currentItem);
          }
          return accumulator;
        }, []),
      };
    default:
      return {
        ...state,
      };
  }
};

export default clearDocumentsDashboardReducer;
