import React, { useEffect, useState } from 'react';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import FAQItem from './FAQAccordion/FAQAccordion';
import { getCountriesWithQuestions } from '../../../store/actions/countriesForRelocation';
import useWindowDimensions from '../../Shared/Hooks/useWindowDimensions';

import './frequentlyAskedQuestions.scss';

const FrequentlyAskedQuestion = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const countriesInfo = useSelector((state) => state.countries.countriesWithFAQ);

  const { width } = useWindowDimensions();

  let tabs = [];
  if (countriesInfo) {
    tabs = countriesInfo?.map((item, index) => {
      return {
        index: index,
        tabName: item.name,
        title: item.name,
      };
    });
  }
  const tabName = tabs.map(({ tabName }) => tabName).find((tabName) => location.pathname.includes(tabName));

  const [active, setActive] = useState(tabName ? tabName : '');

  useEffect(() => {
    if (!countriesInfo?.length) {
      dispatch(getCountriesWithQuestions());
    }
    if (countriesInfo?.length && !tabName) {
      setActive(countriesInfo[0].name);
    } else {
      setActive(tabName);
    }
  }, [dispatch, countriesInfo?.length, tabName]);

  return (
    <section className="asked-question">
      <div className="heading">
        <h1>FAQ</h1>
      </div>
      {width <= 576 ? (
        <div className="tabs">
          {tabs &&
            tabs.map(({ index, title, tabName }) => (
              <>
                <Link to={`/relocation/faq/${tabName}`} className="link" key={index}>
                  <TabItemComponent
                    key={title}
                    title={title}
                    onItemClicked={() => setActive(tabName)}
                    isActive={active === tabName}
                  />
                </Link>
                {countriesInfo?.length &&
                  countriesInfo.map((item, index) => {
                    if (item.name === tabName) {
                      return (
                        <Route
                          key={index}
                          path={`/relocation/faq/${item.name}`}
                          component={() => <FAQItem countryId={item.id} width={width} />}
                        />
                      );
                    }
                  })}
                {countriesInfo?.length && (
                  <Redirect from="/relocation/faq" to={`/relocation/faq/${countriesInfo[0].name}`} />
                )}
              </>
            ))}
        </div>
      ) : (
        <>
          <div className="tabs">
            {tabs &&
              tabs.map(({ index, title, tabName }) => (
                <Link to={`/relocation/faq/${tabName}`} className="link" key={index}>
                  <TabItemComponent
                    key={title}
                    title={title}
                    onItemClicked={() => setActive(tabName)}
                    isActive={active === tabName}
                  />
                </Link>
              ))}
          </div>
          <Switch>
            {countriesInfo?.length &&
              countriesInfo.map((item, index) => {
                return (
                  <Route
                    key={index}
                    path={`/relocation/faq/${item.name}`}
                    component={() => <FAQItem countryId={item.id} />}
                  />
                );
              })}
            {countriesInfo?.length && (
              <Redirect from="/relocation/faq" to={`/relocation/faq/${countriesInfo[0].name}`} />
            )}
          </Switch>
        </>
      )}
    </section>
  );
};

const TabItemComponent = ({
  title = '',
  onItemClicked = () => console.error('You passed no action to the component'),
  isActive = false,
}) => {
  return (
    <div className={isActive ? 'tab-item active' : 'tab-item'} onClick={onItemClicked}>
      {title}
    </div>
  );
};

export default FrequentlyAskedQuestion;
