import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SpinnerContainer from '../Common/SpinnerContainer/SpinnerContainer';
import { getEmployeeProfile } from '../../store/actions/employeeProfile';
import EmployeeNameEvents from './EmployeeNameEvents/EmployeeNameEvents';
import QuickNavUp from '../Shared/QuickButtonsBlock/QuickNavUp/QuickNavUp';
import SiderMenu from './SiderMenu/SiderMenu';
import ContentRoutes from './ContentRoutes/ContentRoutes';

export const IsProfileDisabledContext = createContext(null);
export const IsCandidateContext = createContext(null);

import './employeeProfile.scss';
import ExtendedEmployeeProfile from './ExtendedEmployeeProfile/ExtendedEmployeeProfile';
import RegularEmployeeProfile from './RegularEmployeeProfile/RegularEmployeeProfile';

const EmployeeProfile = ({ match, isHrManager, isPortalStaff }) => {
  // const dispatch = useDispatch();

  // const employeeUuid = match.params.id;
  // const employee = useSelector((state) => state.employeeProfileReducer[employeeUuid]?.employeeProfile);
  //
  // const [isLoading, setLoading] = useState(true);
  //
  // useEffect(() => {
  //   dispatch(getEmployeeProfile(employeeUuid)).then(() => setLoading(false));
  // }, [dispatch, employeeUuid]);
  //
  // const isProfileDisable = !employee?.isActive && !employee?.currentEmploymentInfo;
  // const isCandidate = !employee?.apiUuid && !employee?.domainName && !employee?.internalId;

  return isPortalStaff || isHrManager ? (
    <ExtendedEmployeeProfile match={match} isHrManager={isHrManager} isPortalStaff={isPortalStaff} />
  ) : (
    <RegularEmployeeProfile match={match} isHrManager={isHrManager} isPortalStaff={isPortalStaff} />
  );
  //   <IsProfileDisabledContext.Provider value={isProfileDisable}>
  //     <IsCandidateContext.Provider value={isCandidate}>
  //       <>
  //         {!isLoading && employee && (
  //           <section className="RequestDetails employee-profile">
  //             {employee?.uuid === employeeUuid && (
  //               <div className="RequestDetails__wrapper">
  //                 <EmployeeNameEvents
  //                   genInfo={employee?.generalInfo}
  //                   employee={employee}
  //                   isCandidate={isCandidate}
  //                   id={employeeUuid}
  //                 />
  //
  //                 <div className="employee-profile__container">
  //                   <SiderMenu employee={employee} isCandidate={isCandidate} isHrManager={isHrManager} />
  //                   <ContentRoutes
  //                     employee={employee}
  //                     isHrManager={isHrManager}
  //                     isProfileDisable={isProfileDisable}
  //                     isCandidate={isCandidate}
  //                   />
  //                 </div>
  //               </div>
  //             )}
  //             <QuickNavUp />
  //           </section>
  //         )}
  //         {!isLoading && !employee && <h1 className="no-data">Employee not found!</h1>}
  //         {isLoading && <SpinnerContainer />}
  //       </>
  //     </IsCandidateContext.Provider>
  //   </IsProfileDisabledContext.Provider>
  // );
};

export default EmployeeProfile;
