import actionCreators from './actionCreators';
import axios from '../../axios';

export const getCountriesForRelocation = () => async (dispatch) => {
  const params = {
    visibility: 'public',
    is_allowed_for_relocation: true,
  };
  try {
    const response = await axios.get('/api/v1/geo/countries/', { params });
    dispatch(actionCreators.countriesForRelocationLoad(response.data.results));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCountriesWithQuestions = () => async (dispatch) => {
  try {
    const response = await axios.get(
      '/api/v1/geo/countries/?countries_with_questions=True&is_allowed_for_relocation=True',
    );
    dispatch(actionCreators.countriesWithFAQLoad(response.data.results));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCountriesWithImageUrlsForRelocation = () => async (dispatch) => {
  const params = {
    visibility: 'public',
    is_allowed_for_relocation: true,
  };
  try {
    const response = await axios.get('/api/v1/geo/countries-with-images/', { params });
    dispatch(actionCreators.countriesForRelocationLoad(response.data.results));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCountries = (params) => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/geo/countries/', { params });
    !params && dispatch(actionCreators.countriesListLoad(response.data.results));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCountriesWithCompanies = (params) => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/geo/countries/', { params });
    dispatch(actionCreators.countriesWithCompaniesLoad(response.data.results));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCities = (filterParams) => async (dispatch) => {
  const params = { ...filterParams, limit: 999 };
  try {
    const response = await axios.get('/api/v1/geo/cities/', { params });
    dispatch(actionCreators.citiesLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCitiesForRelocation = () => async (dispatch) => {
  const params = {
    is_allowed_for_relocation: true,
    limit: 999,
  };
  try {
    const response = await axios.get('/api/v1/geo/cities/', { params });
    dispatch(actionCreators.citiesForRelocationLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addCity = (data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post('/api/v1/geo/cities/', data);
    response.data && dispatch(actionCreators.citiesUpdate(response.data));
    return response.data;
  } catch (error) {
    toast.type = 'warning';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};
