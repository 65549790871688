import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';

import { editRelocationRequest } from '../../../../../store/actions/request';
import { getFormElement } from '../../../../Shared/Form/FormElements';
import { IsCaseDisabledContext } from '../../../RequestDetails';

export const withWhomInfo = ['withSpouse', 'withChildren', 'withPets'];

const RelatedProfiles = ({ withWhom, requestId }) => {
  const dispatch = useDispatch();

  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const onChangeRelatedProfiles = (values) => {
    const valuesData = {};
    withWhomInfo.map((i) => (values.includes(i) ? (valuesData[i] = true) : (valuesData[i] = false)));

    dispatch(editRelocationRequest(requestId, valuesData));
  };

  const formSchema = [
    {
      fieldName: 'withWhom',
      type: 'Checkboxes',
      label: 'With whom',
      inline: true,
      options: [
        { text: 'Spouse', key: 'withSpouse', value: 'withSpouse' },
        { text: 'Children', key: 'withChildren', value: 'withChildren' },
        { text: 'Pets', key: 'withPets', value: 'withPets' },
      ],
      updateselectvalueroot: onChangeRelatedProfiles,
      disabled: isCaseDisabled,
    },
  ];
  return (
    <Formik enableReinitialize initialValues={{ withWhom: withWhom }}>
      {(formikProps) => (
        <Form>
          {formSchema.map((field) => (
            <div key="field.fieldName" className={field.fieldName}>
              {getFormElement(field.type, field, formikProps)}
            </div>
          ))}
        </Form>
      )}
    </Formik>
  );
};

export default RelatedProfiles;
