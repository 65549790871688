import { useEffect } from 'react';
import DateRangeFilterView from './DateRangeFilterView/DateRangeFIlterView';

import './dateRangeFilter.scss';
import { defaultDateFormat } from '../../../../constants';

const DateRangeFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close, visible }) => {
  useEffect(() => {
    if (!visible) {
      confirm();
    }
  }, [visible]);

  const onChange = (values) => {
    setSelectedKeys(
      values
        ? [
            {
              after: values[0].format(defaultDateFormat),
              before: values[1].format(defaultDateFormat),
            },
          ]
        : [],
    );
  };

  return (
    <div className="date-range-filter__container">
      <DateRangeFilterView
        value={selectedKeys[0]}
        onChange={onChange}
        disabled={!selectedKeys[0]}
        clearFilters={clearFilters}
        confirm={confirm}
      />
    </div>
  );
};

export default DateRangeFilter;
