import actionCreators from './actionCreators';
import axios from '../../axios';

export const getVacAppointmentAddresses = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/relocation-requests/vac-appointment-addresses/?limit=999');
    dispatch(actionCreators.vacAppointmentAddressesLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
