import TotalRelocatedEmployeesStatistics from './TotalRelocatedEmployeesStatistics/TotalRelocatedEmployeesStatistics';
import ResidencePermitStatistics from './ResidencePermitStatistics/ResidencePermitStatistics';
import './relocatedEmployees.scss';

const RelocatedEmployees = () => {
  return (
    <div className="statistics__relocated-employees">
      <TotalRelocatedEmployeesStatistics />
      <ResidencePermitStatistics />
    </div>
  );
};

export default RelocatedEmployees;
