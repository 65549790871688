import parse from 'html-react-parser';
import { Tooltip } from 'antd';

import { ReactComponent as AdditionalInfoIcon } from '../../../../assets/img/icons/additional-info.svg';

const TitleWithAdditionalInfo = ({ title, additionalInfo }) => {
  return (
    <div className={'ant-table-additional-info-column'}>
      <div className={'ant-table-column-title'}>{title}</div>
      <div className={'ant-table-additional-info-icon'}>
        <Tooltip placement={'top'} title={parse(additionalInfo)}>
          <AdditionalInfoIcon
            data-tip={additionalInfo}
            className={'ant-table-additional-info'}
            data-for="additional-info-column"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default TitleWithAdditionalInfo;
