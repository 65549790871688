import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip } from 'antd';

import ModalConfirm from '../../../../Common/ModalConfirm/ModalConfirm';
import ExitImg from '../../../../../assets/img/icons/exit.svg';
import { deleteRelativeProfile } from '../../../../../store/actions/requestDetails';

const DocumentsTab = ({
  currentTab,
  tab,
  docsInfo,
  docsInfoEmployee,
  setSelectedTab,
  setCurrentTab,
  setSelectedMenuItem,
  tabs,
  requestId,
}) => {
  const dispatch = useDispatch();

  const [isModalVisible, setModalVisible] = useState(false);
  const [tabForDelete, setTabForDelete] = useState();

  const handleDeleteProfile = (relationType) => {
    const tabId = tabForDelete.requestId.replace(/[^\d]/g, '');
    const requestRelId = tabForDelete.id.replace(/[^\d]/g, '');
    if (relationType === 'child' || relationType === 'spouse') {
      dispatch(deleteRelativeProfile(requestId, tabId, requestRelId));
    }
    setModalVisible(false);
    setCurrentTab(String(docsInfo.employee.internalId));
  };

  return (
    <>
      <div className="tab-item__container">
        <button
          className={
            String(currentTab) === `${tab.id}`
              ? `tab-item active ${tab?.className || ''}`
              : `tab-item ${tab?.className || ''}`
          }
          id={tab.id}
          disabled={String(currentTab) === `${tab.id}`}
          onClick={() => {
            setCurrentTab(tab.id);
            setSelectedMenuItem(null);
            setSelectedTab(`${tab.type}_${tab.id}`);
          }}
        >
          {tab.tabTitle}
          {tab.id !== docsInfo.employee.internalId &&
            tab.id !== docsInfo.employee.internalId + 1 &&
            tab.id !== docsInfo.employee.internalId + 2 &&
            !docsInfoEmployee?.relatedCases?.some((element) => element.employee?.internalId === tab.id) && (
              <Tooltip placement={'top'} title={'Remove related profile'}>
                <div className="deleteRelative-img">
                  <img
                    src={ExitImg}
                    onClick={() => {
                      setTabForDelete({ requestId: tab.requestId, id: tab.id });
                      setModalVisible(true);
                    }}
                  />
                </div>
              </Tooltip>
            )}
        </button>
      </div>
      {isModalVisible && (
        <ModalConfirm
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          confirmationQuestion={
            'If you delete the related profile, the Global Mobility team will not recieve the data about this related profile. Are you sure you want to delete this related profile?'
          }
          handleClick={() => {
            const [deleteTab] = tabs.filter((tab) => tab.requestId === tabForDelete.requestId);
            handleDeleteProfile(deleteTab.relationType);
          }}
        />
      )}
    </>
  );
};

export default DocumentsTab;
