import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import DocumentForm from '../../../../Common/DocumentForm/DocumentForm';
import { upsertRelatedDocumentsWithCurrentField } from '../../../../../store/actions/requestDetails';
import { getValuesToUpdate, setAllValuesToNull } from '../../../../../helpers';
import { IsRelativeCaseDisabledContext } from '../../../RequestRelatedDetails';

const RelatedDocumentForm = ({ requestId, relatedReqId, document, employeeId, relativeId }) => {
  const dispatch = useDispatch();

  const isCaseDisabled = useContext(IsRelativeCaseDisabledContext);

  const onSubmit = (values, { setSubmitting, setFieldValue, resetForm, setStatus }) => {
    const valuesInfo = { ...values };

    valuesInfo[document.slug].forEach((item) => {
      if (item.id === null) delete item.id;
    });

    const allFormFields = document.forms[0].fields.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.fieldName);
      accumulator.push(currentValue.fieldName + 'DateExtraFields');
      return accumulator;
    }, []);

    const valuesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
      })),
      allFormFields,
    );

    const valuesWithFilesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
        files: [],
      })),
      ['files'],
    );

    const valuesToAdd = values[document.slug].filter(({ id }) => id === null || id === undefined);

    const isCurrentDocumentsToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        id: item.formId,
        isCurrent: item.isCurrent,
      })),
      ['isCurrent'],
    );

    const valuesToUpdateWithNullValues = setAllValuesToNull(valuesToUpdate);
    const valuesToAddWithNullValues = setAllValuesToNull(valuesToAdd);

    const files = valuesInfo[document.slug].some((item) => item?.files?.length);

    if (valuesToUpdate.length || valuesToAdd.length || isCurrentDocumentsToUpdate.length || files) {
      dispatch(
        upsertRelatedDocumentsWithCurrentField(
          valuesToUpdateWithNullValues,
          valuesToAddWithNullValues,
          requestId,
          relativeId,
          relatedReqId,
          document.id,
          'related',
          false,
          isCurrentDocumentsToUpdate,
          employeeId,
          valuesWithFilesToUpdate,
        ),
      );
    }
    if (document.isWithAttachments) {
      values[document.slug].forEach((item, index) => setFieldValue(`${document.slug}.${index}.files`, []));
    }

    setSubmitting(false);
  };

  return (
    <div>
      <DocumentForm
        document={document}
        requestId={requestId}
        relatedReqId={relatedReqId}
        onSubmit={onSubmit}
        isCase={true}
        isCaseDisabled={isCaseDisabled}
        employeeId={employeeId}
        withCurrentField={true}
        withArchiveForm={true}
        withEditFormInConstructor={{ pathName: 'documents' }}
        documentType={{ forWhom: 'related', type: 'documents' }}
      />
    </div>
  );
};

export default RelatedDocumentForm;
