import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';

import { getFormElement } from '../../../../../Shared/Form/FormElements';
import { changeVacDocRequired } from '../../../../../../store/actions/requestDetails';

const RelativeVisaBlock = ({ relativeRequestId, displayVacIsRequiredForRelatives, isVacRequired, requestId }) => {
  const dispatch = useDispatch();

  const onVacRequired = (value) => {
    dispatch(
      changeVacDocRequired(requestId, {
        relationType: 'relative',
        relativeRequestId: relativeRequestId,
        // +tabForVisa.replace(/[^\d]/g, ''),
        existence_in_list: value,
      }),
    );
  };

  const formVacRequired = [
    {
      fieldName: 'isVacRequired',
      type: 'Checkbox',
      label: 'Does any help with visa required?',
      updateselectvalueroot: onVacRequired,
    },
  ];

  return (
    <>
      {displayVacIsRequiredForRelatives && (
        <Formik enableReinitialize initialValues={{ isVacRequired: isVacRequired }}>
          {(formikProps) => (
            <Form>
              {formVacRequired.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
              ))}
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default RelativeVisaBlock;
