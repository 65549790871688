import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { getEmloyees } from '../../../../../store/actions/searchEmployees';
import './HeaderSearch.scss';

const HeaderSearch = ({ type, closePopUp }) => {
  const dispatch = useDispatch();
  const [searchHide, setSearchHide] = useState(true);

  const searchEmployees = useSelector((state) => state.searchEmployeesList.searchEmployeesList);

  const searchRef = useRef();

  const searchResult = (e, blur) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2 && !blur) {
      dispatch(getEmloyees({ search: searchValue }));
      setSearchHide(false);
    } else {
      setSearchHide(true);
    }
  };

  const blurAway = () => {
    if (searchRef.current.value.length < 3) {
      searchRef.current.value = '';
      searchRef.current.blur();
      setSearchHide(true);
    }
  };

  return (
    <div className="HeaderSearch">
      <div className={`search-wrapper ${searchHide ? 'hide' : ''}`}>
        <input
          ref={searchRef}
          type="text"
          autoComplete="off"
          onChange={(e) => {
            searchResult(e);
          }}
          onBlur={(e) => {
            setTimeout(() => {
              setSearchHide(true);
              e.target.value = '';
            }, 400);
          }}
          onMouseOut={() => {
            blurAway();
          }}
          name="search"
          id={type}
          placeholder="Enter employee name or whatever"
          className="search-input"
        />
        <span className="search-icon">
          <i className="fas fa-search"></i>
        </span>
      </div>
      <div className={`search-results__wrapper ${searchHide ? 'hide' : ''}`}>
        <ul className="search-results__items">
          {searchEmployees && searchEmployees.length ? (
            searchEmployees.map((searchItem) => {
              const isActive = searchItem.employee?.isActive;
              return (
                <li className="search-results__item" key={searchItem.id}>
                  <Link to={`/request-details/${searchItem.id}`} onClick={closePopUp}>
                    {`${searchItem.employee.generalInfo.fullName} (${
                      isActive ? `${searchItem.city.country.name}: ${searchItem.status}` : 'Dismissed'
                    })`}
                  </Link>
                </li>
              );
            })
          ) : (
            <li className="search-results__item no-content">No data to display</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default HeaderSearch;
