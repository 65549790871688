import { useState, useEffect } from 'react';

import VacAppointment from './VacAppointment';
import WarningModal from './WarningModal/WarningModal';

const VacAppointmentWrapper = ({ requestId, vacInfo }) => {
  const [isWarningModalVisible, setWarningModalVisible] = useState(false);

  const currentVacAppointment = vacInfo.find((item) => item.isCurrent);

  useEffect(() => {
    if (currentVacAppointment?.appeal?.decision === 'Rejected' && currentVacAppointment?.isSubmitting) {
      setWarningModalVisible(true);
    }
  }, []);

  return (
    <>
      <VacAppointment requestId={requestId} vacInfo={vacInfo} setWarningModalVisible={setWarningModalVisible} />
      {isWarningModalVisible && (
        <WarningModal
          requestId={requestId}
          currentVacAppointment={currentVacAppointment}
          isModalVisible={isWarningModalVisible}
          setModalVisible={setWarningModalVisible}
        />
      )}
    </>
  );
};

export default VacAppointmentWrapper;
