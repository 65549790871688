import { useDispatch } from 'react-redux';

import { deleteVacAppointmentForm } from '../../../../store/actions/uploadFile';
import DeleteForm from '../../../Common/DeleteForm/DeleteForm';
import { CancelButton } from '../../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../../Shared/Form/FormElements';
import CustomAccordion from '../../../Common/CustomAccordion/CustomAccordion';

import './VacField.scss';
import axios from '../../../../axios';
import actionCreators from '../../../../store/actions/actionCreators';

export const deleteForeignPoliceForm = (vacId, requestId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/relocation-requests/foreign-police-appointment/${vacId}/`)
      .then((response) => {
        dispatch(actionCreators.foreignPoliceAppointmentDelete(vacId, requestId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

const ForeignPoliceField = (props) => {
  const { values, formSchema, formikProps, checkboxesSchema, requestId, remove } = props;
  const dispatch = useDispatch();

  const handleDeleteForm = (vacId) => () => {
    dispatch(deleteForeignPoliceForm(vacId, requestId));
  };

  return (
    <div>
      {values.vacInfo.length > 0 &&
        values.vacInfo.map((vacInfo, formIndex) => {
          const formSchemaWithDisabledFields = formSchema.map((field) => {
            if (!vacInfo.isCurrent && field.fieldName !== 'isCurrent') {
              return { ...field, disabled: true };
            } else return field;
          });
          const checkboxesSchemaWithDisabledFields = checkboxesSchema.map((field) => {
            if (!vacInfo.isCurrent) {
              return { ...field, disabled: true };
            } else {
              return field;
            }
          });
          return (
            <div className="vacAppointment-container" key={formIndex}>
              <div className="vacApointment-form">
                <div className={'vacAppointment-title__wrapper'}>
                  <div className={'vacAppointment-title__container'}>
                    <h4>Migration office details</h4>
                    {vacInfo.id && <div className="vacAppointment-id">#{vacInfo.id}</div>}
                  </div>{' '}
                  {!vacInfo.id && values.vacInfo.length > 1 && <CancelButton remove={remove} formIndex={formIndex} />}
                  {vacInfo.id && <DeleteForm deleteForm={handleDeleteForm(vacInfo.id)} />}
                </div>
                {formSchemaWithDisabledFields.map((field, index) => {
                  if (field.fieldName === 'address' && !vacInfo.address) {
                    return;
                  }
                  if (field.fieldName === 'addressInfo.city.name' && !vacInfo.addressInfo?.city?.name) {
                    return;
                  }
                  return (
                    <div key={index}>
                      {getFormElement(
                        field.type,
                        {
                          nameFieldArray: 'vacInfo',
                          formIndex,
                          ...field,
                        },
                        formikProps,
                      )}
                    </div>
                  );
                })}
                <CustomAccordion
                  id={vacInfo.id}
                  titleName={'Migration office package'}
                  type={'foreignPoliceOfficePackage'}
                >
                  <>
                    {checkboxesSchemaWithDisabledFields.map((field, index) => (
                      <div key={index}>
                        {getFormElement(
                          field.type,
                          {
                            nameFieldArray: 'vacInfo',
                            formIndex,
                            ...field,
                          },
                          formikProps,
                        )}
                      </div>
                    ))}
                  </>
                </CustomAccordion>
              </div>
              {/*{visaNotesSchemaWithDisabledFields.map((field, index) => (*/}
              {/*  <div key={index}>*/}
              {/*    {getFormElement(*/}
              {/*      field.type,*/}
              {/*      {*/}
              {/*        nameFieldArray: 'vacInfo',*/}
              {/*        formIndex,*/}
              {/*        ...field,*/}
              {/*      },*/}
              {/*      formikProps,*/}
              {/*    )}*/}
              {/*  </div>*/}
              {/*))}*/}
            </div>
          );
        })}
    </div>
  );
};

export default ForeignPoliceField;
