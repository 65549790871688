import { Form as FormikForm, Field, ErrorMessage } from 'formik';
import classNames from 'classnames';
import { checkIsRequiredField } from '../../../../helpers';

const TextFieldRelocation = (props) => {
  const {
    name,
    label,
    placeholder,
    formIndex,
    nameFieldArray,
    formikProps,
    canHaveDisplayHorizontal,
    isRequired,
    ...rest
  } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  return (
    <div className="form-group">
      <div className="form-error-container-relocation text-field">
        <div className="form">
          {label && (
            <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
              {label}
            </label>
          )}

          <div className={classNames('text-field__wrapper')}>
            <Field
              className={classNames('form-control', 'form-width')}
              type="text"
              name={fieldName}
              id={fieldName}
              placeholder={placeholder || ''}
              {...rest}
            />
          </div>
        </div>
        <div className="error-container">
          <div className="label-error"></div>
          <ErrorMessage
            name={fieldName}
            render={(msg) => (
              <div className="error" style={{ color: 'red' }}>
                {msg}
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default TextFieldRelocation;
