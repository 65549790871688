export const toolbarOptions = {
  toolbar_location: 'bottom',
  toolbar: 'link bold italic underline removeformat | paragraphgroup',
  toolbar_groups: {
    paragraphgroup: {
      icon: 'chevron-down',
      tooltip: 'Paragraph format',
      items: 'bullist numlist | indent outdent',
    },
  },
};
