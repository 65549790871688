import { types } from '../actions/types';
import { targetIds } from '../../helpers';
import dayjs from 'dayjs';

const initialState = {};

const requestDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_DETAILS_LOADED: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: action.payload?.requestDetails,
        },
      };
    }
    case types.REQUEST_DETAILS_FEEDBACK_FORMS_LOAD: {
      const targetId = action.payload.requestId;

      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId]?.requestDetails,
            employeeFeedback: action.payload.employeeFeedback || {},
          },
        },
      };
    }
    case types.REQUEST_DETAILS_EMPLOYEE_FEEDBACK_UPFATE: {
      const targetId = action.payload.requestId;

      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId]?.requestDetails,
            employeeFeedback: {
              ...state[targetId]?.requestDetails?.employeeFeedback,
              ...action.payload.employeeFeedback,
            },
          },
        },
      };
    }
    case types.REQUEST_DETAILS_UPDATED: {
      const targetId = action.payload.requestId;
      return state[targetId]
        ? {
            ...state,
            [targetId]: {
              ...state[targetId],
              requestDetails: {
                ...state[targetId].requestDetails,
                ...action.payload.requestDetails,
                employee: { ...state[targetId].requestDetails.employee, ...action.payload.requestDetails?.employee },
              },
            },
          }
        : { ...state };
    }
    case types.VAC_APPOINTMENT_ADD: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            vacAppointment: [...state[targetId].requestDetails.vacAppointment, action.payload.vacAppointment],
          },
        },
      };
    }
    case types.FOREIGN_POLICE_APPOINTMENT_ADD: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            foreignPoliceAppointments: [
              ...state[targetId].requestDetails.foreignPoliceAppointments,
              action.payload.vacAppointment,
            ],
          },
        },
      };
    }
    case types.VAC_APPOINTMENT_FORM_DELETE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            vacAppointment: state[targetId].requestDetails.vacAppointment.filter(
              (item) => item.id !== action.payload.vacId,
            ),
          },
        },
      };
    }
    case types.FOREIGN_POLICE_APPOINTMENT_DELETE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            foreignPoliceAppointments: state[targetId].requestDetails.foreignPoliceAppointments.filter(
              (item) => item.id !== action.payload.vacId,
            ),
          },
        },
      };
    }
    case types.VAC_APPOINTMENT_UPDATE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            vacAppointment: state[targetId].requestDetails.vacAppointment.map((vacInfo) => {
              if (vacInfo.id === action.payload.vacAppointment.id) {
                vacInfo = action.payload.vacAppointment;
              }
              return vacInfo;
            }),
          },
        },
      };
    }
    case types.FOREIGN_POLICE_APPOINTMENT_UPDATE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            foreignPoliceAppointments: state[targetId].requestDetails.foreignPoliceAppointments.map((vacInfo) => {
              if (vacInfo.id === action.payload.vacAppointment.id) {
                vacInfo = action.payload.vacAppointment;
              }
              return vacInfo;
            }),
          },
        },
      };
    }
    case types.CURRENT_VAC_NOTIFIED_UPDATE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            vacAppointment: state[targetId].requestDetails.vacAppointment.map((vacInfo) => {
              if (vacInfo.isCurrent) {
                vacInfo = { ...vacInfo, isEmployeeNotifiedNew: { value: true, date: dayjs().format('MM/DD/YYYY') } };
              }
              return vacInfo;
            }),
          },
        },
      };
    }
    case types.FOREIGN_POLICE_IS_NOTIFIED: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            foreignPoliceAppointments: state[targetId].requestDetails.foreignPoliceAppointments.map((vacInfo) => {
              if (vacInfo.isCurrent) {
                vacInfo = { ...vacInfo, isEmployeeNotified: { value: true, date: dayjs().format('MM/DD/YYYY') } };
              }
              return vacInfo;
            }),
          },
        },
      };
    }
    case types.TRANSPORTATION_DETAILS_ADD: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            transportationDetails: [
              ...state[targetId].requestDetails.transportationDetails,
              action.payload.transportationDetails,
            ],
          },
        },
      };
    }
    case types.TICKET_FORM_DELETE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            transportationDetails: state[targetId].requestDetails.transportationDetails.filter(
              (current) => +current.id !== +action.payload.tdId,
            ),
          },
        },
      };
    }
    case types.TRANSPORTATION_DETAILS_UPDATE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            transportationDetails: state[targetId].requestDetails.transportationDetails.map((item) => {
              if (item.id === action.payload.transportationDetails.id) {
                item = action.payload.transportationDetails;
              }
              return item;
            }),
          },
        },
      };
    }
    case types.ACCOMMODATION_DETAILS_ADD: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            accommodationDetails: [
              ...state[targetId].requestDetails.accommodationDetails,
              action.payload.accommodationDetails,
            ],
          },
        },
      };
    }
    case types.ACCOMMODATION_FORM_DELETE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            accommodationDetails: state[targetId].requestDetails.accommodationDetails.filter(
              (item) => +item.id !== +action.payload.adId,
            ),
          },
        },
      };
    }
    case types.ACCOMMODATION_DETAILS_UPDATE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            accommodationDetails: state[targetId].requestDetails.accommodationDetails.map((item) => {
              if (item.id === action.payload.accommodationDetails.id) {
                item = action.payload.accommodationDetails;
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELATED_REQUESTS_ADD: {
      return {
        ...state,
        [targetIds(state, action)]: {
          ...state[targetIds(state, action)],
          requestDetails: {
            ...state[targetIds(state, action)].requestDetails,
            relativeRequests: [
              ...state[targetIds(state, action)].requestDetails.relativeRequests,
              action.payload.relativeRequest,
            ],
          },
        },
      };
    }
    case types.RELATED_PET_REQUEST_ADD: {
      return {
        ...state,
        [targetIds(state, action)]: {
          ...state[targetIds(state, action)],
          requestDetails: {
            ...state[targetIds(state, action)].requestDetails,
            petRequests: [...state[targetIds(state, action)].requestDetails.petRequests, action.payload.petRequest],
          },
        },
      };
    }
    case types.DELETE_PET_REQUEST: {
      return {
        ...state,
        [targetIds(state, action)]: {
          ...state[targetIds(state, action)],
          requestDetails: {
            ...state[targetIds(state, action)].requestDetails,
            petRequests: state[targetIds(state, action)].requestDetails.petRequests.filter(
              (item) => item.id !== action.payload.requestPetId,
            ),
          },
        },
      };
    }
    case types.DELETE_RELATIVE_REQUEST: {
      return {
        ...state,
        [targetIds(state, action)]: {
          ...state[targetIds(state, action)],
          requestDetails: {
            ...state[targetIds(state, action)].requestDetails,
            relativeRequests: state[targetIds(state, action)].requestDetails.relativeRequests.filter(
              (item) => item.id !== action.payload.requestRelId,
            ),
          },
        },
      };
    }
    case types.RELOCATION_DOCUMENT_ADD: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            relocationSteps: state[targetId].requestDetails.relocationSteps.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms = [...item.forms, action.payload.relocationDocument];
                item.forms.forEach((form, index) => {
                  if (form.formId === null) {
                    item.forms.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.RELOCATION_DOCUMENT_UPDATE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            relocationSteps: state[targetId].requestDetails.relocationSteps.map((item) => {
              if (+item.id === +action.payload.gfId) {
                item.forms.forEach((form, index) => {
                  if (form.formId === action.payload.relocationDocument.formId) {
                    form.values = action.payload.relocationDocument.values;
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.TICKET_ATTACHMENT_ADD: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            transportationDetails: state[targetId].requestDetails.transportationDetails.map((item) => {
              if (item.id === action.payload.tdId) {
                item.attachments = [...item.attachments, action.payload.attachments];
              }
              return item;
            }),
          },
        },
      };
    }
    case types.TICKET_ATTACHMENT_DELETE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            transportationDetails: state[targetId].requestDetails.transportationDetails.map((item) => {
              if (item.id === action.payload.tdId) {
                item.attachments.forEach((attachment, index) => {
                  if (+attachment.id === +action.payload.attachmentId) {
                    item.attachments.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.ACCOMMODATION_ATTACHMENT_ADD: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            accommodationDetails: state[targetId].requestDetails.accommodationDetails.map((item) => {
              if (item.id === action.payload.adId) {
                item.attachments = [...item.attachments, action.payload.attachments];
              }
              return item;
            }),
          },
        },
      };
    }
    case types.ACCOMMODATION_ATTACHMENT_DELETE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            accommodationDetails: state[targetId].requestDetails.accommodationDetails.map((item) => {
              if (item.id === action.payload.adId) {
                item.attachments.forEach((attachment, index) => {
                  if (+attachment.id === +action.payload.attachmentId) {
                    item.attachments.splice(index, 1);
                  }
                });
              }
              return item;
            }),
          },
        },
      };
    }
    case types.PLANNED_RELOCATION_NOTES_ADD: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            notes: [...state[targetId].requestDetails.notes, action.payload.note],
          },
        },
      };
    }
    case types.PLANNED_RELOCATION_NOTES_UPDATE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId].requestDetails,
            notes: state[targetId].requestDetails.notes.map((note) => {
              if (+note.id === +action.payload.note.id) {
                return action.payload.note;
              } else {
                return note;
              }
            }),
          },
        },
      };
    }
    case types.OTHER_DETAILS_LOADED: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          requestDetails: state[targetId]?.requestDetails,
          otherDetails: action.payload.otherDetails,
        },
      };
    }
    case types.OTHER_DETAILS_UPDATED: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          requestDetails: state[targetId]?.requestDetails,
          otherDetails: { ...state[targetId].otherDetails, ...action.payload.otherDetails },
        },
      };
    }
    case types.CHANGED_RELATIVES: {
      const targetId = action.payload.requestId;
      if (Object.keys(action.payload.relative).includes('relative')) {
        return {
          ...state,
          [targetId]: {
            ...state[targetId],
            requestDetails: {
              ...state[targetId]?.requestDetails,
              relativesNotInRequest: state[targetId]?.requestDetails.relativesNotInRequest.filter(
                (rel) => +rel.id !== +action.payload.relative.relative.id,
              ),
              relativeRequests: [...state[targetId]?.requestDetails.relativeRequests, action.payload.relative],
            },
          },
        };
      } else {
        return {
          ...state,
          [targetId]: {
            ...state[targetId],
            requestDetails: {
              ...state[targetId]?.requestDetails,
              petsNotInRequest: state[targetId]?.requestDetails.petsNotInRequest.filter(
                (rel) => +rel.id !== +action.payload.relative.pet.id,
              ),
              petRequests: [...state[targetId]?.requestDetails.petRequests, action.payload.relative],
            },
          },
        };
      }
    }
    case types.CHANGED_VAC_RELATIVES: {
      const targetId = action.payload.requestId;
      if (action.payload.relationType === 'relative') {
        return {
          ...state,
          [targetId]: {
            ...state[targetId],
            requestDetails: {
              ...state[targetId]?.requestDetails,
              relativeRequests: state[targetId]?.requestDetails.relativeRequests.map((relative) => {
                if (relative.id === action.payload.relativeRequestId) {
                  relative.isVacRequired = action.payload.existence_in_list;
                }
                return relative;
              }),
            },
          },
        };
      } else {
        return {
          ...state,
          [targetId]: {
            ...state[targetId],
            requestDetails: {
              ...state[targetId]?.requestDetails,
              linkedRequests: state[targetId]?.requestDetails.linkedRequests.map((empl) => {
                if (empl.id === action.payload.relativeRequestId) {
                  empl.isVacRequired = action.payload.existence_in_list;
                }
                return empl;
              }),
            },
          },
        };
      }
    }
    case types.CHANGED_FOREIGN_POLICE_RELATIVES: {
      const targetId = action.payload.requestId;
      if (action.payload.relationType === 'relative') {
        return {
          ...state,
          [targetId]: {
            ...state[targetId],
            requestDetails: {
              ...state[targetId]?.requestDetails,
              relativeRequests: state[targetId]?.requestDetails.relativeRequests.map((relative) => {
                if (relative.id === action.payload.relativeRequestId) {
                  relative.isForeignPoliceRequired = action.payload.existence_in_list;
                }
                return relative;
              }),
            },
          },
        };
      } else {
        return {
          ...state,
          [targetId]: {
            ...state[targetId],
            requestDetails: {
              ...state[targetId]?.requestDetails,
              linkedRequests: state[targetId]?.requestDetails.linkedRequests.map((empl) => {
                if (empl.id === action.payload.relativeRequestId) {
                  empl.isForeignPoliceRequired = action.payload.existence_in_list;
                }
                return empl;
              }),
            },
          },
        };
      }
    }
    case types.CHANGED_TD_RELATIVES: {
      const targetId = action.payload.requestId;
      if (action.payload.relationType === 'relative') {
        return {
          ...state,
          [targetId]: {
            ...state[targetId],
            requestDetails: {
              ...state[targetId]?.requestDetails,
              relativeRequests: state[targetId]?.requestDetails.relativeRequests.map((relative) => {
                if (relative.id === action.payload.relativeRequestId) {
                  relative.isTdRequired = action.payload.existence_in_list;
                }
                return relative;
              }),
            },
          },
        };
      } else if (action.payload.relationType === 'pet') {
        return {
          ...state,
          [targetId]: {
            ...state[targetId],
            requestDetails: {
              ...state[targetId]?.requestDetails,
              petRequests: state[targetId]?.requestDetails.petRequests.map((pet) => {
                if (pet.id === action.payload.relativeRequestId) {
                  pet.isTdRequired = action.payload.existence_in_list;
                }
                return pet;
              }),
            },
          },
        };
      } else {
        return {
          ...state,
          [targetId]: {
            ...state[targetId],
            requestDetails: {
              ...state[targetId]?.requestDetails,
              linkedRequests: state[targetId]?.requestDetails.linkedRequests.map((empl) => {
                if (empl.id === action.payload.relativeRequestId) {
                  empl.isTransportationRequired = action.payload.existence_in_list;
                }
                return empl;
              }),
            },
          },
        };
      }
    }
    case types.LINKED_REQUESTS_UPDATED: {
      const targetId = action.payload.linkedRequests.id;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: {
            ...state[targetId]?.requestDetails,
            linkedRequests: action.payload.linkedRequests.linkedRequests,
          },
        },
      };
    }
    case types.REQUEST_DETAILS_ADD_EVENT:
      const targetId = action.payload.requestId;
      return state.hasOwnProperty(targetId)
        ? {
            ...state,
            [targetId]: {
              ...state[targetId],
              requestDetails: {
                ...state[targetId]?.requestDetails,
                events: state[targetId]?.requestDetails.events
                  ? [action.payload.newEvent, ...state[targetId].requestDetails.events]
                  : [action.payload.newEvent],
              },
            },
          }
        : { ...state };
    case types.CASE_CONTROLLER_LOAD: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          controller: action.payload.controller,
        },
      };
    }
    case types.CASE_CONTROLLER_UPDATE: {
      const targetId = action.payload.requestId;
      return {
        ...state,
        [targetId]: {
          ...state[targetId],
          requestDetails: { ...state[targetId].requestDetails, tabsController: action.payload.tabsController },
          controller: { ...state[targetId].controller, instance: action.payload.tabsController },
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default requestDetailsReducer;
