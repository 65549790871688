import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TableDropdown } from '../Common/Table/Dropdown/Dropdown';
import SearchEmployeeOnDashboard from './AdditionalFilters/SearchEmployeeOnDashboard/SearchEmployeeOnDashboard';
import FilterToggle from './AdditionalFilters/FilterToggle/FilterToggle';
import CustomTable from '../Shared/Table/CustomTable';
import TitleWithAdditionalInfo from '../Shared/Table/TitleWithAdditionalInfo/TitleWithAdditionalInfo';
import { columns, sortMapping, filterMapping, statusReminderOptions, dateRangeFilterMapping } from './tableProperty';
import { editReminderStatus, getReminderAuthors, getReminders } from '../../store/actions/reminders';
import QuickNavUp from '../Shared/QuickButtonsBlock/QuickNavUp/QuickNavUp';
import { tableDropdownCustomStyles } from '../Shared/Table/CustomStyles/dropdownCustomStyles';
import ClearFilters from '../Shared/Table/ClearFilters/ClearFilters';
import ContentWrapper from '../Shared/Table/ContentWrapperMobileView/ContentWrapperMobileView';

import './reminders.scss';
import { defaultDateFormat } from '../../constants';
import dayjs from 'dayjs';

const Reminders = () => {
  const dispatch = useDispatch();
  const paramsFromLocalStorage = JSON.parse(sessionStorage.getItem('RemindersDashboard'));
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const { count, reminders } = useSelector((state) => state.remindersReducer);

  const authorsFilterOptions = useSelector((state) =>
    state.remindersReducer.authorList.map((item) => {
      return {
        text: item.author.generalInfo.fullName,
        value: item.author.internalId.toString(),
      };
    }),
  );

  const [searchName, setSearchName] = useState(paramsFromLocalStorage?.search || '');
  const [toggleParam, setToggleParam] = useState(paramsFromLocalStorage?.toggle || null);

  const [isClearFilterParams, setClearFilterParams] = useState(false);

  const clearAllInnerFilters = () => {
    setClearFilterParams(false);
  };

  useEffect(() => {
    if (!authorsFilterOptions.length) {
      dispatch(getReminderAuthors());
    }
  }, [authorsFilterOptions.length]);

  columns.forEach((item) => {
    if (item.dataIndex === 'id') {
      item.title = (props) => (
        <TitleWithAdditionalInfo
          {...props}
          title={toggleParam ? (toggleParam === 'cases' ? 'Case ID' : 'Profile ID') : 'Case/Profile ID'}
          additionalInfo={'The following abbreviation is used for this column:<ul><li>C: Case<li>P: Profile</ul>'}
        />
      );
    }
    if (item.key === 'authorName') {
      item.filters = authorsFilterOptions;
    }
  });

  const onStatusChange = (reminderId, value) => {
    dispatch(editReminderStatus(reminderId, { status: value }));
  };

  const data = reminders.map((item, index) => {
    return {
      key: index,
      id: (
        <ContentWrapper dataLabel={'Case/Profile ID'}>
          {item.requestId ? (
            <Link to={`/request-details/${item.requestId}/`}>C:{item.requestId}</Link>
          ) : (
            <Link to={`/profile/${item.employee.uuid}/`}>
              {(item.employee.internalId && `P:${item.employee.internalId}`) || 'Candidate'}
            </Link>
          )}
        </ContentWrapper>
      ),

      employeeName: (
        <ContentWrapper dataLabel={'Employee name'}>
          <Link className={'employee-name'} to={`/profile/${item.employee.uuid}/`}>
            {item.employee?.generalInfo?.fullName}
          </Link>
        </ContentWrapper>
      ),
      authorName: <ContentWrapper dataLabel={'Author'}>{item.author?.generalInfo?.fullName}</ContentWrapper>,
      dateReminder: (
        <ContentWrapper dataLabel={'Reminder date'}>
          {item.targetDate ? dayjs(item.targetDate).format(displayDateFormat) : 'Not provided'}
        </ContentWrapper>
      ),
      reminderText: (
        <ContentWrapper dataLabel={'Reminder text'}>
          <div className={'reminder-text'}>{item.text}</div>
        </ContentWrapper>
      ),
      statusReminder: (
        <ContentWrapper dataLabel={'Status'}>
          <TableDropdown
            value={{ value: item.status, label: item.status }}
            options={statusReminderOptions}
            onChange={(option) => onStatusChange(item.uuid, option.value)}
            customStyles={tableDropdownCustomStyles}
            isClearable={false}
          />
        </ContentWrapper>
      ),
    };
  });

  return (
    <section className="reminders">
      <div className="reminders-dashboard__header">
        <h3 className="underlined panel-top-row">Reminders</h3>
      </div>
      <>
        <div className="additional-filters__container">
          <FilterToggle toggleParam={toggleParam} setToggle={setToggleParam} count={count} />
          <SearchEmployeeOnDashboard searchName={searchName} setSearchName={setSearchName} />
        </div>
        <div className="reminders__clear-filters-container">
          <ClearFilters setClearFilterParams={setClearFilterParams} />
        </div>
        <CustomTable
          tableKey={'RemindersDashboard'}
          data={data}
          columns={columns}
          count={count}
          sortMapping={sortMapping}
          filterMapping={filterMapping}
          dateRangeFilterMapping={dateRangeFilterMapping}
          searchParams={searchName}
          toggleParams={toggleParam}
          getTableDataCallback={getReminders}
          paramsFromLocalStorage={paramsFromLocalStorage}
          isClearFilterParams={isClearFilterParams}
          clearAllInnerFilters={clearAllInnerFilters}
          mobileView={true}
          pageSize={20}
        />
      </>
      <QuickNavUp />
    </section>
  );
};

export default Reminders;
