import Checkbox from '../Checkbox/Checkbox';

import './checkboxWithLabel.scss';

const CheckboxWithLabel = ({ mainLabel, secondaryLabel, isDisabled, isChecked, tabKey }) => {
  return (
    <div className="case-progress__block">
      <label className="case-progress__label">{mainLabel}</label>
      <Checkbox tabKey={tabKey} isChecked={isChecked} label={secondaryLabel} isDisabled={isDisabled} />
    </div>
  );
};

export default CheckboxWithLabel;
