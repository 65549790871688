import { NavLink } from 'react-router-dom';

export const sidebarNavigation = [
  {
    label: (
      <NavLink to={'./controller'} className="menu-item">
        Controller
      </NavLink>
    ),
    key: 'controller',
    url: '/controller',
  },
  {
    label: (
      <NavLink to={'./general-information'} className="menu-item">
        Overview
      </NavLink>
    ),
    key: 'generalInfo',
    url: '/general-information',
  },
  {
    label: (
      <NavLink to={'./contact-information'} className="menu-item">
        Contact information
      </NavLink>
    ),
    key: 'contactInfo',
    url: '/contact-information',
  },
  {
    label: (
      <NavLink to={'./employment-information'} className="menu-item">
        Employment information
      </NavLink>
    ),
    key: 'employmentInfo',
    url: '/employment-information',
  },
  {
    label: 'Employee documents',
    key: 'employeeDocuments',
    children: [
      {
        label: (
          <NavLink to={'./employee-documents'} className="menu-item">
            Documents
          </NavLink>
        ),
        key: 'documents',
        url: '/employee-documents',
      },
      {
        label: (
          <NavLink to={'./employee-forms'} className="menu-item">
            Forms
          </NavLink>
        ),
        key: 'employeeForms',
        url: '/employee-forms',
      },
      {
        label: (
          <NavLink to={'./request-documents'} className="menu-item">
            Request docs
          </NavLink>
        ),
        key: 'requestDocuments',
        url: '/request-documents',
      },
      {
        label: (
          <NavLink to={'./legalization-steps'} className="menu-item">
            Legalization steps
          </NavLink>
        ),
        key: 'legalizationSteps',
        url: '/legalization-steps',
      },
    ],
  },
  {
    label: (
      <NavLink to={'./employee-comments'} className="menu-item">
        Employee comments
      </NavLink>
    ),
    key: 'employeeComments',
    url: '/employee-comments',
  },
  {
    label: (
      <NavLink to={'./employee-feedback'} className="menu-item">
        Employee feedback
      </NavLink>
    ),
    key: 'employeeFeedback',
    url: '/employee-feedback',
  },
  {
    label: (
      <NavLink to={'./archived-documents'} className="menu-item">
        Archived docs
      </NavLink>
    ),
    key: 'archivedDocuments',
    url: '/archived-documents',
  },
  {
    label: (
      <NavLink to={'./relocation-request-details'} className="menu-item">
        Relocation details
      </NavLink>
    ),
    key: 'relocationDetails',
    url: '/relocation-request-details',
  },
  {
    label: (
      <NavLink to={'./additional-steps'} className="menu-item">
        Additional steps
      </NavLink>
    ),
    key: 'additionalSteps',
    url: '/additional-steps',
  },
  {
    label: (
      <NavLink to={'./related-profiles'} className="menu-item">
        Related profiles
      </NavLink>
    ),
    key: 'relatedProfiles',
    url: '/related-profiles',
  },
  {
    label: 'Appointments',
    key: 'appointments',
    children: [
      {
        label: (
          <NavLink to={'./vac-appointment'} className="menu-item">
            Visa application
          </NavLink>
        ),
        key: 'vacAppointment',
        url: '/vac-appointment',
      },
      {
        label: (
          <NavLink to={'./foreign-police-appointment'} className="menu-item">
            Migration office
          </NavLink>
        ),
        key: 'foreignPoliceAppointment',
        url: '/foreign-police-appointment',
      },
    ],
  },
  {
    label: (
      <NavLink to={'./transportation-details'} className="menu-item">
        Transportation details
      </NavLink>
    ),
    key: 'transportationDetails',
    url: '/transportation-details',
  },
  {
    label: (
      <NavLink to={'./accommodation-details'} className="menu-item">
        Accommodation details
      </NavLink>
    ),
    key: 'accommodationDetails',
    url: '/accommodation-details',
  },
  {
    label: (
      <NavLink to={'./compensation'} className="menu-item">
        Compensation
      </NavLink>
    ),
    key: 'compensation',
    url: '/compensation',
  },
  {
    label: (
      <NavLink to={'./relocation-date'} className="menu-item">
        Relocation date
      </NavLink>
    ),
    key: 'relocationDate',
    url: '/relocation-date',
  },
  {
    label: (
      <NavLink to={'./history'} className="menu-item">
        History
      </NavLink>
    ),
    key: 'history',
    url: '/history',
  },
];
