import TitleWithSort from '../Shared/Table/TitleWithSort/TitleWithSort';
import FilterDropdownIcon from '../Shared/Table/FilterIcon/FilterIcon';
import MultipleCheckboxTreeFilter from '../Shared/Table/MultipleFilter/MultipleCheckboxTreeFilter';
import { feedbackFilteringOptions } from './filterOptions';

export const columns = [
  {
    key: 'employeeName',
    title: (props) => <TitleWithSort {...props} sorterKey={'employeeName'} title={'Employee name'} />,
    label: 'Employee name',
    dataIndex: 'employeeName',
    width: '10%',
    sorter: {
      multiple: 1,
    },
    fixed: 'left',
    sortOrder: null,
    filteredValue: null,
  },
  {
    key: 'destinationCountry',
    title: (props) => <TitleWithSort {...props} sorterKey={'destinationCountry'} title={'Destination country'} />,
    label: 'Destination country',
    dataIndex: 'destinationCountry',
    width: '13%',
    sorter: {
      multiple: 2,
    },
    sortOrder: null,
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
  },
  {
    key: 'gmAssigned',
    title: ' GM assigned',
    label: ' GM assigned',
    dataIndex: 'gmAssigned',
    width: '11%',
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
  },
  {
    key: 'feedbackVisa',
    title: 'Feedback (Visa)',
    label: 'Feedback (Visa)',
    dataIndex: 'feedbackVisa',
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <MultipleCheckboxTreeFilter {...props} options={feedbackFilteringOptions} />,
    width: '17%',
    filteredValue: null,
  },
  {
    key: 'feedbackRelocation',
    title: 'Feedback (Relocation)',
    label: 'Feedback (Relocation)',
    dataIndex: 'feedbackRelocation',
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <MultipleCheckboxTreeFilter {...props} options={feedbackFilteringOptions} />,
    width: '17%',
    filteredValue: null,
  },
  {
    key: 'feedbackLegalization',
    title: 'Feedback (Legalization)',
    label: 'Feedback (Legalization)',
    dataIndex: 'feedbackLegalization',
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <MultipleCheckboxTreeFilter {...props} options={feedbackFilteringOptions} />,
    width: '17%',
    filteredValue: null,
  },
  {
    key: 'comments',
    title: 'Comments',
    label: 'Comments',
    dataIndex: 'comments',
    width: '17%',
    filteredValue: null,
  },
];

export const sortMapping = {
  employeeName: 'last_name',
  destinationCountry: 'country_name',
};

export const filterMapping = {
  gmAssigned: 'responsible',
  destinationCountry: 'country_name',
  feedbackRelocation: 'feedback_relocation',
  feedbackLegalization: 'feedback_legalization',
  feedbackVisa: 'feedback_visa',
};

export const multipleCheckboxTreeFilterMapping = {
  keys: ['feedback_relocation', 'feedback_visa', 'feedback_legalization'],
  filterLookups: ['status', 'quality'],
};

export const feedbackStatusOptions = [
  { value: 'New', text: 'New', label: 'New' },
  { value: 'Processed', text: 'Processed', label: 'Processed' },
];
