export const employeeFormSchema = [
  {
    fieldName: 'email',
    type: 'TextInput',
    label: 'Email',
    disabled: true,
  },
  {
    fieldName: 'skype',
    type: 'TextInput',
    label: 'Skype',
    disabled: true,
  },
  {
    fieldName: 'phones',
    type: 'PhoneField',
    label: 'Phone numbers',
  },
  {
    fieldName: 'emergencyInfo',
    type: 'TextInput',
    label: 'Emergency contacts',
  },
];

export const candidateFormSchema = [
  {
    fieldName: 'email',
    type: 'TextInput',
    label: 'Email',
  },
  {
    fieldName: 'skype',
    type: 'TextInput',
    label: 'Skype',
  },
  {
    fieldName: 'phones',
    type: 'PhoneField',
    label: 'Phone numbers',
  },
  {
    fieldName: 'emergencyInfo',
    type: 'TextInput',
    label: 'Emergency contacts',
  },
];

export const formAddressSchema = [
  {
    fieldName: 'addressInfo',
    type: 'AddressField',
    label: 'Registration address',
  },
];
