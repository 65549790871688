export const additionalFeedbackInformationSchema = [
  {
    fieldName: 'status',
    type: 'Dropdown',
    label: 'Status',
    options: [
      {
        value: 'New',
        text: 'New',
      },
      {
        value: 'Processed',
        text: 'Processed',
      },
    ],
  },
  {
    fieldName: 'comment',
    type: 'TextArea',
    label: 'Comment',
    options: [],
  },
];
