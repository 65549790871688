import { useSelector } from 'react-redux';
import Relocation from '../Relocation/MainPage/Relocation';
import RelocatedEmployees from '../RelocatedEmployees/RelocatedEmployees';
import Statistics from '../Statistics/Statistics';

const RootPage = () => {
  const user = useSelector((state) => state.auth.user);

  let dominantRole;

  if (user?.isPortalStaff) {
    dominantRole = 'gmg';
  } else if (user?.isApproverStaff) {
    dominantRole = 'approver';
  } else if (!user?.isApproverStaff && !user?.isPortalStaff) {
    dominantRole = 'employee';
  } else if (user?.isHrManager) {
    dominantRole = 'hr';
  }

  switch (dominantRole) {
    case 'gmg':
      return <Statistics />;
    case 'approver':
      return <RelocatedEmployees />;
    case 'employee':
      return <Relocation />;
    default:
      return <Relocation />;
  }
};

export default RootPage;
