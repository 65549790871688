import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { ToastContainer, toast, Slide } from 'react-toastify';

import { getParsedMessage } from '../../../helpers';
import actionCreators from '../../../store/actions/actionCreators';

import 'react-toastify/dist/ReactToastify.css';

const Toast = () => {
  const dispatch = useDispatch();

  const toastInfo = useSelector((state) => state.toast, shallowEqual);

  useEffect(() => {
    switch (toastInfo.type) {
      case 'error':
        const message = getParsedMessage(toastInfo.message);
        return toast.error(message ? message : 'Something went wrong', {
          onClose: dispatch(actionCreators.toastReset()),
        });
      case 'success':
        return toast.success('Changes successfully saved', {
          onClose: dispatch(actionCreators.toastReset()),
        });
      case 'warning':
        return toast.warn(toastInfo.message ? toastInfo.message : 'Warning', {
          onClose: dispatch(actionCreators.toastReset()),
        });
      case 'info':
        return toast.info('Info', {
          onClose: dispatch(actionCreators.toastReset()),
        });
      case null:
        break;
      default:
        return toast.dark(toastInfo.message, {
          onClose: dispatch(actionCreators.toastReset()),
        });
    }
  }, [toastInfo, dispatch]);

  return (
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      transition={Slide}
      limit={10}
    />
  );
};

export default Toast;
