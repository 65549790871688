import { Field, ErrorMessage } from 'formik';
import './checkbox.scss';

const CheckboxReverse = (props) => {
  const { name, label, updateselectvalueroot, formikProps, formIndex, nameFieldArray, disabled } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const handleChange = (e) => {
    const value = e.target.checked ? true : false;
    formikProps.setFieldValue(fieldName, value);
    if (updateselectvalueroot) {
      updateselectvalueroot(value, formIndex, formikProps);
    }
  };
  return (
    <div className="form-group radio-line checkbox-reverse">
      <div className="custom-control custom-checkbox">
        <Field
          name={fieldName}
          className="custom-control-input"
          type="checkbox"
          id={fieldName}
          disabled={disabled}
          {...(updateselectvalueroot ? { onChange: handleChange } : {})}
        />
        <label className="custom-checkbox__label" htmlFor={fieldName}>
          <span></span>
        </label>
      </div>
      <label className="control-label" htmlFor={fieldName}>
        {label}
      </label>
      <ErrorMessage
        name={fieldName}
        className="field-error"
        render={(msg) => <div style={{ color: 'red' }}>{msg}</div>}
      />
    </div>
  );
};

export default CheckboxReverse;
