import { types } from '../actions/types';
import _ from 'lodash';

export const initialState = {
  feedbackForm: {
    name: '',
    description: '',
    fieldset: [],
    countries: [],
  },
};

const feedbackFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FEEDBACK_FORM_LOADED:
      return {
        feedbackForm: action.payload.feedbackForm,
      };
    case types.FEEDBACK_FORM_UPDATED:
      return {
        ...state,
        feedbackForm: _.cloneDeep(action.payload.feedbackForm),
      };
    default:
      return {
        ...state,
      };
  }
};

export default feedbackFormReducer;
