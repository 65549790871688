import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({});

export const validationNewRelSchema = Yup.object().shape({
  relationType: Yup.string().required('This field is required'),
  firstName: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-zA-Zа-яА-Я])/, 'First name must contain at least one letter')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'First name must contain only letters'),
  lastName: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-zA-Zа-яА-Я])/, 'Last name must contain at least one letter')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'Last name must contain only letters'),
  maidenName: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-zA-Zа-яА-Я])/, `Mother's maiden name must contain at least one letter`),
  birthDate: Yup.date().required('This field is required').nullable(),
  citizenshipId: Yup.string().required('This field is required').nullable(),
  nationalityId: Yup.string().required('This field is required').nullable(),
});

export const validationRelSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-zA-Zа-яА-Я])/, 'First name must contain at least one letter')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'First name must contain only letters'),
  lastName: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-zA-Zа-яА-Я])/, 'Last name must contain at least one letter')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'Last name must contain only letters'),
  maidenName: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-zA-Zа-яА-Я])/, `Mother's maiden name must contain at least one letter`),
  birthDate: Yup.date().required('This field is required').nullable(),
  citizenshipId: Yup.string().required('This field is required').nullable(),
  nationalityId: Yup.string().required('This field is required').nullable(),
});

export const validationSchemaITechArtEmployee = Yup.object().shape({
  employeeId: Yup.string().required('This field is required'),
});

export const initialValuesITechArtEmployee = {
  employeeId: '',
};
