import { useContext } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { ReactComponent as ManagerIcon } from '../../../assets/img/icons/suitcase.svg';
import { sendNotifyAboutGatheredVacToManager } from '../../../store/actions/request';
import { IsCaseDisabledContext } from '../RequestDetails';

import './contactManager.scss';
import { defaultDateFormat } from '../../../constants';

const allowedStatusesForEmployeeContact = [
  'Ready for employment',
  'Country legalization',
  'Relocation completed',
  'Completed',
];

const ContactManager = ({
  requestId,
  isManagerNotifiedAboutGatheredVac,
  managerNotifiedAt,
  employeeNotifiedAboutFinishedRelocationAt,
  status,
}) => {
  const dispatch = useDispatch();

  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;
  const tooltipTitle = `Email sent to manager: ${dayjs(managerNotifiedAt).format(
    `${displayDateFormat} hh:mm A (Z [UTC])`,
  )}`;
  const isDisabledButton = isCaseDisabled || isManagerNotifiedAboutGatheredVac;

  const handleSendNotifyToManager = () => {
    dispatch(sendNotifyAboutGatheredVacToManager(requestId));
  };

  const isContactEmployeeBtnActive = allowedStatusesForEmployeeContact.some(
    (item) => item.toLowerCase() === status.toLowerCase(),
  );

  const contactEmployeeTooltipTitle = `Email sent to employee: ${dayjs(
    employeeNotifiedAboutFinishedRelocationAt,
  ).format(`${displayDateFormat} hh:mm A (Z [UTC])`)}`;
  const isContactEmployeeBtnDisabled =
    employeeNotifiedAboutFinishedRelocationAt || !isContactEmployeeBtnActive || isCaseDisabled;

  return (
    <div className="contact-manager__container">
      <span className="contact-text">Contact</span>

      <Tooltip placement={'top'} title={managerNotifiedAt ? tooltipTitle : 'Contact manager'}>
        <span className={classNames('btn-with-tooltip__container', isDisabledButton && 'disabled-btn-with-tooltip')}>
          <button
            type="button"
            className={classNames(
              'button btn-form main-btn btn-back submit-btn',
              isDisabledButton && 'submit-btn-disabled',
            )}
            onClick={() => handleSendNotifyToManager()}
            disabled={isDisabledButton}
            style={{ pointerEvents: isDisabledButton ? 'none' : 'unset' }}
          >
            <ManagerIcon />
          </button>
        </span>
      </Tooltip>

      <Tooltip
        placement={'top'}
        title={employeeNotifiedAboutFinishedRelocationAt ? contactEmployeeTooltipTitle : 'Contact employee'}
      >
        {isContactEmployeeBtnDisabled ? (
          <span className={classNames(isContactEmployeeBtnDisabled && 'disabled-btn-with-tooltip')}>
            <button
              type="button"
              className={classNames(
                'button btn-form main-btn btn-back submit-btn',
                isContactEmployeeBtnDisabled && 'submit-btn-disabled',
              )}
              disabled={isContactEmployeeBtnDisabled}
              style={{ pointerEvents: isContactEmployeeBtnDisabled ? 'none' : 'unset' }}
            >
              <UserOutlined />
            </button>
          </span>
        ) : (
          <Link
            to={{
              pathname: `/contact-employee/${requestId}`,
            }}
          >
            <button
              type="button"
              className={classNames(
                'button btn-form main-btn btn-back submit-btn',
                isContactEmployeeBtnDisabled && 'submit-btn-disabled',
              )}
              disabled={isContactEmployeeBtnDisabled}
              style={{ pointerEvents: isContactEmployeeBtnDisabled ? 'none' : 'unset' }}
            >
              <UserOutlined />
            </button>
          </Link>
        )}
      </Tooltip>
    </div>
  );
};

export default ContactManager;
