import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import classNames from 'classnames';

import { upsertRelatedVacAppointments, sendVACdetailsRelated } from '../../../store/actions/requestDetails';
import VacField from './VacField/VacField';
import { getAddressInfoOptions, getValuesToUpdate, setAllValuesToNull } from '../../../helpers';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import {
  chekboxesSchema,
  formSchema,
  validationSchema,
  visaNotesSchema,
  vacAppointmentInitialValues,
} from './formSchema';
import { getVacAppointmentAddresses } from '../../../store/actions/requestVacAppointment';

const VacAppointment = (props) => {
  const { vacAppointment, requestId, relatedReqId, relationType, isCaseDisabled } = props;

  const dispatch = useDispatch();

  const vacInfo = vacAppointment
    .map((item) => ({ ...item }))
    .sort((a, b) => a.id - b.id)
    .sort((a, b) => (a.isCurrent === b.isCurrent ? 0 : a.isCurrent ? -1 : 1));

  vacInfo.forEach((item) => {
    item.time = {
      startTime: item?.mainTime || null,
      endTime: item?.possibleTime || null,
    };
  });

  const initialValues = {
    vacInfo: vacAppointment.length ? vacInfo : [vacAppointmentInitialValues],
  };

  const addressesInfo = useSelector((state) => state.vacAppointmentReducer.addressesInfo);

  const [addressInfoOptions, setAddressInfoOptions] = useState(getAddressInfoOptions(addressesInfo));
  const cityOptions = [
    ...new Map(addressesInfo.map((item) => [item.city?.id, { key: item.city?.id, value: item.city?.name }])).values(),
  ];

  useEffect(() => {
    setAddressInfoOptions(getAddressInfoOptions(addressesInfo));
  }, [addressesInfo]);

  useEffect(() => {
    if (!addressesInfo?.length) {
      dispatch(getVacAppointmentAddresses());
    }
  }, [dispatch, addressesInfo?.length]);

  const onChangeSelectedAddress = (addressInfoId, formikProps, formIndex) => {
    const selectedCity = addressesInfo.find((item) => item.id === addressInfoId).city?.name;

    formikProps.setFieldValue(`vacInfo.${formIndex}.addressInfo.city.name`, selectedCity);
  };

  const setIsCurrentVacAppointment = (value, formIndex, formikProps) => {
    if (value) {
      if (formIndex >= 0) {
        formikProps.values.vacInfo.forEach((item, index) => {
          if (index !== formIndex) {
            formikProps.setFieldValue(`vacInfo.${index}.isCurrent`, false);
          }
        });
      } else {
        formikProps.values.vacInfo.forEach((item, index) => {
          if (index !== formikProps.values.vacInfo.length - 1) {
            formikProps.setFieldValue(`vacInfo.${index}.isCurrent`, false);
          }
        });
      }
    }
  };

  formSchema.forEach((item) => {
    if (item.fieldName === 'addressInfo.id') {
      item.options = addressInfoOptions;
      item.updateselectvalueroot = onChangeSelectedAddress;
    }
    if (item.fieldName === 'addressInfo.city.name') {
      item.options = cityOptions;
    }
    if (item.fieldName === 'isCurrent') {
      item.updateselectvalueroot = setIsCurrentVacAppointment;
    }
  });

  chekboxesSchema.forEach((item) => {
    if (item.fieldName === 'isDocsGatheredNew') {
      item.label = `${relationType ? relationType[0].toUpperCase() + relationType.slice(1) : ''} docs are gathered`;
    }
  });

  const currentVAC = vacInfo.find((item) => item.isCurrent === true);

  const isDisplaySendVacDetails = !currentVAC?.isEmployeeNotifiedNew?.value && !currentVAC?.isDuplicated;
  const isEmployeeNotifiedForCurrentVAC = currentVAC ? currentVAC.isEmployeeNotifiedNew?.value : true;

  const handleSendVACdetails = () => {
    dispatch(sendVACdetailsRelated(currentVAC.id, relatedReqId));
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    const vacInfo = values.vacInfo.map((vacInfo, formIndex) => {
      vacInfo.addressInfoId = vacInfo.addressInfo?.id;

      vacInfo.mainTime = vacInfo.time.startTime;
      vacInfo.possibleTime = vacInfo.time.endTime;

      return vacInfo;
    });

    const valuesToUpdate = getValuesToUpdate(vacInfo, vacAppointment, Object.keys(vacAppointmentInitialValues));

    const vacAppointmentIsNotCurrent = getValuesToUpdate(vacInfo, vacAppointment, ['isCurrent']).filter(
      (item) => item.isCurrent === false,
    );

    const valuesToAdd = vacInfo.filter(({ id }) => isNaN(id));

    const valuesToUpdateWithNullValues = setAllValuesToNull(valuesToUpdate);
    const vacAppointmentIsNotCurrentWithNullValues = setAllValuesToNull(vacAppointmentIsNotCurrent);
    const valuesToAddWithNullValues = setAllValuesToNull(valuesToAdd);

    try {
      if (
        valuesToUpdateWithNullValues.length ||
        valuesToAddWithNullValues.length ||
        vacAppointmentIsNotCurrentWithNullValues.length
      ) {
        dispatch(
          upsertRelatedVacAppointments(
            valuesToUpdateWithNullValues,
            valuesToAddWithNullValues,
            vacAppointmentIsNotCurrentWithNullValues,
            requestId,
            relatedReqId,
          ),
        );
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="VacAppointment">
      <>
        {vacAppointment && (
          <>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {(formikProps) => {
                return (
                  <Form>
                    <FieldArray
                      name="vacInfo"
                      render={({ remove, push }) => (
                        <>
                          <VacField
                            values={formikProps.values}
                            formSchema={formSchema}
                            chekboxesSchema={chekboxesSchema}
                            visaNotesSchema={visaNotesSchema}
                            formikProps={formikProps}
                            relatedReqId={relatedReqId}
                            remove={remove}
                          />
                          <div className="button-wrapper">
                            <SubmitButton title="Submit" formikProps={formikProps} />
                            <button
                              type="button"
                              className="button btn-form main-btn"
                              onClick={() => {
                                push(vacAppointmentInitialValues);
                                setIsCurrentVacAppointment(true, null, formikProps);
                              }}
                            >
                              Add VAC
                            </button>
                            {isDisplaySendVacDetails && vacInfo.length > 0 && (
                              <button
                                type="button"
                                className={classNames(
                                  'button btn-form main-btn btn-back',
                                  isCaseDisabled || isEmployeeNotifiedForCurrentVAC ? 'btn-disabled' : '',
                                )}
                                onClick={() => handleSendVACdetails()}
                                disabled={isCaseDisabled || isEmployeeNotifiedForCurrentVAC}
                              >
                                Send VAC details to Employee
                              </button>
                            )}
                          </div>
                        </>
                      )}
                    />
                  </Form>
                );
              }}
            </Formik>
          </>
        )}
      </>
    </div>
  );
};

export default VacAppointment;
