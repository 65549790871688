import TitleWithAdditionalInfo from '../Shared/Table/TitleWithAdditionalInfo/TitleWithAdditionalInfo';
import TitleWithSort from '../Shared/Table/TitleWithSort/TitleWithSort';
import FilterDropdownIcon from '../Shared/Table/FilterIcon/FilterIcon';
import DateRangeFilter from '../Shared/Table/DateRangeFilter/DateRangeFilter';

export const columns = [
  {
    title: (props) => (
      <TitleWithAdditionalInfo
        {...props}
        title={'Case/Profile ID'}
        additionalInfo={'The following abbreviation is used for this column:<ol><br><li>C: Case<br><li>P: Profile</ol>'}
      />
    ),
    key: 'id',
    dataIndex: 'id',
    fixed: 'left',
    width: '12%',
  },
  {
    key: 'employeeName',
    title: 'Employee name',
    dataIndex: 'employeeName',
    width: '12%',
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'authorName'} title={'Author'} />,
    key: 'authorName',
    dataIndex: 'authorName',
    width: '12%',
    sorter: {
      multiple: 1,
    },
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    sortOrder: false,
    filteredValue: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'dateReminder'} title={'Reminder date'} />,
    sorter: {
      multiple: 1,
    },
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <DateRangeFilter {...props} />,
    key: 'dateReminder',
    dataIndex: 'dateReminder',
    width: '12%',
    sortOrder: false,
    filteredValue: null,
  },
  { key: 'reminderText', title: 'Reminder text', dataIndex: 'reminderText', width: '38%' },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'statusReminder'} title={'Status'} />,
    key: 'statusReminder',
    dataIndex: 'statusReminder',
    width: '16%',
    sorter: {
      multiple: 1,
    },
    sortOrder: false,
  },
];

export const sortMapping = {
  authorName: 'author_name',
  dateReminder: 'reminder_date',
  statusReminder: 'reminder_status',
  requestId: 'case_id',
  employeeId: 'employee_id',
};

export const filterMapping = {
  authorName: 'author_name',
  dateReminder: 'dateReminder',
};

export const dateRangeFilterMapping = ['dateReminder'];

export const statusReminderOptions = [
  { value: 'Active', text: 'Active', label: 'Active' },
  { value: 'Completed', text: 'Completed', label: 'Completed' },
];
