import parse from 'html-react-parser';

import '../guidline.scss';

const GuideItem = ({ img, title, number }) => {
  return (
    <div className="guid-item">
      <div className="guid-item__number">
        <p className="guid-item__count">{number}.</p>
        <img className="guid-item__img" src={img} alt="pic" />
      </div>
      <div className="guid-item__text">{parse(title)}</div>
    </div>
  );
};

export default GuideItem;
