import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTemplate } from '../../../../store/actions/emailTemplates';
import { initialValues as initialValuesEmpty } from './schemaProperties';
import SendEmail from './SendEmail';

const SendEmailRouter = ({ match, employees }) => {
  const templateId = match.params.id;

  const dispatch = useDispatch();

  const template = useSelector((state) => state.emailTemplates.emailTemplate[templateId]);

  const initialValues = templateId
    ? {
        emailList: [],
        ccList: [],
        subject: template?.subject || '',
        message: template?.body || '',
      }
    : initialValuesEmpty;

  useEffect(() => {
    if (templateId && !template) {
      dispatch(getTemplate(templateId));
    }
  }, [templateId]);
  return <SendEmail employees={employees} initialValues={initialValues} id={templateId} />;
};

export default SendEmailRouter;
