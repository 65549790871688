import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';

import actionCreators from '../../../../../../store/actions/actionCreators';
import { SubmitButton } from '../../../../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../../../../Shared/Form/FormElements';
import { setCommentOnEmployeeDocumentsPage } from '../../../../../../store/actions/employeeComments';
import { formSchema } from './formSchema';

import './comments.scss';

const Comments = ({ employeeId, documentRequest, pageType }) => {
  const dispatch = useDispatch();

  const documentRequestId = documentRequest?.id;

  const commentId = documentRequest?.comment?.id;
  const commentText = documentRequest?.comment?.text || '';

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    dispatch(setCommentOnEmployeeDocumentsPage(documentRequestId, commentId, pageType, values.text))
      .then((response) => {
        switch (pageType) {
          case 'profile':
            return dispatch(actionCreators.profileEmployeeCommentUpdate(employeeId, response));
          case 'case':
            return dispatch(actionCreators.requestEmployeeCommentUpdate(response));
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className={'comments'}>
      <div className={'comments-text'}>
        Feel free to leave your comments for the Global Mobility team in case you’re changing your passport or need more
        time to gather the requested documents.{' '}
      </div>
      <Formik enableReinitialize initialValues={{ text: commentText }} onSubmit={onSubmit}>
        {(formikProps) => (
          <Form>
            {formSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
            ))}
            <SubmitButton title={'Leave comment'} formikProps={formikProps} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Comments;
