import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import QuickButtonsBlock from '../Shared/QuickButtonsBlock/QuickButtonsBlock';
import './legalizationRequest.scss';

import { FieldArray, Form, Formik } from 'formik';
import { getFormElement } from '../Shared/Form/FormElements';
import { SubmitButton } from '../Shared/Form/Buttons/Buttons';

import axios from '../../axios';
import { useGetInitialValues } from '../Feedback/EmployeeFeedbackForm/hooks';
import { getCountriesWithCompanies } from '../../store/actions/countriesForRelocation';

const DisabledLegalizationRequest = ({ match }) => {
  const dispatch = useDispatch();

  const legalizationRequestId = match.params.id;

  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const getFeedbackForm = async () => {
    try {
      const response = await axios.get(`/api/v1/legalization-request/${legalizationRequestId}/`);
      // dispatch(actionCreators.feedbackFormLoaded(response.data));
      setFeedbackForms(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  const [isLoading, setLoading] = useState(true);
  const [feedbackForms, setFeedbackForms] = useState([]);
  const [initialValues, setInitialValues] = useState({});

  // useEffect(() => {
  //   getEmployeeFeedbackForms(requestId)
  //     .then((forms) => {
  //       setFeedbackForms(forms);
  //       setLoading(false);
  //     })
  //     .catch(() => setLoading(false));
  // }, [dispatch, requestId]);

  // const countries = useSelector((state) => state.countries.countriesList);
  //
  // useEffect(() => {
  //   if (!countries?.length) {
  //     dispatch(getCountries());
  //   }
  // }, [countries?.length]);

  useEffect(() => {
    getFeedbackForm().then((r) => {
      setFeedbackForms(r.forms);
      setInitialValues({
        countryId: r.country?.name,
        forWhom: r.formsType,
        deadline: r.deadline,
        comments: r.comments,
        ...useGetInitialValues(r.forms),
      });
    });
  }, [selectedCountryId, selectedOption]);

  // formSchema.forEach((item) => {
  //   if (item.fieldName === 'countryId') {
  //     item.options = countries.map((country) => {
  //       return {
  //         text: country.name,
  //         key: country.id,
  //         value: country.id,
  //       };
  //     });
  //   }
  // });

  const useGetInitialValues = (feedbackForms) => {
    return {
      legalizationRequest: feedbackForms.map((item) => {
        const form = item.forms[0];
        form.fields.forEach((field) => {
          if (
            field.disabled &&
            (form.values[field.fieldName] === '' ||
              form.values[field.fieldName] === null ||
              form.values[field.fieldName] === 0 ||
              form.values[field.fieldName]?.length === 0)
          ) {
            delete form.values[field.fieldName];
          }
        });
        return {
          ...form.values,
          generic_form_id: item.id,
          form_id: form.formId,
        };
      }),
    };
  };

  const formSchema = [
    // {
    //   fieldName: 'countryId',
    //   type: 'TextInput',
    //   label: 'Country',
    //   required: true,
    //   options: [],
    // },
    {
      fieldName: 'forWhom',
      type: 'Dropdown',
      label: 'Legalization documents are required for',
      required: true,
      options: [
        {
          value: 'employee',
          text: 'Me',
        },
        {
          value: 'employee_and_family',
          text: 'Me and my family',
        },
        {
          value: 'family',
          text: 'Only family members',
        },
      ],
    },
  ];

  const formSchema2 = [
    {
      fieldName: 'deadline',
      type: 'DatePicker',
      label: 'Deadline: Date of submission of documents (if known).',
    },
    {
      fieldName: 'comments',
      type: 'TextArea',
      label: 'Comments: please provide your comments for the Global Mobility Team, if necessary.',
    },
  ];

  return (
    <section className="legalization-request">
      <>
        <div className="questionnaire-answer">
          {/*<Formik enableReinitialize initialValues={{}} onSubmit={() => {}}>*/}
          {/*  {(formikProps) => (*/}
          {/*    <>*/}
          {/*      <Form>*/}
          {/*        */}
          {/*      </Form>*/}
          {/*    </>*/}
          {/*  )}*/}
          {/*</Formik>*/}
          <Formik enableReinitialize initialValues={initialValues} onSubmit={() => console.log('')}>
            {(formikProps) => (
              <>
                <Form>
                  <FieldArray
                    name={'legalizationRequest'}
                    render={({ remove, push, insert }) => (
                      <>
                        <div className="question-block">
                          <h3 className="question-name underlined">Legalization request</h3>
                          {formSchema.map((field) => (
                            <div key={field.fieldName} className="question-answer">
                              {getFormElement(field.type, field, formikProps, true)}
                              <hr className={'answer__divider'}></hr>
                            </div>
                          ))}
                        </div>
                        <div className="question-block">
                          {/*{formSchema.map((field) => (*/}
                          {/*  <div key={field.fieldName} className="question-answer">*/}
                          {/*    {getFormElement(field.type, field, formikProps)}*/}
                          {/*    <hr className={'answer__divider'}></hr>*/}
                          {/*  </div>*/}
                          {/*))}*/}
                          {feedbackForms.length > 0 &&
                            feedbackForms.map((form, formIndex) => (
                              <div className="question-block" key={formIndex}>
                                {form.forms[0].fields.map((field, fieldIndex) => (
                                  <div key={field.fieldName} className="question-answer">
                                    {getFormElement(
                                      field.element,
                                      {
                                        nameFieldArray: 'legalizationRequest',
                                        formIndex,
                                        ...field,
                                      },
                                      formikProps,
                                      true,
                                    )}
                                    <hr className={'answer__divider'}></hr>
                                  </div>
                                ))}
                                {formSchema2.map((field) => (
                                  <div key={field.fieldName} className="question-answer">
                                    {getFormElement(field.type, field, formikProps, true)}
                                    <hr className={'answer__divider'}></hr>
                                  </div>
                                ))}
                              </div>
                            ))}
                          <div className="submit-btn__container"></div>
                        </div>
                      </>
                    )}
                  />
                </Form>
              </>
            )}
          </Formik>
          {/*<LegalizationRequestForm feedbackForms={feedbackForms} requestId={requestId} />*/}
        </div>
        <QuickButtonsBlock />
      </>
      {/*)}*/}
      {/*{!isLoading && feedbackForms.length === 0 && <p>No available feedback forms</p>}*/}
      {/*{isLoading && <SpinnerContainer />}*/}
    </section>
  );
};
export default DisabledLegalizationRequest;
