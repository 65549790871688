import { NavLink } from 'react-router-dom';

export const sidebarNavigation = [
  // {
  //   label: (
  //     <NavLink to={'./general-information'} className="menu-item">
  //       Overview
  //     </NavLink>
  //   ),
  //   key: 'generalInfo',
  //   url: '/general-information',
  // },
  // {
  //   label: (
  //     <NavLink to={'./contact-information'} className="menu-item">
  //       Contact information
  //     </NavLink>
  //   ),
  //   key: 'contactInfo',
  //   url: '/contact-information',
  // },
  // {
  //   label: (
  //     <NavLink to={'./employment-information'} className="menu-item">
  //       Employment information
  //     </NavLink>
  //   ),
  //   key: 'employmentInformation',
  //   url: '/employment-information',
  // },
  // {
  //   label: 'Employee documents',
  //   key: 'employeeDocuments',
  //   children: [
  //     {
  //       label: (
  //         <NavLink to={'./employee-documents'} className="menu-item">
  //           Documents
  //         </NavLink>
  //       ),
  //       key: 'documents',
  //       url: '/employee-documents',
  //     },
  //     {
  //       label: (
  //         <NavLink to={'./employee-forms'} className="menu-item">
  //           Forms
  //         </NavLink>
  //       ),
  //       key: 'employeeForms',
  //       url: '/employee-forms',
  //     },
  //     {
  //       label: (
  //         <NavLink to={'./request-documents'} className="menu-item">
  //           Request documents
  //         </NavLink>
  //       ),
  //       key: 'requestDocuments',
  //       url: '/request-documents',
  //     },
  //     {
  //       label: (
  //         <NavLink to={'./legalization-steps'} className="menu-item">
  //           Legalization steps
  //         </NavLink>
  //       ),
  //       key: 'legalizationSteps',
  //       url: '/legalization-steps',
  //     },
  //   ],
  // },
  // {
  //   label: (
  //     <NavLink to={'./archived-documents'} className="menu-item">
  //       Archived documents
  //     </NavLink>
  //   ),
  //   key: 'archivedDocuments',
  //   url: '/archived-documents',
  // },
  // {
  //   label: (
  //     <NavLink to={'./employee-comments'} className="menu-item">
  //       Employee comments
  //     </NavLink>
  //   ),
  //   key: 'employeeComments',
  //   url: '/employee-comments',
  // },
  // {
  //   label: (
  //     <NavLink to={'./request'} className="menu-item">
  //       Legalization request
  //     </NavLink>
  //   ),
  //   key: 'legalizationRequest',
  //   url: '/request',
  // },
  // {
  //   label: (
  //     <NavLink to={'./history'} className="menu-item">
  //       Requests history
  //     </NavLink>
  //   ),
  //   key: 'history',
  //   url: '/history',
  // },
  {
    label: 'Legalization',
    key: 'legalization',
    children: [
      {
        label: (
          <NavLink to={'./legalization-request'} className="menu-item">
            Request
          </NavLink>
        ),
        key: 'legalizationRequest',
        url: '/legalization-request',
      },
      {
        label: (
          <NavLink to={'./legalization-requests'} className="menu-item">
            All requests
          </NavLink>
        ),
        key: 'legalizationRequests',
        url: '/legalization-requests',
      },
    ],
  },
];
