import { Tooltip } from 'antd';
import { useState } from 'react';

import GmgResponsible from '../GmgResponsible/GmgResponsible';
import Status from '../Status/Status';
import NotesModal from '../../Common/NotesModal/NotesModal';
import notesImg from '../../../assets/img/note.svg';
import { getCaseTypeTitle } from '../MainInfoTop/MainInfoTop';

import './infoTopRight.scss';

const InfoTopRight = (props) => {
  const { requestId, gmtMember, statusNote, status, gmgResponsible, employeeId, caseType, isEmployeeRelocated } = props;

  const [isModalVisible, setModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const caseTypeCalculated = getCaseTypeTitle(caseType, isEmployeeRelocated);

  return (
    <div className="additional-info-top">
      <GmgResponsible
        requestId={requestId}
        gmtMember={gmtMember}
        gmtType={'case_gmt_member'}
        gmgResponsible={gmgResponsible}
        isClearable={false}
      />
      <div className="additional-info-top__status-container">
        {statusNote && (
          <>
            <Tooltip placement={'top'} title={'Show notes'} zIndex={10}>
              <div className="additional-info-top__show-notes">
                <img
                  className="notes-img"
                  src={notesImg}
                  alt="notes"
                  onClick={() => {
                    setModalVisible(true);
                    setCurrentTab(0);
                  }}
                />
              </div>
            </Tooltip>
          </>
        )}
        <Status requestId={requestId} status={status} caseType={caseTypeCalculated} />
      </div>
      <div>
        <NotesModal
          requestNote={statusNote}
          requestId={requestId}
          employeeId={employeeId}
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
      </div>
    </div>
  );
};

export default InfoTopRight;
