import classNames from 'classnames';
import { Field, ErrorMessage } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import './textarea.scss';

const AutoSizeTextArea = ({ field, form, ...props }) => {
  return <TextareaAutosize {...field} {...props} maxRows={25} />;
};

const TextareaAsInput = (props) => {
  const {
    name,
    label,
    placeholder,
    formIndex,
    nameFieldArray,
    formikProps,
    canHaveDisplayHorizontal,
    isRequired,
    ...rest
  } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  return (
    <div className="small-textarea-group form-group column">
      <div className="label-message control-label">
        {label && (
          <label className={classNames({ required: isRequired })} htmlFor={fieldName}>
            {label}
          </label>
        )}
      </div>
      <div className="form-textarea form-input-width">
        <Field
          className="form-control"
          component={AutoSizeTextArea}
          name={fieldName}
          id={fieldName}
          placeholder={placeholder || ''}
          {...rest}
        />
        <ErrorMessage
          name={fieldName}
          render={(msg) => (
            <span className="error-textarea" style={{ color: 'red' }}>
              {msg}
            </span>
          )}
        />
      </div>
    </div>
  );
};

export default TextareaAsInput;
