import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import useWindowDimensions from '../../../../Shared/Hooks/useWindowDimensions';
import './PopUp.scss';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import SettingsModal from '../Settings/SettingsModal/SettingsModal';
import { useSelector } from 'react-redux';

const Popup = (props) => {
  const { isApproverStaff, isPortalStaff, isHrManager, closePopUp, burgerMenuRef, isPopUpShow } = props;

  const user = useSelector((state) => state.auth.user);

  const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;

  const { width } = useWindowDimensions();

  const [isModalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (isPopUpShow && width <= 768) {
      document.body.style.overflow = 'hidden';
    }
  }, [isPopUpShow]);

  useEffect(() => {
    return () => {
      document.body.style.overflow = 'unset';
    };
  });

  const handleLogOut = (e) => {
    e.preventDefault();
    closePopUp();
    logout();
  };

  const history = useHistory();
  const logout = useCallback(() => history.push('/auth/signout-redirect'), [history]);

  const wrapperRef = useRef();

  const handleClickOutside = useCallback(
    (e) => {
      if (
        wrapperRef &&
        !wrapperRef.current.contains(e.target) &&
        burgerMenuRef &&
        !burgerMenuRef.current.contains(e.target)
      ) {
        closePopUp();
      }
    },
    [closePopUp, burgerMenuRef],
  );

  useEffect(() => {
    if (isPopUpShow) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside, isPopUpShow]);

  const onClick = (e) => {
    e.preventDefault();
    closePopUp();
    logout();
  };

  return (
    <nav className={classNames('pop-up', isPopUpShow ? 'pop-up__active' : 'pop-up__disabled')} ref={wrapperRef}>
      <Menu
        selectable={false}
        style={{ width: 220 }}
        mode="vertical"
        // items={items}
      >
        <Menu.Item key="register">
          <Link
            to={
              isPortalStaff ? `/profile/${user.uuid}/general-information` : `/profile/${user.uuid}/legalization-request`
            }
            onClick={closePopUp}
          >
            <span className="nav-text">My profile</span>
          </Link>
        </Menu.Item>
        <Menu.Divider />
        {isPortalStaff && (
          <>
            <Menu.Item key="main">
              <Link to="/relocation" onClick={closePopUp}>
                <span className="nav-text">Main page</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="statistics">
              <Link to="/statistics" onClick={closePopUp}>
                <span className="nav-text">Statistics</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="legalization-dashboard">
              <Link to="/legalization-dashboard" onClick={closePopUp}>
                <span className="nav-text">Legalization dashboard</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="feedback">
              <Link to="/feedback-dashboard" onClick={closePopUp}>
                <span className="nav-text">Feedback dashboard</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="reminders">
              <Link to="/reminders" onClick={closePopUp}>
                <span className="nav-text">Reminder dashboard</span>
              </Link>
            </Menu.Item>

            <Menu.Divider />
          </>
        )}
        {isPortalStaff && (
          <>
            <Menu.Item key="form-builder">
              <Link to="/form-builder" onClick={closePopUp}>
                <span className="nav-text">Constructor</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="relocation-costs">
              <Link to="/relocation-costs" onClick={closePopUp}>
                <span className="nav-text">Relocation costs</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="relocation-routes">
              <Link to="/relocation-routes" onClick={closePopUp}>
                <span className="nav-text">Relocation routes</span>
              </Link>
            </Menu.Item>

            <Menu.Divider />
          </>
        )}
        {isHrManager && !isPortalStaff && (
          <>
            <Menu.Item
              key="settings"
              onClick={() => {
                setModalVisible(true);
              }}
            >
              <span className="nav-text">Settings</span>
            </Menu.Item>
            <Menu.Divider />
          </>
        )}
        {isPortalStaff && (
          <>
            <Menu.Item
              key="settings"
              onClick={() => {
                setModalVisible(true);
              }}
            >
              <span className="nav-text">Settings</span>
            </Menu.Item>
            <Menu.Item key="admin-panel">
              <Link target="_blank" rel="noopener noreferrer" to={{ pathname: ADMIN_URL + '/secret-admin/' }}>
                <span className="nav-text">Admin panel</span>
              </Link>
            </Menu.Item>
            <Menu.Divider />
          </>
        )}
        <Menu.Item key="log-out">
          <Link to={'/login'} onClick={handleLogOut}>
            <span className="nav-text log-out">Log out</span>
          </Link>
        </Menu.Item>
      </Menu>
      {/*<ul className="nav-list">*/}
      {/*  {isPortalStaff && (*/}
      {/*    <li className="mobile">*/}
      {/*      <Link to={'/cases'} className="desctop" onClick={closePopUp}>*/}
      {/*        Cases*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {(isPortalStaff || isApproverStaff) && (*/}
      {/*    <li className="mobile">*/}
      {/*      <Link to={'/relocated-employees'} className="desctop" onClick={closePopUp}>*/}
      {/*        Relocated employees*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {isPortalStaff && (*/}
      {/*    <li className="mobile">*/}
      {/*      <Link to={'/visa-only'} className="desctop" onClick={closePopUp}>*/}
      {/*        Visa only*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {(isPortalStaff || isApproverStaff || isHrManager) && (*/}
      {/*    <li className="mobile">*/}
      {/*      <Link to={'/legalization-documents'} className="desctop" onClick={closePopUp}>*/}
      {/*        Documents*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {isPortalStaff && (*/}
      {/*    <li className="mobile">*/}
      {/*      <Link to={'/reminders'} className="desctop" onClick={closePopUp}>*/}
      {/*        Reminders*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {isPortalStaff && (*/}
      {/*    <li>*/}
      {/*      <Link to={'/statistic'} onClick={closePopUp}>*/}
      {/*        Statistics*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {isPortalStaff && (*/}
      {/*    <li className="desktop">*/}
      {/*      <Link to={'/form-builder'} onClick={closePopUp}>*/}
      {/*        Constructor*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {(isPortalStaff || isApproverStaff) && (*/}
      {/*    <li>*/}
      {/*      <Link to={'/feedback-dashboard'} onClick={closePopUp}>*/}
      {/*        Feedback*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {(isPortalStaff || isApproverStaff) && (*/}
      {/*    <li>*/}
      {/*      <Link to={'/relocation-costs'} onClick={closePopUp}>*/}
      {/*        Relocation costs*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {(isPortalStaff || isApproverStaff) && (*/}
      {/*    <li>*/}
      {/*      <Link to={'/relocation-routes'} onClick={closePopUp}>*/}
      {/*        Relocation routes*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {(isPortalStaff || isApproverStaff) && (*/}
      {/*    <li>*/}
      {/*      <Link to={'/relocation'} onClick={closePopUp}>*/}
      {/*        Main*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {isPortalStaff && (*/}
      {/*    <li>*/}
      {/*      <Link*/}
      {/*        target="_blank"*/}
      {/*        rel="noopener noreferrer"*/}
      {/*        to={{ pathname: ADMIN_URL + '/secret-admin/' }}*/}
      {/*        onClick={closePopUp}*/}
      {/*      >*/}
      {/*        Admin*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  )}*/}
      {/*  {!isPortalStaff && !isApproverStaff && (*/}
      {/*    <>*/}
      {/*      <li>*/}
      {/*        <Link to={'/relocation/about/Poland'} onClick={closePopUp}>*/}
      {/*          Poland*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*      <li>*/}
      {/*        <Link to={'/relocation/about/Ukraine'} onClick={closePopUp}>*/}
      {/*          Ukraine*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*    </>*/}
      {/*  )}*/}
      {/*  <li>*/}
      {/*    <Link to={'/login'} onClick={onClick}>*/}
      {/*      Log out*/}
      {/*    </Link>*/}
      {/*  </li>*/}
      {/*</ul>*/}

      {isModalVisible && <SettingsModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} />}
    </nav>
  );
};

export default Popup;
