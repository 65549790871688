import * as Yup from 'yup';

const EmploymentInfoValidationSchema = Yup.object().shape({
  phoneNumbers: Yup.array().of(
    Yup.string()
      .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/, 'Please enter correct phone number')
      .typeError('Please enter correct phone number')
      .required('Please enter phone number'),
  ),
  company: Yup.string().required('This field is required'),
  position: Yup.string().required('This field is required'),
});
export default EmploymentInfoValidationSchema;
