import React, { useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';

import ModalConfirm from '../../../Common/ModalConfirm/ModalConfirm';
import ArchiveIcon from '../../../../assets/img/icons/Archive.svg';

const ArchiveForm = ({ toggle, archiveForm }) => {
  const [isArchveModalVisible, setArchveModalVisible] = useState(false);

  const handleArchiveForm = () => {
    archiveForm();
    setArchveModalVisible(false);
  };
  return (
    <>
      <Tooltip placement={'top'} title={'Archive form'} mouseEnterDelay={0} mouseLeaveDelay={0} zIndex={10}>
        <button className={classNames('shareButton ico', { open: toggle }, { close: !toggle })} type="button">
          <div className="tooltip-button">
            <img className="archive-icon" src={ArchiveIcon} alt="archive" onClick={() => setArchveModalVisible(true)} />
          </div>
        </button>
      </Tooltip>
      {isArchveModalVisible && (
        <ModalConfirm
          isModalVisible={isArchveModalVisible}
          setModalVisible={setArchveModalVisible}
          confirmationQuestion={'Are you sure you want to archive this form?'}
          handleClick={handleArchiveForm}
        />
      )}
    </>
  );
};

export default ArchiveForm;
