import { useEffect, useState } from 'react';
import { Dropdown, Space } from 'antd';
import classNames from 'classnames';

import './toggleFilter.scss';

const ToggleFilter = ({
  candidateEmployeeToggleParam,
  setCandidateEmployeeToggleParam,
  tableKey,
  items,
  countByGroups,
}) => {
  const [toggleFilterValue, setToggleFilterValue] = useState(
    items.find((item) => item.key === candidateEmployeeToggleParam) || '',
  );

  useEffect(() => {
    setToggleFilterValue(items.find((item) => item.key === candidateEmployeeToggleParam) || '');
  }, [candidateEmployeeToggleParam]);

  const handleChangeFilter = (newValue) => {
    const value = newValue.key === 'all' ? '' : newValue.key;

    sessionStorage.setItem(
      tableKey,
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem(tableKey)),
        toggle: value,
      }),
    );

    setCandidateEmployeeToggleParam(value);
  };

  const menuProps = {
    items,
    onClick: handleChangeFilter,
  };

  return (
    <div className="toggle-filter__container">
      <Dropdown className="toggle-filter__container" menu={menuProps} trigger={['click']}>
        <div className={classNames('toggle-filter__container-button', { filterActive: toggleFilterValue })}>
          <Space>
            <svg width="16" height="9" viewBox="0 0 16 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="16" height="1" rx="0.5" />
              <rect x="2" y="4" width="12" height="1" rx="0.5" />
              <rect x="4" y="8" width="8" height="1" rx="0.5" />
            </svg>

            {toggleFilterValue?.name
              ? `${toggleFilterValue?.name} (${countByGroups[toggleFilterValue?.key]}) `
              : 'Filter'}
          </Space>
        </div>
      </Dropdown>
    </div>
  );
};

export default ToggleFilter;
