import { useState } from 'react';
import { Tooltip } from 'antd';
import classNames from 'classnames';

import ModalConfirm from '../ModalConfirm/ModalConfirm';
import ArchiveIcon from '../../../assets/img/icons/Archive.svg';

import './archiveForm.scss';

const ArchiveForm = (props) => {
  const { archiveForm, type, petDocuments, isCaseDisabled } = props;

  const [isModalVisible, setModalVisible] = useState(false);

  const handleArchiveForm = () => {
    archiveForm();
    setModalVisible(false);
  };

  return (
    <>
      {type === 'unarchive' && (
        <button
          type="button"
          className={classNames('button btn-form main-btn red', isCaseDisabled ? 'btn-disabled' : '')}
          onClick={() => setModalVisible(true)}
          disabled={isCaseDisabled}
        >
          Unarchive form
        </button>
      )}
      {type !== 'unarchive' && !petDocuments && (
        <>
          <Tooltip placement={'top'} title={'Add to archive'} zIndex={10}>
            <div className="tooltip-button" data-tip="Add to archive" data-for="archive">
              <img className="archive-icon" src={ArchiveIcon} alt="archive" onClick={() => setModalVisible(true)} />
            </div>
          </Tooltip>
        </>
      )}
      {isModalVisible && (
        <ModalConfirm
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          confirmationQuestion={
            type === 'unarchive'
              ? 'Are you sure you want to unarchive this form?'
              : 'Are you sure you want to archive this form?'
          }
          handleClick={handleArchiveForm}
        />
      )}
    </>
  );
};

export default ArchiveForm;
