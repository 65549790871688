import CaseProgress from './CaseProgress/CaseProgress';
import SelectTabsDisplay from './SelectTabsDisplay/SelectTabsDisplay';
import './controller.scss';

const Controller = ({ requestId, tabsInfo, caseProgress, caseType }) => {
  return (
    <div className="controller-container">
      <SelectTabsDisplay requestId={requestId} tabsInfo={tabsInfo} caseType={caseType} />
      <CaseProgress requestId={requestId} caseProgress={caseProgress} />
    </div>
  );
};

export default Controller;
