import React, { useMemo, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import { useLocation } from 'react-router-dom';

import TableComponent from '../../Common/Table/TableComponent';
import { useGridWithCheckboxes } from '../../Shared/Hooks/useGridWithCheckboxes';
import { CellType } from '../../Shared/Constants/Constants';
import RelatedModal from './RelatedModal/RelatedModal';
import {
  changeVacRequired,
  changeTdRequired,
  deleteRelativeRequest,
  deletePetRequest,
  changeForeignPoliceRequired,
} from '../../../store/actions/requestDetails';
import ModalConfirm from '../../Common/ModalConfirm/ModalConfirm';
import { IsCaseDisabledContext } from '../RequestDetails';

import './RelatedProfiles.scss';
import { defaultDateFormat } from '../../../constants';
import dayjs from 'dayjs';

const RelatedProfiles = ({ request, requestId }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const employeesRowData = useMemo(
    () =>
      request?.relativeRequests.map((relativeRequest) => ({
        fullName: {
          fullName: relativeRequest.relative.generalInfo.fullName,
          id: `relative-requests/${relativeRequest.id}`,
        },
        nameRu: relativeRequest.relative.generalInfo.fullNameRu,
        relation: relativeRequest.relative.relationType,
        birth:
          relativeRequest.relative.generalInfo.birthDate &&
          dayjs(relativeRequest.relative.generalInfo.birthDate).format(displayDateFormat),
        vacRequest: relativeRequest.isVacRequired,
        tdRequest: relativeRequest.isTdRequired,
        foreignPolice: relativeRequest.isForeignPoliceRequired,
        deleteRelative: isCaseDisabled ? (
          ''
        ) : (
          <>
            <div className="tooltip-button">
              <Tooltip placement={'top'} title={'Delete relative'}>
                <i className="fa fa-trash icon-bin" aria-hidden="true"></i>
              </Tooltip>
            </div>
          </>
        ),
      })),
    [request],
  );

  const petsRowData = useMemo(
    () =>
      request?.petRequests.map((petRequest) => ({
        fullName: { fullName: petRequest.pet.name, id: `pet-requests/${petRequest.id}` },
        nameRu: '',
        relation: petRequest.pet.petType,
        birth: '',
        vacRequest: '',
        tdRequest: petRequest.isTdRequired,
        deleteRelative: isCaseDisabled ? (
          ''
        ) : (
          <>
            <div className="tooltip-button">
              <Tooltip placement={'top'} title={'Delete relative'}>
                <i className="fa fa-trash icon-bin" aria-hidden="true"></i>
              </Tooltip>
            </div>
          </>
        ),
      })),
    [request],
  );

  const linkedRowData = useMemo(
    () =>
      request?.linkedRequests.map((linkedRequest) => {
        const isCreatedByEmployee = linkedRequest.isCreatedByEmployee;
        return {
          fullName: {
            fullName: isCreatedByEmployee ? (
              <Tooltip
                placement="topLeft"
                title={
                  <div className="case-item__reason-tooltip">
                    Case was created automatically <br />
                    (Added as a related profile for {linkedRequest.createdBy || '-/-'} that is working at
                    iTechArt/Vention).
                    <br />
                    You&apos;ll be able to edit this case, once relocation of Employee is approved in WoD.
                  </div>
                }
                arrowPointAtCenter={true}
                className="disabled-fullname"
              >
                <span className="case-item__fullname-with-tooltip">{linkedRequest.employee.generalInfo.fullName}</span>
              </Tooltip>
            ) : (
              linkedRequest.employee.generalInfo.fullName
            ),
            id: `/request-details/${linkedRequest.id}`,
            isLinked: true,
            withoutLink: isCreatedByEmployee,
          },
          nameRu: linkedRequest.employee.generalInfo.fullNameRu,
          relation: linkedRequest.relationType,
          birth:
            linkedRequest.employee.generalInfo.birthDate &&
            dayjs(linkedRequest.employee.generalInfo.birthDate).format(displayDateFormat),
          vacRequest: linkedRequest.isVacRequired,
          tdRequest: linkedRequest.isTransportationRequired,
          foreignPolice: linkedRequest.isForeignPoliceRequired,
        };
      }),
    [request],
  );

  const rowsData = useMemo(
    () => employeesRowData.concat(petsRowData, linkedRowData),
    [employeesRowData.length, petsRowData.length, linkedRowData.length],
  );

  const relativesIds = request?.relativeRequests.map(({ id }) => id);

  const petsIds = request?.petRequests.map(({ id }) => id);

  const linkedIds = request?.linkedRequests.map(({ id }) => id);

  const ids = relativesIds.concat(petsIds, linkedIds);

  const deleteRelative = (rowIndex) => {
    const relationType = rows[rowIndex].fullName.isLinked ? 'employee' : rows[rowIndex].nameRu ? 'relative' : 'pet';
    const requestRelId = ids[rowIndex];

    if (relationType === 'relative') {
      dispatch(deleteRelativeRequest(request.id, requestRelId));
    } else if (relationType === 'pet') {
      dispatch(deletePetRequest(request.id, requestRelId));
    }
    setConfirmModalVisible(false);
  };

  const columns = useMemo(() =>
    [
      {
        key: 'fullName',
        title: 'Name (passport)',
        type: CellType.Link,
        getLink: (cell) => `./${cell.id}`,
        getValue: (cell) => cell.fullName,
      },
      {
        key: 'nameRu',
        title: 'Name (Russian)',
      },
      {
        key: 'relation',
        title: 'Relation',
      },
      {
        key: 'birth',
        title: 'Birth date',
      },
    ]
      .concat(
        request.city.country?.name !== 'Ukraine'
          ? [
              {
                key: 'vacRequest',
                title: 'VAC is required',
                type: CellType.Checkbox,
                disabled: isCaseDisabled,
                onChange: (rowIndex) => {
                  toggleCheckbox('vacRequest', rowIndex);
                  dispatch(
                    changeVacRequired(request.id, {
                      relationType: rows[rowIndex].fullName.isLinked ? 'employee' : 'relative',
                      relativeRequestId: ids[rowIndex],
                      existence_in_list: !rows[rowIndex].vacRequest,
                    }),
                  );
                },
              },
              {
                key: 'tdRequest',
                title: 'Transportation is required',
                type: CellType.Checkbox,
                disabled: isCaseDisabled,
                onChange: (rowIndex) => {
                  toggleCheckbox('tdRequest', rowIndex);
                  dispatch(
                    changeTdRequired(request.id, {
                      relationType: rows[rowIndex].fullName.isLinked
                        ? 'employee'
                        : rows[rowIndex].nameRu
                        ? 'relative'
                        : 'pet',
                      relativeRequestId: ids[rowIndex],
                      existence_in_list: !rows[rowIndex].tdRequest,
                    }),
                  );
                },
              },
              {
                key: 'foreignPolice',
                title: 'Migration office is required',
                type: CellType.Checkbox,
                disabled: isCaseDisabled,
                onChange: (rowIndex) => {
                  toggleCheckbox('foreignPolice', rowIndex);
                  dispatch(
                    changeForeignPoliceRequired(request.id, {
                      relationType: rows[rowIndex].fullName.isLinked ? 'employee' : 'relative',
                      relativeRequestId: ids[rowIndex],
                      existence_in_list: !rows[rowIndex].foreignPolice,
                    }),
                  );
                },
              },
            ]
          : [
              {
                key: 'tdRequest',
                title: 'Transportation is required',
                type: CellType.Checkbox,
                disabled: isCaseDisabled,
                onChange: (rowIndex) => {
                  toggleCheckbox('tdRequest', rowIndex);
                  dispatch(
                    changeTdRequired(request.id, {
                      relationType: rows[rowIndex].fullName.isLinked
                        ? 'employee'
                        : rows[rowIndex].nameRu
                        ? 'relative'
                        : 'pet',
                      relativeRequestId: ids[rowIndex],
                      existence_in_list: !rows[rowIndex].tdRequest,
                    }),
                  );
                },
              },
              {
                key: 'vacRequest',
                title: 'VAC is required',
                type: CellType.Checkbox,
                disabled: isCaseDisabled,
                onChange: (rowIndex) => {
                  toggleCheckbox('vacRequest', rowIndex);
                  dispatch(
                    changeVacRequired(request.id, {
                      relationType: rows[rowIndex].fullName.isLinked ? 'employee' : 'relative',
                      relativeRequestId: ids[rowIndex],
                      existence_in_list: !rows[rowIndex].vacRequest,
                    }),
                  );
                },
              },
              {
                key: 'foreignPolice',
                title: 'Migration office is required',
                type: CellType.Checkbox,
                disabled: isCaseDisabled,
                onChange: (rowIndex) => {
                  toggleCheckbox('foreignPolice', rowIndex);
                  dispatch(
                    changeForeignPoliceRequired(request.id, {
                      relationType: rows[rowIndex].fullName.isLinked ? 'employee' : 'relative',
                      relativeRequestId: ids[rowIndex],
                      existence_in_list: !rows[rowIndex].vacRequest,
                    }),
                  );
                },
              },
            ],
      )
      .concat([
        {
          key: 'deleteRelative',
          title: '',
          onClick: (rowIndex, value) => {
            setConfirmModalVisible({ rowIndex: rowIndex });
          },
        },
      ]),
  );

  const { rows, toggleCheckbox } = useGridWithCheckboxes({
    initialRows: rowsData,
    columns,
  });

  return (
    <div className="RelatedProfiles">
      <TableComponent rows={rows} columns={columns} />
      <RelatedModal request={request} requestId={requestId} isCaseDisabled={isCaseDisabled} />
      {isConfirmModalVisible && (
        <ModalConfirm
          isModalVisible={isConfirmModalVisible}
          setModalVisible={setConfirmModalVisible}
          confirmationQuestion={
            'All data garthered in this profile and attached documents will be removed permanently without ability to restore. Are you sure you want to delete this related profile?'
          }
          handleClick={() => {
            deleteRelative(isConfirmModalVisible.rowIndex);
          }}
        />
      )}
    </div>
  );
};

export default RelatedProfiles;
