import * as Yup from 'yup';

export const formSchema = [
  {
    fieldName: 'requiredFor',
    type: 'Checkboxes',
    label: 'Required for',
    inline: true,
    options: [
      {
        text: 'Spouse',
        key: 'isRequiredForSpouse',
        value: 'isRequiredForSpouse',
      },
      {
        text: 'Children',
        key: 'isRequiredForChildren',
        value: 'isRequiredForChildren',
      },
      {
        text: 'Pets',
        key: 'isRequiredForPets',
        value: 'isRequiredForPets',
      },
    ],
  },

  {
    fieldName: 'address',
    type: 'TextareaAsInput',
    label: 'Address',
  },
  {
    fieldName: 'checkInDate',
    type: 'DatePicker',
    label: 'Check-in date',
  },
  {
    fieldName: 'checkInTime',
    type: 'TimeField',
    label: 'Check-in time (after)',
  },
  {
    fieldName: 'checkOutDate',
    type: 'DatePicker',
    label: 'Check-out date',
  },
  {
    fieldName: 'checkOutTime',
    type: 'TimeField',
    label: 'Check-out time (before)',
  },
  {
    fieldName: 'files',
    type: 'AttachFiles',
    label: 'Attachments',
  },
  {
    fieldName: 'notes',
    type: 'NoteField',
    label: 'Notes',
  },
];

export const validationSchema = Yup.object().shape({
  accommodationDetailsInfo: Yup.array().of(
    Yup.object().shape({
      checkInDate: Yup.date().nullable(),
      checkOutDate: Yup.date().nullable(),
      address: Yup.string().nullable(),
      notes: Yup.array().of(
        Yup.object().shape({
          text: Yup.string()
            .min(2, 'Notes must be at least 2 characters')
            .test('len', 'Notes must be at least 2 characters', (val) => (val ? val.toString().length : 0 > 0)),
        }),
      ),
    }),
  ),
});
