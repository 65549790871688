import { types } from '../actions/types';
import _ from 'lodash';

const initialState = {};

const relocationCostsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.RELOCATION_COSTS_LOAD:
      return action.payload.relocationCosts;
    case types.RELOCATION_COSTS_UPDATE:
      return {
        ...state,
        [action.payload.currencyType]: state[action.payload.currencyType].map((country) => {
          if (+country.id === +action.payload.countryId) {
            return {
              ...country,
              calculatorData: {
                ...country.calculatorData,
                [action.payload.relocationCosts.item]: action.payload.relocationCosts.value,
              },
            };
          } else return country;
        }),
      };
    case types.RELOCATION_COSTS_STRUCT_UPDATE:
      return {
        ...state,
        calculatorStruct: [...state.calculatorStruct, action.payload.structItem],
      };
    case types.RELOCATION_COSTS_STRUCT_NAME_EDIT:
      const newState = {};
      for (const prop in state) {
        if (prop === 'calculatorStruct') {
          newState.calculatorStruct = state.calculatorStruct.map((item) => {
            if (item === action.payload.oldStructItem) {
              return action.payload.structItem;
            } else {
              return item;
            }
          });
        } else {
          newState[prop] = state[prop].reduce((previousValue, country) => {
            const calculatorDataCopy = _.cloneDeep(country.calculatorData);
            if (calculatorDataCopy && calculatorDataCopy[action.payload.oldStructItem]) {
              Object.defineProperty(
                calculatorDataCopy,
                action.payload.structItem,
                Object.getOwnPropertyDescriptor(calculatorDataCopy, action.payload.oldStructItem),
              );
              delete calculatorDataCopy[action.payload.oldStructItem];
            }
            previousValue.push({
              ...country,
              calculatorData: { ...calculatorDataCopy },
            });
            return previousValue;
          }, []);
        }
      }

      return {
        ...newState,
      };
    case types.RELOCATION_COSTS_STRUCT_ITEM_DELETE:
      return {
        ...state,
        calculatorStruct: state.calculatorStruct.filter((item) => item !== action.payload.structItem),
      };
    default:
      return {
        ...state,
      };
  }
};

export default relocationCostsReducer;
