import { useState, useContext } from 'react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';

import { editRelocationRequest } from '../../../store/actions/request';
import { getFormElement } from '../../Shared/Form/FormElements';
import { IsCaseDisabledContext } from '../RequestDetails';
import './employeeIsRelocated.scss';

const EmployeeIsRelocated = (props) => {
  const { requestId, isEmployeeRelocated, employeeRelocationDate } = props;

  const dispatch = useDispatch();

  const [relocationDate, setRelocationDate] = useState(employeeRelocationDate);
  const [updateTimeout, setUpdateTimeout] = useState(setTimeout(0));
  const [previousPlannedDate, setPreviousPlannedDate] = useState(relocationDate);

  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const onEmployeeRelocated = (value) => {
    setRelocationDate(value ? value : null);
  };

  const onCalendarClose = () => {
    if (previousPlannedDate !== relocationDate) {
      setPreviousPlannedDate(relocationDate);
      if (updateTimeout) {
        clearTimeout(updateTimeout);
      }
      setUpdateTimeout(
        setTimeout(() => {
          dispatch(editRelocationRequest(requestId, { employeeRelocationDate: relocationDate }));
        }, 1000),
      );
    }
  };

  const formIsEmployeeRelocated = [
    {
      fieldName: 'isEmployeeRelocated',
      type: 'DatepickerWithDisabledCheckbox',
      label: 'Employee is relocated',
      updateselectvalueroot: onEmployeeRelocated,
      oncalendarcloseupdate: onCalendarClose,
    },
  ];

  return (
    <>
      <div className="employee-relocated">
        <Formik
          enableReinitialize
          initialValues={{ isEmployeeRelocated: isEmployeeRelocated, employeeRelocationDate: employeeRelocationDate }}
        >
          {(formikProps) => (
            <Form>
              {formIsEmployeeRelocated.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
              ))}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default EmployeeIsRelocated;
