import { types } from '../actions/types';

const initialState = {
  addressesInfo: [],
  foreignPoliceAddressesInfo: [],
};

const vacAppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.VAC_APPOINTMENT_ADDRESSES_LOADED:
      return {
        ...state,
        addressesInfo: action.payload.addressesInfo,
      };
    case types.FOREIGN_POLICE_ADDRESSES_LOADED:
      return {
        ...state,
        foreignPoliceAddressesInfo: action.payload.addressesInfo,
      };
    default:
      return {
        ...state,
      };
  }
};

export default vacAppointmentReducer;
