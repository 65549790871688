import { useDispatch } from 'react-redux';
import { getValuesToUpdate } from '../../../helpers';
import { upsertRelatedTransportationDetails } from '../../../store/actions/requestDetails';
import TransportationDetails from '../../Common/TransportationDetails/TransportationDetails';
import { formSchema } from './requestSchema';

const transportationDetailsInitialValues = {
  transportType: '',
  ticketCost: 0,
  currency: 'usd',
  tickets: [
    {
      date: '',
      time: '',
      travelFrom: '',
      travelTo: '',
      address: '',
    },
  ],
  files: [],
  notes: [],
};

const TransportationRelatedDetails = (props) => {
  const { transportationDetailsInfo, requestId, relatedReqId, isCaseDisabled } = props;

  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    const valuesToUpdate = getValuesToUpdate(
      values.transportationDetailsInfo,
      transportationDetailsInfo,
      Object.keys(transportationDetailsInitialValues),
    );
    const valuesToAdd = values?.transportationDetailsInfo.filter(({ id }) => isNaN(id));

    const valuesToUpdateWithTicketAmount = valuesToUpdate.map((item) => {
      return { ...item, ticketAmount: item.tickets.length };
    });

    const valuesToAddWithTicketAmount = valuesToAdd.map((item) => {
      return { ...item, ticketAmount: item.tickets.length };
    });

    const valuesWithFilesToUpdate = getValuesToUpdate(values.transportationDetailsInfo, transportationDetailsInfo, [
      'files',
    ]);
    const files = values.transportationDetailsInfo.some((item) => item?.files?.length);

    if (valuesToUpdate.length || valuesToAdd.length || files) {
      dispatch(
        upsertRelatedTransportationDetails(
          valuesToUpdateWithTicketAmount,
          valuesToAddWithTicketAmount,
          requestId,
          relatedReqId,
          valuesWithFilesToUpdate,
        ),
      );
    }
    setSubmitting(false);
  };

  return (
    <TransportationDetails
      transportationDetailsAllInfo={transportationDetailsInfo}
      onSubmit={onSubmit}
      formSchema={formSchema}
      relatedReqId={relatedReqId}
      type="related"
      isCaseDisabled={isCaseDisabled}
    />
  );
};

export default TransportationRelatedDetails;
