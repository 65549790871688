import { types } from '../actions/types';

export const initialState = {
  feedbackForms: [],
  feedbackFormsForEmployee: [],
  filterByCountry: '',
  next: null,
  previous: null,
  count: 0,
};

const feedbackFormsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FEEDBACK_FORMS_LOADED:
      return {
        ...state,
        feedbackForms: action.payload.feedbackForms,
        filterByCountry: action.payload.filterByCountry || '',
        next: action.payload.next,
        previous: action.payload.previous,
        count: action.payload.count,
      };
    case types.FEEDBACK_FORMS_UPDATE:
      return {
        ...state,
        feedbackForms: [...state.feedbackForms, ...action.payload.feedbackForms],
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.FEEDBACK_FORMS_EMPLOYEE_LOAD:
      return {
        ...state,
        ...(action.payload.parameters?.is_for_employee && {
          feedbackFormsForEmployee: action.payload.feedbackForms,
        }),
      };
    case types.FEEDBACK_FORMS_UPDATED:
      return {
        ...state,
        lfeedbackForms: state.feedbackForms.map((item) => {
          if (item.id === action.payload.feedbackForms.id) {
            item = action.payload.feedbackForms;
          }
          return item;
        }),
      };
    case types.FEEDBACK_FORMS_ADD:
      return {
        ...state,
        feedbackForms: [...state.feedbackForms, action.payload.feedbackForms],
      };
    default:
      return {
        ...state,
      };
  }
};

export default feedbackFormsReducer;
