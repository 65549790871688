import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLinkedRequest } from '../../../../../../store/actions/linkedRequest';
import './LinkedRequests.scss';

const getLinkedIds = (linkedRequests, requestId) => {
  const ids = [];
  linkedRequests.forEach((req) => {
    ids.push(req.id);
  });
  ids.push(requestId);
  return ids.join();
};

const LinkedRequests = ({ requestId, cityId, setRequestChoosed, setLinkedId, linkedRequests, formikProps }) => {
  const dispatch = useDispatch();

  const searchRequestsList = useSelector((state) => state.searchRequestRelatedProfiles.searchRequestsList);

  const [searchHide, setSearchHide] = useState(true);

  const searchResult = (e, blur) => {
    const searchValue = e.target.value;
    if (searchValue.length > 2 && !blur) {
      dispatch(
        getLinkedRequest({
          search: searchValue,
          exclude_id: getLinkedIds(linkedRequests, requestId),
          city_id: cityId,
        }),
      );
      setSearchHide(false);
    } else {
      setSearchHide(true);
    }
  };

  const handleClick = (id, fullName) => {
    setRequestChoosed(true);
    setLinkedId({ case_id: id });
    formikProps.setFieldValue(`firstName`, fullName.split(' ')[0]);
    formikProps.setFieldValue(`lastName`, fullName.split(' ')[1]);
  };

  return (
    <div className="case-search-employees">
      <div className="search-form">
        <p>Search for name</p>
        <div className="search-container">
          <span className="search-container__icon">
            <i className="fas fa-search"></i>
          </span>
          <input
            type="text"
            autoComplete="off"
            onChange={(e) => {
              searchResult(e);
            }}
            name="search"
            placeholder=""
            className="search-container__input"
            onBlur={(e) => {
              setTimeout(() => {
                setSearchHide(true);
                e.target.value = '';
              }, 400);
            }}
          />
        </div>
      </div>
      <div className={`search-results__container${searchHide ? 'hide' : ''}`}>
        <div className="search-results__position">
          <div className={`search-results__wrapper ${searchHide ? 'hide' : ''}`}>
            <ul className="search-results__items">
              {searchRequestsList?.results?.length > 0 ? (
                searchRequestsList?.results.map((searchItem, index) => {
                  return (
                    <li
                      className="search-results__item"
                      key={index}
                      onClick={() => handleClick(searchItem.id, searchItem.employee.generalInfo.fullName)}
                    >
                      <p>{`${searchItem.employee.generalInfo.fullName}`}</p>
                    </li>
                  );
                })
              ) : (
                <li key="no-data" className="search-results__item no-content">
                  No data to display
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkedRequests;
