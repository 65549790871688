import * as Yup from 'yup';

export const formSchema = [
  {
    fieldName: 'firstName',
    type: 'TextInput',
    label: 'Name',
  },
  {
    fieldName: 'lastName',
    type: 'TextInput',
    label: 'Surname',
  },
  {
    fieldName: 'birthDate',
    type: 'DatePicker',
    label: 'Date of birth',
  },
  {
    fieldName: 'companyId',
    type: 'CustomSelect',
    label: 'Company',
    options: [],
  },
  // {
  //   fieldName: 'targetUnitId',
  //   type: 'CustomSelect',
  //   label: 'Target Unit',
  //   options: [],
  // },
  {
    fieldName: 'plannedEmploymentDate',
    type: 'DatePicker',
    label: 'Planned employment date',
  },
];

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .required('This field is required')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'First name must contain only letters'),
  lastName: Yup.string()
    .required('This field is required')
    .matches(/^[a-zA-Zа-яА-Я ,.'-]+$/, 'First name must contain only letters'),
  companyId: Yup.number().required('This field is required').nullable(),
  // birthDate: Yup.date().required('This field is required').nullable(),
  // targetUnitId: Yup.string().required('This field is required'),
  plannedEmploymentDate: Yup.date().required('This field is required'),
});

export const initialValues = {
  firstName: '',
  lastName: '',
  birthDate: null,
  companyId: null,
  // targetUnitId: null,
  plannedEmploymentDate: '',
};
