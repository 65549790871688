import { useEffect } from 'react';

import { getEmployeeTab, getNewRelativeTab, getExistingRelativeTab, getLinkedTab, getRelativeTab } from './useGetTabs';
import DocumentsTab from './DocumentsTab/DocumentsTab';
import './documentsTabs.scss';

const DocumentsTabs = ({
  withWhom,
  setWithWhom,
  docsInfo,
  docsInfoEmployee,
  requestId,
  docsInfoRelatives,
  selectedMenuItem,
  setSelectedTab,
  setSelectedMenuItem,
  currentTab,
  setCurrentTab,
}) => {
  useEffect(() => {
    if (!currentTab) setCurrentTab(String(docsInfo.employee.internalId));
  }, []);

  const isJustMe = !withWhom.withChildren && !withWhom.withSpouse;

  const relDocuments = docsInfo.relativeRequests;

  const tabs = [];
  //employeeTab
  const employeeTab = getEmployeeTab({
    docsInfo,
    docsInfoEmployee,
  });
  tabs.push(employeeTab);

  //relatedCases tabs
  docsInfoEmployee.relatedCases &&
    docsInfoEmployee.relatedCases.forEach((item, index) => {
      tabs.push(
        getLinkedTab({
          item,
          docsInfo,
        }),
      );
    });

  //relative tabs
  relDocuments &&
    relDocuments.forEach((item, index) => {
      ((withWhom.withSpouse && item?.relative?.relationType === 'spouse') ||
        (withWhom.withChildren && item?.relative?.relationType === 'child')) &&
        tabs.push(
          getRelativeTab(
            item,
            requestId,
            docsInfo,
            docsInfoRelatives,
            setCurrentTab,
            currentTab,
            index,
            setSelectedTab,
          ),
        );
    });

  //new relative tab
  !isJustMe &&
    tabs.push(
      getNewRelativeTab({
        requestId,
        docsInfoEmployee,
        setCurrentTab,
        setWithWhom,
        currentTab,
        withWhom,
        setSelectedTab,
      }),
    );

  //existing relative tab
  !isJustMe &&
    docsInfo.relativesNotInRequest?.length &&
    tabs.push(
      getExistingRelativeTab({
        requestId,
        docsInfo,
        setWithWhom,
        withWhom,
      }),
    );

  return (
    <>
      <div className="documentTabs-wrapper">
        <div className="documentTabs">
          {tabs.map((tab, i) => (
            <DocumentsTab
              key={i}
              currentTab={currentTab}
              tab={tab}
              docsInfo={docsInfo}
              docsInfoEmployee={docsInfoEmployee}
              setSelectedTab={setSelectedTab}
              setCurrentTab={setCurrentTab}
              setSelectedMenuItem={setSelectedMenuItem}
              tabs={tabs}
              requestId={requestId}
            />
          ))}
        </div>

        <div className="content">
          {tabs.map((tab, i) => (
            <div key={i}>{String(currentTab) === `${tab.id}` && <>{tab.content(selectedMenuItem || '')}</>}</div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DocumentsTabs;
