import { Switch, Route, Redirect } from 'react-router-dom';

import GeneralInfo from '../../Common/GeneralInfo/GeneralInfo';
import ContactInfo from '../../Common/ContactInfo/ContactInfo';
import EmploymentInfo from '../EmploymentInfo/EmploymentInfo';
import RequestEmployeeDocuments from '../EmployeeDocumentsBlock/RequestEmployeeDocuments/RequestEmployeeDocuments';
import EmployeeDocuments from '../../Common/EmployeeDocumentsTabs/EmployeeDocuments/EmployeeDocuments';
import EmployeeForms from '../../Common/EmployeeDocumentsTabs/EmployeeForms/EmployeeForms';
import RelocationRequestDetails from '../RelocationRequestDetails/RelocationRequestDetails';
import ArchivedDocument from '../ArchivedDocument/ArchivedDocument';
import PlannedRelocation from '../PlannedRelocation/PlannedRelocation';
import AdditionalSteps from '../AdditionalSteps/AdditionalSteps';
import RelatedProfiles from '../RelatedProfiles/RelatedProfiles';
import TransportationEmployeeDetails from '../TransportationDetails/TransportationEmployeeDetails';
import AccommodationDetails from '../AccommodationDetails/AccommodationDetails';
import CompensationRequested from '../CompensationRequested/CompensationRequest';
import EmployeeIsRelocated from '../EmployeeIsRelocated/EmployeeIsRelocated';
import CaseHistory from '../CaseHistory/CaseHistory';
import DeleteRequest from '../DeleteRequest/DeleteRequest';
import InfoTopRight from '../InfoTopRight/InfoTopRight';
import EmployeeComments from '../../Common/EmployeeComments/EmployeeComments';
import VacAppointmentWrapper from '../VacAppointment/VacAppointmentWrapper';
import LegalizationDocuments from '../../Common/EmployeeDocumentsTabs/LegalizationDocuments/LegalizationDocuments';
import EmployeeFeedback from '../EmployeeFeedback/EmployeeFeedback';
import Controller from '../TabsController/Controller';
import ForeignPoliceAppointmentWrapper from '../ForeignPoliceAppointment/ForeignPoliceAppointmentWrapper';

const ContentRoutes = ({ request, employee, requestId, isCaseDisabled, gmgResponsible }) => {
  const employeeId = request.employee.uuid;
  const genInfo = employee?.generalInfo;

  return (
    <div className="request-details__content">
      <InfoTopRight
        requestId={requestId}
        gmtMember={request.caseGmtMember}
        statusNote={request.statusNote}
        status={request.status}
        gmgResponsible={gmgResponsible}
        isCaseDisabled={isCaseDisabled}
        caseType={request.caseType}
        isEmployeeRelocated={request.isEmployeeRelocated}
        employeeId={employeeId}
      />
      <Switch>
        <Route
          exact
          path="/request-details/:id/controller"
          component={() => (
            <Controller
              requestId={requestId}
              caseType={request.caseType}
              tabsInfo={{
                isVacRequired: request.isVacRequired,
                isTransportationRequired: request.isTransportationRequired,
                isAccommodationRequired: request.isAccommodationRequired,
                isForeignPoliceRequired: request.isForeignPoliceRequired,
              }}
              caseProgress={{
                withWhom: [
                  request.withSpouse && 'withSpouse',
                  request.withChildren && 'withChildren',
                  request.withPets && 'withPets',
                ],
                isVacRequired: request.isVacRequired,
                vacAppointment: request.vacAppointment,
                managerNotifiedAt: request.managerNotifiedAt,
                compensationDate: request.compensationDate,
              }}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/general-information"
          component={() => (
            <>
              <GeneralInfo
                requestId={requestId}
                employeeId={employeeId}
                fullName={genInfo.fullName}
                fullNameRu={genInfo.fullNameRu}
                otherNameSpelling={genInfo.otherNameSpelling || ''}
                birthDate={genInfo.birthDate}
                maidenName={genInfo.maidenName || ''}
                nationalityId={genInfo.nationality?.id || undefined}
                citizenshipId={genInfo.citizenship?.id || undefined}
                residencyCityId={genInfo.residencyCity?.id || ''}
                isCaseDisabled={isCaseDisabled}
                isStudent={employee.generalInfo.isStudent}
                passportFirstName={employee.generalInfo.passportFirstName || ''}
                passportLastName={employee.generalInfo.passportLastName || ''}
                isCreatedByEmployee={request.isCreatedByEmployee}
                createdBy={request.createdBy}
                unit={employee?.unit?.longName}
                manager={employee?.manager}
                position={employee?.rank?.name || ''}
                employeeEducation={request.employeeEducation}
              />
              <DeleteRequest requestId={requestId} />
            </>
          )}
        />
        <Route
          exact
          path="/request-details/:id/contact-information"
          component={() => (
            <ContactInfo
              requestId={requestId}
              employeeId={employeeId}
              email={employee.contactInfo.email}
              skype={employee.contactInfo.skype}
              phones={employee.contactInfo.phones || ['']}
              emergencyInfo={employee.contactInfo.emergencyInfo}
              addressInfo={employee.contactInfo?.addressInfo}
              isCaseDisabled={isCaseDisabled}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/employment-information"
          component={() => (
            <EmploymentInfo
              employmentInfoHistory={employee.employmentInfoHistory}
              employeeId={employeeId}
              employee={employee}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/employee-documents"
          component={() => (
            <EmployeeDocuments
              employeeDocuments={employee.documents}
              // employeeEducation={request.employeeEducation}
              documentsStatus={employee.documentsStatus}
              employeeId={employeeId}
              isCaseDisabled={isCaseDisabled}
              requestId={requestId}
              country={request.city?.country}
              documentsToDisplay={request.documentsTypeMapping}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/employee-forms"
          component={() => <EmployeeForms documents={employee.forms} employeeId={employeeId} />}
        />
        <Route
          exact
          path="/request-details/:id/legalization-steps"
          component={() => (
            <LegalizationDocuments
              documents={employee.legalizationDocuments}
              employeeId={employeeId}
              requestId={requestId}
              // country={request.city?.country}
              documentsToDisplay={request.documentsTypeMapping}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/request-documents"
          component={() => (
            <RequestEmployeeDocuments
              requestId={requestId}
              isDocsEditable={request.isDocsEditable}
              requestedDocuments={request.requestedDocuments}
              documentsToDisplay={request.documentsToDisplay}
              documentsSentList={request.documentsSentList}
              employeeEducation={request.employeeEducation}
              documentsStatus={request.documentsStatus}
              country={request.city?.country}
              employeeForms={employee.forms}
              isCaseDisabled={isCaseDisabled}
            />
          )}
        />

        <Route
          exact
          path="/request-details/:id/archived-documents"
          component={() => (
            <ArchivedDocument
              requestId={requestId}
              employeeId={employeeId}
              archivedDocuments={employee.archivedDocuments}
              isCaseDisabled={isCaseDisabled}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/relocation-request-details"
          component={() => (
            <>
              <RelocationRequestDetails requestId={requestId} request={request} />
              <PlannedRelocation request={request} />
            </>
          )}
        />
        <Route
          exact
          path="/request-details/:id/additional-steps"
          component={() => <AdditionalSteps requestId={requestId} documents={request.relocationSteps} />}
        />
        <Route
          exact
          path="/request-details/:id/related-profiles"
          component={() => <RelatedProfiles request={request} requestId={requestId} />}
        />
        <Route
          exact
          path="/request-details/:id/vac-appointment"
          component={() => <VacAppointmentWrapper requestId={requestId} vacInfo={request?.vacAppointment || []} />}
        />
        <Route
          exact
          path="/request-details/:id/foreign-police-appointment"
          component={() => (
            <ForeignPoliceAppointmentWrapper requestId={requestId} vacInfo={request?.foreignPoliceAppointments || []} />
          )}
        />
        <Route
          exact
          path="/request-details/:id/transportation-details"
          component={() => (
            <TransportationEmployeeDetails
              requestId={requestId}
              transportationDetailsInfo={request?.transportationDetails}
              isTicketsBooked={request?.isTicketsBooked}
              employeeId={employeeId}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/accommodation-details"
          component={() => (
            <AccommodationDetails
              requestId={requestId}
              accommodationDetailsAllInfo={request?.accommodationDetails}
              isAccommodationBookedInfo={request?.isAccommodationBooked}
              employeeId={employeeId}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/compensation"
          component={() => (
            <CompensationRequested
              compensationRequestedInfo={{
                currency: request?.currency,
                compensationAmount: request?.compensationAmount,
                isCompensationDisabled: request?.isCompensationDisabled,
              }}
              requestId={requestId}
              linkedRequests={request.linkedRequests}
              paidInAdvance={request.paidInAdvance}
            />
          )}
        />
        <Route
          exact
          path="/request-details/:id/employee-comments"
          component={() => (
            <>
              <EmployeeComments employeeId={employeeId} />
            </>
          )}
        />
        <Route
          exact
          path="/request-details/:id/employee-feedback"
          component={() => (
            <>
              <EmployeeFeedback requestId={requestId} />
            </>
          )}
        />
        <Route
          exact
          path="/request-details/:id/relocation-date"
          component={() => (
            <EmployeeIsRelocated
              requestId={requestId}
              isEmployeeRelocated={request?.isEmployeeRelocated}
              employeeRelocationDate={request?.employeeRelocationDate}
            />
          )}
        />
        <Route exact path="/request-details/:id/history" component={() => <CaseHistory requestId={requestId} />} />

        <Redirect exact from="/request-details/:id" to="/request-details/:id/general-information" />
      </Switch>
    </div>
  );
};

export default ContentRoutes;
