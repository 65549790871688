import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { useState } from 'react';

import { sendEmployeeProfileDocumentsNotification } from '../../../../../store/actions/requestDetails';
import Modal from '../../../../Shared/Modal/Modal';
import EmployeeSelectDocuments from './Employee/EmployeeSelectDocuments';
import SetDeadLine from './SetDeadline/SetDeadline';
import { initialValues, validationSchema } from './validationSchema';
import ExitImg from '../../../../../assets/img/exit.svg';
import './selectDocumentsModal.scss';

const SelectDocumentsModal = (props) => {
  const { isModalVisible, setModalVisible, employeeId, documentsToDisplay, employeeForms } = props;

  const dispatch = useDispatch();

  const [step, setStep] = useState(1);

  const onSubmit = (values, { setSubmitting }) => {
    const docsValues = Object.keys(values).reduce((object, key) => {
      if (key !== 'date') {
        object[key] = values[key];
      }
      return object;
    }, {});

    const documentsSentListEmployee = _.keys(_.pickBy(docsValues));

    dispatch(
      sendEmployeeProfileDocumentsNotification(employeeId, {
        requiredDocsIds: documentsSentListEmployee,
        spouseRequiredDocsIds: [],
        childRequiredDocsIds: [],
        docsDeadline: values.date,
      }),
    ).finally(() => {
      setSubmitting(false);
      setModalVisible(false);
    });
  };

  return (
    <div className="select-documents select-documents-send">
      <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l">
        <div className="select-documents__wrapper">
          <div className="select-documents-send__title">
            <h3 className="underlined panel-top-row">{step === 2 ? 'Set deadline' : 'Select documents to send'}</h3>
            <img src={ExitImg} alt="close" onClick={() => setModalVisible(false)} />
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => {
            return (
              <>
                <Form>
                  {step === 1 && (
                    <EmployeeSelectDocuments
                      documentsToDisplay={documentsToDisplay}
                      employeeForms={employeeForms}
                      setModalVisible={setModalVisible}
                      setStep={setStep}
                      formikProps={formikProps}
                    />
                  )}
                  {step === 2 && (
                    <>
                      <SetDeadLine setStep={setStep} formikProps={formikProps} values={formikProps.values} />
                    </>
                  )}
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default SelectDocumentsModal;
