import { useState } from 'react';
import { Tooltip } from 'antd';

import ModalConfirm from '../ModalConfirm/ModalConfirm';
import DeleteFormIcon from '../../../assets/img/icons/Сlose_square_line.svg';

import './deleteForm.scss';

const DeleteForm = (props) => {
  const { deleteForm, isCaseDisabled } = props;
  const [isModalVisible, setModalVisible] = useState(false);

  const handleDeleteForm = () => {
    deleteForm();
    setModalVisible(false);
  };

  return (
    <>
      <div className="delete-form-container">
        <Tooltip placement={'top'} title={'Delete form'} mouseEnterDelay={0} mouseLeaveDelay={0} zIndex={10}>
          <div className="tooltip-button">
            <img className="delete-icon" src={DeleteFormIcon} alt="delete" onClick={() => setModalVisible(true)} />
          </div>
        </Tooltip>
      </div>
      {isModalVisible && (
        <ModalConfirm
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          confirmationQuestion="Are you sure you want to delete this form?"
          handleClick={handleDeleteForm}
        />
      )}
    </>
  );
};

export default DeleteForm;
