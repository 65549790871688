import actionCreators from './actionCreators';
import axios from '../../axios';

export const getStatistics = (params) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/relocation-requests/analytics/`, { params });

    if (params.analytics_type !== 'residence_permit_count') {
      dispatch(actionCreators.statisticsLoad(response.data, params));
    }
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const statisticsExportExcel = (filters) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .post(
        '/api/v1/relocation-requests/analytics/excel',
        { analytics: filters },
        {
          responseType: 'blob',
        },
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const fileName =
          response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '') || `${Date.now()}.xlsx`;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);

        return 1;
      })
      .catch(async (error) => {
        toast.type = 'error';
        const responseData = await error.response?.data;
        const responseMessage = responseData === 'Internal server error' ? responseData : responseData.text();
        toast.errorMessage = await responseMessage;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
