import { useDispatch } from 'react-redux';
import { unArhiveDocumentForm } from '../../../store/actions/uploadFile';
import ArchivedDocumentForm from '../../Common/ArchivedDocumentForm/ArchivedDocumentForm';

const ArchivedDocument = ({ archivedDocuments, employeeId, isCaseDisabled }) => {
  const dispatch = useDispatch();

  const handleUnarchiveForm = (employeeId, documentId, formId) => () => {
    dispatch(unArhiveDocumentForm({ isArchived: false }, employeeId, documentId, formId, 'employee'));
  };

  return (
    <div>
      {archivedDocuments.map((archivedDocument) => {
        return (
          <ArchivedDocumentForm
            key={archivedDocument.id}
            document={archivedDocument}
            employeeId={employeeId}
            unarchiveForm={handleUnarchiveForm}
            isCaseDisabled={isCaseDisabled}
            type="employee"
          />
        );
      })}
    </div>
  );
};

export default ArchivedDocument;
