import { Formik, Form, FieldArray } from 'formik';

import { getFormElement } from '../../Shared/Form/FormElements';
import ArchiveForm from '../ArchiveForm/ArchiveForm';
import AttachmentBlock from './AttachmentBlock/AttachmentBlock';
import DocumentAccordion from '../DocumentAccordion/DocumentAccordion';
import './archivedDocument.scss';

const ArchivedDocumentForm = ({ document, employeeId, unarchiveForm, isCaseDisabled, type }) => {
  const formSchema = document.forms[0].fields;
  formSchema.map((item) => (item.disabled = true));

  const initialValues = {
    [`archived${document.slug}`]: document.forms.map((item) => {
      return {
        ...item.values,
        id: item.formId,
      };
    }),
  };

  return (
    <div className="archived-document">
      <DocumentAccordion documentName={document.name} documentId={document.slug} type={type}>
        <Formik enableReinitialize initialValues={initialValues}>
          {(formikProps) => {
            return (
              <Form>
                <FieldArray
                  name={`archived${document.slug}`}
                  render={({ remove, push }) => (
                    <>
                      <div>
                        {formikProps.values[`archived${document.slug}`].length > 0 &&
                          formikProps.values[`archived${document.slug}`].map((form, formIndex) => (
                            <div className="document-container" key={formIndex}>
                              {formSchema.map((field) => (
                                <div key={field.fieldName}>
                                  {getFormElement(
                                    field.element,
                                    {
                                      nameFieldArray: `archived${document.slug}`,
                                      formIndex,
                                      ...field,
                                    },
                                    formikProps,
                                  )}
                                </div>
                              ))}
                              <AttachmentBlock form={form} document={document} employeeId={employeeId} />
                              <ArchiveForm
                                archiveForm={unarchiveForm(employeeId, document.id, form.id)}
                                type="unarchive"
                                isCaseDisabled={isCaseDisabled}
                              />
                            </div>
                          ))}
                      </div>
                    </>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </DocumentAccordion>
    </div>
  );
};

export default ArchivedDocumentForm;
