import './checkbox.scss';

const Checkbox = ({ tabKey, onChange, isChecked, label, isDisabled }) => {
  return (
    <div key={tabKey} className="controller-checkbox__container">
      <button
        className="custom-checkbox controller-checkbox"
        onClick={() => !isDisabled && onChange(tabKey, !isChecked)}
      >
        <input
          className="custom-control-input"
          type="checkbox"
          checked={isChecked}
          onChange={() => onChange()}
          disabled={isDisabled}
        />
        <label className="custom-checkbox__label">
          <span></span>
        </label>
      </button>
      <label onClick={() => !isDisabled && onChange(tabKey, !isChecked)}>{label}</label>
    </div>
  );
};

export default Checkbox;
