import actionCreators from './actionCreators';
import axios from '../../axios';
import { getValuesToUpdate } from '../../helpers';
import queryString from 'query-string';
import { accommodationAttachmentsUpload, attachmentUpload, transportationTicketsUpload } from './uploadFile';

export const getRequestDetails = (requestId) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/cases/${requestId}/`)
      .then((response) => {
        dispatch(actionCreators.requestDetailsLoaded(response.data, requestId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.requestDetailsError());
      });
  };
};

export const deleteCase = (requestId) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .delete(`/api/v1/relocation-requests/${requestId}/`)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error.response?.data) {
          errorMessage = error.response?.data;
        }
        toast.errorMessage = errorMessage;
        toast.type = 'error';
        return error.response;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const getEmployeeDocuments = (requestId) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/employee-edit/cases/${requestId}/`)
      .then((response) => {
        dispatch(actionCreators.requestDocumentsLoaded(response.data, requestId));
        dispatch(
          actionCreators.requestDetailsLoaded(
            {
              employeeDocuments: response.data?.employeeDocuments,
              employeeForms: response.data?.employeeForms,
              employee: response.data?.employee,
              relatedCases: response.data?.relatedCases,
            },
            requestId,
          ),
        );
        response.data?.relativeRequests.forEach((relativeDocument) =>
          dispatch(
            actionCreators.relatedInfoLoaded(
              {
                documents: relativeDocument.documents,
                relative: relativeDocument.relative,
                forms: relativeDocument.forms,
              },
              relativeDocument.id,
              relativeDocument.relative.id,
            ),
          ),
        );
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const getProfileDocuments = (employeeId) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/employees/employee-edit/${employeeId}/profile/`)
      .then((response) => {
        dispatch(
          actionCreators.profileDocumentsLoad(
            {
              documentRequest: response.data?.documentRequest,
              employeeDocuments: response.data?.documents,
              employeeForms: response.data?.forms,
              employee: {
                internalId: response.data?.internalId,
                uuid: response.data?.uuid,
                generalInfo: response.data?.generalInfo,
              },
            },
            employeeId,
          ),
        );
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const sendEmployeeDocuments = (requestId, data) => {
  return axios.post(`/api/v1/relocation-requests/employee-edit/${requestId}/documents/`, data).then((response) => {
    return response.data;
  });
};

export const sendProfileDocuments = (employeeId, data) => {
  return axios.post(`/api/v1/employees/employee-edit/${employeeId}/documents/`, data).then((response) => {
    return response.data;
  });
};

export const sendEmployeeDocumentsNotification = (requestId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .post(`/api/v1/relocation-requests/${requestId}/documents-notice/`, data)
      .then((response) => {
        dispatch(
          actionCreators.requestDetailsUpdated(
            {
              isDocsEditable: true,
              requestedDocuments: {
                docsRequestedAt: response.data.docsRequestedAt,
                deadline: response.data.deadline,
                isSendFurtherEmail: response.data.isSendFurtherEmail,
                id: response.data.id,
              },
            },
            requestId,
          ),
        );
        return response.data;
      })
      .catch((error) => {
        toast.errorMessage = error.response?.data;
        toast.type = 'error';
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const sendEmployeeProfileDocumentsNotification = (employeeId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .post(`/api/v1/employees/${employeeId}/profile/documents-request`, data)
      .then((response) => {
        dispatch(
          actionCreators.employeeProfileUpdate(
            {
              isDocsEditable: true,
              requestedDocuments: {
                docsRequestedAt: response.data.docsRequestedAt,
                isSendFurtherEmail: response.data.isSendFurtherEmail,
                deadline: response.data.deadline,
                id: response.data.id,
              },
            },
            employeeId,
          ),
        );
        return response.data;
      })
      .catch((error) => {
        // let errorMessage = '';
        // if (error.response?.data) {
        //   errorMessage = parse(error.response?.data);
        // }
        toast.errorMessage = error.response?.data;
        toast.type = 'error';
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const getDocumentsIntro = () => {
  return () => {
    return axios.get(`/api/v1/relocation-requests/employee-edit/documents-intro-text/`).then((response) => {
      return response.data;
    });
  };
};

export const editGeneralInfo = (employeeId, requestId, data, isDocuments) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    axios
      .put(
        isDocuments
          ? `/api/v1/employees/employee-edit/${employeeId}/general-info/`
          : `/api/v1/employees/${employeeId}/general-info/`,
        data,
      )
      .then((response) => {
        isDocuments
          ? dispatch(actionCreators.generalDocumentsInfoUpdated(response.data, employeeId))
          : dispatch(actionCreators.generalInfoUpdated(response.data, requestId, employeeId));
      })
      .catch((error) => {
        dispatch(actionCreators.requestDetailsError());
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editContactInfo = (id, requestId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    axios
      .put(`/api/v1/employees/${id}/contact-info/`, data)
      .then((response) => {
        dispatch(actionCreators.contactInfoUpdated(response.data, requestId, id));
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editEmploymentInfo = (employeeId, employmentInfoId, data) => async (dispatch) => {
  try {
    const response = await axios.put(`/api/v1/employees/${employeeId}/employment-info/${employmentInfoId}`, data);
    dispatch(actionCreators.employmentInfoUpdate(response.data, employeeId));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const upsertEmploymentInfo = (valuesToUpdate, valuesToAdd, employeeId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const employmentInfoToUpdatePromises = valuesToUpdate.map((item) => {
      return dispatch(editEmploymentInfo(employeeId, item.id, item));
    });

    const employmentInfoToAddPromises = valuesToAdd.map((item) => {
      return dispatch(addEmploymentInfo(employeeId, item));
    });

    const promises = employmentInfoToUpdatePromises.concat(employmentInfoToAddPromises);

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const upsertRelocatedEmploymentInfo = (valuesToUpdate, employeeId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const employmentInfoToUpdatePromises = valuesToUpdate.map((item) => {
      return dispatch(editEmploymentInfo(employeeId, item.id, item));
    });

    return Promise.all(employmentInfoToUpdatePromises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addAddressInfo = async (id, data) => {
  try {
    const response = await axios.post(`/api/v1/employees/${id}/addresses/`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editAddressInfo = async (employeeId, addressId, data) => {
  try {
    const response = await axios.put(`/api/v1/employees/${employeeId}/addresses/${addressId}/`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addDocument = (employeeId, gfId, data, isDraft) => {
  return (dispatch) => {
    return axios
      .post(
        isDraft
          ? `/api/v1/employees/${employeeId}/gf/employee-edit/${gfId}/`
          : `/api/v1/employees/${employeeId}/gf/${gfId}/`,
        data,
      )
      .then((response) => {
        dispatch(actionCreators.documentAdd(response.data, gfId, employeeId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const editDocument = (employeeId, gfId, formId, data, type, relatedId, relatedReqId, isDraft) => {
  return (dispatch) => {
    return axios
      .put(
        isDraft
          ? `/api/v1/employees/${employeeId}/gf/employee-edit/${gfId}/form/${formId}/`
          : `/api/v1/employees/${employeeId}/gf/${gfId}/form/${formId}/`,
        data,
      )
      .then((response) => {
        if (type === 'employee') {
          dispatch(actionCreators.documentUpdate(response.data, gfId, formId, employeeId));
        }
        if (type === 'related') {
          dispatch(actionCreators.relatedDocumentUpdate(response.data, gfId, formId, relatedId, relatedReqId));
        }
        if (type === 'pet') {
          dispatch(actionCreators.petsDocumentUpdate(response.data, gfId, formId, relatedId, relatedReqId));
        }
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const currentDocumentUpdate = (gfId, formId, data, type, relatedId, relatedReqId, employeeId) => {
  return (dispatch) => {
    return axios
      .put(`/api/v1/employees/${gfId}/form/${formId}/current/`, data)
      .then((response) => {
        if (type === 'employee') {
          dispatch(actionCreators.documentUpdate(response.data, gfId, formId, employeeId));
        }
        if (type === 'related') {
          dispatch(actionCreators.relatedDocumentUpdate(response.data, gfId, formId, relatedId, relatedReqId));
        }
        if (type === 'pet') {
          dispatch(actionCreators.petsDocumentUpdate(response.data, gfId, formId, relatedId, relatedReqId));
        }
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const upsertDocuments = (
  documentsToUpdate,
  documentsToAdd,
  employeeId,
  gfId,
  type,
  isDraft,
  valuesWithFilesToUpdate,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const documentsToAddPromises = documentsToAdd.map((item) => {
      return dispatch(addDocument(employeeId, gfId, item, isDraft)).then((response) => {
        const attachmentPromises =
          (item?.files && dispatch(attachmentUpload(item.files, employeeId, gfId, response.formId, type))) || [];
        return Promise.all(attachmentPromises.flat());
      });
    });

    const documentsToUpdatePromises = documentsToUpdate.map((item) => {
      return dispatch(editDocument(employeeId, gfId, item.id, item, type, undefined, undefined, isDraft));
    });

    const uploadAttachmentsPromises = valuesWithFilesToUpdate.map((item) => {
      return item?.files && dispatch(attachmentUpload(item.files, employeeId, gfId, item.id, type));
    });

    const promises = documentsToAddPromises.concat(documentsToUpdatePromises).concat(uploadAttachmentsPromises.flat());

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addRelatedDocument = (relativeId, gfId, data, isDraft, relatedReqId) => {
  return (dispatch) => {
    return axios
      .post(
        isDraft
          ? `/api/v1/employees/relative/${relativeId}/gf/employee-edit/${gfId}/`
          : `/api/v1/employees/relative/${relativeId}/gf/${gfId}/`,
        data,
      )
      .then((response) => {
        dispatch(actionCreators.relatedDocumentAdd(response.data, gfId, relatedReqId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const upsertDocumentsWithCurrentField = (
  documentsToUpdate,
  documentsToAdd,
  employeeId,
  gfId,
  type,
  isDraft,
  isCurrentDocumentsToUpdate,
  valuesWithFilesToUpdate,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const documentsToAddPromises = documentsToAdd.map((item) => {
      return dispatch(addDocument(employeeId, gfId, item, isDraft)).then((response) => {
        const attachmentPromises =
          item?.files && dispatch(attachmentUpload(item.files, employeeId, gfId, response.formId, type));

        const isCurrentPromises =
          item?.isCurrent &&
          dispatch(
            currentDocumentUpdate(
              gfId,
              response.formId,
              { isCurrent: item?.isCurrent },
              type,
              undefined,
              undefined,
              employeeId,
            ),
          );

        let promises = [];

        isCurrentPromises && promises.push(isCurrentPromises);
        promises = attachmentPromises ? promises.concat(attachmentPromises.flat()) : promises;

        return Promise.all(promises);
      });
    });

    const documentsToUpdatePromises = documentsToUpdate.map((item) => {
      return dispatch(editDocument(employeeId, gfId, item.id, item, type, undefined, undefined, isDraft));
    });

    const uploadAttachmentsPromises = valuesWithFilesToUpdate.map((item) => {
      return item?.files && dispatch(attachmentUpload(item.files, employeeId, gfId, item.id, type));
    });

    const isCurrentDocumentsToUpdatePromises =
      isCurrentDocumentsToUpdate &&
      isCurrentDocumentsToUpdate.map((item) => {
        return dispatch(
          currentDocumentUpdate(gfId, item.id, { isCurrent: item?.isCurrent }, type, undefined, undefined, employeeId),
        );
      });

    const promises = documentsToAddPromises
      .concat(documentsToUpdatePromises)
      .concat(isCurrentDocumentsToUpdatePromises)
      .concat(uploadAttachmentsPromises.flat());

    return Promise.all(promises)
      .then((response) => {
        toast.type = 'success';
        return response;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const upsertRelatedDocuments = (
  documentsToUpdate,
  documentsToAdd,
  requestId,
  relatedId,
  relatedReqId,
  gfId,
  type,
  isDraft,
  employeeId,
  valuesWithFilesToUpdate,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const documentsToAddPromises = documentsToAdd.map((item) => {
      return dispatch(addRelatedDocument(relatedId, gfId, item, isDraft, relatedReqId)).then((response) => {
        const attachmentPromises =
          (item?.files &&
            dispatch(attachmentUpload(item.files, employeeId, gfId, response.formId, type, relatedId, relatedReqId))) ||
          [];
        return Promise.all(attachmentPromises.flat());
      });
    });

    const documentsToUpdatePromises = documentsToUpdate.map((item) => {
      return dispatch(editDocument(employeeId, gfId, item.id, item, type, relatedId, relatedReqId, isDraft));
    });

    const uploadAttachmentsPromises = valuesWithFilesToUpdate.map((item) => {
      return (
        item?.files && dispatch(attachmentUpload(item.files, employeeId, gfId, item.id, type, relatedId, relatedReqId))
      );
    });

    const promises = documentsToAddPromises.concat(documentsToUpdatePromises).concat(uploadAttachmentsPromises.flat());

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addPetDocument = (petId, petReqId, gfId, data, isDraft) => {
  return (dispatch) => {
    return axios
      .post(
        isDraft
          ? `/api/v1/employees/pet/${petId}/gf/employee-edit/${gfId}/`
          : `/api/v1/employees/pet/${petId}/gf/${gfId}/`,
        data,
      )
      .then((response) => {
        dispatch(actionCreators.petsDocumentAdd(response.data, gfId, petId, petReqId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const upsertRelatedDocumentsWithCurrentField = (
  documentsToUpdate,
  documentsToAdd,
  requestId,
  relatedId,
  relatedReqId,
  gfId,
  type,
  isDraft,
  isCurrentDocumentsToUpdate,
  employeeId,
  valuesWithFilesToUpdate,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const documentsToAddPromises = documentsToAdd.map((item) => {
      return dispatch(addRelatedDocument(relatedId, gfId, item, isDraft, relatedReqId)).then((response) => {
        const attachmnetPromises =
          (item?.files &&
            dispatch(attachmentUpload(item.files, employeeId, gfId, response.formId, type, relatedId, relatedReqId))) ||
          [];

        const isCurrentPromises =
          (item?.isCurrent &&
            dispatch(
              currentDocumentUpdate(
                gfId,
                response.formId,
                { isCurrent: item?.isCurrent },
                type,
                relatedId,
                relatedReqId,
              ),
            )) ||
          [];
        return Promise.all(attachmnetPromises.flat().concat(isCurrentPromises));
      });
    });

    const documentsToUpdatePromises = documentsToUpdate.map((item) => {
      return dispatch(editDocument(employeeId, gfId, item.id, item, type, relatedId, relatedReqId, isDraft));
    });

    const isCurrentDocumentsToUpdatePromises =
      isCurrentDocumentsToUpdate &&
      isCurrentDocumentsToUpdate.map((item) => {
        return dispatch(
          currentDocumentUpdate(gfId, item.id, { isCurrent: item?.isCurrent }, type, relatedId, relatedReqId),
        );
      });

    const uploadAttachmentsPromises = valuesWithFilesToUpdate.map((item) => {
      return (
        item?.files && dispatch(attachmentUpload(item.files, employeeId, gfId, item.id, type, relatedId, relatedReqId))
      );
    });

    const promises = documentsToAddPromises
      .concat(documentsToUpdatePromises)
      .concat(isCurrentDocumentsToUpdatePromises)
      .concat(uploadAttachmentsPromises.flat());

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const upsertPetDocuments = (
  documentsToUpdate,
  documentsToAdd,
  petId,
  petReqId,
  gfId,
  type,
  isDraft,
  employeeId,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const documentsToAddPromises = documentsToAdd.map((item) => {
      return dispatch(addPetDocument(petId, petReqId, gfId, item, isDraft));
    });

    const documentsToUpdatePromises = documentsToUpdate.map((item) => {
      return dispatch(editDocument(employeeId, gfId, item.id, item, type, petId, petReqId, isDraft));
    });

    const promises = documentsToAddPromises.concat(documentsToUpdatePromises);

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const upsertPetDocumentsWithCurrentField = (
  documentsToUpdate,
  documentsToAdd,
  petId,
  petReqId,
  gfId,
  type,
  isDraft,
  isCurrentDocumentsToUpdate,
  employeeId,
  valuesWithFilesToUpdate,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const documentsToAddPromises = documentsToAdd.map((item) => {
      return dispatch(addPetDocument(petId, petReqId, gfId, item, isDraft)).then((response) => {
        const attachmentPromises =
          item?.files &&
          dispatch(attachmentUpload(item.files, employeeId, gfId, response.formId, type, null, null, petId, petReqId));

        const isCurrentPromises =
          item?.isCurrent &&
          dispatch(currentDocumentUpdate(gfId, response.formId, { isCurrent: item?.isCurrent }, type, petId, petReqId));

        let promises = [];
        isCurrentPromises && promises.push(isCurrentPromises);
        promises = attachmentPromises ? promises.concat(attachmentPromises.flat()) : promises;

        return Promise.all(promises);
      });
    });

    const documentsToUpdatePromises = documentsToUpdate.map((item) => {
      return dispatch(editDocument(employeeId, gfId, item.id, item, type, petId, petReqId, isDraft));
    });

    const isCurrentDocumentsToUpdatePromises =
      isCurrentDocumentsToUpdate &&
      isCurrentDocumentsToUpdate.map((item) => {
        return dispatch(currentDocumentUpdate(gfId, item.id, { isCurrent: item?.isCurrent }, type, petId, petReqId));
      });

    const uploadAttachmentsPromises = valuesWithFilesToUpdate.map((item) => {
      return (
        item?.files &&
        dispatch(attachmentUpload(item.files, employeeId, gfId, item.id, type, null, null, petId, petReqId))
      );
    });

    const promises = documentsToAddPromises
      .concat(documentsToUpdatePromises)
      .concat(isCurrentDocumentsToUpdatePromises)
      .concat(uploadAttachmentsPromises.flat());

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addRelocationDocument = (reqId, gfId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .post(`/api/v1/relocation-requests/${reqId}/constructor-forms/${gfId}/`, data)
      .then((response) => {
        dispatch(actionCreators.relocationDocumentAdd(response.data, gfId, reqId));
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editRelocationDocument = (reqId, gfId, formId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .put(`/api/v1/relocation-requests/${reqId}/constructor-forms/${gfId}/form/${formId}/`, data)
      .then((response) => {
        dispatch(actionCreators.relocationDocumentUpdate(response.data, gfId, formId, reqId));
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editRelativeGeneralInformation = (id, rel_id, rel_req_id, info) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/employees/${id}/relatives/${rel_id}/general-info/`, info);
    dispatch(actionCreators.relatedGeneralInfoUpdate(response.data, rel_id, rel_req_id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};
export const editRelativeContactInformation = (id, rel_id, rel_req_id, info) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/employees/${id}/relatives/${rel_id}/contact-info/`, info);
    dispatch(actionCreators.relatedContactInfoUpdate(response.data, rel_id, rel_req_id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};
export const editRelativeEmploymentInformation = (id, rel_id, rel_req_id, info) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/employees/${id}/relatives/${rel_id}/employment-info/`, info);
    dispatch(actionCreators.relatedEmploymentInfoUpdate(response.data, rel_id, rel_req_id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editRelativeEmploymentInfo = (info, employeeUuid, relativeId, relReqId) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(
      `/api/v1/employees/employee-edit/${employeeUuid}/relatives/${relativeId}/employment-info/`,
      info,
    );
    dispatch(actionCreators.relatedEmploymentInfoUpdate(response.data, relativeId, relReqId));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editGeneralPetProfile = (id, rel_id, rel_req_id, info) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/employees/${id}/pets/${rel_id}/`, info);
    dispatch(actionCreators.petsGeneralInfoUpdate(response.data, rel_req_id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const addVacAppointment = async (id, info) => {
  try {
    const response = await axios.post(`/api/v1/relocation-requests/${id}/vac-appointments/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addVacAppointments = (vacAppointments, requestId) => {
  return (dispatch) => {
    const vacAppointmentsPromise = vacAppointments.map((vacInfo) => {
      return addVacAppointment(requestId, {
        ...vacInfo,
      })
        .then(async (response) => {
          const notesPromise = vacInfo.notes.map((note) => {
            if (note) {
              return addVacAppointmentNotes(response.id, { ...note });
            } else return null;
          });
          return { ...response, notes: await Promise.all(notesPromise) };
        })
        .catch((error) => {
          throw error;
        });
    });
    return Promise.all(vacAppointmentsPromise)
      .then((responses) => {
        responses.forEach((vacInfo) => {
          dispatch(actionCreators.vacAppointmentAdd(vacInfo, requestId));
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const editVacAppointment = async (id, info) => {
  try {
    const response = await axios.put(`/api/v1/relocation-requests/vac-appointments/${id}/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editVacAppointments = (vacAppointments, relatedReqId, relatedFlag) => {
  return (dispatch) => {
    let isDisplaySendVacDetails;
    const vacAppointmentsPromise = vacAppointments.map((vacInfo) => {
      return editVacAppointment(vacInfo.id, {
        ...vacInfo,
      })
        .then(async (response) => {
          isDisplaySendVacDetails = response.isDisplaySendVacDetails;
          const notesToUpdate = getValuesToUpdate(vacInfo.notes, response.vacAppointment.notes, ['author', 'text']);
          const notesToAdd = vacInfo.notes.filter(({ id }) => isNaN(id));

          const notesPromise = vacInfo.notes.map((note) => {
            if (notesToUpdate.includes(note)) {
              return editNotes(note.id, { ...note });
            }
            if (notesToAdd.includes(note)) {
              if (note) {
                return addVacAppointmentNotes(vacInfo.id, { ...note });
              } else return null;
            } else {
              return note;
            }
          });
          return { ...response.vacAppointment, notes: await Promise.all(notesPromise) };
        })
        .catch((error) => {
          throw error;
        });
    });
    return Promise.all(vacAppointmentsPromise)
      .then((responses) => {
        responses.forEach((vacInfo) => {
          if (relatedFlag) {
            dispatch(actionCreators.relatedVacAppointmentUpdate(vacInfo, relatedReqId, isDisplaySendVacDetails));
          } else {
            dispatch(actionCreators.vacAppointmentUpdate({ ...vacInfo, isSubmitting: true }, relatedReqId));
          }
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const upsertVacAppointments = (valuesToUpdate, valuesToAdd, vacAppointmentIsNotCurrent, requestId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    let promises = [];
    let vacAppointmentIsNotCurrentPromises, vacAppointmentsToUpdatePromises, vacAppointmentsToAddPromises;
    const relatedFlag = false;

    if (valuesToUpdate.length) {
      vacAppointmentIsNotCurrentPromises = dispatch(
        editVacAppointments(vacAppointmentIsNotCurrent, requestId, relatedFlag),
      )
        .then(async () => {
          vacAppointmentsToUpdatePromises = await dispatch(editVacAppointments(valuesToUpdate, requestId, relatedFlag));
        })
        .then(async () => {
          if (valuesToAdd.length) {
            vacAppointmentsToAddPromises = await dispatch(addVacAppointments(valuesToAdd, requestId));
          }
        });
    } else {
      if (valuesToAdd.length) {
        vacAppointmentsToAddPromises = dispatch(addVacAppointments(valuesToAdd, requestId));
      }
    }

    if (vacAppointmentIsNotCurrentPromises) {
      promises.push(vacAppointmentIsNotCurrentPromises);
    }
    if (vacAppointmentsToUpdatePromises) {
      promises.push(vacAppointmentsToUpdatePromises);
    }
    if (vacAppointmentsToAddPromises) {
      promises.push(vacAppointmentsToAddPromises);
    }

    return Promise.all(promises)
      .then((responses) => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addRelatedVacAppointment = async (requestId, relatedReqId, vacInfo) => {
  try {
    const response = await axios.post(
      `/api/v1/relocation-requests/${requestId}/relative-requests/${relatedReqId}/vac-appointments/`,
      vacInfo,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addRelatedVacAppointments = (vacAppointments, requestId, relatedReqId) => {
  return (dispatch) => {
    const vacAppointmentsPromises = vacAppointments.map((vacInfo) => {
      return addRelatedVacAppointment(requestId, relatedReqId, {
        ...vacInfo,
      })
        .then(async (response) => {
          const notesPromise = vacInfo.notes.map((note) => {
            if (note) {
              return addVacAppointmentNotes(response.id, { ...note });
            } else return null;
          });
          return { ...response, notes: await Promise.all(notesPromise) };
        })
        .catch((error) => {
          throw error;
        });
    });
    return Promise.all(vacAppointmentsPromises)
      .then((responses) => {
        responses.forEach((vacInfo) => {
          dispatch(actionCreators.relatedVacAppointmentAdd(vacInfo, relatedReqId));
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const upsertRelatedVacAppointments = (
  valuesToUpdate,
  valuesToAdd,
  vacAppointmentIsNotCurrent,
  requestId,
  relatedReqId,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    let promises = [];
    let vacAppointmentIsNotCurrentPromises, vacAppointmentsToUpdatePromises, vacAppointmentsToAddPromises;
    const relatedFlag = true;

    if (valuesToUpdate.length) {
      vacAppointmentIsNotCurrentPromises = dispatch(
        editVacAppointments(vacAppointmentIsNotCurrent, relatedReqId, relatedFlag),
      )
        .then(async () => {
          vacAppointmentsToUpdatePromises = await dispatch(
            editVacAppointments(valuesToUpdate, relatedReqId, relatedFlag),
          );
        })
        .then(async () => {
          if (valuesToAdd.length) {
            vacAppointmentsToAddPromises = await dispatch(
              addRelatedVacAppointments(valuesToAdd, requestId, relatedReqId),
            );
          }
        });
    } else {
      if (valuesToAdd.length) {
        vacAppointmentsToAddPromises = dispatch(addRelatedVacAppointments(valuesToAdd, requestId, relatedReqId));
      }
    }

    if (vacAppointmentIsNotCurrentPromises) {
      promises.push(vacAppointmentIsNotCurrentPromises);
    }
    if (vacAppointmentsToUpdatePromises) {
      promises.push(vacAppointmentsToUpdatePromises);
    }
    if (vacAppointmentsToAddPromises) {
      promises.push(vacAppointmentsToAddPromises);
    }

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const upsertTransportationDetails = (valuesToUpdate, valuesToAdd, requestId, valuesWithFilesToUpdate) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const transportationDetailsToUpdatePromises = dispatch(editAllTransportationDetails(valuesToUpdate, requestId));

    const transportationDetailsToAddPromises = dispatch(addAllTransportationDetails(valuesToAdd, requestId));

    const ticketAttachmentsPromises = valuesWithFilesToUpdate.map((item) => {
      return item?.files && dispatch(transportationTicketsUpload(item.files, item.id, 'employee', requestId));
    });

    let promises = [];
    promises.push(transportationDetailsToUpdatePromises);
    promises.push(transportationDetailsToAddPromises);
    promises = promises.concat(ticketAttachmentsPromises.flat());

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addVacAppointmentNotes = async (vacId, info) => {
  try {
    const response = await axios.post(`/api/v1/relocation-requests/vac-appointments/${vacId}/notes/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const editNotes = async (noteId, info) => {
  try {
    const response = await axios.put(`/api/v1/relocation-requests/notes/${noteId}/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addAllTransportationDetails = (transportationDetails, requestId) => {
  return (dispatch) => {
    const transportationDetailsPromises = transportationDetails.map((item) => {
      return addTransportationDetails(requestId, {
        ...item,
      })
        .then(async (response) => {
          const notesPromises = item.notes.map((note) => {
            if (note) {
              return addTransportationDetailsNotes(response.id, { ...note });
            } else return null;
          });
          const ticketsPromises =
            item?.files && dispatch(transportationTicketsUpload(item.files, response.id, 'employee', requestId));
          return { ...response, notes: await Promise.all(notesPromises) };
        })
        .catch((error) => {
          throw error;
        });
    });
    return Promise.all(transportationDetailsPromises)
      .then((responses) => {
        responses.forEach((item) => {
          dispatch(actionCreators.transportationDetailsAdd(item, requestId));
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const editAllTransportationDetails = (transportationDetails, typeRelated, relatedReqId) => {
  return (dispatch) => {
    const transportationDetailsPromises = transportationDetails.map((item) => {
      return editTransportationDetails(item.id, {
        ...item,
      })
        .then(async (response) => {
          const notesPromises = item.notes.map((note) => {
            const notesToUpdate = getValuesToUpdate(item.notes, response.notes, ['author', 'text']);
            const notesToAdd = item.notes.filter(({ id }) => isNaN(id));

            if (notesToUpdate.includes(note)) {
              return editNotes(note.id, { ...note });
            }
            if (notesToAdd.includes(note)) {
              if (note) {
                return addTransportationDetailsNotes(item.id, { ...note });
              } else return null;
            } else {
              return note;
            }
          });
          return { ...response, notes: await Promise.all(notesPromises) };
        })
        .catch((error) => {
          throw error;
        });
    });
    return Promise.all(transportationDetailsPromises)
      .then((responses) => {
        responses.forEach((item) => {
          if (typeRelated === 'related') {
            dispatch(actionCreators.relatedTransportationDetailsUpdate(item, relatedReqId));
          } else if (typeRelated === 'pets') {
            dispatch(actionCreators.petsTransportationDetailsUpdate(item, relatedReqId));
          } else {
            dispatch(actionCreators.transportationDetailsUpdate(item, typeRelated));
          }
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addTransportationDetails = async (id, info) => {
  try {
    const response = await axios.post(`/api/v1/relocation-requests/${id}/transportation-details/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editTransportationDetails = async (tdId, info) => {
  try {
    const response = await axios.put(`/api/v1/relocation-requests/transportation-details/${tdId}/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addTransportationDetailsNotes = async (tdId, info) => {
  try {
    const response = await axios.post(`/api/v1/relocation-requests/transportation-details/${tdId}/notes/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendVACdetails = (vacId, requestId) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/relocation-requests/vac-appointment-notification/${vacId}/employee/`);
    dispatch(actionCreators.currentVacAppointmentIsNotifiedUpdate(requestId));
    return response.data;
  } catch (error) {
    // let errorMessage = '';
    // if (error.response?.data) {
    //   errorMessage = parse(error.response?.data);
    // }
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
    throw error;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const sendVACdetailsRelated = (vacId, relatedId) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/relocation-requests/vac-appointment-notification/${vacId}/relative/`);
    dispatch(actionCreators.currentRelatedVacAppointmentIsNotifiedUpdate(relatedId));
    return response.data;
  } catch (error) {
    // let errorMessage = '';
    // if (error.response?.data) {
    //   errorMessage = parse(error.response?.data);
    // }
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
    throw error;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const addRelatedTransportationDetails = async (requestId, relativeReqId, info) => {
  try {
    const response = await axios.post(
      `/api/v1/relocation-requests/${requestId}/relative-requests/${relativeReqId}/transportation-details/`,
      info,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addAllRelatedTransportationDetails = (transportationDetails, requestId, relatedReqId) => {
  return (dispatch) => {
    const transportationDetailsPromises = transportationDetails.map((item) => {
      return addRelatedTransportationDetails(requestId, relatedReqId, {
        ...item,
      })
        .then(async (response) => {
          const notesPromises = item.notes.map((note) => {
            if (note) {
              return addTransportationDetailsNotes(response.id, { ...note });
            } else return null;
          });
          const ticketsPromises =
            item?.files &&
            dispatch(transportationTicketsUpload(item.files, response.id, 'related', requestId, relatedReqId));
          return { ...response, notes: await Promise.all(notesPromises) };
        })
        .catch((error) => {
          throw error;
        });
    });
    return Promise.all(transportationDetailsPromises)
      .then((responses) => {
        responses.forEach((item) => {
          dispatch(actionCreators.relatedTransportationDetailsAdd(item, relatedReqId));
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const upsertRelatedTransportationDetails = (
  valuesToUpdate,
  valuesToAdd,
  requestId,
  relatedReqId,
  valuesWithFilesToUpdate,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const transportationDetailsToUpdatePromises = dispatch(
      editAllTransportationDetails(valuesToUpdate, 'related', relatedReqId),
    );

    const transportationDetailsToAddPromises = dispatch(
      addAllRelatedTransportationDetails(valuesToAdd, requestId, relatedReqId),
    );

    const ticketAttachmentsPromises = valuesWithFilesToUpdate.map((item) => {
      return (
        item?.files && dispatch(transportationTicketsUpload(item.files, item.id, 'related', requestId, relatedReqId))
      );
    });

    let promises = [];
    promises.push(transportationDetailsToUpdatePromises);
    promises.push(transportationDetailsToAddPromises);
    promises = promises.concat(ticketAttachmentsPromises.flat());

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addPetTransportationDetails = async (requestId, petReqId, info) => {
  try {
    const response = await axios.post(
      `/api/v1/relocation-requests/${requestId}/pet-requests/${petReqId}/transportation-details/`,
      info,
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addAllPetTransportationDetails = (transportationDetails, requestId, petReqId) => {
  return (dispatch) => {
    const transportationDetailsPromises = transportationDetails.map((item) => {
      return addPetTransportationDetails(requestId, petReqId, {
        ...item,
      })
        .then(async (response) => {
          const notesPromises = item.notes.map((note) => {
            if (note) {
              return addTransportationDetailsNotes(response.id, { ...note });
            } else return null;
          });
          const ticketsPromises =
            item?.files &&
            dispatch(transportationTicketsUpload(item.files, response.id, 'pet', requestId, null, petReqId));

          return { ...response, notes: await Promise.all(notesPromises) };
        })
        .catch((error) => {
          throw error;
        });
    });
    return Promise.all(transportationDetailsPromises)
      .then((responses) => {
        responses.forEach((item) => {
          dispatch(actionCreators.petsTransportationDetailsAdd(item, petReqId));
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const upsertPetTransportationDetails = (
  valuesToUpdate,
  valuesToAdd,
  requestId,
  relatedReqId,
  valuesWithFilesToUpdate,
) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const transportationDetailsToUpdatePromises = dispatch(
      editAllTransportationDetails(valuesToUpdate, 'pets', relatedReqId),
    );

    const transportationDetailsToAddPromises = dispatch(
      addAllPetTransportationDetails(valuesToAdd, requestId, relatedReqId),
    );
    const ticketAttachmentsPromises = valuesWithFilesToUpdate.map((item) => {
      return (
        item?.files && dispatch(transportationTicketsUpload(item.files, item.id, 'pet', requestId, null, relatedReqId))
      );
    });

    let promises = [];
    promises.push(transportationDetailsToUpdatePromises);
    promises.push(transportationDetailsToAddPromises);
    promises = promises.concat(ticketAttachmentsPromises.flat());

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const addAccommodationDetails = async (id, info) => {
  try {
    const response = await axios.post(`/api/v1/relocation-requests/${id}/accommodation-details/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editAccommodationDetails = async (id, info) => {
  try {
    const response = await axios.put(`/api/v1/relocation-requests/accommodation-details/${id}/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const addAccommodationDetailsNotes = async (id, info) => {
  try {
    const response = await axios.post(`/api/v1/relocation-requests/accommodation-details/${id}/notes/`, info);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const editAllAccommodationDetails = (accommodationDetails, requestId) => {
  return (dispatch) => {
    const accommodationDetailsPromises = accommodationDetails.map((item) => {
      return editAccommodationDetails(item.id, {
        ...item,
      })
        .then(async (response) => {
          const notesToUpdate = getValuesToUpdate(item.notes, response.notes, ['author', 'text']);
          const notesToAdd = item.notes.filter(({ id }) => isNaN(id));

          const notesPromise = item.notes.map((note) => {
            if (notesToUpdate.includes(note)) {
              return editNotes(note.id, { ...note });
            }
            if (notesToAdd.includes(note)) {
              if (note) {
                return addAccommodationDetailsNotes(item.id, { ...note });
              } else return null;
            } else {
              return note;
            }
          });
          return { ...response, notes: await Promise.all(notesPromise) };
        })
        .catch((error) => {
          throw error;
        });
    });
    return Promise.all(accommodationDetailsPromises)
      .then((responses) => {
        responses.forEach((accommodationInfo) => {
          dispatch(actionCreators.accommodationDetailsUpdate(accommodationInfo, requestId));
        });
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addAllAccommodationDetails = (accommodationDetails, requestId) => {
  return (dispatch) => {
    const accommodationDetailsPromises = accommodationDetails.map((item) => {
      return addAccommodationDetails(requestId, {
        ...item,
      }).then(async (response) => {
        const notesPromise = item.notes.map((note) => {
          if (note) {
            return addAccommodationDetailsNotes(response.id, { ...note });
          } else return null;
        });
        const attachmentsPromises =
          item?.files && dispatch(accommodationAttachmentsUpload(item.files, response.id, requestId));

        return { ...response, notes: await Promise.all(notesPromise) };
      });
    });
    return Promise.all(accommodationDetailsPromises).then((responses) => {
      responses.forEach((item) => {
        dispatch(actionCreators.accommodationDetailsAdd(item, requestId));
      });
    });
  };
};

export const upsertAccommodationDetails = (valuesToUpdate, valuesToAdd, requestId, valuesWithFilesToUpdate) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };

    const accommodationDetailsToUpdatePromises = dispatch(editAllAccommodationDetails(valuesToUpdate, requestId));

    const accommodationDetailsToAddPromises = dispatch(addAllAccommodationDetails(valuesToAdd, requestId));

    const attachmentsPromises = valuesWithFilesToUpdate.map((item) => {
      return item?.files && dispatch(accommodationAttachmentsUpload(item.files, item.id, requestId));
    });

    let promises = [];
    promises.push(accommodationDetailsToUpdatePromises);
    promises.push(accommodationDetailsToAddPromises);
    promises = promises.concat(attachmentsPromises.flat());

    return Promise.all(promises)
      .then(() => {
        toast.type = 'success';
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const getRelocationHistory = (requestId, page) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/${requestId}/history/`, {
        params: { page: page },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.requestHistoryLoaded(response.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.requestHistoryError());
      });
  };
};

export const getCompanies = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/employees/companies/');
    dispatch(actionCreators.companiesLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getUnits = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/employees/units/?limit=999');
    dispatch(actionCreators.unitsLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const addEmploymentInfo = (employeeId, data) => async (dispatch) => {
  try {
    const response = await axios.post(`/api/v1/employees/${employeeId}/employment-info/`, data);
    dispatch(actionCreators.relocatedEmploymentInfoAdd(response.data, employeeId));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const changeVacRequired = (id, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/relocation-requests/${id}/vac-required/`, data);
    dispatch(actionCreators.changingVacRequired(data, id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
    throw error;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const changeForeignPoliceRequired = (id, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/relocation-requests/${id}/foreign-police-required/`, data);
    dispatch(actionCreators.changingForeignPoliceRequired(data, id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
    throw error;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const changeTdRequired = (id, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/relocation-requests/${id}/td-required/`, data);
    dispatch(actionCreators.changingTdRequired(data, id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
    throw error;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const changeRelatives = (id, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/relocation-requests/${id}/relatives/`, data);
    dispatch(actionCreators.changingRelatives(response.data, id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
    throw error;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const addPlannedRelocationNotes = (request_id, info) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/relocation-requests/${request_id}/notes/`, info);
    dispatch(actionCreators.plannedRelocationNotesAdd(response.data, request_id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editPlannedRelocationNotes = (noteId, info, requestId) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/relocation-requests/notes/${noteId}/`, info);
    dispatch(actionCreators.plannedRelocationNotesUpdate(response.data, requestId));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const notifyHR = (id) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .post(`/api/v1/relocation-requests/${id}/notify-hr/`, '')
      .then((response) => {
        dispatch(actionCreators.requestDetailsUpdated({ isHrNotified: true }, id));
        dispatch(
          actionCreators.requestListUpdate({
            requestList: { isHrNotified: true },
            requestId: id,
          }),
        );
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const notifyManager = (id) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .post(`/api/v1/relocation-requests/${id}/notify-manager/`, '')
      .then((response) => {
        dispatch(actionCreators.requestDetailsUpdated({ isManagerNotified: true }, id));
        dispatch(
          actionCreators.requestListUpdate({
            requestList: { isManagerNotified: true },
            requestId: id,
          }),
        );
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
        dispatch(actionCreators.requestDetailsError());
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const getHrNotes = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/relocation-requests/${id}/hr-notes/`);
    dispatch(actionCreators.hrNotesLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const editHrNotes = (id, note) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/relocation-requests/${id}/hr-notes/`, note);
    dispatch(actionCreators.hrNotesUpdate(response.data));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const getResponsibleGMG = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/employees/gmt/');
    dispatch(actionCreators.responsibleGMGLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const editResponsible = (id, responsible) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .put(`/api/v1/relocation-requests/${id}/gmg-responsible/`, responsible)
      .then((response) => {
        dispatch(
          actionCreators.requestDetailsUpdated(
            {
              gmtMember: response.data.gmtMember,
              caseGmtMember: response.data.caseGmtMember,
              arsGmtMember: response.data.arsGmtMember,
              status: response.data.status,
            },
            id,
          ),
        );
        dispatch(
          actionCreators.requestCaseUpdate({
            requestCaseList: {
              gmtMember: response.data.gmtMember,
              caseGmtMember: response.data.caseGmtMember,
              arsGmtMember: response.data.arsGmtMember,
              status: response.data.status,
            },
            caseId: id,
          }),
        );
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const getNationalities = () => {
  return (dispatch) => {
    axios
      .get('/api/v1/geo/nationalities/?limit=999')
      .then((response) => {
        dispatch(actionCreators.requestNationalitiesLoaded(response.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.requestNationalitiesError());
      });
  };
};

export const exportExcel = (data, params) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .post('/api/v1/relocation-requests/cases/export', data, {
        params: { ...params, case_type: 'Standard' },
        responseType: 'blob',
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        const fileName =
          response.headers['content-disposition'].split('filename=')[1].replace(/"/g, '') || `${Date.now()}.xlsx`;

        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        dispatch(actionCreators.generationExcelCheckboxesRemove());
        return 1;
      })
      .catch(async (error) => {
        toast.type = 'error';
        const responseData = await error.response?.data;
        const responseMessage = responseData === 'Internal server error' ? responseData : responseData.text();
        toast.errorMessage = await responseMessage;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const getCitizenships = () => {
  return (dispatch) => {
    return axios
      .get('/api/v1/geo/citizenship/?limit=999')
      .then((response) => {
        dispatch(actionCreators.requestCitizenShipsLoaded(response.data));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const editRelatedRequest = (id, requestId, related) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/employees/${id}/relatives/`, related);
    dispatch(actionCreators.relatedRequestsAdd(response.data, requestId));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editRelatedPetRequest = (id, requestId, related) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/employees/${id}/pets/`, related);
    dispatch(actionCreators.relatedPetRequestAdd(response.data, requestId));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const addNewCase = (data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/relocation-requests/cases/`, data);
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const addRelativesWithGeneralInfo = (employeeId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .post(`/api/v1/employees/employee-edit/${employeeId}/relatives-with-general-info/`, data)
      .then((response) => {
        dispatch(actionCreators.relatedDocRequestsAdd(response.data, employeeId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const deleteRelativeRequest = (requestId, requestRelId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/relocation-requests/${requestId}/relative-requests/${requestRelId}/`)
      .then((response) => {
        dispatch(actionCreators.deleteRelativeRequest(requestRelId, requestId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const deleteRelativeProfile = (requestId, requestRelId, relId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/relocation-requests/employee-edit/${requestId}/relative-requests/${requestRelId}/`)
      .then((response) => {
        dispatch(actionCreators.deleteRelativeProfile(requestRelId, relId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
export const deletePetRequest = (requestId, requestRelId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/relocation-requests/${requestId}/pet-requests/${requestRelId}/`)
      .then((response) => {
        dispatch(actionCreators.deletePetRequest(requestRelId, requestId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const deletePetProfile = (requestId, requestRelId, petId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    axios
      .delete(`/api/v1/relocation-requests/employee-edit/${requestId}/pet-requests/${requestRelId}/`)
      .then((response) => {
        dispatch(actionCreators.deletePetProfile(requestRelId, petId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editRelativeDocGeneralInformation = (id, rel_id, rel_req_id, info) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/employees/employee-edit/${id}/relatives/${rel_id}/general-info/`, info);
    dispatch(actionCreators.relatedDocGeneralInfoUpdate(response.data, rel_id, rel_req_id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editDocGeneralPetProfile = (id, rel_id, rel_req_id, info) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/employees/employee-edit/${id}/pets/${rel_id}/`, info);
    dispatch(actionCreators.petsDocGeneralInfoUpdate(response.data, rel_req_id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editRelatedDocPetRequest = (id, requestId, related) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/employees/employee-edit/${id}/pets/`, related);
    dispatch(actionCreators.relatedPetDocRequestAdd(response.data, requestId));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const changeVacDocRequired = (id, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/relocation-requests/employee-edit/${id}/vac-required/`, data);
    dispatch(actionCreators.changingVacDocRequired(data, id));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
    throw error;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editDocRelatedRequest = (id, requestId, related) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/employees/employee-edit/${id}/relatives/`, related);
    dispatch(actionCreators.relatedDocRequestsAdd(response.data, requestId));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const setExistingRelatives = (requestId, relatives) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(
      `/api/v1/relocation-requests/employee-edit/${requestId}/existing-relatives/`,
      relatives,
    );
    dispatch(
      actionCreators.requestExistRelativesAdd(
        relatives,
        requestId,
        response.data.relativeRequests,
        response.data.petRequests,
      ),
    );
    dispatch(
      actionCreators.existingRelatedDocAdd(response.data.relativeRequests, response.data.petRequests, relatives),
    );
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const linkRequestWithTechArtEmployee = (data, requestId, employeeId) => {
  return (dispatch) => {
    let toast = { type: 'success', messageError: '' };
    return axios
      .post(`/api/v1/relocation-requests/employee-edit/${requestId}/related-cases/`, data)
      .then((response) => {
        dispatch(actionCreators.relatedCasesUpdated(response.data, employeeId));
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
