import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { Tooltip } from 'antd';

import { updateViewedEvents } from '../../store/actions/events';
import EventsIcon from '../../assets/img/icons/events.svg';
import AlertEventsIcon from '../../assets/img/icons/events-alert.svg';
import { getEventIdsForCase } from '../../helpers';
import { defaultDateFormat } from '../../constants';

import './events.scss';

const Events = ({ instanceId, events, eventsType, tooltipPlace }) => {
  const dispatch = useDispatch();
  const eventsInfo = useSelector((state) => state.eventsInfoReducer[eventsType][instanceId]);

  const [isHasNewEvents, setIsHasNewEvents] = useState(null);

  useEffect(() => {
    setIsHasNewEvents(events.some((item) => !item.isViewedEvent));
  }, [events]);

  useEffect(() => {
    setIsHasNewEvents(events.some((item) => !item.isViewedEvent));
  }, [eventsInfo]);

  const limitTooltipMessage = (string, limit) => {
    return string.length <= limit ? string : string.substring(0, limit - 3).concat('..');
  };

  let tooltipText = events.slice(0, 5).map((item, index) => {
    const isNotViewedEvent = Boolean(!item.isViewedEvent && isHasNewEvents && !eventsInfo?.isViewed);
    return (
      <div key={index} className={classNames('event', isNotViewedEvent && 'not-viewed-event')}>
        {dayjs(item.createdAt).format(defaultDateFormat)}: {limitTooltipMessage(item.message, 48)}.
      </div>
    );
  });

  const onEventsViewed = (requestId) => {
    if (isHasNewEvents && !eventsInfo?.isViewed) {
      setIsHasNewEvents(false);

      const eventIds = getEventIdsForCase(events);
      dispatch(updateViewedEvents(requestId, eventsType, { viewedEventIds: eventIds }));
    }
  };

  return (
    <Tooltip
      placement={tooltipPlace ? tooltipPlace : 'top'}
      title={tooltipText}
      arrowPointAtCenter={true}
      overlayClassName={'events-tooltip'}
    >
      <img
        src={isHasNewEvents && !eventsInfo?.isViewed ? AlertEventsIcon : EventsIcon}
        className="event-icon__wrapper event-icon"
        onMouseLeave={() => {
          setTimeout(() => {
            onEventsViewed(instanceId);
          }, 300);
        }}
        alt={'events'}
      />
    </Tooltip>
  );
};

export default Events;
