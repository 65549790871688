import { getGuidelineInfo } from './guidelineSchema';
import GuideItem from './GuideItem/GuideItem';
import './guidline.scss';

const GuideLine = ({ userType }) => {
  return (
    <div className="guidline__container">
      <h3 className="underlined">Guideline</h3>
      <p className="underlined-text">Please read carefully the provided below guidance.</p>
      <div className="guid-items">
        {getGuidelineInfo(userType).map((item, index) => (
          <GuideItem number={index + 1} title={item.title} key={index} img={item.img} />
        ))}
      </div>
    </div>
  );
};

export default GuideLine;
