import { types } from '../actions/types';

const initialState = {
  searchEmployeesList: [],
  next: null,
  previous: null,
};

const searchEmployeesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SEARCH_EMPLOYEES_LOADED:
      return {
        ...state,
        searchEmployeesList: action.payload.searchEmployeesList,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.SEARCH_EMPLOYEES_UPDATED:
      return {
        ...state,
        searchEmployeesList: [...state.requestList, ...action.payload.searchEmployeesList],
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.SEARCH_EMPLOYEES_ERROR:
      return {
        ...state,
        searchEmployeesList: action.payload.searchEmployeesList,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    default:
      return {
        ...state,
      };
  }
};

export default searchEmployeesReducer;
