import { useContext } from 'react';

import { useDispatch } from 'react-redux';
import { getValuesToUpdate } from '../../../helpers';
import { upsertTransportationDetails } from '../../../store/actions/requestDetails';
import TransportationDetails from '../../Common/TransportationDetails/TransportationDetails';
import { IsCaseDisabledContext } from '../RequestDetails';
import { formSchema } from './requestSchema';

const transportationDetailsInitialValues = {
  isTheSameForRelated: false,
  transportType: '',
  ticketCost: 0,
  currency: 'usd',
  ticketAmount: 1,
  tickets: [
    {
      date: '',
      time: '',
      travelFrom: '',
      travelTo: '',
      address: '',
    },
  ],
  files: [],
  notes: [],
};

const TransportationEmployeeDetails = (props) => {
  const { transportationDetailsInfo, requestId, isTicketsBooked, employeeId } = props;
  const dispatch = useDispatch();

  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const onSubmit = (values, { setSubmitting, setFieldValue, resetForm, setStatus }) => {
    const valuesToUpdate = getValuesToUpdate(
      values.transportationDetailsInfo,
      transportationDetailsInfo,
      Object.keys(transportationDetailsInitialValues),
    );
    const valuesToAdd = values?.transportationDetailsInfo.filter(({ id }) => isNaN(id));

    const valuesToUpdateWithTicketAmount = valuesToUpdate.map((item) => {
      return { ...item, ticketAmount: item.tickets.length };
    });
    const valuesToAddWithTicketAmount = valuesToAdd.map((item) => {
      return { ...item, ticketAmount: item.tickets.length };
    });

    const valuesWithFilesToUpdate = getValuesToUpdate(values.transportationDetailsInfo, transportationDetailsInfo, [
      'files',
    ]);

    const files = values.transportationDetailsInfo.some((item) => item?.files?.length);

    if (valuesToUpdate.length || valuesToAdd.length || files) {
      dispatch(
        upsertTransportationDetails(
          valuesToUpdateWithTicketAmount,
          valuesToAddWithTicketAmount,
          requestId,
          valuesWithFilesToUpdate,
        ),
      );
    }
    setSubmitting(false);
  };

  return (
    <TransportationDetails
      transportationDetailsAllInfo={transportationDetailsInfo}
      isTicketsBookedInfo={isTicketsBooked}
      requestId={requestId}
      transportationEmployeeDetails={true}
      onSubmit={onSubmit}
      formSchema={formSchema}
      type="employee"
      isCaseDisabled={isCaseDisabled}
      employeeId={employeeId}
    />
  );
};

export default TransportationEmployeeDetails;
