import classNames from 'classnames';
import { Field, ErrorMessage } from 'formik';
import './timeField.scss';

const TimeField = (props) => {
  const { name, label, formIndex, nameFieldArray, disabled, isRequired } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  return (
    <div className="form-group form-group__column time-field">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}

      <div className="time-input__wrapper form-input-width">
        <Field
          className="form-control time-input"
          as="input"
          id={fieldName}
          name={fieldName}
          type="time"
          disabled={disabled}
        />
      </div>
      <ErrorMessage
        name={fieldName}
        render={(msg) => (
          <span className="error" style={{ color: 'red' }}>
            {msg}
          </span>
        )}
      />
    </div>
  );
};

export default TimeField;
