import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';

import Modal from '../../../Shared/Modal/Modal';

export const StatusModal = (props) => {
  const { isModalVisible, setIsModalVisible, redirectUrlPath } = props;

  const history = useHistory();

  const handleCloseModal = () => {
    setIsModalVisible((prev) => ({ ...prev, isVisible: false, status: false }));
    if (isModalVisible.status) {
      history.push('/legalization-documents');
    }
  };

  const message = isModalVisible.status
    ? 'Thank you! Your message has been sent.'
    : isModalVisible.error
    ? parse(isModalVisible.error)
    : 'Sorry, something went wrong during sending email. Try to send email again';

  const title = isModalVisible.status ? 'Thank you' : 'Error';

  return (
    <div className="send-email__status">
      <Modal isVisible={isModalVisible.isVisible} setIsVisible={handleCloseModal} size="l">
        <div className="modal-message">
          <h4 className="underlined panel-top-row">{title}</h4>
          <div className={'message'}>{message}</div>
          <button type="button" className="button btn-form main-btn btn-back ok-btn" onClick={handleCloseModal}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
};
