import { types } from '../actions/types';

const initialState = {
  count: null,
  reminders: [],
  authorList: [],
};

const remindersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REMINDERS_LOAD:
      return {
        ...state,
        count: action.payload.count,
        reminders: action.payload.reminders,
      };
    case types.REMINDERS_UPDATE:
      return {
        ...state,
        reminders: [...state.reminders, action.payload.reminders],
      };
    case types.REMINDER_UPDATE:
      return {
        ...state,
        reminders: state.reminders.reduce((accumulator, currentItem) => {
          if (currentItem.uuid === action.payload.reminderUuid) {
            accumulator.push(action.payload.reminder);
          } else {
            accumulator.push(currentItem);
          }
          return accumulator;
        }, []),
      };
    case types.REMINDERS_AUTHOR_LOAD:
      return {
        ...state,
        authorList: action.payload.authorList,
      };
    default:
      return {
        ...state,
      };
  }
};

export default remindersReducer;
