import { useEffect } from 'react';

import { getCallbackUserManager } from '../userManager';

const SignInSilentCallback = () => {
  const trySignInSilentAsync = async () => {
    const userManager = getCallbackUserManager();

    await userManager.signinSilentCallback();
  };

  useEffect(() => {
    const userManager = getCallbackUserManager();

    trySignInSilentAsync();
  }, []);

  return null;
};

export default SignInSilentCallback;
