import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCaseController, updateCaseController } from '../../../../store/actions/caseController';
import Checkbox from '../FormElements/Checkbox/Checkbox';
import { editRelocationRequest } from '../../../../store/actions/request';

const exclusionTabsVisaOnly = ['additionalStepsTab', 'relocationDateTab'];

const SelectTabsDisplay = ({ requestId, tabsInfo, caseType }) => {
  const dispatch = useDispatch();

  const controllerInfo = useSelector((state) => state.requestDetails[requestId]?.controller);

  const { instance, mapping } = controllerInfo || {};

  const { isVacRequired, isTransportationRequired, isAccommodationRequired, isForeignPoliceRequired } = tabsInfo;
  const isVisaOnlyCase = caseType && caseType.toLowerCase() === 'visa only';

  useEffect(() => {
    if (!controllerInfo) {
      dispatch(getCaseController(requestId));
    }
  }, []);

  const onChangeTabsController = (tabKey) => {
    dispatch(updateCaseController(requestId, { [tabKey]: !instance[tabKey] }));
  };

  const onVacRequired = (tabKey, value) => {
    dispatch(editRelocationRequest(requestId, { isVacRequired: value }));
  };

  const onForeignPoliceRequired = (tabKey, value) => {
    dispatch(editRelocationRequest(requestId, { isForeignPoliceRequired: value }));
  };

  const onTransportationRequired = (tabKey, value) => {
    dispatch(editRelocationRequest(requestId, { isTransportationRequired: value }));
  };

  const onAccommodationRequired = (tabKey, value) => {
    dispatch(editRelocationRequest(requestId, { isAccommodationRequired: value }));
  };

  const getOnChangeFunction = (tabKey) => {
    switch (tabKey) {
      case 'vacAppointmentTab':
        return onVacRequired;
      case 'foreignPoliceTab':
        return onForeignPoliceRequired;
      case 'transportationDetailsTab':
        return onTransportationRequired;
      case 'accommodationDetailsTab':
        return onAccommodationRequired;
      default:
        return onChangeTabsController;
    }
  };

  const getTabValue = (tabKey) => {
    switch (tabKey) {
      case 'vacAppointmentTab':
        return isVacRequired;
      case 'foreignPoliceTab':
        return isForeignPoliceRequired;
      case 'transportationDetailsTab':
        return isTransportationRequired;
      case 'accommodationDetailsTab':
        return isAccommodationRequired;
      default:
        return instance[tabKey] || false;
    }
  };

  return (
    <>
      <h3>Select tabs to display:</h3>
      {controllerInfo &&
        Object.keys(mapping).map((tabKey) => {
          const isDisplayCheckbox = !isVisaOnlyCase || (isVisaOnlyCase && !exclusionTabsVisaOnly.includes(tabKey));
          return (
            isDisplayCheckbox && (
              <Checkbox
                key={tabKey}
                tabKey={tabKey}
                onChange={getOnChangeFunction(tabKey)}
                isChecked={getTabValue(tabKey)}
                label={mapping[tabKey]}
              />
            )
          );
        })}
    </>
  );
};

export default SelectTabsDisplay;
