import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import { getFormElement } from '../../Shared/Form/FormElements';
import { editGeneralPetProfile } from '../../../store/actions/requestDetails';
import { getFormSchemaWithRequiredField, getValuesToUpdate } from '../../../helpers';
import { addGeneralPetProfileNotes, editGeneralInfoPetsNotes } from '../../../store/actions/requestPetsProfiles';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import { formSchema as formSchemaWithoutRequiredField, validationSchema, formSchemaNotes } from './schemaProperties';
import './generalPetInfo.scss';

const GeneralPetInfo = ({ isCaseDisabled, ...generalPetInfo }) => {
  const dispatch = useDispatch();

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField);

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    dispatch(editGeneralPetProfile(generalPetInfo.id, generalPetInfo.rel_id, generalPetInfo.rel_req_id, values));
    setSubmitting(false);
  };

  const onSubmitNotes = (values, { setSubmitting, resetForm, setStatus }) => {
    const notesToUpdate = getValuesToUpdate(values.notes, generalPetInfo.notes, ['author', 'text']);
    const notesToAdd = values.notes.filter(({ id }) => isNaN(id));
    if (notesToUpdate.length) {
      notesToUpdate.forEach((note) => {
        dispatch(editGeneralInfoPetsNotes(note.id, { ...note }, generalPetInfo.rel_req_id));
      });
    }

    if (notesToAdd.length) {
      notesToAdd.forEach((note) => {
        if (note) {
          dispatch(addGeneralPetProfileNotes(generalPetInfo.req_id, generalPetInfo.rel_req_id, { ...note }));
        }
      });
    }
    setSubmitting(false);
  };

  return (
    <div className="GeneralInfo">
      <Formik enableReinitialize initialValues={generalPetInfo} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps) => (
          <Form>
            {formSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, formikProps, isCaseDisabled)}</div>
            ))}
            <SubmitButton title="Submit" formikProps={formikProps} />
          </Form>
        )}
      </Formik>

      <Formik
        enableReinitialize
        initialValues={generalPetInfo}
        onSubmit={onSubmitNotes}
        validationSchema={validationSchema}
      >
        {(formikProps) => (
          <Form>
            {formSchemaNotes.map((field) => (
              <div key={field.fieldName} className="pets-notes">
                {getFormElement(field.type, field, formikProps, isCaseDisabled)}
              </div>
            ))}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GeneralPetInfo;
