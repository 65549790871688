import { types } from '../actions/types';

const initialState = {};

const approversReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.APPROVERS_INFO_LOAD: {
      const { requestId } = action.payload;
      return { ...state, [requestId]: action.payload };
    }
    default:
      return {
        ...state,
      };
  }
};

export default approversReducer;
