const currencyType = {
  usd: '\u0024',
  eur: '\u20ac',
};

const CompensationFormula = ({
  compensationCurrency,
  forWhom,
  calculatorData,
  numberOfChild,
  calculatorDataChildren,
  previousCompensationOneThousand,
  excludePreviousCompensation,
  paidInAdvance,
}) => {
  const employeeCompensation =
    compensationCurrency + ((forWhom.some((item) => item === 'employee') && calculatorData?.Employee) || '0');

  const spouseCompensation =
    compensationCurrency + ((forWhom.some((item) => item === 'spouse') && calculatorData?.Spouse) || '0');

  const childCompensation =
    compensationCurrency +
    (forWhom.some((item) => item === 'child') && +numberOfChild ? calculatorDataChildren[numberOfChild] || '0' : '0');

  const excludeOneThousandCompensation =
    (excludePreviousCompensation &&
      currencyType[previousCompensationOneThousand?.compensationCurrency] +
        previousCompensationOneThousand?.compensationAmount) ||
    '0';

  const previousCompensation =
    excludePreviousCompensation && previousCompensationOneThousand
      ? ` - Previous compensation(${excludeOneThousandCompensation})`
      : '';

  const paidInAdvanceFormula = (paidInAdvance && '- Paid in advance') || '';

  const formula = `Employee (${employeeCompensation}) + Spouse(${spouseCompensation}) + Child(ren)(${childCompensation}) ${previousCompensation} ${paidInAdvanceFormula}`;

  return (
    <p className="formula">
      <span>Formula: </span>
      {formula}
    </p>
  );
};

export default CompensationFormula;
