import { NavLink } from 'react-router-dom';

export const sidebarNavigation = [
  {
    label: (
      <NavLink to={'./general-information'} className="menu-item">
        Overview
      </NavLink>
    ),
    key: 'generalInfo',
    url: '/general-information',
  },
  {
    label: (
      <NavLink to={'./contact-information'} className="menu-item">
        Contact information
      </NavLink>
    ),
    key: 'contactInfo',
    url: '/contact-information',
  },
  {
    label: (
      <NavLink to={'./employment-information'} className="menu-item">
        Employment information
      </NavLink>
    ),
    key: 'employmentInformation',
    url: '/employment-information',
  },
  {
    label: (
      <NavLink to={'./education-information'} className="menu-item">
        Education information
      </NavLink>
    ),
    key: 'educationInformation',
    url: '/education-information',
  },
  {
    label: (
      <NavLink to={'./related-profiles'} className="menu-item">
        Related profiles
      </NavLink>
    ),
    key: 'relatedProfiles',
    url: '/related-profiles',
  },
  {
    label: 'Documents',
    key: 'relativeDocuments',
    children: [
      {
        label: (
          <NavLink to={'./documents'} className="menu-item">
            Documents
          </NavLink>
        ),
        key: 'documents',
        url: '/documents',
      },
      {
        label: (
          <NavLink to={'./forms'} className="menu-item">
            Forms
          </NavLink>
        ),
        key: 'forms',
        url: '/forms',
      },
    ],
  },
  {
    label: (
      <NavLink to={'./archived-documents'} className="menu-item">
        Archived docs
      </NavLink>
    ),
    key: 'archivedDocuments',
    url: '/archived-documents',
  },
  {
    label: (
      <NavLink to={'./vac-appointment'} className="menu-item">
        VAC appointment
      </NavLink>
    ),
    key: 'vacAppointment',
    url: '/vac-appointment',
  },
  {
    label: (
      <NavLink to={'./foreign-police'} className="menu-item">
        Migration office
      </NavLink>
    ),
    key: 'foreignPoliceAppointment',
    url: '/foreign-police',
  },
  {
    label: (
      <NavLink to={'./transportation-details'} className="menu-item">
        Transportation details
      </NavLink>
    ),
    key: 'transportationDetails',
    url: '/transportation-details',
  },
  {
    label: (
      <NavLink to={'./history'} className="menu-item">
        History
      </NavLink>
    ),
    key: 'history',
    url: '/history',
  },
];
