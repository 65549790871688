import { Formik, Form, FieldArray } from 'formik';
import { useState } from 'react';
import parse from 'html-react-parser';

import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../Shared/Form/FormElements';
import { useGetInitialValues, useGetValidationSchema } from './hooks';
import { sendFeedback } from '../../../store/actions/employeeFeedback';
import FeedbackStatusModal from './FeedbackStatusModal/FeedbackStatusModal';

import './employeeFeedbackForm.scss';

const EmployeeFeedbackForm = ({ feedbackForms, requestId }) => {
  const [isModalVisible, setModalVisible] = useState({ isVisible: false, isSuccess: '' });

  const formSchema = feedbackForms.map((form) => form.forms[0].fields.filter((item) => !item.disabled));
  const initialValues = useGetInitialValues(feedbackForms);
  const validationSchema = useGetValidationSchema(feedbackForms);

  const onSubmit = (values, { setSubmitting, setStatus, setFieldValue }) => {
    const valuesInfoObj = values.feedback.reduce((accumulator, currentItem) => {
      const { generic_form_id, form_id, ...formValues } = currentItem;
      accumulator[currentItem.generic_form_id] = [{ form_id: form_id, values: formValues }];
      return accumulator;
    }, {});

    sendFeedback(requestId, valuesInfoObj)
      .then(() => {
        setModalVisible({ isVisible: true, isSuccess: true });
      })
      .catch((error) => {
        setModalVisible({ isVisible: true, isSuccess: false, message: error.response?.data });
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div>
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps) => {
          return (
            <Form>
              <FieldArray
                name={'feedback'}
                render={({ remove, push, insert }) => (
                  <>
                    <div>
                      {feedbackForms.length > 0 &&
                        feedbackForms.map((form, formIndex) => (
                          <div className="question-block" key={formIndex}>
                            <h3 className="question-name underlined">{form.name}</h3>
                            {form.description && form.description !== '' && (
                              <div className="description-item">{parse(form.description)}</div>
                            )}
                            {form.forms[0].fields.map((field, fieldIndex) => (
                              <div key={field.fieldName} className="question-answer">
                                {getFormElement(
                                  field.element,
                                  {
                                    nameFieldArray: `feedback`,
                                    formIndex,
                                    validationSchema,
                                    ...field,
                                  },
                                  formikProps,
                                )}
                                <hr className={'answer__divider'}></hr>
                              </div>
                            ))}
                          </div>
                        ))}
                    </div>
                    <div className="submit-btn__container">
                      <SubmitButton title="Send" formikProps={formikProps} />
                    </div>
                  </>
                )}
              />
            </Form>
          );
        }}
      </Formik>
      {isModalVisible.isVisible && (
        <FeedbackStatusModal setIsModalVisible={setModalVisible} isModalVisible={isModalVisible} />
      )}
    </div>
  );
};

export default EmployeeFeedbackForm;
