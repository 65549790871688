import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';

import EmployeeSearch from '../../../Shared/Form/EmployeeSearch/EmployeeSearch';
import { SubmitButton } from '../../../Shared/Form/Buttons/Buttons';
import {
  addEmployeeToPolishLegalizationDocuments,
  getLegalizationDocuments,
} from '../../../../store/actions/legalizationDocuments';
import { initialValues, validationSchema } from './schemaProperties';
import { getParams } from '../../tableProperties/getParams';

const AddEmployee = ({ setModalVisible, page, countryId }) => {
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(addEmployeeToPolishLegalizationDocuments(values, countryId || null))
      .then((response) => {
        const [ordering, filtering, searchParams, toggleParams] = getParams({
          tableKey: 'PolandDashboard',
        });
        dispatch(
          getLegalizationDocuments(page, filtering, {
            ordering: ordering,
            search: searchParams,
            toggle: toggleParams,
          }),
        );
        setModalVisible(false);
      })
      .catch((error) => {
        setSubmitting(false);
        setModalVisible(false);
      })
      .finally(() => {
        setSubmitting(false);
        setModalVisible(false);
      });
  };

  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
      {(formikProps) => (
        <>
          <EmployeeSearch formikProps={formikProps} isHiddenOverflow={true} />
          <Form>
            <div className="add-item-to-legalization-table__button-container">
              <button type="button" className="button btn-form main-btn" onClick={() => setModalVisible(false)}>
                Cancel
              </button>
              <SubmitButton title="Confirm" formikProps={formikProps} />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default AddEmployee;
