import { useState } from 'react';

import DocumentsTabs from './DocumentsTabs/DocumentsTabs';
import MenuList from './MenuList/MenuList';
import EmployeeVacBlock from './EmployeeVacBlock/EmployeeVacBlock';
import SendInfo from './SendInfo/SendInfo';
import { sendEmployeeDocuments } from '../../../store/actions/requestDetails';
import './documents.scss';

const Documents = ({
  requestId,
  withWhom,
  setWithWhom,
  docsInfo,
  docsInfoEmployee,
  docsInfoRelatives,
  displayVacIsRequired,
}) => {
  const [selectedTabType, setSelectedTab] = useState('employee');
  const [currentTab, setCurrentTab] = useState('');

  const [selectedMenuItem, setSelectedMenuItem] = useState();

  const sendDocs = (data) => {
    return sendEmployeeDocuments(requestId, data);
  };

  return (
    <div className="request-employee-documents__container">
      <h3 className="underlined">Documents</h3>
      {displayVacIsRequired && (
        <EmployeeVacBlock
          isVacRequired={withWhom?.isVacRequired || false}
          requestId={requestId}
          consultationCountry={docsInfo?.consultationCountry ? docsInfo?.consultationCountry : ''}
          setWithWhom={setWithWhom}
          consulateLocations={
            docsInfo.city.country.consultationLocations ? docsInfo.city.country.consultationLocations : []
          }
        />
      )}
      <div className="documents__wrapper">
        <div className="documets__menu">
          <MenuList
            selectedTab={selectedTabType}
            setSelectedMenuItem={setSelectedMenuItem}
            selectedMenuItem={selectedMenuItem}
            docsInfo={docsInfo}
          />
          <SendInfo
            docsInfoEmployee={docsInfoEmployee}
            docsInfoRelatives={docsInfoRelatives}
            setCurrentTab={setCurrentTab}
            setSelectedMenuItem={setSelectedMenuItem}
            setSelectedTab={setSelectedTab}
            sendDocs={sendDocs}
            withWhom={withWhom}
            requestId={requestId}
          />
        </div>
        <div className="documets__content">
          <DocumentsTabs
            withWhom={withWhom}
            setWithWhom={setWithWhom}
            requestId={requestId}
            docsInfo={docsInfo}
            docsInfoEmployee={docsInfoEmployee}
            docsInfoRelatives={docsInfoRelatives}
            setSelectedTab={setSelectedTab}
            selectedMenuItem={selectedMenuItem}
            setSelectedMenuItem={setSelectedMenuItem}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </div>
      </div>
    </div>
  );
};

export default Documents;
