import Select from 'react-select';
import './dropdown.scss';

import { customStyles } from './customStyles';

const Dropdown = ({ label, defaultValue, options, onChange, isDisabled }) => {
  return (
    <div className="case-progress__dropdown-container">
      <label className="case-progress__label">{label}</label>
      <Select
        defaultValue={defaultValue || ''}
        placeholder="-select-"
        options={options}
        styles={customStyles}
        onChange={(option) => onChange(option && option.value)}
        className="case-progress__dropdown"
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default Dropdown;
