import FilterDropdownIcon from '../../Shared/Table/FilterIcon/FilterIcon';

import React from 'react';

const columns = [
  {
    key: 'employee',
    title: 'Employee',
    label: 'Employee',
    dataIndex: 'employee',
    width: '150px',
  },
  {
    key: 'status',
    title: 'Status',
    label: 'Status',
    dataIndex: 'status',
    width: '220px',
    filters: [
      { text: 'New', value: 'New' },
      { text: 'In progress', value: 'In progress' },
      { text: 'Waiting for passport translation', value: 'Waiting for passport translation' },
      {
        text: 'Verification of the notarized translation of the passport',
        value: 'Verification of the notarized translation of the passport',
      },
      { text: 'Documents preparation', value: 'Documents preparation' },
      {
        text: 'An electronic version was sent to the employee',
        value: 'An electronic version was sent to the employee',
      },
      { text: 'Documents are ready to pick up', value: 'Documents are ready to pick up' },
      { text: 'Documents were sent by post', value: 'Documents were sent by post' },
      { text: 'Documents received by the employee', value: 'Documents received by the employee' },
    ],
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
    sortOrder: null,
  },
  {
    key: 'responsible',
    title: 'Responsible',
    label: 'Responsible',
    dataIndex: 'responsible',
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filters: [],
    width: '220px',
    filteredValue: null,
    sortOrder: null,
  },
  {
    key: 'type',
    title: 'Type',
    label: 'Type',
    dataIndex: 'type',
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filters: [
      { value: 'employee', text: 'Only employee' },
      { value: 'family', text: 'Only family' },
      { value: 'employee_and_family', text: 'With family' },
    ],
    width: '120px',
    filteredValue: null,
    sortOrder: null,
  },
];

const countryColumn = [
  {
    key: 'country',
    title: 'Country',
    label: 'Country',
    dataIndex: 'country',
    width: '150px',
  },
];

const additionalColumns = [
  {
    key: 'viewDetails',
    title: '',
    label: '',
    dataIndex: 'viewDetails',
  },
];

const lastColumns = [
  {
    key: 'deadline',
    title: 'Deadline',
    label: 'Deadline',
    dataIndex: 'deadline',
    width: '150px',
  },
  {
    key: 'comments',
    title: 'Comments',
    label: 'Comments',
    dataIndex: 'comments',
  },
];

export const getDefaultColumns = (selectedCountry) => {
  if (selectedCountry.value) {
    return [...columns, ...lastColumns];
  }
  return [...columns, ...countryColumn, ...lastColumns, ...additionalColumns];
};
