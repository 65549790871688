import { types } from '../actions/types';

const initialState = {};

const faqInfoLoadReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FAQ_INFO_LOAD: {
      const { countryId } = action.payload;
      return { ...state, [countryId]: action.payload };
    }
    case types.FAQ_INFO_UPDATED: {
      const { countryId } = action.payload;
      return {
        ...state,
        next: action.payload.next,
        previous: action.payload.previous,
        [countryId]: {
          ...state[countryId],
          faqInfo: {
            ...state[countryId].faqInfo,
            next: action.payload.next,
            previous: action.payload.previous,
            results: [...state[countryId].faqInfo.results, ...action.payload.faqInfo.results],
          },
        },
      };
    }
    default:
      return {
        ...state,
      };
  }
};

export default faqInfoLoadReducer;
