import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getPetRelocationHistory } from '../../../../store/actions/requestPetsProfiles';
import History from '../../../Common/History/History';

const PetHistory = ({ requestId, petReqId }) => {
  const dispatch = useDispatch();
  const { petRequestHistory, count } = useSelector((state) => state.petRequestHistory);

  const getTableDataCallback = (requestId, petReqId) => (page) => {
    return getPetRelocationHistory(requestId, petReqId, page);
  };

  useEffect(() => {
    if (requestId && petReqId) {
      dispatch(getPetRelocationHistory(requestId, petReqId));
    }
  }, [requestId, dispatch, petReqId]);

  return (
    <History
      historyList={petRequestHistory}
      count={count}
      getTableDataCallback={getTableDataCallback(requestId, petReqId)}
      className="relocation-pet-history-details"
      name="petHistory"
    />
  );
};

export default PetHistory;
