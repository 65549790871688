import { useState } from 'react';
import { Tooltip } from 'antd';

import Document from '../../../../../assets/img/document.svg';
import Close from '../../../../../assets/img/icons/exit.svg';
import ModalConfirm from '../../../ModalConfirm/ModalConfirm';
import { getTicketAttachmentLink } from '../../../../../store/actions/downloadFile';

import './displayAttachment.scss';

const DisplayAttachment = ({ attachmentId, attach, deleteAttachment, isCaseDisabled }) => {
  const [isDeleteAttachmentModalVisible, setDeleteAttachmentModalVisible] = useState(false);

  const downloadFile = async (attachmentId) => {
    const response = await getTicketAttachmentLink(attachmentId);
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = response.url;
    document.body.appendChild(link);
    link.click();
  };

  const openModal = () => {
    setDeleteAttachmentModalVisible(true);
  };

  const deleteFile = () => {
    deleteAttachment(attachmentId);
    setDeleteAttachmentModalVisible(false);
  };

  return (
    <>
      <div className="display-attachment">
        <div className="attachment-container">
          <img src={Document} alt={attach.fileName} className="icon" onClick={() => downloadFile(attachmentId)} />
          <span className="attachment-name" onClick={() => downloadFile(attachmentId)}>
            {attach.fileName}
          </span>
          {!isCaseDisabled && deleteAttachment && (
            <Tooltip placement={'top'} title={'Delete attachment'}>
              <div className="delete-attachment">
                <img src={Close} alt="Close" className="close-attachment" onClick={() => openModal()} />
              </div>
            </Tooltip>
          )}
        </div>
        {isDeleteAttachmentModalVisible && (
          <ModalConfirm
            isModalVisible={isDeleteAttachmentModalVisible}
            setModalVisible={setDeleteAttachmentModalVisible}
            confirmationQuestion="Are you sure you want to delete this attachment?"
            handleClick={deleteFile}
          />
        )}
      </div>
    </>
  );
};

export default DisplayAttachment;
