import React from 'react';

import DocumentForm from '../../../../../Common/DocumentForm/DocumentForm';

const EmployeeDocument = ({ employeeId, document }) => {
  return (
    <DocumentForm
      document={document}
      employeeId={employeeId}
      education={[]}
      isCase={false}
      documentType={{ forWhom: 'employee', type: 'documents' }}
      allowCustomReminders={false}
      submitBtnTitle={'Save'}
      isLegalizationRequestDocument={true}
    />
  );
};

export default EmployeeDocument;
