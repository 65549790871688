import { Link } from 'react-router-dom';

import './previousCompensation.scss';

const currencyType = {
  usd: '\u0024',
  eur: '\u20ac',
};

const PreviousCompensations = ({ previousCompensations }) => {
  return (
    <div className="previous-compensation">
      <div className="previous-compensation__label">Previous compensation amount</div>
      <div className="previous-compensation__list">
        {previousCompensations.map((previousCompensation, index) => (
          <div key={index}>
            <p>
              {`${index + 1}. `}
              {previousCompensation.relocationCaseId && (
                <>
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to={'/request-details/' + previousCompensation.relocationCaseId}
                    className="previous-compensation__link"
                  >
                    Case {previousCompensation.relocationCaseId}
                  </Link>
                </>
              )}
              {` ${currencyType[previousCompensation.compensationCurrency] || ''}${
                previousCompensation.compensationAmount ? previousCompensation.compensationAmount : ''
              } `}

              {previousCompensation.description && ` ${previousCompensation.description || ''}`}
            </p>
          </div>
        ))}
        {previousCompensations.length === 0 && 'No'}
      </div>
    </div>
  );
};

export default PreviousCompensations;
