import { Formik, Form, Field } from 'formik';

const InputText = ({ cell, col, rowIndex, colIndex }) => {
  return (
    <Formik enableReinitialize initialValues={{ text: cell?.cell }}>
      {(formikProps) => (
        <Form>
          <Field
            type="text"
            name="text"
            id={`${col.title}.${rowIndex}.${colIndex}`}
            disabled={cell?.disabled}
            className="input-text"
            onBlur={(event) => col.onChange(rowIndex, event.target.value, cell, colIndex)}
          />
        </Form>
      )}
    </Formik>
  );
};

export default InputText;
