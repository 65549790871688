import axios from '../../axios';
import actionCreators from './actionCreators';
import queryString from 'query-string';

export const relocationQuestion = async (data) => {
  try {
    const response = await axios.post(`/api/v1/gmg-question/`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const relocationRequest = async (data) => {
  try {
    const response = await axios.post(`/api/v1/relocation-requests/`, data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getFAQ = (filterParams, nextLink) => async (dispatch) => {
  try {
    const link = nextLink?.slice(nextLink.indexOf('?'), nextLink.length);
    const link_params = queryString.parse(link);
    const params = { ...filterParams, ...link_params };

    const response = await axios.get('/api/v1/faq/', { params });
    if (nextLink) {
      dispatch(actionCreators.faqInfoUpdated(response.data, filterParams?.countries));
    } else {
      dispatch(actionCreators.faqInfoLoad(response.data, filterParams?.countries));
    }
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getCountryRelatedPages = async (pageId) => {
  try {
    const response = await axios.get(`/api/v1/geo/countries/pages/${pageId}`);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
