import Chart from 'react-apexcharts';

import { getOptions } from '../chartProperties';

const BarChart = ({ customOptions, series, title, isLoading }) => {
  const options = getOptions({ isLoading, customOptions, title });

  return <Chart options={options} series={series} height={460} type="bar" />;
};

export default BarChart;
