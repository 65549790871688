import actionCreators from './actionCreators';
import axios from '../../axios';
import { useHistory } from 'react-router';
import { getAppUserManager } from '../../components/auth/userManager';

export const authentication = (userInfo) => {
  return (dispatch) => {
    dispatch(actionCreators.authenticateSuccess(userInfo));
  };
};

export const authorization = () => {
  return (dispatch) => {
    axios
      .get('/api/v1/auth/user-permissions/')
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data));

        const userManager = getAppUserManager();
        userManager.getUser().then((user) => {
          dispatch(actionCreators.authorizationSuccess({ ...response.data, picture: user?.profile?.picture }));
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

// export const getDateFormat = () => {
//   return (dispatch) => {
//     axios
//       .get('/api/v1/core/date-format/')
//       .then((response) => {
//         dispatch(actionCreators.dateFormatLoaded(response.data));
//         return response.data;
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   };
// };
//
// export const editDateFormat = (data) => {
//   return (dispatch) => {
//     let toast = { type: 'success', messageError: '' };
//     return axios
//       .put('/api/v1/core/date-format/', data)
//       .then((response) => {
//         dispatch(actionCreators.dateFormatUpdated(response.data));
//         return response.data;
//       })
//       .catch((error) => {
//         toast.errorMessage = error.response?.data;
//         toast.type = 'error';
//       })
//       .finally(() => {
//         dispatch(
//           actionCreators.toastUpdate({
//             type: toast.type,
//             message: toast.errorMessage,
//           }),
//         );
//       });
//   };
// };
