import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { CellType } from '../../Shared/Constants/Constants';
import Checkbox from './Checkbox/Checkbox';
import InputText from './InputText/InputText';
import InputNumber from './InputNumber/InputNumber';
import Dropdown from './Dropdown/Dropdown';

import './TableComponent.scss';

const TableComponent = ({ columns, rows, wrapperRef }) => {
  return (
    <>
      {columns.length > 0 ? (
        <div className="table-wrapper">
          <table className="table_">
            <colgroup>
              <col className="col1"></col>
              <col className="col2"></col>
              <col className="col3"></col>
              <col></col>
            </colgroup>
            <thead className="table__head" ref={wrapperRef}>
              <tr className="table__head-list">
                {columns.map((col, index) => (
                  <th key={index} className="table__head-item">
                    {col.title}
                  </th>
                ))}
              </tr>
            </thead>
            {rows.length > 0 && (
              <tbody className="table__body">
                {rows.map((row, rowIndex) => (
                  <tr key={rowIndex} className="table__body-list">
                    {columns.map((col, colIndex) => {
                      const cell = row[col.key];
                      const dataLabel = columns[colIndex].dataLabel
                        ? columns[colIndex].dataLabel
                        : columns[colIndex].title;
                      switch (col.type) {
                        case CellType.Link:
                          return (
                            <td className="table__body-item" key={colIndex} data-label={dataLabel}>
                              <div className="table__body-text">
                                {cell?.isLinked ? (
                                  cell?.withoutLink ? (
                                    col.getValue(cell)
                                  ) : (
                                    <Link to={cell.id}>{col.getValue(cell)}</Link>
                                  )
                                ) : (
                                  <Link to={col.getLink(cell)}>{col.getValue(cell)}</Link>
                                )}
                              </div>
                            </td>
                          );
                        case CellType.Checkbox: {
                          if (row.vacRequest === '' && col.key === 'vacRequest') {
                            return (
                              <td className="table__body-item" key={colIndex} data-label={dataLabel}>
                                <div className="table__body-text">{cell}</div>
                              </td>
                            );
                          }
                          const isDisabled = !!col.isDisabled?.(row);
                          const checked = typeof cell === 'object' ? cell?.cell : cell;

                          return (
                            <td className="table__body-item textAlign" key={colIndex} data-label={dataLabel}>
                              <div className="table__body-text">
                                <Checkbox
                                  col={col}
                                  rowIndex={rowIndex}
                                  checked={checked}
                                  isDisabled={isDisabled}
                                  cell={cell}
                                  colIndex={colIndex}
                                />
                              </div>
                            </td>
                          );
                        }
                        case CellType.InputNumber:
                          return (
                            <td className="table__body-item" key={colIndex} data-label={dataLabel}>
                              <div className="table__body-text">
                                <InputNumber cell={cell} colIndex={colIndex} rowIndex={rowIndex} col={col} />
                              </div>
                            </td>
                          );
                        case CellType.InputText:
                          return (
                            <td className="table__body-item" key={colIndex} data-label={dataLabel}>
                              <div className="table__body-text">
                                <InputText cell={cell} col={col} rowIndex={rowIndex} colIndex={colIndex} />
                              </div>
                            </td>
                          );
                        case CellType.Dropdown:
                          return (
                            <td className="table__body-item" key={colIndex} data-label={dataLabel}>
                              <Dropdown col={col} cell={cell} />
                            </td>
                          );
                        case CellType.Mix:
                          if (cell?.componentOfStep === 'enabled' && cell?.isDisplay) {
                            return (
                              <td className="table__body-item textAlign" key={colIndex} data-label={dataLabel}>
                                <div className="table__body-text">
                                  <Checkbox
                                    col={col}
                                    rowIndex={rowIndex}
                                    checked={cell?.cell}
                                    isDisabled={false}
                                    cell={cell}
                                    colIndex={colIndex}
                                  />
                                </div>
                              </td>
                            );
                          } else if (cell?.componentOfStep === 'duration' && cell?.isDisplay) {
                            return (
                              <td className="table__body-item" key={colIndex} data-label={dataLabel}>
                                <div className="table__body-text">
                                  <InputText cell={cell} col={col} rowIndex={rowIndex} colIndex={colIndex} />
                                </div>
                              </td>
                            );
                          } else if (cell?.componentOfStep === 'order' && cell?.isDisplay) {
                            return (
                              <td className="table__body-item" key={colIndex} data-label={dataLabel}>
                                <div className="table__body-text">
                                  <InputNumber cell={cell} colIndex={colIndex} rowIndex={rowIndex} col={col} />
                                </div>
                              </td>
                            );
                          } else if (!cell?.isDisplay) {
                            return (
                              <td className="table__body-item" key={colIndex} data-label={dataLabel}>
                                <div className="table__body-text"></div>
                              </td>
                            );
                          }

                        default:
                          return (
                            <td
                              className="table__body-item"
                              key={colIndex}
                              data-label={dataLabel}
                              title={col.tooltip ? col.tooltip : ''}
                            >
                              <div
                                className={classNames('table__body-text', col.title)}
                                onClick={() => (col.onClick ? col.onClick(rowIndex) : {})}
                              >
                                {cell?.cell ? cell?.cell : cell}
                              </div>
                            </td>
                          );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            )}
          </table>
        </div>
      ) : (
        <h1 className="no-data">NO DATA</h1>
      )}
    </>
  );
};

export default TableComponent;
