import { Formik, Form, FieldArray } from 'formik';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';

import { getFormElement } from '../../Shared/Form/FormElements';
import { employeeFormSchema, candidateFormSchema } from './formSchemes';
import { IsCandidateContext } from '../../EmployeeProfile/EmployeeProfile';
import { upsertRelocatedEmploymentInfo } from '../../../store/actions/requestDetails';
// import EmploymentInfoEdit from './EmploymentInfoEdit/EmploymentInfoEdit';
import { getValuesToUpdate } from '../../../helpers';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import CandidateWithoutEmploymentInfo from './CandidateWithoutEmploymentInfo/CandidateWithoutEmploymentInfo';

import './employmentInfo.scss';

const initialValuesToPush = {
  employmentDate: '',
};

const EmploymentInfo = ({ employmentInfoHistory, employeeId }) => {
  const dispatch = useDispatch();

  const isCandidate = useContext(IsCandidateContext);
  const formSchema = isCandidate ? candidateFormSchema : employeeFormSchema;

  const employmentInfoFromSMG = isCandidate
    ? employmentInfoHistory
    : employmentInfoHistory.filter((item) => item.isFromSmg);

  // const employmentInfoNotFromSMG = employmentInfoHistory.filter((item) => !item.isFromSmg);

  const employmentInfoHistoryInitialValues = employmentInfoFromSMG.map((item) => {
    return {
      id: item.id,
      company: item.company ? item.company.title : 'iTechArt Group/Vention Teams',
      companyId: item.company ? item.company.id : item?.companyId,
      dismissDate: item.dismissDate,
      employmentDate: item.employmentDate,
    };
  });

  const initialValues = {
    employmentInfoHistory: employmentInfoFromSMG.length ? employmentInfoHistoryInitialValues : [],
  };

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    const valuesToUpdate = getValuesToUpdate(
      values.employmentInfoHistory,
      employmentInfoHistoryInitialValues,
      Object.keys(initialValuesToPush),
    );

    dispatch(upsertRelocatedEmploymentInfo(valuesToUpdate, employeeId)).finally(() => {
      setSubmitting(false);
    });
    setSubmitting(false);
  };

  return (
    <div className="EmploymentInfo">
      <div>
        <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
          {(formikProps) => {
            return (
              <Form>
                <FieldArray
                  name="employmentInfoHistory"
                  render={({ remove, push }) => (
                    <div>
                      {formikProps.values.employmentInfoHistory.length > 0 &&
                        formikProps.values.employmentInfoHistory.map((employmentInfoHistory, formIndex) => {
                          const formSchemaWithoutEmptyDismissDate = formSchema.filter(
                            (item) =>
                              item.fieldName !== 'dismissDate' ||
                              (item.fieldName === 'dismissDate' && employmentInfoHistory.dismissDate),
                          );
                          return (
                            <div className="employment-info-container" key={formIndex}>
                              {formSchemaWithoutEmptyDismissDate.map((field, index) => (
                                <div key={index}>
                                  {getFormElement(
                                    field.type,
                                    {
                                      nameFieldArray: 'employmentInfoHistory',
                                      formIndex,
                                      ...field,
                                    },
                                    formikProps,
                                  )}
                                </div>
                              ))}
                            </div>
                          );
                        })}
                    </div>
                  )}
                />
                {isCandidate && employmentInfoHistory.length > 0 && (
                  <SubmitButton title="Submit" formikProps={formikProps} />
                )}
              </Form>
            );
          }}
        </Formik>
      </div>

      {isCandidate && employmentInfoHistory.length === 0 && <CandidateWithoutEmploymentInfo />}

      {/* {!isCandidate && (
        <EmploymentInfoEdit relocatedEmploymentInfo={employmentInfoNotFromSMG} employeeId={employeeId} />
      )} */}
    </div>
  );
};

export default EmploymentInfo;
