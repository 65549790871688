import dayjs from 'dayjs';

import { defaultDateFormat } from '../../../constants';

export const useGetParams = ({ paramsFromLocation, paramsFromLocalStorage }) => {
  const cityParam = paramsFromLocalStorage?.city ? 'city=' + paramsFromLocalStorage?.city.value : '';
  const countryParam = paramsFromLocalStorage?.country ? 'country=' + paramsFromLocalStorage?.country.value : '';
  const responsibleParam = paramsFromLocalStorage?.responsible
    ? 'responsible=' + paramsFromLocalStorage?.responsible.value
    : '';
  const statusParam = paramsFromLocalStorage?.status?.length
    ? 'status=' + paramsFromLocalStorage?.status.map((status) => status.value).join()
    : '';
  const vacParam = paramsFromLocalStorage?.vac ? 'vac=' + paramsFromLocalStorage?.vac.value : '';
  const createdSortParam = paramsFromLocalStorage?.sortCreated
    ? 'ordering=' + `${paramsFromLocalStorage?.sortCreated.value === 1 ? '' : '-'}created_at`
    : '';

  const documentParam = paramsFromLocalStorage?.document
    ? 'documents_state=' + paramsFromLocalStorage?.document.value
    : '';
  const dateBeforeParam =
    paramsFromLocalStorage?.dateBefore && paramsFromLocalStorage?.dateBefore !== 'Invalid Date'
      ? 'date_before=' + dayjs(paramsFromLocalStorage?.dateBefore).format(defaultDateFormat)
      : '';
  const dateAfterParam = paramsFromLocalStorage?.dateAfter
    ? 'date_after=' + dayjs(paramsFromLocalStorage?.dateAfter).format(defaultDateFormat)
    : '';
  const vacDateBeforeParam =
    paramsFromLocalStorage?.vacDateBefore && paramsFromLocalStorage?.vacDateBefore !== 'Invalid Date'
      ? 'vac_appointment_date_before=' + dayjs(paramsFromLocalStorage?.vacDateBefore).format(defaultDateFormat)
      : '';
  const vacDateAfterParam = paramsFromLocalStorage?.vacDateAfter
    ? 'vac_appointment_date_after=' + dayjs(paramsFromLocalStorage?.vacDateAfter).format(defaultDateFormat)
    : '';
  const vacAddressParam = paramsFromLocalStorage?.vacAddress
    ? 'vac_appointment_address=' + paramsFromLocalStorage?.vacAddress.value
    : '';

  const params = paramsFromLocation
    ? paramsFromLocation
    : [
        cityParam,
        countryParam,
        responsibleParam,
        statusParam,
        dateAfterParam,
        dateBeforeParam,
        vacParam,
        documentParam,
        createdSortParam,
        vacDateBeforeParam,
        vacDateAfterParam,
        vacAddressParam,
      ]
        .filter((param) => param)
        .join('&');

  return params;
};
