const AttachmentValidation = ({
  employeeFormsWithoutAttachments,
  relativesFormsWithoutAttachments,
  docsInfoRelatives,
}) => {
  return (
    <>
      {employeeFormsWithoutAttachments?.length > 0 && (
        <div className="send-docs__validation-error">
          -Please attach scans or click Save button for{' '}
          <span>{employeeFormsWithoutAttachments.map((item) => item.name).join(', ')}</span>
        </div>
      )}
      {relativesFormsWithoutAttachments &&
        Object.keys(relativesFormsWithoutAttachments).map((relativeId) => {
          return (
            <div className="send-docs__validation-error" key={relativeId}>
              -Please attach scans or click Save button for{' '}
              <span>{relativesFormsWithoutAttachments[relativeId].map((item) => item.name).join(', ')} </span>
              for your {docsInfoRelatives[relativeId]?.relatedInfo?.relative?.relationType}{' '}
              <span>{docsInfoRelatives[relativeId]?.relatedInfo?.relative?.generalInfo.fullName}</span>
            </div>
          );
        })}
    </>
  );
};

export default AttachmentValidation;
