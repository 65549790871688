import { useDispatch } from 'react-redux';

import { feedbackStatusOptions } from '../tableProperties';
import { tableDropdownCustomStyles } from '../../Shared/Table/CustomStyles/dropdownCustomStyles';
import { TableDropdown } from '../../Common/Table/Dropdown/Dropdown';
import { getEmployeeFeedbacks } from '../../../store/actions/employeeFeedback';
import { editAdditionalFeedbackInformation } from '../../../store/actions/feedbackForm';
import { getParams } from '../getParams';

import './feedbackColumn.scss';

export const getValuesByElement = (field, value, dateExtraFieldsValue) => {
  switch (field.element) {
    case 'Checkboxes':
      if (value) {
        return value.map((item) => field.options.find((option) => option.value === item)?.text).join(', ');
      }
      return value;
    case 'RadioButtons':
      return field.options.find((option) => option.value === value)?.text;
    case 'DatePicker':
      return value;
    case 'TextArea':
      return value;
    case 'Dropdown':
      return field.options.find((option) => option.value === value)?.text;
    case 'TextInput':
      return value;
    case 'CustomElement':
      if (field.key === 'CheckboxesWithDates') {
        return value
          .map((item) => {
            const option = field.options.find((option) => option.value === item);
            return `${option?.text} - ${option?.key && dateExtraFieldsValue[option?.key]}`;
          })
          .join(', ');
      }
      if (field.key === 'RadioButtonWithTextArea') {
        const val1 = field.options.find((option) => option.value === value)?.text;
        if (val1) {
          return val1;
        } else if (value !== '' && value?.length !== 0 && value !== 'undefined' && value !== null) {
          return value;
        }
      }
      return null;
    default:
      return value;
  }
};

const FeedbackColumn = ({ page, requestId, feedbackForms }) => {
  const dispatch = useDispatch();

  const { fields, values, status } = feedbackForms.forms[0];

  const fieldsWithoutEmptyValues = fields.filter(
    (item) =>
      values[item.fieldName] !== '' &&
      values[item.fieldName]?.length !== 0 &&
      typeof values[item.fieldName] !== 'undefined' &&
      values[item.fieldName] !== null,
  );

  const onStatusChange = (value) => {
    dispatch(editAdditionalFeedbackInformation(requestId, feedbackForms.forms[0].formId, { status: value })).then(
      () => {
        const [ordering, filtering, searchParams, toggleParams] = getParams({
          tableKey: 'FeedbackTable',
        });
        dispatch(
          getEmployeeFeedbacks(page, filtering, {
            ordering: ordering,
            search: searchParams,
            toggle: toggleParams,
          }),
        );
      },
    );
  };

  return (
    <>
      <div className="feedback-column">
        {status && (
          <div className={'feedback-column__status-container'}>
            <div style={{}} className={'feedback-column__status'}>
              Status:
            </div>
            <TableDropdown
              value={{ value: status || 0, label: status || '' }}
              options={feedbackStatusOptions}
              onChange={(option) => onStatusChange(option && option.value)}
              customStyles={tableDropdownCustomStyles}
            />
          </div>
        )}

        {fieldsWithoutEmptyValues.length > 0 ? (
          <div className="feedback-column__form">
            <div className="feedback-column__question-container">
              {fieldsWithoutEmptyValues.map((field) => {
                const fieldLabelTruncationIndex = field.label && field.label.indexOf(':');
                let fieldLabel = fieldLabelTruncationIndex
                  ? field.label.slice(0, fieldLabelTruncationIndex)
                  : field.label;

                return (
                  <div key={field.fieldName} className="feedback-column__question">
                    <span>{fieldLabel}</span>
                    <div>
                      {' '}
                      {getValuesByElement(field, values[field.fieldName], values[field.fieldName + 'DateExtraFields'])}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default FeedbackColumn;
