import EmployeeForm from './EmployeeForm/EmployeeForm';
import './employeeForms.scss';

const EmployeeForms = (props) => {
  const { documents, employeeId } = props;

  if (!documents?.length) {
    return <p className="no-documents">No available forms</p>;
  }

  return (
    <div>
      {documents?.length > 0 &&
        documents.map((document, index) => {
          return <EmployeeForm key={index} employeeId={employeeId} document={document} />;
        })}
    </div>
  );
};

export default EmployeeForms;
