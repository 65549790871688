export const feedbackFilteringOptions = [
  {
    title: 'Quality',
    key: 'quality',
    hasChildren: true,
    children: [
      {
        title: 'Poor',
        key: 'poor',
      },
      {
        title: 'Good',
        key: 'good',
      },
      {
        title: 'Excellent',
        key: 'excellent',
      },
    ],
  },
  {
    title: 'Status',
    key: 'status',
    hasChildren: true,
    children: [
      {
        title: 'New',
        key: 'New',
      },
      {
        title: 'Processed',
        key: 'Processed',
      },
    ],
  },
];
