import { getFormElement } from '../../../Shared/Form/FormElements';
import { formSchema } from '../requestSchema';
import React from 'react';
import {
  addAccommodationAttachment,
  deleteAccommodationDetailsForm,
  getAccommodationPresignedUrl,
} from '../../../../store/actions/uploadFile';
import { uploadFileToS3 } from '../../../../store/actions/uploadToS3';
import actionCreators from '../../../../store/actions/actionCreators';
import { useDispatch } from 'react-redux';
import DeleteForm from '../../../Common/DeleteForm/DeleteForm';
import { CancelButton } from '../../../Shared/Form/Buttons/Buttons';
import CurrentAttachmentBlock from '../CurrentAttachmentBlock/CurrentAttachmentBlock';

const AccommodationDetailsField = (props) => {
  const {
    formikProps,
    formIndex,
    accommodationDetailsAllInfo,
    accommodationDetailsInfo,
    requestId,
    remove,
    isCaseDisabled,
  } = props;
  const dispatch = useDispatch();

  const handleDeleteForm = (adId, requestId) => () => {
    dispatch(deleteAccommodationDetailsForm(adId, requestId));
  };

  return (
    <div className="accommodation-details-container" key={formIndex}>
      <div className="attachments-block">
        {accommodationDetailsInfo.id && (
          <div>
            {!isCaseDisabled && (
              <>
                <DeleteForm deleteForm={handleDeleteForm(accommodationDetailsInfo.id, requestId)} />
              </>
            )}
          </div>
        )}
        {!accommodationDetailsInfo.id && formikProps.values.accommodationDetailsInfo.length > 1 && (
          <CancelButton remove={remove} formIndex={formIndex} />
        )}
      </div>
      {formSchema.map((field, index) => {
        if (field.fieldName === 'notes') {
          return (
            <React.Fragment key={index}>
              {accommodationDetailsInfo?.attachments?.length > 0 && (
                <CurrentAttachmentBlock
                  formId={accommodationDetailsInfo.id}
                  accommodationDetailsAllInfo={accommodationDetailsAllInfo}
                  requestId={requestId}
                  isCaseDisabled={isCaseDisabled}
                />
              )}
              <div key={index}>
                {getFormElement(
                  field.type,
                  {
                    nameFieldArray: 'accommodationDetailsInfo',
                    formIndex,
                    ...field,
                  },
                  formikProps,
                  isCaseDisabled,
                )}
              </div>
            </React.Fragment>
          );
        } else {
          return (
            <div key={index}>
              {getFormElement(
                field.type,
                {
                  nameFieldArray: 'accommodationDetailsInfo',
                  formIndex,
                  ...field,
                },
                formikProps,
                isCaseDisabled,
              )}
            </div>
          );
        }
      })}
    </div>
  );
};

export default AccommodationDetailsField;
