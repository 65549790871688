import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import { useHistory } from 'react-router';

import { relocationQuestion } from '../../../store/actions/relocation';
import AskQuestionModal from './AskQuestionModal/AskQuestionModal';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';
import { validationSchema } from './validationSchema';
import AskQuestionImg from '../../../assets/img/relocation/askQuestion.svg';

import './askQuestion.scss';

const AutoSizeTextArea = ({ field, form, ...props }) => {
  return <TextareaAutosize {...field} {...props} maxRows={25} />;
};

const AskQuestion = () => {
  const history = useHistory();

  const [isModalVisible, setModalVisible] = useState(false);
  const [formSubmitState, setFormSubmitState] = useState('pending');

  const onSubmit = (values, { setSubmitting, resetForm }) => {
    setModalVisible(true);
    relocationQuestion(values)
      .then((response) => {
        setFormSubmitState('success');
      })
      .catch(() => {
        setFormSubmitState('error');
      })
      .finally(() => setSubmitting(false));
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    formSubmitState !== 'pending' && history.push(`/relocation`);
  };

  return (
    <>
      <section className="ask-question">
        <div>
          <img src={AskQuestionImg} alt="pic" />
        </div>
        <div>
          <h2 className="ask-question__title"> Ask a Question</h2>
        </div>

        <div className="ask-question__content">
          <div className="ask-question__form-container">
            <Formik
              enableReinitialize
              initialValues={{ question: '' }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {(formikProps) => (
                <Form className="ask-question__form">
                  <Field
                    className="ask-question__form-textarea"
                    component={AutoSizeTextArea}
                    name="question"
                    id="question"
                    placeholder="Write your question here..."
                  />

                  <ErrorMessage
                    name="question"
                    render={(msg) => (
                      <span className="error" style={{ color: 'red' }}>
                        {msg}
                      </span>
                    )}
                  />
                  <div className="ask-question__btn-wrapper">
                    <SubmitButton type="submit" title="Send question" formikProps={formikProps} />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </section>
      {isModalVisible && (
        <AskQuestionModal
          isModalVisible={isModalVisible}
          setModalVisible={handleCloseModal}
          formSubmitState={formSubmitState}
        />
      )}
    </>
  );
};

export default AskQuestion;
