import actionCreators from './actionCreators';
import axios from '../../axios';

export const getEmloyees = (params) => {
  return (dispatch) => {
    axios
      .get(`/api/v1/relocation-requests/search/`, { params })
      .then((response) => {
        dispatch(actionCreators.searchEmployeesLoaded(response.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.searchEmployeesError());
      });
  };
};

export const getEmloyeesForCreatingCase = (params) => {
  return (dispatch) => {
    axios
      .get(`/api/v1/employees/`, { params })
      .then((response) => {
        dispatch(actionCreators.searchEmployeesForCreatingCaseLoaded(response.data));
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.searchEmployeesError());
      });
  };
};
