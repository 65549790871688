import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  transportationDetailsInfo: Yup.array().of(
    Yup.object().shape({
      transportType: Yup.string().required('This field is required'),
      tickets: Yup.array().of(
        Yup.object().shape({
          date: Yup.date().nullable(),
          time: Yup.string().nullable(),
          address: Yup.string().nullable(),
        }),
      ),
      ticketCost: Yup.lazy((value) =>
        value === ''
          ? Yup.string().required('Please enter numbers (0-9)')
          : Yup.number().typeError('Please enter numbers (0-9)').nullable(),
      ),
      notes: Yup.array().of(
        Yup.object().shape({
          text: Yup.string()
            .min(2, 'Notes must be at least 2 characters')
            .test('len', 'Notes must be at least 2 characters', (val) => (val ? val.toString().length : 0 > 0)),
        }),
      ),
    }),
  ),
});

export const transportationDetailsInitialValues = {
  isTheSameForRelated: false,
  transportType: '',
  ticketCost: 0,
  currency: 'usd',
  ticketAmount: 1,
  tickets: [
    {
      date: null,
      time: null,
      travelFrom: '',
      travelTo: '',
      address: null,
    },
  ],
  notes: [],
  files: [],
};
