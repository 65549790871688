import DisplayDocuments from '../DisplayDocuments/DisplayDocuments';
import AttachDocuments from './AttachDocuments/AttachDocuments';
import './displayAttachDocuments.scss';

const DisplayAttachDocuments = ({
  documents,
  employeeId,
  requestId,
  documentTypeOptions,
  validationMessages,
  validationType,
  reason,
  page,
}) => {
  return (
    <div className="display-attach-documents">
      <AttachDocuments
        employeeId={employeeId}
        requestId={requestId}
        documentTypeOptions={documentTypeOptions}
        page={page}
      />
      <DisplayDocuments
        documents={documents}
        validationType={validationType}
        validationMessages={validationMessages}
        reason={reason}
      />
    </div>
  );
};

export default DisplayAttachDocuments;
