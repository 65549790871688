import { useState } from 'react';
import classNames from 'classnames';

import RequestFeedbackModal from './RequestFeedbackModal/RequestFeedbackModal.js';

const RequestEmployeeFeedback = ({ requestId, documents, isCaseDisabled }) => {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <button
        disabled={isCaseDisabled}
        className={classNames('button btn-form main-btn btn-back request-feedback_btn', {
          'btn-disabled': isCaseDisabled,
        })}
        style={{ marginBottom: '20px' }}
        onClick={() => setModalVisible(true)}
      >
        Request Employee feedback
      </button>
      {isModalVisible && (
        <RequestFeedbackModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          requestId={requestId}
          documents={documents}
        />
      )}
    </>
  );
};

export default RequestEmployeeFeedback;
