import { NavLink } from 'react-router-dom';

export const sidebarNavigation = [
  {
    label: (
      <NavLink to={'./general-information'} className="menu-item">
        General info
      </NavLink>
    ),
    key: 'generalInfo',
    url: '/general-information',
  },
  {
    label: (
      <NavLink to={'./related-profiles'} className="menu-item">
        Related profiles
      </NavLink>
    ),
    key: 'relatedProfiles',
    url: '/related-profiles',
  },
  {
    label: (
      <NavLink to={'./documents'} className="menu-item">
        Documents
      </NavLink>
    ),
    key: 'relativeDocuments',
    url: '/documents',
  },
  {
    label: (
      <NavLink to={'./transportation-details'} className="menu-item">
        Transportation details
      </NavLink>
    ),
    key: 'transportationDetails',
    url: '/transportation-details',
  },
  {
    label: (
      <NavLink to={'./history'} className="menu-item">
        History
      </NavLink>
    ),
    key: 'history',
    url: '/history',
  },
];
