import React, { useMemo } from 'react';
import classNames from 'classnames';
import './displayDocuments.scss';
import { Tooltip } from 'antd';
import parse from 'html-react-parser';
import { ReactComponent as AdditionalInfoIcon } from '../../../assets/img/icons/additional-info.svg';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { defaultDateFormat } from '../../../constants';

const visaTypes = ['D23', 'D18', 'D21', 'D05A', 'D23 PBH', 'D06', 'D09'];

const DisplayDocuments = ({ documents, validationMessages, validationType, reason, source }) => {
  const dateNow = new Date();
  const thirtyDays = useMemo(() => 1000 * 60 * 60 * 24 * 30, []);
  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  return (
    <div className="display-documents__container">
      {documents.length > 0 && (
        <div className="display-documents">
          {documents.map((item, index) => {
            const expirationDate = new Date(item.expirationDate);
            const expirationInMonth = expirationDate - dateNow < thirtyDays;
            const isVisaDocumentResidencePermitReason =
              visaTypes.some(
                (visaType) => item.docType && item.docType.replace(/\s/g, '') === visaType.replace(/\s/g, ''),
              ) && reason?.toLowerCase() === 'residence permit';

            const isTemporaryPermitResidence = source && source !== 'documents';

            return (
              <React.Fragment key={index}>
                <span className="doc-type" style={{ display: 'flex' }}>
                  {item.docType || 'Not provided'}
                  {item.sourceForm && (
                    <div style={{ display: 'flex', marginLeft: '2px' }}>
                      <Tooltip placement={'top'} title={`Data obtained from ${item.sourceForm} form`}>
                        <AdditionalInfoIcon
                          data-tip={item.sourceForm}
                          className={'ant-table-additional-info'}
                          data-for="additional-info-column"
                        />
                      </Tooltip>
                    </div>
                  )}
                </span>
                <span>{item.startDate ? `s.d. ${dayjs(item.startDate).format(displayDateFormat)}` : ''}</span>
                <span
                  className={classNames(
                    'exp-date',
                    { expirationInMonth: expirationInMonth },
                    { visaDocumentResidencePermitReason: isVisaDocumentResidencePermitReason },
                    { temporaryPermitResidence: isTemporaryPermitResidence },
                    {
                      expirationBottomBorder:
                        index !== documents.length - 1 ||
                        (index === documents.length - 1 && validationType && validationMessages[validationType]),
                    },
                  )}
                >
                  {item.expirationDate ? `exp. ${dayjs(item.expirationDate).format(displayDateFormat)}` : ''}
                </span>
              </React.Fragment>
            );
          })}
        </div>
      )}
      {validationMessages &&
        validationMessages.map((item, index) => {
          return (
            <p key={index} className="validation-messages">
              {item}
            </p>
          );
        })}
    </div>
  );
};

export default DisplayDocuments;
