import { useState } from 'react';
import classNames from 'classnames';
import { ErrorMessage, useField } from 'formik';
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { SwapRightOutlined } from '@ant-design/icons';

import './timeRange.scss';

const format = 'HH:mm';

const TimeRange = (props) => {
  const { name, label, formIndex, nameFieldArray, disabled, isRequired, formikProps } = props;

  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const [field, , { setValue }] = useField(fieldName);

  const initialValueStartTime = field.value.startTime ? dayjs(field.value.startTime, format) : null;
  const initialValueEndTime = field.value.endTime ? dayjs(field.value.endTime, format) : null;

  const [selectedTimeRange, setSelectedTimeRange] = useState({
    startTime: initialValueStartTime,
    endTime: initialValueEndTime,
  });

  const fieldNameStartTime = `${fieldName}.startTime`;
  const fieldNameEndTime = `${fieldName}.endTime`;

  const handleChange = (value, fieldName, shortFieldName) => {
    const time = value ? dayjs(value, format) : null;
    setSelectedTimeRange((prev) => ({ ...prev, [shortFieldName]: time }));

    formikProps.setFieldValue(fieldName, time ? dayjs(time).format(format) : null);
  };

  const handleSelect = (value, fieldName, shortFieldName) => {
    const time = dayjs(value, format);
    setSelectedTimeRange((prev) => ({ ...prev, [shortFieldName]: time }));

    formikProps.setFieldValue(fieldName, dayjs(time).format(format));
  };

  return (
    <div className="form-group form-group__column time-field time-range">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}

      <div className="time-input__wrapper form-input-width">
        <div className="time-range-field">
          <TimePicker
            className="time-range__start-time"
            value={selectedTimeRange.startTime}
            onChange={(value) => handleChange(value, fieldNameStartTime, 'startTime')}
            id={fieldNameStartTime}
            name={fieldNameStartTime}
            placeholder={'Start time'}
            bordered={false}
            onSelect={(value) => handleSelect(value, fieldNameStartTime, 'startTime')}
            suffixIcon={null}
            format={format}
            showNow={false}
            minuteStep={5}
            allowClear={true}
          />
          <SwapRightOutlined />
          <TimePicker
            className="time-range__end-time"
            value={selectedTimeRange.endTime}
            onChange={(time) => handleChange(time, fieldNameEndTime, 'endTime')}
            id={fieldNameEndTime}
            name={fieldNameEndTime}
            placeholder={'End time'}
            bordered={false}
            onSelect={(value) => handleSelect(value, fieldNameEndTime, 'endTime')}
            format={format}
            showNow={false}
            minuteStep={5}
          />
        </div>
      </div>
      <ErrorMessage
        name={fieldName}
        render={(msg) => (
          <span className="error" style={{ color: 'red' }}>
            {msg}
          </span>
        )}
      />
    </div>
  );
};

export default TimeRange;
