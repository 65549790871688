import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import SelectDocumentsForm from './SelectDocumentsForm/SelectDocumentsForm';
import { getGenericFormsForEmployeeRelative } from '../../../../../../store/actions/genericForm';
import { formVacIsRequiredSchema } from './schemes';
import Spinner from '../../../../../Common/SpinnerContainer/Spinner/Spinner';
import { getExcelFormsRelative } from '../../../../../../store/actions/excelForm';

import '../selectDocumentsModal.scss';

const EmployeeSelectDocuments = (props) => {
  const { country, setModalVisible, documentsToDisplay, setStep, formikProps } = props;

  const dispatch = useDispatch();

  const [isDocumentsLoading, setIsDocumentsLoading] = useState(true);
  const [isFormsLoading, setIsFormsLoading] = useState(true);

  const genericForms = useSelector((state) => state.genericForms.genericFormsForEmployee[country.id]) || [];
  const employeeForms = useSelector((state) => state.excelForms.excelFormsForEmployee[country.id]) || [];

  useEffect(() => {
    dispatch(getGenericFormsForEmployeeRelative({ is_for_employee: true, countries: country.id }, country.id)).finally(
      () => {
        setIsDocumentsLoading(false);
      },
    );
    dispatch(getExcelFormsRelative({ is_for_employee: true, countries: country.id }, country.id)).finally(() => {
      setIsFormsLoading(false);
    });
  }, [dispatch, genericForms?.length, employeeForms?.length]);

  const formSchema = genericForms
    .filter((genericForm) => documentsToDisplay.find((documentId) => documentId === genericForm.id))
    .map((item) => {
      return { fieldName: +item.id, type: 'CheckboxReverse', label: item.name };
    });

  const formSchemaForForms = employeeForms.map((item) => {
    return { fieldName: +item.id, type: 'CheckboxReverse', label: item.name };
  });

  const onNext = () => {
    setStep(2);
  };

  return (
    <>
      <h4 className="employee__title">Employee</h4>
      {(isDocumentsLoading || isFormsLoading) && (
        <div className={'employee-select-forms__spinner'}>
          <Spinner />
        </div>
      )}
      {!isDocumentsLoading && !isFormsLoading && (
        <SelectDocumentsForm
          setModalVisible={setModalVisible}
          onNext={onNext}
          formSchema={formSchema}
          formSchemaForForms={formSchemaForForms}
          formVacIsRequiredSchema={formVacIsRequiredSchema}
          formikProps={formikProps}
        />
      )}
    </>
  );
};

export default EmployeeSelectDocuments;
