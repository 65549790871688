import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import { getCountryRelatedPages } from '../../../../store/actions/relocation';
import SpinnerContainer from '../../../Common/SpinnerContainer/SpinnerContainer';
import './relatedPage.scss';
import BackArrow from '../../BackArrow/BackArrow';

const RelatedPage = ({ match }) => {
  const location = useLocation();

  const { relatedPageId } = match.params;

  const [page, setPage] = useState();
  const [isLoading, setLoading] = useState(!page);

  useEffect(() => {
    getCountryRelatedPages(relatedPageId).then((response) => {
      setPage(response);
      setLoading(false);
    });
  }, [relatedPageId]);
  return (
    <section className="related-page">
      <BackArrow />
      {!isLoading && page && (
        <div className="content">
          {page.packageHeader && <h2 className="block related-page__header">{parse(page.packageHeader)}</h2>}
          {page.packageDescription && <div className="block">{parse(page.packageDescription)}</div>}
          {page.packageBlock1 && <div className="block">{parse(page.packageBlock1)}</div>}
          {page.packageBlock2 && <div className="block">{parse(page.packageBlock2)}</div>}
        </div>
      )}

      {isLoading && <SpinnerContainer />}
    </section>
  );
};

export default RelatedPage;
