import QuickContactUs from './QuickContactUs/QuickContactUs';
import QuickNavUp from './QuickNavUp/QuickNavUp';

const QuickButtonsBlock = () => {
  return (
    <>
      <QuickContactUs />
      <QuickNavUp />
    </>
  );
};

export default QuickButtonsBlock;
