export const title = 'Total number of VAC appointments';

export const radioGroupOptions = [
  {
    value: 'gm_assigned',
    name: 'GMG assigned',
  },
  {
    value: 'month',
    name: 'By month',
  },
];

export const filters = {
  radioInitialValue: 'gm_assigned',
  analyticsType: 'vac_count',
};
