import { useEffect } from 'react';
import { Button } from 'antd';

import MultipleCheckboxTree from './MultipleCheckboxTree/MultipleCheckboxTree';

const MultipleCheckboxTreeFilter = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  close,
  visible,
  options,
}) => {
  useEffect(() => {
    if (!visible) {
      confirm();
    }
  }, [visible]);

  return (
    <div className="multiple-dropdown-date-range-filter">
      <MultipleCheckboxTree options={options} selectedKeys={selectedKeys} setSelectedKeys={setSelectedKeys} />

      <div className="date-range-filter__buttons-container">
        <Button
          disabled={!selectedKeys[0]}
          type={'link'}
          size="small"
          onClick={() => {
            clearFilters && clearFilters();
          }}
        >
          Reset
        </Button>

        <Button
          type="primary"
          onClick={() => {
            confirm();
          }}
          size="small"
        >
          OK
        </Button>
      </div>
    </div>
  );
};

export default MultipleCheckboxTreeFilter;
