import { useCallback, useEffect, useState } from 'react';
import { produce } from 'immer';

export const useGridWithCheckboxes = ({ columns, initialRows }) => {
  const [rows, setRows] = useState(initialRows);

  const toggleCheckbox = useCallback(
    (colKey, rowIndex) => {
      setRows((rows) =>
        produce(rows, (newRows) => {
          const row = newRows[rowIndex];
          const col = columns.find((col) => col.key === colKey);

          if (!col.isDisabled || !col.isDisabled(row)) {
            row[colKey] = !row[colKey];
          }
        }),
      );
    },
    [columns],
  );

  useEffect(() => {
    const newRowsWithPreservedInputState = initialRows.map((newRow) => {
      const { id } = newRow;

      const matchingRow = rows.find((row) => row.id === id);

      return { ...newRow, isChecked: matchingRow?.isChecked ?? newRow.isChecked };
    });

    setRows(newRowsWithPreservedInputState);
  }, [initialRows]);

  return { rows, toggleCheckbox };
};
