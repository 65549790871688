import Modal from '../../Shared/Modal/Modal';
import './modalConfirm.scss';

const ModalConfirm = (props) => {
  const { isModalVisible, setModalVisible, confirmationQuestion, handleClick } = props;
  return (
    <div className="ModalConfirm">
      <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="xs">
        <div className="content-modal">
          <h3 className="title">{confirmationQuestion}</h3>
          <div className="buttons-container">
            <button type="button" className="button btn-form main-btn btn-back" onClick={() => handleClick()}>
              Yes
            </button>
            <button type="button" className="button btn-form main-btn" onClick={() => setModalVisible(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalConfirm;
