import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import parse from 'html-react-parser';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';

import { getCountriesWithImageUrlsForRelocation } from '../../../store/actions/countriesForRelocation';
import RelocationWagesImg from '../../../assets/img/relocation/relocationWages.svg';
import RelocationPackageImg from '../../../assets/img/relocation/relocationPackage.svg';
import AskQuestionButton from '../Common/AskQuestionButton/AskQuestionButton';
import './relocationPackage.scss';

const RelocationPackage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const countriesInfo = useSelector((state) => state.countries.countriesForRelocation);

  const [isLoading, setLoading] = useState(!countriesInfo);

  const selectedCountry = location.state?.countryName
    ? location.state?.countryName
    : location.pathname.match(/about\/(.*)/)[1].replace('-', ' ');

  useEffect(() => {
    if (!countriesInfo?.length) {
      dispatch(getCountriesWithImageUrlsForRelocation()).then(() => setLoading(false));
    }
  }, [dispatch, countriesInfo?.length]);

  const countryInfo = countriesInfo?.find((item) => item.name === selectedCountry);

  if (!countryInfo && !isLoading) {
    return <h1 className="no-data">Relocation package not found!</h1>;
  }

  return (
    <section className="relocation-package">
      {!isLoading && countryInfo?.relocationPackage && (
        <div className="relocation-package">
          <div className="relocation-package__wages">
            <div>
              <img src={RelocationWagesImg} alt="pic" />
            </div>
            <h2 className="relocation-package__wages-title">
              {parse(countryInfo.relocationPackage.packageHeader ? countryInfo.relocationPackage.packageHeader : '')}
            </h2>
            <div className="relocation-package__wages-content">
              {parse(
                countryInfo.relocationPackage.packageDescription
                  ? countryInfo.relocationPackage.packageDescription
                  : '',
              )}
            </div>

            <AskQuestionButton />
          </div>
          {countryInfo?.relocationPackage?.packageBlock1 && (
            <div className="relocation-package__info">
              <div>
                <img src={RelocationPackageImg} alt="pic" />
              </div>
              <h2 className="relocation-package__info-title">Relocation to {selectedCountry}</h2>
              <div className="relocation-package__info-content">
                {parse(
                  countryInfo?.relocationPackage?.packageBlock1 ? countryInfo.relocationPackage.packageBlock1 : '',
                )}
              </div>
            </div>
          )}
        </div>
      )}
      {isLoading && <SpinnerContainer />}
    </section>
  );
};

export default RelocationPackage;
