import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IsCaseDisabledContext } from '../RequestDetails';
import RequestEmployeeFeedback from './RequestEmployeeFeedback/RequestEmployeeFeedback';
import FeedbackForm from './FeedbackForm/FeedbackForm';
import { getFeedbackFormsInCase } from '../../../store/actions/feedbackForm';
import EmailSentDeadlineBlock from './EmailSentDeadlineBlock/EmailSentDeadlineBlock';

const EmployeeFeedback = ({ requestId }) => {
  const isCaseDisabled = useContext(IsCaseDisabledContext);

  const employeeFeedback = useSelector((state) => state.requestDetails[requestId]?.requestDetails?.employeeFeedback);

  const { forms: documents, deadline, requestedAt, feedbackId } = employeeFeedback || {};

  const dispatch = useDispatch();

  useEffect(() => {
    if (!employeeFeedback) {
      dispatch(getFeedbackFormsInCase(requestId));
    }
  }, []);

  return (
    <div className="employee-feedback-section">
      {(deadline || requestedAt) && (
        <EmailSentDeadlineBlock
          requestId={requestId}
          deadline={deadline}
          requestedAt={requestedAt}
          feedbackId={feedbackId}
        />
      )}
      {employeeFeedback && (
        <RequestEmployeeFeedback requestId={requestId} documents={documents} isCaseDisabled={isCaseDisabled} />
      )}
      {documents?.length > 0 &&
        documents.map((document, index) => {
          return <FeedbackForm key={index} document={document} requestId={requestId} />;
        })}
      {!documents?.length && <p>No available forms</p>}
    </div>
  );
};

export default EmployeeFeedback;
