import actionCreators from './actionCreators';
import axios from '../../axios';
import queryString from 'query-string';

export const getGenericForm = (id) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/generic-forms/${id}/`);
    dispatch(actionCreators.genericFormLoaded(response.data));
    return response.data;
  } catch (error) {
    dispatch(actionCreators.genericFormError());
    console.error(error);
  }
};

export const getGenericForms = (page, parameters) => async (dispatch) => {
  try {
    const params = parameters ? { ...parameters, form_type: 'documents' } : { form_type: 'documents' };

    const response = await axios.get(`/api/v1/generic-forms/`, {
      params: { ...params, page: page },
      paramsSerializer: function (params) {
        return queryString.stringify(params, {
          arrayFormat: 'comma',
          skipNull: true,
          skipEmptyString: true,
        });
      },
    });

    dispatch(actionCreators.genericFormsLoaded(response.data, parameters, parameters?.countries));

    return response.data;
  } catch (error) {
    dispatch(actionCreators.genericFormsError());
    console.error(error);
  }
};

export const getGenericFormsForEmployeeRelative = (parameters, countryId) => async (dispatch) => {
  try {
    const params = parameters ? { ...parameters, form_type: 'documents' } : { form_type: 'documents' };

    const response = await axios.get(`/api/v1/generic-forms/`, {
      params: { ...params },
      paramsSerializer: function (params) {
        return queryString.stringify(params, {
          arrayFormat: 'comma',
          skipNull: true,
          skipEmptyString: true,
        });
      },
    });

    dispatch(actionCreators.genericFormsEmployeeRelativeLoad(response.data, parameters, countryId));

    return response.data;
  } catch (error) {
    dispatch(actionCreators.genericFormsError());
    console.error(error);
  }
};

export const deleteFormFromCountry = (countyFormId) => {
  return (dispatch) => {
    return axios
      .delete(`/api/v1/generic-forms/country-generic-form/${countyFormId}/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
};

export const addGenericForm = (data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.post(`/api/v1/generic-forms/`, data);
    dispatch(actionCreators.genericFormUpdated(response.data));
    dispatch(actionCreators.genericFormsAdd(response.data));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};

export const editGenericForm = (id, data) => async (dispatch) => {
  let toast = { type: 'success', errorMessage: '' };
  try {
    const response = await axios.put(`/api/v1/generic-forms/${id}/`, data);
    dispatch(actionCreators.genericFormUpdated(response.data));
    dispatch(actionCreators.genericFormsUpdated(response.data));
    return response.data;
  } catch (error) {
    toast.type = 'error';
    toast.errorMessage = error.response?.data;
  } finally {
    dispatch(
      actionCreators.toastUpdate({
        type: toast.type,
        message: toast.errorMessage,
      }),
    );
  }
};
