import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { getAppUserManager } from '../userManager';
import { authentication } from '../../../store/actions/auth';

const SignInRedirectCallback = ({ onSignedIn }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [hasSignInFailed, setHasSignInFailed] = useState(false);

  const trySignInAsync = async () => {
    try {
      const userManager = getAppUserManager();

      const { state, ...oidcUserInfo } = await userManager.signinRedirectCallback();
      onSignedIn(oidcUserInfo);
      history.replace(state);

      await userManager.clearStaleState();
      await dispatch(authentication(oidcUserInfo));
    } catch {
      setHasSignInFailed(true);
    }
  };

  useEffect(() => {
    trySignInAsync();
  }, []);

  return <p>{hasSignInFailed ? 'Something went wrong...' : 'Redirecting to app...'}</p>;
};

SignInRedirectCallback.propTypes = {
  onSignedIn: PropTypes.func.isRequired,
};

export default SignInRedirectCallback;
