import * as Yup from 'yup';

import getTypeSchema from '../../../../../Common/DocumentForm/DocumentFormValidation';

export const useValidationSchema = (document) => {
  const formSchema = document.forms[0].fields;
  let validation = {};

  formSchema.forEach((item) => {
    if (item.required) {
      const element = item.element === 'CustomElement' ? item.key : item.element;
      validation[item.fieldName] = getTypeSchema(element);
    }
  });
  const validationSchema = Yup.object().shape({
    ...validation,
  });
  return validationSchema;
};
