import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { Provider as RollbarProvider, ErrorBoundary } from '@rollbar/react';
import store from './store/store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { SignInSilentCallback, SignOutRedirectCallback } from './components/auth';
import './assets/css/index.scss';
import Rollbar from 'rollbar';

// if (process.env.REACT_APP_SENTRY_URL) {
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_URL,
//     environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
//   });
// }
const rollbarConfig = {
  accessToken: 'f94f2f732bff41ffb6ef04c11c47b1dd',
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
};

const rollbar = new Rollbar(rollbarConfig);
ReactDOM.render(
  <RollbarProvider instance={rollbar}>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <Switch>
            <Route path="/auth/signin-callback-silent" component={SignInSilentCallback} />
            <Route path="/auth/signout-callback" component={SignOutRedirectCallback} />
            <Route component={App} />
          </Switch>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </RollbarProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
