import React, { useEffect, useCallback, useState } from 'react';
import { Route } from 'react-router-dom';
import { useHistory } from 'react-router';
import RoleManagement from '../RoleManagement/RoleManagement';
import { getCurrentRelativeUrl } from '../../helpers';
import { getAppUserManager } from '../auth/userManager';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();

  const userManager = getAppUserManager();

  const [user, setUser] = useState(null);
  const signOutCurrentUser = useCallback(() => setUser(null), [setUser]);

  const login = useCallback(() => {
    const currentEncodedUrl = encodeURIComponent(getCurrentRelativeUrl());
    history.push(`/auth/signin-redirect?redirectPath=${currentEncodedUrl}`);
  }, [history]);

  const renewToken = async () => {
    let refreshedUser;
    try {
      refreshedUser = await userManager.signinSilent();
    } catch {
      refreshedUser = null;
    }

    if (refreshedUser) {
      setUser(refreshedUser);
    } else {
      if (shouldLoginAutomatically) {
        login();
      } else {
        signOutCurrentUser();
      }
    }
  };

  useEffect(() => {
    userManager.getUser().then(async function (user) {
      if (user && !user.expired) {
        setUser(user);
        return;
      }

      let shouldTryRefreshSilently = !!user;

      if (!shouldTryRefreshSilently) {
        try {
          await userManager.querySessionStatus();
          shouldTryRefreshSilently = true;
        } catch {
          shouldTryRefreshSilently = false;
        }
      }
      if (shouldTryRefreshSilently) {
        try {
          await renewToken();
        } catch (error) {
          login();
        }
      } else {
        login();
      }
    });
  }, []);

  return user && <Route {...rest} render={(props) => <RoleManagement component={Component} {...props} {...rest} />} />;
};

export default PrivateRoute;
