import * as Yup from 'yup';

const ContactInfoValidationSchema = Yup.object().shape({
  email: Yup.string().matches(
    '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])',
    'Please enter a valid email',
  ),
  phones: Yup.array().of(
    Yup.string()
      .matches(/^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/, 'Please enter correct phone number')
      .typeError('Please enter correct phone number'),
  ),
  addressInfo: Yup.array().of(
    Yup.object().shape({
      startDate: Yup.date()
        .nullable()
        .test('lteEndDate', 'Start date should be less or equal to the start date', function (val) {
          const endDate = this.resolve(Yup.ref('endDate'));
          if (!val || !endDate) {
            return true;
          }
          return val <= endDate;
        }),

      endDate: Yup.date()
        .nullable()
        .test('gteStartDate', 'End date should be greater or equal to start date', function (val) {
          const startDate = this.resolve(Yup.ref('startDate'));
          if (!val || !startDate) {
            return true;
          }
          return startDate <= val;
        }),
      address: Yup.string().test('len', 'This field is required', (val) => (val ? val.toString().length : 0 > 0)),
    }),
  ),
});

export default ContactInfoValidationSchema;
