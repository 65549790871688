import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import ContentWrapper from '../../Shared/Table/ContentWrapperMobileView/ContentWrapperMobileView';
import OtherSpellingSmgWodLinks from '../../Common/OtherSpellingSmgWodLinks/OtherSpellingSmgWodLinks';
import { defaultColumns } from './tableProperties';
import { getCityOptions } from './tableOptions';
import { getCountriesWithCompanies } from '../../../store/actions/countriesForRelocation';
import { TableCheckbox } from '../../Shared/Table/Checkbox/Checkbox';
import actionCreators from '../../../store/actions/actionCreators';
import { getResponsibleGMG } from '../../../store/actions/requestDetails';
import { defaultDateFormat } from '../../../constants';
import FilterDropdownIcon from '../../Shared/Table/FilterIcon/FilterIcon';
import { TableDropdown } from '../../Common/Table/Dropdown/Dropdown';
import { tableDropdownCustomStyles } from '../../Shared/Table/CustomStyles/dropdownCustomStyles';

const getValuesByElement = (field, value, dateExtraFieldsValue) => {
  switch (field.element) {
    case 'Checkboxes':
      if (value) {
        return value.map((item) => field.options.find((option) => option.value === item)?.text).join(', ');
      }
      return value;
    case 'RadioButtons':
      return field.options.find((option) => option.value === value)?.text;
    case 'DatePicker':
      return value;
    case 'TextArea':
      return value;
    case 'Dropdown':
      return field.options.find((option) => option.value === value)?.text;
    case 'TextInput':
      return value;
    case 'CustomElement':
      if (field.key === 'CheckboxesWithDates') {
        return value
          .map((item) => {
            const option = field.options.find((option) => option.value === item);
            return `${option?.text} - ${option?.key && dateExtraFieldsValue[option?.key]}`;
          })
          .join(', ');
      }
      if (field.key === 'RadioButtonWithTextArea') {
        const val1 = field.options.find((option) => option.value === value)?.text;
        if (val1) {
          return val1;
        } else if (value !== '' && value?.length !== 0 && value !== 'undefined' && value !== null) {
          return value;
        }
      }
      return null;
    default:
      return value;
  }
};

const defaultResponsibleFilterOption = [{ text: 'Not assigned', value: 'notAssigned' }];

export const useGetColumnsRows = ({
  relocatedEmployees,
  page,
  selectedCountry,
  generationExcelRequestIds,
  allCases,
  exludeIds,
  extraColumns,
}) => {
  const dispatch = useDispatch();

  const countriesInfo = useSelector((state) => state.countries.countriesWithCompanies);
  const user = useSelector((state) => state.auth.user);
  const gmgResponsible = useSelector((state) => state.gmgResponsible.gmgResponsible);

  const getCheckboxValue = (employeeUuid) => {
    return allCases
      ? exludeIds.includes(employeeUuid)
        ? false
        : true
      : generationExcelRequestIds[employeeUuid]
      ? generationExcelRequestIds[employeeUuid]
      : false;
  };

  useEffect(() => {
    if (!countriesInfo?.length) {
      dispatch(
        getCountriesWithCompanies({
          countries_with_companies: true,
        }),
      );
    }
  }, [dispatch, countriesInfo?.length]);

  useEffect(() => {
    if (!gmgResponsible?.length) {
      dispatch(getResponsibleGMG());
    }
  }, [dispatch]);

  const countryOptions = countriesInfo?.map((country) => ({
    value: country.name,
    text: country.name,
  }));

  const cityOptions = selectedCountry
    ? getCityOptions(countriesInfo, selectedCountry)
    : countriesInfo
        ?.map((country) =>
          country.cities.map((city) => ({
            value: city.name,
            text: city.name,
          })),
        )
        .flat() ?? [];

  const gmgResponsibleOptions = defaultResponsibleFilterOption.concat(
    gmgResponsible?.map((responsible) => {
      return { text: responsible.generalInfo.fullName, value: responsible.internalId };
    }) || [],
  );

  const secondColumns = extraColumns.map((item) => {
    let columnLabel = item?.field?.label;

    return {
      key: `${item?.field?.fieldName}_${item.formId}`,
      title: columnLabel,
      label: columnLabel,
      dataIndex: `${item?.field?.fieldName}_${item.formId}`,
      filters:
        item.field &&
        item.field.options &&
        item.field.options.length > 0 &&
        item.field.options.map((item2) => {
          return {
            text: item2.text,
            value: item2.value,
          };
        }),
      filteredValue: null,
      filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    };
  });

  const columns = defaultColumns;
  columns.forEach((item) => {
    if (item.key === 'number') {
      item.render = (value, item, index) => (
        <ContentWrapper dataLabel={'№'}>{page === 1 ? index + 1 : (page - 1) * 50 + (index + 1)}</ContentWrapper>
      );
    }
    if (item.key === 'country') {
      item.filters = countryOptions;
    }
    if (item.key === 'city') {
      item.filters = cityOptions;
    }
    if (item.key === 'gmAssigned') {
      item.filters = gmgResponsibleOptions;
    }
  });

  const onChangeGenerateExcel = (employeeUuid) => {
    dispatch(
      actionCreators.relocatedEmployeesExcelCheckboxesAdd(
        {
          [employeeUuid]: generationExcelRequestIds[employeeUuid] ? !generationExcelRequestIds[employeeUuid] : true,
        },
        employeeUuid,
      ),
    );
  };

  const displayDateFormat = useSelector((state) => state.auth.dateFormat) || defaultDateFormat;

  const rows = relocatedEmployees.map((row, index) => {
    const employeeUuid = row.uuid;

    return Object.assign(
      {
        key: index,
        number: `${index + 1}`,
        fullName: (
          <ContentWrapper dataLabel={'Name'}>
            <div>
              {!user?.isPortalStaff ? (
                row.employee.generalInfo.fullName
              ) : (
                <Link to={'/request-details/' + row.id}>
                  {row.employee.generalInfo.fullName.split(' ').reverse().join(' ')}
                </Link>
              )}
              <span className="logo-wrap">
                <OtherSpellingSmgWodLinks
                  internalId={row.employee.internalId}
                  otherNameSpelling={row.employee.generalInfo.otherNameSpelling}
                  index={row.id}
                />
              </span>
            </div>
          </ContentWrapper>
        ),
        country: (
          <ContentWrapper dataLabel={'Country'}>
            <div>{row.city?.country?.name ? row.city.country.name : 'Not provided'}</div>
          </ContentWrapper>
        ),
        city: (
          <ContentWrapper dataLabel={'City'}>
            <div>{row.city.name}</div>
          </ContentWrapper>
        ),
        gmAssigned: (
          <ContentWrapper dataLabel={'GM assigned'}>
            <div>{row.caseGmtMember?.generalInfo?.fullName}</div>
          </ContentWrapper>
        ),
        status: (
          <ContentWrapper dataLabel={'Status'}>
            <div> {row.status}</div>
          </ContentWrapper>
        ),
        relocationDate: (
          <ContentWrapper dataLabel={'Relocation date'}>
            <div>
              {row.employeeRelocationDate
                ? dayjs(row.employeeRelocationDate).format(displayDateFormat)
                : 'Not provided'}
            </div>
          </ContentWrapper>
        ),
        residencePermit: (
          <ContentWrapper dataLabel={'Residence permit'}>
            <div>{row?.plTrcBlueCardStatus?.replace(/_/gi, ' ') || 'Not provided'}</div>
          </ContentWrapper>
        ),
        residencePermitExpiration: (
          <ContentWrapper dataLabel={'Residence permit expiration'}>
            {row?.plTrcBlueCardDate ? dayjs(row?.plTrcBlueCardDate).format(displayDateFormat) : 'Not provided'}
          </ContentWrapper>
        ),
        residencePermitTypeLt: (
          <ContentWrapper dataLabel={'Residence permit type'}>
            {row?.ltResidencePermitType?.replace(/_/gi, ' ') || 'Not provided'}
          </ContentWrapper>
        ),
        residencePermitExpiresLt: (
          <ContentWrapper dataLabel={'Residence permit expires'}>
            {row?.ltResidencePermitExpires
              ? dayjs(row?.ltResidencePermitExpires).format(displayDateFormat)
              : 'Not provided'}
          </ContentWrapper>
        ),
        notes: <ContentWrapper dataLabel={'Notes'}>{row?.statusNote || ''}</ContentWrapper>,
        excel: (
          <ContentWrapper dataLabel={'Excel'}>
            <TableCheckbox
              value={getCheckboxValue(employeeUuid)}
              onChange={() => onChangeGenerateExcel(employeeUuid)}
            />
          </ContentWrapper>
        ),
      },
      ...(row.forms
        ? row.forms?.map((item2) => {
            const { fields, values } = item2.forms[0];
            return Object.assign(
              {},
              ...fields.map((field) => {
                if (field.fieldName === 'notes') {
                  const value = getValuesByElement(
                    field,
                    values[field.fieldName],
                    values[field.fieldName + 'DateExtraFields'],
                  );
                  return {
                    [`${field?.fieldName}_${item2.id}`]: (
                      <div style={{ minWidth: '250px' }}>
                        {value && value.length > 120 ? `${value.slice(0, 120)}...` : value}
                      </div>
                    ),
                  };
                }
                return {
                  [`${field?.fieldName}_${item2.id}`]: getValuesByElement(
                    field,
                    values[field.fieldName],
                    values[field.fieldName + 'DateExtraFields'],
                  ),
                };
              }),
            );
          })
        : []),
    );
  });

  return [columns, secondColumns, rows];
};
