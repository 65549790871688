import { Form, Formik } from 'formik';
import { getFormElement } from '../../../../Shared/Form/FormElements';

export const iTechArtEmployeeSchema = [
  {
    fieldName: 'isITechArtEmployee',
    type: 'Checkbox',
    label: 'Is iTechArt/Vention employee?',
    disabled: true,
  },
];

const LinkedEmployeeTab = () => {
  return (
    <div className="employee-tabs">
      <Formik enableReinitialize initialValues={{ isITechArtEmployee: true }}>
        {(formikProps) => (
          <Form>
            {iTechArtEmployeeSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
            ))}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LinkedEmployeeTab;
