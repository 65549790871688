import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { SubmitButton } from '../../../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../../../Shared/Form/FormElements';
import { addCity } from '../../../../../store/actions/countriesForRelocation';
import { getFormSchemaWithRequiredField } from '../../../../../helpers';

export const validationSchema = Yup.object().shape({
  countryId: Yup.string().required('This field is required'),
  name: Yup.string().required('This field is required'),
});

const AddNewCity = (props) => {
  const { countries, setIsAddNewCity } = props;

  const dispatch = useDispatch();

  const formSchemaWithoutRequiredField = [
    {
      fieldName: 'countryId',
      type: 'Dropdown',
      label: 'Country',
      options: countries.map((country) => {
        return {
          text: country.name,
          key: country.id,
          value: country.id,
        };
      }),
    },
    {
      fieldName: 'name',
      type: 'TextInput',
      label: 'City',
    },
  ];

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField);

  const initialValues = {
    countryId: '',
    name: '',
  };

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(addCity(values))
      .then(() => setIsAddNewCity(false))
      .finally(() => setSubmitting(false));
  };
  return (
    <>
      <h3 className="underlined panel-top-row">New city</h3>
      <div className="new-city__container">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
              {formSchema.map((field) => (
                <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
              ))}
              <div className="new-case__button-container">
                <SubmitButton title="Submit" formikProps={formikProps} />
                <button type="button" className="button btn-form main-btn" onClick={() => setIsAddNewCity(false)}>
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddNewCity;
