import { SubmitButton } from '../../../../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../../../../Shared/Form/FormElements';
import { formDeadlineSchema } from './formSchema';

const SetDeadLine = ({ setStep, formikProps, values }) => {
  return (
    <>
      <>
        {formDeadlineSchema.map((field) => (
          <div key={field.fieldName} className={field.fieldName}>
            {getFormElement(field.type, field, formikProps)}
          </div>
        ))}
      </>
      <div className="employee-select-documents__button-container">
        <button type="button" className="button btn-form main-btn" onClick={() => setStep(1)}>
          Back
        </button>
        <SubmitButton title="Send" formikProps={formikProps} forceDisabled={!Object.values(values).includes(true)} />
      </div>
    </>
  );
};

export default SetDeadLine;
