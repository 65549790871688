import React, { useMemo } from 'react';
import { Field, ErrorMessage } from 'formik';
import Select from 'react-select';
import classNames from 'classnames';

import { customStyles } from '../customStyles';
import './currency.scss';

const Currency = (props) => {
  const { name, label, options, formIndex, nameFieldArray, formikProps, disabled, updateselectvalueroot, isRequired } =
    props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;
  const fieldNameCost = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.ticketCost` : 'salaryAfterRelocation';

  const onChange = (e) => {
    formikProps.setFieldValue(fieldName, e.target.value);
    if (updateselectvalueroot) {
      updateselectvalueroot(e.target.value, formikProps, e.target.value);
    }
  };

  const optionValues = useMemo(
    () =>
      options.map((option) => ({
        label: option.name || option.label || option.text,
        value: option.id || option.value,
      })),
    [options],
  );

  const defaultValue = optionValues.find((option) => {
    let formikOption = '';
    if (label === 'Salary after relocation') {
      formikOption = formikProps?.values[name];
    } else {
      formikOption = formikProps?.values[name] ?? formikProps?.values[nameFieldArray][formIndex][name];
    }
    return option.value === formikOption;
  });

  const handleChange = (e) => {
    formikProps.setFieldValue('salaryAfterRelocation', e.target.value);

    if (updateselectvalueroot) {
      updateselectvalueroot(e.target.value, formikProps);
    }
  };

  return (
    <div className="form-group currency-form">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}
      <div className="currency-container">
        {!disabled ? (
          <Select
            id="currency"
            value={defaultValue}
            options={optionValues}
            styles={customStyles}
            isDisabled={disabled}
            onChange={(option) => onChange({ target: { value: option.value, options: optionValues } })}
            // {...(updateselectvalueroot ? { onBlur: onChange } : {})}
            className="responsible-select-top"
          />
        ) : (
          <div style={{ display: 'flex', alignItems: 'center' }}>{defaultValue.label}</div>
        )}

        <Field
          className="form-control currency-cost"
          type="text"
          id={fieldName + '-amount'}
          name={fieldNameCost}
          disabled={disabled}
          {...(updateselectvalueroot ? { onBlur: handleChange } : {})}
        />
      </div>
      <ErrorMessage
        name={fieldNameCost}
        render={(msg) => (
          <span className="error" style={{ color: 'red' }}>
            {msg}
          </span>
        )}
      />
      <ErrorMessage name={fieldName} render={(msg) => <div style={{ color: 'red' }}>{msg}</div>} />
    </div>
  );
};

export default Currency;
