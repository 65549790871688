import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import { editGeneralInfo, getCitizenships, getNationalities } from '../../../../../../store/actions/requestDetails';
import { SubmitButton } from '../../../../../Shared/Form/Buttons/Buttons';
import { getFormElement } from '../../../../../Shared/Form/FormElements';
import { formSchema as formSchemaWithoutRequiredField, validationSchema } from './formSchema';
import { getFormSchemaWithRequiredField } from '../../../../../../helpers';
import './personalInfo.scss';

const PersonalInfo = ({ employeeId, generalInfo }) => {
  const dispatch = useDispatch();

  const nationalities = useSelector((state) => state.nationalitiesList.nationalitiesList);
  const citizenships = useSelector((state) => state.nationalitiesList.citizenshipsList);

  const initialValues = {
    nationalityId: generalInfo.nationality?.id,
    citizenshipId: generalInfo.citizenship?.id,
    passportFirstName: generalInfo.passportFirstName || '',
    passportLastName: generalInfo.passportLastName || '',
  };

  useEffect(() => {
    if (!nationalities?.length) {
      dispatch(getNationalities());
    }
  }, [dispatch, nationalities?.length]);

  useEffect(() => {
    if (!citizenships?.length) {
      dispatch(getCitizenships());
    }
  }, [dispatch, citizenships?.length]);

  nationalities &&
    formSchemaWithoutRequiredField.forEach((field) => {
      if (field.fieldName === 'nationalityId') {
        field.options = nationalities;
      }
      if (field.fieldName === 'citizenshipId') {
        field.options = citizenships;
      }
    });

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    dispatch(editGeneralInfo(employeeId, null, values, true));
    setSubmitting(false);
  };

  const formSchema = getFormSchemaWithRequiredField(validationSchema, formSchemaWithoutRequiredField);

  return (
    <div className="employee-additional-info employee-personal-info">
      <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(formikProps) => (
          <Form>
            {formSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
            ))}
            <SubmitButton title="Save" formikProps={formikProps} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PersonalInfo;
