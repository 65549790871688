import { useState } from 'react';

import RequestDocumentsFormButton from '../../../RequestDetails/EmployeeDocumentsBlock/RequestEmployeeDocuments/RequestDocumentsFormButton/RequestDocumentsFormButton';
import SelectDocumentsModal from './SelectDocumentsModal/SelectDocumentsModal';

const RequestEmployeeProfileDocuments = ({
  isDocsEditable,
  requestedDocuments,
  employeeId,
  documentsToDisplay,
  employeeForms,
}) => {
  const [isModalVisible, setModalVisible] = useState(false);

  return (
    <>
      <RequestDocumentsFormButton
        isDocsEditable={isDocsEditable}
        requestedDocuments={requestedDocuments}
        documentsStatus={false}
        setModalVisible={setModalVisible}
        employeeId={employeeId}
      />
      {isModalVisible && (
        <SelectDocumentsModal
          isModalVisible={isModalVisible}
          setModalVisible={setModalVisible}
          documentsToDisplay={documentsToDisplay}
          employeeForms={employeeForms}
          employeeId={employeeId}
        />
      )}
    </>
  );
};

export default RequestEmployeeProfileDocuments;
