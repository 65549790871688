import { Link } from 'react-router-dom';

const AskQuestionButton = () => {
  return (
    <Link to="/relocation/ask-question">
      <button className="button btn-form main-btn btn-back">Ask a question</button>
    </Link>
  );
};

export default AskQuestionButton;
