import axios from '../../axios';

import actionCreators from './actionCreators';
import queryString from 'query-string';

export const sendEmail = (data) => {
  return axios
    .post('/api/v1/notification/send-email/', data)
    .then((response) => {
      return response?.status;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const sendLegalizationDocumentsEmail = (data) => {
  return axios
    .post('/api/v1/notification/send-legalization-documents-email/', data)
    .then((response) => {
      return response?.status;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const contactEmployee = (data, requestId) => {
  return axios
    .post(`/api/v1/relocation-requests/${requestId}/relocation-finished/`, data)
    .then((response) => {
      return response?.status;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};

export const getEmployeesEmails = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/employees/');
    dispatch(actionCreators.employeesWithEmailsLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmailTemplates = () => async (dispatch) => {
  try {
    const response = await axios.get('/api/v1/notification/templates/');
    dispatch(actionCreators.emailTemplatesLoad(response.data));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getTemplate = (templateId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/notification/templates/${templateId}`);
    dispatch(actionCreators.emailTemplateLoad(response.data, templateId));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getContactEmployeeTemplate = (requestId) => async (dispatch) => {
  try {
    const response = await axios.get(`/api/v1/relocation-requests/${requestId}/relocation-finished/template`);
    dispatch(actionCreators.contactEmployeeTemplateLoad(response.data, requestId));
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getEmployee = (requestId) => {
  return axios
    .get(`/api/v1/relocation-requests/${requestId}/employee/`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
