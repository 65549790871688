export const customStyles = {
  control: (base, state) => ({
    ...base,
    borderColor: '#ced4da',
    cursor: 'pointer',
    boxShadow: state.isFocused ? 0 : 0,
    '&:hover': {
      borderColor: '#ec2227',
    },
  }),
  option: (provided, state) => ({
    ...provided,
    color: 'black',
    backgroundColor: state.isSelected ? '#cccccb' : 'white',
    '&:hover': {
      backgroundColor: state.isSelected ? '#cccccb' : 'rgba(204, 204, 204, .3)',
    },
    cursor: 'pointer',
    border: 'none',
  }),
  placeholder: (placeholder) => ({
    ...placeholder,
    fontWeight: '300',
    color: 'black',
    opacity: 0.5,
  }),
  valueContainer: (valueContainer) => ({
    ...valueContainer,
    padding: '0px 8px',
  }),
  singleValue: (valueContainer) => ({
    ...valueContainer,
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 2 }),
  menu: (provided) => ({ ...provided, zIndex: 2 }),
};
