import { types } from '../actions/types';
import _ from 'lodash';

export const initialState = {
  legalizationForm: {
    name: '',
    description: '',
    forms: {},
    fieldset: [],
    isCanBePlural: false,
    isCanBeArchived: false,
    isWithAttachments: false,
    countries: [],
  },
};

const legalizationFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LEGALIZATION_FORM_LOADED:
      return {
        legalizationForm: action.payload.legalizationForm,
      };
    case types.LEGALIZATION_FORM_UPDATED:
      return {
        ...state,
        legalizationForm: _.cloneDeep(action.payload.legalizationForm),
      };
    default:
      return {
        ...state,
      };
  }
};

export default legalizationFormReducer;
