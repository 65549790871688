import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import SpinnerContainer from '../Common/SpinnerContainer/SpinnerContainer';
import { authorization } from '../../store/actions/auth';
import { addNewEvent } from '../../store/actions/events';
import AppLayout from '../Layouts/AppLayout/AppLayout';
import ScrollToTop from '../Shared/ScrollToTop/ScrollToTop';
import getEventSource from '../Events/EventSourceProvider';
import { getDateFormat } from '../../store/actions/core';
import { getAppUserManager } from '../auth/userManager';
import actionCreators from '../../store/actions/actionCreators';

const RoleManagement = ({ component: Component, secondaryLayout, rootPage, roles, path, ...rest }) => {
  const dispatch = useDispatch();

  const isAuthorizated = useSelector((state) => state.auth.isAuthorizated);
  const user = useSelector((state) => state.auth.user);

  const isDateFormatLoaded = useSelector((state) => state.auth.isDateFormatLoaded);

  useEffect(() => {
    if (!isAuthorizated || !user.uuid) {
      dispatch(authorization());
    }
  }, [isAuthorizated]);

  useEffect(() => {
    if (!isDateFormatLoaded && (userRoles.includes('gmg') || userRoles.includes('hr'))) {
      dispatch(getDateFormat());
    }
  }, []);

  const isApproverStaff = user?.isApproverStaff;
  const isPortalStaff = user?.isPortalStaff;
  const isHrManager = user?.isHrManager;

  const userRoles = [];
  if (user?.isPortalStaff) {
    userRoles.push('gmg');
  }
  if (user?.isApproverStaff) {
    userRoles.push('approver');
  }
  if (user?.isHrManager) {
    secondaryLayout = userRoles.length === 0 && rootPage ? true : secondaryLayout;
    userRoles.push('hr');
  }
  if (!user?.isApproverStaff && !user?.isPortalStaff) {
    secondaryLayout = userRoles.length === 0 && rootPage ? true : secondaryLayout;
    userRoles.push('employee');
  }

  const updateEvents = (event) => {
    const eventData = JSON.parse(event.data);
    const { eventType, instanceId } = eventData;
    dispatch(addNewEvent(instanceId, eventType, eventData));
  };

  useEffect(async () => {
    if (isAuthorizated && (userRoles.includes('gmg') || userRoles.includes('hr'))) {
      const groupName = userRoles.includes('gmg') ? 'gmg' : 'hr';
      const source = await getEventSource(groupName);
      if (!source.onmessage && 'EventSource' in window) {
        source.onmessage = updateEvents;
      }
    }
  }, [isAuthorizated]);

  return (
    <>
      {isAuthorizated ? (
        <AppLayout
          secondaryLayout={secondaryLayout}
          isApproverStaff={isApproverStaff}
          isPortalStaff={isPortalStaff}
          isHrManager={isHrManager}
        >
          {userRoles.find((role) => roles.includes(role)) ? (
            <ScrollToTop>
              <Component
                {...rest}
                isApproverStaff={isApproverStaff}
                isPortalStaff={isPortalStaff}
                isHrManager={isHrManager}
              />
            </ScrollToTop>
          ) : (
            <Redirect to="/access-denied" />
          )}
        </AppLayout>
      ) : (
        <SpinnerContainer />
      )}
    </>
  );
};

export default RoleManagement;
