import { Formik, Form as FormikForm, Field, ErrorMessage, useField } from 'formik';
import TextField from './TextField/TextField';
import TextFieldRelocation from './TextFieldRelocation/TextFieldRelocation';
import DateField from './DateField/DateField';
import TimeField from './TimeField/TimeField';
import AddressField from './AddressField/AddressField';
import TextArea from './TextArea/TextArea';
import CheckboxGroup from './CheckboxGroup/CheckboxGroup';
import NoteField from './NoteField/NoteField';
import RadioGroup from './RadioGroup/RadioGroup';
import Checkbox from './Checkbox/Checkbox';
import CheckboxReverse from './Checkbox/CheckboxReverse';
import Currency from './Currency/Currency';
import DatepickerWithDisabledCheckbox from './DatepickerWithDisabledCheckbox/DatepickerWithDisabledCheckbox';
import TicketField from './TicketField/TicketField';
import PhoneField from './PhoneField/PhoneField';
import CustomSelect from './Dropdown/CustomSelect/CustomSelect';
import SelectField from './Dropdown/SelectField/SelectField';
import TextareaAsInput from './TextAreaAsInput/TextAreaAsInput';
import CheckboxGroupWithDates from './CheckboxGroupWithDates/CheckboxGroupWithDates';
import AttachmentBlock from './AttachFiles/AttachmentBlock';
import CheckboxWithDatepicker from './CheckboxWithDatepicker/CheckboxWithDatepicker';
import TinyMCEField from './TinyMCEField/TinyMCEField';
import SelectTags from './Dropdown/SelectTags/SelectTags';
import TimeRange from './TimeRange/TimeRange';
import DateFieldAsText from './DateFieldAsText/DateFieldAsText';

import './FormElements.scss';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import QuestionIcon from '../../../assets/img/icons/Question.svg';
import { useEffect, useState } from 'react';

export const Form = (props) => {
  return (
    <Formik {...props}>
      <FormikForm className="form-horizontal needs-validation">{props.children}</FormikForm>
    </Formik>
  );
};

const RadioGroupWithOtherOption = (props) => {
  const {
    name,
    label,
    formIndex,
    nameFieldArray,
    options,
    canHaveDisplayHorizontal,
    formikProps,
    disabled,
    isRequired,
  } = props;
  const fieldName = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${name}` : `${name}`;

  const [field, , { setValue }] = useField(fieldName);
  const [fieldOtherValue, setFieldOtherValue] = useState('');

  const handleChange = (e) => {
    const value = e.target.value;
    setValue(value);
  };

  const [isOtherOptionChecked, setIsOtherOptionChecked] = useState(false);

  useEffect(() => {
    if (field.value !== '' && field.value?.length !== 0 && typeof field.value !== 'undefined' && field.value !== null) {
      if (!options.find((obj) => field.value === obj.value)) {
        setFieldOtherValue(field.value);
        !isOtherOptionChecked && setIsOtherOptionChecked(true);
      } else if (field.value && options.find((obj) => field.value === obj.value)) {
        setFieldOtherValue('');
        isOtherOptionChecked && setIsOtherOptionChecked(false);
      }
    } else {
      setFieldOtherValue('');
      isOtherOptionChecked && setIsOtherOptionChecked(false);
    }
  }, [field.value]);

  return (
    <div className="custom-radio radio-horizontal">
      {label && (
        <label className={classNames('control-label', { required: isRequired })} htmlFor={fieldName}>
          {label}
        </label>
      )}

      <div className="radios-error-container">
        <div className={classNames(canHaveDisplayHorizontal ? 'radio-horizontal' : '', 'radio-group__container')}>
          {options.map((option, index) => {
            const key = formIndex >= 0 ? `${nameFieldArray}.${formIndex}.${option.key}` : option.key;

            return (
              <div className="relocation-group" role="group" aria-labelledby="my-radio-group" key={index}>
                <div className="relocation-group-radio">
                  <div className="relocation-type-input">
                    <Field
                      type="radio"
                      name={fieldName}
                      value={option.value}
                      className="custom-control-input"
                      id={key}
                      disabled={disabled}
                      onChange={handleChange}
                    />
                    <label className="custom-radio__label" htmlFor={key}>
                      <span></span>
                    </label>
                  </div>

                  <label className="radio-option" htmlFor={key}>
                    {option.text}

                    {option.tooltip && (
                      <span>
                        <Tooltip placement={'top'} title={option.tooltip}>
                          <div className="tooltip-button">
                            <img src={QuestionIcon} alt="question" />
                          </div>
                        </Tooltip>
                      </span>
                    )}
                  </label>
                </div>
              </div>
            );
          })}
          <div
            className={classNames(
              'text-field__wrapper',
              name === 'additionalDocuments' ? 'col-md-7' : name === 'address' ? 'col-md-5' : 'form-input-width',
              'other-option-width',
            )}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ width: '18px', height: '18px', marginRight: '10px' }}>
                <div
                  className={classNames(isOtherOptionChecked ? 'test-checked' : 'test-x', disabled && 'disabled')}
                ></div>
              </div>
              <Field
                className={classNames('form-control', 'form-width', 'other-option')}
                type="text"
                // name={otherFieldName}
                // id={otherFieldName}
                value={fieldOtherValue || ''}
                disabled={disabled}
                placeholder={'Other'}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <ErrorMessage
          name={fieldName}
          render={(msg) => (
            <span className="error" style={{ color: 'red' }}>
              {msg}
            </span>
          )}
        />
      </div>
    </div>
  );
};

export const getFormElement = (
  type,
  field,
  formikProps,
  forceDisabled,
  allowCustomReminders,
  isLegalizationRequestDocument,
) => {
  const props = {
    name: field.fieldName,
    label: field.label,
    id: field.id,
    disabled: forceDisabled ? forceDisabled : field.disabled,
    isclearable: field.isClearable,
    options: field.options,
    updateselectvalueroot: field.updateselectvalueroot,
    notify: field.notify,
    notify_days: field.notifyDaysBefore,
    placeholder: field?.placeholder,
    formikProps: formikProps,
    formIndex: field?.formIndex,
    nameFieldArray: field?.nameFieldArray,
    canHaveDisplayHorizontal: field?.inline,
    ticketamount: field?.ticketAmount,
    ismulti: field?.isMulti,
    isRequired: field?.required,
    filteroption: field?.filterOption,
  };

  if (type === 'CustomElement') {
    switch (field.key) {
      case 'CheckboxesWithDates':
        return <CheckboxGroupWithDates {...props} />;
      case 'RadioButtonWithTextArea':
        return <RadioGroupWithOtherOption {...props} />;
    }
  }

  switch (type) {
    case 'TextInput':
    case 'NumberInput':
      return <TextField {...props} />;
    case 'InputText':
      return <TextFieldRelocation {...props} />;
    case 'DatePicker':
      props.oncalendarcloseupdate = field?.oncalendarcloseupdate;
      return <DateField {...props} allowCustomReminders={allowCustomReminders || false} />;
    case 'TimeField':
      return <TimeField {...props} />;
    case 'Dropdown':
      return <SelectField {...props} />;
    case 'Checkboxes':
      return <CheckboxGroup {...props} />;
    case 'Radios':
      return <RadioGroup {...props} />;
    case 'RadioButtons':
      return <RadioGroup {...props} />;
    case 'Checkbox':
      return <Checkbox {...props} />;
    case 'CheckboxReverse':
      return <CheckboxReverse {...props} />;
    case 'CheckboxWithDatepicker':
      return <CheckboxWithDatepicker {...props} />;
    case 'DatepickerWithDisabledCheckbox':
      props.oncalendarcloseupdate = field?.oncalendarcloseupdate;
      return <DatepickerWithDisabledCheckbox {...props} />;
    case 'DateFieldAsText':
      return <DateFieldAsText {...props} />;
    case 'TextArea':
      return <TextArea {...props} />;
    case 'TextareaAsInput':
      return <TextareaAsInput {...props} />;
    case 'Currency':
      return <Currency {...props} />;
    case 'PhoneField':
      return <PhoneField {...props} />;
    case 'AddressField':
      return <AddressField {...props} />;
    case 'CustomSelect':
      return <CustomSelect {...props} />;
    case 'TicketField':
      return <TicketField {...props} />;
    case 'NoteField':
      return <NoteField {...props} />;
    case 'TinyMCEField':
      return <TinyMCEField {...props} />;
    case 'SelectTags':
      return <SelectTags {...props} />;
    case 'AttachFiles':
      return <AttachmentBlock {...props} isLegalizationRequestDocument={isLegalizationRequestDocument} />;
    case 'TimeRange':
      return <TimeRange {...props} />;
    default:
      return false;
  }
};
