import TitleWithSort from '../../Shared/Table/TitleWithSort/TitleWithSort';
import FilterDropdownIcon from '../../Shared/Table/FilterIcon/FilterIcon';
import MultipleFilter from '../../Shared/Table/MultipleFilter/CheckboxGroupDateRangeFilter';
import {
  // workDocumentsOptions,
  // stayDocumentsOptions,
  passportOptions,
  employmentDateOptions,
  generalDocumentsOptions,
  reasonOptions,
  visaDocumentsOptions,
  residentPermitOptions,
  slovakiaWorkDocumentsOptions,
  polandWorkDocumentsOptions,
  polandStayDocumentsOptions,
  slovakiaStayDocumentsOptions,
} from './filterOptions';
import CheckboxTreeDateRangeFilter from '../../Shared/Table/MultipleFilter/CheckboxTreeDateRangeFilter';
import { useDispatch, useSelector } from 'react-redux';
import actionCreators from '../../../store/actions/actionCreators';
import classNames from 'classnames';

const ExcelTitle = () => {
  const dispatch = useDispatch();
  const { allCases } = useSelector((state) => state.legalizationDocuments);

  const handleClickAllCasesExcel = () => {
    dispatch(actionCreators.legalizationDocumentsAllCasesUpdate());
  };

  return (
    <span className="excel-title__container">
      Email
      <div
        className={classNames('all-excel-btn', { allExcelBtnActive: allCases })}
        onClick={() => handleClickAllCasesExcel()}
      >
        All
      </div>
    </span>
  );
};

export const columns = [
  {
    key: 'number',
    title: '№',
    label: '№',
    dataIndex: 'number',
    fixed: 'left',
    width: '2%',
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'fullName'} title={'Employee name'} />,
    label: 'Employee name',
    key: 'fullName',
    dataIndex: 'fullName',
    sorter: {
      multiple: 1,
    },
    fixed: 'left',
    width: '10%',
    sortOrder: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'organization'} title={'Organization'} />,
    label: 'Organization',
    key: 'organization',
    dataIndex: 'organization',
    width: '6%',
    sorter: {
      multiple: 2,
    },
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
    sortOrder: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'unit'} title={'Unit'} />,
    label: 'Unit',
    key: 'unit',
    dataIndex: 'unit',
    width: '6%',
    sorter: {
      multiple: 3,
    },
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
    sortOrder: null,
  },
  {
    title: 'GM assigned',
    label: 'GM assigned',
    key: 'gmgAssigned',
    dataIndex: 'gmgAssigned',
    width: '5%',
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'employmentDate'} title={'Employment date'} />,
    label: 'Employment date',
    key: 'employmentDate',
    dataIndex: 'employmentDate',
    sorter: {
      multiple: 4,
    },
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <MultipleFilter {...props} options={employmentDateOptions} />,
    width: '12%',
    sortOrder: null,
    filteredValue: null,
  },
  {
    title: 'City',
    label: 'City',
    key: 'city',
    dataIndex: 'city',
    width: '6%',
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
  },
  {
    title: 'Residency city',
    label: 'Residency city',
    key: 'residencyCity',
    dataIndex: 'residencyCity',
    width: '6%',
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'citizenship'} title={'Citizenship'} />,
    label: 'Citizenship',
    key: 'citizenship',
    dataIndex: 'citizenship',
    width: '5%',
    sorter: {
      multiple: 5,
    },
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
    sortOrder: null,
  },
  {
    title: 'Passport',
    label: 'Passport',
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <MultipleFilter {...props} options={passportOptions} />,
    key: 'passport',
    dataIndex: 'passport',
    width: '6%',
    filteredValue: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'visa'} title={'Visa'} />,
    label: 'Visa',
    sorter: {
      multiple: 6,
    },
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <CheckboxTreeDateRangeFilter {...props} options={visaDocumentsOptions} />,
    key: 'visa',
    dataIndex: 'visa',
    width: '10%',
    sortOrder: null,
    filteredValue: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'stayDocuments'} title={'Stay basis'} />,
    label: 'Stay basis',
    sorter: {
      multiple: 7,
    },
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filters: reasonOptions,
    // filterDropdown: (props) => <CheckboxTreeDateRangeFilter {...props} options={stayDocumentsOptions} />,
    key: 'stayDocuments',
    dataIndex: 'stayDocuments',
    width: '10%',
    sortOrder: null,
    filteredValue: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'workDocuments'} title={'Work basis'} />,
    label: 'Work basis',
    sorter: {
      multiple: 8,
    },
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    // filterDropdown: (props) => <CheckboxTreeDateRangeFilter {...props} options={workDocumentsOptions} />,
    key: 'workDocuments',
    dataIndex: 'workDocuments',
    width: '10%',
    sortOrder: null,
    filteredValue: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'residencePermit'} title={'Residence permit'} />,
    label: 'Residence permit',
    sorter: {
      multiple: 9,
    },
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <CheckboxTreeDateRangeFilter {...props} options={residentPermitOptions} />,
    key: 'residencePermit',
    dataIndex: 'residencePermit',
    width: '8%',
    sortOrder: null,
    filteredValue: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'documents'} title={'Documents'} />,
    label: 'Documents',
    sorter: {
      multiple: 10,
    },
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <MultipleFilter {...props} options={generalDocumentsOptions} />,
    key: 'documents',
    dataIndex: 'documents',
    width: '14%',
    sortOrder: null,
    filteredValue: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'reminders'} title={'Reminders'} />,
    key: 'reminders',
    label: 'Reminders',
    sorter: {
      multiple: 11,
    },
    dataIndex: 'reminders',
    sortOrder: null,
    width: '6%',
  },
  {
    key: 'actions',
    title: 'Actions',
    label: 'Actions',
    dataIndex: 'actions',
    width: '5%',
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filters: [
      // { value: 'Not provided', text: 'Not provided' },
      { value: 'Documents verified', text: 'Documents verified' },
      { value: 'Attention needed', text: 'Attention needed' },
      { value: 'No documents to check', text: 'No documents to check' },
    ],
    filteredValue: null,
  },
  {
    key: 'excel',
    title: <ExcelTitle />,
    label: 'Email',
    dataIndex: 'excel',
    width: '4%',
  },
];

export const sortMapping = {
  stayDocuments: 'ordering_stay_date',
  workDocuments: 'ordering_work_date',
  visa: 'ordering_visa_date',
  unit: 'unit_name',
  employmentDate: 'employment_date',
  citizenship: 'citizenship',
  residencePermit: 'residence_permit',
  fullName: 'last_name',
  organization: 'organization',
  documents: 'documents_date',
  reminders: 'reminders',
};

export const filterMapping = {
  citizenship: 'citizenship_id',
  residencyCity: 'residency_city_id',
  unit: 'unit_id',
  workDocuments: 'work_date',
  stayDocuments: 'stay_date',
  visa: 'visa_date',
  residencePermit: 'residence_permit_date',
  passport: 'passport_expiration_date',
  organization: 'company_id',
  documents: 'documents_date',
  employmentDate: 'employment_date',
  city: 'workplace_city_name',
  gmgAssigned: 'responsible',
  actions: 'documents_status',
};

export const dateRangeFilterMapping = [];

export const multipleFilterMapping = {
  filterMapping: {
    stay_date: 'stay_document',
    work_date: 'work_document',
    visa_date: 'visa_document',
    residence_permit_date: 'residence_permit',
    passport_expiration_date: 'passport_absence',
    documents_date: 'documents',
    employment_date: 'employment_date_not_provided',
  },
  dateRangeFilterMapping: [
    'stay_date',
    'work_date',
    'visa_date',
    'passport_expiration_date',
    'documents_date',
    'employment_date',
    'residence_permit_date',
  ],
};

export const stayDocumentTypeOptions = [
  { label: 'Passport', value: 'passport' },
  { label: 'Visa', value: 'visa' },
];

export const workDocumentTypeOptions = [
  { label: 'PESEL UKR', value: 'pesel-ukr' },
  { label: 'Zezwolenie', value: 'zezwolenie' },
  { label: 'Oswiadczenie', value: 'oswiadczenie' },
  { label: 'Certificate from the university', value: 'reference-from-the-university' },
];

export const columnsKeys = [
  'number',
  'fullName',
  'employmentDate',
  'city',
  'citizenship',
  'passport',
  'stayDocuments',
  'workDocuments',
  'residencePermit',
  'gmgAssigned',
  'reminders',
  'actions',
];

export const columnsKeysPL = [
  'number',
  'fullName',
  'employmentDate',
  'unit',
  'city',
  'residencyCity',
  'citizenship',
  'passport',
  'visa',
  'stayDocuments',
  'workDocuments',
  'residencePermit',
  'gmgAssigned',
  'reminders',
  'actions',
  'excel',
];

export const columnsKeysSL = [
  'number',
  'fullName',
  'employmentDate',
  'unit',
  'city',
  'residencyCity',
  'citizenship',
  'passport',
  // 'visa',
  'stayDocuments',
  'workDocuments',
  // 'residencePermit',
  'gmgAssigned',
  'reminders',
  'actions',
];

export const columnsKeysDefault = [
  'number',
  'fullName',
  'organization',
  'unit',
  'employmentDate',
  'city',
  'residencyCity',
  'citizenship',
  'documents',
  'gmgAssigned',
  'reminders',
  'actions',
];

export const getColumnsKeys = (selectedCountry) => {
  switch (selectedCountry.label.toLowerCase()) {
    case 'poland':
      return columnsKeysPL;
    case 'slovakia':
      return columnsKeysSL;
    default:
      return columnsKeysDefault;
  }
};

export const getWorkDocumentsOption = (selectedCountry) => {
  switch (selectedCountry.toLowerCase()) {
    case 'poland':
      return polandWorkDocumentsOptions;
    case 'slovakia':
      return slovakiaWorkDocumentsOptions;
    default:
      return [];
  }
};

export const getStayDocumentsOption = (selectedCountry) => {
  switch (selectedCountry.toLowerCase()) {
    case 'poland':
      return polandStayDocumentsOptions;
    case 'slovakia':
      return slovakiaStayDocumentsOptions;
    default:
      return [];
  }
};
