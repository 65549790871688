export const title = 'Total number of relocated employees';

export const radioGroupOptions = [
  {
    value: 'country',
    name: 'By country',
  },
  {
    value: 'city',
    name: 'By city',
  },
];

export const filters = {
  radioInitialValue: 'country',
  analyticsType: 'case_count',
  analyticsSource: 're',
};

export const customCityOptions = {
  plotOptions: {
    bar: {
      distributed: true,
      dataLabels: {
        position: 'top',
        orientation: 'vertical',
        fontSize: '14px',
        fontFamily: 'IBM Plex Sans',
      },
      columnWidth: '100%',
      rangeBarOverlap: false,
    },
  },
  dataLabels: {
    enabled: true,
    offsetY: 10,
    offsetX: -4,
    style: {
      padding: {
        top: 30,
      },
      fontSize: '14px',
      fontWeight: '600',
      colors: ['#000000'],
    },
  },
};

export const customCountryOptions = {
  xaxis: {
    type: 'category',
    labels: {
      minHeight: 120,
      maxHeight: 120,
      rotate: -45,
      rotateAlways: true,
      hideOverlappingLabels: true,
      style: {
        fontSize: '14px',
        fontFamily: 'IBM Plex Sans',
        fontWeight: 400,
        cssClass: 'apexcharts-xaxis-label',
      },
    },
    tickPlacement: 'on',
  },
};
