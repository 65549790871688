import axios from '../../axios';
import actionCreators from './actionCreators';
import queryString from 'query-string';

export const getEmployeeComments = (employeeId, page) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/employees/${employeeId}/comments/`, {
        params: { page: page },
        paramsSerializer: function (params) {
          return queryString.stringify(params, {
            arrayFormat: 'comma',
            skipNull: true,
            skipEmptyString: true,
          });
        },
      })
      .then((response) => {
        dispatch(actionCreators.employeeCommentsLoad(employeeId, response.data, page));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  };
};

export const setCommentOnEmployeeDocumentsPage =
  (documentRequestId, commentId, type, commentText) => async (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    try {
      if (commentId) {
        if (commentText) {
          const response = await axios.put(
            `/api/v1/user-documents/requested-documents/${documentRequestId}/${type}/comment/${commentId}`,
            { text: commentText },
          );
          return response.data;
        } else {
          const response = await axios.delete(
            `/api/v1/user-documents/requested-documents/${documentRequestId}/${type}/comment/${commentId}`,
          );
          return response.data;
        }
      } else {
        const response = await axios.post(
          `/api/v1/user-documents/requested-documents/${documentRequestId}/${type}/comment`,
          {
            text: commentText,
          },
        );
        return response.data;
      }
    } catch (error) {
      toast.type = 'error';
      toast.errorMessage = error.response?.data;
    } finally {
      dispatch(
        actionCreators.toastUpdate({
          type: toast.type,
          message: toast.errorMessage,
        }),
      );
    }
  };
