import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getValuesToUpdate, setAllValuesToNull } from '../../../../helpers';
import { upsertDocumentsWithCurrentField } from '../../../../store/actions/requestDetails';
import DocumentForm from '../../../Common/DocumentForm/DocumentForm';
import { IsCaseDisabledContext } from '../../../RequestDetails/RequestDetails';
import { IsProfileDisabledContext } from '../../../EmployeeProfile/EmployeeProfile';

const EmployeeDocument = ({ employeeId, document }) => {
  const dispatch = useDispatch();

  const isCaseDisabled = useContext(IsCaseDisabledContext);
  const isProfileDisabled = useContext(IsProfileDisabledContext);
  const isDocumentDisabled = isCaseDisabled || isProfileDisabled;

  const onSubmit = (values, { setSubmitting, resetForm, setStatus, setFieldValue }) => {
    const valuesInfo = { ...values };

    valuesInfo[document.slug].forEach((item) => {
      if (item.id === null) delete item.id;
    });

    const allFormFields = document.forms[0].fields.reduce((accumulator, currentValue) => {
      accumulator.push(currentValue.fieldName);
      accumulator.push('customReminders');
      accumulator.push(currentValue.fieldName + 'DateExtraFields');
      return accumulator;
    }, []);

    const valuesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
      })),
      allFormFields,
    );

    const valuesWithFilesToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        ...item.values,
        id: item.formId,
        files: [],
      })),
      ['files'],
    );

    const valuesToAdd = values[document.slug].filter(({ id }) => id === null || id === undefined);

    const isCurrentDocumentsToUpdate = getValuesToUpdate(
      values[document.slug],
      document.forms.map((item) => ({
        id: item.formId,
        isCurrent: item.isCurrent,
      })),
      ['isCurrent'],
    );

    const valuesToUpdateWithNullValues = setAllValuesToNull(valuesToUpdate);
    const valuesToAddWithNullValues = setAllValuesToNull(valuesToAdd);

    const files = valuesInfo[document.slug].some((item) => item?.files?.length);

    if (valuesToUpdate.length || valuesToAdd.length || isCurrentDocumentsToUpdate.length || files) {
      dispatch(
        upsertDocumentsWithCurrentField(
          valuesToUpdateWithNullValues,
          valuesToAddWithNullValues,
          employeeId,
          document.id,
          'employee',
          false,
          isCurrentDocumentsToUpdate,
          valuesWithFilesToUpdate,
        ),
      ).then(() => {
        if (document.isWithAttachments) {
          values[document.slug].forEach((item, index) => setFieldValue(`${document.slug}.${index}.files`, []));
        }
        setSubmitting(false);
      });
    }
  };

  return (
    <DocumentForm
      document={document}
      employeeId={employeeId}
      onSubmit={onSubmit}
      isCase={true}
      isCaseDisabled={isDocumentDisabled}
      withCurrentField={true}
      withArchiveForm={true}
      withEditFormInConstructor={{ pathName: 'documents' }}
      documentType={{ forWhom: 'employee', type: 'documents' }}
      allowCustomReminders={true}
    />
  );
};

export default EmployeeDocument;
