import actionCreators from './actionCreators';
import axios from '../../axios';
import parse from 'html-react-parser';

export const addRelocationCase = async (id) => {
  try {
    const response = await axios.put(`/api/v1/relocation-requests/${id}/create-case/`, { isCase: true });
    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export const getRelocationRequest = (id) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/${id}/`)
      .then((response) => {
        dispatch(actionCreators.requestDetailsLoaded(response.data, id));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.requestDetailsError());
      });
  };
};

export const getCompensation = (requestId) => {
  return (dispatch) => {
    return axios
      .get(`/api/v1/relocation-requests/${requestId}/compensation/`)
      .then((response) => {
        dispatch(actionCreators.compensationCalculatorLoad(response.data, requestId));
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        dispatch(actionCreators.requestDetailsError());
      });
  };
};

export const editCompensation = (data, requestId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .put(`/api/v1/relocation-requests/${requestId}/compensation/`, data)
      .then((response) => {
        dispatch(actionCreators.compensationCalculatorUpdate(response.data, requestId));
        dispatch(
          actionCreators.requestDetailsUpdated(
            {
              compensationAmount: response.data.compensationCalculation.compensationAmount,
              paidInAdvance: response.data.compensationCalculation.paidInAdvance,
            },
            requestId,
          ),
        );
        return response.data;
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editRequestedDocuments = (docRequestId, reqId, data, requestedDocuments, employeeId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .put(
        employeeId
          ? `/api/v1/user-documents/requested-documents/${docRequestId}/profile`
          : `/api/v1/user-documents/requested-documents/${docRequestId}/case`,
        data,
      )
      .then((response) => {
        reqId
          ? dispatch(actionCreators.requestDetailsUpdated({ requestedDocuments: response.data }, reqId))
          : dispatch(actionCreators.employeeProfileUpdate({ requestedDocuments: response.data }, employeeId));
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editRelocationRequest = (reqId, data, isDocuments) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .put(
        isDocuments ? `/api/v1/relocation-requests/employee-edit/${reqId}/` : `/api/v1/relocation-requests/${reqId}/`,
        data,
      )
      .then((response) => {
        dispatch(actionCreators.requestDetailsUpdated(response.data, reqId, response.data.employee.uuid));
        dispatch(
          actionCreators.requestCaseUpdate({
            requestCaseList: { status: response.data?.status },
            caseId: reqId,
          }),
        );
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const editCaseDocumentsToDisplay = (requestId, data) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .put(`/api/v1/relocation-requests/${requestId}/documents-to-display/`, data)
      .then((response) => {
        dispatch(actionCreators.requestDetailsUpdated(response.data, requestId, response.data.employee.uuid));
      })
      .catch((error) => {
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};

export const sendNotifyAboutGatheredVacToManager = (requestId) => {
  return (dispatch) => {
    let toast = { type: 'success', errorMessage: '' };
    return axios
      .post(`/api/v1/relocation-requests/${requestId}/vac-is-gathered-notice/`)
      .then((response) => {
        dispatch(
          actionCreators.requestDetailsUpdated(
            { isManagerNotifiedAboutGatheredVac: true, managerNotifiedAt: response.data?.managerNotifiedAt },
            requestId,
          ),
        );
      })
      .catch((error) => {
        // let errorMessage = '';
        // if (error.response?.data) {
        //   errorMessage = parse(error.response?.data);
        // }
        toast.type = 'error';
        toast.errorMessage = error.response?.data;
      })
      .finally(() => {
        dispatch(
          actionCreators.toastUpdate({
            type: toast.type,
            message: toast.errorMessage,
          }),
        );
      });
  };
};
