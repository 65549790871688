import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEmployeesEmails, getTemplate, sendBulkEmail } from '../../../store/actions/emailTemplates';

import { initialValues as initialValuesEmpty } from '../../EmailTemplates/WriteEmail/SendEmail/schemaProperties';

import SendBulkEmailForm from './SendBulkEmailForm/SendBulkEmailForm';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import axios from '../../../axios';
import actionCreators from '../../../store/actions/actionCreators';
import queryString from 'query-string';
import { getParams } from '../tableProperties/getParams';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const getBulkEmailData = (data, filterParams, params) => {
  return axios
    .post('/api/v1/employees/legalization-dashboard/bulk-email/', data, {
      params: { ...params, ...filterParams },
      paramsSerializer: function (params) {
        return queryString.stringify(params, {
          arrayFormat: 'comma',
          skipNull: true,
          skipEmptyString: true,
        });
      },
    })
    .then((response) => {
      return response?.data;
    })
    .catch((error) => {
      console.error(error);
      throw error;
    });
};
const SendBulkEmailWrapper = ({ match }) => {
  const dispatch = useDispatch();

  const [isLoadingEmployees, setIsLoadingEmployees] = useState(true);
  const [isLoadingTemplate, setIsLoadingTemplate] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [bccList, setBccList] = useState([]);
  const [ccList, setCcList] = useState([]);

  const templateId = match.params.templateId;
  const template = useSelector((state) => state.emailTemplates.emailTemplate[templateId]);

  const { employeesWithEmails: employees } = useSelector((state) => state.emailTemplates || []);

  const { generationExcelEmployeeIds, allCases, excludeIds } = useSelector((state) => state.legalizationDocuments);
  const objectUuids = Object.keys(generationExcelEmployeeIds).filter((key) => generationExcelEmployeeIds[key]);

  const initialValues = templateId
    ? {
        emailList: [],
        bccList: bccList,
        ccList: ccList,
        subject: template?.subject || '',
        message: template?.body || '',
      }
    : initialValuesEmpty;

  useEffect(() => {
    if (templateId && !template) {
      dispatch(getTemplate(templateId))
        .then(() => setIsLoadingTemplate(false))
        .catch(() => {
          setIsLoadingTemplate(false);
        });
    } else {
      setIsLoadingTemplate(false);
    }
  }, [templateId]);

  useEffect(() => {
    if (!employees?.length) {
      dispatch(getEmployeesEmails())
        .then(() => setIsLoadingEmployees(false))
        .catch(() => {
          setIsLoadingEmployees(false);
        });
    } else {
      setIsLoadingEmployees(false);
    }
  }, []);

  useEffect(() => {
    if (!isLoadingTemplate && !isLoadingEmployees) {
      const [ordering, filtering, searchParams, toggleParams] = getParams({
        tableKey: 'PolandDashboard',
      });

      getBulkEmailData(
        {
          objectUuids: objectUuids,
          allObjects: allCases,
          excludeIds: excludeIds,
        },
        filtering,
        {
          ordering: ordering,
          search: searchParams,
          toggle: toggleParams,
        },
      )
        .then((response) => {
          setBccList(response.bccList || []);
          setCcList(response.ccList || []);
          setIsLoadingData(false);
        })
        .catch(() => {
          setIsLoadingData(false);
        });
    }
  }, [isLoadingTemplate, isLoadingEmployees]);

  return (
    <>
      {!isLoadingData && !isLoadingEmployees && !isLoadingTemplate && (
        <section className="email-templates__section">
          <div className="email-templates__wrapper">
            <SendBulkEmailForm employees={employees} initialValues={initialValues} id={templateId} />
          </div>
        </section>
      )}
      {(isLoadingData || isLoadingEmployees || isLoadingTemplate) && <SpinnerContainer />}
    </>
  );
};

export default SendBulkEmailWrapper;
