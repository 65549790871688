import axios from 'axios';

export const uploadFileToS3 = async (presignedUrl, file) => {
  const formData = new FormData();
  Object.keys(presignedUrl.fields).forEach((key) => {
    formData.append(key, presignedUrl.fields[key]);
  });

  formData.append('Content-Type', file.type);
  formData.append('file', file);

  try {
    const response = await axios.post(presignedUrl.url, formData);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
