import TitleWithSort from '../../Shared/Table/TitleWithSort/TitleWithSort';
import FilterDropdownIcon from '../../Shared/Table/FilterIcon/FilterIcon';
import DateRangeFilter from '../../Shared/Table/DateRangeFilter/DateRangeFilter';
import { statusList, blueCardOptions, workPermitOptions, residencePermitTypeOptions } from './tableOptions';
import ExcelTitle from '../generationAllExcel/ExcelTitle';

export const defaultColumns = [
  {
    key: 'number',
    title: '№',
    label: '№',
    dataIndex: 'number',
    fixed: 'left',
    width: '2%',
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'fullName'} title={'Name'} />,
    label: 'Name',
    key: 'fullName',
    dataIndex: 'fullName',
    sorter: {
      multiple: 1,
    },
    fixed: 'left',
    width: '4%',
    sortOrder: null,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'country'} title={'Country'} />,
    label: 'Country',
    key: 'country',
    dataIndex: 'country',
    width: '8%',
    sorter: {
      multiple: 2,
    },
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
    sortOrder: null,
    filterMultiple: false,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'city'} title={'City'} />,
    label: 'City',
    key: 'city',
    dataIndex: 'city',
    width: '8%',
    sorter: {
      multiple: 3,
    },
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
    sortOrder: null,
  },
  {
    key: 'gmAssigned',
    title: 'GM assigned',
    label: 'GM assigned',
    dataIndex: 'gmAssigned',
    width: '8%',
    filters: [],
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
    filterMultiple: false,
  },
  {
    title: 'Status',
    label: 'Status',
    key: 'status',
    dataIndex: 'status',
    width: '10%',
    filters: statusList,
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
    filterMultiple: false,
  },
  {
    title: (props) => <TitleWithSort {...props} sorterKey={'relocationDate'} title={'Relocation date'} />,
    label: 'Relocation date',
    sorter: {
      multiple: 4,
    },
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <DateRangeFilter {...props} />,
    key: 'relocationDate',
    dataIndex: 'relocationDate',
    width: '10%',
    sortOrder: null,
    filteredValue: null,
  },
  {
    title: 'Residence permit',
    label: 'Residence permit',
    key: 'residencePermit',
    dataIndex: 'residencePermit',
    width: '10%',
    filters: blueCardOptions,
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
    filterMultiple: false,
  },
  {
    key: 'residencePermitExpiration',
    title: (props) => (
      <TitleWithSort {...props} sorterKey={'residencePermitExpiration'} title={'Residence permit expiration'} />
    ),
    label: 'Residence permit expiration',
    dataIndex: 'residencePermitExpiration',
    sorter: {
      multiple: 5,
    },
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <DateRangeFilter {...props} />,
    width: '10%',
    sortOrder: null,
    filteredValue: null,
  },
  // {
  //   title: 'Work permit type',
  //   label: 'Work permit type',
  //   key: 'workPermitType',
  //   dataIndex: 'workPermitType',
  //   width: '10%',
  //   filters: workPermitOptions,
  //   filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
  //   filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
  //   filteredValue: null,
  //   filterMultiple: false,
  // },
  // {
  //   key: 'workPermitExpiration',
  //   title: 'Work permit expiration',
  //   label: 'Work permit expiration',
  //   dataIndex: 'workPermitExpiration',
  //   width: '10%',
  // },
  {
    title: 'Residence permit type',
    label: 'Residence permit type',
    key: 'residencePermitTypeLt',
    dataIndex: 'residencePermitTypeLt',
    width: '10%',
    filters: residencePermitTypeOptions,
    filterSearch: (input, record) => record.text.toLowerCase().includes(input.toLowerCase()),
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filteredValue: null,
    filterMultiple: false,
  },
  {
    title: (props) => (
      <TitleWithSort {...props} sorterKey={'residencePermitExpiresLt'} title={'Residence permit expires'} />
    ),
    label: 'Residence permit expires',
    key: 'residencePermitExpiresLt',
    dataIndex: 'residencePermitExpiresLt',
    sorter: {
      multiple: 5,
    },
    filterIcon: (filtered) => <FilterDropdownIcon filtered={filtered} />,
    filterDropdown: (props) => <DateRangeFilter {...props} />,
    width: '10%',
    sortOrder: null,
    filteredValue: null,
  },
  {
    key: 'notes',
    title: 'Notes',
    label: 'Notes',
    dataIndex: 'notes',
    width: '12%',
  },
  {
    key: 'excel',
    title: <ExcelTitle />,
    label: 'Excel',
    dataIndex: 'excel',
    width: '4%',
  },
];

export const sortMapping = {
  fullName: 'last_name',
  country: 'country_name',
  city: 'city_name',
  relocationDate: 'relocation_date',
  residencePermitExpiresLt: 'lt_residence_permit_expires',
  residencePermitExpiration: 'pl_trc_blue_card_date',
  status: 'status',
};

export const filterMapping = {
  country: 'country',
  city: 'city',
  relocationDate: 'employee_relocation_date',
  residencePermit: 'residence_permit',
  // workPermitType: 'work_permit_type',
  residencePermitTypeLt: 'residence_permit_type',
  status: 'status',
  gmAssigned: 'responsible',
  residencePermitExpiration: 'residence_permit_date',
  residencePermitExpiresLt: 'residence_permit_expires',
};

export const dateRangeFilterMapping = ['employee_relocation_date', 'residence_permit_date', 'residence_permit_expires'];

export const columnsKeysDefault = [
  'number',
  'fullName',
  'country',
  'city',
  'gmAssigned',
  'status',
  'relocationDate',
  'notes',
  'excel',
];

export const columnsKeysPL = [
  'number',
  'fullName',
  'country',
  'city',
  'gmAssigned',
  'status',
  'relocationDate',
  'residencePermit',
  'residencePermitExpiration',
  'workPermitType',
  'workPermitExpiration',
  'notes',
  'excel',
];

export const columnsKeysLT = [
  'number',
  'fullName',
  'country',
  'city',
  'gmAssigned',
  'status',
  'relocationDate',
  'residencePermitTypeLt',
  'residencePermitExpiresLt',
  'notes',
  'excel',
];

export const columnsKeysSL = ['number', 'fullName', 'country', 'city', 'gmAssigned', 'relocationDate', 'excel'];

export const getColumnsKeys = (selectedCountry) => {
  switch (selectedCountry.toLowerCase()) {
    case 'poland':
      return columnsKeysPL;
    case 'lithuania':
      return columnsKeysLT;
    case 'slovakia':
      return columnsKeysSL;
    default:
      return columnsKeysDefault;
  }
};
