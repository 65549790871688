import { Formik, Form } from 'formik';

import { getFormElement } from '../../../Shared/Form/FormElements';
import { formSchema } from './formSchema';

const CandidateWithoutEmploymentInfo = () => {
  return (
    <Formik enableReinitialize initialValues={{ company: 'Not provided', employmentDate: 'Not provided' }}>
      {(formikProps) => (
        <Form>
          {formSchema.map((field) => (
            <div key={field.fieldName}>{getFormElement(field.type, field, formikProps)}</div>
          ))}
        </Form>
      )}
    </Formik>
  );
};

export default CandidateWithoutEmploymentInfo;
