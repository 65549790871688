import { Formik, Form } from 'formik';
import { getFormElement } from '../../Shared/Form/FormElements';
import { editRelativeEmploymentInformation } from '../../../store/actions/requestDetails';
import { useDispatch } from 'react-redux';
import { SubmitButton } from '../../Shared/Form/Buttons/Buttons';

const formSchema = [
  {
    fieldName: 'company',
    type: 'TextInput',
    label: 'Institution',
  },
  {
    fieldName: 'position',
    type: 'TextInput',
    label: 'Position',
  },
  {
    fieldName: 'address',
    type: 'TextareaAsInput',
    label: 'Address',
  },
];

const EducationInfo = ({ isCaseDisabled, ...employmentInfo }) => {
  const dispatch = useDispatch();

  const onSubmit = (values, { setSubmitting, resetForm, setStatus }) => {
    dispatch(
      editRelativeEmploymentInformation(employmentInfo.id, employmentInfo.rel_id, employmentInfo.rel_req_id, values),
    );
    setSubmitting(false);
  };

  return (
    <div className="EducationInfo">
      <Formik enableReinitialize initialValues={employmentInfo} onSubmit={onSubmit}>
        {(formikProps) => (
          <Form>
            {formSchema.map((field) => (
              <div key={field.fieldName}>{getFormElement(field.type, field, undefined, isCaseDisabled)}</div>
            ))}
            <SubmitButton title="Submit" formikProps={formikProps} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EducationInfo;
