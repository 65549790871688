import { parseFiltering, parseOrdering, transformObjectToArray } from '../../Shared/Table/helpers';
import { columns, dateRangeFilterMapping, filterMapping, multipleFilterMapping, sortMapping } from './tableProperties';

export const getParams = ({ tableKey }) => {
  const paramsFromLocalStorage = JSON.parse(sessionStorage.getItem(tableKey));

  const getDefaultOrderingValue = () => {
    const orderingParams =
      paramsFromLocalStorage && paramsFromLocalStorage?.ordering
        ? transformObjectToArray(paramsFromLocalStorage?.ordering)
        : [];

    orderingParams.forEach((item) => {
      columns.find((columnItem) => columnItem.key === item.columnKey).defaultSortOrder = item.order;
    });

    return orderingParams;
  };

  const getDefaultFilteringValue = () => {
    const filteringParams = paramsFromLocalStorage?.filtering || {};
    for (const key in filteringParams) {
      columns.find((item) => item.key === key).defaultFilteredValue = paramsFromLocalStorage.filtering[key];
    }

    return filteringParams;
  };

  const additionalFiltering = paramsFromLocalStorage?.additionalFiltering || {};

  const ordering = parseOrdering(getDefaultOrderingValue(), sortMapping);
  const mainFiltering = parseFiltering({
    rowFiltering: getDefaultFilteringValue(),
    dateRangeFilterMapping,
    multipleFilterMapping,
    filterMapping,
  });

  const filtering = { ...mainFiltering, ...additionalFiltering };

  const searchParams = paramsFromLocalStorage?.search || '';
  const toggleParams = paramsFromLocalStorage?.toggle || null;

  return [ordering, filtering, searchParams, toggleParams];
};
