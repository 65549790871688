import { useHistory } from 'react-router-dom';

import Modal from '../../../../Shared/Modal/Modal';
import './emailStatusModal.scss';

const EmailStatusModal = ({ isModalVisible, setIsModalVisible }) => {
  const history = useHistory();

  const handleCloseModal = () => {
    setIsModalVisible((prev) => ({ ...prev, isVisible: false, status: false }));
    if (isModalVisible.isTemplateSelected) {
      history.push(`/email-templates`);
    }
  };

  const message = isModalVisible.status
    ? 'Thank you! Your message has been sent.'
    : 'Sorry, something went wrong during sending email. Try to send email again';

  return (
    <div className="send-email__status">
      <Modal isVisible={isModalVisible.isVisible} setIsVisible={handleCloseModal} size="l">
        <div className="modal-message">
          <h4 className="underlined panel-top-row">Thank you</h4>
          <div className={'message'}>{message}</div>
          <button type="button" className="button btn-form main-btn btn-back ok-btn" onClick={handleCloseModal}>
            OK
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EmailStatusModal;
