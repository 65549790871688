import { UserManager, WebStorageStateStore } from 'oidc-client';

import { AUTHORITY, CLIENT_ID } from './constants';

let userManager = null;

const createCallbackUserManager = () =>
  new UserManager({
    authority: AUTHORITY,
    client_id: CLIENT_ID,
    monitorSession: false,
    userStore: new WebStorageStateStore({ store: localStorage }),
    // redirect_uri: `${window.location.origin}/auth/signin-callback`,
    // automaticSilentRenew: true,
  });

const getCallbackUserManager = () => {
  if (!userManager) {
    userManager = createCallbackUserManager();
  }

  return userManager;
};

export default getCallbackUserManager;
