import { types } from '../actions/types';

const initialState = {
  requestCaseList: [],
  generationExcelRequestIds: {},
  allCases: false,
  exludeIds: [],
  next: null,
  previous: null,
};

const requestCaseListReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_CASE_LOADED:
      return {
        ...state,
        generationExcelRequestIds: {},
        requestCaseList: action.payload.requestCaseList,
        statistics: action.payload.statistics,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.REQUEST_CASE_ERROR:
      return {
        ...state,
        requestCaseList: action.payload.requestCaseList,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.REQUEST_CASE_UPDATED:
      return {
        ...state,
        requestCaseList: [...state.requestCaseList, ...action.payload.requestCaseList],
        statistics: action.payload.statistics,
        next: action.payload.next,
        previous: action.payload.previous,
      };
    case types.REQUEST_CASE_UPDATE:
      return {
        ...state,
        requestCaseList: state.requestCaseList.map((item) => {
          if (item.id === parseInt(action.payload.caseId)) {
            if (action.payload.requestCaseList.events) {
              item.events = [action.payload.requestCaseList.events, ...item.events];
              return item;
            }
            return { ...item, ...action.payload.requestCaseList };
          }
          return item;
        }),
      };
    case types.CASE_LIST_ALL_CASES_UPDATE:
      return {
        ...state,
        allCases: !state.allCases,
        generationExcelRequestIds: {},
        exludeIds: [],
      };
    case types.GENERATION_EXCEL_CHECKBOXES_ADD:
      return {
        ...state,
        ...(state.allCases &&
          !state.exludeIds.includes(action.payload.employeeUuid) && {
            exludeIds: [...state.exludeIds, action.payload.employeeUuid],
          }),
        ...(state.allCases &&
          state.exludeIds.includes(action.payload.employeeUuid) && {
            exludeIds: state.exludeIds.filter((item) => item !== action.payload.employeeUuid),
          }),
        ...(!state.allCases && {
          generationExcelRequestIds: { ...state.generationExcelRequestIds, ...action.payload.generationExcelCheckbox },
        }),
      };
    case types.GENERATION_EXCEL_CHECKBOXES_REMOVE:
      return {
        ...state,
        generationExcelRequestIds: {},
      };
    default:
      return {
        ...state,
      };
  }
};

export default requestCaseListReducer;
