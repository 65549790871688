export const columns = [
  {
    key: 'date',
    title: 'Date',
    label: 'Date',
    dataIndex: 'date',
    width: '15%',
  },
  {
    key: 'time',
    title: 'Time',
    label: 'Time',
    dataIndex: 'time',
    width: '15%',
  },
  {
    key: 'comment',
    title: 'Comment',
    label: 'Comment',
    dataIndex: 'comment',
    width: '70%',
  },
];
