export const reasonOptions = [
  {
    value: 'Visa',
    label: 'Visa',
    text: 'Visa',
  },
  {
    value: 'Passport',
    label: 'Passport',
    text: 'Passport',
  },
  {
    value: 'Residence permit',
    label: 'Residence permit',
    text: 'Residence permit',
  },
];

export const residencePermitOptions = [
  {
    value: 'Not provided',
    label: 'Not provided',
    text: 'Not provided',
  },
  {
    value: 'Blue Card',
    label: 'Blue Card',
    text: 'Blue Card',
  },
];

export const passportOptions = [
  {
    value: true,
    label: 'Not provided',
    text: 'Not provided',
  },
];

export const visaDocumentsOptions = [
  {
    title: 'D23',
    key: 'D23',
  },
  {
    title: 'D18',
    key: 'D18',
  },
  {
    title: 'D21',
    key: 'D21',
  },
  {
    title: 'D21 PBH',
    key: 'D21 PBH',
  },
  {
    title: 'D05A',
    key: 'D05A',
  },
  {
    title: 'D23 PBH',
    key: 'D23 PBH',
  },
  {
    title: 'D06',
    key: 'D06',
  },
  {
    title: 'D09',
    key: 'D09',
  },
];

export const polandWorkDocumentsOptions = [
  {
    title: 'Visa',
    key: 'VisaForWork',
    hasChildren: true,
    children: [
      {
        title: 'Visa D21 (humanitarian)',
        key: 'Visa D21 (humanitarian)',
      },
      {
        title: 'Visa D21 (PBH)',
        key: 'Visa D21 (PBH)',
      },
      {
        title: 'Visa D23 (PBH)',
        key: 'Visa D23 (PBH)',
      },
    ],
  },
  {
    value: 'Oswiadczenie',
    title: 'Oświadczenie',
    key: 'Oświadczenie',
    isLeaf: true,
  },
  {
    value: 'Zezwolenie',
    title: 'Zezwolenie',
    key: 'Zezwolenie',
    isLeaf: true,
  },
  {
    value: 'Karta pobytu',
    title: 'Karta pobytu',
    key: 'Karta pobytu',
    isLeaf: true,
  },
  {
    value: 'Karta Polaka',
    title: 'Karta Polaka',
    key: 'Karta Polaka',
    isLeaf: true,
  },
  {
    value: 'Powiadomienie UKR',
    title: 'Powiadomienie UKR',
    key: 'Powiadomienie UKR',
    isLeaf: true,
  },
  {
    value: 'Zaświadczenie z uczelni',
    title: 'Zaświadczenie z uczelni',
    key: 'Zaświadczenie z uczelni',
    isLeaf: true,
  },
  {
    value: 'Polski dyplom',
    title: 'Polski dyplom',
    key: 'Polski dyplom',
    isLeaf: true,
  },
  {
    value: 'EU citizenship',
    title: 'EU citizenship',
    key: 'EU citizenship',
    isLeaf: true,
  },
  {
    value: 'Not provided',
    title: 'Not provided',
    key: 'Not provided',
    isLeaf: true,
  },
];

export const slovakiaWorkDocumentsOptions = [
  {
    title: 'Visa',
    key: 'VisaForWork',
    hasChildren: true,
    children: [
      {
        title: 'D269/2022',
        key: 'D269/2022',
      },
      {
        title: 'D work',
        key: 'D work',
      },
      {
        title: 'D rodinny prislusnik',
        key: 'D rodinny prislusnik',
      },
      {
        title: 'Type D',
        key: 'Type D',
      },
    ],
  },
  {
    value: 'Residence permit',
    title: 'Residence permit',
    key: 'Residence permit',
    isLeaf: true,
  },
  {
    value: 'Temporary refugee protection',
    title: 'Temporary refugee protection',
    key: 'Temporary refugee protection',
    isLeaf: true,
  },
  {
    value: 'Work permit',
    title: 'Work permit',
    key: 'Work permit',
    isLeaf: true,
  },
  {
    value: 'EU citizenship',
    title: 'EU citizenship',
    key: 'EU citizenship',
    isLeaf: true,
  },
  {
    value: 'Not provided',
    title: 'Not provided',
    key: 'Not provided',
    isLeaf: true,
  },
];

export const polandStayDocumentsOptions = [
  {
    title: 'Visa',
    key: 'Visa',
    hasChildren: true,
    children: [
      {
        title: 'D23',
        key: 'D23',
      },
      {
        title: 'D18',
        key: 'D18',
      },
      {
        title: 'D21',
        key: 'D21',
      },
      {
        title: 'D21 PBH',
        key: 'D21 PBH',
      },
      {
        title: 'D05A',
        key: 'D05A',
      },
      {
        title: 'D23 PBH',
        key: 'D23 PBH',
      },
      {
        title: 'D06',
        key: 'D06',
      },
    ],
  },

  {
    text: 'Paszport biometryczny',
    title: 'Paszport biometryczny',
    key: 'Paszport biometryczny',
    isLeaf: true,
  },
  {
    text: 'Karta pobytu',
    title: 'Karta pobytu',
    key: 'Karta pobytu',
    isLeaf: true,
  },

  {
    text: 'Złożony wniosek',
    title: 'Złożony wniosek',
    key: 'Złożony wniosek',
    isLeaf: true,
  },
  {
    text: 'Ustawa wojenna',
    title: 'Ustawa wojenna',
    key: 'Ustawa wojenna',
    isLeaf: true,
  },
  {
    text: 'Appeal',
    title: 'Appeal',
    key: 'Appeal',
    isLeaf: true,
  },
  {
    value: 'EU citizenship',
    title: 'EU citizenship',
    key: 'EU citizenship',
    isLeaf: true,
  },
  {
    text: 'Not provided',
    title: 'Not provided',
    key: 'Not provided',
    isLeaf: true,
  },
];

export const slovakiaStayDocumentsOptions = [
  {
    title: 'Visa',
    key: 'VisaForWork',
    hasChildren: true,
    children: [
      {
        title: 'D269/2022',
        key: 'D269/2022',
      },
      {
        title: 'D work',
        key: 'D work',
      },
      {
        title: 'D rodinny prislusnik',
        key: 'D rodinny prislusnik',
      },
      {
        title: 'Type D',
        key: 'Type D',
      },
    ],
  },
  {
    value: 'Residence permit',
    title: 'Residence permit',
    key: 'Residence permit',
    isLeaf: true,
  },
  {
    value: 'Temporary refugee protection',
    title: 'Temporary refugee protection',
    key: 'Temporary refugee protection',
    isLeaf: true,
  },
  {
    value: 'EU citizenship',
    title: 'EU citizenship',
    key: 'EU citizenship',
    isLeaf: true,
  },
  {
    value: 'Not provided',
    title: 'Not provided',
    key: 'Not provided',
    isLeaf: true,
  },
];

export const residentPermitOptions = [
  {
    text: 'Temporary Residence Card',
    title: 'Temporary Residence Card',
    key: 'Temporary Residence Card',
    isLeaf: true,
  },
  {
    text: 'Blue Card',
    title: 'Blue Card',
    key: 'Blue Card',
    isLeaf: true,
  },

  {
    text: 'Permanent Residence Card',
    title: 'Permanent Residence Card',
    key: 'Permanent Residence Card',
    isLeaf: true,
  },
  {
    text: 'International protection',
    title: 'International protection',
    key: 'International protection',
    isLeaf: true,
  },
  {
    text: 'Long-term EU Residence Card',
    title: 'Long-term EU Residence Card',
    key: 'Long-term EU Residence Card',
    isLeaf: true,
  },
  {
    value: 'Refugee',
    title: 'Refugee',
    key: 'Refugee',
    isLeaf: true,
  },
  {
    value: 'Not provided',
    title: 'Not provided',
    key: 'Not provided',
    isLeaf: true,
  },
];

export const employmentDateOptions = [
  {
    value: true,
    label: 'Not provided',
    text: 'Not provided',
  },
];

export const generalDocumentsOptions = [
  {
    value: 'Passport',
    label: 'Passport',
    text: 'Passport',
  },
  {
    value: 'Visa',
    label: 'Visa',
    text: 'Visa',
  },
  {
    value: 'Residence permit',
    label: 'Residence permit',
    text: 'Residence permit',
  },
  {
    value: 'Registration',
    label: 'Registration',
    text: 'Registration',
  },
  {
    value: 'Entry date',
    label: 'Entry date',
    text: 'Entry date',
  },
  {
    value: 'Residence card',
    label: 'Residence card',
    text: 'Residence card',
  },
  {
    value: 'Digital nomad',
    label: 'Digital nomad',
    text: 'Digital nomad',
  },
  {
    value: 'Not provided',
    label: 'Not provided',
    text: 'Not provided',
  },
];

export const countries = {
  Poland: [
    { id: 22, name: 'Gdansk' },
    { id: 21, name: 'Krakow' },
    { id: 13, name: 'Lodz' },
    { id: 26, name: 'Poznan' },
    { id: 18, name: 'Warsaw' },
    { id: 17, name: 'Wroclaw' },
  ],
  Belarus: [
    { id: 4, name: 'Brest' },
    { id: 5, name: 'Gomel' },
    { id: 6, name: 'Grodno' },
    { id: 1, name: 'Minsk' },
    { id: 2, name: 'Mogilev' },
    { id: 7, name: 'Polotsk' },
    { id: 3, name: 'Vitebsk' },
  ],
  US: [
    { id: 11, name: 'Iselin' },
    { id: 10, name: 'Marietta' },
    { id: 8, name: 'New York' },
    { id: 9, name: 'San Francisco' },
  ],
  UK: [{ id: 12, name: 'London' }],
  Austria: [{ id: 14, name: 'Vienna' }],
  Ukraine: [
    { id: 15, name: 'Kiev' },
    { id: 16, name: 'Lviv' },
  ],
  Lithuania: [{ id: 20, name: 'Vilnius' }],
  Uzbekistan: [{ id: 19, name: 'Tashkent' }],
  Georgia: [{ id: 23, name: 'Tbilisi' }],
  Kazakhstan: [{ id: 24, name: 'Almaty' }],
  Kyrgyzstan: [{ id: 25, name: 'Bishkek' }],
  Slovakia: [
    { id: 27, name: 'Bratislava' },
    { id: 28, name: 'Kosice' },
  ],
  Bulgaria: [{ id: 29, name: 'Sofia' }],
  Mexico: [{ id: 30, name: 'Mexico City' }],
};

const defaultWorkCityOption = [{ name: 'Not provided' }];

export const getWorkPlaceCitiesOptions = ({ selectedCountry, workPlaceCities }) => {
  const selectedCountryCities =
    countries[selectedCountry]?.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    }) || [];

  const workPlaceCitiesWithoutSelectedCountryCities = workPlaceCities.filter((item) =>
    selectedCountryCities.every((city) => city.id !== item.id),
  );

  const workPlaceCitiesOptions = defaultWorkCityOption
    .concat(selectedCountryCities)
    .concat(workPlaceCitiesWithoutSelectedCountryCities)
    .map((city) => ({
      text: city.name,
      value: city.name,
    }));

  return workPlaceCitiesOptions || [];
};
