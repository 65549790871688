import { useState } from 'react';
import { useHistory } from 'react-router';
import { Tooltip } from 'antd';

import Modal from '../../../Shared/Modal/Modal';
import SendDocs from '../../SendDocs/SendDocs';
import './sendInfo.scss';

const SendInfo = ({
  sendDocs,
  docsInfoEmployee,
  docsInfoRelatives,
  setCurrentTab,
  setSelectedMenuItem,
  setSelectedTab,
  withWhom,
  requestId,
}) => {
  const history = useHistory();

  const [isModalVisible, setModalVisible] = useState(false);
  const [isDocsSent, setDocsSent] = useState(false);

  const onRedirect = () => {
    return history.push(`/`);
  };

  const handleCloseModal = () => {
    setDocsSent(false);
    onRedirect();
  };
  return (
    <>
      <div className="send-info">
        <Tooltip
          placement={'top'}
          title={
            <div className="send-info__tooltip">
              Click to send attached docs
              <br /> to the Global Mobility team
            </div>
          }
        >
          <button className="button btn-form main-btn btn-back send-docs__btn" onClick={() => setModalVisible(true)}>
            Send docs
          </button>
        </Tooltip>
      </div>
      {isModalVisible && (
        <div className="send-info__modal">
          <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l" title="Send info">
            <div className="send-info-modal">
              <h4 className="underlined panel-top-row">Send info</h4>
              <p className="send-info-modal__question">
                Are you sure? All information will be saved without the possibility of editing!
              </p>
              <SendDocs
                sendDocs={sendDocs}
                docsInfoEmployee={docsInfoEmployee}
                docsInfoRelatives={docsInfoRelatives}
                setCurrentTab={setCurrentTab}
                setSelectedMenuItem={setSelectedMenuItem}
                setSelectedTab={setSelectedTab}
                sendInfoModalClose={setModalVisible}
                setDocsSent={setDocsSent}
                withWhom={withWhom}
                requestId={requestId}
              />
            </div>
          </Modal>
        </div>
      )}
      {isDocsSent && (
        <div className="send-docs-success">
          <Modal isVisible={isDocsSent} setIsVisible={handleCloseModal} size="l">
            <div className="modal-message">
              <h4 className="underlined panel-top-row">Thank you</h4>
              <p>Thank you for the provided documents. We will get back to you if we need additional information.</p>
              <button type="button" className="button btn-form main-btn btn-back ok-btn" onClick={handleCloseModal}>
                OK
              </button>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default SendInfo;
