import classNames from 'classnames';
import { Tooltip } from 'antd';

import DeleteFormIcon from '../../../../assets/img/icons/Сlose_square_line.svg';

const CancelForm = ({ toggle, formIndex, remove }) => {
  return (
    <>
      <Tooltip placement={'top'} title={'Cancel'} mouseEnterDelay={0} mouseLeaveDelay={0}>
        <button className={classNames('shareButton ico', { open: toggle }, { close: !toggle })} type="button">
          <div className="tooltip-button">
            <img className="delete-icon" src={DeleteFormIcon} alt="cancel" onClick={() => remove(formIndex)} />
          </div>
        </button>
      </Tooltip>
    </>
  );
};

export default CancelForm;
