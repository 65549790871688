import { Switch, Route, Redirect } from 'react-router-dom';

import DeleteEmployeeProfile from '../DeleteEmployeeProfile/DeleteEmployeeProfile';
import GeneralInfo from '../../Common/GeneralInfo/GeneralInfo';
import ContactInfo from '../../Common/ContactInfo/ContactInfo';
import EmploymentInfo from '../../RequestDetails/EmploymentInfo/EmploymentInfo';
import EmployeeForms from '../../Common/EmployeeDocumentsTabs/EmployeeForms/EmployeeForms';
import RequestEmployeeProfileDocuments from '../ProfileDocumentsBlock/RequestEmployeeProfileDocuments/RequestEmployeeProfileDocuments';
import EmployeeProfileHistory from '../EmployeeProfileHistory/EmployeeProfileHistory';
import AdditionalInfoRight from '../AdditionalInfoRight/AdditionalInfoRight';
import EmployeeComments from '../../Common/EmployeeComments/EmployeeComments';
import EmployeeCases from '../EmployeeCases/EmployeeCases';
import LegalizationDocuments from '../../Common/EmployeeDocumentsTabs/LegalizationDocuments/LegalizationDocuments';
import ArchivedDocument from '../../RequestDetails/ArchivedDocument/ArchivedDocument';
import LegalizationRequestOverview from '../../LegalizationProfile/LegalizationRequestOverview/LegalizationRequestOverview';
import LegalizationRequestsHistory from '../../LegalizationProfile/LegalizationRequestsHistory/LegalizationRequestsHistory';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getEmployeeDocumentsToUploadForms } from '../../../store/actions/employeeFeedback';
import EmployeeFeedbackForm from '../../Feedback/EmployeeFeedbackForm/EmployeeFeedBackForm';
import QuickButtonsBlock from '../../Shared/QuickButtonsBlock/QuickButtonsBlock';
import SpinnerContainer from '../../Common/SpinnerContainer/SpinnerContainer';
import EmployeeDocument from '../../EmployeeDocuments/Documents/Content/Employee/EmployeeDocuments/EmployeeDocument/EmployeeDocument';
import EmployeeDocuments from '../../Common/EmployeeDocumentsTabs/EmployeeDocuments/EmployeeDocuments';

const DocumentsToUpload = ({ employeeId }) => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [documentsToUpload, setDocumentsToUpload] = useState([]);

  useEffect(() => {
    getEmployeeDocumentsToUploadForms(employeeId)
      .then((forms) => {
        setDocumentsToUpload(forms);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  }, [dispatch, employeeId]);

  return (
    <div className={'employee-documents-to-upload'}>
      {!isLoading && documentsToUpload.length > 0 && (
        <EmployeeDocuments
          employeeId={employeeId}
          employeeDocuments={documentsToUpload}
          isLegalizationRequestDocuments={true}
        />
      )}

      {!isLoading && documentsToUpload.length === 0 && <p>No available feedback forms</p>}
      {isLoading && <SpinnerContainer />}
    </div>
  );
};

const ContentRoutes = ({ employee, isHrManager, isPortalStaff, isProfileDisable, isCandidate }) => {
  const genInfo = employee?.generalInfo;

  return (
    <div className="employee-profile__content">
      <AdditionalInfoRight employeeId={employee.uuid} gmtMember={employee.profileGmtMember} />
      <Switch>
        <Route
          exact
          path="/profile/:id/general-information"
          component={() => (
            <>
              <GeneralInfo
                employeeId={employee?.uuid}
                fullName={genInfo.fullName}
                fullNameRu={genInfo.fullNameRu}
                otherNameSpelling={genInfo.otherNameSpelling || ''}
                birthDate={genInfo.birthDate}
                maidenName={genInfo.maidenName || ''}
                nationalityId={employee.generalInfo.nationality?.id || undefined}
                citizenshipId={employee.generalInfo.citizenship?.id || undefined}
                isCaseDisabled={!isPortalStaff || isProfileDisable}
                isStudent={employee.generalInfo.isStudent}
                residencyCityId={employee.generalInfo.residencyCity?.id || undefined}
                passportFirstName={employee.generalInfo.passportFirstName || ''}
                passportLastName={employee.generalInfo.passportLastName || ''}
                unit={employee?.unit?.longName || 'Not provided'}
                manager={employee?.manager || 'Not provided'}
                position={employee?.rank?.name || 'Not provided'}
                employeeEducation={employee?.educations}
              />
              {isCandidate && <DeleteEmployeeProfile employeeId={employee?.uuid} />}
            </>
          )}
        />
        <Route
          exact
          path="/profile/:id/contact-information"
          component={() => (
            <ContactInfo
              employeeId={employee?.uuid}
              email={employee.contactInfo?.email || ''}
              skype={employee.contactInfo?.skype || ''}
              phones={employee.contactInfo?.phones || ['']}
              emergencyInfo={employee.contactInfo?.emergencyInfo || ''}
              addressInfo={employee.contactInfo?.addressInfo || []}
              isCaseDisabled={!isPortalStaff || isProfileDisable}
            />
          )}
        />
        <Route
          exact
          path="/profile/:id/employment-information"
          component={() => (
            <EmploymentInfo employmentInfoHistory={employee.employmentInfoHistory} employeeId={employee.uuid} />
          )}
        />
        <Route
          exact
          path="/profile/:id/employee-documents"
          component={() => (
            <EmployeeDocuments
              employeeDocuments={employee?.documents}
              employeeId={employee?.uuid}
              documentsStatus={employee.documentsStatus}
              isCaseDisabled={!isPortalStaff || isProfileDisable}
              country={employee?.currentEmploymentInfo?.company?.country}
              documentsToDisplay={employee?.documentsTypeMapping || {}}
            />
          )}
        />
        <Route
          exact
          path="/profile/:id/employee-forms"
          component={() => <EmployeeForms documents={employee.forms} employeeId={employee.uuid} />}
        />
        <Route
          exact
          path="/profile/:id/legalization-steps"
          component={() => (
            <LegalizationDocuments
              documents={employee.legalizationDocuments}
              employeeId={employee.uuid}
              documentsToDisplay={employee?.documentsTypeMapping || {}}
            />
          )}
        />
        <Route
          exact
          path="/profile/:id/archived-documents"
          component={() => (
            <ArchivedDocument
              employeeId={employee?.uuid}
              archivedDocuments={employee.archivedDocuments}
              isCaseDisabled={!isPortalStaff || isProfileDisable}
            />
          )}
        />
        <Route
          exact
          path="/profile/:id/request-documents"
          component={() => (
            <RequestEmployeeProfileDocuments
              isDocsEditable={employee.isDocsEditable}
              requestedDocuments={employee.requestedDocuments}
              employeeId={employee?.uuid}
              documentsToDisplay={employee.documents}
              employeeForms={employee.forms}
            />
          )}
        />

        <Route
          exact
          path="/profile/:id/employee-cases"
          component={() => <EmployeeCases cases={employee?.relocationRequests} />}
        />
        <Route
          exact
          path="/profile/:id/employee-comments"
          component={() => (
            <>
              <EmployeeComments employeeId={employee?.uuid} />
            </>
          )}
        />
        <Route
          exact
          path="/profile/:id/history"
          component={() => <EmployeeProfileHistory employeeId={employee?.uuid} />}
        />
        <Route
          exact
          path="/profile/:id/legalization-request"
          component={() => <LegalizationRequestOverview employeeId={employee?.uuid} />}
        />
        <Route
          exact
          path="/profile/:id/documents-to-upload/"
          component={() => <DocumentsToUpload employeeId={employee?.uuid} />}
        />
        <Route
          exact
          path="/profile/:id/legalization-requests"
          component={() => <LegalizationRequestsHistory employeeId={employee?.uuid} isPortalStaff={isPortalStaff} />}
        />

        <Redirect exact from="/profile/:id/" to="/profile/:id/general-information" />
      </Switch>
    </div>
  );
};

export default ContentRoutes;
