export const title = 'Total number of Visa gathered';

export const radioGroupOptions = [
  {
    value: 'gm_assigned',
    name: 'GMG assigned',
  },
  {
    value: 'month',
    name: 'By month',
  },
];

export const filters = {
  radioInitialValue: 'gm_assigned',
  analyticsSource: 'sc',
  analyticsType: 'visa_gathered_count',
};
