import NotaBene from '../../../SendInfo/NotaBene/NotaBene';
import RelativeDocument from './RelativeDocument/RelativeDocument';

const RelativeDocuments = ({ requestId, relativeInfo, documents, employeeId }) => {
  return (
    <div>
      <NotaBene />
      {documents &&
        documents.map((document) => (
          <RelativeDocument
            key={document.id}
            requestId={requestId}
            relatedId={relativeInfo.relative.id}
            relatedReqId={relativeInfo.id}
            document={document}
            employeeId={employeeId}
          />
        ))}

      {!documents?.length && <p>No available documents</p>}
    </div>
  );
};

export default RelativeDocuments;
