export const parseOrdering = (sorters, sortMapping) => {
  return sorters.map((item) => {
    if (item?.order === 'ascend') {
      return `-${sortMapping[item.columnKey]}`;
    }
    if (item?.order === 'descend') {
      return `${sortMapping[item.columnKey]}`;
    }
  });
};

export const parseFiltering = ({ rowFiltering, dateRangeFilterMapping, multipleFilterMapping, filterMapping }) => {
  const filters = { ...rowFiltering };

  for (const filter in filters) {
    const filterName = filterMapping[filter] ? filterMapping[filter] : null;
    if (filterName && filterMapping[filter] !== filter) {
      filters[filterName] = filters[filter];
      delete filters[filter];
    }
  }

  dateRangeFilterMapping &&
    dateRangeFilterMapping.map((item) => {
      const obj = filters[item] ? filters[item][0] : null;

      if (obj) {
        filters[item.concat('_', 'before')] = obj.before;
        filters[item.concat('_', 'after')] = obj.after;

        delete filters[item];
      }
    });

  multipleFilterMapping &&
    multipleFilterMapping?.dateRangeFilterMapping.map((item) => {
      const obj = filters[item] ? filters[item][0] : null;

      if (obj?.dateRange?.length > 0) {
        filters[item.concat('_', 'before')] = obj.dateRange[0].before;
        filters[item.concat('_', 'after')] = obj.dateRange[0].after;

        delete filters[item];
      }
      if (obj?.multipleFilter) {
        filters[multipleFilterMapping?.filterMapping[item]] = obj.multipleFilter;

        delete filters[item];
      }
    });

  const filterCheckboxTree = {
    keys: ['feedback_relocation', 'feedback_visa', 'feedback_legalization'],
    filterLookups: ['status', 'quality'],
  };

  filterCheckboxTree &&
    filterCheckboxTree?.keys.map((filterKey) => {
      const obj = filters[filterKey] ? filters[filterKey][0] : null;

      if (obj?.multipleCheckboxTreeFilter) {
        filterCheckboxTree?.filterLookups.map((filterLookup) => {
          if (obj.multipleCheckboxTreeFilter[filterLookup]) {
            filters[filterKey.concat('_', filterLookup)] = obj.multipleCheckboxTreeFilter[filterLookup];

            delete filters[filterKey];
          }
        });
      }
    });

  return filters;
};

export const transformObjectToArray = (object) => {
  return Array.isArray(object) ? object : [object];
};
