import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { useState } from 'react';

import { sendEmployeeDocumentsNotification } from '../../../../../store/actions/requestDetails';
import Modal from '../../../../Shared/Modal/Modal';
import EmployeeSelectDocuments from './Employee/EmployeeSelectDocuments';
import RelativeSelectDocuments from './Relative/RelativeSelectDocuments';
import SetDeadLine from './SetDeadline/SetDeadline';
import { initialValues, validationSchema } from './validationSchema';
import ExitImg from '../../../../../assets/img/exit.svg';
import './selectDocumentsModal.scss';

const SelectDocumentsModal = (props) => {
  const { isModalVisible, setModalVisible, requestId, documentsToDisplay, country, employeeForms } = props;

  const dispatch = useDispatch();
  //
  const [step, setStep] = useState(1);

  const onSubmit = (values, { setSubmitting }) => {
    const docsValues = Object.keys(values).reduce((object, key) => {
      if (key !== 'date' && key !== 'displayVacIsRequired' && key !== 'displayVacIsRequiredForRelatives') {
        object[key] = values[key];
      }
      return object;
    }, {});

    const documentsSentList = _.keys(_.pickBy(docsValues));

    const documentsSentListSpouse = documentsSentList
      .filter((item) => item.includes('spouse'))
      .map((item) => item.split('_')[1]);

    const documentsSentListChild = documentsSentList
      .filter((item) => item.includes('child'))
      .map((item) => item.split('_')[1]);

    const documentsSentListEmployee = documentsSentList.filter(
      (item) => !(item.includes('spouse') || item.includes('child')),
    );

    dispatch(
      sendEmployeeDocumentsNotification(requestId, {
        requiredDocsIds: documentsSentListEmployee,
        docsDeadline: values.date,
        displayVacIsRequired: values.displayVacIsRequired,
        displayVacIsRequiredForRelatives: values.displayVacIsRequiredForRelatives,
        spouseRequiredDocsIds: documentsSentListSpouse,
        childRequiredDocsIds: documentsSentListChild,
      }),
    ).finally(() => {
      setSubmitting(false);
      setModalVisible(false);
    });
  };

  return (
    <div className="select-documents select-documents-send">
      <Modal isVisible={isModalVisible} setIsVisible={setModalVisible} size="l">
        <div className="select-documents__wrapper">
          <div className="select-documents-send__title">
            <h3 className="underlined panel-top-row">{step === 4 ? 'Set deadline' : 'Select documents to send'}</h3>
            <img src={ExitImg} alt="close" onClick={() => setModalVisible(false)} />
          </div>
        </div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {(formikProps) => {
            const { displayVacIsRequired, displayVacIsRequiredForRelatives, ...valuesWithoutDisplayVacIsRequired } =
              formikProps.values;
            return (
              <>
                <Form>
                  {step === 1 && (
                    <EmployeeSelectDocuments
                      documentsToDisplay={documentsToDisplay}
                      employeeForms={employeeForms}
                      country={country}
                      setModalVisible={setModalVisible}
                      setStep={setStep}
                      formikProps={formikProps}
                    />
                  )}
                  {step === 2 && (
                    <RelativeSelectDocuments
                      forWhom={'spouse'}
                      nextStep={3}
                      backStep={1}
                      country={country}
                      setModalVisible={setModalVisible}
                      setStep={setStep}
                      formikProps={formikProps}
                    />
                  )}
                  {step === 3 && (
                    <RelativeSelectDocuments
                      forWhom={'child'}
                      nextStep={4}
                      backStep={2}
                      country={country}
                      setModalVisible={setModalVisible}
                      setStep={setStep}
                      formikProps={formikProps}
                    />
                  )}
                  {step === 4 && (
                    <>
                      <SetDeadLine
                        setStep={setStep}
                        formikProps={formikProps}
                        valuesWithoutDisplayVacIsRequired={valuesWithoutDisplayVacIsRequired}
                      />
                    </>
                  )}
                </Form>
              </>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
};

export default SelectDocumentsModal;
